import { buildPermission } from "portal/utils/auth";
import { capitalize } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import {
  classes,
  GREEN_LOADING_BUTTON,
  RED_LOADING_BUTTON,
  STATUS_TEXT,
} from "portal/utils/theme";
import { ConfigCrop } from "protos/portal/configs";
import {
  CropConfidence,
  getConfigKey,
  getCrops,
  SortedCrops,
} from "portal/utils/crops";
import { CropEditor } from "portal/components/CropEditor";
import { findWhere } from "portal/utils/arrays";
import { getCropPath, getRobotPath, RobotSubpath } from "portal/utils/routing";
import {
  GridActionsColDef,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { ModelName } from "portal/components/ModelName";
import { NoScroll } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SearchField } from "portal/components/header/SearchField";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useGetConfigQuery,
  useListRobotCropsQuery,
  useSetConfigValueMutation,
} from "portal/state/portalApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DisableIcon from "@mui/icons-material/CloseOutlined";
import EnableIcon from "@mui/icons-material/CheckOutlined";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import PinnedIcon from "@mui/icons-material/PushPinOutlined";
import React, { FunctionComponent, useMemo, useState } from "react";

const defaultColumn: Partial<GridColDef> = {
  sortable: true,
  disableColumnMenu: true,
};

const _RobotCropList: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canReadAdvancedCrops = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);
  const canEnableCrops = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);

  const { data } = useQueryPopups(
    useGetConfigQuery(serial ? { serial, noCache: true } : skipToken)
  );

  const config = data?.config;

  const { data: robotCrops } = useQueryPopups(
    useListRobotCropsQuery(serial ? { serial } : skipToken)
  );

  const crops = useMemo<SortedCrops | undefined>(
    () => getCrops(serial, config, robotCrops),
    [serial, config, robotCrops]
  );

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const allCrops = useMemo(() => {
    if (!canEnableCrops) {
      return crops?.enabled;
    }
    return showArchived
      ? [...(crops?.all ?? []), ...(crops?.archived ?? [])]
      : crops?.all;
  }, [canEnableCrops, crops, showArchived]);

  // selected crop
  const { pathname } = useLocation();
  let cropId: string = "";
  const basePath = getRobotPath(serial, RobotSubpath.CROPS);
  if (!pathname.endsWith(basePath)) {
    cropId = pathname.replace(`${basePath}/`, "");
  }
  const selectedCrop = findWhere(allCrops, { id: cropId });

  const { searchText, setSearchText, results } = useFuzzySearch<ConfigCrop>({
    items: allCrops ?? [],
    options: {
      keys: ["id", "commonName", "pinned", "recommended"],
    },
  });

  const columns: Array<GridColDef<ConfigCrop> | GridActionsColDef> = [
    {
      ...defaultColumn,
      field: "commonName",
      headerName: t("models.crops.crop_one"),
      cellClassName: () => "font-bold",
      valueGetter: (value, crop) => crop.commonName,
      width: 250,
    },
    {
      ...defaultColumn,
      field: "id",
      headerName: t("models.crops.fields.id"),
      cellClassName: () => "font-mono text-xs/inherit",
      width: 260,
    },
    {
      ...defaultColumn,
      field: "isEnabled",
      headerName: t("utils.descriptors.enabled"),
      width: 100,
      valueGetter: (value, crop) =>
        crop.isEnabled
          ? t("utils.descriptors.enabled")
          : t("utils.descriptors.disabled"),
      cellClassName: ({ row: crop }) =>
        classes("text-center", {
          "font-bold": crop.isEnabled,
          "text-green-500": crop.isEnabled,
          "text-gray-400": !crop.isEnabled,
        }),
    },
    {
      ...defaultColumn,
      field: "confidence",
      headerName: t("models.crops.fields.confidence.name"),
      width: 100,
      valueGetter: (value, crop) =>
        findWhere(robotCrops, { crop: { id: crop.id } })?.confidence
          ?.confidence ?? t("models.crops.fields.confidence.values.unknown"),
      renderCell: ({ row: crop }) => {
        const veselkaCrop = findWhere(robotCrops, {
          crop: { id: crop.id },
        });
        if (veselkaCrop?.crop?.archived) {
          t("models.crops.fields.confidence.values.archived");
        }
        const confidence = veselkaCrop?.confidence;
        if (!confidence) {
          return "";
        }
        const labelClasses = "text-sm text-right font-bold";
        const valueClasses = "text-sm text-left";
        return (
          <Tooltip
            arrow
            title={
              <div className="text-md p-4">
                <Grid container spacing={1}>
                  <Grid xs={8} className={labelClasses}>
                    {t("models.crops.fields.confidence.fields.totalImages")}
                  </Grid>
                  <Grid xs={4} className={valueClasses}>
                    {confidence.numTotalImages}
                  </Grid>
                  <Grid xs={8} className={labelClasses}>
                    {t("models.crops.fields.confidence.fields.regionalImages")}
                  </Grid>
                  <Grid xs={4} className={valueClasses}>
                    {confidence.numRegionalImages}
                  </Grid>
                </Grid>
              </div>
            }
          >
            <span>
              {/* carbon.actions.compareKeys.ignoreDynamic */}
              {t(
                `models.crops.fields.confidence.values.${confidence.confidence}`
              )}
            </span>
          </Tooltip>
        );
      },
      cellClassName: ({ row: crop }) => {
        const veselkaCrop = findWhere(robotCrops, {
          crop: { id: crop.id },
        });
        const confidence = veselkaCrop?.confidence?.confidence;
        const archived = veselkaCrop?.crop?.archived ?? false;
        return classes("text-center", {
          [`${STATUS_TEXT.ORANGE} font-bold`]:
            !archived && confidence === CropConfidence.LOW,
          [STATUS_TEXT.GREEN]: !archived && confidence === CropConfidence.HIGH,
          [STATUS_TEXT.YELLOW]:
            !archived && confidence === CropConfidence.MEDIUM,
          [STATUS_TEXT.GRAY]: !confidence || archived,
        });
      },
    },
    {
      ...defaultColumn,
      field: "pinned",
      headerName: t("models.crops.fields.pinned"),
      width: 250,
      cellClassName: ({ row: crop }) =>
        classes({
          "text-yellow-500": crop.isEnabled,
        }),
      renderCell: ({ row: crop }) =>
        canReadAdvancedCrops ? (
          <ModelName modelId={crop.pinned}>
            <span className="font-mono text-xs/inherit cursor-pointer text-yellow-500">
              {crop.pinned}
            </span>
          </ModelName>
        ) : (
          <PinnedIcon />
        ),
    },
    {
      ...defaultColumn,
      field: "recommended",
      headerName: t("models.crops.fields.recommended"),
      width: 250,
      cellClassName: ({ row: crop }) =>
        classes("text-mono text-xs/inherit", {
          "text-red-500": crop.pinned && crop.pinned !== crop.recommended,
        }),
      renderCell: ({ row: crop }) => (
        <ModelName modelId={crop.recommended}>{crop.recommended}</ModelName>
      ),
    },
  ];

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [setConfigValue] = useMutationPopups(useSetConfigValueMutation(), {
    success: capitalize(
      t("utils.actions.savedLong", {
        subject: capitalize(t("models.configs.config_one")),
      })
    ),
  });

  const [disabledLoading, setDisabledLoading] = useState<boolean>(false);
  const [enabledLoading, setEnabledLoading] = useState<boolean>(false);
  const toggleSelected = async (enabled: boolean): Promise<void> => {
    if (!serial) {
      return;
    }
    const promises: Promise<any>[] = [];
    for (const id of selectedIds.filter((id) => {
      // allow disabling of all crops
      if (!enabled) {
        return true;
      }
      // only allow enabling of non-archived crops
      const archivedCrop = findWhere(crops?.archived, { id });
      return !archivedCrop;
    })) {
      promises.push(
        setConfigValue({
          serial,
          path: [getConfigKey(serial, String(id)), "enabled"].join("/"),
          value: enabled,
        })
      );
    }
    await Promise.all(promises);
  };

  return (
    <NoScroll>
      <CarbonDataGrid<ConfigCrop>
        header={
          <>
            <SearchField
              value={searchText}
              onChange={setSearchText}
              label={t("utils.actions.searchLong", {
                subject: capitalize(
                  t("models.crops.crop", { count: crops?.all.length ?? 0 })
                ),
              })}
            />
            {canEnableCrops && (
              <div className="flex gap-2">
                <FormControlLabel
                  className="flex-shrink-0"
                  control={
                    <Checkbox
                      checked={showArchived}
                      color="default"
                      onChange={(event, checked) => setShowArchived(checked)}
                    />
                  }
                  label={
                    <span className="whitespace-nowrap">
                      <span className="hidden sm:inline">
                        {t("utils.actions.showLong", {
                          subject: t(
                            "models.crops.fields.confidence.values.archived"
                          ),
                        })}
                      </span>
                      <span className="sm:hidden">
                        {t("models.crops.fields.confidence.values.archived")}
                      </span>
                    </span>
                  }
                />
                <LoadingButton
                  {...RED_LOADING_BUTTON}
                  loading={disabledLoading}
                  disabled={enabledLoading || selectedIds.length === 0}
                  onClick={async () => {
                    setDisabledLoading(true);
                    await toggleSelected(false);
                    setDisabledLoading(false);
                  }}
                  startIcon={<DisableIcon />}
                >
                  <span className="hidden sm:inline">
                    {t("utils.actions.disableLong", {
                      subject: t("utils.table.selected"),
                    })}
                  </span>
                  <span className="sm:hidden">{t("utils.table.selected")}</span>
                </LoadingButton>
                <LoadingButton
                  {...GREEN_LOADING_BUTTON}
                  loading={enabledLoading}
                  disabled={disabledLoading || selectedIds.length === 0}
                  onClick={async () => {
                    setEnabledLoading(true);
                    await toggleSelected(true);
                    setEnabledLoading(false);
                  }}
                  startIcon={<EnableIcon />}
                >
                  <span className="hidden sm:inline">
                    {t("utils.actions.enableLong", {
                      subject: t("utils.table.selected"),
                    })}
                  </span>
                  <span className="sm:hidden">{t("utils.table.selected")}</span>
                </LoadingButton>
              </div>
            )}
          </>
        }
        dimensionClasses={classes("h-auto flex-0", {
          "mr-80": !isUndefined(selectedCrop),
        })}
        className={classes("flex flex-1")}
        loading={!config}
        rows={results}
        columns={columns}
        columnVisibilityModel={{
          isEnabled: canEnableCrops,
          id: canReadAdvancedCrops,
          recommended: canReadAdvancedCrops,
          confidence: canReadAdvancedCrops,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "isEnabled", sort: "desc" }],
          },
        }}
        hideFooter
        getRowId={(row: ConfigCrop) => row.id}
        getRowClassName={({ row: crop }) =>
          classes({
            "cursor-pointer": canEnableCrops,
            "bg-blue-500": crop.id === selectedCrop?.id,
          })
        }
        onRowClick={({ row: crop }) =>
          navigate(getCropPath(serial ?? "", crop.id))
        }
        disableRowSelectionOnClick
        checkboxSelection={canEnableCrops}
        onRowSelectionModelChange={(newRowSelectionModel) =>
          setSelectedIds(newRowSelectionModel)
        }
        rowSelectionModel={selectedIds}
      />
      <CropEditor
        serial={serial}
        configCrop={selectedCrop}
        open={!isUndefined(selectedCrop)}
        onClose={() => navigate(basePath)}
      />
    </NoScroll>
  );
};

export const RobotCropList = withAuthenticationRequired(_RobotCropList);
