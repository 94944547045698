import {
  getPointDetectionThumbnailPaddingFactor,
  PaginationParameters,
} from "portal/utils/categoryCollectionProfile";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { LabelPoint } from "protos/veselka/label_point";
import {
  processImageResults,
  ResultsMetadata,
} from "../images/processImageResults";
import { RadioChipOption } from "../RadioChips";
import { RemoveImageOverlay } from "./RemoveImageOverlay";
import { theme } from "portal/utils/theme";
import { ThumbnailBaseInfo, ThumbnailImage } from "../images/ThumbnailImage";
import { useLazyListLabelPointsQuery } from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import MoreIcon from "@mui/icons-material/Add";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

const DEFAULT_PAGINATION: PaginationParameters = { page: 1, pageSize: 50 };

const initialResultsState: ResultsMetadata<LabelPoint> = {
  data: {
    ids: new Set(),
    data: [],
  },
  areAllResultsLoaded: false,
  loadableImagesCount: 0,
};

interface Props {
  category: RadioChipOption;
  chipIds: string[];
  thumbnailImages: ThumbnailBaseInfo[];
  dimensions: { width: number; height: number };
  onRemove?: (
    id: { chipId?: string; labelPointId: string },
    thumbnail: ThumbnailBaseInfo
  ) => void;
}
export const CategorizedImages: FunctionComponent<Props> = ({
  category,
  chipIds,
  thumbnailImages,
  dimensions,
  onRemove,
}) => {
  const { t } = useTranslation();

  const [pagination, setPagination] =
    useState<PaginationParameters>(DEFAULT_PAGINATION);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [resultsState, setResultsState] =
    useState<ResultsMetadata<LabelPoint>>(initialResultsState);

  const [getLabelPoints] = useLazyPopups(useLazyListLabelPointsQuery());

  const fetchChipLabelPoints = useCallback(
    async (pagination: PaginationParameters, chipIds: string[]) => {
      if (chipIds.length === 0) {
        return;
      }
      setIsLoading(true);
      const { data: labelPointResults, isError: fetchError } =
        await getLabelPoints({
          ...pagination,
          chipIds,
        });

      if (
        (labelPointResults && labelPointResults.data.length < chipIds.length) ||
        fetchError
      ) {
        setIsError(true);
      } else {
        setResultsState((previous) =>
          processImageResults(previous, labelPointResults)
        );
        setPagination((previous) => ({
          ...previous,
          page: previous.page + 1,
        }));
      }
      setIsLoading(false);
    },
    [getLabelPoints]
  );

  const loadMore = useCallback(() => {
    if (!isLoading && !resultsState.areAllResultsLoaded && !isError) {
      fetchChipLabelPoints(pagination, chipIds);
    }
  }, [
    isLoading,
    resultsState.areAllResultsLoaded,
    isError,
    fetchChipLabelPoints,
    pagination,
    chipIds,
  ]);

  useEffect(() => {
    setResultsState(initialResultsState);
    setPagination(DEFAULT_PAGINATION);
    fetchChipLabelPoints(DEFAULT_PAGINATION, chipIds);
  }, [chipIds, fetchChipLabelPoints]);

  const labelPointThumbnails = useMemo(
    () =>
      thumbnailImages.map((thumbnail) => ({
        ...thumbnail,
        renderOverlay: onRemove
          ? () => (
              <RemoveImageOverlay
                dimensions={dimensions}
                onClick={() => {
                  onRemove(
                    {
                      chipId: undefined,
                      labelPointId: thumbnail.id,
                    },
                    thumbnail
                  );
                }}
              />
            )
          : undefined,
      })),
    [dimensions, onRemove, thumbnailImages]
  );
  const allThumbnails = useMemo(() => {
    const chipThumbnails = resultsState.data.data.map(
      ({ id, x, y, radius, image, chipId }) => {
        if (!image) {
          return;
        }
        const padding = Math.ceil(
          radius * getPointDetectionThumbnailPaddingFactor(radius)
        );
        const imageCropDimensions = (radius + padding) * 2;
        const baseThumbnail = {
          id,
          url: image.url,
          x: Math.ceil(x - (radius + padding)),
          y: Math.ceil(y - (radius + padding)),
          width: Math.ceil(imageCropDimensions),
          height: Math.ceil(imageCropDimensions),
        };
        return {
          ...baseThumbnail,
          renderOverlay: onRemove
            ? () => (
                <RemoveImageOverlay
                  dimensions={dimensions}
                  onClick={() => {
                    onRemove(
                      {
                        chipId,
                        labelPointId: id,
                      },
                      baseThumbnail
                    );
                  }}
                />
              )
            : undefined,
        };
      }
    );
    return [...chipThumbnails, ...labelPointThumbnails];
  }, [dimensions, labelPointThumbnails, onRemove, resultsState.data.data]);

  return (
    <div className="flex flex-col">
      <p
        className="px-1 py-0.5 m-0 w-fit text-xs hidden md:block"
        style={{
          backgroundColor: category.color?.bg ?? theme.colors.carbon.gray.light,
          color: category.color?.text ?? theme.colors.white,
        }}
      >
        {category.name.toLocaleUpperCase()}
      </p>
      <div
        className="overflow-x-auto flex gap-2 whitespace-nowrap border-1 border-solid p-1"
        style={{
          borderColor: category.color?.bg ?? theme.colors.carbon.gray.light,
          minHeight: `${dimensions.height + 12}px`,
        }}
      >
        {isLoading &&
          Array.from({ length: chipIds.length })
            .fill(undefined)
            .map((_, index) => (
              <ImageWrapper dimensions={dimensions} key={`loading-${index}`}>
                <Skeleton
                  variant="rectangular"
                  width={dimensions.width}
                  height={dimensions.height}
                />
              </ImageWrapper>
            ))}
        {allThumbnails.map((thumbnail, index) => (
          <ImageWrapper
            dimensions={dimensions}
            key={`${thumbnail?.id}-${index}`}
          >
            <ThumbnailImage image={thumbnail} dimensions={dimensions} />
          </ImageWrapper>
        ))}
        {chipIds.length > 0 && !resultsState.areAllResultsLoaded && (
          <div className="flex items-center">
            <Tooltip title={t("utils.lists.loadMore")}>
              <span>
                <IconButton
                  className="text-carbon-orange"
                  size="small"
                  onClick={loadMore}
                  aria-label={t("utils.lists.loadMore")}
                >
                  <MoreIcon className="text-inherit" />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

interface ImageWrapperProps extends PropsWithChildren {
  dimensions: { width: number; height: number };
}
const ImageWrapper: FunctionComponent<ImageWrapperProps> = ({
  dimensions,
  children,
}) => (
  <div className="relative grow-0 shrink-0" style={dimensions}>
    {children}
  </div>
);
