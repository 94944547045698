import { Alert } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { capitalize } from "portal/utils/strings";
import { CarbonCard } from "portal/components/CarbonCard";
import { GlobalAllowedAlarm } from "protos/portal/admin";
import { ListManager } from "portal/components/ListManager";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import {
  useGetGlobalAlarmListsQuery,
  useSetGlobalAlarmListsMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { without } from "portal/utils/arrays";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent } from "react";

const _AdminAlarms: FunctionComponent = () => {
  const { t } = useTranslation();

  const { data: globalLists } = useQueryPopups(useGetGlobalAlarmListsQuery());
  const [setGlobalAlarmLists, { isLoading }] = useMutationPopups(
    useSetGlobalAlarmListsMutation(),
    {
      success: capitalize(
        t("utils.actions.savedLong", {
          subject: t("views.admin.alarms.lists"),
        })
      ),
    }
  );

  return (
    <Grid container className="w-full" spacing={2}>
      <CarbonCard
        title={t("views.admin.alarms.titleAllow")}
        error={false}
        success={true}
        content={
          <>
            <Alert severity="warning">
              {t("views.admin.alarms.allowWarning")}
            </Alert>
            <ListManager
              list={globalLists?.allowed?.alarms.map((alarm) => alarm.code)}
              loading={isLoading}
              onAdd={(newCode) => {
                setGlobalAlarmLists({
                  allowed: {
                    alarms: [
                      ...(globalLists?.allowed?.alarms ?? []),
                      GlobalAllowedAlarm.fromPartial({ code: newCode }),
                    ],
                  },
                });
              }}
              onRemove={(removedCode) => {
                setGlobalAlarmLists({
                  allowed: {
                    alarms: without(
                      globalLists?.allowed?.alarms,
                      GlobalAllowedAlarm.fromPartial({ code: removedCode }),
                      "code"
                    ),
                  },
                });
              }}
            />
          </>
        }
      />
    </Grid>
  );
};

export const AdminAlarms = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.admin_alarms,
        PermissionDomain.all
      ),
    ],
    _AdminAlarms
  )
);
