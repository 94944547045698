import { Avatar, Checkbox, FormControlLabel } from "@mui/material";
import {
  buildPermission,
  CarbonUser,
  getCustomerId,
  isActivated,
  isInternal,
} from "portal/utils/auth";
import { capitalize, titleCase } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { getUserPath } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Header } from "portal/components/header/Header";
import { InviteUser } from "portal/components/InviteUser";
import { isContractor, isStaff } from "portal/views/users/User";
import { Loading } from "portal/components/Loading";
import {
  LOCALSTORAGE_USERS_ACTIVATED,
  LOCALSTORAGE_USERS_CONTRACTORS,
  LOCALSTORAGE_USERS_STAFF,
} from "portal/utils/localStorage";
import { NoScroll, Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SearchField } from "portal/components/header/SearchField";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useListCustomersQuery,
  useListUsersQuery,
} from "portal/state/portalApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useCallback, useMemo } from "react";

const AVATAR_SIZE = 35;
const CELL_PADDING_CLASS = "leading-none py-4";

const defaultColumn: Partial<GridColDef> = {
  sortable: false,
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

const _UserList: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isInternal: isSelfInternal } = useSelf();

  const canReadCustomers = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);

  const {
    data: users,
    isLoading,
    isError,
    error,
  } = useQueryPopups(useListUsersQuery());
  const { data: customers, isSuccess: isCustomersSuccess } = useQueryPopups(
    useListCustomersQuery(undefined, { skip: !canReadCustomers }),
    { errorVariant: "warning" }
  );

  const userToCustomer = useCallback(
    (user: CarbonUser): string => {
      if (isInternal(user)) {
        return "Carbon Robotics";
      }
      const customer = customers?.find(
        (customer) => customer.db?.id === getCustomerId(user)
      );
      if (!customer) {
        return "";
      }
      return customer.name;
    },
    [customers]
  );

  // filters
  const [showStaff, setShowStaff] = useLocalStorage<boolean>(
    LOCALSTORAGE_USERS_STAFF,
    false
  );

  const [showContractors, setShowContractors] = useLocalStorage<boolean>(
    LOCALSTORAGE_USERS_CONTRACTORS,
    false
  );

  const [showActivated, setShowActivated] = useLocalStorage<boolean>(
    LOCALSTORAGE_USERS_ACTIVATED,
    true
  );

  const filteredUsers = useMemo<CarbonUser[]>(() => {
    if (!users) {
      return [];
    }
    return users.filter((user) => {
      if (!showStaff && isStaff(user)) {
        return false;
      } else if (!showContractors && isContractor(user)) {
        return false;
      } else if (!showActivated && !isStaff(user) && isActivated(user)) {
        return false;
      } else {
        return true;
      }
    });
  }, [showActivated, showContractors, showStaff, users]);

  const { searchText, setSearchText, results } = useFuzzySearch<CarbonUser>({
    items: filteredUsers,
    options: {
      keys: ["name", "email", { name: "customerName", getFn: userToCustomer }],
    },
  });

  const columns: GridColDef<CarbonUser>[] = [
    {
      ...defaultColumn,
      field: "picture",
      headerName: "",
      width: 50,
      cellClassName: () => "p-2",
      renderCell: ({ row: user }) => (
        <Avatar
          sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
          src={user.picture}
          alt={user.name}
        />
      ),
    },
    {
      ...defaultColumn,
      field: "name",
      headerName: t("models.users.fields.name"),
      valueGetter: (value, user) => (user.name === user.email ? "" : user.name),
    },
    {
      ...defaultColumn,
      field: "email",
      headerName: t("models.users.fields.email"),
    },
    {
      ...defaultColumn,
      field: "customer",
      headerName: titleCase(t("models.customers.customer_one")),
      valueGetter: (value, user) => userToCustomer(user),
    },
    {
      ...defaultColumn,
      field: "status",
      headerName: t("models.users.fields.status.name"),
      cellClassName: ({ row: user }) =>
        classes(CELL_PADDING_CLASS, {
          "font-bold text-yellow-500": !isActivated(user),
        }),
      valueGetter: (value, user) =>
        isActivated(user)
          ? t("models.users.activated")
          : t("models.users.fields.status.values.false"),
    },
  ];

  return (
    <>
      <GlobalHotKeys
        keyMap={
          isSelfInternal
            ? {
                TOGGLE_STAFF: {
                  name: t("utils.actions.toggle", {
                    subject: t("models.users.staff"),
                  }),
                  action: "keyup",
                  sequence: "s",
                },
                TOGGLE_CONTRACTORS: {
                  name: t("utils.actions.toggle", {
                    subject: t("views.users.toggleable.contractors"),
                  }),
                  action: "keyup",
                  sequence: "c",
                },
                TOGGLE_ACTIVATED: {
                  name: t("utils.actions.toggle", {
                    subject: t("models.users.activated"),
                  }),
                  action: "keyup",
                  sequence: "a",
                },
              }
            : {}
        }
        handlers={{
          TOGGLE_STAFF: () => setShowStaff(!showStaff),
          TOGGLE_CONTRACTORS: () => setShowContractors(!showContractors),
          TOGGLE_ACTIVATED: () => setShowActivated(!showActivated),
        }}
      />
      <Header title={titleCase(t("models.users.user_other"))}>
        <SearchField
          value={searchText}
          label={t("utils.actions.searchLong", {
            subject: capitalize(
              t("models.users.user", { count: users?.length ?? 0 })
            ),
          })}
          onChange={setSearchText}
          success={isCustomersSuccess}
        />
        <InviteUser hotkey="n" />
      </Header>
      <Page>
        <NoScroll>
          <CarbonDataGrid<CarbonUser>
            header={
              isSelfInternal ? (
                <div className="flex items-center justify-center md:justify-start flex-shrink-0 w-full">
                  <FormControlLabel
                    className="flex-shrink-0"
                    control={
                      <Checkbox
                        checked={showStaff}
                        color="default"
                        onChange={(event, checked) => setShowStaff(checked)}
                      />
                    }
                    label={t("utils.actions.showLong", {
                      subject: t("models.users.staff"),
                    })}
                  />
                  <FormControlLabel
                    className="flex-shrink-0"
                    control={
                      <Checkbox
                        checked={showContractors}
                        color="default"
                        onChange={(event, checked) =>
                          setShowContractors(checked)
                        }
                      />
                    }
                    label={t("utils.actions.showLong", {
                      subject: t("views.users.toggleable.contractors"),
                    })}
                  />
                  <FormControlLabel
                    className="flex-shrink-0"
                    control={
                      <Checkbox
                        checked={showActivated}
                        color="default"
                        onChange={(event, checked) => setShowActivated(checked)}
                      />
                    }
                    label={t("utils.actions.showLong", {
                      subject: t("models.users.activated"),
                    })}
                  />
                </div>
              ) : undefined
            }
            className="flex flex-1"
            rows={results}
            getRowId={(user) => user.userId ?? "UNKNOWN"}
            getRowClassName={() => "cursor-pointer"}
            hideFooter
            onRowClick={({ row: user }) => navigate(getUserPath(user.userId))}
            columns={columns}
            columnVisibilityModel={{
              customer: canReadCustomers,
              status: isSelfInternal,
            }}
            disableRowSelectionOnClick
            loading={isLoading}
          />
          <Loading
            loading={isLoading || isError}
            failed={isError}
            error={error}
          />
        </NoScroll>
      </Page>
    </>
  );
};

export const UserList = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.all
      ),
    ],
    _UserList
  )
);
