import { classes } from "portal/utils/theme";
import { IconButton, Slider, Tooltip } from "@mui/material";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ImageIcon from "@mui/icons-material/Image";
import React, { FunctionComponent, PropsWithChildren } from "react";

interface ImageSizeSliderProps {
  className?: string;
  value: number;
  onChange: (size: number) => void;
  max: number;
  min: number;
}
export const ImageSizeSlider: FunctionComponent<ImageSizeSliderProps> = ({
  className,
  value,
  onChange,
  max,
  min,
}) => {
  const { t } = useTranslation();
  const onValueChange = (_: Event, value: number | number[]): void => {
    onChange(value as number);
  };
  const step = 10;
  return (
    <div className={clsx("flex items-center gap-0.5", className)}>
      <ActionIconButton
        tooltip={titleCase(t("components.images.ImageSizeSlider.smaller"))}
        onClick={() => onChange(Math.max(value - step, min))}
      >
        <ImageIcon className="text-xs text-inherit" />
      </ActionIconButton>
      <Slider
        size="small"
        max={max}
        min={min}
        value={value}
        step={step}
        onChange={onValueChange}
      />
      <ActionIconButton
        tooltip={titleCase(t("components.images.ImageSizeSlider.larger"))}
        onClick={() => onChange(Math.min(value + step, max))}
      >
        <ImageIcon className="text-lg text-inherit" />
      </ActionIconButton>
    </div>
  );
};

interface ActionIconButtonProps extends PropsWithChildren {
  className?: string;
  tooltip: string;
  disabled?: boolean;
  onClick: () => void;
}
const ActionIconButton: FunctionComponent<ActionIconButtonProps> = ({
  className,
  tooltip,
  disabled,
  onClick,
  children,
}) => {
  const defaultClassnames = "w-6 h-6 text-inherit";
  return (
    <Tooltip
      title={tooltip}
      className={disabled ? undefined : "cursor-pointer"}
    >
      <span>
        <IconButton
          className={classes(className, defaultClassnames)}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};
