import { useCallback, useEffect, useRef } from "react";

export const useInfiniteScroll = <T extends HTMLElement>(
  isLoading: boolean,
  onLoadMore: () => void
): ((node: T | null) => void) => {
  const observer = useRef<IntersectionObserver | undefined>(undefined);

  const cleanup = (): void => {
    if (observer.current) {
      observer.current.disconnect();
      observer.current = undefined;
    }
  };

  useEffect(() => cleanup, []);

  return useCallback(
    (node: T | null) => {
      if (isLoading) {
        return;
      }
      cleanup();

      observer.current = new IntersectionObserver((entries) => {
        // this hook only supports monitoring one element
        if (entries[0]?.isIntersecting) {
          onLoadMore();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, onLoadMore]
  );
};
