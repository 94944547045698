// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/messages.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.messages";

export interface Message {
  db: DB | undefined;
  message: string;
  authorUserId: string;
  authorRobotId: number;
  recipientUserId: string;
  recipientCustomerId: number;
  recipientRobotId: number;
  authorUserEmail: string;
  authorUserName: string;
}

export interface MessagesResponse {
  page: number;
  limit: number;
  messages: Message[];
}

function createBaseMessage(): Message {
  return {
    db: undefined,
    message: "",
    authorUserId: "",
    authorRobotId: 0,
    recipientUserId: "",
    recipientCustomerId: 0,
    recipientRobotId: 0,
    authorUserEmail: "",
    authorUserName: "",
  };
}

export const Message: MessageFns<Message> = {
  encode(message: Message, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    if (message.authorUserId !== "") {
      writer.uint32(26).string(message.authorUserId);
    }
    if (message.authorRobotId !== 0) {
      writer.uint32(32).int64(message.authorRobotId);
    }
    if (message.recipientUserId !== "") {
      writer.uint32(42).string(message.recipientUserId);
    }
    if (message.recipientCustomerId !== 0) {
      writer.uint32(48).int64(message.recipientCustomerId);
    }
    if (message.recipientRobotId !== 0) {
      writer.uint32(56).int64(message.recipientRobotId);
    }
    if (message.authorUserEmail !== "") {
      writer.uint32(66).string(message.authorUserEmail);
    }
    if (message.authorUserName !== "") {
      writer.uint32(74).string(message.authorUserName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Message {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authorUserId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.authorRobotId = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.recipientUserId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.recipientCustomerId = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.recipientRobotId = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.authorUserEmail = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.authorUserName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      authorUserId: isSet(object.authorUserId) ? globalThis.String(object.authorUserId) : "",
      authorRobotId: isSet(object.authorRobotId) ? globalThis.Number(object.authorRobotId) : 0,
      recipientUserId: isSet(object.recipientUserId) ? globalThis.String(object.recipientUserId) : "",
      recipientCustomerId: isSet(object.recipientCustomerId) ? globalThis.Number(object.recipientCustomerId) : 0,
      recipientRobotId: isSet(object.recipientRobotId) ? globalThis.Number(object.recipientRobotId) : 0,
      authorUserEmail: isSet(object.authorUserEmail) ? globalThis.String(object.authorUserEmail) : "",
      authorUserName: isSet(object.authorUserName) ? globalThis.String(object.authorUserName) : "",
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.authorUserId !== "") {
      obj.authorUserId = message.authorUserId;
    }
    if (message.authorRobotId !== 0) {
      obj.authorRobotId = Math.round(message.authorRobotId);
    }
    if (message.recipientUserId !== "") {
      obj.recipientUserId = message.recipientUserId;
    }
    if (message.recipientCustomerId !== 0) {
      obj.recipientCustomerId = Math.round(message.recipientCustomerId);
    }
    if (message.recipientRobotId !== 0) {
      obj.recipientRobotId = Math.round(message.recipientRobotId);
    }
    if (message.authorUserEmail !== "") {
      obj.authorUserEmail = message.authorUserEmail;
    }
    if (message.authorUserName !== "") {
      obj.authorUserName = message.authorUserName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.message = object.message ?? "";
    message.authorUserId = object.authorUserId ?? "";
    message.authorRobotId = object.authorRobotId ?? 0;
    message.recipientUserId = object.recipientUserId ?? "";
    message.recipientCustomerId = object.recipientCustomerId ?? 0;
    message.recipientRobotId = object.recipientRobotId ?? 0;
    message.authorUserEmail = object.authorUserEmail ?? "";
    message.authorUserName = object.authorUserName ?? "";
    return message;
  },
};

function createBaseMessagesResponse(): MessagesResponse {
  return { page: 0, limit: 0, messages: [] };
}

export const MessagesResponse: MessageFns<MessagesResponse> = {
  encode(message: MessagesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.page !== 0) {
      writer.uint32(16).int64(message.page);
    }
    if (message.limit !== 0) {
      writer.uint32(24).int64(message.limit);
    }
    for (const v of message.messages) {
      Message.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MessagesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.page = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.messages.push(Message.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessagesResponse {
    return {
      page: isSet(object.page) ? globalThis.Number(object.page) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => Message.fromJSON(e)) : [],
    };
  },

  toJSON(message: MessagesResponse): unknown {
    const obj: any = {};
    if (message.page !== 0) {
      obj.page = Math.round(message.page);
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => Message.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessagesResponse>, I>>(base?: I): MessagesResponse {
    return MessagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessagesResponse>, I>>(object: I): MessagesResponse {
    const message = createBaseMessagesResponse();
    message.page = object.page ?? 0;
    message.limit = object.limit ?? 0;
    message.messages = object.messages?.map((e) => Message.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
