import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import { buildPermission } from "portal/utils/auth";
import { capitalize, titleCase } from "portal/utils/strings";
import { carbon, classes } from "portal/utils/theme";
import { ReactComponent as CarbonLogo } from "portal/images/logo_ops_center.svg";
import { ReactComponent as ConfluenceIcon } from "portal/images/icons/confluence.svg";
import { Drawer, NavItem } from "../Drawer";
import { ReactComponent as FarmIcon } from "portal/images/icons/farm.svg";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { ReactComponent as FleetIcon } from "portal/images/icons/slayer_side_outlined.svg";
import { GlobalHotKeys, GlobalHotKeysProps, KeyMap } from "react-hotkeys";
import { GROUP_NAVIGATION } from "portal/utils/hotkeys";
import { Helmet } from "react-helmet";
import { isUndefined } from "portal/utils/identity";
import { ReactComponent as JiraIcon } from "portal/images/icons/jira.svg";
import { Link, useNavigate } from "react-router-dom";
import { LOCALSTORAGE_DRAWER_EXPANDED } from "portal/utils/localStorage";
import { Path } from "portal/utils/routing";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import { WithSkeleton } from "../WithSkeleton";
import AdminSettingsIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CloseMenuIcon from "@mui/icons-material/MenuOpenOutlined";
import ExternalIcon from "@mui/icons-material/OpenInNewOutlined";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, ReactNode } from "react";
import ReportIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import SupportIcon from "@mui/icons-material/HelpCenterOutlined";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import UsersIcon from "@mui/icons-material/GroupsOutlined";

interface Props {
  children?: ReactNode;
  hideDrawer?: boolean;
  hideMascot?: boolean;
  hideTitle?: boolean;
  parentLink?: string;
  subtitle?: string;
  title?: string;
  pageTitle?: string;
}

const _Header: FunctionComponent<Props> = ({
  children,
  hideDrawer = false,
  hideTitle = false,
  parentLink,
  subtitle,
  title,
  pageTitle,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();
  const isSmall = breakpoint <= Breakpoint.sm;

  const [isExpanded, setExpanded] = useLocalStorage<boolean>(
    LOCALSTORAGE_DRAWER_EXPANDED,
    false
  );

  const keyHandlers: GlobalHotKeysProps["handlers"] = {
    GO_SETTINGS: () => navigate(Path.SETTINGS),
    GO_ADMIN: () => navigate(Path.ADMIN),
    GO_CUSTOMERS: () => navigate(Path.CUSTOMERS),
    GO_FLEET: () => navigate(Path.FLEET),
    GO_KNOWLEDGE: () => navigate(Path.KNOWLEDGE),
    GO_OFFLINE: () => navigate(Path.OFFLINE),
    GO_REPORTS: () => navigate(Path.REPORTS),
    GO_USERS: () => navigate(Path.USERS),
  };
  const keyMap: KeyMap = {};
  const drawerItems: NavItem[] = [];

  // settings
  const isSettingsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.portal_settings,
      PermissionDomain.self
    ),
  ]);
  if (isSettingsAuthorized) {
    drawerItems.push({
      label: t("views.settings.title"),
      href: Path.SETTINGS,
      icon: <SettingsIcon />,
    });

    keyMap.GO_SETTINGS = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: t("views.settings.title"),
      }),
      action: "keyup",
      sequence: "g s",
    };
  }

  // fleet
  const isRobotsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.robots,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.robots,
      PermissionDomain.customer
    ),
  ]);
  if (isRobotsAuthorized) {
    drawerItems.push({
      label: t("views.fleet.title"),
      icon: <FleetIcon />,
      href: Path.FLEET,
    });
    keyMap.GO_FLEET = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.title"),
      }),
      action: "keyup",
      sequence: "g f",
    };
  }

  // reports
  const { isEnabled: hasReports } = useFeatureFlag(FeatureFlag.REPORTS);
  const isReportsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.reports,
      PermissionDomain.self
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.reports,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.reports,
      PermissionDomain.customer
    ),
  ]);
  if (hasReports && isReportsAuthorized) {
    drawerItems.push({
      label: titleCase(t("models.reports.report_other")),
      icon: <ReportIcon />,
      href: Path.REPORTS,
    });
    keyMap.GO_REPORTS = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: capitalize(t("models.reports.report_other")),
      }),
      action: "keyup",
      sequence: "g r",
    };
  }

  // users
  const isUsersAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.users,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.users,
      PermissionDomain.customer
    ),
  ]);
  if (isUsersAuthorized) {
    drawerItems.push({
      label: titleCase(t("models.users.user_other")),
      icon: <UsersIcon />,
      href: Path.USERS,
    });
    keyMap.GO_USERS = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: capitalize(t("models.users.user_other")),
      }),
      action: "keyup",
      sequence: "g u",
    };
  }

  // customers
  const isCustomersAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);
  if (isCustomersAuthorized) {
    drawerItems.push({
      label: titleCase(t("models.customers.customer_other")),
      icon: <FarmIcon />,
      href: Path.CUSTOMERS,
    });
    keyMap.GO_CUSTOMERS = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: capitalize(t("models.customers.customer_other")),
      }),
      action: "keyup",
      sequence: "g c",
    };
  }

  // knowledge
  const isGuidesAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.guides,
      PermissionDomain.all
    ),
  ]);
  if (isGuidesAuthorized) {
    drawerItems.push({
      label: t("views.knowledge.title"),
      icon: <SupportIcon />,
      href: Path.KNOWLEDGE,
    });
    keyMap.GO_KNOWLEDGE = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: t("views.knowledge.title"),
      }),
      action: "keyup",
      sequence: "g k",
    };
  }

  // offline
  const isOfflineAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.uploads,
      PermissionDomain.self
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.uploads,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.uploads,
      PermissionDomain.all
    ),
  ]);
  if (isOfflineAuthorized) {
    drawerItems.push({
      label: t("views.offline.title"),
      icon: <UploadIcon />,
      href: Path.OFFLINE,
    });
    keyMap.GO_OFFLINE = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: t("views.offline.title"),
      }),
      action: "keyup",
      sequence: "g o",
    };
  }

  // admin
  const isAdminAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_alarms,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.templates
    ),
  ]);
  if (isAdminAuthorized) {
    drawerItems.push({
      label: t("views.admin.title"),
      icon: <AdminSettingsIcon />,
      href: Path.ADMIN,
    });
    keyMap.GO_ADMIN = {
      group: GROUP_NAVIGATION,
      name: t("utils.actions.goToLong", {
        subject: t("views.admin.title"),
      }),
      action: "keyup",
      sequence: "g d",
    };
  }

  // shortcuts
  const isShortcutsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.shortcuts_internal,
      PermissionDomain.all
    ),
  ]);
  if (isShortcutsAuthorized) {
    drawerItems.push(
      {
        label: (
          // not need to localize proper noun
          // eslint-disable-next-line i18next/no-literal-string
          <div className="flex items-center">
            Jira <ExternalIcon className="ml-2 text-sm text-lighten-500" />
          </div>
        ),
        icon: <JiraIcon />,
        href: "https://carbonrobotics.atlassian.net/jira/your-work",
      },
      {
        label: (
          // not need to localize proper noun
          // eslint-disable-next-line i18next/no-literal-string
          <div className="flex items-center">
            Confluence{" "}
            <ExternalIcon className="ml-2 text-sm text-lighten-500" />
          </div>
        ),
        icon: <ConfluenceIcon />,
        href: "https://carbonrobotics.atlassian.net/wiki/spaces/Home/overview",
      }
    );
  }

  if (!pageTitle) {
    pageTitle = title;
    if (subtitle) {
      pageTitle += ` ${subtitle}`;
    }
  }

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={keyHandlers} />
      {title && (
        <Helmet>
          <title>
            {/* no need to localize proper nouns */}
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {pageTitle} - Carbon Robotics
          </title>
        </Helmet>
      )}
      <AppBar
        position="fixed"
        className={classes("items-center print:hidden")}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          className="w-full flex"
          style={{ maxWidth: carbon.breakpoints.values.xl }}
        >
          {!isSmall && (
            <Link to={Path.FLEET} className="2xl:fixed 2xl:top-4 2xl:left-4">
              <CarbonLogo className="h-8 mr-4" />
            </Link>
          )}
          {!hideDrawer && isSmall && (
            <IconButton
              className="flex-shrink-0"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? <CloseMenuIcon /> : <MenuIcon />}
            </IconButton>
          )}

          {parentLink && (
            <IconButton
              className="flex-shrink-0"
              edge="start"
              color="inherit"
              aria-label="back to parent"
              component={Link}
              to={parentLink}
            >
              <ParentIcon />
            </IconButton>
          )}
          <WithSkeleton
            variant="rectangular"
            className="min-h-6 mr-4 whitespace-nowrap text-ellipsis overflow-hidden min-w-10 flex-shrink"
            success={!isUndefined(title)}
          >
            {hideTitle ? (
              <></>
            ) : (
              <Typography
                variant="h1"
                color="inherit"
                component="div"
                className="text-lg"
              >
                {title}
              </Typography>
            )}
          </WithSkeleton>
          <div className="flex items-center justify-between gap-4 flex-1 overflow-hidden self-stretch">
            {children}
          </div>
        </Toolbar>
      </AppBar>
      {!hideDrawer && <Drawer items={drawerItems} />}
    </>
  );
};

export const Header = withErrorBoundary(
  {
    i18nKey: "components.header.failed",
    small: true,
  },
  _Header
);
