// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: cv/runtime/cv_runtime.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { LightSourcePreset, lightSourcePresetFromJSON, lightSourcePresetToJSON } from "../../lib/common/camera/camera";
import { Detection } from "../../weed_tracking/weed_tracking";
import { P2PCaptureReason, p2PCaptureReasonFromJSON, p2PCaptureReasonToJSON } from "../cv";

export const protobufPackage = "cv.runtime.proto";

export enum BufferUseCase {
  P2P = 0,
  /** OpticalFlow - Deprecated */
  OpticalFlow = 1,
  Predict = 2,
  Drive = 3,
  UNRECOGNIZED = -1,
}

export function bufferUseCaseFromJSON(object: any): BufferUseCase {
  switch (object) {
    case 0:
    case "P2P":
      return BufferUseCase.P2P;
    case 1:
    case "OpticalFlow":
      return BufferUseCase.OpticalFlow;
    case 2:
    case "Predict":
      return BufferUseCase.Predict;
    case 3:
    case "Drive":
      return BufferUseCase.Drive;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BufferUseCase.UNRECOGNIZED;
  }
}

export function bufferUseCaseToJSON(object: BufferUseCase): string {
  switch (object) {
    case BufferUseCase.P2P:
      return "P2P";
    case BufferUseCase.OpticalFlow:
      return "OpticalFlow";
    case BufferUseCase.Predict:
      return "Predict";
    case BufferUseCase.Drive:
      return "Drive";
    case BufferUseCase.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DetectionClass {
  ORGANICS = 0,
  DRIPTAPE = 1,
  SPINACH = 2,
  STICK_RED = 3,
  STICK_YELLOW = 4,
  ONION = 5,
  CROWN = 6,
  GRASS = 7,
  FURROW = 8,
  PURSLANE = 9,
  HORSETAIL = 10,
  KOCHIA = 11,
  LAMBSQUARTER = 12,
  MALLOW = 13,
  NUTSEDGE = 14,
  LEAFY_WEED = 15,
  INFRASTRUCTURE = 16,
  LONDON_ROCKET = 17,
  BABY_WEED = 18,
  UNRECOGNIZED = -1,
}

export function detectionClassFromJSON(object: any): DetectionClass {
  switch (object) {
    case 0:
    case "ORGANICS":
      return DetectionClass.ORGANICS;
    case 1:
    case "DRIPTAPE":
      return DetectionClass.DRIPTAPE;
    case 2:
    case "SPINACH":
      return DetectionClass.SPINACH;
    case 3:
    case "STICK_RED":
      return DetectionClass.STICK_RED;
    case 4:
    case "STICK_YELLOW":
      return DetectionClass.STICK_YELLOW;
    case 5:
    case "ONION":
      return DetectionClass.ONION;
    case 6:
    case "CROWN":
      return DetectionClass.CROWN;
    case 7:
    case "GRASS":
      return DetectionClass.GRASS;
    case 8:
    case "FURROW":
      return DetectionClass.FURROW;
    case 9:
    case "PURSLANE":
      return DetectionClass.PURSLANE;
    case 10:
    case "HORSETAIL":
      return DetectionClass.HORSETAIL;
    case 11:
    case "KOCHIA":
      return DetectionClass.KOCHIA;
    case 12:
    case "LAMBSQUARTER":
      return DetectionClass.LAMBSQUARTER;
    case 13:
    case "MALLOW":
      return DetectionClass.MALLOW;
    case 14:
    case "NUTSEDGE":
      return DetectionClass.NUTSEDGE;
    case 15:
    case "LEAFY_WEED":
      return DetectionClass.LEAFY_WEED;
    case 16:
    case "INFRASTRUCTURE":
      return DetectionClass.INFRASTRUCTURE;
    case 17:
    case "LONDON_ROCKET":
      return DetectionClass.LONDON_ROCKET;
    case 18:
    case "BABY_WEED":
      return DetectionClass.BABY_WEED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DetectionClass.UNRECOGNIZED;
  }
}

export function detectionClassToJSON(object: DetectionClass): string {
  switch (object) {
    case DetectionClass.ORGANICS:
      return "ORGANICS";
    case DetectionClass.DRIPTAPE:
      return "DRIPTAPE";
    case DetectionClass.SPINACH:
      return "SPINACH";
    case DetectionClass.STICK_RED:
      return "STICK_RED";
    case DetectionClass.STICK_YELLOW:
      return "STICK_YELLOW";
    case DetectionClass.ONION:
      return "ONION";
    case DetectionClass.CROWN:
      return "CROWN";
    case DetectionClass.GRASS:
      return "GRASS";
    case DetectionClass.FURROW:
      return "FURROW";
    case DetectionClass.PURSLANE:
      return "PURSLANE";
    case DetectionClass.HORSETAIL:
      return "HORSETAIL";
    case DetectionClass.KOCHIA:
      return "KOCHIA";
    case DetectionClass.LAMBSQUARTER:
      return "LAMBSQUARTER";
    case DetectionClass.MALLOW:
      return "MALLOW";
    case DetectionClass.NUTSEDGE:
      return "NUTSEDGE";
    case DetectionClass.LEAFY_WEED:
      return "LEAFY_WEED";
    case DetectionClass.INFRASTRUCTURE:
      return "INFRASTRUCTURE";
    case DetectionClass.LONDON_ROCKET:
      return "LONDON_ROCKET";
    case DetectionClass.BABY_WEED:
      return "BABY_WEED";
    case DetectionClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HitClass {
  WEED = 0,
  CROP = 1,
  PLANT = 2,
  UNRECOGNIZED = -1,
}

export function hitClassFromJSON(object: any): HitClass {
  switch (object) {
    case 0:
    case "WEED":
      return HitClass.WEED;
    case 1:
    case "CROP":
      return HitClass.CROP;
    case 2:
    case "PLANT":
      return HitClass.PLANT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HitClass.UNRECOGNIZED;
  }
}

export function hitClassToJSON(object: HitClass): string {
  switch (object) {
    case HitClass.WEED:
      return "WEED";
    case HitClass.CROP:
      return "CROP";
    case HitClass.PLANT:
      return "PLANT";
    case HitClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MaskExpressions {
  exprs: MaskExpression[];
}

export interface TargetSafetyZone {
  up: number;
  down: number;
  left: number;
  right: number;
}

export interface P2PContext {
  predictCamId: string;
  predictTimestampMs: number;
  predictCoordX: number;
  predictCoordY: number;
}

export interface SetP2PContextRequest {
  targetCamId: string;
  primaryContext: P2PContext | undefined;
  secondaryContext?: P2PContext | undefined;
  safetyZone: TargetSafetyZone | undefined;
}

export interface SetP2PContextResponse {
}

export interface GetBufferNameRequest {
  camId: string;
  useCase: BufferUseCase;
}

export interface GetBufferNameResponse {
  bufferName: string;
}

export interface GetCameraDimensionsRequest {
  camId: string;
}

export interface GetCameraDimensionsResponse {
  width: number;
  height: number;
  transpose: boolean;
}

export interface StartP2PDataCaptureRequest {
  targetCamId: string;
  captureMissRate: number;
  captureSuccessRate: number;
  captureEnabled: boolean;
  capturePath: string;
  afterTimestampMs: number;
}

export interface StartP2PDataCaptureResponse {
}

export interface StopP2PDataCaptureRequest {
  targetCamId: string;
}

export interface StopP2PDataCaptureResponse {
}

export interface PointDetectionCategory {
  threshold: number;
  category: string;
}

export interface SegmentationDetectionCategory {
  threshold: number;
  category: string;
  safetyRadiusIn: number;
}

export interface DeepweedDetectionCriteriaSetting {
  /** v1 */
  pointCategories: PointDetectionCategory[];
  /** v2 */
  weedPointThreshold: number;
  cropPointThreshold: number;
  segmentationCategories: SegmentationDetectionCategory[];
}

export interface SetDeepweedDetectionCriteriaRequest {
  weedPointThreshold: number;
  cropPointThreshold: number;
  pointCategories: PointDetectionCategory[];
  segmentationCategories: SegmentationDetectionCategory[];
}

export interface SetDeepweedDetectionCriteriaResponse {
}

export interface GetDeepweedDetectionCriteriaRequest {
}

export interface GetDeepweedDetectionCriteriaResponse {
  weedPointThreshold: number;
  cropPointThreshold: number;
  pointCategories: PointDetectionCategory[];
  segmentationCategories: SegmentationDetectionCategory[];
}

export interface GetDeepweedSupportedCategoriesRequest {
  camId?: string | undefined;
}

export interface GetDeepweedSupportedCategoriesResponse {
  segmentationCategories: string[];
  pointCategories: string[];
}

export interface SetDeeplabMaskExpressionsRequest {
  camId: string;
  useCase: BufferUseCase;
  exprs: MaskExpression[];
}

export interface SetDeeplabMaskExpressionsResponse {
}

export interface GetDeepweedIndexToCategoryRequest {
  camId: string;
}

export interface GetDeepweedIndexToCategoryResponse {
  weedPointIndexToCategory: { [key: number]: string };
  cropPointIndexToCategory: { [key: number]: string };
  intersectionIndexToCategory: { [key: number]: string };
}

export interface GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
  key: number;
  value: string;
}

export interface GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
  key: number;
  value: string;
}

export interface GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
  key: number;
  value: string;
}

export interface GetPredictCamMatrixRequest {
  predictCamId: string;
}

export interface GetPredictCamDistortionCoefficientsRequest {
  predictCamId: string;
}

export interface SetCameraSettingsRequest {
  camIds: string[];
  exposureUs?: number | undefined;
  gamma?: number | undefined;
  gainDb?: number | undefined;
  lightSourcePreset?: LightSourcePreset | undefined;
  wbRatioRed?: number | undefined;
  wbRatioGreen?: number | undefined;
  wbRatioBlue?: number | undefined;
  roiOffsetX?: number | undefined;
  roiOffsetY?: number | undefined;
  mirror?: boolean | undefined;
  flip?: boolean | undefined;
  strobing?: boolean | undefined;
  ptp?: boolean | undefined;
  autoWhitebalance?: boolean | undefined;
}

export interface SetCameraSettingsResponse {
  camIds: string[];
}

export interface SetAutoWhitebalanceRequest {
  enable: boolean;
  camIds: string[];
}

export interface SetAutoWhitebalanceResponse {
}

export interface GetCameraSettingsRequest {
  camIds: string[];
}

export interface CameraSettingsResponse {
  camId: string;
  exposureUs?: number | undefined;
  gamma?: number | undefined;
  gainDb?: number | undefined;
  lightSourcePreset?: LightSourcePreset | undefined;
  wbRatioRed?: number | undefined;
  wbRatioGreen?: number | undefined;
  wbRatioBlue?: number | undefined;
  roiWidth?: number | undefined;
  roiHeight?: number | undefined;
  roiOffsetX?: number | undefined;
  roiOffsetY?: number | undefined;
  gpuId?: number | undefined;
  mirror: boolean;
  flip: boolean;
  strobing: boolean;
  ptp: boolean;
  autoWhitebalance?: boolean | undefined;
}

export interface GetCameraSettingsResponse {
  cameraSettingsResponse: CameraSettingsResponse[];
}

export interface StartBurstRecordFramesRequest {
  camId: string;
  durationMs: number;
  path: string;
  dontCapturePredictImage: boolean;
  downsampleFactor: number;
}

export interface StartBurstRecordFramesResponse {
}

export interface StopBurstRecordFramesRequest {
  camId: string;
  lastFrameTimestampMs?: number | undefined;
}

export interface StopBurstRecordFramesResponse {
}

export interface P2POutputProto {
  matched: boolean;
  targetCoordX: number;
  targetCoordY: number;
  targetTimestampMs: number;
  predictTimestampMs: number;
  safe: boolean;
}

export interface GetNextP2POutputRequest {
  bufferName: string;
  timestampMs: number;
  timeoutMs: number;
}

export interface GetConnectorsRequest {
  camIds: string[];
  connectorIds: string[];
}

export interface ConnectorResponse {
  camId: string;
  connectorId: string;
  isEnabled: boolean;
  reductionRatio: number;
}

export interface GetConnectorsResponse {
  connectorResponse: ConnectorResponse[];
}

export interface SetConnectorsRequest {
  camIds: string[];
  connectorIds: string[];
  isEnabled?: boolean | undefined;
  reductionRatio?: number | undefined;
}

export interface SetConnectorsResponse {
}

export interface NodeTiming {
  name: string;
  fpsMean: number;
  fps99pct: number;
  latencyMsMean: number;
  latencyMs99pct: number;
  state: string;
  stateTimings: { [key: string]: number };
}

export interface NodeTiming_StateTimingsEntry {
  key: string;
  value: number;
}

export interface GetTimingRequest {
}

export interface GetTimingResponse {
  nodeTiming: NodeTiming[];
}

export interface PredictRequest {
  camId: string;
  filePaths: string[];
  timestampsMs: number[];
}

export interface PredictResponse {
}

export interface LoadAndQueueRequest {
  camId: string;
  filePaths: string[];
  timestampsMs: number[];
}

export interface LoadAndQueueResponse {
}

export interface SetImageRequest {
  camId: string;
  filePath: string;
}

export interface SetImageResponse {
}

export interface UnsetImageRequest {
  camId: string;
}

export interface UnsetImageResponse {
}

export interface GetModelPathsRequest {
}

export interface GetModelPathsResponse {
  p2p?: string | undefined;
  deepweed?: string | undefined;
  furrows?: string | undefined;
}

export interface GetCameraTemperaturesRequest {
}

export interface CameraTemperature {
  camId: string;
  temperature: number;
}

export interface GetCameraTemperaturesResponse {
  temperature: CameraTemperature[];
}

export interface GeoLLA {
  lat?: number | undefined;
  lng?: number | undefined;
  alt?: number | undefined;
  timestampMs?: number | undefined;
}

export interface GeoECEF {
  x?: number | undefined;
  y?: number | undefined;
  z?: number | undefined;
  timestampMs?: number | undefined;
}

export interface SetGPSLocationRequest {
  lla: GeoLLA | undefined;
  ecef: GeoECEF | undefined;
}

export interface SetGPSLocationResponse {
}

export interface SetImplementStatusRequest {
  lifted: boolean;
  estopped: boolean;
}

export interface SetImplementStatusResponse {
}

export interface MaskExpression {
  category?: CategoryExpression | undefined;
  dilate?: DilateExpression | undefined;
  erode?:
    | ErodeExpression
    | undefined;
  /** Union is a reserved keyword in C */
  unionExpr?: UnionExpression | undefined;
  intersect?: IntersectExpression | undefined;
  negate?: NegateExpression | undefined;
  line?: LineExpression | undefined;
  allLines?: AllLinesExpression | undefined;
}

export interface CategoryExpression {
  category: string;
  threshold: number;
}

export interface LineExpression {
  category: string;
  x: number;
  y: number;
}

export interface AllLinesExpression {
  category: string;
}

export interface DilateExpression {
  expr: MaskExpression | undefined;
  size: number;
  granularity: number;
}

export interface ErodeExpression {
  expr: MaskExpression | undefined;
  size: number;
  granularity: number;
}

export interface UnionExpression {
  exprs: MaskExpression[];
}

export interface IntersectExpression {
  exprs: MaskExpression[];
}

export interface NegateExpression {
  expr: MaskExpression | undefined;
}

export interface SetImageScoreRequest {
  score: number;
  timestampMs: number;
  camId: string;
  deepweedDetections: DeepweedDetection[];
}

export interface SetImageScoreResponse {
}

export interface GetScoreQueueRequest {
  scoreType: string;
}

export interface ScoreObject {
  score: number;
  timestampMs: number;
  camId: string;
}

export interface GetScoreQueueResponse {
  scoreObject: ScoreObject[];
}

export interface GetMaxImageScoreRequest {
  scoreType: string;
}

export interface GetMaxImageScoreResponse {
  score: number;
  type: string;
}

export interface GetMaxScoredImageRequest {
  scoreType: string;
}

export interface GetLatestP2PImageRequest {
  /** @deprecated */
  scoreType: string;
  reason: P2PCaptureReason;
}

export interface GetLatestImageRequest {
  camId: string;
}

export interface FlushQueuesRequest {
  scoreType: string[];
}

export interface FlushQueuesResponse {
}

export interface ImageAndMetadataResponse {
  bytes: Uint8Array;
  width: number;
  height: number;
  timestampMs: number;
  score: number;
  camId: string;
  isoFormattedTime: string;
  llaLat: number;
  llaLng: number;
  llaAlt: number;
  llaTimestampMs: number;
  ecefX: number;
  ecefY: number;
  ecefZ: number;
  ecefTimestampMs: number;
  ppi: number;
  scoreType?: string | undefined;
  imageType: string;
  modelUrl: string;
  crop: string;
  weedHeightColumns: number[];
  cropHeightColumns: number[];
  bbhOffsetMm: number;
  focusMetric: number;
  exposureUs: number;
  cropPointThreshold: number;
  weedPointThreshold: number;
  weedingEnabled: boolean;
  thinningEnabled: boolean;
  deepweedId: string;
  p2pId: string;
  deepweedDetections: Detection[];
  segmentationThreshold: number;
  simulatorGenerated: boolean;
}

export interface P2PImageAndMetadataResponse {
  targetBytes: Uint8Array;
  targetWidth: number;
  targetHeight: number;
  perspectiveBytes: Uint8Array;
  perspectiveWidth: number;
  perspectiveHeight: number;
  annotatedTargetBytes: Uint8Array;
  annotatedTargetWidth: number;
  annotatedTargetHeight: number;
  timestampMs: number;
  score: number;
  camId: string;
  isoFormattedTime: string;
  llaLat: number;
  llaLng: number;
  llaAlt: number;
  llaTimestampMs: number;
  ecefX: number;
  ecefY: number;
  ecefZ: number;
  ecefTimestampMs: number;
  ppi: number;
  perspectivePpi: number;
  imageType: string;
  modelUrl: string;
  crop: string;
  focusMetric: number;
  exposureUs: number;
  weedingEnabled: boolean;
  thinningEnabled: boolean;
  deepweedId: string;
  p2pId: string;
}

export interface GetCameraInfoRequest {
}

export interface CameraInfo {
  camId: string;
  ipAddress?: string | undefined;
  serialNumber?: string | undefined;
  model: string;
  width: number;
  height: number;
  connected: boolean;
  linkSpeed: number;
}

export interface GetCameraInfoResponse {
  cameraInfo: CameraInfo[];
}

export interface GetLightweightBurstRecordRequest {
}

export interface GetLightweightBurstRecordResponse {
  zipFile: Uint8Array;
  metadataFile: Uint8Array;
}

export interface GetBootedRequest {
}

export interface GetBootedResponse {
  booted: boolean;
}

export interface GetReadyRequest {
}

export interface GetReadyResponse {
  ready: boolean;
}

export interface DeepweedDetection {
  x: number;
  y: number;
  size: number;
  score: number;
  detectionClassScores: { [key: string]: number };
  hitClass: HitClass;
  maskIntersections: string[];
  weedScore: number;
  cropScore: number;
}

export interface DeepweedDetection_DetectionClassScoresEntry {
  key: string;
  value: number;
}

export interface DeepweedOutput {
  detections: DeepweedDetection[];
  maskWidth: number;
  maskHeight: number;
  maskChannels: number;
  mask: number[];
  maskChannelClasses: string[];
  predictInDistanceBuffer: boolean;
  timestampMs: number;
}

export interface GetDeepweedOutputByTimestampRequest {
  camId: string;
  timestampMs: number;
}

export interface GetRecommendedStrobeSettingsRequest {
}

export interface GetRecommendedStrobeSettingsResponse {
  targetCameraFps: number;
  targetsPerPredictRatio: number;
}

/** @deprecated */
export interface StartP2PBufferringRequest {
  camId: string;
}

/** @deprecated */
export interface StartP2PBufferringResponse {
}

/** @deprecated */
export interface StopP2PBufferringRequest {
  saveBurst: boolean;
  camId: string;
  path: string;
  dontCapturePredictImage: boolean;
  startTimestampMs: number;
  endTimestampMs: number;
}

/** @deprecated */
export interface StopP2PBufferringResponse {
}

export interface P2PCaptureRequest {
  camId: string;
  name: string;
  timestampMs: number;
  writeToDisk: boolean;
  reason: P2PCaptureReason;
}

export interface P2PCaptureResponse {
}

export interface P2PBufferringBurstCaptureRequest {
  camId: string;
  path: string;
  dontCapturePredictImage: boolean;
  startTimestampMs: number;
  endTimestampMs: number;
}

export interface P2PBufferringBurstCaptureResponse {
}

export interface GetNextDeepweedOutputRequest {
  timestampMs: number;
  bufferName: string;
  timeoutMs: number;
}

export interface SetTargetingStateRequest {
  weedingEnabled: boolean;
  thinningEnabled: boolean;
}

export interface SetTargetingStateResponse {
}

function createBaseMaskExpressions(): MaskExpressions {
  return { exprs: [] };
}

export const MaskExpressions: MessageFns<MaskExpressions> = {
  encode(message: MaskExpressions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.exprs) {
      MaskExpression.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MaskExpressions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMaskExpressions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exprs.push(MaskExpression.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MaskExpressions {
    return {
      exprs: globalThis.Array.isArray(object?.exprs) ? object.exprs.map((e: any) => MaskExpression.fromJSON(e)) : [],
    };
  },

  toJSON(message: MaskExpressions): unknown {
    const obj: any = {};
    if (message.exprs?.length) {
      obj.exprs = message.exprs.map((e) => MaskExpression.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MaskExpressions>, I>>(base?: I): MaskExpressions {
    return MaskExpressions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MaskExpressions>, I>>(object: I): MaskExpressions {
    const message = createBaseMaskExpressions();
    message.exprs = object.exprs?.map((e) => MaskExpression.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTargetSafetyZone(): TargetSafetyZone {
  return { up: 0, down: 0, left: 0, right: 0 };
}

export const TargetSafetyZone: MessageFns<TargetSafetyZone> = {
  encode(message: TargetSafetyZone, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.up !== 0) {
      writer.uint32(13).float(message.up);
    }
    if (message.down !== 0) {
      writer.uint32(21).float(message.down);
    }
    if (message.left !== 0) {
      writer.uint32(29).float(message.left);
    }
    if (message.right !== 0) {
      writer.uint32(37).float(message.right);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TargetSafetyZone {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetSafetyZone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.up = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.down = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.left = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.right = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetSafetyZone {
    return {
      up: isSet(object.up) ? globalThis.Number(object.up) : 0,
      down: isSet(object.down) ? globalThis.Number(object.down) : 0,
      left: isSet(object.left) ? globalThis.Number(object.left) : 0,
      right: isSet(object.right) ? globalThis.Number(object.right) : 0,
    };
  },

  toJSON(message: TargetSafetyZone): unknown {
    const obj: any = {};
    if (message.up !== 0) {
      obj.up = message.up;
    }
    if (message.down !== 0) {
      obj.down = message.down;
    }
    if (message.left !== 0) {
      obj.left = message.left;
    }
    if (message.right !== 0) {
      obj.right = message.right;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetSafetyZone>, I>>(base?: I): TargetSafetyZone {
    return TargetSafetyZone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetSafetyZone>, I>>(object: I): TargetSafetyZone {
    const message = createBaseTargetSafetyZone();
    message.up = object.up ?? 0;
    message.down = object.down ?? 0;
    message.left = object.left ?? 0;
    message.right = object.right ?? 0;
    return message;
  },
};

function createBaseP2PContext(): P2PContext {
  return { predictCamId: "", predictTimestampMs: 0, predictCoordX: 0, predictCoordY: 0 };
}

export const P2PContext: MessageFns<P2PContext> = {
  encode(message: P2PContext, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.predictCamId !== "") {
      writer.uint32(10).string(message.predictCamId);
    }
    if (message.predictTimestampMs !== 0) {
      writer.uint32(16).int64(message.predictTimestampMs);
    }
    if (message.predictCoordX !== 0) {
      writer.uint32(29).float(message.predictCoordX);
    }
    if (message.predictCoordY !== 0) {
      writer.uint32(37).float(message.predictCoordY);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PContext {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.predictCamId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.predictTimestampMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.predictCoordX = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.predictCoordY = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): P2PContext {
    return {
      predictCamId: isSet(object.predictCamId) ? globalThis.String(object.predictCamId) : "",
      predictTimestampMs: isSet(object.predictTimestampMs) ? globalThis.Number(object.predictTimestampMs) : 0,
      predictCoordX: isSet(object.predictCoordX) ? globalThis.Number(object.predictCoordX) : 0,
      predictCoordY: isSet(object.predictCoordY) ? globalThis.Number(object.predictCoordY) : 0,
    };
  },

  toJSON(message: P2PContext): unknown {
    const obj: any = {};
    if (message.predictCamId !== "") {
      obj.predictCamId = message.predictCamId;
    }
    if (message.predictTimestampMs !== 0) {
      obj.predictTimestampMs = Math.round(message.predictTimestampMs);
    }
    if (message.predictCoordX !== 0) {
      obj.predictCoordX = message.predictCoordX;
    }
    if (message.predictCoordY !== 0) {
      obj.predictCoordY = message.predictCoordY;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PContext>, I>>(base?: I): P2PContext {
    return P2PContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PContext>, I>>(object: I): P2PContext {
    const message = createBaseP2PContext();
    message.predictCamId = object.predictCamId ?? "";
    message.predictTimestampMs = object.predictTimestampMs ?? 0;
    message.predictCoordX = object.predictCoordX ?? 0;
    message.predictCoordY = object.predictCoordY ?? 0;
    return message;
  },
};

function createBaseSetP2PContextRequest(): SetP2PContextRequest {
  return { targetCamId: "", primaryContext: undefined, secondaryContext: undefined, safetyZone: undefined };
}

export const SetP2PContextRequest: MessageFns<SetP2PContextRequest> = {
  encode(message: SetP2PContextRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetCamId !== "") {
      writer.uint32(10).string(message.targetCamId);
    }
    if (message.primaryContext !== undefined) {
      P2PContext.encode(message.primaryContext, writer.uint32(18).fork()).join();
    }
    if (message.secondaryContext !== undefined) {
      P2PContext.encode(message.secondaryContext, writer.uint32(26).fork()).join();
    }
    if (message.safetyZone !== undefined) {
      TargetSafetyZone.encode(message.safetyZone, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetP2PContextRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetP2PContextRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetCamId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.primaryContext = P2PContext.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secondaryContext = P2PContext.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.safetyZone = TargetSafetyZone.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetP2PContextRequest {
    return {
      targetCamId: isSet(object.targetCamId) ? globalThis.String(object.targetCamId) : "",
      primaryContext: isSet(object.primaryContext) ? P2PContext.fromJSON(object.primaryContext) : undefined,
      secondaryContext: isSet(object.secondaryContext) ? P2PContext.fromJSON(object.secondaryContext) : undefined,
      safetyZone: isSet(object.safetyZone) ? TargetSafetyZone.fromJSON(object.safetyZone) : undefined,
    };
  },

  toJSON(message: SetP2PContextRequest): unknown {
    const obj: any = {};
    if (message.targetCamId !== "") {
      obj.targetCamId = message.targetCamId;
    }
    if (message.primaryContext !== undefined) {
      obj.primaryContext = P2PContext.toJSON(message.primaryContext);
    }
    if (message.secondaryContext !== undefined) {
      obj.secondaryContext = P2PContext.toJSON(message.secondaryContext);
    }
    if (message.safetyZone !== undefined) {
      obj.safetyZone = TargetSafetyZone.toJSON(message.safetyZone);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetP2PContextRequest>, I>>(base?: I): SetP2PContextRequest {
    return SetP2PContextRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetP2PContextRequest>, I>>(object: I): SetP2PContextRequest {
    const message = createBaseSetP2PContextRequest();
    message.targetCamId = object.targetCamId ?? "";
    message.primaryContext = (object.primaryContext !== undefined && object.primaryContext !== null)
      ? P2PContext.fromPartial(object.primaryContext)
      : undefined;
    message.secondaryContext = (object.secondaryContext !== undefined && object.secondaryContext !== null)
      ? P2PContext.fromPartial(object.secondaryContext)
      : undefined;
    message.safetyZone = (object.safetyZone !== undefined && object.safetyZone !== null)
      ? TargetSafetyZone.fromPartial(object.safetyZone)
      : undefined;
    return message;
  },
};

function createBaseSetP2PContextResponse(): SetP2PContextResponse {
  return {};
}

export const SetP2PContextResponse: MessageFns<SetP2PContextResponse> = {
  encode(_: SetP2PContextResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetP2PContextResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetP2PContextResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetP2PContextResponse {
    return {};
  },

  toJSON(_: SetP2PContextResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetP2PContextResponse>, I>>(base?: I): SetP2PContextResponse {
    return SetP2PContextResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetP2PContextResponse>, I>>(_: I): SetP2PContextResponse {
    const message = createBaseSetP2PContextResponse();
    return message;
  },
};

function createBaseGetBufferNameRequest(): GetBufferNameRequest {
  return { camId: "", useCase: 0 };
}

export const GetBufferNameRequest: MessageFns<GetBufferNameRequest> = {
  encode(message: GetBufferNameRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.useCase !== 0) {
      writer.uint32(16).int32(message.useCase);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBufferNameRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBufferNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.useCase = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBufferNameRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      useCase: isSet(object.useCase) ? bufferUseCaseFromJSON(object.useCase) : 0,
    };
  },

  toJSON(message: GetBufferNameRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.useCase !== 0) {
      obj.useCase = bufferUseCaseToJSON(message.useCase);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBufferNameRequest>, I>>(base?: I): GetBufferNameRequest {
    return GetBufferNameRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBufferNameRequest>, I>>(object: I): GetBufferNameRequest {
    const message = createBaseGetBufferNameRequest();
    message.camId = object.camId ?? "";
    message.useCase = object.useCase ?? 0;
    return message;
  },
};

function createBaseGetBufferNameResponse(): GetBufferNameResponse {
  return { bufferName: "" };
}

export const GetBufferNameResponse: MessageFns<GetBufferNameResponse> = {
  encode(message: GetBufferNameResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bufferName !== "") {
      writer.uint32(10).string(message.bufferName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBufferNameResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBufferNameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bufferName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBufferNameResponse {
    return { bufferName: isSet(object.bufferName) ? globalThis.String(object.bufferName) : "" };
  },

  toJSON(message: GetBufferNameResponse): unknown {
    const obj: any = {};
    if (message.bufferName !== "") {
      obj.bufferName = message.bufferName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBufferNameResponse>, I>>(base?: I): GetBufferNameResponse {
    return GetBufferNameResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBufferNameResponse>, I>>(object: I): GetBufferNameResponse {
    const message = createBaseGetBufferNameResponse();
    message.bufferName = object.bufferName ?? "";
    return message;
  },
};

function createBaseGetCameraDimensionsRequest(): GetCameraDimensionsRequest {
  return { camId: "" };
}

export const GetCameraDimensionsRequest: MessageFns<GetCameraDimensionsRequest> = {
  encode(message: GetCameraDimensionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraDimensionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraDimensionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraDimensionsRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : "" };
  },

  toJSON(message: GetCameraDimensionsRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraDimensionsRequest>, I>>(base?: I): GetCameraDimensionsRequest {
    return GetCameraDimensionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraDimensionsRequest>, I>>(object: I): GetCameraDimensionsRequest {
    const message = createBaseGetCameraDimensionsRequest();
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseGetCameraDimensionsResponse(): GetCameraDimensionsResponse {
  return { width: 0, height: 0, transpose: false };
}

export const GetCameraDimensionsResponse: MessageFns<GetCameraDimensionsResponse> = {
  encode(message: GetCameraDimensionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.width !== 0) {
      writer.uint32(8).int64(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(16).int64(message.height);
    }
    if (message.transpose !== false) {
      writer.uint32(24).bool(message.transpose);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraDimensionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraDimensionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.width = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.height = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transpose = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraDimensionsResponse {
    return {
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      transpose: isSet(object.transpose) ? globalThis.Boolean(object.transpose) : false,
    };
  },

  toJSON(message: GetCameraDimensionsResponse): unknown {
    const obj: any = {};
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.transpose !== false) {
      obj.transpose = message.transpose;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraDimensionsResponse>, I>>(base?: I): GetCameraDimensionsResponse {
    return GetCameraDimensionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraDimensionsResponse>, I>>(object: I): GetCameraDimensionsResponse {
    const message = createBaseGetCameraDimensionsResponse();
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.transpose = object.transpose ?? false;
    return message;
  },
};

function createBaseStartP2PDataCaptureRequest(): StartP2PDataCaptureRequest {
  return {
    targetCamId: "",
    captureMissRate: 0,
    captureSuccessRate: 0,
    captureEnabled: false,
    capturePath: "",
    afterTimestampMs: 0,
  };
}

export const StartP2PDataCaptureRequest: MessageFns<StartP2PDataCaptureRequest> = {
  encode(message: StartP2PDataCaptureRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetCamId !== "") {
      writer.uint32(10).string(message.targetCamId);
    }
    if (message.captureMissRate !== 0) {
      writer.uint32(21).float(message.captureMissRate);
    }
    if (message.captureSuccessRate !== 0) {
      writer.uint32(29).float(message.captureSuccessRate);
    }
    if (message.captureEnabled !== false) {
      writer.uint32(32).bool(message.captureEnabled);
    }
    if (message.capturePath !== "") {
      writer.uint32(42).string(message.capturePath);
    }
    if (message.afterTimestampMs !== 0) {
      writer.uint32(48).int64(message.afterTimestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartP2PDataCaptureRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartP2PDataCaptureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetCamId = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.captureMissRate = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.captureSuccessRate = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.captureEnabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.capturePath = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.afterTimestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartP2PDataCaptureRequest {
    return {
      targetCamId: isSet(object.targetCamId) ? globalThis.String(object.targetCamId) : "",
      captureMissRate: isSet(object.captureMissRate) ? globalThis.Number(object.captureMissRate) : 0,
      captureSuccessRate: isSet(object.captureSuccessRate) ? globalThis.Number(object.captureSuccessRate) : 0,
      captureEnabled: isSet(object.captureEnabled) ? globalThis.Boolean(object.captureEnabled) : false,
      capturePath: isSet(object.capturePath) ? globalThis.String(object.capturePath) : "",
      afterTimestampMs: isSet(object.afterTimestampMs) ? globalThis.Number(object.afterTimestampMs) : 0,
    };
  },

  toJSON(message: StartP2PDataCaptureRequest): unknown {
    const obj: any = {};
    if (message.targetCamId !== "") {
      obj.targetCamId = message.targetCamId;
    }
    if (message.captureMissRate !== 0) {
      obj.captureMissRate = message.captureMissRate;
    }
    if (message.captureSuccessRate !== 0) {
      obj.captureSuccessRate = message.captureSuccessRate;
    }
    if (message.captureEnabled !== false) {
      obj.captureEnabled = message.captureEnabled;
    }
    if (message.capturePath !== "") {
      obj.capturePath = message.capturePath;
    }
    if (message.afterTimestampMs !== 0) {
      obj.afterTimestampMs = Math.round(message.afterTimestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartP2PDataCaptureRequest>, I>>(base?: I): StartP2PDataCaptureRequest {
    return StartP2PDataCaptureRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartP2PDataCaptureRequest>, I>>(object: I): StartP2PDataCaptureRequest {
    const message = createBaseStartP2PDataCaptureRequest();
    message.targetCamId = object.targetCamId ?? "";
    message.captureMissRate = object.captureMissRate ?? 0;
    message.captureSuccessRate = object.captureSuccessRate ?? 0;
    message.captureEnabled = object.captureEnabled ?? false;
    message.capturePath = object.capturePath ?? "";
    message.afterTimestampMs = object.afterTimestampMs ?? 0;
    return message;
  },
};

function createBaseStartP2PDataCaptureResponse(): StartP2PDataCaptureResponse {
  return {};
}

export const StartP2PDataCaptureResponse: MessageFns<StartP2PDataCaptureResponse> = {
  encode(_: StartP2PDataCaptureResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartP2PDataCaptureResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartP2PDataCaptureResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StartP2PDataCaptureResponse {
    return {};
  },

  toJSON(_: StartP2PDataCaptureResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StartP2PDataCaptureResponse>, I>>(base?: I): StartP2PDataCaptureResponse {
    return StartP2PDataCaptureResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartP2PDataCaptureResponse>, I>>(_: I): StartP2PDataCaptureResponse {
    const message = createBaseStartP2PDataCaptureResponse();
    return message;
  },
};

function createBaseStopP2PDataCaptureRequest(): StopP2PDataCaptureRequest {
  return { targetCamId: "" };
}

export const StopP2PDataCaptureRequest: MessageFns<StopP2PDataCaptureRequest> = {
  encode(message: StopP2PDataCaptureRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetCamId !== "") {
      writer.uint32(10).string(message.targetCamId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopP2PDataCaptureRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopP2PDataCaptureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetCamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopP2PDataCaptureRequest {
    return { targetCamId: isSet(object.targetCamId) ? globalThis.String(object.targetCamId) : "" };
  },

  toJSON(message: StopP2PDataCaptureRequest): unknown {
    const obj: any = {};
    if (message.targetCamId !== "") {
      obj.targetCamId = message.targetCamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopP2PDataCaptureRequest>, I>>(base?: I): StopP2PDataCaptureRequest {
    return StopP2PDataCaptureRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopP2PDataCaptureRequest>, I>>(object: I): StopP2PDataCaptureRequest {
    const message = createBaseStopP2PDataCaptureRequest();
    message.targetCamId = object.targetCamId ?? "";
    return message;
  },
};

function createBaseStopP2PDataCaptureResponse(): StopP2PDataCaptureResponse {
  return {};
}

export const StopP2PDataCaptureResponse: MessageFns<StopP2PDataCaptureResponse> = {
  encode(_: StopP2PDataCaptureResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopP2PDataCaptureResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopP2PDataCaptureResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopP2PDataCaptureResponse {
    return {};
  },

  toJSON(_: StopP2PDataCaptureResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopP2PDataCaptureResponse>, I>>(base?: I): StopP2PDataCaptureResponse {
    return StopP2PDataCaptureResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopP2PDataCaptureResponse>, I>>(_: I): StopP2PDataCaptureResponse {
    const message = createBaseStopP2PDataCaptureResponse();
    return message;
  },
};

function createBasePointDetectionCategory(): PointDetectionCategory {
  return { threshold: 0, category: "" };
}

export const PointDetectionCategory: MessageFns<PointDetectionCategory> = {
  encode(message: PointDetectionCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.threshold !== 0) {
      writer.uint32(13).float(message.threshold);
    }
    if (message.category !== "") {
      writer.uint32(18).string(message.category);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PointDetectionCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePointDetectionCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.threshold = reader.float();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.category = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PointDetectionCategory {
    return {
      threshold: isSet(object.threshold) ? globalThis.Number(object.threshold) : 0,
      category: isSet(object.category) ? globalThis.String(object.category) : "",
    };
  },

  toJSON(message: PointDetectionCategory): unknown {
    const obj: any = {};
    if (message.threshold !== 0) {
      obj.threshold = message.threshold;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PointDetectionCategory>, I>>(base?: I): PointDetectionCategory {
    return PointDetectionCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PointDetectionCategory>, I>>(object: I): PointDetectionCategory {
    const message = createBasePointDetectionCategory();
    message.threshold = object.threshold ?? 0;
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseSegmentationDetectionCategory(): SegmentationDetectionCategory {
  return { threshold: 0, category: "", safetyRadiusIn: 0 };
}

export const SegmentationDetectionCategory: MessageFns<SegmentationDetectionCategory> = {
  encode(message: SegmentationDetectionCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.threshold !== 0) {
      writer.uint32(13).float(message.threshold);
    }
    if (message.category !== "") {
      writer.uint32(18).string(message.category);
    }
    if (message.safetyRadiusIn !== 0) {
      writer.uint32(29).float(message.safetyRadiusIn);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SegmentationDetectionCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegmentationDetectionCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.threshold = reader.float();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.category = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.safetyRadiusIn = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SegmentationDetectionCategory {
    return {
      threshold: isSet(object.threshold) ? globalThis.Number(object.threshold) : 0,
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      safetyRadiusIn: isSet(object.safetyRadiusIn) ? globalThis.Number(object.safetyRadiusIn) : 0,
    };
  },

  toJSON(message: SegmentationDetectionCategory): unknown {
    const obj: any = {};
    if (message.threshold !== 0) {
      obj.threshold = message.threshold;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.safetyRadiusIn !== 0) {
      obj.safetyRadiusIn = message.safetyRadiusIn;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SegmentationDetectionCategory>, I>>(base?: I): SegmentationDetectionCategory {
    return SegmentationDetectionCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SegmentationDetectionCategory>, I>>(
    object: I,
  ): SegmentationDetectionCategory {
    const message = createBaseSegmentationDetectionCategory();
    message.threshold = object.threshold ?? 0;
    message.category = object.category ?? "";
    message.safetyRadiusIn = object.safetyRadiusIn ?? 0;
    return message;
  },
};

function createBaseDeepweedDetectionCriteriaSetting(): DeepweedDetectionCriteriaSetting {
  return { pointCategories: [], weedPointThreshold: 0, cropPointThreshold: 0, segmentationCategories: [] };
}

export const DeepweedDetectionCriteriaSetting: MessageFns<DeepweedDetectionCriteriaSetting> = {
  encode(message: DeepweedDetectionCriteriaSetting, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.pointCategories) {
      PointDetectionCategory.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.weedPointThreshold !== 0) {
      writer.uint32(21).float(message.weedPointThreshold);
    }
    if (message.cropPointThreshold !== 0) {
      writer.uint32(29).float(message.cropPointThreshold);
    }
    for (const v of message.segmentationCategories) {
      SegmentationDetectionCategory.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedDetectionCriteriaSetting {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedDetectionCriteriaSetting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pointCategories.push(PointDetectionCategory.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.weedPointThreshold = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.cropPointThreshold = reader.float();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.segmentationCategories.push(SegmentationDetectionCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedDetectionCriteriaSetting {
    return {
      pointCategories: globalThis.Array.isArray(object?.pointCategories)
        ? object.pointCategories.map((e: any) => PointDetectionCategory.fromJSON(e))
        : [],
      weedPointThreshold: isSet(object.weedPointThreshold) ? globalThis.Number(object.weedPointThreshold) : 0,
      cropPointThreshold: isSet(object.cropPointThreshold) ? globalThis.Number(object.cropPointThreshold) : 0,
      segmentationCategories: globalThis.Array.isArray(object?.segmentationCategories)
        ? object.segmentationCategories.map((e: any) => SegmentationDetectionCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DeepweedDetectionCriteriaSetting): unknown {
    const obj: any = {};
    if (message.pointCategories?.length) {
      obj.pointCategories = message.pointCategories.map((e) => PointDetectionCategory.toJSON(e));
    }
    if (message.weedPointThreshold !== 0) {
      obj.weedPointThreshold = message.weedPointThreshold;
    }
    if (message.cropPointThreshold !== 0) {
      obj.cropPointThreshold = message.cropPointThreshold;
    }
    if (message.segmentationCategories?.length) {
      obj.segmentationCategories = message.segmentationCategories.map((e) => SegmentationDetectionCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedDetectionCriteriaSetting>, I>>(
    base?: I,
  ): DeepweedDetectionCriteriaSetting {
    return DeepweedDetectionCriteriaSetting.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedDetectionCriteriaSetting>, I>>(
    object: I,
  ): DeepweedDetectionCriteriaSetting {
    const message = createBaseDeepweedDetectionCriteriaSetting();
    message.pointCategories = object.pointCategories?.map((e) => PointDetectionCategory.fromPartial(e)) || [];
    message.weedPointThreshold = object.weedPointThreshold ?? 0;
    message.cropPointThreshold = object.cropPointThreshold ?? 0;
    message.segmentationCategories =
      object.segmentationCategories?.map((e) => SegmentationDetectionCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetDeepweedDetectionCriteriaRequest(): SetDeepweedDetectionCriteriaRequest {
  return { weedPointThreshold: 0, cropPointThreshold: 0, pointCategories: [], segmentationCategories: [] };
}

export const SetDeepweedDetectionCriteriaRequest: MessageFns<SetDeepweedDetectionCriteriaRequest> = {
  encode(message: SetDeepweedDetectionCriteriaRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.weedPointThreshold !== 0) {
      writer.uint32(13).float(message.weedPointThreshold);
    }
    if (message.cropPointThreshold !== 0) {
      writer.uint32(21).float(message.cropPointThreshold);
    }
    for (const v of message.pointCategories) {
      PointDetectionCategory.encode(v!, writer.uint32(26).fork()).join();
    }
    for (const v of message.segmentationCategories) {
      SegmentationDetectionCategory.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetDeepweedDetectionCriteriaRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeepweedDetectionCriteriaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.weedPointThreshold = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.cropPointThreshold = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pointCategories.push(PointDetectionCategory.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.segmentationCategories.push(SegmentationDetectionCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDeepweedDetectionCriteriaRequest {
    return {
      weedPointThreshold: isSet(object.weedPointThreshold) ? globalThis.Number(object.weedPointThreshold) : 0,
      cropPointThreshold: isSet(object.cropPointThreshold) ? globalThis.Number(object.cropPointThreshold) : 0,
      pointCategories: globalThis.Array.isArray(object?.pointCategories)
        ? object.pointCategories.map((e: any) => PointDetectionCategory.fromJSON(e))
        : [],
      segmentationCategories: globalThis.Array.isArray(object?.segmentationCategories)
        ? object.segmentationCategories.map((e: any) => SegmentationDetectionCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetDeepweedDetectionCriteriaRequest): unknown {
    const obj: any = {};
    if (message.weedPointThreshold !== 0) {
      obj.weedPointThreshold = message.weedPointThreshold;
    }
    if (message.cropPointThreshold !== 0) {
      obj.cropPointThreshold = message.cropPointThreshold;
    }
    if (message.pointCategories?.length) {
      obj.pointCategories = message.pointCategories.map((e) => PointDetectionCategory.toJSON(e));
    }
    if (message.segmentationCategories?.length) {
      obj.segmentationCategories = message.segmentationCategories.map((e) => SegmentationDetectionCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeepweedDetectionCriteriaRequest>, I>>(
    base?: I,
  ): SetDeepweedDetectionCriteriaRequest {
    return SetDeepweedDetectionCriteriaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeepweedDetectionCriteriaRequest>, I>>(
    object: I,
  ): SetDeepweedDetectionCriteriaRequest {
    const message = createBaseSetDeepweedDetectionCriteriaRequest();
    message.weedPointThreshold = object.weedPointThreshold ?? 0;
    message.cropPointThreshold = object.cropPointThreshold ?? 0;
    message.pointCategories = object.pointCategories?.map((e) => PointDetectionCategory.fromPartial(e)) || [];
    message.segmentationCategories =
      object.segmentationCategories?.map((e) => SegmentationDetectionCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetDeepweedDetectionCriteriaResponse(): SetDeepweedDetectionCriteriaResponse {
  return {};
}

export const SetDeepweedDetectionCriteriaResponse: MessageFns<SetDeepweedDetectionCriteriaResponse> = {
  encode(_: SetDeepweedDetectionCriteriaResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetDeepweedDetectionCriteriaResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeepweedDetectionCriteriaResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetDeepweedDetectionCriteriaResponse {
    return {};
  },

  toJSON(_: SetDeepweedDetectionCriteriaResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeepweedDetectionCriteriaResponse>, I>>(
    base?: I,
  ): SetDeepweedDetectionCriteriaResponse {
    return SetDeepweedDetectionCriteriaResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeepweedDetectionCriteriaResponse>, I>>(
    _: I,
  ): SetDeepweedDetectionCriteriaResponse {
    const message = createBaseSetDeepweedDetectionCriteriaResponse();
    return message;
  },
};

function createBaseGetDeepweedDetectionCriteriaRequest(): GetDeepweedDetectionCriteriaRequest {
  return {};
}

export const GetDeepweedDetectionCriteriaRequest: MessageFns<GetDeepweedDetectionCriteriaRequest> = {
  encode(_: GetDeepweedDetectionCriteriaRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedDetectionCriteriaRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedDetectionCriteriaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetDeepweedDetectionCriteriaRequest {
    return {};
  },

  toJSON(_: GetDeepweedDetectionCriteriaRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedDetectionCriteriaRequest>, I>>(
    base?: I,
  ): GetDeepweedDetectionCriteriaRequest {
    return GetDeepweedDetectionCriteriaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedDetectionCriteriaRequest>, I>>(
    _: I,
  ): GetDeepweedDetectionCriteriaRequest {
    const message = createBaseGetDeepweedDetectionCriteriaRequest();
    return message;
  },
};

function createBaseGetDeepweedDetectionCriteriaResponse(): GetDeepweedDetectionCriteriaResponse {
  return { weedPointThreshold: 0, cropPointThreshold: 0, pointCategories: [], segmentationCategories: [] };
}

export const GetDeepweedDetectionCriteriaResponse: MessageFns<GetDeepweedDetectionCriteriaResponse> = {
  encode(message: GetDeepweedDetectionCriteriaResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.weedPointThreshold !== 0) {
      writer.uint32(13).float(message.weedPointThreshold);
    }
    if (message.cropPointThreshold !== 0) {
      writer.uint32(21).float(message.cropPointThreshold);
    }
    for (const v of message.pointCategories) {
      PointDetectionCategory.encode(v!, writer.uint32(26).fork()).join();
    }
    for (const v of message.segmentationCategories) {
      SegmentationDetectionCategory.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedDetectionCriteriaResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedDetectionCriteriaResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.weedPointThreshold = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.cropPointThreshold = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pointCategories.push(PointDetectionCategory.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.segmentationCategories.push(SegmentationDetectionCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedDetectionCriteriaResponse {
    return {
      weedPointThreshold: isSet(object.weedPointThreshold) ? globalThis.Number(object.weedPointThreshold) : 0,
      cropPointThreshold: isSet(object.cropPointThreshold) ? globalThis.Number(object.cropPointThreshold) : 0,
      pointCategories: globalThis.Array.isArray(object?.pointCategories)
        ? object.pointCategories.map((e: any) => PointDetectionCategory.fromJSON(e))
        : [],
      segmentationCategories: globalThis.Array.isArray(object?.segmentationCategories)
        ? object.segmentationCategories.map((e: any) => SegmentationDetectionCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetDeepweedDetectionCriteriaResponse): unknown {
    const obj: any = {};
    if (message.weedPointThreshold !== 0) {
      obj.weedPointThreshold = message.weedPointThreshold;
    }
    if (message.cropPointThreshold !== 0) {
      obj.cropPointThreshold = message.cropPointThreshold;
    }
    if (message.pointCategories?.length) {
      obj.pointCategories = message.pointCategories.map((e) => PointDetectionCategory.toJSON(e));
    }
    if (message.segmentationCategories?.length) {
      obj.segmentationCategories = message.segmentationCategories.map((e) => SegmentationDetectionCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedDetectionCriteriaResponse>, I>>(
    base?: I,
  ): GetDeepweedDetectionCriteriaResponse {
    return GetDeepweedDetectionCriteriaResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedDetectionCriteriaResponse>, I>>(
    object: I,
  ): GetDeepweedDetectionCriteriaResponse {
    const message = createBaseGetDeepweedDetectionCriteriaResponse();
    message.weedPointThreshold = object.weedPointThreshold ?? 0;
    message.cropPointThreshold = object.cropPointThreshold ?? 0;
    message.pointCategories = object.pointCategories?.map((e) => PointDetectionCategory.fromPartial(e)) || [];
    message.segmentationCategories =
      object.segmentationCategories?.map((e) => SegmentationDetectionCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeepweedSupportedCategoriesRequest(): GetDeepweedSupportedCategoriesRequest {
  return { camId: undefined };
}

export const GetDeepweedSupportedCategoriesRequest: MessageFns<GetDeepweedSupportedCategoriesRequest> = {
  encode(message: GetDeepweedSupportedCategoriesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== undefined) {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedSupportedCategoriesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedSupportedCategoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedSupportedCategoriesRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : undefined };
  },

  toJSON(message: GetDeepweedSupportedCategoriesRequest): unknown {
    const obj: any = {};
    if (message.camId !== undefined) {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedSupportedCategoriesRequest>, I>>(
    base?: I,
  ): GetDeepweedSupportedCategoriesRequest {
    return GetDeepweedSupportedCategoriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedSupportedCategoriesRequest>, I>>(
    object: I,
  ): GetDeepweedSupportedCategoriesRequest {
    const message = createBaseGetDeepweedSupportedCategoriesRequest();
    message.camId = object.camId ?? undefined;
    return message;
  },
};

function createBaseGetDeepweedSupportedCategoriesResponse(): GetDeepweedSupportedCategoriesResponse {
  return { segmentationCategories: [], pointCategories: [] };
}

export const GetDeepweedSupportedCategoriesResponse: MessageFns<GetDeepweedSupportedCategoriesResponse> = {
  encode(message: GetDeepweedSupportedCategoriesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.segmentationCategories) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.pointCategories) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedSupportedCategoriesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedSupportedCategoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.segmentationCategories.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pointCategories.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedSupportedCategoriesResponse {
    return {
      segmentationCategories: globalThis.Array.isArray(object?.segmentationCategories)
        ? object.segmentationCategories.map((e: any) => globalThis.String(e))
        : [],
      pointCategories: globalThis.Array.isArray(object?.pointCategories)
        ? object.pointCategories.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetDeepweedSupportedCategoriesResponse): unknown {
    const obj: any = {};
    if (message.segmentationCategories?.length) {
      obj.segmentationCategories = message.segmentationCategories;
    }
    if (message.pointCategories?.length) {
      obj.pointCategories = message.pointCategories;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedSupportedCategoriesResponse>, I>>(
    base?: I,
  ): GetDeepweedSupportedCategoriesResponse {
    return GetDeepweedSupportedCategoriesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedSupportedCategoriesResponse>, I>>(
    object: I,
  ): GetDeepweedSupportedCategoriesResponse {
    const message = createBaseGetDeepweedSupportedCategoriesResponse();
    message.segmentationCategories = object.segmentationCategories?.map((e) => e) || [];
    message.pointCategories = object.pointCategories?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetDeeplabMaskExpressionsRequest(): SetDeeplabMaskExpressionsRequest {
  return { camId: "", useCase: 0, exprs: [] };
}

export const SetDeeplabMaskExpressionsRequest: MessageFns<SetDeeplabMaskExpressionsRequest> = {
  encode(message: SetDeeplabMaskExpressionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.useCase !== 0) {
      writer.uint32(16).int32(message.useCase);
    }
    for (const v of message.exprs) {
      MaskExpression.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetDeeplabMaskExpressionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeeplabMaskExpressionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.useCase = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exprs.push(MaskExpression.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDeeplabMaskExpressionsRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      useCase: isSet(object.useCase) ? bufferUseCaseFromJSON(object.useCase) : 0,
      exprs: globalThis.Array.isArray(object?.exprs) ? object.exprs.map((e: any) => MaskExpression.fromJSON(e)) : [],
    };
  },

  toJSON(message: SetDeeplabMaskExpressionsRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.useCase !== 0) {
      obj.useCase = bufferUseCaseToJSON(message.useCase);
    }
    if (message.exprs?.length) {
      obj.exprs = message.exprs.map((e) => MaskExpression.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeeplabMaskExpressionsRequest>, I>>(
    base?: I,
  ): SetDeeplabMaskExpressionsRequest {
    return SetDeeplabMaskExpressionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeeplabMaskExpressionsRequest>, I>>(
    object: I,
  ): SetDeeplabMaskExpressionsRequest {
    const message = createBaseSetDeeplabMaskExpressionsRequest();
    message.camId = object.camId ?? "";
    message.useCase = object.useCase ?? 0;
    message.exprs = object.exprs?.map((e) => MaskExpression.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetDeeplabMaskExpressionsResponse(): SetDeeplabMaskExpressionsResponse {
  return {};
}

export const SetDeeplabMaskExpressionsResponse: MessageFns<SetDeeplabMaskExpressionsResponse> = {
  encode(_: SetDeeplabMaskExpressionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetDeeplabMaskExpressionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeeplabMaskExpressionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetDeeplabMaskExpressionsResponse {
    return {};
  },

  toJSON(_: SetDeeplabMaskExpressionsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeeplabMaskExpressionsResponse>, I>>(
    base?: I,
  ): SetDeeplabMaskExpressionsResponse {
    return SetDeeplabMaskExpressionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeeplabMaskExpressionsResponse>, I>>(
    _: I,
  ): SetDeeplabMaskExpressionsResponse {
    const message = createBaseSetDeeplabMaskExpressionsResponse();
    return message;
  },
};

function createBaseGetDeepweedIndexToCategoryRequest(): GetDeepweedIndexToCategoryRequest {
  return { camId: "" };
}

export const GetDeepweedIndexToCategoryRequest: MessageFns<GetDeepweedIndexToCategoryRequest> = {
  encode(message: GetDeepweedIndexToCategoryRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedIndexToCategoryRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedIndexToCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedIndexToCategoryRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : "" };
  },

  toJSON(message: GetDeepweedIndexToCategoryRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryRequest>, I>>(
    base?: I,
  ): GetDeepweedIndexToCategoryRequest {
    return GetDeepweedIndexToCategoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryRequest>, I>>(
    object: I,
  ): GetDeepweedIndexToCategoryRequest {
    const message = createBaseGetDeepweedIndexToCategoryRequest();
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseGetDeepweedIndexToCategoryResponse(): GetDeepweedIndexToCategoryResponse {
  return { weedPointIndexToCategory: {}, cropPointIndexToCategory: {}, intersectionIndexToCategory: {} };
}

export const GetDeepweedIndexToCategoryResponse: MessageFns<GetDeepweedIndexToCategoryResponse> = {
  encode(message: GetDeepweedIndexToCategoryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.weedPointIndexToCategory).forEach(([key, value]) => {
      GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).join();
    });
    Object.entries(message.cropPointIndexToCategory).forEach(([key, value]) => {
      GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).join();
    });
    Object.entries(message.intersectionIndexToCategory).forEach(([key, value]) => {
      GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedIndexToCategoryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedIndexToCategoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.weedPointIndexToCategory[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.cropPointIndexToCategory[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.intersectionIndexToCategory[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedIndexToCategoryResponse {
    return {
      weedPointIndexToCategory: isObject(object.weedPointIndexToCategory)
        ? Object.entries(object.weedPointIndexToCategory).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
      cropPointIndexToCategory: isObject(object.cropPointIndexToCategory)
        ? Object.entries(object.cropPointIndexToCategory).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
      intersectionIndexToCategory: isObject(object.intersectionIndexToCategory)
        ? Object.entries(object.intersectionIndexToCategory).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: GetDeepweedIndexToCategoryResponse): unknown {
    const obj: any = {};
    if (message.weedPointIndexToCategory) {
      const entries = Object.entries(message.weedPointIndexToCategory);
      if (entries.length > 0) {
        obj.weedPointIndexToCategory = {};
        entries.forEach(([k, v]) => {
          obj.weedPointIndexToCategory[k] = v;
        });
      }
    }
    if (message.cropPointIndexToCategory) {
      const entries = Object.entries(message.cropPointIndexToCategory);
      if (entries.length > 0) {
        obj.cropPointIndexToCategory = {};
        entries.forEach(([k, v]) => {
          obj.cropPointIndexToCategory[k] = v;
        });
      }
    }
    if (message.intersectionIndexToCategory) {
      const entries = Object.entries(message.intersectionIndexToCategory);
      if (entries.length > 0) {
        obj.intersectionIndexToCategory = {};
        entries.forEach(([k, v]) => {
          obj.intersectionIndexToCategory[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse>, I>>(
    base?: I,
  ): GetDeepweedIndexToCategoryResponse {
    return GetDeepweedIndexToCategoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse>, I>>(
    object: I,
  ): GetDeepweedIndexToCategoryResponse {
    const message = createBaseGetDeepweedIndexToCategoryResponse();
    message.weedPointIndexToCategory = Object.entries(object.weedPointIndexToCategory ?? {}).reduce<
      { [key: number]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.cropPointIndexToCategory = Object.entries(object.cropPointIndexToCategory ?? {}).reduce<
      { [key: number]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.intersectionIndexToCategory = Object.entries(object.intersectionIndexToCategory ?? {}).reduce<
      { [key: number]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry(): GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
  return { key: 0, value: "" };
}

export const GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry: MessageFns<
  GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry
> = {
  encode(
    message: GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: BinaryReader | Uint8Array,
    length?: number,
  ): GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry>, I>>(
    base?: I,
  ): GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
    return GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry>, I>>(
    object: I,
  ): GetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry {
    const message = createBaseGetDeepweedIndexToCategoryResponse_WeedPointIndexToCategoryEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry(): GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
  return { key: 0, value: "" };
}

export const GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry: MessageFns<
  GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry
> = {
  encode(
    message: GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: BinaryReader | Uint8Array,
    length?: number,
  ): GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry>, I>>(
    base?: I,
  ): GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
    return GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry>, I>>(
    object: I,
  ): GetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry {
    const message = createBaseGetDeepweedIndexToCategoryResponse_CropPointIndexToCategoryEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry(): GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
  return { key: 0, value: "" };
}

export const GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry: MessageFns<
  GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry
> = {
  encode(
    message: GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: BinaryReader | Uint8Array,
    length?: number,
  ): GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry>, I>>(
    base?: I,
  ): GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
    return GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry>, I>>(
    object: I,
  ): GetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry {
    const message = createBaseGetDeepweedIndexToCategoryResponse_IntersectionIndexToCategoryEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetPredictCamMatrixRequest(): GetPredictCamMatrixRequest {
  return { predictCamId: "" };
}

export const GetPredictCamMatrixRequest: MessageFns<GetPredictCamMatrixRequest> = {
  encode(message: GetPredictCamMatrixRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.predictCamId !== "") {
      writer.uint32(10).string(message.predictCamId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPredictCamMatrixRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPredictCamMatrixRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.predictCamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPredictCamMatrixRequest {
    return { predictCamId: isSet(object.predictCamId) ? globalThis.String(object.predictCamId) : "" };
  },

  toJSON(message: GetPredictCamMatrixRequest): unknown {
    const obj: any = {};
    if (message.predictCamId !== "") {
      obj.predictCamId = message.predictCamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPredictCamMatrixRequest>, I>>(base?: I): GetPredictCamMatrixRequest {
    return GetPredictCamMatrixRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPredictCamMatrixRequest>, I>>(object: I): GetPredictCamMatrixRequest {
    const message = createBaseGetPredictCamMatrixRequest();
    message.predictCamId = object.predictCamId ?? "";
    return message;
  },
};

function createBaseGetPredictCamDistortionCoefficientsRequest(): GetPredictCamDistortionCoefficientsRequest {
  return { predictCamId: "" };
}

export const GetPredictCamDistortionCoefficientsRequest: MessageFns<GetPredictCamDistortionCoefficientsRequest> = {
  encode(message: GetPredictCamDistortionCoefficientsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.predictCamId !== "") {
      writer.uint32(10).string(message.predictCamId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPredictCamDistortionCoefficientsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPredictCamDistortionCoefficientsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.predictCamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPredictCamDistortionCoefficientsRequest {
    return { predictCamId: isSet(object.predictCamId) ? globalThis.String(object.predictCamId) : "" };
  },

  toJSON(message: GetPredictCamDistortionCoefficientsRequest): unknown {
    const obj: any = {};
    if (message.predictCamId !== "") {
      obj.predictCamId = message.predictCamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPredictCamDistortionCoefficientsRequest>, I>>(
    base?: I,
  ): GetPredictCamDistortionCoefficientsRequest {
    return GetPredictCamDistortionCoefficientsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPredictCamDistortionCoefficientsRequest>, I>>(
    object: I,
  ): GetPredictCamDistortionCoefficientsRequest {
    const message = createBaseGetPredictCamDistortionCoefficientsRequest();
    message.predictCamId = object.predictCamId ?? "";
    return message;
  },
};

function createBaseSetCameraSettingsRequest(): SetCameraSettingsRequest {
  return {
    camIds: [],
    exposureUs: undefined,
    gamma: undefined,
    gainDb: undefined,
    lightSourcePreset: undefined,
    wbRatioRed: undefined,
    wbRatioGreen: undefined,
    wbRatioBlue: undefined,
    roiOffsetX: undefined,
    roiOffsetY: undefined,
    mirror: undefined,
    flip: undefined,
    strobing: undefined,
    ptp: undefined,
    autoWhitebalance: undefined,
  };
}

export const SetCameraSettingsRequest: MessageFns<SetCameraSettingsRequest> = {
  encode(message: SetCameraSettingsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.camIds) {
      writer.uint32(10).string(v!);
    }
    if (message.exposureUs !== undefined) {
      writer.uint32(21).float(message.exposureUs);
    }
    if (message.gamma !== undefined) {
      writer.uint32(29).float(message.gamma);
    }
    if (message.gainDb !== undefined) {
      writer.uint32(37).float(message.gainDb);
    }
    if (message.lightSourcePreset !== undefined) {
      writer.uint32(40).int32(message.lightSourcePreset);
    }
    if (message.wbRatioRed !== undefined) {
      writer.uint32(53).float(message.wbRatioRed);
    }
    if (message.wbRatioGreen !== undefined) {
      writer.uint32(61).float(message.wbRatioGreen);
    }
    if (message.wbRatioBlue !== undefined) {
      writer.uint32(69).float(message.wbRatioBlue);
    }
    if (message.roiOffsetX !== undefined) {
      writer.uint32(72).int64(message.roiOffsetX);
    }
    if (message.roiOffsetY !== undefined) {
      writer.uint32(80).int64(message.roiOffsetY);
    }
    if (message.mirror !== undefined) {
      writer.uint32(88).bool(message.mirror);
    }
    if (message.flip !== undefined) {
      writer.uint32(96).bool(message.flip);
    }
    if (message.strobing !== undefined) {
      writer.uint32(104).bool(message.strobing);
    }
    if (message.ptp !== undefined) {
      writer.uint32(112).bool(message.ptp);
    }
    if (message.autoWhitebalance !== undefined) {
      writer.uint32(120).bool(message.autoWhitebalance);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetCameraSettingsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetCameraSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.exposureUs = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.gamma = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.gainDb = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lightSourcePreset = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.wbRatioRed = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.wbRatioGreen = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.wbRatioBlue = reader.float();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.roiOffsetX = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.roiOffsetY = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.mirror = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.flip = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.strobing = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.ptp = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.autoWhitebalance = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetCameraSettingsRequest {
    return {
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
      exposureUs: isSet(object.exposureUs) ? globalThis.Number(object.exposureUs) : undefined,
      gamma: isSet(object.gamma) ? globalThis.Number(object.gamma) : undefined,
      gainDb: isSet(object.gainDb) ? globalThis.Number(object.gainDb) : undefined,
      lightSourcePreset: isSet(object.lightSourcePreset)
        ? lightSourcePresetFromJSON(object.lightSourcePreset)
        : undefined,
      wbRatioRed: isSet(object.wbRatioRed) ? globalThis.Number(object.wbRatioRed) : undefined,
      wbRatioGreen: isSet(object.wbRatioGreen) ? globalThis.Number(object.wbRatioGreen) : undefined,
      wbRatioBlue: isSet(object.wbRatioBlue) ? globalThis.Number(object.wbRatioBlue) : undefined,
      roiOffsetX: isSet(object.roiOffsetX) ? globalThis.Number(object.roiOffsetX) : undefined,
      roiOffsetY: isSet(object.roiOffsetY) ? globalThis.Number(object.roiOffsetY) : undefined,
      mirror: isSet(object.mirror) ? globalThis.Boolean(object.mirror) : undefined,
      flip: isSet(object.flip) ? globalThis.Boolean(object.flip) : undefined,
      strobing: isSet(object.strobing) ? globalThis.Boolean(object.strobing) : undefined,
      ptp: isSet(object.ptp) ? globalThis.Boolean(object.ptp) : undefined,
      autoWhitebalance: isSet(object.autoWhitebalance) ? globalThis.Boolean(object.autoWhitebalance) : undefined,
    };
  },

  toJSON(message: SetCameraSettingsRequest): unknown {
    const obj: any = {};
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    if (message.exposureUs !== undefined) {
      obj.exposureUs = message.exposureUs;
    }
    if (message.gamma !== undefined) {
      obj.gamma = message.gamma;
    }
    if (message.gainDb !== undefined) {
      obj.gainDb = message.gainDb;
    }
    if (message.lightSourcePreset !== undefined) {
      obj.lightSourcePreset = lightSourcePresetToJSON(message.lightSourcePreset);
    }
    if (message.wbRatioRed !== undefined) {
      obj.wbRatioRed = message.wbRatioRed;
    }
    if (message.wbRatioGreen !== undefined) {
      obj.wbRatioGreen = message.wbRatioGreen;
    }
    if (message.wbRatioBlue !== undefined) {
      obj.wbRatioBlue = message.wbRatioBlue;
    }
    if (message.roiOffsetX !== undefined) {
      obj.roiOffsetX = Math.round(message.roiOffsetX);
    }
    if (message.roiOffsetY !== undefined) {
      obj.roiOffsetY = Math.round(message.roiOffsetY);
    }
    if (message.mirror !== undefined) {
      obj.mirror = message.mirror;
    }
    if (message.flip !== undefined) {
      obj.flip = message.flip;
    }
    if (message.strobing !== undefined) {
      obj.strobing = message.strobing;
    }
    if (message.ptp !== undefined) {
      obj.ptp = message.ptp;
    }
    if (message.autoWhitebalance !== undefined) {
      obj.autoWhitebalance = message.autoWhitebalance;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetCameraSettingsRequest>, I>>(base?: I): SetCameraSettingsRequest {
    return SetCameraSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetCameraSettingsRequest>, I>>(object: I): SetCameraSettingsRequest {
    const message = createBaseSetCameraSettingsRequest();
    message.camIds = object.camIds?.map((e) => e) || [];
    message.exposureUs = object.exposureUs ?? undefined;
    message.gamma = object.gamma ?? undefined;
    message.gainDb = object.gainDb ?? undefined;
    message.lightSourcePreset = object.lightSourcePreset ?? undefined;
    message.wbRatioRed = object.wbRatioRed ?? undefined;
    message.wbRatioGreen = object.wbRatioGreen ?? undefined;
    message.wbRatioBlue = object.wbRatioBlue ?? undefined;
    message.roiOffsetX = object.roiOffsetX ?? undefined;
    message.roiOffsetY = object.roiOffsetY ?? undefined;
    message.mirror = object.mirror ?? undefined;
    message.flip = object.flip ?? undefined;
    message.strobing = object.strobing ?? undefined;
    message.ptp = object.ptp ?? undefined;
    message.autoWhitebalance = object.autoWhitebalance ?? undefined;
    return message;
  },
};

function createBaseSetCameraSettingsResponse(): SetCameraSettingsResponse {
  return { camIds: [] };
}

export const SetCameraSettingsResponse: MessageFns<SetCameraSettingsResponse> = {
  encode(message: SetCameraSettingsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.camIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetCameraSettingsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetCameraSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetCameraSettingsResponse {
    return {
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SetCameraSettingsResponse): unknown {
    const obj: any = {};
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetCameraSettingsResponse>, I>>(base?: I): SetCameraSettingsResponse {
    return SetCameraSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetCameraSettingsResponse>, I>>(object: I): SetCameraSettingsResponse {
    const message = createBaseSetCameraSettingsResponse();
    message.camIds = object.camIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetAutoWhitebalanceRequest(): SetAutoWhitebalanceRequest {
  return { enable: false, camIds: [] };
}

export const SetAutoWhitebalanceRequest: MessageFns<SetAutoWhitebalanceRequest> = {
  encode(message: SetAutoWhitebalanceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.enable !== false) {
      writer.uint32(8).bool(message.enable);
    }
    for (const v of message.camIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetAutoWhitebalanceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAutoWhitebalanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enable = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetAutoWhitebalanceRequest {
    return {
      enable: isSet(object.enable) ? globalThis.Boolean(object.enable) : false,
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SetAutoWhitebalanceRequest): unknown {
    const obj: any = {};
    if (message.enable !== false) {
      obj.enable = message.enable;
    }
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAutoWhitebalanceRequest>, I>>(base?: I): SetAutoWhitebalanceRequest {
    return SetAutoWhitebalanceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAutoWhitebalanceRequest>, I>>(object: I): SetAutoWhitebalanceRequest {
    const message = createBaseSetAutoWhitebalanceRequest();
    message.enable = object.enable ?? false;
    message.camIds = object.camIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetAutoWhitebalanceResponse(): SetAutoWhitebalanceResponse {
  return {};
}

export const SetAutoWhitebalanceResponse: MessageFns<SetAutoWhitebalanceResponse> = {
  encode(_: SetAutoWhitebalanceResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetAutoWhitebalanceResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAutoWhitebalanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetAutoWhitebalanceResponse {
    return {};
  },

  toJSON(_: SetAutoWhitebalanceResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAutoWhitebalanceResponse>, I>>(base?: I): SetAutoWhitebalanceResponse {
    return SetAutoWhitebalanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAutoWhitebalanceResponse>, I>>(_: I): SetAutoWhitebalanceResponse {
    const message = createBaseSetAutoWhitebalanceResponse();
    return message;
  },
};

function createBaseGetCameraSettingsRequest(): GetCameraSettingsRequest {
  return { camIds: [] };
}

export const GetCameraSettingsRequest: MessageFns<GetCameraSettingsRequest> = {
  encode(message: GetCameraSettingsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.camIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraSettingsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraSettingsRequest {
    return {
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetCameraSettingsRequest): unknown {
    const obj: any = {};
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraSettingsRequest>, I>>(base?: I): GetCameraSettingsRequest {
    return GetCameraSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraSettingsRequest>, I>>(object: I): GetCameraSettingsRequest {
    const message = createBaseGetCameraSettingsRequest();
    message.camIds = object.camIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCameraSettingsResponse(): CameraSettingsResponse {
  return {
    camId: "",
    exposureUs: undefined,
    gamma: undefined,
    gainDb: undefined,
    lightSourcePreset: undefined,
    wbRatioRed: undefined,
    wbRatioGreen: undefined,
    wbRatioBlue: undefined,
    roiWidth: undefined,
    roiHeight: undefined,
    roiOffsetX: undefined,
    roiOffsetY: undefined,
    gpuId: undefined,
    mirror: false,
    flip: false,
    strobing: false,
    ptp: false,
    autoWhitebalance: undefined,
  };
}

export const CameraSettingsResponse: MessageFns<CameraSettingsResponse> = {
  encode(message: CameraSettingsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.exposureUs !== undefined) {
      writer.uint32(21).float(message.exposureUs);
    }
    if (message.gamma !== undefined) {
      writer.uint32(29).float(message.gamma);
    }
    if (message.gainDb !== undefined) {
      writer.uint32(37).float(message.gainDb);
    }
    if (message.lightSourcePreset !== undefined) {
      writer.uint32(40).int32(message.lightSourcePreset);
    }
    if (message.wbRatioRed !== undefined) {
      writer.uint32(53).float(message.wbRatioRed);
    }
    if (message.wbRatioGreen !== undefined) {
      writer.uint32(61).float(message.wbRatioGreen);
    }
    if (message.wbRatioBlue !== undefined) {
      writer.uint32(69).float(message.wbRatioBlue);
    }
    if (message.roiWidth !== undefined) {
      writer.uint32(72).int64(message.roiWidth);
    }
    if (message.roiHeight !== undefined) {
      writer.uint32(80).int64(message.roiHeight);
    }
    if (message.roiOffsetX !== undefined) {
      writer.uint32(88).int64(message.roiOffsetX);
    }
    if (message.roiOffsetY !== undefined) {
      writer.uint32(96).int64(message.roiOffsetY);
    }
    if (message.gpuId !== undefined) {
      writer.uint32(104).int64(message.gpuId);
    }
    if (message.mirror !== false) {
      writer.uint32(112).bool(message.mirror);
    }
    if (message.flip !== false) {
      writer.uint32(120).bool(message.flip);
    }
    if (message.strobing !== false) {
      writer.uint32(128).bool(message.strobing);
    }
    if (message.ptp !== false) {
      writer.uint32(136).bool(message.ptp);
    }
    if (message.autoWhitebalance !== undefined) {
      writer.uint32(144).bool(message.autoWhitebalance);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CameraSettingsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.exposureUs = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.gamma = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.gainDb = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lightSourcePreset = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.wbRatioRed = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.wbRatioGreen = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.wbRatioBlue = reader.float();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.roiWidth = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.roiHeight = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.roiOffsetX = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.roiOffsetY = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.gpuId = longToNumber(reader.int64());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.mirror = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.flip = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.strobing = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.ptp = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.autoWhitebalance = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraSettingsResponse {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      exposureUs: isSet(object.exposureUs) ? globalThis.Number(object.exposureUs) : undefined,
      gamma: isSet(object.gamma) ? globalThis.Number(object.gamma) : undefined,
      gainDb: isSet(object.gainDb) ? globalThis.Number(object.gainDb) : undefined,
      lightSourcePreset: isSet(object.lightSourcePreset)
        ? lightSourcePresetFromJSON(object.lightSourcePreset)
        : undefined,
      wbRatioRed: isSet(object.wbRatioRed) ? globalThis.Number(object.wbRatioRed) : undefined,
      wbRatioGreen: isSet(object.wbRatioGreen) ? globalThis.Number(object.wbRatioGreen) : undefined,
      wbRatioBlue: isSet(object.wbRatioBlue) ? globalThis.Number(object.wbRatioBlue) : undefined,
      roiWidth: isSet(object.roiWidth) ? globalThis.Number(object.roiWidth) : undefined,
      roiHeight: isSet(object.roiHeight) ? globalThis.Number(object.roiHeight) : undefined,
      roiOffsetX: isSet(object.roiOffsetX) ? globalThis.Number(object.roiOffsetX) : undefined,
      roiOffsetY: isSet(object.roiOffsetY) ? globalThis.Number(object.roiOffsetY) : undefined,
      gpuId: isSet(object.gpuId) ? globalThis.Number(object.gpuId) : undefined,
      mirror: isSet(object.mirror) ? globalThis.Boolean(object.mirror) : false,
      flip: isSet(object.flip) ? globalThis.Boolean(object.flip) : false,
      strobing: isSet(object.strobing) ? globalThis.Boolean(object.strobing) : false,
      ptp: isSet(object.ptp) ? globalThis.Boolean(object.ptp) : false,
      autoWhitebalance: isSet(object.autoWhitebalance) ? globalThis.Boolean(object.autoWhitebalance) : undefined,
    };
  },

  toJSON(message: CameraSettingsResponse): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.exposureUs !== undefined) {
      obj.exposureUs = message.exposureUs;
    }
    if (message.gamma !== undefined) {
      obj.gamma = message.gamma;
    }
    if (message.gainDb !== undefined) {
      obj.gainDb = message.gainDb;
    }
    if (message.lightSourcePreset !== undefined) {
      obj.lightSourcePreset = lightSourcePresetToJSON(message.lightSourcePreset);
    }
    if (message.wbRatioRed !== undefined) {
      obj.wbRatioRed = message.wbRatioRed;
    }
    if (message.wbRatioGreen !== undefined) {
      obj.wbRatioGreen = message.wbRatioGreen;
    }
    if (message.wbRatioBlue !== undefined) {
      obj.wbRatioBlue = message.wbRatioBlue;
    }
    if (message.roiWidth !== undefined) {
      obj.roiWidth = Math.round(message.roiWidth);
    }
    if (message.roiHeight !== undefined) {
      obj.roiHeight = Math.round(message.roiHeight);
    }
    if (message.roiOffsetX !== undefined) {
      obj.roiOffsetX = Math.round(message.roiOffsetX);
    }
    if (message.roiOffsetY !== undefined) {
      obj.roiOffsetY = Math.round(message.roiOffsetY);
    }
    if (message.gpuId !== undefined) {
      obj.gpuId = Math.round(message.gpuId);
    }
    if (message.mirror !== false) {
      obj.mirror = message.mirror;
    }
    if (message.flip !== false) {
      obj.flip = message.flip;
    }
    if (message.strobing !== false) {
      obj.strobing = message.strobing;
    }
    if (message.ptp !== false) {
      obj.ptp = message.ptp;
    }
    if (message.autoWhitebalance !== undefined) {
      obj.autoWhitebalance = message.autoWhitebalance;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraSettingsResponse>, I>>(base?: I): CameraSettingsResponse {
    return CameraSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraSettingsResponse>, I>>(object: I): CameraSettingsResponse {
    const message = createBaseCameraSettingsResponse();
    message.camId = object.camId ?? "";
    message.exposureUs = object.exposureUs ?? undefined;
    message.gamma = object.gamma ?? undefined;
    message.gainDb = object.gainDb ?? undefined;
    message.lightSourcePreset = object.lightSourcePreset ?? undefined;
    message.wbRatioRed = object.wbRatioRed ?? undefined;
    message.wbRatioGreen = object.wbRatioGreen ?? undefined;
    message.wbRatioBlue = object.wbRatioBlue ?? undefined;
    message.roiWidth = object.roiWidth ?? undefined;
    message.roiHeight = object.roiHeight ?? undefined;
    message.roiOffsetX = object.roiOffsetX ?? undefined;
    message.roiOffsetY = object.roiOffsetY ?? undefined;
    message.gpuId = object.gpuId ?? undefined;
    message.mirror = object.mirror ?? false;
    message.flip = object.flip ?? false;
    message.strobing = object.strobing ?? false;
    message.ptp = object.ptp ?? false;
    message.autoWhitebalance = object.autoWhitebalance ?? undefined;
    return message;
  },
};

function createBaseGetCameraSettingsResponse(): GetCameraSettingsResponse {
  return { cameraSettingsResponse: [] };
}

export const GetCameraSettingsResponse: MessageFns<GetCameraSettingsResponse> = {
  encode(message: GetCameraSettingsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.cameraSettingsResponse) {
      CameraSettingsResponse.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraSettingsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraSettingsResponse.push(CameraSettingsResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraSettingsResponse {
    return {
      cameraSettingsResponse: globalThis.Array.isArray(object?.cameraSettingsResponse)
        ? object.cameraSettingsResponse.map((e: any) => CameraSettingsResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCameraSettingsResponse): unknown {
    const obj: any = {};
    if (message.cameraSettingsResponse?.length) {
      obj.cameraSettingsResponse = message.cameraSettingsResponse.map((e) => CameraSettingsResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraSettingsResponse>, I>>(base?: I): GetCameraSettingsResponse {
    return GetCameraSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraSettingsResponse>, I>>(object: I): GetCameraSettingsResponse {
    const message = createBaseGetCameraSettingsResponse();
    message.cameraSettingsResponse = object.cameraSettingsResponse?.map((e) => CameraSettingsResponse.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseStartBurstRecordFramesRequest(): StartBurstRecordFramesRequest {
  return { camId: "", durationMs: 0, path: "", dontCapturePredictImage: false, downsampleFactor: 0 };
}

export const StartBurstRecordFramesRequest: MessageFns<StartBurstRecordFramesRequest> = {
  encode(message: StartBurstRecordFramesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.durationMs !== 0) {
      writer.uint32(16).int64(message.durationMs);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.dontCapturePredictImage !== false) {
      writer.uint32(32).bool(message.dontCapturePredictImage);
    }
    if (message.downsampleFactor !== 0) {
      writer.uint32(40).int32(message.downsampleFactor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartBurstRecordFramesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBurstRecordFramesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.durationMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dontCapturePredictImage = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.downsampleFactor = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartBurstRecordFramesRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      durationMs: isSet(object.durationMs) ? globalThis.Number(object.durationMs) : 0,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      dontCapturePredictImage: isSet(object.dontCapturePredictImage)
        ? globalThis.Boolean(object.dontCapturePredictImage)
        : false,
      downsampleFactor: isSet(object.downsampleFactor) ? globalThis.Number(object.downsampleFactor) : 0,
    };
  },

  toJSON(message: StartBurstRecordFramesRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.durationMs !== 0) {
      obj.durationMs = Math.round(message.durationMs);
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.dontCapturePredictImage !== false) {
      obj.dontCapturePredictImage = message.dontCapturePredictImage;
    }
    if (message.downsampleFactor !== 0) {
      obj.downsampleFactor = Math.round(message.downsampleFactor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBurstRecordFramesRequest>, I>>(base?: I): StartBurstRecordFramesRequest {
    return StartBurstRecordFramesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBurstRecordFramesRequest>, I>>(
    object: I,
  ): StartBurstRecordFramesRequest {
    const message = createBaseStartBurstRecordFramesRequest();
    message.camId = object.camId ?? "";
    message.durationMs = object.durationMs ?? 0;
    message.path = object.path ?? "";
    message.dontCapturePredictImage = object.dontCapturePredictImage ?? false;
    message.downsampleFactor = object.downsampleFactor ?? 0;
    return message;
  },
};

function createBaseStartBurstRecordFramesResponse(): StartBurstRecordFramesResponse {
  return {};
}

export const StartBurstRecordFramesResponse: MessageFns<StartBurstRecordFramesResponse> = {
  encode(_: StartBurstRecordFramesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartBurstRecordFramesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBurstRecordFramesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StartBurstRecordFramesResponse {
    return {};
  },

  toJSON(_: StartBurstRecordFramesResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBurstRecordFramesResponse>, I>>(base?: I): StartBurstRecordFramesResponse {
    return StartBurstRecordFramesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBurstRecordFramesResponse>, I>>(_: I): StartBurstRecordFramesResponse {
    const message = createBaseStartBurstRecordFramesResponse();
    return message;
  },
};

function createBaseStopBurstRecordFramesRequest(): StopBurstRecordFramesRequest {
  return { camId: "", lastFrameTimestampMs: undefined };
}

export const StopBurstRecordFramesRequest: MessageFns<StopBurstRecordFramesRequest> = {
  encode(message: StopBurstRecordFramesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.lastFrameTimestampMs !== undefined) {
      writer.uint32(16).int64(message.lastFrameTimestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopBurstRecordFramesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopBurstRecordFramesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lastFrameTimestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopBurstRecordFramesRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      lastFrameTimestampMs: isSet(object.lastFrameTimestampMs)
        ? globalThis.Number(object.lastFrameTimestampMs)
        : undefined,
    };
  },

  toJSON(message: StopBurstRecordFramesRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.lastFrameTimestampMs !== undefined) {
      obj.lastFrameTimestampMs = Math.round(message.lastFrameTimestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopBurstRecordFramesRequest>, I>>(base?: I): StopBurstRecordFramesRequest {
    return StopBurstRecordFramesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopBurstRecordFramesRequest>, I>>(object: I): StopBurstRecordFramesRequest {
    const message = createBaseStopBurstRecordFramesRequest();
    message.camId = object.camId ?? "";
    message.lastFrameTimestampMs = object.lastFrameTimestampMs ?? undefined;
    return message;
  },
};

function createBaseStopBurstRecordFramesResponse(): StopBurstRecordFramesResponse {
  return {};
}

export const StopBurstRecordFramesResponse: MessageFns<StopBurstRecordFramesResponse> = {
  encode(_: StopBurstRecordFramesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopBurstRecordFramesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopBurstRecordFramesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopBurstRecordFramesResponse {
    return {};
  },

  toJSON(_: StopBurstRecordFramesResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopBurstRecordFramesResponse>, I>>(base?: I): StopBurstRecordFramesResponse {
    return StopBurstRecordFramesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopBurstRecordFramesResponse>, I>>(_: I): StopBurstRecordFramesResponse {
    const message = createBaseStopBurstRecordFramesResponse();
    return message;
  },
};

function createBaseP2POutputProto(): P2POutputProto {
  return { matched: false, targetCoordX: 0, targetCoordY: 0, targetTimestampMs: 0, predictTimestampMs: 0, safe: false };
}

export const P2POutputProto: MessageFns<P2POutputProto> = {
  encode(message: P2POutputProto, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.matched !== false) {
      writer.uint32(8).bool(message.matched);
    }
    if (message.targetCoordX !== 0) {
      writer.uint32(21).float(message.targetCoordX);
    }
    if (message.targetCoordY !== 0) {
      writer.uint32(29).float(message.targetCoordY);
    }
    if (message.targetTimestampMs !== 0) {
      writer.uint32(32).int64(message.targetTimestampMs);
    }
    if (message.predictTimestampMs !== 0) {
      writer.uint32(40).int64(message.predictTimestampMs);
    }
    if (message.safe !== false) {
      writer.uint32(48).bool(message.safe);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2POutputProto {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2POutputProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.matched = reader.bool();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.targetCoordX = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.targetCoordY = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.targetTimestampMs = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.predictTimestampMs = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.safe = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): P2POutputProto {
    return {
      matched: isSet(object.matched) ? globalThis.Boolean(object.matched) : false,
      targetCoordX: isSet(object.targetCoordX) ? globalThis.Number(object.targetCoordX) : 0,
      targetCoordY: isSet(object.targetCoordY) ? globalThis.Number(object.targetCoordY) : 0,
      targetTimestampMs: isSet(object.targetTimestampMs) ? globalThis.Number(object.targetTimestampMs) : 0,
      predictTimestampMs: isSet(object.predictTimestampMs) ? globalThis.Number(object.predictTimestampMs) : 0,
      safe: isSet(object.safe) ? globalThis.Boolean(object.safe) : false,
    };
  },

  toJSON(message: P2POutputProto): unknown {
    const obj: any = {};
    if (message.matched !== false) {
      obj.matched = message.matched;
    }
    if (message.targetCoordX !== 0) {
      obj.targetCoordX = message.targetCoordX;
    }
    if (message.targetCoordY !== 0) {
      obj.targetCoordY = message.targetCoordY;
    }
    if (message.targetTimestampMs !== 0) {
      obj.targetTimestampMs = Math.round(message.targetTimestampMs);
    }
    if (message.predictTimestampMs !== 0) {
      obj.predictTimestampMs = Math.round(message.predictTimestampMs);
    }
    if (message.safe !== false) {
      obj.safe = message.safe;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<P2POutputProto>, I>>(base?: I): P2POutputProto {
    return P2POutputProto.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2POutputProto>, I>>(object: I): P2POutputProto {
    const message = createBaseP2POutputProto();
    message.matched = object.matched ?? false;
    message.targetCoordX = object.targetCoordX ?? 0;
    message.targetCoordY = object.targetCoordY ?? 0;
    message.targetTimestampMs = object.targetTimestampMs ?? 0;
    message.predictTimestampMs = object.predictTimestampMs ?? 0;
    message.safe = object.safe ?? false;
    return message;
  },
};

function createBaseGetNextP2POutputRequest(): GetNextP2POutputRequest {
  return { bufferName: "", timestampMs: 0, timeoutMs: 0 };
}

export const GetNextP2POutputRequest: MessageFns<GetNextP2POutputRequest> = {
  encode(message: GetNextP2POutputRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bufferName !== "") {
      writer.uint32(10).string(message.bufferName);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    if (message.timeoutMs !== 0) {
      writer.uint32(24).int64(message.timeoutMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextP2POutputRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextP2POutputRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bufferName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timeoutMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextP2POutputRequest {
    return {
      bufferName: isSet(object.bufferName) ? globalThis.String(object.bufferName) : "",
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      timeoutMs: isSet(object.timeoutMs) ? globalThis.Number(object.timeoutMs) : 0,
    };
  },

  toJSON(message: GetNextP2POutputRequest): unknown {
    const obj: any = {};
    if (message.bufferName !== "") {
      obj.bufferName = message.bufferName;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.timeoutMs !== 0) {
      obj.timeoutMs = Math.round(message.timeoutMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextP2POutputRequest>, I>>(base?: I): GetNextP2POutputRequest {
    return GetNextP2POutputRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextP2POutputRequest>, I>>(object: I): GetNextP2POutputRequest {
    const message = createBaseGetNextP2POutputRequest();
    message.bufferName = object.bufferName ?? "";
    message.timestampMs = object.timestampMs ?? 0;
    message.timeoutMs = object.timeoutMs ?? 0;
    return message;
  },
};

function createBaseGetConnectorsRequest(): GetConnectorsRequest {
  return { camIds: [], connectorIds: [] };
}

export const GetConnectorsRequest: MessageFns<GetConnectorsRequest> = {
  encode(message: GetConnectorsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.camIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.connectorIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetConnectorsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetConnectorsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectorIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetConnectorsRequest {
    return {
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
      connectorIds: globalThis.Array.isArray(object?.connectorIds)
        ? object.connectorIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetConnectorsRequest): unknown {
    const obj: any = {};
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    if (message.connectorIds?.length) {
      obj.connectorIds = message.connectorIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetConnectorsRequest>, I>>(base?: I): GetConnectorsRequest {
    return GetConnectorsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetConnectorsRequest>, I>>(object: I): GetConnectorsRequest {
    const message = createBaseGetConnectorsRequest();
    message.camIds = object.camIds?.map((e) => e) || [];
    message.connectorIds = object.connectorIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseConnectorResponse(): ConnectorResponse {
  return { camId: "", connectorId: "", isEnabled: false, reductionRatio: 0 };
}

export const ConnectorResponse: MessageFns<ConnectorResponse> = {
  encode(message: ConnectorResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.connectorId !== "") {
      writer.uint32(18).string(message.connectorId);
    }
    if (message.isEnabled !== false) {
      writer.uint32(24).bool(message.isEnabled);
    }
    if (message.reductionRatio !== 0) {
      writer.uint32(32).int64(message.reductionRatio);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConnectorResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnectorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectorId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isEnabled = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reductionRatio = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConnectorResponse {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      connectorId: isSet(object.connectorId) ? globalThis.String(object.connectorId) : "",
      isEnabled: isSet(object.isEnabled) ? globalThis.Boolean(object.isEnabled) : false,
      reductionRatio: isSet(object.reductionRatio) ? globalThis.Number(object.reductionRatio) : 0,
    };
  },

  toJSON(message: ConnectorResponse): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.connectorId !== "") {
      obj.connectorId = message.connectorId;
    }
    if (message.isEnabled !== false) {
      obj.isEnabled = message.isEnabled;
    }
    if (message.reductionRatio !== 0) {
      obj.reductionRatio = Math.round(message.reductionRatio);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConnectorResponse>, I>>(base?: I): ConnectorResponse {
    return ConnectorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConnectorResponse>, I>>(object: I): ConnectorResponse {
    const message = createBaseConnectorResponse();
    message.camId = object.camId ?? "";
    message.connectorId = object.connectorId ?? "";
    message.isEnabled = object.isEnabled ?? false;
    message.reductionRatio = object.reductionRatio ?? 0;
    return message;
  },
};

function createBaseGetConnectorsResponse(): GetConnectorsResponse {
  return { connectorResponse: [] };
}

export const GetConnectorsResponse: MessageFns<GetConnectorsResponse> = {
  encode(message: GetConnectorsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.connectorResponse) {
      ConnectorResponse.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetConnectorsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetConnectorsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.connectorResponse.push(ConnectorResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetConnectorsResponse {
    return {
      connectorResponse: globalThis.Array.isArray(object?.connectorResponse)
        ? object.connectorResponse.map((e: any) => ConnectorResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetConnectorsResponse): unknown {
    const obj: any = {};
    if (message.connectorResponse?.length) {
      obj.connectorResponse = message.connectorResponse.map((e) => ConnectorResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetConnectorsResponse>, I>>(base?: I): GetConnectorsResponse {
    return GetConnectorsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetConnectorsResponse>, I>>(object: I): GetConnectorsResponse {
    const message = createBaseGetConnectorsResponse();
    message.connectorResponse = object.connectorResponse?.map((e) => ConnectorResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetConnectorsRequest(): SetConnectorsRequest {
  return { camIds: [], connectorIds: [], isEnabled: undefined, reductionRatio: undefined };
}

export const SetConnectorsRequest: MessageFns<SetConnectorsRequest> = {
  encode(message: SetConnectorsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.camIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.connectorIds) {
      writer.uint32(18).string(v!);
    }
    if (message.isEnabled !== undefined) {
      writer.uint32(24).bool(message.isEnabled);
    }
    if (message.reductionRatio !== undefined) {
      writer.uint32(32).int64(message.reductionRatio);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetConnectorsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetConnectorsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectorIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isEnabled = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reductionRatio = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetConnectorsRequest {
    return {
      camIds: globalThis.Array.isArray(object?.camIds) ? object.camIds.map((e: any) => globalThis.String(e)) : [],
      connectorIds: globalThis.Array.isArray(object?.connectorIds)
        ? object.connectorIds.map((e: any) => globalThis.String(e))
        : [],
      isEnabled: isSet(object.isEnabled) ? globalThis.Boolean(object.isEnabled) : undefined,
      reductionRatio: isSet(object.reductionRatio) ? globalThis.Number(object.reductionRatio) : undefined,
    };
  },

  toJSON(message: SetConnectorsRequest): unknown {
    const obj: any = {};
    if (message.camIds?.length) {
      obj.camIds = message.camIds;
    }
    if (message.connectorIds?.length) {
      obj.connectorIds = message.connectorIds;
    }
    if (message.isEnabled !== undefined) {
      obj.isEnabled = message.isEnabled;
    }
    if (message.reductionRatio !== undefined) {
      obj.reductionRatio = Math.round(message.reductionRatio);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetConnectorsRequest>, I>>(base?: I): SetConnectorsRequest {
    return SetConnectorsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetConnectorsRequest>, I>>(object: I): SetConnectorsRequest {
    const message = createBaseSetConnectorsRequest();
    message.camIds = object.camIds?.map((e) => e) || [];
    message.connectorIds = object.connectorIds?.map((e) => e) || [];
    message.isEnabled = object.isEnabled ?? undefined;
    message.reductionRatio = object.reductionRatio ?? undefined;
    return message;
  },
};

function createBaseSetConnectorsResponse(): SetConnectorsResponse {
  return {};
}

export const SetConnectorsResponse: MessageFns<SetConnectorsResponse> = {
  encode(_: SetConnectorsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetConnectorsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetConnectorsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetConnectorsResponse {
    return {};
  },

  toJSON(_: SetConnectorsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetConnectorsResponse>, I>>(base?: I): SetConnectorsResponse {
    return SetConnectorsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetConnectorsResponse>, I>>(_: I): SetConnectorsResponse {
    const message = createBaseSetConnectorsResponse();
    return message;
  },
};

function createBaseNodeTiming(): NodeTiming {
  return { name: "", fpsMean: 0, fps99pct: 0, latencyMsMean: 0, latencyMs99pct: 0, state: "", stateTimings: {} };
}

export const NodeTiming: MessageFns<NodeTiming> = {
  encode(message: NodeTiming, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.fpsMean !== 0) {
      writer.uint32(21).float(message.fpsMean);
    }
    if (message.fps99pct !== 0) {
      writer.uint32(29).float(message.fps99pct);
    }
    if (message.latencyMsMean !== 0) {
      writer.uint32(37).float(message.latencyMsMean);
    }
    if (message.latencyMs99pct !== 0) {
      writer.uint32(45).float(message.latencyMs99pct);
    }
    if (message.state !== "") {
      writer.uint32(50).string(message.state);
    }
    Object.entries(message.stateTimings).forEach(([key, value]) => {
      NodeTiming_StateTimingsEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTiming {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTiming();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.fpsMean = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.fps99pct = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.latencyMsMean = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.latencyMs99pct = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = NodeTiming_StateTimingsEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.stateTimings[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTiming {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      fpsMean: isSet(object.fpsMean) ? globalThis.Number(object.fpsMean) : 0,
      fps99pct: isSet(object.fps99pct) ? globalThis.Number(object.fps99pct) : 0,
      latencyMsMean: isSet(object.latencyMsMean) ? globalThis.Number(object.latencyMsMean) : 0,
      latencyMs99pct: isSet(object.latencyMs99pct) ? globalThis.Number(object.latencyMs99pct) : 0,
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      stateTimings: isObject(object.stateTimings)
        ? Object.entries(object.stateTimings).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: NodeTiming): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.fpsMean !== 0) {
      obj.fpsMean = message.fpsMean;
    }
    if (message.fps99pct !== 0) {
      obj.fps99pct = message.fps99pct;
    }
    if (message.latencyMsMean !== 0) {
      obj.latencyMsMean = message.latencyMsMean;
    }
    if (message.latencyMs99pct !== 0) {
      obj.latencyMs99pct = message.latencyMs99pct;
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.stateTimings) {
      const entries = Object.entries(message.stateTimings);
      if (entries.length > 0) {
        obj.stateTimings = {};
        entries.forEach(([k, v]) => {
          obj.stateTimings[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTiming>, I>>(base?: I): NodeTiming {
    return NodeTiming.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTiming>, I>>(object: I): NodeTiming {
    const message = createBaseNodeTiming();
    message.name = object.name ?? "";
    message.fpsMean = object.fpsMean ?? 0;
    message.fps99pct = object.fps99pct ?? 0;
    message.latencyMsMean = object.latencyMsMean ?? 0;
    message.latencyMs99pct = object.latencyMs99pct ?? 0;
    message.state = object.state ?? "";
    message.stateTimings = Object.entries(object.stateTimings ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseNodeTiming_StateTimingsEntry(): NodeTiming_StateTimingsEntry {
  return { key: "", value: 0 };
}

export const NodeTiming_StateTimingsEntry: MessageFns<NodeTiming_StateTimingsEntry> = {
  encode(message: NodeTiming_StateTimingsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTiming_StateTimingsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTiming_StateTimingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTiming_StateTimingsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: NodeTiming_StateTimingsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTiming_StateTimingsEntry>, I>>(base?: I): NodeTiming_StateTimingsEntry {
    return NodeTiming_StateTimingsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTiming_StateTimingsEntry>, I>>(object: I): NodeTiming_StateTimingsEntry {
    const message = createBaseNodeTiming_StateTimingsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseGetTimingRequest(): GetTimingRequest {
  return {};
}

export const GetTimingRequest: MessageFns<GetTimingRequest> = {
  encode(_: GetTimingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTimingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTimingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTimingRequest {
    return {};
  },

  toJSON(_: GetTimingRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTimingRequest>, I>>(base?: I): GetTimingRequest {
    return GetTimingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTimingRequest>, I>>(_: I): GetTimingRequest {
    const message = createBaseGetTimingRequest();
    return message;
  },
};

function createBaseGetTimingResponse(): GetTimingResponse {
  return { nodeTiming: [] };
}

export const GetTimingResponse: MessageFns<GetTimingResponse> = {
  encode(message: GetTimingResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.nodeTiming) {
      NodeTiming.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTimingResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTimingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeTiming.push(NodeTiming.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTimingResponse {
    return {
      nodeTiming: globalThis.Array.isArray(object?.nodeTiming)
        ? object.nodeTiming.map((e: any) => NodeTiming.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetTimingResponse): unknown {
    const obj: any = {};
    if (message.nodeTiming?.length) {
      obj.nodeTiming = message.nodeTiming.map((e) => NodeTiming.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTimingResponse>, I>>(base?: I): GetTimingResponse {
    return GetTimingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTimingResponse>, I>>(object: I): GetTimingResponse {
    const message = createBaseGetTimingResponse();
    message.nodeTiming = object.nodeTiming?.map((e) => NodeTiming.fromPartial(e)) || [];
    return message;
  },
};

function createBasePredictRequest(): PredictRequest {
  return { camId: "", filePaths: [], timestampsMs: [] };
}

export const PredictRequest: MessageFns<PredictRequest> = {
  encode(message: PredictRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    for (const v of message.filePaths) {
      writer.uint32(18).string(v!);
    }
    writer.uint32(26).fork();
    for (const v of message.timestampsMs) {
      writer.int64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PredictRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filePaths.push(reader.string());
          continue;
        case 3:
          if (tag === 24) {
            message.timestampsMs.push(longToNumber(reader.int64()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.timestampsMs.push(longToNumber(reader.int64()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PredictRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      filePaths: globalThis.Array.isArray(object?.filePaths)
        ? object.filePaths.map((e: any) => globalThis.String(e))
        : [],
      timestampsMs: globalThis.Array.isArray(object?.timestampsMs)
        ? object.timestampsMs.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: PredictRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.filePaths?.length) {
      obj.filePaths = message.filePaths;
    }
    if (message.timestampsMs?.length) {
      obj.timestampsMs = message.timestampsMs.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PredictRequest>, I>>(base?: I): PredictRequest {
    return PredictRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictRequest>, I>>(object: I): PredictRequest {
    const message = createBasePredictRequest();
    message.camId = object.camId ?? "";
    message.filePaths = object.filePaths?.map((e) => e) || [];
    message.timestampsMs = object.timestampsMs?.map((e) => e) || [];
    return message;
  },
};

function createBasePredictResponse(): PredictResponse {
  return {};
}

export const PredictResponse: MessageFns<PredictResponse> = {
  encode(_: PredictResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PredictResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PredictResponse {
    return {};
  },

  toJSON(_: PredictResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PredictResponse>, I>>(base?: I): PredictResponse {
    return PredictResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictResponse>, I>>(_: I): PredictResponse {
    const message = createBasePredictResponse();
    return message;
  },
};

function createBaseLoadAndQueueRequest(): LoadAndQueueRequest {
  return { camId: "", filePaths: [], timestampsMs: [] };
}

export const LoadAndQueueRequest: MessageFns<LoadAndQueueRequest> = {
  encode(message: LoadAndQueueRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    for (const v of message.filePaths) {
      writer.uint32(18).string(v!);
    }
    writer.uint32(26).fork();
    for (const v of message.timestampsMs) {
      writer.int64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LoadAndQueueRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadAndQueueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filePaths.push(reader.string());
          continue;
        case 3:
          if (tag === 24) {
            message.timestampsMs.push(longToNumber(reader.int64()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.timestampsMs.push(longToNumber(reader.int64()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoadAndQueueRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      filePaths: globalThis.Array.isArray(object?.filePaths)
        ? object.filePaths.map((e: any) => globalThis.String(e))
        : [],
      timestampsMs: globalThis.Array.isArray(object?.timestampsMs)
        ? object.timestampsMs.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: LoadAndQueueRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.filePaths?.length) {
      obj.filePaths = message.filePaths;
    }
    if (message.timestampsMs?.length) {
      obj.timestampsMs = message.timestampsMs.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadAndQueueRequest>, I>>(base?: I): LoadAndQueueRequest {
    return LoadAndQueueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadAndQueueRequest>, I>>(object: I): LoadAndQueueRequest {
    const message = createBaseLoadAndQueueRequest();
    message.camId = object.camId ?? "";
    message.filePaths = object.filePaths?.map((e) => e) || [];
    message.timestampsMs = object.timestampsMs?.map((e) => e) || [];
    return message;
  },
};

function createBaseLoadAndQueueResponse(): LoadAndQueueResponse {
  return {};
}

export const LoadAndQueueResponse: MessageFns<LoadAndQueueResponse> = {
  encode(_: LoadAndQueueResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LoadAndQueueResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadAndQueueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LoadAndQueueResponse {
    return {};
  },

  toJSON(_: LoadAndQueueResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadAndQueueResponse>, I>>(base?: I): LoadAndQueueResponse {
    return LoadAndQueueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadAndQueueResponse>, I>>(_: I): LoadAndQueueResponse {
    const message = createBaseLoadAndQueueResponse();
    return message;
  },
};

function createBaseSetImageRequest(): SetImageRequest {
  return { camId: "", filePath: "" };
}

export const SetImageRequest: MessageFns<SetImageRequest> = {
  encode(message: SetImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.filePath !== "") {
      writer.uint32(18).string(message.filePath);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filePath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetImageRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      filePath: isSet(object.filePath) ? globalThis.String(object.filePath) : "",
    };
  },

  toJSON(message: SetImageRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.filePath !== "") {
      obj.filePath = message.filePath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImageRequest>, I>>(base?: I): SetImageRequest {
    return SetImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImageRequest>, I>>(object: I): SetImageRequest {
    const message = createBaseSetImageRequest();
    message.camId = object.camId ?? "";
    message.filePath = object.filePath ?? "";
    return message;
  },
};

function createBaseSetImageResponse(): SetImageResponse {
  return {};
}

export const SetImageResponse: MessageFns<SetImageResponse> = {
  encode(_: SetImageResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImageResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetImageResponse {
    return {};
  },

  toJSON(_: SetImageResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImageResponse>, I>>(base?: I): SetImageResponse {
    return SetImageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImageResponse>, I>>(_: I): SetImageResponse {
    const message = createBaseSetImageResponse();
    return message;
  },
};

function createBaseUnsetImageRequest(): UnsetImageRequest {
  return { camId: "" };
}

export const UnsetImageRequest: MessageFns<UnsetImageRequest> = {
  encode(message: UnsetImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UnsetImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsetImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnsetImageRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : "" };
  },

  toJSON(message: UnsetImageRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnsetImageRequest>, I>>(base?: I): UnsetImageRequest {
    return UnsetImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnsetImageRequest>, I>>(object: I): UnsetImageRequest {
    const message = createBaseUnsetImageRequest();
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseUnsetImageResponse(): UnsetImageResponse {
  return {};
}

export const UnsetImageResponse: MessageFns<UnsetImageResponse> = {
  encode(_: UnsetImageResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UnsetImageResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsetImageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UnsetImageResponse {
    return {};
  },

  toJSON(_: UnsetImageResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UnsetImageResponse>, I>>(base?: I): UnsetImageResponse {
    return UnsetImageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnsetImageResponse>, I>>(_: I): UnsetImageResponse {
    const message = createBaseUnsetImageResponse();
    return message;
  },
};

function createBaseGetModelPathsRequest(): GetModelPathsRequest {
  return {};
}

export const GetModelPathsRequest: MessageFns<GetModelPathsRequest> = {
  encode(_: GetModelPathsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetModelPathsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetModelPathsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetModelPathsRequest {
    return {};
  },

  toJSON(_: GetModelPathsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetModelPathsRequest>, I>>(base?: I): GetModelPathsRequest {
    return GetModelPathsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetModelPathsRequest>, I>>(_: I): GetModelPathsRequest {
    const message = createBaseGetModelPathsRequest();
    return message;
  },
};

function createBaseGetModelPathsResponse(): GetModelPathsResponse {
  return { p2p: undefined, deepweed: undefined, furrows: undefined };
}

export const GetModelPathsResponse: MessageFns<GetModelPathsResponse> = {
  encode(message: GetModelPathsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.p2p !== undefined) {
      writer.uint32(10).string(message.p2p);
    }
    if (message.deepweed !== undefined) {
      writer.uint32(18).string(message.deepweed);
    }
    if (message.furrows !== undefined) {
      writer.uint32(26).string(message.furrows);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetModelPathsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetModelPathsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.p2p = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deepweed = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.furrows = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetModelPathsResponse {
    return {
      p2p: isSet(object.p2p) ? globalThis.String(object.p2p) : undefined,
      deepweed: isSet(object.deepweed) ? globalThis.String(object.deepweed) : undefined,
      furrows: isSet(object.furrows) ? globalThis.String(object.furrows) : undefined,
    };
  },

  toJSON(message: GetModelPathsResponse): unknown {
    const obj: any = {};
    if (message.p2p !== undefined) {
      obj.p2p = message.p2p;
    }
    if (message.deepweed !== undefined) {
      obj.deepweed = message.deepweed;
    }
    if (message.furrows !== undefined) {
      obj.furrows = message.furrows;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetModelPathsResponse>, I>>(base?: I): GetModelPathsResponse {
    return GetModelPathsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetModelPathsResponse>, I>>(object: I): GetModelPathsResponse {
    const message = createBaseGetModelPathsResponse();
    message.p2p = object.p2p ?? undefined;
    message.deepweed = object.deepweed ?? undefined;
    message.furrows = object.furrows ?? undefined;
    return message;
  },
};

function createBaseGetCameraTemperaturesRequest(): GetCameraTemperaturesRequest {
  return {};
}

export const GetCameraTemperaturesRequest: MessageFns<GetCameraTemperaturesRequest> = {
  encode(_: GetCameraTemperaturesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraTemperaturesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraTemperaturesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetCameraTemperaturesRequest {
    return {};
  },

  toJSON(_: GetCameraTemperaturesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraTemperaturesRequest>, I>>(base?: I): GetCameraTemperaturesRequest {
    return GetCameraTemperaturesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraTemperaturesRequest>, I>>(_: I): GetCameraTemperaturesRequest {
    const message = createBaseGetCameraTemperaturesRequest();
    return message;
  },
};

function createBaseCameraTemperature(): CameraTemperature {
  return { camId: "", temperature: 0 };
}

export const CameraTemperature: MessageFns<CameraTemperature> = {
  encode(message: CameraTemperature, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.temperature !== 0) {
      writer.uint32(17).double(message.temperature);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CameraTemperature {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraTemperature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.temperature = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraTemperature {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      temperature: isSet(object.temperature) ? globalThis.Number(object.temperature) : 0,
    };
  },

  toJSON(message: CameraTemperature): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.temperature !== 0) {
      obj.temperature = message.temperature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraTemperature>, I>>(base?: I): CameraTemperature {
    return CameraTemperature.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraTemperature>, I>>(object: I): CameraTemperature {
    const message = createBaseCameraTemperature();
    message.camId = object.camId ?? "";
    message.temperature = object.temperature ?? 0;
    return message;
  },
};

function createBaseGetCameraTemperaturesResponse(): GetCameraTemperaturesResponse {
  return { temperature: [] };
}

export const GetCameraTemperaturesResponse: MessageFns<GetCameraTemperaturesResponse> = {
  encode(message: GetCameraTemperaturesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.temperature) {
      CameraTemperature.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraTemperaturesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraTemperaturesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.temperature.push(CameraTemperature.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraTemperaturesResponse {
    return {
      temperature: globalThis.Array.isArray(object?.temperature)
        ? object.temperature.map((e: any) => CameraTemperature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCameraTemperaturesResponse): unknown {
    const obj: any = {};
    if (message.temperature?.length) {
      obj.temperature = message.temperature.map((e) => CameraTemperature.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraTemperaturesResponse>, I>>(base?: I): GetCameraTemperaturesResponse {
    return GetCameraTemperaturesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraTemperaturesResponse>, I>>(
    object: I,
  ): GetCameraTemperaturesResponse {
    const message = createBaseGetCameraTemperaturesResponse();
    message.temperature = object.temperature?.map((e) => CameraTemperature.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeoLLA(): GeoLLA {
  return { lat: undefined, lng: undefined, alt: undefined, timestampMs: undefined };
}

export const GeoLLA: MessageFns<GeoLLA> = {
  encode(message: GeoLLA, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lat !== undefined) {
      writer.uint32(9).double(message.lat);
    }
    if (message.lng !== undefined) {
      writer.uint32(17).double(message.lng);
    }
    if (message.alt !== undefined) {
      writer.uint32(25).double(message.alt);
    }
    if (message.timestampMs !== undefined) {
      writer.uint32(32).int64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GeoLLA {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoLLA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lng = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.alt = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeoLLA {
    return {
      lat: isSet(object.lat) ? globalThis.Number(object.lat) : undefined,
      lng: isSet(object.lng) ? globalThis.Number(object.lng) : undefined,
      alt: isSet(object.alt) ? globalThis.Number(object.alt) : undefined,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : undefined,
    };
  },

  toJSON(message: GeoLLA): unknown {
    const obj: any = {};
    if (message.lat !== undefined) {
      obj.lat = message.lat;
    }
    if (message.lng !== undefined) {
      obj.lng = message.lng;
    }
    if (message.alt !== undefined) {
      obj.alt = message.alt;
    }
    if (message.timestampMs !== undefined) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeoLLA>, I>>(base?: I): GeoLLA {
    return GeoLLA.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeoLLA>, I>>(object: I): GeoLLA {
    const message = createBaseGeoLLA();
    message.lat = object.lat ?? undefined;
    message.lng = object.lng ?? undefined;
    message.alt = object.alt ?? undefined;
    message.timestampMs = object.timestampMs ?? undefined;
    return message;
  },
};

function createBaseGeoECEF(): GeoECEF {
  return { x: undefined, y: undefined, z: undefined, timestampMs: undefined };
}

export const GeoECEF: MessageFns<GeoECEF> = {
  encode(message: GeoECEF, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== undefined) {
      writer.uint32(9).double(message.x);
    }
    if (message.y !== undefined) {
      writer.uint32(17).double(message.y);
    }
    if (message.z !== undefined) {
      writer.uint32(25).double(message.z);
    }
    if (message.timestampMs !== undefined) {
      writer.uint32(32).int64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GeoECEF {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoECEF();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.x = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.y = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.z = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeoECEF {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : undefined,
      y: isSet(object.y) ? globalThis.Number(object.y) : undefined,
      z: isSet(object.z) ? globalThis.Number(object.z) : undefined,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : undefined,
    };
  },

  toJSON(message: GeoECEF): unknown {
    const obj: any = {};
    if (message.x !== undefined) {
      obj.x = message.x;
    }
    if (message.y !== undefined) {
      obj.y = message.y;
    }
    if (message.z !== undefined) {
      obj.z = message.z;
    }
    if (message.timestampMs !== undefined) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeoECEF>, I>>(base?: I): GeoECEF {
    return GeoECEF.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeoECEF>, I>>(object: I): GeoECEF {
    const message = createBaseGeoECEF();
    message.x = object.x ?? undefined;
    message.y = object.y ?? undefined;
    message.z = object.z ?? undefined;
    message.timestampMs = object.timestampMs ?? undefined;
    return message;
  },
};

function createBaseSetGPSLocationRequest(): SetGPSLocationRequest {
  return { lla: undefined, ecef: undefined };
}

export const SetGPSLocationRequest: MessageFns<SetGPSLocationRequest> = {
  encode(message: SetGPSLocationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lla !== undefined) {
      GeoLLA.encode(message.lla, writer.uint32(10).fork()).join();
    }
    if (message.ecef !== undefined) {
      GeoECEF.encode(message.ecef, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetGPSLocationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetGPSLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lla = GeoLLA.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ecef = GeoECEF.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetGPSLocationRequest {
    return {
      lla: isSet(object.lla) ? GeoLLA.fromJSON(object.lla) : undefined,
      ecef: isSet(object.ecef) ? GeoECEF.fromJSON(object.ecef) : undefined,
    };
  },

  toJSON(message: SetGPSLocationRequest): unknown {
    const obj: any = {};
    if (message.lla !== undefined) {
      obj.lla = GeoLLA.toJSON(message.lla);
    }
    if (message.ecef !== undefined) {
      obj.ecef = GeoECEF.toJSON(message.ecef);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetGPSLocationRequest>, I>>(base?: I): SetGPSLocationRequest {
    return SetGPSLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetGPSLocationRequest>, I>>(object: I): SetGPSLocationRequest {
    const message = createBaseSetGPSLocationRequest();
    message.lla = (object.lla !== undefined && object.lla !== null) ? GeoLLA.fromPartial(object.lla) : undefined;
    message.ecef = (object.ecef !== undefined && object.ecef !== null) ? GeoECEF.fromPartial(object.ecef) : undefined;
    return message;
  },
};

function createBaseSetGPSLocationResponse(): SetGPSLocationResponse {
  return {};
}

export const SetGPSLocationResponse: MessageFns<SetGPSLocationResponse> = {
  encode(_: SetGPSLocationResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetGPSLocationResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetGPSLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetGPSLocationResponse {
    return {};
  },

  toJSON(_: SetGPSLocationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetGPSLocationResponse>, I>>(base?: I): SetGPSLocationResponse {
    return SetGPSLocationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetGPSLocationResponse>, I>>(_: I): SetGPSLocationResponse {
    const message = createBaseSetGPSLocationResponse();
    return message;
  },
};

function createBaseSetImplementStatusRequest(): SetImplementStatusRequest {
  return { lifted: false, estopped: false };
}

export const SetImplementStatusRequest: MessageFns<SetImplementStatusRequest> = {
  encode(message: SetImplementStatusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lifted !== false) {
      writer.uint32(8).bool(message.lifted);
    }
    if (message.estopped !== false) {
      writer.uint32(16).bool(message.estopped);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImplementStatusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImplementStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lifted = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.estopped = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetImplementStatusRequest {
    return {
      lifted: isSet(object.lifted) ? globalThis.Boolean(object.lifted) : false,
      estopped: isSet(object.estopped) ? globalThis.Boolean(object.estopped) : false,
    };
  },

  toJSON(message: SetImplementStatusRequest): unknown {
    const obj: any = {};
    if (message.lifted !== false) {
      obj.lifted = message.lifted;
    }
    if (message.estopped !== false) {
      obj.estopped = message.estopped;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImplementStatusRequest>, I>>(base?: I): SetImplementStatusRequest {
    return SetImplementStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImplementStatusRequest>, I>>(object: I): SetImplementStatusRequest {
    const message = createBaseSetImplementStatusRequest();
    message.lifted = object.lifted ?? false;
    message.estopped = object.estopped ?? false;
    return message;
  },
};

function createBaseSetImplementStatusResponse(): SetImplementStatusResponse {
  return {};
}

export const SetImplementStatusResponse: MessageFns<SetImplementStatusResponse> = {
  encode(_: SetImplementStatusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImplementStatusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImplementStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetImplementStatusResponse {
    return {};
  },

  toJSON(_: SetImplementStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImplementStatusResponse>, I>>(base?: I): SetImplementStatusResponse {
    return SetImplementStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImplementStatusResponse>, I>>(_: I): SetImplementStatusResponse {
    const message = createBaseSetImplementStatusResponse();
    return message;
  },
};

function createBaseMaskExpression(): MaskExpression {
  return {
    category: undefined,
    dilate: undefined,
    erode: undefined,
    unionExpr: undefined,
    intersect: undefined,
    negate: undefined,
    line: undefined,
    allLines: undefined,
  };
}

export const MaskExpression: MessageFns<MaskExpression> = {
  encode(message: MaskExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.category !== undefined) {
      CategoryExpression.encode(message.category, writer.uint32(10).fork()).join();
    }
    if (message.dilate !== undefined) {
      DilateExpression.encode(message.dilate, writer.uint32(18).fork()).join();
    }
    if (message.erode !== undefined) {
      ErodeExpression.encode(message.erode, writer.uint32(26).fork()).join();
    }
    if (message.unionExpr !== undefined) {
      UnionExpression.encode(message.unionExpr, writer.uint32(34).fork()).join();
    }
    if (message.intersect !== undefined) {
      IntersectExpression.encode(message.intersect, writer.uint32(42).fork()).join();
    }
    if (message.negate !== undefined) {
      NegateExpression.encode(message.negate, writer.uint32(50).fork()).join();
    }
    if (message.line !== undefined) {
      LineExpression.encode(message.line, writer.uint32(58).fork()).join();
    }
    if (message.allLines !== undefined) {
      AllLinesExpression.encode(message.allLines, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MaskExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMaskExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = CategoryExpression.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dilate = DilateExpression.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.erode = ErodeExpression.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unionExpr = UnionExpression.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.intersect = IntersectExpression.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.negate = NegateExpression.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.line = LineExpression.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.allLines = AllLinesExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MaskExpression {
    return {
      category: isSet(object.category) ? CategoryExpression.fromJSON(object.category) : undefined,
      dilate: isSet(object.dilate) ? DilateExpression.fromJSON(object.dilate) : undefined,
      erode: isSet(object.erode) ? ErodeExpression.fromJSON(object.erode) : undefined,
      unionExpr: isSet(object.unionExpr) ? UnionExpression.fromJSON(object.unionExpr) : undefined,
      intersect: isSet(object.intersect) ? IntersectExpression.fromJSON(object.intersect) : undefined,
      negate: isSet(object.negate) ? NegateExpression.fromJSON(object.negate) : undefined,
      line: isSet(object.line) ? LineExpression.fromJSON(object.line) : undefined,
      allLines: isSet(object.allLines) ? AllLinesExpression.fromJSON(object.allLines) : undefined,
    };
  },

  toJSON(message: MaskExpression): unknown {
    const obj: any = {};
    if (message.category !== undefined) {
      obj.category = CategoryExpression.toJSON(message.category);
    }
    if (message.dilate !== undefined) {
      obj.dilate = DilateExpression.toJSON(message.dilate);
    }
    if (message.erode !== undefined) {
      obj.erode = ErodeExpression.toJSON(message.erode);
    }
    if (message.unionExpr !== undefined) {
      obj.unionExpr = UnionExpression.toJSON(message.unionExpr);
    }
    if (message.intersect !== undefined) {
      obj.intersect = IntersectExpression.toJSON(message.intersect);
    }
    if (message.negate !== undefined) {
      obj.negate = NegateExpression.toJSON(message.negate);
    }
    if (message.line !== undefined) {
      obj.line = LineExpression.toJSON(message.line);
    }
    if (message.allLines !== undefined) {
      obj.allLines = AllLinesExpression.toJSON(message.allLines);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MaskExpression>, I>>(base?: I): MaskExpression {
    return MaskExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MaskExpression>, I>>(object: I): MaskExpression {
    const message = createBaseMaskExpression();
    message.category = (object.category !== undefined && object.category !== null)
      ? CategoryExpression.fromPartial(object.category)
      : undefined;
    message.dilate = (object.dilate !== undefined && object.dilate !== null)
      ? DilateExpression.fromPartial(object.dilate)
      : undefined;
    message.erode = (object.erode !== undefined && object.erode !== null)
      ? ErodeExpression.fromPartial(object.erode)
      : undefined;
    message.unionExpr = (object.unionExpr !== undefined && object.unionExpr !== null)
      ? UnionExpression.fromPartial(object.unionExpr)
      : undefined;
    message.intersect = (object.intersect !== undefined && object.intersect !== null)
      ? IntersectExpression.fromPartial(object.intersect)
      : undefined;
    message.negate = (object.negate !== undefined && object.negate !== null)
      ? NegateExpression.fromPartial(object.negate)
      : undefined;
    message.line = (object.line !== undefined && object.line !== null)
      ? LineExpression.fromPartial(object.line)
      : undefined;
    message.allLines = (object.allLines !== undefined && object.allLines !== null)
      ? AllLinesExpression.fromPartial(object.allLines)
      : undefined;
    return message;
  },
};

function createBaseCategoryExpression(): CategoryExpression {
  return { category: "", threshold: 0 };
}

export const CategoryExpression: MessageFns<CategoryExpression> = {
  encode(message: CategoryExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.category !== "") {
      writer.uint32(10).string(message.category);
    }
    if (message.threshold !== 0) {
      writer.uint32(21).float(message.threshold);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CategoryExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCategoryExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.threshold = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CategoryExpression {
    return {
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      threshold: isSet(object.threshold) ? globalThis.Number(object.threshold) : 0,
    };
  },

  toJSON(message: CategoryExpression): unknown {
    const obj: any = {};
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.threshold !== 0) {
      obj.threshold = message.threshold;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CategoryExpression>, I>>(base?: I): CategoryExpression {
    return CategoryExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CategoryExpression>, I>>(object: I): CategoryExpression {
    const message = createBaseCategoryExpression();
    message.category = object.category ?? "";
    message.threshold = object.threshold ?? 0;
    return message;
  },
};

function createBaseLineExpression(): LineExpression {
  return { category: "", x: 0, y: 0 };
}

export const LineExpression: MessageFns<LineExpression> = {
  encode(message: LineExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.category !== "") {
      writer.uint32(10).string(message.category);
    }
    if (message.x !== 0) {
      writer.uint32(21).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(29).float(message.y);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LineExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.x = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.y = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LineExpression {
    return {
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: LineExpression): unknown {
    const obj: any = {};
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LineExpression>, I>>(base?: I): LineExpression {
    return LineExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineExpression>, I>>(object: I): LineExpression {
    const message = createBaseLineExpression();
    message.category = object.category ?? "";
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseAllLinesExpression(): AllLinesExpression {
  return { category: "" };
}

export const AllLinesExpression: MessageFns<AllLinesExpression> = {
  encode(message: AllLinesExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.category !== "") {
      writer.uint32(10).string(message.category);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AllLinesExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllLinesExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllLinesExpression {
    return { category: isSet(object.category) ? globalThis.String(object.category) : "" };
  },

  toJSON(message: AllLinesExpression): unknown {
    const obj: any = {};
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AllLinesExpression>, I>>(base?: I): AllLinesExpression {
    return AllLinesExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AllLinesExpression>, I>>(object: I): AllLinesExpression {
    const message = createBaseAllLinesExpression();
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseDilateExpression(): DilateExpression {
  return { expr: undefined, size: 0, granularity: 0 };
}

export const DilateExpression: MessageFns<DilateExpression> = {
  encode(message: DilateExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.expr !== undefined) {
      MaskExpression.encode(message.expr, writer.uint32(10).fork()).join();
    }
    if (message.size !== 0) {
      writer.uint32(16).int32(message.size);
    }
    if (message.granularity !== 0) {
      writer.uint32(24).int32(message.granularity);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DilateExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDilateExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expr = MaskExpression.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.granularity = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DilateExpression {
    return {
      expr: isSet(object.expr) ? MaskExpression.fromJSON(object.expr) : undefined,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      granularity: isSet(object.granularity) ? globalThis.Number(object.granularity) : 0,
    };
  },

  toJSON(message: DilateExpression): unknown {
    const obj: any = {};
    if (message.expr !== undefined) {
      obj.expr = MaskExpression.toJSON(message.expr);
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    if (message.granularity !== 0) {
      obj.granularity = Math.round(message.granularity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DilateExpression>, I>>(base?: I): DilateExpression {
    return DilateExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DilateExpression>, I>>(object: I): DilateExpression {
    const message = createBaseDilateExpression();
    message.expr = (object.expr !== undefined && object.expr !== null)
      ? MaskExpression.fromPartial(object.expr)
      : undefined;
    message.size = object.size ?? 0;
    message.granularity = object.granularity ?? 0;
    return message;
  },
};

function createBaseErodeExpression(): ErodeExpression {
  return { expr: undefined, size: 0, granularity: 0 };
}

export const ErodeExpression: MessageFns<ErodeExpression> = {
  encode(message: ErodeExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.expr !== undefined) {
      MaskExpression.encode(message.expr, writer.uint32(10).fork()).join();
    }
    if (message.size !== 0) {
      writer.uint32(16).int32(message.size);
    }
    if (message.granularity !== 0) {
      writer.uint32(24).int32(message.granularity);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ErodeExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErodeExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expr = MaskExpression.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.granularity = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErodeExpression {
    return {
      expr: isSet(object.expr) ? MaskExpression.fromJSON(object.expr) : undefined,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      granularity: isSet(object.granularity) ? globalThis.Number(object.granularity) : 0,
    };
  },

  toJSON(message: ErodeExpression): unknown {
    const obj: any = {};
    if (message.expr !== undefined) {
      obj.expr = MaskExpression.toJSON(message.expr);
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    if (message.granularity !== 0) {
      obj.granularity = Math.round(message.granularity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ErodeExpression>, I>>(base?: I): ErodeExpression {
    return ErodeExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErodeExpression>, I>>(object: I): ErodeExpression {
    const message = createBaseErodeExpression();
    message.expr = (object.expr !== undefined && object.expr !== null)
      ? MaskExpression.fromPartial(object.expr)
      : undefined;
    message.size = object.size ?? 0;
    message.granularity = object.granularity ?? 0;
    return message;
  },
};

function createBaseUnionExpression(): UnionExpression {
  return { exprs: [] };
}

export const UnionExpression: MessageFns<UnionExpression> = {
  encode(message: UnionExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.exprs) {
      MaskExpression.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UnionExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnionExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exprs.push(MaskExpression.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnionExpression {
    return {
      exprs: globalThis.Array.isArray(object?.exprs) ? object.exprs.map((e: any) => MaskExpression.fromJSON(e)) : [],
    };
  },

  toJSON(message: UnionExpression): unknown {
    const obj: any = {};
    if (message.exprs?.length) {
      obj.exprs = message.exprs.map((e) => MaskExpression.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnionExpression>, I>>(base?: I): UnionExpression {
    return UnionExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnionExpression>, I>>(object: I): UnionExpression {
    const message = createBaseUnionExpression();
    message.exprs = object.exprs?.map((e) => MaskExpression.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIntersectExpression(): IntersectExpression {
  return { exprs: [] };
}

export const IntersectExpression: MessageFns<IntersectExpression> = {
  encode(message: IntersectExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.exprs) {
      MaskExpression.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IntersectExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntersectExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exprs.push(MaskExpression.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntersectExpression {
    return {
      exprs: globalThis.Array.isArray(object?.exprs) ? object.exprs.map((e: any) => MaskExpression.fromJSON(e)) : [],
    };
  },

  toJSON(message: IntersectExpression): unknown {
    const obj: any = {};
    if (message.exprs?.length) {
      obj.exprs = message.exprs.map((e) => MaskExpression.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IntersectExpression>, I>>(base?: I): IntersectExpression {
    return IntersectExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IntersectExpression>, I>>(object: I): IntersectExpression {
    const message = createBaseIntersectExpression();
    message.exprs = object.exprs?.map((e) => MaskExpression.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNegateExpression(): NegateExpression {
  return { expr: undefined };
}

export const NegateExpression: MessageFns<NegateExpression> = {
  encode(message: NegateExpression, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.expr !== undefined) {
      MaskExpression.encode(message.expr, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NegateExpression {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNegateExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expr = MaskExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NegateExpression {
    return { expr: isSet(object.expr) ? MaskExpression.fromJSON(object.expr) : undefined };
  },

  toJSON(message: NegateExpression): unknown {
    const obj: any = {};
    if (message.expr !== undefined) {
      obj.expr = MaskExpression.toJSON(message.expr);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NegateExpression>, I>>(base?: I): NegateExpression {
    return NegateExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NegateExpression>, I>>(object: I): NegateExpression {
    const message = createBaseNegateExpression();
    message.expr = (object.expr !== undefined && object.expr !== null)
      ? MaskExpression.fromPartial(object.expr)
      : undefined;
    return message;
  },
};

function createBaseSetImageScoreRequest(): SetImageScoreRequest {
  return { score: 0, timestampMs: 0, camId: "", deepweedDetections: [] };
}

export const SetImageScoreRequest: MessageFns<SetImageScoreRequest> = {
  encode(message: SetImageScoreRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.score !== 0) {
      writer.uint32(9).double(message.score);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    if (message.camId !== "") {
      writer.uint32(26).string(message.camId);
    }
    for (const v of message.deepweedDetections) {
      DeepweedDetection.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImageScoreRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageScoreRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.score = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deepweedDetections.push(DeepweedDetection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetImageScoreRequest {
    return {
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      deepweedDetections: globalThis.Array.isArray(object?.deepweedDetections)
        ? object.deepweedDetections.map((e: any) => DeepweedDetection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetImageScoreRequest): unknown {
    const obj: any = {};
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.deepweedDetections?.length) {
      obj.deepweedDetections = message.deepweedDetections.map((e) => DeepweedDetection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImageScoreRequest>, I>>(base?: I): SetImageScoreRequest {
    return SetImageScoreRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImageScoreRequest>, I>>(object: I): SetImageScoreRequest {
    const message = createBaseSetImageScoreRequest();
    message.score = object.score ?? 0;
    message.timestampMs = object.timestampMs ?? 0;
    message.camId = object.camId ?? "";
    message.deepweedDetections = object.deepweedDetections?.map((e) => DeepweedDetection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetImageScoreResponse(): SetImageScoreResponse {
  return {};
}

export const SetImageScoreResponse: MessageFns<SetImageScoreResponse> = {
  encode(_: SetImageScoreResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetImageScoreResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageScoreResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetImageScoreResponse {
    return {};
  },

  toJSON(_: SetImageScoreResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetImageScoreResponse>, I>>(base?: I): SetImageScoreResponse {
    return SetImageScoreResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetImageScoreResponse>, I>>(_: I): SetImageScoreResponse {
    const message = createBaseSetImageScoreResponse();
    return message;
  },
};

function createBaseGetScoreQueueRequest(): GetScoreQueueRequest {
  return { scoreType: "" };
}

export const GetScoreQueueRequest: MessageFns<GetScoreQueueRequest> = {
  encode(message: GetScoreQueueRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scoreType !== "") {
      writer.uint32(10).string(message.scoreType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetScoreQueueRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScoreQueueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScoreQueueRequest {
    return { scoreType: isSet(object.scoreType) ? globalThis.String(object.scoreType) : "" };
  },

  toJSON(message: GetScoreQueueRequest): unknown {
    const obj: any = {};
    if (message.scoreType !== "") {
      obj.scoreType = message.scoreType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScoreQueueRequest>, I>>(base?: I): GetScoreQueueRequest {
    return GetScoreQueueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScoreQueueRequest>, I>>(object: I): GetScoreQueueRequest {
    const message = createBaseGetScoreQueueRequest();
    message.scoreType = object.scoreType ?? "";
    return message;
  },
};

function createBaseScoreObject(): ScoreObject {
  return { score: 0, timestampMs: 0, camId: "" };
}

export const ScoreObject: MessageFns<ScoreObject> = {
  encode(message: ScoreObject, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.score !== 0) {
      writer.uint32(9).double(message.score);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    if (message.camId !== "") {
      writer.uint32(26).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScoreObject {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScoreObject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.score = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScoreObject {
    return {
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
    };
  },

  toJSON(message: ScoreObject): unknown {
    const obj: any = {};
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScoreObject>, I>>(base?: I): ScoreObject {
    return ScoreObject.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScoreObject>, I>>(object: I): ScoreObject {
    const message = createBaseScoreObject();
    message.score = object.score ?? 0;
    message.timestampMs = object.timestampMs ?? 0;
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseGetScoreQueueResponse(): GetScoreQueueResponse {
  return { scoreObject: [] };
}

export const GetScoreQueueResponse: MessageFns<GetScoreQueueResponse> = {
  encode(message: GetScoreQueueResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.scoreObject) {
      ScoreObject.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetScoreQueueResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScoreQueueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreObject.push(ScoreObject.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScoreQueueResponse {
    return {
      scoreObject: globalThis.Array.isArray(object?.scoreObject)
        ? object.scoreObject.map((e: any) => ScoreObject.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetScoreQueueResponse): unknown {
    const obj: any = {};
    if (message.scoreObject?.length) {
      obj.scoreObject = message.scoreObject.map((e) => ScoreObject.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScoreQueueResponse>, I>>(base?: I): GetScoreQueueResponse {
    return GetScoreQueueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScoreQueueResponse>, I>>(object: I): GetScoreQueueResponse {
    const message = createBaseGetScoreQueueResponse();
    message.scoreObject = object.scoreObject?.map((e) => ScoreObject.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetMaxImageScoreRequest(): GetMaxImageScoreRequest {
  return { scoreType: "" };
}

export const GetMaxImageScoreRequest: MessageFns<GetMaxImageScoreRequest> = {
  encode(message: GetMaxImageScoreRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scoreType !== "") {
      writer.uint32(10).string(message.scoreType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMaxImageScoreRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMaxImageScoreRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMaxImageScoreRequest {
    return { scoreType: isSet(object.scoreType) ? globalThis.String(object.scoreType) : "" };
  },

  toJSON(message: GetMaxImageScoreRequest): unknown {
    const obj: any = {};
    if (message.scoreType !== "") {
      obj.scoreType = message.scoreType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMaxImageScoreRequest>, I>>(base?: I): GetMaxImageScoreRequest {
    return GetMaxImageScoreRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMaxImageScoreRequest>, I>>(object: I): GetMaxImageScoreRequest {
    const message = createBaseGetMaxImageScoreRequest();
    message.scoreType = object.scoreType ?? "";
    return message;
  },
};

function createBaseGetMaxImageScoreResponse(): GetMaxImageScoreResponse {
  return { score: 0, type: "" };
}

export const GetMaxImageScoreResponse: MessageFns<GetMaxImageScoreResponse> = {
  encode(message: GetMaxImageScoreResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.score !== 0) {
      writer.uint32(9).double(message.score);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMaxImageScoreResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMaxImageScoreResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.score = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMaxImageScoreResponse {
    return {
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: GetMaxImageScoreResponse): unknown {
    const obj: any = {};
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMaxImageScoreResponse>, I>>(base?: I): GetMaxImageScoreResponse {
    return GetMaxImageScoreResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMaxImageScoreResponse>, I>>(object: I): GetMaxImageScoreResponse {
    const message = createBaseGetMaxImageScoreResponse();
    message.score = object.score ?? 0;
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseGetMaxScoredImageRequest(): GetMaxScoredImageRequest {
  return { scoreType: "" };
}

export const GetMaxScoredImageRequest: MessageFns<GetMaxScoredImageRequest> = {
  encode(message: GetMaxScoredImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scoreType !== "") {
      writer.uint32(10).string(message.scoreType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMaxScoredImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMaxScoredImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMaxScoredImageRequest {
    return { scoreType: isSet(object.scoreType) ? globalThis.String(object.scoreType) : "" };
  },

  toJSON(message: GetMaxScoredImageRequest): unknown {
    const obj: any = {};
    if (message.scoreType !== "") {
      obj.scoreType = message.scoreType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMaxScoredImageRequest>, I>>(base?: I): GetMaxScoredImageRequest {
    return GetMaxScoredImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMaxScoredImageRequest>, I>>(object: I): GetMaxScoredImageRequest {
    const message = createBaseGetMaxScoredImageRequest();
    message.scoreType = object.scoreType ?? "";
    return message;
  },
};

function createBaseGetLatestP2PImageRequest(): GetLatestP2PImageRequest {
  return { scoreType: "", reason: 0 };
}

export const GetLatestP2PImageRequest: MessageFns<GetLatestP2PImageRequest> = {
  encode(message: GetLatestP2PImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scoreType !== "") {
      writer.uint32(10).string(message.scoreType);
    }
    if (message.reason !== 0) {
      writer.uint32(16).int32(message.reason);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLatestP2PImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestP2PImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.reason = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLatestP2PImageRequest {
    return {
      scoreType: isSet(object.scoreType) ? globalThis.String(object.scoreType) : "",
      reason: isSet(object.reason) ? p2PCaptureReasonFromJSON(object.reason) : 0,
    };
  },

  toJSON(message: GetLatestP2PImageRequest): unknown {
    const obj: any = {};
    if (message.scoreType !== "") {
      obj.scoreType = message.scoreType;
    }
    if (message.reason !== 0) {
      obj.reason = p2PCaptureReasonToJSON(message.reason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLatestP2PImageRequest>, I>>(base?: I): GetLatestP2PImageRequest {
    return GetLatestP2PImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestP2PImageRequest>, I>>(object: I): GetLatestP2PImageRequest {
    const message = createBaseGetLatestP2PImageRequest();
    message.scoreType = object.scoreType ?? "";
    message.reason = object.reason ?? 0;
    return message;
  },
};

function createBaseGetLatestImageRequest(): GetLatestImageRequest {
  return { camId: "" };
}

export const GetLatestImageRequest: MessageFns<GetLatestImageRequest> = {
  encode(message: GetLatestImageRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLatestImageRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLatestImageRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : "" };
  },

  toJSON(message: GetLatestImageRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLatestImageRequest>, I>>(base?: I): GetLatestImageRequest {
    return GetLatestImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestImageRequest>, I>>(object: I): GetLatestImageRequest {
    const message = createBaseGetLatestImageRequest();
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseFlushQueuesRequest(): FlushQueuesRequest {
  return { scoreType: [] };
}

export const FlushQueuesRequest: MessageFns<FlushQueuesRequest> = {
  encode(message: FlushQueuesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.scoreType) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FlushQueuesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlushQueuesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scoreType.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlushQueuesRequest {
    return {
      scoreType: globalThis.Array.isArray(object?.scoreType)
        ? object.scoreType.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: FlushQueuesRequest): unknown {
    const obj: any = {};
    if (message.scoreType?.length) {
      obj.scoreType = message.scoreType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlushQueuesRequest>, I>>(base?: I): FlushQueuesRequest {
    return FlushQueuesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlushQueuesRequest>, I>>(object: I): FlushQueuesRequest {
    const message = createBaseFlushQueuesRequest();
    message.scoreType = object.scoreType?.map((e) => e) || [];
    return message;
  },
};

function createBaseFlushQueuesResponse(): FlushQueuesResponse {
  return {};
}

export const FlushQueuesResponse: MessageFns<FlushQueuesResponse> = {
  encode(_: FlushQueuesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FlushQueuesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlushQueuesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FlushQueuesResponse {
    return {};
  },

  toJSON(_: FlushQueuesResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FlushQueuesResponse>, I>>(base?: I): FlushQueuesResponse {
    return FlushQueuesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlushQueuesResponse>, I>>(_: I): FlushQueuesResponse {
    const message = createBaseFlushQueuesResponse();
    return message;
  },
};

function createBaseImageAndMetadataResponse(): ImageAndMetadataResponse {
  return {
    bytes: new Uint8Array(0),
    width: 0,
    height: 0,
    timestampMs: 0,
    score: 0,
    camId: "",
    isoFormattedTime: "",
    llaLat: 0,
    llaLng: 0,
    llaAlt: 0,
    llaTimestampMs: 0,
    ecefX: 0,
    ecefY: 0,
    ecefZ: 0,
    ecefTimestampMs: 0,
    ppi: 0,
    scoreType: undefined,
    imageType: "",
    modelUrl: "",
    crop: "",
    weedHeightColumns: [],
    cropHeightColumns: [],
    bbhOffsetMm: 0,
    focusMetric: 0,
    exposureUs: 0,
    cropPointThreshold: 0,
    weedPointThreshold: 0,
    weedingEnabled: false,
    thinningEnabled: false,
    deepweedId: "",
    p2pId: "",
    deepweedDetections: [],
    segmentationThreshold: 0,
    simulatorGenerated: false,
  };
}

export const ImageAndMetadataResponse: MessageFns<ImageAndMetadataResponse> = {
  encode(message: ImageAndMetadataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bytes.length !== 0) {
      writer.uint32(10).bytes(message.bytes);
    }
    if (message.width !== 0) {
      writer.uint32(16).int32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(24).int32(message.height);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(32).int64(message.timestampMs);
    }
    if (message.score !== 0) {
      writer.uint32(41).double(message.score);
    }
    if (message.camId !== "") {
      writer.uint32(50).string(message.camId);
    }
    if (message.isoFormattedTime !== "") {
      writer.uint32(58).string(message.isoFormattedTime);
    }
    if (message.llaLat !== 0) {
      writer.uint32(65).double(message.llaLat);
    }
    if (message.llaLng !== 0) {
      writer.uint32(73).double(message.llaLng);
    }
    if (message.llaAlt !== 0) {
      writer.uint32(81).double(message.llaAlt);
    }
    if (message.llaTimestampMs !== 0) {
      writer.uint32(88).int64(message.llaTimestampMs);
    }
    if (message.ecefX !== 0) {
      writer.uint32(97).double(message.ecefX);
    }
    if (message.ecefY !== 0) {
      writer.uint32(105).double(message.ecefY);
    }
    if (message.ecefZ !== 0) {
      writer.uint32(113).double(message.ecefZ);
    }
    if (message.ecefTimestampMs !== 0) {
      writer.uint32(120).int64(message.ecefTimestampMs);
    }
    if (message.ppi !== 0) {
      writer.uint32(133).float(message.ppi);
    }
    if (message.scoreType !== undefined) {
      writer.uint32(138).string(message.scoreType);
    }
    if (message.imageType !== "") {
      writer.uint32(146).string(message.imageType);
    }
    if (message.modelUrl !== "") {
      writer.uint32(154).string(message.modelUrl);
    }
    if (message.crop !== "") {
      writer.uint32(162).string(message.crop);
    }
    writer.uint32(170).fork();
    for (const v of message.weedHeightColumns) {
      writer.double(v);
    }
    writer.join();
    writer.uint32(178).fork();
    for (const v of message.cropHeightColumns) {
      writer.double(v);
    }
    writer.join();
    if (message.bbhOffsetMm !== 0) {
      writer.uint32(185).double(message.bbhOffsetMm);
    }
    if (message.focusMetric !== 0) {
      writer.uint32(193).double(message.focusMetric);
    }
    if (message.exposureUs !== 0) {
      writer.uint32(201).double(message.exposureUs);
    }
    if (message.cropPointThreshold !== 0) {
      writer.uint32(209).double(message.cropPointThreshold);
    }
    if (message.weedPointThreshold !== 0) {
      writer.uint32(217).double(message.weedPointThreshold);
    }
    if (message.weedingEnabled !== false) {
      writer.uint32(224).bool(message.weedingEnabled);
    }
    if (message.thinningEnabled !== false) {
      writer.uint32(232).bool(message.thinningEnabled);
    }
    if (message.deepweedId !== "") {
      writer.uint32(242).string(message.deepweedId);
    }
    if (message.p2pId !== "") {
      writer.uint32(250).string(message.p2pId);
    }
    for (const v of message.deepweedDetections) {
      Detection.encode(v!, writer.uint32(258).fork()).join();
    }
    if (message.segmentationThreshold !== 0) {
      writer.uint32(265).double(message.segmentationThreshold);
    }
    if (message.simulatorGenerated !== false) {
      writer.uint32(272).bool(message.simulatorGenerated);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImageAndMetadataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageAndMetadataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bytes = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.width = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.height = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.score = reader.double();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.isoFormattedTime = reader.string();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.llaLat = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.llaLng = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.llaAlt = reader.double();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.llaTimestampMs = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.ecefX = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.ecefY = reader.double();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.ecefZ = reader.double();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.ecefTimestampMs = longToNumber(reader.int64());
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.ppi = reader.float();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.scoreType = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.imageType = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.modelUrl = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 21:
          if (tag === 169) {
            message.weedHeightColumns.push(reader.double());

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.weedHeightColumns.push(reader.double());
            }

            continue;
          }

          break;
        case 22:
          if (tag === 177) {
            message.cropHeightColumns.push(reader.double());

            continue;
          }

          if (tag === 178) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cropHeightColumns.push(reader.double());
            }

            continue;
          }

          break;
        case 23:
          if (tag !== 185) {
            break;
          }

          message.bbhOffsetMm = reader.double();
          continue;
        case 24:
          if (tag !== 193) {
            break;
          }

          message.focusMetric = reader.double();
          continue;
        case 25:
          if (tag !== 201) {
            break;
          }

          message.exposureUs = reader.double();
          continue;
        case 26:
          if (tag !== 209) {
            break;
          }

          message.cropPointThreshold = reader.double();
          continue;
        case 27:
          if (tag !== 217) {
            break;
          }

          message.weedPointThreshold = reader.double();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.weedingEnabled = reader.bool();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.thinningEnabled = reader.bool();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.deepweedId = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.p2pId = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.deepweedDetections.push(Detection.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 265) {
            break;
          }

          message.segmentationThreshold = reader.double();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.simulatorGenerated = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageAndMetadataResponse {
    return {
      bytes: isSet(object.bytes) ? bytesFromBase64(object.bytes) : new Uint8Array(0),
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      isoFormattedTime: isSet(object.isoFormattedTime) ? globalThis.String(object.isoFormattedTime) : "",
      llaLat: isSet(object.llaLat) ? globalThis.Number(object.llaLat) : 0,
      llaLng: isSet(object.llaLng) ? globalThis.Number(object.llaLng) : 0,
      llaAlt: isSet(object.llaAlt) ? globalThis.Number(object.llaAlt) : 0,
      llaTimestampMs: isSet(object.llaTimestampMs) ? globalThis.Number(object.llaTimestampMs) : 0,
      ecefX: isSet(object.ecefX) ? globalThis.Number(object.ecefX) : 0,
      ecefY: isSet(object.ecefY) ? globalThis.Number(object.ecefY) : 0,
      ecefZ: isSet(object.ecefZ) ? globalThis.Number(object.ecefZ) : 0,
      ecefTimestampMs: isSet(object.ecefTimestampMs) ? globalThis.Number(object.ecefTimestampMs) : 0,
      ppi: isSet(object.ppi) ? globalThis.Number(object.ppi) : 0,
      scoreType: isSet(object.scoreType) ? globalThis.String(object.scoreType) : undefined,
      imageType: isSet(object.imageType) ? globalThis.String(object.imageType) : "",
      modelUrl: isSet(object.modelUrl) ? globalThis.String(object.modelUrl) : "",
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      weedHeightColumns: globalThis.Array.isArray(object?.weedHeightColumns)
        ? object.weedHeightColumns.map((e: any) => globalThis.Number(e))
        : [],
      cropHeightColumns: globalThis.Array.isArray(object?.cropHeightColumns)
        ? object.cropHeightColumns.map((e: any) => globalThis.Number(e))
        : [],
      bbhOffsetMm: isSet(object.bbhOffsetMm) ? globalThis.Number(object.bbhOffsetMm) : 0,
      focusMetric: isSet(object.focusMetric) ? globalThis.Number(object.focusMetric) : 0,
      exposureUs: isSet(object.exposureUs) ? globalThis.Number(object.exposureUs) : 0,
      cropPointThreshold: isSet(object.cropPointThreshold) ? globalThis.Number(object.cropPointThreshold) : 0,
      weedPointThreshold: isSet(object.weedPointThreshold) ? globalThis.Number(object.weedPointThreshold) : 0,
      weedingEnabled: isSet(object.weedingEnabled) ? globalThis.Boolean(object.weedingEnabled) : false,
      thinningEnabled: isSet(object.thinningEnabled) ? globalThis.Boolean(object.thinningEnabled) : false,
      deepweedId: isSet(object.deepweedId) ? globalThis.String(object.deepweedId) : "",
      p2pId: isSet(object.p2pId) ? globalThis.String(object.p2pId) : "",
      deepweedDetections: globalThis.Array.isArray(object?.deepweedDetections)
        ? object.deepweedDetections.map((e: any) => Detection.fromJSON(e))
        : [],
      segmentationThreshold: isSet(object.segmentationThreshold) ? globalThis.Number(object.segmentationThreshold) : 0,
      simulatorGenerated: isSet(object.simulatorGenerated) ? globalThis.Boolean(object.simulatorGenerated) : false,
    };
  },

  toJSON(message: ImageAndMetadataResponse): unknown {
    const obj: any = {};
    if (message.bytes.length !== 0) {
      obj.bytes = base64FromBytes(message.bytes);
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.isoFormattedTime !== "") {
      obj.isoFormattedTime = message.isoFormattedTime;
    }
    if (message.llaLat !== 0) {
      obj.llaLat = message.llaLat;
    }
    if (message.llaLng !== 0) {
      obj.llaLng = message.llaLng;
    }
    if (message.llaAlt !== 0) {
      obj.llaAlt = message.llaAlt;
    }
    if (message.llaTimestampMs !== 0) {
      obj.llaTimestampMs = Math.round(message.llaTimestampMs);
    }
    if (message.ecefX !== 0) {
      obj.ecefX = message.ecefX;
    }
    if (message.ecefY !== 0) {
      obj.ecefY = message.ecefY;
    }
    if (message.ecefZ !== 0) {
      obj.ecefZ = message.ecefZ;
    }
    if (message.ecefTimestampMs !== 0) {
      obj.ecefTimestampMs = Math.round(message.ecefTimestampMs);
    }
    if (message.ppi !== 0) {
      obj.ppi = message.ppi;
    }
    if (message.scoreType !== undefined) {
      obj.scoreType = message.scoreType;
    }
    if (message.imageType !== "") {
      obj.imageType = message.imageType;
    }
    if (message.modelUrl !== "") {
      obj.modelUrl = message.modelUrl;
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.weedHeightColumns?.length) {
      obj.weedHeightColumns = message.weedHeightColumns;
    }
    if (message.cropHeightColumns?.length) {
      obj.cropHeightColumns = message.cropHeightColumns;
    }
    if (message.bbhOffsetMm !== 0) {
      obj.bbhOffsetMm = message.bbhOffsetMm;
    }
    if (message.focusMetric !== 0) {
      obj.focusMetric = message.focusMetric;
    }
    if (message.exposureUs !== 0) {
      obj.exposureUs = message.exposureUs;
    }
    if (message.cropPointThreshold !== 0) {
      obj.cropPointThreshold = message.cropPointThreshold;
    }
    if (message.weedPointThreshold !== 0) {
      obj.weedPointThreshold = message.weedPointThreshold;
    }
    if (message.weedingEnabled !== false) {
      obj.weedingEnabled = message.weedingEnabled;
    }
    if (message.thinningEnabled !== false) {
      obj.thinningEnabled = message.thinningEnabled;
    }
    if (message.deepweedId !== "") {
      obj.deepweedId = message.deepweedId;
    }
    if (message.p2pId !== "") {
      obj.p2pId = message.p2pId;
    }
    if (message.deepweedDetections?.length) {
      obj.deepweedDetections = message.deepweedDetections.map((e) => Detection.toJSON(e));
    }
    if (message.segmentationThreshold !== 0) {
      obj.segmentationThreshold = message.segmentationThreshold;
    }
    if (message.simulatorGenerated !== false) {
      obj.simulatorGenerated = message.simulatorGenerated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageAndMetadataResponse>, I>>(base?: I): ImageAndMetadataResponse {
    return ImageAndMetadataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageAndMetadataResponse>, I>>(object: I): ImageAndMetadataResponse {
    const message = createBaseImageAndMetadataResponse();
    message.bytes = object.bytes ?? new Uint8Array(0);
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.timestampMs = object.timestampMs ?? 0;
    message.score = object.score ?? 0;
    message.camId = object.camId ?? "";
    message.isoFormattedTime = object.isoFormattedTime ?? "";
    message.llaLat = object.llaLat ?? 0;
    message.llaLng = object.llaLng ?? 0;
    message.llaAlt = object.llaAlt ?? 0;
    message.llaTimestampMs = object.llaTimestampMs ?? 0;
    message.ecefX = object.ecefX ?? 0;
    message.ecefY = object.ecefY ?? 0;
    message.ecefZ = object.ecefZ ?? 0;
    message.ecefTimestampMs = object.ecefTimestampMs ?? 0;
    message.ppi = object.ppi ?? 0;
    message.scoreType = object.scoreType ?? undefined;
    message.imageType = object.imageType ?? "";
    message.modelUrl = object.modelUrl ?? "";
    message.crop = object.crop ?? "";
    message.weedHeightColumns = object.weedHeightColumns?.map((e) => e) || [];
    message.cropHeightColumns = object.cropHeightColumns?.map((e) => e) || [];
    message.bbhOffsetMm = object.bbhOffsetMm ?? 0;
    message.focusMetric = object.focusMetric ?? 0;
    message.exposureUs = object.exposureUs ?? 0;
    message.cropPointThreshold = object.cropPointThreshold ?? 0;
    message.weedPointThreshold = object.weedPointThreshold ?? 0;
    message.weedingEnabled = object.weedingEnabled ?? false;
    message.thinningEnabled = object.thinningEnabled ?? false;
    message.deepweedId = object.deepweedId ?? "";
    message.p2pId = object.p2pId ?? "";
    message.deepweedDetections = object.deepweedDetections?.map((e) => Detection.fromPartial(e)) || [];
    message.segmentationThreshold = object.segmentationThreshold ?? 0;
    message.simulatorGenerated = object.simulatorGenerated ?? false;
    return message;
  },
};

function createBaseP2PImageAndMetadataResponse(): P2PImageAndMetadataResponse {
  return {
    targetBytes: new Uint8Array(0),
    targetWidth: 0,
    targetHeight: 0,
    perspectiveBytes: new Uint8Array(0),
    perspectiveWidth: 0,
    perspectiveHeight: 0,
    annotatedTargetBytes: new Uint8Array(0),
    annotatedTargetWidth: 0,
    annotatedTargetHeight: 0,
    timestampMs: 0,
    score: 0,
    camId: "",
    isoFormattedTime: "",
    llaLat: 0,
    llaLng: 0,
    llaAlt: 0,
    llaTimestampMs: 0,
    ecefX: 0,
    ecefY: 0,
    ecefZ: 0,
    ecefTimestampMs: 0,
    ppi: 0,
    perspectivePpi: 0,
    imageType: "",
    modelUrl: "",
    crop: "",
    focusMetric: 0,
    exposureUs: 0,
    weedingEnabled: false,
    thinningEnabled: false,
    deepweedId: "",
    p2pId: "",
  };
}

export const P2PImageAndMetadataResponse: MessageFns<P2PImageAndMetadataResponse> = {
  encode(message: P2PImageAndMetadataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetBytes.length !== 0) {
      writer.uint32(10).bytes(message.targetBytes);
    }
    if (message.targetWidth !== 0) {
      writer.uint32(16).int32(message.targetWidth);
    }
    if (message.targetHeight !== 0) {
      writer.uint32(24).int32(message.targetHeight);
    }
    if (message.perspectiveBytes.length !== 0) {
      writer.uint32(34).bytes(message.perspectiveBytes);
    }
    if (message.perspectiveWidth !== 0) {
      writer.uint32(40).int32(message.perspectiveWidth);
    }
    if (message.perspectiveHeight !== 0) {
      writer.uint32(48).int32(message.perspectiveHeight);
    }
    if (message.annotatedTargetBytes.length !== 0) {
      writer.uint32(58).bytes(message.annotatedTargetBytes);
    }
    if (message.annotatedTargetWidth !== 0) {
      writer.uint32(64).int32(message.annotatedTargetWidth);
    }
    if (message.annotatedTargetHeight !== 0) {
      writer.uint32(72).int32(message.annotatedTargetHeight);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(80).int64(message.timestampMs);
    }
    if (message.score !== 0) {
      writer.uint32(89).double(message.score);
    }
    if (message.camId !== "") {
      writer.uint32(98).string(message.camId);
    }
    if (message.isoFormattedTime !== "") {
      writer.uint32(106).string(message.isoFormattedTime);
    }
    if (message.llaLat !== 0) {
      writer.uint32(113).double(message.llaLat);
    }
    if (message.llaLng !== 0) {
      writer.uint32(121).double(message.llaLng);
    }
    if (message.llaAlt !== 0) {
      writer.uint32(129).double(message.llaAlt);
    }
    if (message.llaTimestampMs !== 0) {
      writer.uint32(136).int64(message.llaTimestampMs);
    }
    if (message.ecefX !== 0) {
      writer.uint32(145).double(message.ecefX);
    }
    if (message.ecefY !== 0) {
      writer.uint32(153).double(message.ecefY);
    }
    if (message.ecefZ !== 0) {
      writer.uint32(161).double(message.ecefZ);
    }
    if (message.ecefTimestampMs !== 0) {
      writer.uint32(168).int64(message.ecefTimestampMs);
    }
    if (message.ppi !== 0) {
      writer.uint32(181).float(message.ppi);
    }
    if (message.perspectivePpi !== 0) {
      writer.uint32(189).float(message.perspectivePpi);
    }
    if (message.imageType !== "") {
      writer.uint32(202).string(message.imageType);
    }
    if (message.modelUrl !== "") {
      writer.uint32(210).string(message.modelUrl);
    }
    if (message.crop !== "") {
      writer.uint32(218).string(message.crop);
    }
    if (message.focusMetric !== 0) {
      writer.uint32(225).double(message.focusMetric);
    }
    if (message.exposureUs !== 0) {
      writer.uint32(233).double(message.exposureUs);
    }
    if (message.weedingEnabled !== false) {
      writer.uint32(240).bool(message.weedingEnabled);
    }
    if (message.thinningEnabled !== false) {
      writer.uint32(248).bool(message.thinningEnabled);
    }
    if (message.deepweedId !== "") {
      writer.uint32(258).string(message.deepweedId);
    }
    if (message.p2pId !== "") {
      writer.uint32(266).string(message.p2pId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PImageAndMetadataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PImageAndMetadataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetBytes = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetWidth = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.targetHeight = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.perspectiveBytes = reader.bytes();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.perspectiveWidth = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.perspectiveHeight = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.annotatedTargetBytes = reader.bytes();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.annotatedTargetWidth = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.annotatedTargetHeight = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.score = reader.double();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.isoFormattedTime = reader.string();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.llaLat = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.llaLng = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.llaAlt = reader.double();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.llaTimestampMs = longToNumber(reader.int64());
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.ecefX = reader.double();
          continue;
        case 19:
          if (tag !== 153) {
            break;
          }

          message.ecefY = reader.double();
          continue;
        case 20:
          if (tag !== 161) {
            break;
          }

          message.ecefZ = reader.double();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.ecefTimestampMs = longToNumber(reader.int64());
          continue;
        case 22:
          if (tag !== 181) {
            break;
          }

          message.ppi = reader.float();
          continue;
        case 23:
          if (tag !== 189) {
            break;
          }

          message.perspectivePpi = reader.float();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.imageType = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.modelUrl = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 28:
          if (tag !== 225) {
            break;
          }

          message.focusMetric = reader.double();
          continue;
        case 29:
          if (tag !== 233) {
            break;
          }

          message.exposureUs = reader.double();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.weedingEnabled = reader.bool();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.thinningEnabled = reader.bool();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.deepweedId = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.p2pId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): P2PImageAndMetadataResponse {
    return {
      targetBytes: isSet(object.targetBytes) ? bytesFromBase64(object.targetBytes) : new Uint8Array(0),
      targetWidth: isSet(object.targetWidth) ? globalThis.Number(object.targetWidth) : 0,
      targetHeight: isSet(object.targetHeight) ? globalThis.Number(object.targetHeight) : 0,
      perspectiveBytes: isSet(object.perspectiveBytes) ? bytesFromBase64(object.perspectiveBytes) : new Uint8Array(0),
      perspectiveWidth: isSet(object.perspectiveWidth) ? globalThis.Number(object.perspectiveWidth) : 0,
      perspectiveHeight: isSet(object.perspectiveHeight) ? globalThis.Number(object.perspectiveHeight) : 0,
      annotatedTargetBytes: isSet(object.annotatedTargetBytes)
        ? bytesFromBase64(object.annotatedTargetBytes)
        : new Uint8Array(0),
      annotatedTargetWidth: isSet(object.annotatedTargetWidth) ? globalThis.Number(object.annotatedTargetWidth) : 0,
      annotatedTargetHeight: isSet(object.annotatedTargetHeight) ? globalThis.Number(object.annotatedTargetHeight) : 0,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      isoFormattedTime: isSet(object.isoFormattedTime) ? globalThis.String(object.isoFormattedTime) : "",
      llaLat: isSet(object.llaLat) ? globalThis.Number(object.llaLat) : 0,
      llaLng: isSet(object.llaLng) ? globalThis.Number(object.llaLng) : 0,
      llaAlt: isSet(object.llaAlt) ? globalThis.Number(object.llaAlt) : 0,
      llaTimestampMs: isSet(object.llaTimestampMs) ? globalThis.Number(object.llaTimestampMs) : 0,
      ecefX: isSet(object.ecefX) ? globalThis.Number(object.ecefX) : 0,
      ecefY: isSet(object.ecefY) ? globalThis.Number(object.ecefY) : 0,
      ecefZ: isSet(object.ecefZ) ? globalThis.Number(object.ecefZ) : 0,
      ecefTimestampMs: isSet(object.ecefTimestampMs) ? globalThis.Number(object.ecefTimestampMs) : 0,
      ppi: isSet(object.ppi) ? globalThis.Number(object.ppi) : 0,
      perspectivePpi: isSet(object.perspectivePpi) ? globalThis.Number(object.perspectivePpi) : 0,
      imageType: isSet(object.imageType) ? globalThis.String(object.imageType) : "",
      modelUrl: isSet(object.modelUrl) ? globalThis.String(object.modelUrl) : "",
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      focusMetric: isSet(object.focusMetric) ? globalThis.Number(object.focusMetric) : 0,
      exposureUs: isSet(object.exposureUs) ? globalThis.Number(object.exposureUs) : 0,
      weedingEnabled: isSet(object.weedingEnabled) ? globalThis.Boolean(object.weedingEnabled) : false,
      thinningEnabled: isSet(object.thinningEnabled) ? globalThis.Boolean(object.thinningEnabled) : false,
      deepweedId: isSet(object.deepweedId) ? globalThis.String(object.deepweedId) : "",
      p2pId: isSet(object.p2pId) ? globalThis.String(object.p2pId) : "",
    };
  },

  toJSON(message: P2PImageAndMetadataResponse): unknown {
    const obj: any = {};
    if (message.targetBytes.length !== 0) {
      obj.targetBytes = base64FromBytes(message.targetBytes);
    }
    if (message.targetWidth !== 0) {
      obj.targetWidth = Math.round(message.targetWidth);
    }
    if (message.targetHeight !== 0) {
      obj.targetHeight = Math.round(message.targetHeight);
    }
    if (message.perspectiveBytes.length !== 0) {
      obj.perspectiveBytes = base64FromBytes(message.perspectiveBytes);
    }
    if (message.perspectiveWidth !== 0) {
      obj.perspectiveWidth = Math.round(message.perspectiveWidth);
    }
    if (message.perspectiveHeight !== 0) {
      obj.perspectiveHeight = Math.round(message.perspectiveHeight);
    }
    if (message.annotatedTargetBytes.length !== 0) {
      obj.annotatedTargetBytes = base64FromBytes(message.annotatedTargetBytes);
    }
    if (message.annotatedTargetWidth !== 0) {
      obj.annotatedTargetWidth = Math.round(message.annotatedTargetWidth);
    }
    if (message.annotatedTargetHeight !== 0) {
      obj.annotatedTargetHeight = Math.round(message.annotatedTargetHeight);
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.isoFormattedTime !== "") {
      obj.isoFormattedTime = message.isoFormattedTime;
    }
    if (message.llaLat !== 0) {
      obj.llaLat = message.llaLat;
    }
    if (message.llaLng !== 0) {
      obj.llaLng = message.llaLng;
    }
    if (message.llaAlt !== 0) {
      obj.llaAlt = message.llaAlt;
    }
    if (message.llaTimestampMs !== 0) {
      obj.llaTimestampMs = Math.round(message.llaTimestampMs);
    }
    if (message.ecefX !== 0) {
      obj.ecefX = message.ecefX;
    }
    if (message.ecefY !== 0) {
      obj.ecefY = message.ecefY;
    }
    if (message.ecefZ !== 0) {
      obj.ecefZ = message.ecefZ;
    }
    if (message.ecefTimestampMs !== 0) {
      obj.ecefTimestampMs = Math.round(message.ecefTimestampMs);
    }
    if (message.ppi !== 0) {
      obj.ppi = message.ppi;
    }
    if (message.perspectivePpi !== 0) {
      obj.perspectivePpi = message.perspectivePpi;
    }
    if (message.imageType !== "") {
      obj.imageType = message.imageType;
    }
    if (message.modelUrl !== "") {
      obj.modelUrl = message.modelUrl;
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.focusMetric !== 0) {
      obj.focusMetric = message.focusMetric;
    }
    if (message.exposureUs !== 0) {
      obj.exposureUs = message.exposureUs;
    }
    if (message.weedingEnabled !== false) {
      obj.weedingEnabled = message.weedingEnabled;
    }
    if (message.thinningEnabled !== false) {
      obj.thinningEnabled = message.thinningEnabled;
    }
    if (message.deepweedId !== "") {
      obj.deepweedId = message.deepweedId;
    }
    if (message.p2pId !== "") {
      obj.p2pId = message.p2pId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PImageAndMetadataResponse>, I>>(base?: I): P2PImageAndMetadataResponse {
    return P2PImageAndMetadataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PImageAndMetadataResponse>, I>>(object: I): P2PImageAndMetadataResponse {
    const message = createBaseP2PImageAndMetadataResponse();
    message.targetBytes = object.targetBytes ?? new Uint8Array(0);
    message.targetWidth = object.targetWidth ?? 0;
    message.targetHeight = object.targetHeight ?? 0;
    message.perspectiveBytes = object.perspectiveBytes ?? new Uint8Array(0);
    message.perspectiveWidth = object.perspectiveWidth ?? 0;
    message.perspectiveHeight = object.perspectiveHeight ?? 0;
    message.annotatedTargetBytes = object.annotatedTargetBytes ?? new Uint8Array(0);
    message.annotatedTargetWidth = object.annotatedTargetWidth ?? 0;
    message.annotatedTargetHeight = object.annotatedTargetHeight ?? 0;
    message.timestampMs = object.timestampMs ?? 0;
    message.score = object.score ?? 0;
    message.camId = object.camId ?? "";
    message.isoFormattedTime = object.isoFormattedTime ?? "";
    message.llaLat = object.llaLat ?? 0;
    message.llaLng = object.llaLng ?? 0;
    message.llaAlt = object.llaAlt ?? 0;
    message.llaTimestampMs = object.llaTimestampMs ?? 0;
    message.ecefX = object.ecefX ?? 0;
    message.ecefY = object.ecefY ?? 0;
    message.ecefZ = object.ecefZ ?? 0;
    message.ecefTimestampMs = object.ecefTimestampMs ?? 0;
    message.ppi = object.ppi ?? 0;
    message.perspectivePpi = object.perspectivePpi ?? 0;
    message.imageType = object.imageType ?? "";
    message.modelUrl = object.modelUrl ?? "";
    message.crop = object.crop ?? "";
    message.focusMetric = object.focusMetric ?? 0;
    message.exposureUs = object.exposureUs ?? 0;
    message.weedingEnabled = object.weedingEnabled ?? false;
    message.thinningEnabled = object.thinningEnabled ?? false;
    message.deepweedId = object.deepweedId ?? "";
    message.p2pId = object.p2pId ?? "";
    return message;
  },
};

function createBaseGetCameraInfoRequest(): GetCameraInfoRequest {
  return {};
}

export const GetCameraInfoRequest: MessageFns<GetCameraInfoRequest> = {
  encode(_: GetCameraInfoRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraInfoRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetCameraInfoRequest {
    return {};
  },

  toJSON(_: GetCameraInfoRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraInfoRequest>, I>>(base?: I): GetCameraInfoRequest {
    return GetCameraInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraInfoRequest>, I>>(_: I): GetCameraInfoRequest {
    const message = createBaseGetCameraInfoRequest();
    return message;
  },
};

function createBaseCameraInfo(): CameraInfo {
  return {
    camId: "",
    ipAddress: undefined,
    serialNumber: undefined,
    model: "",
    width: 0,
    height: 0,
    connected: false,
    linkSpeed: 0,
  };
}

export const CameraInfo: MessageFns<CameraInfo> = {
  encode(message: CameraInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.ipAddress !== undefined) {
      writer.uint32(18).string(message.ipAddress);
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(26).string(message.serialNumber);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    if (message.width !== 0) {
      writer.uint32(40).uint32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(48).uint32(message.height);
    }
    if (message.connected !== false) {
      writer.uint32(56).bool(message.connected);
    }
    if (message.linkSpeed !== 0) {
      writer.uint32(64).uint64(message.linkSpeed);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CameraInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.width = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.height = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.connected = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.linkSpeed = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraInfo {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      ipAddress: isSet(object.ipAddress) ? globalThis.String(object.ipAddress) : undefined,
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : undefined,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      connected: isSet(object.connected) ? globalThis.Boolean(object.connected) : false,
      linkSpeed: isSet(object.linkSpeed) ? globalThis.Number(object.linkSpeed) : 0,
    };
  },

  toJSON(message: CameraInfo): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.ipAddress !== undefined) {
      obj.ipAddress = message.ipAddress;
    }
    if (message.serialNumber !== undefined) {
      obj.serialNumber = message.serialNumber;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.connected !== false) {
      obj.connected = message.connected;
    }
    if (message.linkSpeed !== 0) {
      obj.linkSpeed = Math.round(message.linkSpeed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraInfo>, I>>(base?: I): CameraInfo {
    return CameraInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraInfo>, I>>(object: I): CameraInfo {
    const message = createBaseCameraInfo();
    message.camId = object.camId ?? "";
    message.ipAddress = object.ipAddress ?? undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.model = object.model ?? "";
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.connected = object.connected ?? false;
    message.linkSpeed = object.linkSpeed ?? 0;
    return message;
  },
};

function createBaseGetCameraInfoResponse(): GetCameraInfoResponse {
  return { cameraInfo: [] };
}

export const GetCameraInfoResponse: MessageFns<GetCameraInfoResponse> = {
  encode(message: GetCameraInfoResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.cameraInfo) {
      CameraInfo.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCameraInfoResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraInfo.push(CameraInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraInfoResponse {
    return {
      cameraInfo: globalThis.Array.isArray(object?.cameraInfo)
        ? object.cameraInfo.map((e: any) => CameraInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCameraInfoResponse): unknown {
    const obj: any = {};
    if (message.cameraInfo?.length) {
      obj.cameraInfo = message.cameraInfo.map((e) => CameraInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraInfoResponse>, I>>(base?: I): GetCameraInfoResponse {
    return GetCameraInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraInfoResponse>, I>>(object: I): GetCameraInfoResponse {
    const message = createBaseGetCameraInfoResponse();
    message.cameraInfo = object.cameraInfo?.map((e) => CameraInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetLightweightBurstRecordRequest(): GetLightweightBurstRecordRequest {
  return {};
}

export const GetLightweightBurstRecordRequest: MessageFns<GetLightweightBurstRecordRequest> = {
  encode(_: GetLightweightBurstRecordRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLightweightBurstRecordRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLightweightBurstRecordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetLightweightBurstRecordRequest {
    return {};
  },

  toJSON(_: GetLightweightBurstRecordRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLightweightBurstRecordRequest>, I>>(
    base?: I,
  ): GetLightweightBurstRecordRequest {
    return GetLightweightBurstRecordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLightweightBurstRecordRequest>, I>>(
    _: I,
  ): GetLightweightBurstRecordRequest {
    const message = createBaseGetLightweightBurstRecordRequest();
    return message;
  },
};

function createBaseGetLightweightBurstRecordResponse(): GetLightweightBurstRecordResponse {
  return { zipFile: new Uint8Array(0), metadataFile: new Uint8Array(0) };
}

export const GetLightweightBurstRecordResponse: MessageFns<GetLightweightBurstRecordResponse> = {
  encode(message: GetLightweightBurstRecordResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.zipFile.length !== 0) {
      writer.uint32(10).bytes(message.zipFile);
    }
    if (message.metadataFile.length !== 0) {
      writer.uint32(18).bytes(message.metadataFile);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLightweightBurstRecordResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLightweightBurstRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.zipFile = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metadataFile = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLightweightBurstRecordResponse {
    return {
      zipFile: isSet(object.zipFile) ? bytesFromBase64(object.zipFile) : new Uint8Array(0),
      metadataFile: isSet(object.metadataFile) ? bytesFromBase64(object.metadataFile) : new Uint8Array(0),
    };
  },

  toJSON(message: GetLightweightBurstRecordResponse): unknown {
    const obj: any = {};
    if (message.zipFile.length !== 0) {
      obj.zipFile = base64FromBytes(message.zipFile);
    }
    if (message.metadataFile.length !== 0) {
      obj.metadataFile = base64FromBytes(message.metadataFile);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLightweightBurstRecordResponse>, I>>(
    base?: I,
  ): GetLightweightBurstRecordResponse {
    return GetLightweightBurstRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLightweightBurstRecordResponse>, I>>(
    object: I,
  ): GetLightweightBurstRecordResponse {
    const message = createBaseGetLightweightBurstRecordResponse();
    message.zipFile = object.zipFile ?? new Uint8Array(0);
    message.metadataFile = object.metadataFile ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetBootedRequest(): GetBootedRequest {
  return {};
}

export const GetBootedRequest: MessageFns<GetBootedRequest> = {
  encode(_: GetBootedRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBootedRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBootedRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetBootedRequest {
    return {};
  },

  toJSON(_: GetBootedRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBootedRequest>, I>>(base?: I): GetBootedRequest {
    return GetBootedRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBootedRequest>, I>>(_: I): GetBootedRequest {
    const message = createBaseGetBootedRequest();
    return message;
  },
};

function createBaseGetBootedResponse(): GetBootedResponse {
  return { booted: false };
}

export const GetBootedResponse: MessageFns<GetBootedResponse> = {
  encode(message: GetBootedResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.booted !== false) {
      writer.uint32(8).bool(message.booted);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBootedResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBootedResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.booted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBootedResponse {
    return { booted: isSet(object.booted) ? globalThis.Boolean(object.booted) : false };
  },

  toJSON(message: GetBootedResponse): unknown {
    const obj: any = {};
    if (message.booted !== false) {
      obj.booted = message.booted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBootedResponse>, I>>(base?: I): GetBootedResponse {
    return GetBootedResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBootedResponse>, I>>(object: I): GetBootedResponse {
    const message = createBaseGetBootedResponse();
    message.booted = object.booted ?? false;
    return message;
  },
};

function createBaseGetReadyRequest(): GetReadyRequest {
  return {};
}

export const GetReadyRequest: MessageFns<GetReadyRequest> = {
  encode(_: GetReadyRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetReadyRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReadyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetReadyRequest {
    return {};
  },

  toJSON(_: GetReadyRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReadyRequest>, I>>(base?: I): GetReadyRequest {
    return GetReadyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReadyRequest>, I>>(_: I): GetReadyRequest {
    const message = createBaseGetReadyRequest();
    return message;
  },
};

function createBaseGetReadyResponse(): GetReadyResponse {
  return { ready: false };
}

export const GetReadyResponse: MessageFns<GetReadyResponse> = {
  encode(message: GetReadyResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ready !== false) {
      writer.uint32(8).bool(message.ready);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetReadyResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReadyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ready = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReadyResponse {
    return { ready: isSet(object.ready) ? globalThis.Boolean(object.ready) : false };
  },

  toJSON(message: GetReadyResponse): unknown {
    const obj: any = {};
    if (message.ready !== false) {
      obj.ready = message.ready;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReadyResponse>, I>>(base?: I): GetReadyResponse {
    return GetReadyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReadyResponse>, I>>(object: I): GetReadyResponse {
    const message = createBaseGetReadyResponse();
    message.ready = object.ready ?? false;
    return message;
  },
};

function createBaseDeepweedDetection(): DeepweedDetection {
  return {
    x: 0,
    y: 0,
    size: 0,
    score: 0,
    detectionClassScores: {},
    hitClass: 0,
    maskIntersections: [],
    weedScore: 0,
    cropScore: 0,
  };
}

export const DeepweedDetection: MessageFns<DeepweedDetection> = {
  encode(message: DeepweedDetection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    if (message.size !== 0) {
      writer.uint32(29).float(message.size);
    }
    if (message.score !== 0) {
      writer.uint32(37).float(message.score);
    }
    Object.entries(message.detectionClassScores).forEach(([key, value]) => {
      DeepweedDetection_DetectionClassScoresEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).join();
    });
    if (message.hitClass !== 0) {
      writer.uint32(48).int32(message.hitClass);
    }
    for (const v of message.maskIntersections) {
      writer.uint32(58).string(v!);
    }
    if (message.weedScore !== 0) {
      writer.uint32(69).float(message.weedScore);
    }
    if (message.cropScore !== 0) {
      writer.uint32(77).float(message.cropScore);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedDetection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedDetection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.size = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.score = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = DeepweedDetection_DetectionClassScoresEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.detectionClassScores[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hitClass = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.maskIntersections.push(reader.string());
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.weedScore = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.cropScore = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedDetection {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      detectionClassScores: isObject(object.detectionClassScores)
        ? Object.entries(object.detectionClassScores).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      hitClass: isSet(object.hitClass) ? hitClassFromJSON(object.hitClass) : 0,
      maskIntersections: globalThis.Array.isArray(object?.maskIntersections)
        ? object.maskIntersections.map((e: any) => globalThis.String(e))
        : [],
      weedScore: isSet(object.weedScore) ? globalThis.Number(object.weedScore) : 0,
      cropScore: isSet(object.cropScore) ? globalThis.Number(object.cropScore) : 0,
    };
  },

  toJSON(message: DeepweedDetection): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.size !== 0) {
      obj.size = message.size;
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.detectionClassScores) {
      const entries = Object.entries(message.detectionClassScores);
      if (entries.length > 0) {
        obj.detectionClassScores = {};
        entries.forEach(([k, v]) => {
          obj.detectionClassScores[k] = v;
        });
      }
    }
    if (message.hitClass !== 0) {
      obj.hitClass = hitClassToJSON(message.hitClass);
    }
    if (message.maskIntersections?.length) {
      obj.maskIntersections = message.maskIntersections;
    }
    if (message.weedScore !== 0) {
      obj.weedScore = message.weedScore;
    }
    if (message.cropScore !== 0) {
      obj.cropScore = message.cropScore;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedDetection>, I>>(base?: I): DeepweedDetection {
    return DeepweedDetection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedDetection>, I>>(object: I): DeepweedDetection {
    const message = createBaseDeepweedDetection();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.size = object.size ?? 0;
    message.score = object.score ?? 0;
    message.detectionClassScores = Object.entries(object.detectionClassScores ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.hitClass = object.hitClass ?? 0;
    message.maskIntersections = object.maskIntersections?.map((e) => e) || [];
    message.weedScore = object.weedScore ?? 0;
    message.cropScore = object.cropScore ?? 0;
    return message;
  },
};

function createBaseDeepweedDetection_DetectionClassScoresEntry(): DeepweedDetection_DetectionClassScoresEntry {
  return { key: "", value: 0 };
}

export const DeepweedDetection_DetectionClassScoresEntry: MessageFns<DeepweedDetection_DetectionClassScoresEntry> = {
  encode(
    message: DeepweedDetection_DetectionClassScoresEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedDetection_DetectionClassScoresEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedDetection_DetectionClassScoresEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedDetection_DetectionClassScoresEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: DeepweedDetection_DetectionClassScoresEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedDetection_DetectionClassScoresEntry>, I>>(
    base?: I,
  ): DeepweedDetection_DetectionClassScoresEntry {
    return DeepweedDetection_DetectionClassScoresEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedDetection_DetectionClassScoresEntry>, I>>(
    object: I,
  ): DeepweedDetection_DetectionClassScoresEntry {
    const message = createBaseDeepweedDetection_DetectionClassScoresEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseDeepweedOutput(): DeepweedOutput {
  return {
    detections: [],
    maskWidth: 0,
    maskHeight: 0,
    maskChannels: 0,
    mask: [],
    maskChannelClasses: [],
    predictInDistanceBuffer: false,
    timestampMs: 0,
  };
}

export const DeepweedOutput: MessageFns<DeepweedOutput> = {
  encode(message: DeepweedOutput, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.detections) {
      DeepweedDetection.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.maskWidth !== 0) {
      writer.uint32(16).uint32(message.maskWidth);
    }
    if (message.maskHeight !== 0) {
      writer.uint32(24).uint32(message.maskHeight);
    }
    if (message.maskChannels !== 0) {
      writer.uint32(32).uint32(message.maskChannels);
    }
    writer.uint32(42).fork();
    for (const v of message.mask) {
      writer.int32(v);
    }
    writer.join();
    for (const v of message.maskChannelClasses) {
      writer.uint32(50).string(v!);
    }
    if (message.predictInDistanceBuffer !== false) {
      writer.uint32(56).bool(message.predictInDistanceBuffer);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(64).int64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedOutput {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedOutput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detections.push(DeepweedDetection.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maskWidth = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maskHeight = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maskChannels = reader.uint32();
          continue;
        case 5:
          if (tag === 40) {
            message.mask.push(reader.int32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.mask.push(reader.int32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.maskChannelClasses.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.predictInDistanceBuffer = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedOutput {
    return {
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => DeepweedDetection.fromJSON(e))
        : [],
      maskWidth: isSet(object.maskWidth) ? globalThis.Number(object.maskWidth) : 0,
      maskHeight: isSet(object.maskHeight) ? globalThis.Number(object.maskHeight) : 0,
      maskChannels: isSet(object.maskChannels) ? globalThis.Number(object.maskChannels) : 0,
      mask: globalThis.Array.isArray(object?.mask) ? object.mask.map((e: any) => globalThis.Number(e)) : [],
      maskChannelClasses: globalThis.Array.isArray(object?.maskChannelClasses)
        ? object.maskChannelClasses.map((e: any) => globalThis.String(e))
        : [],
      predictInDistanceBuffer: isSet(object.predictInDistanceBuffer)
        ? globalThis.Boolean(object.predictInDistanceBuffer)
        : false,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
    };
  },

  toJSON(message: DeepweedOutput): unknown {
    const obj: any = {};
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => DeepweedDetection.toJSON(e));
    }
    if (message.maskWidth !== 0) {
      obj.maskWidth = Math.round(message.maskWidth);
    }
    if (message.maskHeight !== 0) {
      obj.maskHeight = Math.round(message.maskHeight);
    }
    if (message.maskChannels !== 0) {
      obj.maskChannels = Math.round(message.maskChannels);
    }
    if (message.mask?.length) {
      obj.mask = message.mask.map((e) => Math.round(e));
    }
    if (message.maskChannelClasses?.length) {
      obj.maskChannelClasses = message.maskChannelClasses;
    }
    if (message.predictInDistanceBuffer !== false) {
      obj.predictInDistanceBuffer = message.predictInDistanceBuffer;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedOutput>, I>>(base?: I): DeepweedOutput {
    return DeepweedOutput.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedOutput>, I>>(object: I): DeepweedOutput {
    const message = createBaseDeepweedOutput();
    message.detections = object.detections?.map((e) => DeepweedDetection.fromPartial(e)) || [];
    message.maskWidth = object.maskWidth ?? 0;
    message.maskHeight = object.maskHeight ?? 0;
    message.maskChannels = object.maskChannels ?? 0;
    message.mask = object.mask?.map((e) => e) || [];
    message.maskChannelClasses = object.maskChannelClasses?.map((e) => e) || [];
    message.predictInDistanceBuffer = object.predictInDistanceBuffer ?? false;
    message.timestampMs = object.timestampMs ?? 0;
    return message;
  },
};

function createBaseGetDeepweedOutputByTimestampRequest(): GetDeepweedOutputByTimestampRequest {
  return { camId: "", timestampMs: 0 };
}

export const GetDeepweedOutputByTimestampRequest: MessageFns<GetDeepweedOutputByTimestampRequest> = {
  encode(message: GetDeepweedOutputByTimestampRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeepweedOutputByTimestampRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeepweedOutputByTimestampRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeepweedOutputByTimestampRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
    };
  },

  toJSON(message: GetDeepweedOutputByTimestampRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeepweedOutputByTimestampRequest>, I>>(
    base?: I,
  ): GetDeepweedOutputByTimestampRequest {
    return GetDeepweedOutputByTimestampRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeepweedOutputByTimestampRequest>, I>>(
    object: I,
  ): GetDeepweedOutputByTimestampRequest {
    const message = createBaseGetDeepweedOutputByTimestampRequest();
    message.camId = object.camId ?? "";
    message.timestampMs = object.timestampMs ?? 0;
    return message;
  },
};

function createBaseGetRecommendedStrobeSettingsRequest(): GetRecommendedStrobeSettingsRequest {
  return {};
}

export const GetRecommendedStrobeSettingsRequest: MessageFns<GetRecommendedStrobeSettingsRequest> = {
  encode(_: GetRecommendedStrobeSettingsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRecommendedStrobeSettingsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecommendedStrobeSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetRecommendedStrobeSettingsRequest {
    return {};
  },

  toJSON(_: GetRecommendedStrobeSettingsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRecommendedStrobeSettingsRequest>, I>>(
    base?: I,
  ): GetRecommendedStrobeSettingsRequest {
    return GetRecommendedStrobeSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRecommendedStrobeSettingsRequest>, I>>(
    _: I,
  ): GetRecommendedStrobeSettingsRequest {
    const message = createBaseGetRecommendedStrobeSettingsRequest();
    return message;
  },
};

function createBaseGetRecommendedStrobeSettingsResponse(): GetRecommendedStrobeSettingsResponse {
  return { targetCameraFps: 0, targetsPerPredictRatio: 0 };
}

export const GetRecommendedStrobeSettingsResponse: MessageFns<GetRecommendedStrobeSettingsResponse> = {
  encode(message: GetRecommendedStrobeSettingsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetCameraFps !== 0) {
      writer.uint32(13).float(message.targetCameraFps);
    }
    if (message.targetsPerPredictRatio !== 0) {
      writer.uint32(16).int32(message.targetsPerPredictRatio);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRecommendedStrobeSettingsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecommendedStrobeSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.targetCameraFps = reader.float();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetsPerPredictRatio = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecommendedStrobeSettingsResponse {
    return {
      targetCameraFps: isSet(object.targetCameraFps) ? globalThis.Number(object.targetCameraFps) : 0,
      targetsPerPredictRatio: isSet(object.targetsPerPredictRatio)
        ? globalThis.Number(object.targetsPerPredictRatio)
        : 0,
    };
  },

  toJSON(message: GetRecommendedStrobeSettingsResponse): unknown {
    const obj: any = {};
    if (message.targetCameraFps !== 0) {
      obj.targetCameraFps = message.targetCameraFps;
    }
    if (message.targetsPerPredictRatio !== 0) {
      obj.targetsPerPredictRatio = Math.round(message.targetsPerPredictRatio);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRecommendedStrobeSettingsResponse>, I>>(
    base?: I,
  ): GetRecommendedStrobeSettingsResponse {
    return GetRecommendedStrobeSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRecommendedStrobeSettingsResponse>, I>>(
    object: I,
  ): GetRecommendedStrobeSettingsResponse {
    const message = createBaseGetRecommendedStrobeSettingsResponse();
    message.targetCameraFps = object.targetCameraFps ?? 0;
    message.targetsPerPredictRatio = object.targetsPerPredictRatio ?? 0;
    return message;
  },
};

function createBaseStartP2PBufferringRequest(): StartP2PBufferringRequest {
  return { camId: "" };
}

export const StartP2PBufferringRequest: MessageFns<StartP2PBufferringRequest> = {
  encode(message: StartP2PBufferringRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartP2PBufferringRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartP2PBufferringRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartP2PBufferringRequest {
    return { camId: isSet(object.camId) ? globalThis.String(object.camId) : "" };
  },

  toJSON(message: StartP2PBufferringRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartP2PBufferringRequest>, I>>(base?: I): StartP2PBufferringRequest {
    return StartP2PBufferringRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartP2PBufferringRequest>, I>>(object: I): StartP2PBufferringRequest {
    const message = createBaseStartP2PBufferringRequest();
    message.camId = object.camId ?? "";
    return message;
  },
};

function createBaseStartP2PBufferringResponse(): StartP2PBufferringResponse {
  return {};
}

export const StartP2PBufferringResponse: MessageFns<StartP2PBufferringResponse> = {
  encode(_: StartP2PBufferringResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartP2PBufferringResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartP2PBufferringResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StartP2PBufferringResponse {
    return {};
  },

  toJSON(_: StartP2PBufferringResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StartP2PBufferringResponse>, I>>(base?: I): StartP2PBufferringResponse {
    return StartP2PBufferringResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartP2PBufferringResponse>, I>>(_: I): StartP2PBufferringResponse {
    const message = createBaseStartP2PBufferringResponse();
    return message;
  },
};

function createBaseStopP2PBufferringRequest(): StopP2PBufferringRequest {
  return {
    saveBurst: false,
    camId: "",
    path: "",
    dontCapturePredictImage: false,
    startTimestampMs: 0,
    endTimestampMs: 0,
  };
}

export const StopP2PBufferringRequest: MessageFns<StopP2PBufferringRequest> = {
  encode(message: StopP2PBufferringRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.saveBurst !== false) {
      writer.uint32(8).bool(message.saveBurst);
    }
    if (message.camId !== "") {
      writer.uint32(18).string(message.camId);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.dontCapturePredictImage !== false) {
      writer.uint32(32).bool(message.dontCapturePredictImage);
    }
    if (message.startTimestampMs !== 0) {
      writer.uint32(40).int64(message.startTimestampMs);
    }
    if (message.endTimestampMs !== 0) {
      writer.uint32(48).int64(message.endTimestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopP2PBufferringRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopP2PBufferringRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.saveBurst = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dontCapturePredictImage = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.startTimestampMs = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.endTimestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopP2PBufferringRequest {
    return {
      saveBurst: isSet(object.saveBurst) ? globalThis.Boolean(object.saveBurst) : false,
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      dontCapturePredictImage: isSet(object.dontCapturePredictImage)
        ? globalThis.Boolean(object.dontCapturePredictImage)
        : false,
      startTimestampMs: isSet(object.startTimestampMs) ? globalThis.Number(object.startTimestampMs) : 0,
      endTimestampMs: isSet(object.endTimestampMs) ? globalThis.Number(object.endTimestampMs) : 0,
    };
  },

  toJSON(message: StopP2PBufferringRequest): unknown {
    const obj: any = {};
    if (message.saveBurst !== false) {
      obj.saveBurst = message.saveBurst;
    }
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.dontCapturePredictImage !== false) {
      obj.dontCapturePredictImage = message.dontCapturePredictImage;
    }
    if (message.startTimestampMs !== 0) {
      obj.startTimestampMs = Math.round(message.startTimestampMs);
    }
    if (message.endTimestampMs !== 0) {
      obj.endTimestampMs = Math.round(message.endTimestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopP2PBufferringRequest>, I>>(base?: I): StopP2PBufferringRequest {
    return StopP2PBufferringRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopP2PBufferringRequest>, I>>(object: I): StopP2PBufferringRequest {
    const message = createBaseStopP2PBufferringRequest();
    message.saveBurst = object.saveBurst ?? false;
    message.camId = object.camId ?? "";
    message.path = object.path ?? "";
    message.dontCapturePredictImage = object.dontCapturePredictImage ?? false;
    message.startTimestampMs = object.startTimestampMs ?? 0;
    message.endTimestampMs = object.endTimestampMs ?? 0;
    return message;
  },
};

function createBaseStopP2PBufferringResponse(): StopP2PBufferringResponse {
  return {};
}

export const StopP2PBufferringResponse: MessageFns<StopP2PBufferringResponse> = {
  encode(_: StopP2PBufferringResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopP2PBufferringResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopP2PBufferringResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopP2PBufferringResponse {
    return {};
  },

  toJSON(_: StopP2PBufferringResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopP2PBufferringResponse>, I>>(base?: I): StopP2PBufferringResponse {
    return StopP2PBufferringResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopP2PBufferringResponse>, I>>(_: I): StopP2PBufferringResponse {
    const message = createBaseStopP2PBufferringResponse();
    return message;
  },
};

function createBaseP2PCaptureRequest(): P2PCaptureRequest {
  return { camId: "", name: "", timestampMs: 0, writeToDisk: false, reason: 0 };
}

export const P2PCaptureRequest: MessageFns<P2PCaptureRequest> = {
  encode(message: P2PCaptureRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(24).int64(message.timestampMs);
    }
    if (message.writeToDisk !== false) {
      writer.uint32(32).bool(message.writeToDisk);
    }
    if (message.reason !== 0) {
      writer.uint32(40).int32(message.reason);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PCaptureRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PCaptureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.writeToDisk = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.reason = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): P2PCaptureRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      writeToDisk: isSet(object.writeToDisk) ? globalThis.Boolean(object.writeToDisk) : false,
      reason: isSet(object.reason) ? p2PCaptureReasonFromJSON(object.reason) : 0,
    };
  },

  toJSON(message: P2PCaptureRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.writeToDisk !== false) {
      obj.writeToDisk = message.writeToDisk;
    }
    if (message.reason !== 0) {
      obj.reason = p2PCaptureReasonToJSON(message.reason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PCaptureRequest>, I>>(base?: I): P2PCaptureRequest {
    return P2PCaptureRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PCaptureRequest>, I>>(object: I): P2PCaptureRequest {
    const message = createBaseP2PCaptureRequest();
    message.camId = object.camId ?? "";
    message.name = object.name ?? "";
    message.timestampMs = object.timestampMs ?? 0;
    message.writeToDisk = object.writeToDisk ?? false;
    message.reason = object.reason ?? 0;
    return message;
  },
};

function createBaseP2PCaptureResponse(): P2PCaptureResponse {
  return {};
}

export const P2PCaptureResponse: MessageFns<P2PCaptureResponse> = {
  encode(_: P2PCaptureResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PCaptureResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PCaptureResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): P2PCaptureResponse {
    return {};
  },

  toJSON(_: P2PCaptureResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PCaptureResponse>, I>>(base?: I): P2PCaptureResponse {
    return P2PCaptureResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PCaptureResponse>, I>>(_: I): P2PCaptureResponse {
    const message = createBaseP2PCaptureResponse();
    return message;
  },
};

function createBaseP2PBufferringBurstCaptureRequest(): P2PBufferringBurstCaptureRequest {
  return { camId: "", path: "", dontCapturePredictImage: false, startTimestampMs: 0, endTimestampMs: 0 };
}

export const P2PBufferringBurstCaptureRequest: MessageFns<P2PBufferringBurstCaptureRequest> = {
  encode(message: P2PBufferringBurstCaptureRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(18).string(message.camId);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.dontCapturePredictImage !== false) {
      writer.uint32(32).bool(message.dontCapturePredictImage);
    }
    if (message.startTimestampMs !== 0) {
      writer.uint32(40).int64(message.startTimestampMs);
    }
    if (message.endTimestampMs !== 0) {
      writer.uint32(48).int64(message.endTimestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PBufferringBurstCaptureRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PBufferringBurstCaptureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dontCapturePredictImage = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.startTimestampMs = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.endTimestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): P2PBufferringBurstCaptureRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      dontCapturePredictImage: isSet(object.dontCapturePredictImage)
        ? globalThis.Boolean(object.dontCapturePredictImage)
        : false,
      startTimestampMs: isSet(object.startTimestampMs) ? globalThis.Number(object.startTimestampMs) : 0,
      endTimestampMs: isSet(object.endTimestampMs) ? globalThis.Number(object.endTimestampMs) : 0,
    };
  },

  toJSON(message: P2PBufferringBurstCaptureRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.dontCapturePredictImage !== false) {
      obj.dontCapturePredictImage = message.dontCapturePredictImage;
    }
    if (message.startTimestampMs !== 0) {
      obj.startTimestampMs = Math.round(message.startTimestampMs);
    }
    if (message.endTimestampMs !== 0) {
      obj.endTimestampMs = Math.round(message.endTimestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PBufferringBurstCaptureRequest>, I>>(
    base?: I,
  ): P2PBufferringBurstCaptureRequest {
    return P2PBufferringBurstCaptureRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PBufferringBurstCaptureRequest>, I>>(
    object: I,
  ): P2PBufferringBurstCaptureRequest {
    const message = createBaseP2PBufferringBurstCaptureRequest();
    message.camId = object.camId ?? "";
    message.path = object.path ?? "";
    message.dontCapturePredictImage = object.dontCapturePredictImage ?? false;
    message.startTimestampMs = object.startTimestampMs ?? 0;
    message.endTimestampMs = object.endTimestampMs ?? 0;
    return message;
  },
};

function createBaseP2PBufferringBurstCaptureResponse(): P2PBufferringBurstCaptureResponse {
  return {};
}

export const P2PBufferringBurstCaptureResponse: MessageFns<P2PBufferringBurstCaptureResponse> = {
  encode(_: P2PBufferringBurstCaptureResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): P2PBufferringBurstCaptureResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PBufferringBurstCaptureResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): P2PBufferringBurstCaptureResponse {
    return {};
  },

  toJSON(_: P2PBufferringBurstCaptureResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<P2PBufferringBurstCaptureResponse>, I>>(
    base?: I,
  ): P2PBufferringBurstCaptureResponse {
    return P2PBufferringBurstCaptureResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PBufferringBurstCaptureResponse>, I>>(
    _: I,
  ): P2PBufferringBurstCaptureResponse {
    const message = createBaseP2PBufferringBurstCaptureResponse();
    return message;
  },
};

function createBaseGetNextDeepweedOutputRequest(): GetNextDeepweedOutputRequest {
  return { timestampMs: 0, bufferName: "", timeoutMs: 0 };
}

export const GetNextDeepweedOutputRequest: MessageFns<GetNextDeepweedOutputRequest> = {
  encode(message: GetNextDeepweedOutputRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampMs !== 0) {
      writer.uint32(8).int64(message.timestampMs);
    }
    if (message.bufferName !== "") {
      writer.uint32(18).string(message.bufferName);
    }
    if (message.timeoutMs !== 0) {
      writer.uint32(24).int64(message.timeoutMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextDeepweedOutputRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextDeepweedOutputRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bufferName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timeoutMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextDeepweedOutputRequest {
    return {
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      bufferName: isSet(object.bufferName) ? globalThis.String(object.bufferName) : "",
      timeoutMs: isSet(object.timeoutMs) ? globalThis.Number(object.timeoutMs) : 0,
    };
  },

  toJSON(message: GetNextDeepweedOutputRequest): unknown {
    const obj: any = {};
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.bufferName !== "") {
      obj.bufferName = message.bufferName;
    }
    if (message.timeoutMs !== 0) {
      obj.timeoutMs = Math.round(message.timeoutMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextDeepweedOutputRequest>, I>>(base?: I): GetNextDeepweedOutputRequest {
    return GetNextDeepweedOutputRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextDeepweedOutputRequest>, I>>(object: I): GetNextDeepweedOutputRequest {
    const message = createBaseGetNextDeepweedOutputRequest();
    message.timestampMs = object.timestampMs ?? 0;
    message.bufferName = object.bufferName ?? "";
    message.timeoutMs = object.timeoutMs ?? 0;
    return message;
  },
};

function createBaseSetTargetingStateRequest(): SetTargetingStateRequest {
  return { weedingEnabled: false, thinningEnabled: false };
}

export const SetTargetingStateRequest: MessageFns<SetTargetingStateRequest> = {
  encode(message: SetTargetingStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.weedingEnabled !== false) {
      writer.uint32(8).bool(message.weedingEnabled);
    }
    if (message.thinningEnabled !== false) {
      writer.uint32(16).bool(message.thinningEnabled);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetTargetingStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTargetingStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.weedingEnabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.thinningEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetTargetingStateRequest {
    return {
      weedingEnabled: isSet(object.weedingEnabled) ? globalThis.Boolean(object.weedingEnabled) : false,
      thinningEnabled: isSet(object.thinningEnabled) ? globalThis.Boolean(object.thinningEnabled) : false,
    };
  },

  toJSON(message: SetTargetingStateRequest): unknown {
    const obj: any = {};
    if (message.weedingEnabled !== false) {
      obj.weedingEnabled = message.weedingEnabled;
    }
    if (message.thinningEnabled !== false) {
      obj.thinningEnabled = message.thinningEnabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetTargetingStateRequest>, I>>(base?: I): SetTargetingStateRequest {
    return SetTargetingStateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetTargetingStateRequest>, I>>(object: I): SetTargetingStateRequest {
    const message = createBaseSetTargetingStateRequest();
    message.weedingEnabled = object.weedingEnabled ?? false;
    message.thinningEnabled = object.thinningEnabled ?? false;
    return message;
  },
};

function createBaseSetTargetingStateResponse(): SetTargetingStateResponse {
  return {};
}

export const SetTargetingStateResponse: MessageFns<SetTargetingStateResponse> = {
  encode(_: SetTargetingStateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetTargetingStateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTargetingStateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetTargetingStateResponse {
    return {};
  },

  toJSON(_: SetTargetingStateResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetTargetingStateResponse>, I>>(base?: I): SetTargetingStateResponse {
    return SetTargetingStateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetTargetingStateResponse>, I>>(_: I): SetTargetingStateResponse {
    const message = createBaseSetTargetingStateResponse();
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
