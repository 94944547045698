import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface State {
  categoryCollection: {
    thumbnailSize: number;
  };
}

const initialState: State = {
  categoryCollection: {
    thumbnailSize: 100,
  },
};

export const userPreferences = createSlice({
  name: "userPreferences",
  initialState,
  reducers: {
    updateCategoryCollectionPreferences: (
      state,
      { payload }: PayloadAction<State["categoryCollection"]>
    ) => {
      state.categoryCollection = payload;
    },
  },
});

export const { updateCategoryCollectionPreferences } = userPreferences.actions;
