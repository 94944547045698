import { BLUE_BUTTON, TEXT_FIELD_DARK, WHITE_BUTTON } from "portal/utils/theme";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { Job, SequenceTask, Task } from "protos/rtc/jobs";
import { LoadingButton } from "@mui/lab";
import { TFunction } from "i18next";
import { useCreateAutotractorJobMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Clear";
import MoveDownIcon from "@mui/icons-material/ArrowDownward";
import MoveUpIcon from "@mui/icons-material/ArrowUpward";
import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";

interface Props {
  serial: string;
}

const _NewRobotAutotractorJob: FunctionComponent<Props> = ({ serial }) => {
  return <NewJobEditor serial={serial} />;
};

export const NewRobotAutotractorJob = withAuthenticationRequired(
  _NewRobotAutotractorJob
);

const replaceTask = (tasks: Task[], newTask: Task): Task[] => {
  return tasks.map((oldTask) =>
    oldTask.id === newTask.id ? newTask : oldTask
  );
};

const removeTask = (tasks: Task[], task: Task): Task[] => {
  return tasks.filter((t) => t.id !== task.id);
};

const moveTask = (tasks: Task[], task: Task, newIndex: number): Task[] => {
  tasks = removeTask(tasks, task);
  tasks.splice(newIndex, 0, task);
  return tasks;
};

const emptyTask = (id: number): Task => {
  return Task.fromPartial({ id, manual: {} });
};

interface NewJobEditorProps {
  serial: string;
}

const NewJobEditor: FunctionComponent<NewJobEditorProps> = ({ serial }) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([emptyTask(1)]);
  const [nextTaskId, setNextTaskId] = useState<number>(2);

  const [createJob, { isLoading: isCreating }] = useMutationPopups(
    useCreateAutotractorJobMutation(),
    {
      success: capitalize(
        t("utils.actions.createdLong", {
          subject: t("models.autotractor.job_one"),
        })
      ),
    }
  );
  const finalizeJob = (): Job => {
    return Job.fromPartial({
      robot: serial,
      name,
      task: {
        sequence: SequenceTask.fromPartial({
          items: tasks.map((task) => ({ ...task, id: 0 })),
        }),
      },
    });
  };

  const errors = useMemo(() => {
    const jobErrors = getToplevelJobErrors(t, { name });
    const taskErrors: Map<number, TaskError[]> = new Map();
    let anyTaskErrors = false;
    for (const task of tasks) {
      const errors = getTaskErrors(t, task);
      taskErrors.set(task.id, errors);
      if (errors.length > 0) {
        anyTaskErrors = true;
      }
    }
    return {
      job: jobErrors,
      tasks: taskErrors,
      any: jobErrors.length > 0 || anyTaskErrors,
    };
  }, [t, name, tasks]);

  return (
    <div className="flex flex-col gap-3 self-center w-[600px] max-w-full">
      <Typography variant="h1" className="text-4xl">
        {t("utils.actions.newLong", {
          subject: t("models.autotractor.job_one"),
        })}
      </Typography>
      <TextField
        {...TEXT_FIELD_DARK}
        label={t("utils.descriptors.name")}
        value={name}
        {...errorProps(errors.job, JobField.NAME)}
        onChange={(event) => setName(event.target.value)}
      />
      {tasks.map((task, index) => (
        <TaskEditor
          key={task.id}
          index={index}
          task={task}
          errors={errors.tasks.get(task.id) ?? []}
          onChange={(t) => setTasks((tasks) => replaceTask(tasks, t))}
          onDelete={
            tasks.length > 1
              ? () => setTasks((tasks) => removeTask(tasks, task))
              : undefined
          }
          onMoveUp={
            index === 0
              ? undefined
              : () => setTasks((tasks) => moveTask(tasks, task, index - 1))
          }
          onMoveDown={
            index >= tasks.length - 1
              ? undefined
              : () => setTasks((tasks) => moveTask(tasks, task, index + 1))
          }
        />
      ))}
      <div className="flex gap-3 justify-end">
        <Button
          {...WHITE_BUTTON}
          startIcon={<AddIcon />}
          onClick={() => {
            setTasks((tasks) => [...tasks, emptyTask(nextTaskId)]);
            setNextTaskId(nextTaskId + 1);
          }}
        >
          {t("utils.actions.addLong", {
            subject: t("models.autotractor.task_one"),
          })}
        </Button>
        <LoadingButton
          {...BLUE_BUTTON}
          loading={isCreating}
          disabled={errors.any}
          onClick={() =>
            createJob({
              job: finalizeJob(),
            })
          }
        >
          {t("utils.actions.saveLong", {
            subject: t("models.autotractor.job_one"),
          })}
        </LoadingButton>
      </div>
    </div>
  );
};

interface ValidationError<Field> {
  field: Field;
  message: string;
}

enum JobField {
  NAME = "name",
}
type JobError = ValidationError<JobField>;

const getToplevelJobErrors = (
  t: TFunction,
  job: Pick<Job, "name">
): JobError[] => {
  const errors: JobError[] = [];
  const { name } = job;
  if (name.length === 0) {
    errors.push({
      field: JobField.NAME,
      message: t("utils.form.required"),
    });
  }
  return errors;
};

enum TaskField {
  NAME = "name",
  MANUAL_INSTRUCTIONS = "manualInstructions",
}
type TaskError = ValidationError<TaskField>;

const getTaskErrors = (t: TFunction, task: Task): TaskError[] => {
  const errors: TaskError[] = [];
  const { name, manual } = task;
  if (name.length === 0) {
    errors.push({
      field: TaskField.NAME,
      message: t("utils.form.required"),
    });
  }
  if (manual?.instructions.length === 0) {
    errors.push({
      field: TaskField.MANUAL_INSTRUCTIONS,
      message: t("utils.form.required"),
    });
  }
  return errors;
};

const errorProps = <F,>(
  errors: Array<ValidationError<F>>,
  field: F
): Partial<TextFieldProps> => {
  const error = errors.find((error) => error.field === field);
  if (!error) {
    return {};
  }
  return {
    error: true,
    helperText: error.message,
  };
};

interface TaskEditorProps {
  task: Task;
  errors: TaskError[];
  index: number;
  onChange: (task: Task) => void;
  onMoveUp: undefined | (() => void);
  onMoveDown: undefined | (() => void);
  onDelete: undefined | (() => void);
}
const TaskEditor: FunctionComponent<TaskEditorProps> = ({
  task,
  errors,
  index,
  onChange,
  onMoveUp,
  onMoveDown,
  onDelete,
}) => {
  const { t } = useTranslation();

  const actionButton = (
    icon: typeof MoveUpIcon,
    onClick: undefined | (() => void),
    title: string
  ): ReactNode => {
    const Icon = icon;
    return (
      <Tooltip arrow title={title}>
        <span>
          <IconButton
            disabled={!onClick}
            onClick={onClick}
            className="text-white disabled:text-gray-600"
          >
            <Icon />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <Card>
      <CardHeader
        className="flex space-between"
        title={t("models.autotractor.taskN", { index: String(index + 1) })}
        titleTypographyProps={{ className: "text-base uppercase opacity-75" }}
        action={
          <div className="flex gap-2">
            {actionButton(MoveUpIcon, onMoveUp, t("utils.form.moveUp"))}
            {actionButton(MoveDownIcon, onMoveDown, t("utils.form.moveDown"))}
            {actionButton(
              DeleteIcon,
              onDelete,
              t("utils.actions.deleteLong", {
                subject: t("models.autotractor.task_one"),
              })
            )}
          </div>
        }
      />
      <CardContent className="flex flex-col gap-3">
        <TextField
          label={t("utils.descriptors.name")}
          value={task.name}
          {...errorProps(errors, TaskField.NAME)}
          onChange={(event) => onChange({ ...task, name: event.target.value })}
        />
        {(() => {
          if (task.manual) {
            const { manual } = task;
            return (
              <TextField
                label={t("models.autotractor.fields.instructions")}
                value={manual.instructions}
                {...errorProps(errors, TaskField.MANUAL_INSTRUCTIONS)}
                onChange={(event) =>
                  onChange({
                    ...task,
                    manual: {
                      ...task.manual,
                      instructions: event.target.value,
                    },
                  })
                }
              />
            );
          }
          console.warn("Unsupported task type", task);
        })()}
      </CardContent>
    </Card>
  );
};
