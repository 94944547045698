import {
  Alert,
  Button,
  Card,
  IconButton,
  type IconButtonProps,
  Popover,
  TextField,
  Tooltip,
  type TooltipProps,
  Typography,
} from "@mui/material";
import {
  areStructuredCategoriesEqual,
  areTrustsEqual,
  configFromPartitioned,
  partitionedFromConfig,
  PartitionedModelinator,
  replaceCategory,
  replaceSize,
  splitCategories,
  SplitModelinator,
  splitSizes,
  StructuredCategory,
  StructuredModelinator,
  syncCategories,
  syncSizes,
  Uniformity,
} from "portal/utils/modelinator";
import {
  BLUE_BUTTON,
  BLUE_LOADING_BUTTON,
  BUTTON,
  classes,
  GREEN_BUTTON,
  RED_BUTTON,
  SMALL_TEXT_FIELD_DARK,
  WHITE_BUTTON,
} from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import { capitalize, titleCase } from "portal/utils/strings";
import {
  CategoryClassification,
  categoryClassificationFromJSON,
  ModelinatorConfig,
  ModelTrust,
} from "protos/almanac/almanac";
import { checkNever } from "portal/utils/identity";
import { ConfirmationDialog } from "portal/components/ConfirmationDialog";
import { Crop, Model } from "protos/portal/veselka";
import {
  CROP_TRUSTS,
  getColumnStrings,
  WEED_TRUSTS,
} from "portal/components/modelinator/trusts";
import { ExportModelinatorButton } from "portal/components/modelinator/ExportModelinatorButton";
import { findWhere, sortBy } from "portal/utils/arrays";
import { getCropPath } from "portal/utils/routing";
import { getTypeCategoryTitle, SIZE_INDEX } from "portal/utils/almanac";
import { Loading } from "portal/components/Loading";
import { LoadingButton } from "@mui/lab";
import { LOCALSTORAGE_MODELINATOR_TRUSTS } from "portal/utils/localStorage";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "../auth/WithAuthorizationRequired";
import {
  useGetModelinatorQuery,
  useGetModelQuery,
  useGetRobotQuery,
  useListCropsQuery,
  useSetModelinatorMutation,
} from "portal/state/portalApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUnmountEffect } from "portal/utils/hooks/useUnmountEffect";
import { withErrorBoundary } from "../ErrorBoundary";
import CheckIcon from "@mui/icons-material/Check";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import PasteIcon from "@mui/icons-material/ContentPasteOutlined";
import React, {
  Dispatch,
  forwardRef,
  Fragment,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

interface Props {
  serial: string;
  cropId: string;
  modelId: string;
}

const _Modelinator: FunctionComponent<Props> = ({
  serial,
  cropId,
  modelId,
}) => {
  const { t } = useTranslation();

  const { data: summary } = useQueryPopups(useGetRobotQuery({ serial }), {
    errorVariant: "warning",
  });
  const { data: loadedCrops, isLoading: isCropsLoading } = useQueryPopups(
    useListCropsQuery()
  );
  const { data: model, isLoading: isModelLoading } = useQueryPopups(
    useGetModelQuery({ modelId }),
    { errorVariant: "warning" }
  );
  const { data: frozenModelinator, isLoading: isGetLoading } = useQueryPopups(
    useGetModelinatorQuery(
      serial && cropId && modelId ? { serial, cropId, modelId } : skipToken
    )
  );
  const crop = useMemo(
    () => findWhere(loadedCrops ?? [], { id: cropId }),
    [loadedCrops, cropId]
  );

  const [saveModelinator, { isLoading: isUpdating }] = useMutationPopups(
    useSetModelinatorMutation(),
    {
      success: capitalize(
        t("utils.actions.saved", { subject: t("models.models.model_one") })
      ),
    }
  );

  // clipboard state
  const [copiedTrusts, setCopiedTrusts] = useLocalStorage<
    ModelTrust | undefined
  >(LOCALSTORAGE_MODELINATOR_TRUSTS);
  const nextCopyIdRef = useRef<number>(0);
  const [activeCopyId, setActiveCopyId] = useState<number | undefined>(
    undefined
  );
  const isMountedRef = useRef<boolean>(true);
  useUnmountEffect(() => {
    isMountedRef.current = false;
  });
  const clipboardState: ClipboardState = {
    copiedTrusts,
    setCopiedTrusts,
    nextCopyIdRef,
    activeCopyId,
    setActiveCopyId: (action: SetStateAction<number | undefined>) => {
      if (isMountedRef.current) {
        setActiveCopyId(action);
      }
    },
  };

  // edit state
  const [changed, setChanged] = useState<boolean>(false);
  const [activeModelinators, setActiveModelinators] = useState<
    PartitionedModelinator | undefined
  >(undefined);
  // Call `updateModelinators({ crops })` to update the weeds modelinator, or
  // m.m. for weeds.
  const updateModelinators = (
    updates: Partial<PartitionedModelinator>
  ): void => {
    setActiveModelinators((old) => (old ? { ...old, ...updates } : undefined));
    setChanged(true);
  };
  const resetUpdatedModelinators = (): void => {
    setActiveModelinators(
      frozenModelinator ? partitionedFromConfig(frozenModelinator) : undefined
    );
    setChanged(false);
  };

  const canUpdate = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.models_advanced,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.models_advanced,
      PermissionDomain.all
    ),
  ]);

  // reset edit state when server state is updated
  const [lastModelinator, setLastModelinator] = useState<
    ModelinatorConfig | undefined
  >(undefined);
  if (lastModelinator !== frozenModelinator) {
    setLastModelinator(frozenModelinator);
    resetUpdatedModelinators();
    return; // re-render
  }

  if (isModelLoading || isCropsLoading || isGetLoading) {
    return <Loading />;
  }
  if (!frozenModelinator) {
    return (
      <Alert severity="warning">
        {t("components.modelinator.errors.sync")}
      </Alert>
    );
  }
  const cropLink = getCropPath(serial, cropId);
  const crops = loadedCrops ?? [];

  const isActive =
    summary?.robot?.health?.cropId === cropId &&
    summary.robot.health.model === modelId;

  return (
    <div className="flex flex-col gap-4">
      {isActive && (
        <Alert severity="warning" className="mb-8">
          {t("components.modelinator.warnings.production")}
        </Alert>
      )}
      <div className="flex flex-col md:flex-row md:justify-between items-start">
        <Title
          crop={crop}
          model={model}
          cropLink={cropLink}
          hasUnsavedChanges={changed}
        />
        <div className="flex flex-wrap w-full md:w-auto justify-end items-center gap-4">
          <ExportModelinatorButton
            modelinators={activeModelinators}
            crops={loadedCrops}
            hasUnsavedChanges={changed}
          />
          <Button
            {...(changed ? WHITE_BUTTON : [])}
            disabled={!changed || isUpdating}
            variant="text"
            color="info"
            classes={{ disabled: "text-lighten-200" }}
            onClick={resetUpdatedModelinators}
          >
            <span className="px-2">{t("utils.actions.discard")}</span>
          </Button>
          <LoadingButton
            {...BLUE_LOADING_BUTTON}
            disabled={!changed}
            loading={isUpdating}
            startIcon={<SaveIcon />}
            onClick={
              activeModelinators &&
              (async () => {
                const modelinator = configFromPartitioned(activeModelinators);
                await saveModelinator({ serial, modelinator });
              })
            }
          >
            {t("utils.actions.save")}
          </LoadingButton>
        </div>
      </div>
      {activeModelinators && (
        <>
          <TableOfCropsOrWeeds
            classification={categoryClassificationFromJSON(
              CategoryClassification.CATEGORY_CROP
            )}
            modelinator={activeModelinators.crops}
            onChange={(crops) => updateModelinators({ crops })}
            crops={crops}
            clipboardState={clipboardState}
            readOnly={!canUpdate}
          />
          <TableOfCropsOrWeeds
            classification={categoryClassificationFromJSON(
              CategoryClassification.CATEGORY_WEED
            )}
            modelinator={activeModelinators.weeds}
            onChange={(weeds) => updateModelinators({ weeds })}
            crops={crops}
            clipboardState={clipboardState}
            readOnly={!canUpdate}
          />
        </>
      )}
    </div>
  );
};

export const Modelinator = withErrorBoundary(
  {},
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.models_advanced,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.models_advanced,
        PermissionDomain.all
      ),
    ],
    _Modelinator
  )
);

interface TitleProps {
  crop: Crop | undefined;
  model: Model | undefined;
  cropLink: string;
  hasUnsavedChanges: boolean;
}
const Title: FunctionComponent<TitleProps> = ({
  crop,
  model,
  cropLink,
  hasUnsavedChanges,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [exitDialogShown, setExitDialogShown] = useState<boolean>(false);
  const confirmExit = (): void => navigate(cropLink);
  const initiateExit = (): void => {
    if (hasUnsavedChanges) {
      setExitDialogShown(true);
    } else {
      confirmExit();
    }
  };
  const cancelExit = (): void => setExitDialogShown(false);

  const cropName = crop?.commonName ?? t("models.crops.categories.unknown");

  return (
    <div className="flex flex-col">
      <Typography variant="h1" className="text-4xl">
        {cropName}
      </Typography>
      <div className="flex items-center">
        <IconButton
          onClick={initiateExit}
          className="text-white pl-0"
          title={capitalize(
            t("utils.actions.backLong", {
              subject: t("models.crops.crop_one"),
            })
          )}
        >
          <ParentIcon />
        </IconButton>
        {exitDialogShown && (
          <ConfirmationDialog
            title={t("components.almanac.discard.title")}
            description={t("components.almanac.discard.description", {
              title: cropName,
            })}
            destructive
            yesText={t("utils.actions.discard")}
            onClose={cancelExit}
            onYes={confirmExit}
          />
        )}
        {model ? (
          <span className="font-mono">{model.id}</span>
        ) : (
          <span className="italic">{t("models.models.unknown")}</span>
        )}
      </div>
    </div>
  );
};

interface SquareIconButtonProps extends IconButtonProps {
  theme: typeof BUTTON;
}
const SquareIconButton = forwardRef<HTMLButtonElement, SquareIconButtonProps>(
  function SquareIconButton({ theme, children, ...props }, ref) {
    return (
      <IconButton
        {...theme}
        {...props}
        className={classes(
          theme.className,
          props.className,
          "aspect-square rounded-sm h-8 sm:h-9"
        )}
        ref={ref}
      >
        {children}
      </IconButton>
    );
  }
);

// A `TableOfCropsOrWeedsProps` renders the top-level "Crops" or "Weed" card
// and its editable table.
interface TableOfCropsOrWeedsProps {
  classification: CategoryClassification;
  modelinator: StructuredModelinator;
  onChange: (value: StructuredModelinator) => void;
  crops: Crop[];
  clipboardState: ClipboardState;
  readOnly?: boolean;
}
const TableOfCropsOrWeeds: FunctionComponent<TableOfCropsOrWeedsProps> = ({
  classification,
  modelinator,
  onChange,
  crops,
  clipboardState,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  let columns: Array<keyof ModelTrust>;
  let strings; // translation values that depend on crop/weed classification
  if (
    classification ===
    categoryClassificationFromJSON(CategoryClassification.CATEGORY_CROP)
  ) {
    columns = CROP_TRUSTS;
    strings = {
      title: t("models.crops.crop_other"),
      categoryHeader: t("models.crops.crop_one"),
      allCategories: t("components.almanac.cropsSynced"),
      sync: t("components.modelinator.categories.syncCrops"),
      split: t("components.modelinator.categories.splitCrops"),
    };
  } else {
    columns = WEED_TRUSTS;
    strings = {
      title: t("models.weeds.weed_other"),
      categoryHeader: t("models.weeds.weed_one"),
      allCategories: t("components.almanac.weedsSynced"),
      sync: t("components.modelinator.categories.syncWeeds"),
      split: t("components.modelinator.categories.splitWeeds"),
    };
  }

  // `PendingSync` is set when the user has clicked the button to sync all
  // categories, but has not yet selected which category to use as the source
  // of truth.
  interface PendingSync {
    popoverAnchor: HTMLButtonElement;
    modelinator: SplitModelinator; // type refined from `StructuredModelinator`
  }
  const [pendingSync, setPendingSync] = useState<PendingSync | undefined>(
    undefined
  );
  const openSync = (
    popoverAnchor: HTMLButtonElement,
    modelinator: SplitModelinator
  ): void => {
    // If all the categories are equivalent, pick one arbitrarily and use that
    // as the source of truth for the synced category. (In particular, this
    // happens if you click "Sync" right after clicking "Split".)
    const { categories } = modelinator;
    if (categories.length > 0) {
      const [first, ...rest] = categories.map(({ category }) => category);
      if (!first) {
        return;
      }
      if (rest.every((other) => areStructuredCategoriesEqual(first, other))) {
        const types = categories.map(({ type }) => type);
        onChange(syncCategories(types, first));
        return;
      }
    }
    // Otherwise, syncing categories necessarily involves data loss; ask the
    // user which category to keep.
    setPendingSync({ popoverAnchor, modelinator });
  };
  const closeSync = (): void => setPendingSync(undefined);
  const open = Boolean(pendingSync);

  let hasMultipleCategories: boolean;
  let syncSplitButton: ReactNode;
  switch (modelinator.type) {
    case Uniformity.SYNCED: {
      hasMultipleCategories = modelinator.categoryTypes.length > 1;
      syncSplitButton = (
        <Button
          {...WHITE_BUTTON}
          onClick={() => onChange(splitCategories(modelinator))}
        >
          {strings.split}
        </Button>
      );
      break;
    }
    case Uniformity.SPLIT: {
      hasMultipleCategories = modelinator.categories.length > 1;
      syncSplitButton = (
        <Button
          {...RED_BUTTON}
          onClick={(event) =>
            open ? closeSync() : openSync(event.currentTarget, modelinator)
          }
        >
          {strings.sync}
        </Button>
      );
      break;
    }
    default: {
      console.error("Unexpected modelinator type:", checkNever(modelinator));
      hasMultipleCategories = true;
    }
  }

  let syncPopoverContents;
  if (pendingSync) {
    const categories = sortBy(pendingSync.modelinator.categories, ({ type }) =>
      getTypeCategoryTitle(t, type, crops)
    );
    syncPopoverContents = (
      <div className="p-4 flex flex-col gap-2">
        <span>{t("components.modelinator.categories.copyFromWhich")}</span>
        <div className="my-2 flex flex-col gap-2">
          {categories.map(({ type, category }, index) => (
            <Button
              key={type ? `split-c1-${type.category}` : `split-c2-${index}`}
              {...RED_BUTTON}
              onClick={() => {
                const types = categories.map(({ type }) => type);
                onChange(syncCategories(types, category));
                closeSync();
              }}
            >
              {getTypeCategoryTitle(t, type, crops)}
            </Button>
          ))}
        </div>
        <Button {...WHITE_BUTTON} onClick={closeSync}>
          {t("utils.actions.cancel")}
        </Button>
      </div>
    );
  }

  let tableBodyRows: ReactNode;
  switch (modelinator.type) {
    case Uniformity.SYNCED: {
      const { categoryTypes, eachCategory } = modelinator;
      const categoryTitle =
        categoryTypes.length === 1
          ? getTypeCategoryTitle(t, categoryTypes[0], crops)
          : strings.allCategories;
      tableBodyRows = (
        <Fragment key="synced">
          <tr className="hidden sm:contents">
            <td className="col-span-full">{categoryTitle}</td>
          </tr>
          <CategoryRows
            category={eachCategory}
            categoryTitle={categoryTitle}
            columns={columns}
            onChange={(eachCategory) =>
              onChange({ ...modelinator, eachCategory })
            }
            clipboardState={clipboardState}
            readOnly={readOnly}
          />
        </Fragment>
      );
      break;
    }
    case Uniformity.SPLIT: {
      const categories = sortBy(modelinator.categories, ({ type }) =>
        getTypeCategoryTitle(t, type, crops)
      );
      tableBodyRows = categories.map(({ type, category }, index) => (
        <Fragment
          key={type ? `split-s1-${type.category}` : `split-s2-${index}`}
        >
          <tr className="hidden sm:contents">
            <td className={classes("col-span-full", index > 0 && "mt-3")}>
              {getTypeCategoryTitle(t, type, crops)}
            </td>
          </tr>
          <CategoryRows
            category={category}
            categoryTitle={getTypeCategoryTitle(t, type, crops)}
            columns={columns}
            onChange={(category) =>
              onChange(replaceCategory(modelinator, { type, category }))
            }
            clipboardState={clipboardState}
          />
        </Fragment>
      ));
      break;
    }
    default: {
      console.error("Unexpected modelinator type:", checkNever(modelinator));
    }
  }

  return (
    <Card className="p-4 flex flex-col gap-3">
      {/* Header: title and sync/split button */}
      <div className="flex gap-3 justify-between">
        <Typography variant="h2" className="text-xl">
          {titleCase(strings.title)}
        </Typography>
        {hasMultipleCategories && (
          <>
            {!readOnly && syncSplitButton}
            <Popover
              open={open}
              anchorEl={pendingSync?.popoverAnchor}
              onClose={closeSync}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              classes={{ paper: "translate-y-4" }}
            >
              {syncPopoverContents}
            </Popover>
          </>
        )}
      </div>

      {/* Main table */}
      <table
        className="grid gap-x-1 grid-cols-[--grid-template-columns-tiny] sm:grid-cols-[--grid-template-columns-small] lg:grid-cols-[--grid-template-columns-large]"
        style={
          {
            // On tiny screens (~portrait mobile), use as much space as
            // possible for the numeric values. Everything else will go on a
            // separate row above the text boxes ("two rows per row").
            "--grid-template-columns-tiny": `repeat(${columns.length}, minmax(4ch, 1fr))`,
            // On larger screens: one column for category/size titles, then the
            // data columns, then the copy/paste buttons. When we have room to
            // spare, try to make the title column fixed-size so that it
            // doesn't reflow when you sync/split sizes.
            "--grid-template-columns-small": `auto repeat(${columns.length}, minmax(4ch, 1fr)) min-content`,
            "--grid-template-columns-large": `minmax(175px, auto) repeat(${columns.length}, minmax(auto, 150px)) 1fr`,
          } as React.CSSProperties // CSS variables not typed yet
        }
      >
        <thead className="contents">
          <tr className="contents">
            <th className="hidden sm:block text-start">
              {capitalize(strings.categoryHeader)}
            </th>
            {columns.map((column) => (
              <ColumnHeader
                key={column}
                classification={classification}
                column={column}
              />
            ))}
            <th className="hidden sm:block">{/* copy/paste */}</th>
          </tr>
        </thead>
        <tbody className="contents">{tableBodyRows}</tbody>
      </table>
    </Card>
  );
};

// `ColumnHeader` renders the `<th>` atop each data column (e.g., "Min DOO").
interface ColumnHeaderProps {
  classification: CategoryClassification;
  column: keyof ModelTrust;
}
const ColumnHeader: FunctionComponent<ColumnHeaderProps> = ({
  classification,
  column: key,
}) => {
  const { t } = useTranslation();
  const { label, description } = getColumnStrings(t, classification, key);
  return (
    <Tooltip title={description} arrow>
      <th className="break-words hyphens-auto">{label}</th>
    </Tooltip>
  );
};

// `CategoryRows` shows either one row for "all sizes" (for a synced category)
// or one row for each size (for a split category).
interface CategoryRowsProps {
  category: StructuredCategory;
  categoryTitle: string;
  columns: Array<keyof ModelTrust>;
  onChange: (value: StructuredCategory) => void;
  clipboardState: ClipboardState;
  readOnly?: boolean;
}
const CategoryRows: FunctionComponent<CategoryRowsProps> = ({
  category,
  categoryTitle,
  columns,
  onChange,
  clipboardState,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  switch (category.type) {
    case Uniformity.SYNCED: {
      const { eachSize } = category;
      return (
        <TrustsRow
          key="synced"
          categoryTitle={categoryTitle}
          sizeTitle={t("components.almanac.formulas.all")}
          firstInCategory={true}
          syncSplitButton={
            <Button
              {...WHITE_BUTTON}
              className={classes(WHITE_BUTTON.className, "h-8 sm:h-7")}
              size="small"
              onClick={() => onChange(splitSizes(category))}
            >
              <span className="sm:hidden">
                {t("components.modelinator.formulas.splitSizesLong")}
              </span>
              <span className="hidden sm:inline">
                {t("components.modelinator.formulas.splitSizesShort")}
              </span>
            </Button>
          }
          columns={columns}
          trusts={eachSize}
          onChange={(eachSize) => onChange({ ...category, eachSize })}
          clipboardState={clipboardState}
          readOnly={readOnly}
        />
      );
    }
    case Uniformity.SPLIT: {
      const { sizes } = category;
      const sizeNames: Record<SIZE_INDEX, string> = {
        [SIZE_INDEX.SMALL]: t("utils.descriptors.small"),
        [SIZE_INDEX.MEDIUM]: t("utils.descriptors.medium"),
        [SIZE_INDEX.LARGE]: t("utils.descriptors.large"),
      };
      return sizes.map((trusts, index) => (
        <TrustsRow
          key={`split-${index}`}
          categoryTitle={categoryTitle}
          sizeTitle={sizeNames[index as SIZE_INDEX]}
          firstInCategory={index === 0}
          syncSplitButton={
            <Button
              {...RED_BUTTON}
              className={classes(RED_BUTTON.className, "h-8 sm:h-7")}
              size="small"
              onClick={() => onChange(syncSizes(trusts))}
            >
              <span className="sm:hidden">
                {t("components.modelinator.formulas.syncSizesLong")}
              </span>
              <span className="hidden sm:inline">
                {t("components.modelinator.formulas.syncSizesShort")}
              </span>
            </Button>
          }
          columns={columns}
          trusts={trusts}
          onChange={(trusts) =>
            onChange(replaceSize(category, { index, trusts }))
          }
          clipboardState={clipboardState}
          readOnly={readOnly}
        />
      ));
    }
    default: {
      console.error("Unexpected category type:", checkNever(category));
    }
  }
};

// `TrustsRow` renders a single logical row of the table, which corresponds to
// either "all sizes" (of a synced category) or a single size (of a split
// category). This includes the "sync/split" button, the category name, all the
// numeric input fields, and the copy/paste buttons.
//
// On tiny screens (~portrait mobile), this component renders two physical
// rows: one for the titles and controls, and one for the data values.
interface TrustsRowProps {
  categoryTitle: string;
  sizeTitle: string;
  firstInCategory: boolean;
  syncSplitButton: ReactNode;
  columns: Array<keyof ModelTrust>;
  trusts: ModelTrust;
  onChange: (value: ModelTrust) => void;
  clipboardState: ClipboardState;
  readOnly?: boolean;
}
const TrustsRow: FunctionComponent<TrustsRowProps> = ({
  categoryTitle,
  sizeTitle,
  firstInCategory,
  syncSplitButton,
  columns,
  trusts,
  onChange,
  clipboardState,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const [thisCopy, setThisCopy] = useState<Copy | undefined>(undefined);
  const copierState = { thisCopy, setThisCopy };
  // Switch off the "Copied!" state when the row is edited.
  if (thisCopy && !areTrustsEqual(thisCopy.trusts, trusts)) {
    setThisCopy(undefined);
    return; // re-render
  }

  return (
    <tr className="contents">
      {/* Combined title and controls row, for tiny screens */}
      <td
        className={classes(
          "sm:hidden col-span-full flex items-center justify-between",
          firstInCategory ? "mt-6" : "mt-2"
        )}
      >
        <span>
          {t("components.modelinator.formulas.categoryAndSize", {
            category: categoryTitle,
            size: sizeTitle,
          })}
        </span>
        <div className="flex gap-2 items-center">
          {!readOnly && (
            <>
              {syncSplitButton}
              <CopyButton
                clipboardState={clipboardState}
                copierState={copierState}
                trusts={trusts}
              />
              <PasteButton
                clipboardState={clipboardState}
                hideWhenDisabled={false}
                onChange={onChange}
              />
            </>
          )}
        </div>
      </td>
      {/* Normal title, for non-tiny screens */}
      <td className="hidden sm:flex gap-2 items-center me-4">
        {syncSplitButton}
        <span>{sizeTitle}</span>
      </td>
      {/* Data columns */}
      {columns.map((key) => (
        <td className="flex items-center" key={key}>
          <TextField
            {...SMALL_TEXT_FIELD_DARK}
            type="number"
            inputProps={{ min: 0, step: 0.1 }}
            value={trusts[key] || 0}
            onChange={(event) =>
              onChange({
                ...trusts,
                [key]: Number(event.target.value),
              })
            }
            className="w-full text-center"
            disabled={readOnly}
          />
        </td>
      ))}
      {/* Controls, for non-tiny screens */}
      <td className="hidden sm:flex sm:ml-2 gap-1 items-center">
        {!readOnly && (
          <>
            <CopyButton
              clipboardState={clipboardState}
              copierState={copierState}
              trusts={trusts}
            />
            <PasteButton
              clipboardState={clipboardState}
              hideWhenDisabled={true}
              onChange={onChange}
            />
          </>
        )}
      </td>
    </tr>
  );
};

interface CopyButtonProps {
  trusts: ModelTrust;
  clipboardState: ClipboardState;
  copierState: CopierState;
}
const CopyButton: FunctionComponent<CopyButtonProps> = ({
  trusts,
  clipboardState,
  copierState,
}) => {
  const { t } = useTranslation();

  const { setCopiedTrusts, nextCopyIdRef, activeCopyId, setActiveCopyId } =
    clipboardState;
  const { thisCopy, setThisCopy } = copierState;
  const wasCopied = activeCopyId !== undefined && thisCopy?.id === activeCopyId;

  return (
    <ResponsiveTooltip
      variants={[
        { className: "lg:hidden", placement: "top" },
        { className: "hidden lg:block", placement: "left" },
      ]}
      arrow
      title={
        wasCopied
          ? t("components.CopyToClipboardButton.copied")
          : t("components.discriminator.configs.copy")
      }
    >
      <span>
        <SquareIconButton
          aria-label={t("components.discriminator.configs.copy")}
          theme={wasCopied ? GREEN_BUTTON : WHITE_BUTTON}
          onClick={() => {
            setCopiedTrusts(trusts);
            const copyId = ++nextCopyIdRef.current;
            setActiveCopyId(copyId);
            setThisCopy({ id: copyId, trusts });
          }}
        >
          {wasCopied ? (
            <CheckIcon fontSize="small" />
          ) : (
            <CopyIcon fontSize="small" />
          )}
        </SquareIconButton>
      </span>
    </ResponsiveTooltip>
  );
};

interface PasteButtonProps {
  clipboardState: ClipboardState;
  hideWhenDisabled: boolean;
  onChange: (value: ModelTrust) => void;
}
const PasteButton: FunctionComponent<PasteButtonProps> = ({
  clipboardState,
  hideWhenDisabled,
  onChange,
}) => {
  const { t } = useTranslation();
  const { copiedTrusts } = clipboardState;
  const disabled = !copiedTrusts;

  return (
    <ResponsiveTooltip
      variants={[
        { className: "lg:hidden", placement: "top" },
        { className: "hidden lg:block", placement: "right" },
      ]}
      arrow
      title={t("components.discriminator.configs.paste")}
    >
      <span>
        <SquareIconButton
          aria-label={t("components.discriminator.configs.paste")}
          disabled={disabled}
          className={classes(disabled && hideWhenDisabled && "invisible")}
          theme={BLUE_BUTTON}
          onClick={copiedTrusts && (() => onChange(copiedTrusts))}
        >
          <PasteIcon fontSize="small" />
        </SquareIconButton>
      </span>
    </ResponsiveTooltip>
  );
};

// When we have a lot of space, we want tooltips on copy/paste buttons to be
// off to the sides so that you can move your mouse vertically and quickly
// click a bunch of them. But when the screen is narrow and the paste button is
// all the way at the right, a horizontal tooltip would be positioned over the
// copy button instead, which is worse. In that case, we want to move the
// tooltip to a vertical position so that you can at least move your mouse
// *down* to click a sequence (though not so easily *up*).
interface ResponsiveTooltipProps extends TooltipProps {
  variants: Array<Pick<TooltipProps, "className" | "placement">>;
}
const ResponsiveTooltip: FunctionComponent<ResponsiveTooltipProps> = ({
  variants,
  ...props
}) => {
  return (
    <>
      {variants.map(({ className, placement }, index) => (
        <Tooltip
          key={index}
          className={classes(props.className, className)}
          placement={placement}
          {...props}
        />
      ))}
    </>
  );
};

/**
 * State for the shared virtual clipboard. This allows copying and pasting
 * trusts, as well as tracking which copy button was clicked most recently, so
 * that the green "Copied!" state is exclusive.
 */
interface ClipboardState {
  copiedTrusts: ModelTrust | undefined;
  setCopiedTrusts: (value: ModelTrust | undefined) => void;
  nextCopyIdRef: React.MutableRefObject<number>;
  activeCopyId: number | undefined;
  setActiveCopyId: Dispatch<SetStateAction<number | undefined>>;
}

/**
 * State for a single logical copy button. This is a prop instead of a
 * component-local state cell because each logical copy button appears twice in
 * the DOM, once each for tiny and non-tiny screens.
 */
interface CopierState {
  thisCopy: Copy | undefined;
  setThisCopy: Dispatch<SetStateAction<Copy | undefined>>;
}
interface Copy {
  id: number;
  trusts: ModelTrust;
}
