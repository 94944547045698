import { classes } from "portal/utils/theme";
import { Container, ContainerProps } from "@mui/material";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import backgroundMap from "portal/images/background_map.svg";
import React, { FunctionComponent, PropsWithChildren } from "react";

export const mapBackgroundStyle = {
  backgroundImage: `url(${backgroundMap})`,
  backgroundAttachment: "fixed",
  backgroundPosition: "bottom right",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

interface Props extends PropsWithChildren {
  maxWidth?: ContainerProps["maxWidth"];
}
/**
 * Basic page wrapper for portal.
 * Handles breakpoints, scrolling, default layout, etc
 */
const _Page: FunctionComponent<Props> = ({ maxWidth = "xl", children }) => {
  return (
    <div
      id="page"
      className="overflow-auto w-full mt-16 print:mt-0 flex-grow flex flex-col"
      style={mapBackgroundStyle}
    >
      <Container
        maxWidth={maxWidth}
        className={classes(
          "relative",
          "py-10 print:py-0 w-full print:px-0",
          "flex-grow flex flex-col"
        )}
      >
        {children}
      </Container>
    </div>
  );
};

export const Page = withErrorBoundary({}, _Page);

/**
 * Wrapper for an element inside a Page that removes the Page's
 * scroll behavior
 *
 * Can be dynamically disabled to allow scrolling for specific breakpoints
 */
interface NoScrollProps extends PropsWithChildren {
  className?: string;
  disable?: boolean;
}
export const NoScroll: FunctionComponent<NoScrollProps> = ({
  className,
  children,
  disable,
}) => {
  return disable ? (
    children
  ) : (
    <div
      className={classes(
        "flex flex-col overflow-hidden absolute inset-0 md:inset-10",
        className
      )}
    >
      {children}
    </div>
  );
};
