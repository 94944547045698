import { buildPermission } from "portal/utils/auth";
import { classes } from "portal/utils/theme";
import {
  ConfigHealthCard,
  SafetyRadiusCard,
  SupportLinksCard,
  WheelEncodersCard,
} from "portal/components/robots/RobotMachineHealth";
import { getConfigNode } from "portal/utils/configs";
import { getCustomerSerial } from "portal/utils/robots";
import { isUndefined } from "portal/utils/identity";
import { Map } from "portal/components/map/Map";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import {
  RobotSummaryCard,
  RobotSummaryCheckedin,
  RobotSummaryIcon,
  RobotSummaryText,
} from "portal/components/robots/RobotSummaryCard";
import { skipToken } from "@reduxjs/toolkit/query";
import { Typography } from "@mui/material";
import { useGetConfigQuery, useGetRobotQuery } from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useRobotHistory } from "./useRobotHistory";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { WithSkeleton } from "portal/components/WithSkeleton";
import React, { FunctionComponent } from "react";

const _RobotSummary: FunctionComponent = () => {
  const { isInternal } = useSelf();
  const { serial } = useParams();
  const { t } = useTranslation();

  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken)
  );

  const {
    logs,
    blocks,
    hasBlocks,
    isLoading: isHistoryLoading,
  } = useRobotHistory(summary?.robot?.serial);

  const { data: configData } = useQueryPopups(
    useGetConfigQuery(serial ? { serial } : skipToken),
    {
      errorVariant: "warning",
    }
  );

  const config = configData ? getConfigNode(configData.config) : undefined;
  const template = configData ? getConfigNode(configData.template) : undefined;

  return (
    <>
      <WithSkeleton
        variant="rectangular"
        success={!isUndefined(summary)}
        className="min-h-10"
      >
        <div className="flex items-center flex-wrap gap-4">
          <RobotSummaryIcon summary={summary} className="text-4xl" />
          <Typography variant="h1" className="text-4xl">
            {isInternal
              ? summary?.robot?.serial ?? t("models.robots.unknown")
              : getCustomerSerial(t, summary?.robot?.serial)}
          </Typography>
          <RobotSummaryText summary={summary} className="text-sm font-normal" />
          <RobotSummaryCheckedin
            summary={summary}
            className="text-sm font-normal"
          />
        </div>
      </WithSkeleton>
      <div
        className={classes("flex flex-col md:flex-row", "w-full gap-8 mt-8")}
      >
        <Map
          className="w-full md:w-1/2 lg:w-2/3 min-h-96"
          robots={summary ? [summary] : []}
          loading={isHistoryLoading}
          history={hasBlocks ? undefined : logs}
          blocks={blocks}
          key={summary?.robot?.serial}
        />
        <WithSkeleton
          variant="rectangular"
          success={!isUndefined(summary)}
          className="w-full md:w-1/2 lg:w-1/3 flex-shrink-0 flex-grow"
        >
          <div className="w-full flex flex-col gap-8">
            {serial && (
              <RobotSummaryCard serial={serial} hideTitle summary={summary} />
            )}
            <SupportLinksCard summary={summary} />
          </div>
        </WithSkeleton>
      </div>
      {/* Robot Machine Health */}
      <WithAuthorizationRequired
        permissionGroups={[
          buildPermission(
            PermissionAction.read,
            PermissionResource.robots_health,
            PermissionDomain.customer
          ),
          buildPermission(
            PermissionAction.read,
            PermissionResource.robots_health,
            PermissionDomain.all
          ),
        ]}
      >
        <Typography variant="h4" className="text-center my-8 text-xl">
          {t("views.fleet.robots.summary.machineHealth")}
        </Typography>
        <div className="flex flex-col md:flex-row items-stretch gap-8">
          <ConfigHealthCard
            className="min-w-52 flex-1"
            config={config}
            template={template}
            health={summary?.robot?.health}
          />
          <WheelEncodersCard
            className="min-w-52 flex-1"
            serial={summary?.robot?.serial}
            config={config}
          />
          <SafetyRadiusCard
            className="min-w-52 flex-1"
            serial={summary?.robot?.serial}
            config={config}
          />
        </div>
      </WithAuthorizationRequired>
    </>
  );
};

export const RobotSummary = withAuthenticationRequired(_RobotSummary);
