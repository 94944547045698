import { Alert, AlertTitle, Chip } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { ConfigNode } from "protos/config/api/config_service";
import { ConfigResponse } from "protos/portal/configs";
import { DateTime } from "luxon";
import { getCustomerSerial } from "portal/utils/robots";
import {
  getNodeFromPath,
  getParentPath,
  isListNode,
} from "portal/utils/configs";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import AddIcon from "@mui/icons-material/CreateNewFolderOutlined";
import ChangeIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/FolderOffOutlined";
import React, { FunctionComponent, ReactNode } from "react";

type UnsyncedKey = ConfigResponse["unsyncedKeys"][0];

interface UnsyncedKeyWarningProps {
  serial?: string;
  config?: ConfigNode;
  unsyncedKeys?: UnsyncedKey[];
}

const _UnsyncedKeyWarning: FunctionComponent<UnsyncedKeyWarningProps> = ({
  serial,
  config,
  unsyncedKeys,
}) => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();

  if (!serial || !config || !unsyncedKeys || unsyncedKeys.length === 0) {
    return;
  }

  return (
    <Alert severity="warning" className="mb-4">
      <AlertTitle>
        {t("views.admin.config.warnings.unsyncedKeys.title")}
      </AlertTitle>
      {t("views.admin.config.warnings.unsyncedKeys.description", {
        serial: isInternal ? serial : getCustomerSerial(t, serial),
      })}
      <ul className="pl-0 flex flex-col gap-4">
        {unsyncedKeys.map((unsyncedKey) => (
          <UnsyncedKeyItem
            config={config}
            key={unsyncedKey.key}
            unsyncedKey={unsyncedKey}
          />
        ))}
      </ul>
    </Alert>
  );
};

export const UnsyncedKeyWarning = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.all
    ),
  ],
  _UnsyncedKeyWarning
);

interface UnsyncedKeyItemProps {
  unsyncedKey: UnsyncedKey;
  config: ConfigNode;
}

const UnsyncedKeyItem: FunctionComponent<UnsyncedKeyItemProps> = ({
  unsyncedKey,
  config,
}) => {
  const { ts, key, oldValue, newValue } = unsyncedKey;
  let displayPath: string;
  let displayOld: string;
  let displayIcon: ReactNode;
  let displayNew: string;
  let displayNewClassName: string;
  const node = getNodeFromPath(config, key);
  const parentPath = getParentPath(key);
  const parent = getNodeFromPath(config, parentPath);
  if (node && isListNode(node)) {
    // node added
    displayPath = key;
    displayOld = "";
    displayIcon = <AddIcon />;
    displayNew = newValue;
    displayNewClassName = "italic";
  } else if (!node && isListNode(parent)) {
    // node removed
    displayPath = parentPath;
    displayOld = "";
    displayIcon = <DeleteIcon />;
    displayNewClassName = "line-through";
    displayNew = key.replace(`${parentPath}/`, "");
  } else {
    // node changed
    displayPath = key;
    displayOld = oldValue;
    displayIcon = <ChangeIcon />;
    displayNew = newValue;
    displayNewClassName = "italic";
  }
  return (
    <li key={key} className="flex gap-2 font-mono text-sm items-center">
      <Chip
        key="date"
        size="small"
        variant="filled"
        className="text-inherit"
        label={DateTime.fromMillis(ts).toLocaleString(DateTime.DATETIME_SHORT)}
      />
      <div className="font-bold">{displayPath}</div>
      {displayOld && <div className="line-through">{displayOld}</div>}
      {displayIcon}
      {displayNew && <div className={displayNewClassName}>{displayNew}</div>}
    </li>
  );
};
