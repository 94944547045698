import { Skeleton } from "@mui/material";
import { ThumbnailImage, ThumbnailImageItem } from "./ThumbnailImage";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";

interface ImageGridProps extends PropsWithChildren {
  loadingItemCount: number;
  loading?: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  thumbnailImages: Array<ThumbnailImageItem | undefined>;
  onImagesLoaded?: (loadedCount: number) => void;
}
export const ImageGrid: FunctionComponent<ImageGridProps> = ({
  loadingItemCount,
  loading,
  thumbnailImages,
  dimensions,
  onImagesLoaded,
}) => {
  const imagesLoaded = useRef<Set<string>>(new Set());

  const onImageLoaded = useCallback(
    (id: string): void => {
      imagesLoaded.current.add(id);
      onImagesLoaded?.(imagesLoaded.current.size);
    },
    [onImagesLoaded]
  );

  return (
    <div className="flex flex-wrap gap-2 justify-start">
      {thumbnailImages.map((thumbnail, index) => (
        <div
          className="relative"
          style={dimensions}
          key={`${thumbnail?.id}-${index}`}
        >
          <ThumbnailImage
            image={thumbnail}
            dimensions={dimensions}
            onImageLoaded={onImageLoaded}
          />
        </div>
      ))}
      {loading
        ? Array.from({ length: loadingItemCount })
            .fill(undefined)
            .map((_, index) => (
              <Skeleton
                key={`loading-${index}`}
                variant="rectangular"
                width={dimensions.width}
                height={dimensions.height}
              />
            ))
        : undefined}
    </div>
  );
};
