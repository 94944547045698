import { classes } from "portal/utils/theme";
import { Skeleton, SkeletonProps } from "@mui/material";
import React, { cloneElement, FunctionComponent, isValidElement } from "react";

/**
 * Wrapper component that shows a skeleton loader for the child element
 */
export interface WithSkeletonProps extends SkeletonProps {
  error?: boolean;
  success: boolean;
}
export const WithSkeleton: FunctionComponent<WithSkeletonProps> = (props) => {
  const { error, success, children, className, ...rest } = props;

  if (error || !children) {
    return;
  }

  if (!success) {
    return <Skeleton className={className} {...rest} />;
  }

  if (!isValidElement(children)) {
    return <>{children}</>;
  }

  // if it's an element, try to add the className to it
  return cloneElement(children, {
    className: classes(className, children.props.className),
  } as any);
};
