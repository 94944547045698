import { AnyMetric, CycleSlot, isMetricNumber } from "portal/utils/metrics";
import {
  BLANK_VALUE,
  formatMeasurement,
  formatMetric,
  formatString,
  FormattedMeasurement,
} from "./formatters";
import {
  CarbonUnit,
  getCycle,
  loadSavedUnit,
  MeasurementSystem,
  UnitNumberType,
} from "portal/utils/units/units";
import { checkNever, isUndefined } from "portal/utils/identity";
import { classes } from "portal/utils/theme";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { isEmpty } from "portal/utils/arrays";
import { setCycleSlot } from "portal/state/measurement";
import { useDispatch } from "react-redux";
import { useMeasurement, useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../ErrorBoundary";
import React, {
  FunctionComponent,
  Key,
  ReactElement,
  ReactNode,
  useState,
} from "react";

interface MeasurementBaseProps {
  valueClassName?: string;
  unitClassName?: string;
  className?: string;
  blankValue?: string;
  cycleSlot?: CycleSlot;
  cycle?: { [K in MeasurementSystem]?: CarbonUnit[] };
  longUnits?: boolean;
  onlyUnits?: boolean;
}

export type MetricValue = string | number | boolean | undefined;

interface MeasurementMetricProps extends MeasurementBaseProps {
  metric: AnyMetric;
  value?: MetricValue | MetricValue[] | undefined;
  toUnits?: CarbonUnit;
  decimalPlaces?: number;
}

interface MeasurementStandaloneProps extends MeasurementBaseProps {
  value?: number | number[] | undefined;
  fromUnits?: CarbonUnit;
  toUnits?: CarbonUnit;
  decimalPlaces?: number;
}

export type MeasurementProps =
  | MeasurementMetricProps
  | MeasurementStandaloneProps;

const isMeasurementMetric = (
  props: MeasurementProps
): props is MeasurementMetricProps => "metric" in props;
const isMeasurementStandalone = (
  props: MeasurementProps
): props is MeasurementStandaloneProps => !("metric" in props);

function normalizeValues<T>(values: T | T[] | undefined): T[] {
  if (values === undefined) {
    return [];
  } else if (Array.isArray(values)) {
    return values;
  } else {
    return [values];
  }
}

const _Measurement: FunctionComponent<MeasurementProps> = (props) => {
  // imports
  const { t, i18n } = useTranslation();
  const { measurementSystem } = useSelf();
  const dispatch = useDispatch();
  const { cycleSlots } = useMeasurement();

  const values = normalizeValues(props.value);

  const { cycleSlot } = isMeasurementMetric(props) ? props.metric : props;
  const [isOpen, setOpen] = useState<boolean>(false);

  let cycle =
    isMeasurementMetric(props) && isMetricNumber(props.metric)
      ? props.metric.cycle?.[measurementSystem] ??
        props.cycle?.[measurementSystem]
      : props.cycle?.[measurementSystem];
  const savedUnit = loadSavedUnit(
    cycleSlots,
    cycleSlot,
    measurementSystem,
    cycle
  );

  const toUnits =
    isMeasurementMetric(props) && isMetricNumber(props.metric)
      ? savedUnit ?? props.metric.toUnits ?? props.toUnits
      : savedUnit ?? props.toUnits;

  const {
    blankValue,
    decimalPlaces,
    unitClassName,
    valueClassName,
    longUnits = false,
  } = props;
  let formatted: FormattedMeasurement[];
  if (isMeasurementMetric(props)) {
    const { metric } = props;
    formatted = isEmpty(values)
      ? [
          formatMetric(t, i18n, measurementSystem, metric, 0, {
            decimalPlaces,
            blankValue,
            toUnits,
          }),
        ]
      : values.map((value) =>
          formatMetric(t, i18n, measurementSystem, metric, value, {
            decimalPlaces,
            blankValue,
            toUnits,
          })
        );
  } else if (isMeasurementStandalone(props)) {
    const { fromUnits } = props;
    const values = normalizeValues(props.value); // refine type
    formatted = isEmpty(values)
      ? [
          formatMeasurement(
            t,
            i18n,
            measurementSystem,
            0,
            fromUnits ?? UnitNumberType.COUNT,
            { toUnits, blankValue, longUnits }
          ),
        ]
      : values.map((value) => {
          return typeof value === "string"
            ? formatString(value)
            : formatMeasurement(
                t,
                i18n,
                measurementSystem,
                value,
                fromUnits ?? UnitNumberType.COUNT,
                { toUnits, decimalPlaces, blankValue, longUnits }
              );
        });
  } else {
    console.error("Unexpected Measurement props:", checkNever(props));
    formatted = [formatString(String(values))];
  }

  const renderValue = (values: string[], key: Key): ReactElement => (
    <span
      key={`value-${key}`}
      className={classes("flex flex-col", valueClassName)}
    >
      {values.map((value, index) => (
        <span
          key={index}
          className={classes(
            "flex-grow leading-none min-w-1 flex items-center",
            {
              "opacity-50": !value || [blankValue, BLANK_VALUE].includes(value),
            }
          )}
        >
          {value || blankValue}
        </span>
      ))}
    </span>
  );

  const renderUnit = (
    values: string[],
    units: string[],
    key: Key
  ): ReactNode => {
    if (values.every((value) => value === BLANK_VALUE)) {
      return;
    }
    return units.length > 0 ? (
      <span
        key={`unit-${key}`}
        className={classes(
          "font-normal text-xs opacity-50 flex flex-col",
          unitClassName
        )}
      >
        {units.map((unit, index) => (
          <span key={index} className="flex-1 min-w-1 flex items-center">
            {unit}
          </span>
        ))}
      </span>
    ) : (
      <></>
    );
  };

  const sampleValue =
    formatted.find((value) => value.value !== BLANK_VALUE) ?? formatted[0];
  if (!sampleValue) {
    return;
  }

  const { units: display, carbonUnits: units, unitsDelimiter } = sampleValue;
  if (units && !cycle) {
    cycle = getCycle(units, measurementSystem);
  }
  // if we didn't have a unit saved for this cycleSlot, use the first "best"
  // unit we calculate
  if (cycleSlot && units && !savedUnit) {
    setTimeout(() => dispatch(setCycleSlot([cycleSlot, units])));
  }
  const segments: ReactNode[] = [];

  const mappedValues = formatted.map((value) => value.value);
  if (!props.onlyUnits) {
    segments.push(renderValue(mappedValues, 0));
    if (unitsDelimiter !== " ") {
      segments.push(
        <span className="-mx-1 text-xs opacity-50" key={"delimiter"}>
          {unitsDelimiter}
        </span>
      );
    }
  }
  segments.push(renderUnit(mappedValues, [display], 0));

  const canCycle = Boolean(
    !isUndefined(cycle) && cycle.length > 1 && !isUndefined(cycleSlot)
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        className={classes(
          "unset flex items-center justify-end h-full w-full gap-1",
          "border-0 hover:border-b hover:border-b-dashed",
          {
            "cursor-ew-resize": canCycle,
          },
          props.className
        )}
        role="button"
        tabIndex={0}
        onClick={(event) => {
          if (!canCycle || isUndefined(cycle) || !cycleSlot) {
            return;
          }
          event.stopPropagation();
          if (isOpen) {
            setOpen(false);
            return;
          }
          setOpen(true);
        }}
      >
        {canCycle ? (
          <>
            {segments.slice(0, -1)}
            <Tooltip
              classes={{
                tooltip: "bg-darken-800 flex p-0",
              }}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
              open={isOpen}
              title={
                <>
                  {cycle?.map((unit) => (
                    <button
                      className="
                          bg-transparent border-0 hover:bg-lighten-100
                          p-2
                          text-xs text-white
                          cursor-pointer
                        "
                      onClick={() => {
                        if (!cycleSlot) {
                          return;
                        }
                        dispatch(setCycleSlot([cycleSlot, unit]));
                      }}
                      key={unit}
                    >
                      {unit}
                    </button>
                  ))}
                </>
              }
            >
              <div
                className={classes("relative", {
                  "bg-darken-800 text-white -m-1 p-1": isOpen,
                })}
              >
                {segments.at(-1)}
              </div>
            </Tooltip>
          </>
        ) : (
          segments
        )}
      </div>
    </ClickAwayListener>
  );
};

export const Measurement = withErrorBoundary(
  {
    i18nKey: "utils.descriptors.error",
    small: true,
  },
  _Measurement
);
