import * as Sentry from "@sentry/browser";
import { CarbonUser, isInternal } from "portal/utils/auth";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { CustomerResponse } from "protos/portal/customers";
import { FleetView, UserResponse } from "protos/portal/users";
import { MeasurementSystem } from "portal/utils/units/units";
import { StreamToken } from "portal/utils/stream";
import { toImplementationStatus } from "portal/utils/robots";
import { values } from "portal/utils/objects";

interface State {
  user?: CarbonUser;
  customer?: CustomerResponse;
  // Index of `user?.appMetadata?.permissions`, for quick authorization lookups.
  permissions: Record<string, true>;
  isInternal: boolean;
  isLoading: boolean;
  measurementSystem: MeasurementSystem;
  settings: CarbonUser["userMetadata"];
  streamToken?: StreamToken;
  fleetViews: Record<number, FleetView>;
}

const initialState: State = {
  permissions: {},
  isInternal: false,
  isLoading: true,
  measurementSystem: MeasurementSystem.imperial,
  settings: {},
  fleetViews: {},
};

const isMeasurementSystem = (
  input: string | undefined
): input is MeasurementSystem =>
  values(MeasurementSystem).includes(input as MeasurementSystem);

export const self = createSlice({
  name: "self",
  initialState,
  reducers: {
    setCustomerMode: (state, action: PayloadAction<boolean>) => {
      if (!state.user || !isInternal(state.user)) {
        return;
      }
      state.isInternal = !action.payload;
    },
    setStreamToken: (state, action: PayloadAction<StreamToken>) => {
      state.streamToken = action.payload;
    },
    setSelf: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload.user;
      state.customer = action.payload.customer;
      state.permissions = Object.fromEntries(
        state.user?.appMetadata?.permissions?.map((key) => [key, true]) ?? []
      );
      state.fleetViews = {};
      for (const view of action.payload.fleetViews) {
        if (!view.db) {
          continue;
        }
        state.fleetViews[view.db.id] = {
          ...view,
          statuses: view.statuses.map((status) =>
            toImplementationStatus(status)
          ),
        };
      }
      state.isInternal = isInternal(state.user);
      state.measurementSystem = isMeasurementSystem(
        state.user?.userMetadata?.unit
      )
        ? state.user.userMetadata.unit
        : MeasurementSystem.imperial;
      state.settings = state.user?.userMetadata ?? {};
      state.isLoading = false;
      Sentry.setUser(
        state.user
          ? {
              email: state.user.email,
              segment: state.isInternal ? "carbon" : "customer",
            }
          : // null required by Sentry
            // eslint-disable-next-line unicorn/no-null
            null
      );
    },
    setFleetView: (state, action: PayloadAction<FleetView>) => {
      if (!action.payload.db) {
        return;
      }
      state.fleetViews[action.payload.db.id] = action.payload;
    },
    unsetFleetView: (state, action: PayloadAction<number>) => {
      delete state.fleetViews[action.payload];
    },
  },
});

export const {
  setCustomerMode,
  setSelf,
  setStreamToken,
  setFleetView,
  unsetFleetView,
} = self.actions;
