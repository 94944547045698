import { BasicDiscriminatorCard } from "./BasicDiscriminatorCard";
import { ConfigCrop } from "protos/portal/configs";
import {
  DiscriminatorConfig,
  DiscriminatorTypeCategory,
} from "protos/almanac/almanac";
import { filterCategories, getWeedCategoryName } from "portal/utils/almanac";
import { findWhere, sortBy, without } from "portal/utils/arrays";
import { getCategoryTitle } from "portal/utils/discriminator";
import { isUndefined } from "portal/utils/identity";
import { titleCase } from "portal/utils/strings";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

interface Props {
  discriminator: DiscriminatorConfig;
  crops: ConfigCrop[];
  onChange: (newDiscriminator: DiscriminatorConfig) => void;
  readOnly?: boolean;
}

export const BasicDiscriminator: FunctionComponent<Props> = ({
  discriminator,
  crops,
  onChange,
  readOnly = false,
}) => {
  const { weedCategories, cropCategories } = filterCategories(
    discriminator.categories
  );

  const { t } = useTranslation();

  // mutations
  const updateCategory = (newCategory: DiscriminatorTypeCategory): void => {
    const updated = structuredClone(discriminator);
    const oldCategory = findWhere(updated.categories, {
      type: { category: newCategory.type?.category },
    });
    updated.categories = [
      ...without(updated.categories, oldCategory).filter(
        (category) => !isUndefined(category)
      ),
      newCategory,
    ];
    onChange(updated);
  };

  return (
    <div className="flex flex-col h-full">
      <Typography variant="h5">
        {titleCase(t("models.weeds.weed_other"))}
      </Typography>
      {weedCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.weeds.none")}
        </div>
      )}
      {sortBy(weedCategories, (category) => getCategoryTitle(t, category)).map(
        (category) => (
          <BasicDiscriminatorCard
            key={category.type?.category}
            category={category}
            title={getWeedCategoryName(t, category.type?.category ?? "")}
            onChange={updateCategory}
            readOnly={readOnly}
          />
        )
      )}
      <Typography variant="h5">
        {titleCase(t("models.crops.crop_other"))}
      </Typography>
      {cropCategories.length === 0 && (
        <div className="flex h-40 w-full items-center justify-center text-lighten-400">
          {t("components.almanac.crops.none")}
        </div>
      )}
      {sortBy(cropCategories, (category) =>
        getCategoryTitle(t, category, crops)
      ).map((category) => (
        <BasicDiscriminatorCard
          key={category.type?.category}
          category={category}
          title={getCategoryTitle(t, category, crops)}
          crops={crops}
          onChange={updateCategory}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};
