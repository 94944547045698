import {
  CategorizationMap,
  getPointDetectionThumbnailPaddingFactor,
  PaginationParameters,
} from "portal/utils/categoryCollectionProfile";
import { CategoryImageOverlay } from "./CategoryImageOverlay";
import { ImageGrid } from "../images/ImageGrid";
import { LabelPoint } from "protos/veselka/label_point";
import { LabelPointFilter } from "./LabelPointFilters";
import { LoadMoreImagesButton } from "./LoadMoreImagesButton";
import {
  processImageResults,
  ResultsMetadata,
} from "../images/processImageResults";
import { RadioChipOption } from "../RadioChips";
import {
  ThumbnailBaseInfo,
  ThumbnailImageItem,
} from "../images/ThumbnailImage";
import { useInfiniteScroll } from "../images/useInfiniteScroll";
import { useLazyListLabelPointsQuery } from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

interface Props {
  isSaving: boolean;
  filters: LabelPointFilter;
  categoryOptions: RadioChipOption[];
  categorizationMap: CategorizationMap;
  dimensions: { width: number; height: number };
  onImageClick: (
    id: { chipId?: string; labelPointId: string },
    thumbnail: ThumbnailBaseInfo
  ) => void;
}
const DEFAULT_PAGINATION: PaginationParameters = { page: 1, pageSize: 50 };

const initialResultsState: ResultsMetadata<LabelPoint> = {
  data: {
    ids: new Set(),
    data: [],
  },
  areAllResultsLoaded: false,
  loadableImagesCount: 0,
};

export const ImageCategorizationOptions: FunctionComponent<Props> = ({
  isSaving,
  filters,
  categoryOptions,
  categorizationMap,
  dimensions,
  onImageClick,
}) => {
  const { t } = useTranslation();

  const [imagesRendered, setImagesRendered] = useState(0);
  const [pagination, setPagination] =
    useState<PaginationParameters>(DEFAULT_PAGINATION);
  const [resultsState, setResultsState] =
    useState<ResultsMetadata<LabelPoint>>(initialResultsState);

  const [getLabelPoints] = useLazyPopups(useLazyListLabelPointsQuery());
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchResults = useCallback(
    async (
      pagination: PaginationParameters,
      filterParameters: LabelPointFilter
    ) => {
      setIsLoading(true);
      const { data: labelPointResults, isError: fetchError } =
        await getLabelPoints({
          ...pagination,
          crops: filterParameters.crops.map((c) => c.id),
          robots: filterParameters.robots.map((r) => r.id),
          capturedAt: filterParameters.capturedAt,
        });

      if (fetchError) {
        setIsError(true);
      } else {
        setResultsState((previous) =>
          processImageResults(previous, labelPointResults)
        );
        setPagination((previous) => ({
          ...previous,
          page: previous.page + 1,
        }));
      }

      setIsLoading(false);
    },
    [getLabelPoints]
  );

  // Automatically fetch more results when infinite scrolling
  const loadMore = useCallback(() => {
    if (
      !isLoading &&
      !resultsState.areAllResultsLoaded &&
      !isError &&
      imagesRendered >= resultsState.loadableImagesCount
    ) {
      fetchResults(pagination, filters);
    }
  }, [
    isLoading,
    resultsState.areAllResultsLoaded,
    resultsState.loadableImagesCount,
    isError,
    imagesRendered,
    fetchResults,
    pagination,
    filters,
  ]);

  const loadMoreRef = useInfiniteScroll<HTMLDivElement>(
    isLoading || imagesRendered < resultsState.loadableImagesCount,
    loadMore
  );

  // Reset results and refetch when filters change or when save completes
  useEffect(() => {
    if (!isSaving) {
      setResultsState(initialResultsState);
      setPagination(DEFAULT_PAGINATION);
      fetchResults(DEFAULT_PAGINATION, filters);
    }
  }, [filters, fetchResults, isSaving]);

  const thumbnailImages: Array<ThumbnailImageItem | undefined> = useMemo(
    () =>
      resultsState.data.data.map(({ id, x, y, radius, image, chipId }) => {
        if (!image) {
          return;
        }
        const padding = Math.ceil(
          radius * getPointDetectionThumbnailPaddingFactor(radius)
        );
        const imageCropDimensions = (radius + padding) * 2;
        const assignedCategoryId =
          (chipId ? categorizationMap.chip[chipId] : undefined) ??
          categorizationMap.labelPoint[id];
        const category = categoryOptions.find(
          (c) => c.id === assignedCategoryId
        );

        const baseThumbnail = {
          id,
          url: image.url,
          x: Math.ceil(x - (radius + padding)),
          y: Math.ceil(y - (radius + padding)),
          width: Math.ceil(imageCropDimensions),
          height: Math.ceil(imageCropDimensions),
        };

        return {
          ...baseThumbnail,
          onClick: () => {
            onImageClick({ chipId, labelPointId: id }, baseThumbnail);
          },
          renderOverlay: category
            ? () => (
                <CategoryImageOverlay
                  dimensions={dimensions}
                  name={category.name}
                  color={category.color}
                />
              )
            : undefined,
        };
      }),
    [
      categorizationMap.chip,
      categorizationMap.labelPoint,
      categoryOptions,
      dimensions,
      onImageClick,
      resultsState.data.data,
    ]
  );

  return (
    <>
      <ImageGrid
        loading={isLoading}
        loadingItemCount={pagination.pageSize}
        dimensions={dimensions}
        thumbnailImages={thumbnailImages}
        onImagesLoaded={setImagesRendered}
      />
      {!isLoading && resultsState.data.data.length === 0 ? (
        <p>{t("utils.lists.noResults")}</p>
      ) : (
        <div className="flex justify-center" ref={loadMoreRef}>
          <LoadMoreImagesButton
            isInitialRequestLoading={isLoading}
            canLoadMore={!resultsState.areAllResultsLoaded}
            onClick={() => fetchResults(pagination, filters)}
          />
        </div>
      )}
    </>
  );
};
