import { auth } from "portal/utils/auth";
import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/query/react";

const RETRY_EXPONENTIAL_CAP_ATTEMPTS = 5;
/**
 * Exponential backoff based on the attempt number with a cap for how much the backoff should expand
 *
 * 1. 600ms * random(0.4, 1.4)
 * 2. 1200ms * random(0.4, 1.4)
 * 3. 2400ms * random(0.4, 1.4)
 * 4. 4800ms * random(0.4, 1.4)
 * 5. 9600ms * random(0.4, 1.4)
 *
 * @param attempt - Current attempt
 * @param maxRetries - Maximum number of retries
 */
async function cappedIncreaseBackoff(attempt: number = 0): Promise<void> {
  const timeoutIncreaseFactor = Math.min(
    attempt,
    RETRY_EXPONENTIAL_CAP_ATTEMPTS
  );

  const timeout = Math.trunc(
    (Math.random() + 0.4) * (300 * 2 ** timeoutIncreaseFactor)
  );
  await new Promise((resolve) =>
    setTimeout((response) => resolve(response), timeout)
  );
}

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  retry(
    async (arguments_, api, extraOptions = {}) => {
      const result = await fetchBaseQuery({
        baseUrl: window._jsenv.REACT_APP_S3_CACHE_PROXY_URL,
        prepareHeaders: async (headers) => {
          const accessToken = await auth.getAccessTokenSilently();
          headers.set("Authorization", `Bearer ${accessToken}`);
          headers.set(
            "X-AUTH0-audience",
            window._jsenv.REACT_APP_AUTH0_AUDIENCE
          );
          return headers;
        },
      })(arguments_, api, extraOptions);

      // bail out of re-tries immediately if unauthorized,
      // because we know successive re-retries would be redundant
      if (result.error?.status === 401) {
        retry.fail(result.error);
      }

      return result;
    },
    {
      maxRetries: 20,
      backoff: cappedIncreaseBackoff,
    }
  );

export enum ImageOp {
  CROP = "crop",
  RESIZE = "resize",
}
export interface CropThumbnailRequest {
  op: ImageOp;
  bucket: string;
  key: string;
  x: number;
  y: number;
  width: number;
  height: number;
  fill?: string;
}

export const s3CacheProxyApi = createApi({
  reducerPath: "s3CacheProxyApi",
  baseQuery,
  endpoints: (builder) => ({
    getCropThumbnail: builder.query<string, CropThumbnailRequest>({
      query: (parameters) => ({
        url: "image",
        params: parameters,
      }),
      transformResponse: (response: { s3_uri: string }) => response.s3_uri,
    }),
  }),
});

export const { useGetCropThumbnailQuery } = s3CacheProxyApi;
