import { capitalize } from "@mui/material";
import { CarbonUser, isInternal } from "portal/utils/auth";
import { classes } from "portal/utils/theme";
import { DateTime } from "luxon";
import { formatCompactDate } from "portal/utils/dates";
import { isSameAuthor, isSoonAfter } from "portal/utils/chats";
import { Message } from "protos/portal/messages";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import React, { FunctionComponent } from "react";

interface ChatMessageProps {
  message: Message;
  prevMessage?: Message;
  nextMessage?: Message;
  user?: CarbonUser;
}

const _LegacyChatMessage: FunctionComponent<ChatMessageProps> = ({
  message,
  prevMessage,
  nextMessage,
  user,
}) => {
  const { t, i18n } = useTranslation();
  const isMessageSupport = isInternal({
    email: message.authorUserEmail,
    userId: message.authorUserId,
  });
  const isUserSupport = isInternal(user);
  const isLeft =
    (isMessageSupport && !isUserSupport) ||
    (!isMessageSupport && isUserSupport);
  const isPreviousSame = isSameAuthor(prevMessage, message);
  const isNextSame = isSameAuthor(message, nextMessage);
  const isPreviousSoon = isPreviousSame && isSoonAfter(prevMessage, message);
  const isNextSoon = isNextSame && isSoonAfter(message, nextMessage);
  let fromName: string;
  if (isMessageSupport) {
    fromName = isUserSupport
      ? `${t("views.fleet.robots.support.carbon")} (${
          message.authorUserName || message.authorUserEmail
        })`
      : t("views.fleet.robots.support.carbon");
  } else if (message.authorRobotId) {
    fromName = capitalize(t("models.users.operator_one"));
  } else {
    fromName = message.authorUserName || message.authorUserEmail;
  }
  return (
    <div
      className={classes("max-w-1/2 flex flex-col", {
        "self-start items-start": isLeft,
        "self-end items-end": !isLeft,
      })}
    >
      <div
        className={classes("flex flex-col rounded-md py-1 px-4 mb-1", {
          "items-start bg-zinc-500": isLeft,
          "items-end bg-blue-500": !isLeft,
          "rounded-tr-sm": !isLeft && isPreviousSoon,
          "rounded-br-sm":
            !isLeft && (isNextSoon || !nextMessage || !isNextSame),
          "rounded-br-none": !isLeft && (!isNextSoon || !isNextSame),
          "rounded-tl-sm": isLeft && isPreviousSoon,
          "rounded-bl-sm":
            isLeft && (isNextSoon || !nextMessage || !isNextSame),
          "rounded-bl-none": isLeft && (!isNextSoon || !isNextSame),
        })}
      >
        {!isPreviousSame && (
          <span className="text-xs font-bold mb-1 text-lighten-500">
            {fromName}
          </span>
        )}
        <span className="whitespace-pre-line"></span>
        {message.message}
      </div>
      {(!isNextSame || !isNextSoon) && (
        <span className="text-xs mr-1 mb-4 text-lighten-500">
          {DateTime.fromMillis(
            message.db?.createdAt ?? Number.NaN
          ).toLocaleString(DateTime.TIME_SIMPLE, {
            locale: i18n.language,
          })}{" "}
          {formatCompactDate(t, i18n, message.db?.createdAt)}
        </span>
      )}
    </div>
  );
};

export const LegacyChatMessage = withErrorBoundary(
  { i18nKey: "views.fleet.robots.support.errors.failed", small: true },
  _LegacyChatMessage
);
