import { buildPermission } from "portal/utils/auth";
import { FleetAlarmList } from "./FleetAlarmList";
import { FleetHardware } from "./FleetHardware";
import { FleetRobotList } from "./FleetRobotList";
import { FleetSubpath, getFleetPath, Path } from "portal/utils/routing";
import { GlobalHotKeys, KeyMap } from "react-hotkeys";
import { GROUP_FLEET } from "portal/utils/hotkeys";
import { Header } from "portal/components/header/Header";
import { MissionControl } from "./MissionControl";
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { NewRobot } from "portal/components/robots/NewRobot";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { titleCase } from "portal/utils/strings";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

const _Fleet: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // ### ROUTING
  const keyMap: KeyMap = {
    GO_ROBOTS: {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.robots.robot_other")),
      }),
      group: GROUP_FLEET,
      action: "keyup",
      sequence: "f r",
    },
    GO_MISSION_CONTROL: {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("views.fleet.missionControl.title")),
      }),
      group: GROUP_FLEET,
      action: "keyup",
      sequence: "f m",
    },
  };
  const routes: RouteObject[] = [
    {
      path: Path.BASE,
      element: <Navigate to={getFleetPath(FleetSubpath.ROBOTS)} replace />,
    },
    {
      path: `${FleetSubpath.ROBOTS}`,
      element: <FleetRobotList />,
    },
    {
      path: `${FleetSubpath.MISSION_CONTROL}`,
      element: <MissionControl />,
    },
  ];

  // alarms
  const isAlarmsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.all
    ),
  ]);
  if (isAlarmsAuthorized) {
    routes.push({
      path: `${FleetSubpath.ALARMS}`,
      element: <FleetAlarmList />,
    });
    keyMap.GO_ALARMS = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.alarms.alarm_other")),
      }),
      group: GROUP_FLEET,
      action: "keyup",
      sequence: "f a",
    };
  }

  // hardware
  const isHardwareAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.hardware,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.hardware,
      PermissionDomain.all
    ),
  ]);
  if (isHardwareAuthorized) {
    routes.push({
      path: `${FleetSubpath.HARDWARE}`,
      element: <FleetHardware />,
    });
    keyMap.GO_HARDWARE = {
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.robots.hardware.title"),
      }),
      group: GROUP_FLEET,
      action: "keyup",
      sequence: "f h",
    };
  }

  const element = useRoutes(routes);

  return (
    <>
      <GlobalHotKeys
        allowChanges
        keyMap={keyMap}
        handlers={{
          GO_ROBOTS: () => navigate(getFleetPath(FleetSubpath.ROBOTS)),
          GO_ALARMS: () => navigate(getFleetPath(FleetSubpath.ALARMS)),
          GO_HARDWARE: () => navigate(getFleetPath(FleetSubpath.HARDWARE)),
          GO_MISSION_CONTROL: () =>
            navigate(getFleetPath(FleetSubpath.MISSION_CONTROL)),
        }}
      />
      <Header title={t("views.fleet.title")}>
        <ResponsiveSubnav
          pages={[
            {
              label: titleCase(t("models.robots.robot_other")),
              to: getFleetPath(FleetSubpath.ROBOTS),
            },
            {
              label: titleCase(t("models.alarms.alarm_other")),
              to: getFleetPath(FleetSubpath.ALARMS),
              hidden: !isAlarmsAuthorized,
            },
            {
              label: titleCase(t("views.fleet.robots.hardware.title")),
              to: getFleetPath(FleetSubpath.HARDWARE),
              hidden: !isHardwareAuthorized,
            },
          ]}
        />
        {pathname.startsWith(getFleetPath(FleetSubpath.ROBOTS)) && <NewRobot />}
      </Header>
      <Page>{element}</Page>
    </>
  );
};

export const Fleet = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.robots,
        PermissionDomain.all
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.robots,
        PermissionDomain.customer
      ),
    ],
    _Fleet,
    <Navigate to={Path.SETTINGS} replace />
  )
);
