import { Alert, Button, capitalize } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { ConfirmationDialog } from "portal/components/ConfirmationDialog";
import { FeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { findWhere } from "portal/utils/arrays";
import { formatList, titleCase } from "portal/utils/strings";
import {
  getReportInstancesPath,
  getReportPath,
  Slug,
} from "portal/utils/routing";
import {
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RED_LOADING_BUTTON } from "portal/utils/theme";
import { ReportResponse } from "protos/portal/reports";
import { SearchField } from "portal/components/header/SearchField";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import {
  useDeleteReportMutation,
  useListReportsQuery,
} from "portal/state/portalApi";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withFeatureFlag } from "portal/components/RequireFeatureFlag";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, { FunctionComponent, useState } from "react";
import RunsIcon from "@mui/icons-material/TocOutlined";

const _ReportList: FunctionComponent = () => {
  const { user, customer } = useSelf();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: reports, isLoading } = useQueryPopups(useListReportsQuery());

  const canUpdateSelf = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.reports,
      PermissionDomain.self
    ),
  ]);
  const canUpdateCustomer = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.reports,
      PermissionDomain.customer
    ),
  ]);
  const canUpdateAll = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.reports,
      PermissionDomain.all
    ),
  ]);
  const canReadCustomers = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);

  const [deleteReport, { isLoading: isDeleteLoading }] = useMutationPopups(
    useDeleteReportMutation(),
    {
      success: capitalize(
        t("utils.actions.deletedLong", {
          subject: t("models.reports.report_one"),
        })
      ),
    }
  );
  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { searchText, setSearchText, results } = useFuzzySearch<ReportResponse>(
    {
      items: reports ?? [],
      options: {
        keys: ["name", "slug"],
      },
    }
  );
  const [selectedSlugs, setSelectedSlugs] = useState<GridRowSelectionModel>([]);

  return (
    <CarbonDataGrid<ReportResponse>
      className="flex flex-1"
      rows={results}
      getRowId={(report) => report.slug}
      getRowClassName={() => "cursor-pointer"}
      header={
        <>
          <SearchField
            value={searchText}
            onChange={setSearchText}
            label={t("utils.actions.searchLong", {
              subject: capitalize(
                t("models.reports.report_one", {
                  count: reports?.length,
                })
              ),
            })}
          />
          <div className="flex gap-2">
            <LoadingButton
              {...RED_LOADING_BUTTON}
              loading={isDeleteLoading}
              disabled={selectedSlugs.length === 0}
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              <span className="ml-1 hidden md:inline">
                {t("utils.actions.deleteLong", {
                  subject: t("utils.table.selected"),
                })}
              </span>
              <span className="ml-1 md:hidden">
                {t("utils.actions.delete")}
              </span>
            </LoadingButton>
            {confirmDelete &&
              (() => {
                const selectedReports: ReportResponse[] = [];
                const unownedReports: ReportResponse[] = [];
                for (const slug of selectedSlugs) {
                  const report = findWhere(reports, { slug });
                  if (!report) {
                    continue;
                  }
                  const canUpdateReport =
                    (canUpdateSelf && report.authorId === user?.userId) ||
                    (canUpdateCustomer &&
                      report.customerId === customer?.db?.id) ||
                    canUpdateAll;
                  if (canUpdateReport) {
                    selectedReports.push(report);
                  } else {
                    unownedReports.push(report);
                  }
                }
                return (
                  <ConfirmationDialog
                    title={t("utils.actions.deleteLong", {
                      subject: capitalize(
                        t("models.reports.report_one", {
                          count: selectedReports.length,
                        })
                      ),
                    })}
                    description={
                      <>
                        {unownedReports.length > 0 && (
                          <Alert severity="warning">
                            {t(
                              "views.reports.scheduled.reportList.errors.unauthorized",
                              {
                                subject: formatList(
                                  t,
                                  unownedReports.map(({ name }) => name)
                                ),
                              }
                            )}
                          </Alert>
                        )}
                        <span>
                          {t(
                            "views.reports.scheduled.reportList.deleteConfirmationDescription",
                            {
                              list: formatList(
                                t,
                                selectedReports.map(({ name }) => name)
                              ),
                            }
                          )}
                        </span>
                      </>
                    }
                    destructive
                    yesText={t("utils.actions.deleteLong", {
                      subject: capitalize(
                        t("models.reports.report", {
                          count: selectedReports.length,
                        })
                      ),
                    })}
                    onClose={() => setConfirmDelete(false)}
                    onYes={async () => {
                      await Promise.all(
                        selectedReports.map(({ slug }) =>
                          deleteReport({ slug })
                        )
                      );
                    }}
                  />
                );
              })()}
            <Button
              className="text-white"
              component={Link}
              startIcon={<AddIcon />}
              to={getReportPath(Slug.NEW)}
            >
              <span className="ml-1 hidden md:inline">
                {t("utils.actions.newLong", {
                  subject: capitalize(t("models.reports.report_one")),
                })}
              </span>
              <span className="ml-1 md:hidden">{t("utils.actions.new")}</span>
            </Button>
          </div>
        </>
      }
      hideFooter
      checkboxSelection
      onRowSelectionModelChange={(newRowSelectionModel) =>
        setSelectedSlugs(newRowSelectionModel)
      }
      rowSelectionModel={selectedSlugs}
      onRowClick={({ row: report }) => navigate(getReportPath(report.slug))}
      columnVisibilityModel={{
        customerId: canReadCustomers,
        automateWeekly: canReadCustomers,
      }}
      columns={[
        {
          disableColumnMenu: true,
          field: "name",
          type: "string",
          headerName: t("models.reports.fields.name"),
        },
        {
          disableColumnMenu: true,
          field: "authorId",
          type: "string",
          headerName: t("models.reports.fields.authorId"),
          valueGetter: (value, report) => report.authorName,
        },
        {
          disableColumnMenu: true,
          field: "customerId",
          type: "string",
          headerName: t("models.customers.customer_one"),
          valueGetter: (value, report) => report.customer?.name,
        },
        {
          disableColumnMenu: true,
          field: "automateWeekly",
          type: "string",
          headerName: t("models.reports.fields.automateWeekly.name"),
          valueFormatter: (automateWeekly) =>
            // idk why valueFormatter's value is never because it definitely is the result of valueGetter
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            automateWeekly
              ? t("models.reports.fields.automateWeekly.values.weekly")
              : "",
        },
        {
          disableColumnMenu: true,
          type: "actions",
          field: "actions",
          headerName: titleCase(t("models.reportInstances.run_other")),
          getActions: ({ row: report }) => [
            <GridActionsCellItem
              key="runs"
              icon={<RunsIcon className="text-white" />}
              onClick={() => navigate(getReportInstancesPath(report.slug))}
              label={t("utils.actions.viewLong", {
                subject: t("models.reportInstances.run_other"),
              })}
            />,
          ],
        },
      ]}
      disableRowSelectionOnClick
      loading={isLoading}
    />
  );
};

export const ReportList = withAuthenticationRequired(
  withFeatureFlag(
    {
      flag: FeatureFlag.REPORTS,
      noFlag: "/",
    },
    _ReportList
  )
);
