import { Grid, InputAdornment, Slider, TextField } from "@mui/material";
import { GRID_ROW } from "./FormulaCard";
import { isUndefined } from "portal/utils/identity";
import { numberOrEmptyString } from "portal/utils/forms";
import { SMALL_TEXT_FIELD_DARK } from "portal/utils/theme";
import { TVEProfile } from "protos/target_velocity_estimator/target_velocity_estimator";
import { useTranslation } from "react-i18next";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

interface Props {
  label: string;
  description: string;
  field: keyof TVEProfile;
  tveProfile: TVEProfile;
  setUpdatedTve: Dispatch<SetStateAction<TVEProfile | undefined>>;
  disabled?: boolean;
}

export const SmoothingInputSlider: FunctionComponent<Props> = ({
  label,
  description,
  field,
  tveProfile,
  setUpdatedTve,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Grid {...GRID_ROW} key={field}>
      <Grid item xs={12} md={2} className="font-bold" order={1}>
        {label}
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className="flex flex-col items-center"
        order={{ xs: 3, md: 2 }}
      >
        <TextField
          {...SMALL_TEXT_FIELD_DARK}
          disabled={disabled}
          type="number"
          inputProps={{ min: 0, max: 100 }}
          value={(() => {
            const value = tveProfile[field];
            return typeof value === "number"
              ? Math.round(Number(value) * 100)
              : value;
          })()}
          className="w-full text-center"
          onChange={(event) => {
            const value = numberOrEmptyString(event.target.value);
            setUpdatedTve({
              ...tveProfile,
              [field]: typeof value === "number" ? value / 100 : value,
            });
          }}
          InputProps={{
            ...SMALL_TEXT_FIELD_DARK.InputProps,
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8} className="text-sm" order={{ xs: 2, md: 3 }}>
        {description}
      </Grid>
      {/* Slider */}
      <Grid item xs={12} className="p-10" order={4}>
        <Slider
          track={false}
          min={0}
          max={100}
          disabled={disabled}
          classes={{
            rail: "bg-white opacity-100",
            markLabel: "text-white",
            mark: "hidden",
          }}
          value={(() => {
            const value = tveProfile[field];
            return typeof value === "number"
              ? Math.round(Number(value) * 100)
              : undefined;
          })()}
          onChange={(__, value) => {
            setUpdatedTve({
              ...tveProfile,
              [field]: isUndefined(value) ? value : Number(value) / 100,
            });
          }}
          marks={[
            {
              value: 0,
              label: t("components.velocityEstimator.slider.gradual"),
            },
            { value: Number(tveProfile[field]) * 100 },
            {
              value: 100,
              label: t("components.velocityEstimator.slider.immediate"),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
