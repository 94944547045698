// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: frontend/laser.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../util/util";

export const protobufPackage = "carbon.frontend.laser";

export interface LaserDescriptor {
  rowNumber: number;
  laserId: number;
  cameraId: string;
  serial: string;
}

export interface LaserState {
  laserDescriptor: LaserDescriptor | undefined;
  firing: boolean;
  enabled: boolean;
  error: boolean;
  totalFireCount: number;
  totalFireTimeMs: number;
  deltaTemp: number;
  current: number;
  targetTrajectoryId: number;
  lifetimeSec: number;
  powerLevel: number;
}

export interface LaserStateList {
  ts: Timestamp | undefined;
  lasers: LaserState[];
}

export interface RowRequest {
  rowNumber: number;
}

export interface SetLaserPowerRequest {
  laserDescriptor: LaserDescriptor | undefined;
  powerLevel: number;
}

export interface FixLaserMetricsRequest {
  laserDescriptor: LaserDescriptor | undefined;
  totalFireCount: number;
  totalFireTimeMs: number;
  lifetimeSec: number;
}

function createBaseLaserDescriptor(): LaserDescriptor {
  return { rowNumber: 0, laserId: 0, cameraId: "", serial: "" };
}

export const LaserDescriptor: MessageFns<LaserDescriptor> = {
  encode(message: LaserDescriptor, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rowNumber !== 0) {
      writer.uint32(8).uint32(message.rowNumber);
    }
    if (message.laserId !== 0) {
      writer.uint32(16).uint32(message.laserId);
    }
    if (message.cameraId !== "") {
      writer.uint32(26).string(message.cameraId);
    }
    if (message.serial !== "") {
      writer.uint32(34).string(message.serial);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserDescriptor {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserDescriptor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.rowNumber = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.laserId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cameraId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serial = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserDescriptor {
    return {
      rowNumber: isSet(object.rowNumber) ? globalThis.Number(object.rowNumber) : 0,
      laserId: isSet(object.laserId) ? globalThis.Number(object.laserId) : 0,
      cameraId: isSet(object.cameraId) ? globalThis.String(object.cameraId) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
    };
  },

  toJSON(message: LaserDescriptor): unknown {
    const obj: any = {};
    if (message.rowNumber !== 0) {
      obj.rowNumber = Math.round(message.rowNumber);
    }
    if (message.laserId !== 0) {
      obj.laserId = Math.round(message.laserId);
    }
    if (message.cameraId !== "") {
      obj.cameraId = message.cameraId;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserDescriptor>, I>>(base?: I): LaserDescriptor {
    return LaserDescriptor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserDescriptor>, I>>(object: I): LaserDescriptor {
    const message = createBaseLaserDescriptor();
    message.rowNumber = object.rowNumber ?? 0;
    message.laserId = object.laserId ?? 0;
    message.cameraId = object.cameraId ?? "";
    message.serial = object.serial ?? "";
    return message;
  },
};

function createBaseLaserState(): LaserState {
  return {
    laserDescriptor: undefined,
    firing: false,
    enabled: false,
    error: false,
    totalFireCount: 0,
    totalFireTimeMs: 0,
    deltaTemp: 0,
    current: 0,
    targetTrajectoryId: 0,
    lifetimeSec: 0,
    powerLevel: 0,
  };
}

export const LaserState: MessageFns<LaserState> = {
  encode(message: LaserState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.laserDescriptor !== undefined) {
      LaserDescriptor.encode(message.laserDescriptor, writer.uint32(10).fork()).join();
    }
    if (message.firing !== false) {
      writer.uint32(16).bool(message.firing);
    }
    if (message.enabled !== false) {
      writer.uint32(24).bool(message.enabled);
    }
    if (message.error !== false) {
      writer.uint32(32).bool(message.error);
    }
    if (message.totalFireCount !== 0) {
      writer.uint32(40).int64(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      writer.uint32(48).int64(message.totalFireTimeMs);
    }
    if (message.deltaTemp !== 0) {
      writer.uint32(61).float(message.deltaTemp);
    }
    if (message.current !== 0) {
      writer.uint32(77).float(message.current);
    }
    if (message.targetTrajectoryId !== 0) {
      writer.uint32(80).uint32(message.targetTrajectoryId);
    }
    if (message.lifetimeSec !== 0) {
      writer.uint32(88).uint64(message.lifetimeSec);
    }
    if (message.powerLevel !== 0) {
      writer.uint32(101).float(message.powerLevel);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.laserDescriptor = LaserDescriptor.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.firing = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.error = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalFireCount = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.totalFireTimeMs = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.deltaTemp = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.current = reader.float();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.targetTrajectoryId = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.lifetimeSec = longToNumber(reader.uint64());
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.powerLevel = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserState {
    return {
      laserDescriptor: isSet(object.laserDescriptor) ? LaserDescriptor.fromJSON(object.laserDescriptor) : undefined,
      firing: isSet(object.firing) ? globalThis.Boolean(object.firing) : false,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      error: isSet(object.error) ? globalThis.Boolean(object.error) : false,
      totalFireCount: isSet(object.totalFireCount) ? globalThis.Number(object.totalFireCount) : 0,
      totalFireTimeMs: isSet(object.totalFireTimeMs) ? globalThis.Number(object.totalFireTimeMs) : 0,
      deltaTemp: isSet(object.deltaTemp) ? globalThis.Number(object.deltaTemp) : 0,
      current: isSet(object.current) ? globalThis.Number(object.current) : 0,
      targetTrajectoryId: isSet(object.targetTrajectoryId) ? globalThis.Number(object.targetTrajectoryId) : 0,
      lifetimeSec: isSet(object.lifetimeSec) ? globalThis.Number(object.lifetimeSec) : 0,
      powerLevel: isSet(object.powerLevel) ? globalThis.Number(object.powerLevel) : 0,
    };
  },

  toJSON(message: LaserState): unknown {
    const obj: any = {};
    if (message.laserDescriptor !== undefined) {
      obj.laserDescriptor = LaserDescriptor.toJSON(message.laserDescriptor);
    }
    if (message.firing !== false) {
      obj.firing = message.firing;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.error !== false) {
      obj.error = message.error;
    }
    if (message.totalFireCount !== 0) {
      obj.totalFireCount = Math.round(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      obj.totalFireTimeMs = Math.round(message.totalFireTimeMs);
    }
    if (message.deltaTemp !== 0) {
      obj.deltaTemp = message.deltaTemp;
    }
    if (message.current !== 0) {
      obj.current = message.current;
    }
    if (message.targetTrajectoryId !== 0) {
      obj.targetTrajectoryId = Math.round(message.targetTrajectoryId);
    }
    if (message.lifetimeSec !== 0) {
      obj.lifetimeSec = Math.round(message.lifetimeSec);
    }
    if (message.powerLevel !== 0) {
      obj.powerLevel = message.powerLevel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserState>, I>>(base?: I): LaserState {
    return LaserState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserState>, I>>(object: I): LaserState {
    const message = createBaseLaserState();
    message.laserDescriptor = (object.laserDescriptor !== undefined && object.laserDescriptor !== null)
      ? LaserDescriptor.fromPartial(object.laserDescriptor)
      : undefined;
    message.firing = object.firing ?? false;
    message.enabled = object.enabled ?? false;
    message.error = object.error ?? false;
    message.totalFireCount = object.totalFireCount ?? 0;
    message.totalFireTimeMs = object.totalFireTimeMs ?? 0;
    message.deltaTemp = object.deltaTemp ?? 0;
    message.current = object.current ?? 0;
    message.targetTrajectoryId = object.targetTrajectoryId ?? 0;
    message.lifetimeSec = object.lifetimeSec ?? 0;
    message.powerLevel = object.powerLevel ?? 0;
    return message;
  },
};

function createBaseLaserStateList(): LaserStateList {
  return { ts: undefined, lasers: [] };
}

export const LaserStateList: MessageFns<LaserStateList> = {
  encode(message: LaserStateList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    for (const v of message.lasers) {
      LaserState.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserStateList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserStateList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lasers.push(LaserState.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserStateList {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      lasers: globalThis.Array.isArray(object?.lasers) ? object.lasers.map((e: any) => LaserState.fromJSON(e)) : [],
    };
  },

  toJSON(message: LaserStateList): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.lasers?.length) {
      obj.lasers = message.lasers.map((e) => LaserState.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserStateList>, I>>(base?: I): LaserStateList {
    return LaserStateList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserStateList>, I>>(object: I): LaserStateList {
    const message = createBaseLaserStateList();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.lasers = object.lasers?.map((e) => LaserState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRowRequest(): RowRequest {
  return { rowNumber: 0 };
}

export const RowRequest: MessageFns<RowRequest> = {
  encode(message: RowRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rowNumber !== 0) {
      writer.uint32(8).uint32(message.rowNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RowRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRowRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.rowNumber = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RowRequest {
    return { rowNumber: isSet(object.rowNumber) ? globalThis.Number(object.rowNumber) : 0 };
  },

  toJSON(message: RowRequest): unknown {
    const obj: any = {};
    if (message.rowNumber !== 0) {
      obj.rowNumber = Math.round(message.rowNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RowRequest>, I>>(base?: I): RowRequest {
    return RowRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RowRequest>, I>>(object: I): RowRequest {
    const message = createBaseRowRequest();
    message.rowNumber = object.rowNumber ?? 0;
    return message;
  },
};

function createBaseSetLaserPowerRequest(): SetLaserPowerRequest {
  return { laserDescriptor: undefined, powerLevel: 0 };
}

export const SetLaserPowerRequest: MessageFns<SetLaserPowerRequest> = {
  encode(message: SetLaserPowerRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.laserDescriptor !== undefined) {
      LaserDescriptor.encode(message.laserDescriptor, writer.uint32(10).fork()).join();
    }
    if (message.powerLevel !== 0) {
      writer.uint32(21).float(message.powerLevel);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetLaserPowerRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetLaserPowerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.laserDescriptor = LaserDescriptor.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.powerLevel = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetLaserPowerRequest {
    return {
      laserDescriptor: isSet(object.laserDescriptor) ? LaserDescriptor.fromJSON(object.laserDescriptor) : undefined,
      powerLevel: isSet(object.powerLevel) ? globalThis.Number(object.powerLevel) : 0,
    };
  },

  toJSON(message: SetLaserPowerRequest): unknown {
    const obj: any = {};
    if (message.laserDescriptor !== undefined) {
      obj.laserDescriptor = LaserDescriptor.toJSON(message.laserDescriptor);
    }
    if (message.powerLevel !== 0) {
      obj.powerLevel = message.powerLevel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetLaserPowerRequest>, I>>(base?: I): SetLaserPowerRequest {
    return SetLaserPowerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetLaserPowerRequest>, I>>(object: I): SetLaserPowerRequest {
    const message = createBaseSetLaserPowerRequest();
    message.laserDescriptor = (object.laserDescriptor !== undefined && object.laserDescriptor !== null)
      ? LaserDescriptor.fromPartial(object.laserDescriptor)
      : undefined;
    message.powerLevel = object.powerLevel ?? 0;
    return message;
  },
};

function createBaseFixLaserMetricsRequest(): FixLaserMetricsRequest {
  return { laserDescriptor: undefined, totalFireCount: 0, totalFireTimeMs: 0, lifetimeSec: 0 };
}

export const FixLaserMetricsRequest: MessageFns<FixLaserMetricsRequest> = {
  encode(message: FixLaserMetricsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.laserDescriptor !== undefined) {
      LaserDescriptor.encode(message.laserDescriptor, writer.uint32(10).fork()).join();
    }
    if (message.totalFireCount !== 0) {
      writer.uint32(16).int64(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      writer.uint32(24).int64(message.totalFireTimeMs);
    }
    if (message.lifetimeSec !== 0) {
      writer.uint32(32).uint64(message.lifetimeSec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FixLaserMetricsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFixLaserMetricsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.laserDescriptor = LaserDescriptor.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalFireCount = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalFireTimeMs = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lifetimeSec = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FixLaserMetricsRequest {
    return {
      laserDescriptor: isSet(object.laserDescriptor) ? LaserDescriptor.fromJSON(object.laserDescriptor) : undefined,
      totalFireCount: isSet(object.totalFireCount) ? globalThis.Number(object.totalFireCount) : 0,
      totalFireTimeMs: isSet(object.totalFireTimeMs) ? globalThis.Number(object.totalFireTimeMs) : 0,
      lifetimeSec: isSet(object.lifetimeSec) ? globalThis.Number(object.lifetimeSec) : 0,
    };
  },

  toJSON(message: FixLaserMetricsRequest): unknown {
    const obj: any = {};
    if (message.laserDescriptor !== undefined) {
      obj.laserDescriptor = LaserDescriptor.toJSON(message.laserDescriptor);
    }
    if (message.totalFireCount !== 0) {
      obj.totalFireCount = Math.round(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      obj.totalFireTimeMs = Math.round(message.totalFireTimeMs);
    }
    if (message.lifetimeSec !== 0) {
      obj.lifetimeSec = Math.round(message.lifetimeSec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FixLaserMetricsRequest>, I>>(base?: I): FixLaserMetricsRequest {
    return FixLaserMetricsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FixLaserMetricsRequest>, I>>(object: I): FixLaserMetricsRequest {
    const message = createBaseFixLaserMetricsRequest();
    message.laserDescriptor = (object.laserDescriptor !== undefined && object.laserDescriptor !== null)
      ? LaserDescriptor.fromPartial(object.laserDescriptor)
      : undefined;
    message.totalFireCount = object.totalFireCount ?? 0;
    message.totalFireTimeMs = object.totalFireTimeMs ?? 0;
    message.lifetimeSec = object.lifetimeSec ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
