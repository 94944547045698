import { AlmanacTypeCategory, Formula } from "protos/almanac/almanac";
import {
  BG_BACKDROP,
  BLUE_BUTTON,
  classes,
  RED_BUTTON,
} from "portal/utils/theme";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { CategoryThresholds } from "./CategoryThresholds";
import { ConfigCrop } from "protos/portal/configs";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { FormulaCard } from "./FormulaCard";
import {
  getFormulaBySize,
  isCategorySynced,
  SIZE_INDEX,
} from "portal/utils/almanac";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, useState } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

interface Props {
  adminEditing?: boolean;
  category: AlmanacTypeCategory;
  copiedFormula: Formula | undefined;
  copiedSizes: number[] | undefined;
  onChange: (newCategory: AlmanacTypeCategory) => void;
  onClose: () => void;
  onDelete?: () => void;
  setCopiedFormula: (formula: Formula | undefined) => void;
  setCopiedSizes: (sizes: number[] | undefined) => void;
  title: string;
  crops: ConfigCrop[];
  readOnly?: boolean;
}

export const AdvancedCategory: FunctionComponent<Props> = ({
  category: input,
  copiedFormula,
  copiedSizes,
  onChange,
  onClose,
  onDelete,
  setCopiedFormula,
  setCopiedSizes,
  title,
  crops,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const crop = crops.find((crop) => crop.id === input.type?.category);
  // local changes
  const [updatedCategory, setUpdatedCategory] = useState<
    AlmanacTypeCategory | undefined
  >();

  // sync state
  const [areSizesSynced, setSizesSynced] = useState<boolean>(
    isCategorySynced(input)
  );

  // confirmation dialogs
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmExit, setConfirmExit] = useState<boolean>(false);

  // visible state
  const category = updatedCategory ?? input;

  return (
    <div
      className={classes(
        "absolute left-0 top-0 w-full p-8 flex flex-col min-h-full",
        BG_BACKDROP
      )}
    >
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        {/* Breadcrumb */}
        <Button
          onClick={updatedCategory ? () => setConfirmExit(true) : onClose}
          className="text-white"
          startIcon={<ParentIcon />}
        >
          {t("utils.actions.backLong", {
            subject: capitalize(t("models.almanacs.almanac_one")),
          })}
        </Button>
        {confirmExit && (
          <ConfirmationDialog
            title={t("components.almanac.discard.title")}
            description={t("components.almanac.discard.description", {
              title,
            })}
            destructive
            yesText={t("utils.actions.discard")}
            onClose={() => setConfirmExit(false)}
            onYes={() => onClose()}
          />
        )}

        {/* Title */}
        <Typography
          variant="h4"
          className={classes("text-xl", {
            "italic text-orange-200": crop && !crop.isEnabled,
          })}
        >
          {title}{" "}
          {crop && !crop.isEnabled
            ? ` (${t("utils.descriptors.disabled")})`
            : ""}
        </Typography>
        {/* Controls */}

        <div className="flex items-center gap-4">
          {onDelete && !readOnly && (
            <Button
              {...RED_BUTTON}
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              {t("utils.actions.delete")}
            </Button>
          )}
          {confirmDelete && (
            <ConfirmationDialog
              title={t("utils.actions.deleteLong", {
                subject: t("models.almanacs.almanac_one"),
              })}
              description={t("components.almanac.delete.description")}
              destructive
              yesText={t("utils.actions.deleteLong", { subject: title })}
              onClose={() => setConfirmDelete(false)}
              onYes={() => onDelete?.()}
            />
          )}
          {!readOnly && (
            <>
              <Button
                variant="text"
                color="info"
                disabled={!updatedCategory}
                classes={{ disabled: "text-lighten-200" }}
                onClick={() => {
                  setUpdatedCategory(undefined);
                  onClose();
                }}
              >
                {t("utils.actions.cancel")}
              </Button>
              <Button
                {...BLUE_BUTTON}
                disabled={!updatedCategory}
                startIcon={<SaveIcon />}
                onClick={() => {
                  onChange(category);
                  setUpdatedCategory(undefined);
                  onClose();
                }}
              >
                {t("utils.actions.save")}
              </Button>
            </>
          )}
        </div>
      </div>

      <CategoryThresholds
        copiedSizes={copiedSizes}
        onChange={(sizes) => setUpdatedCategory({ ...category, sizes })}
        setCopiedSizes={setCopiedSizes}
        sizes={category.sizes}
        readOnly={readOnly}
      />

      {/* Formulas */}
      <div className="flex justify-between items-center mt-8">
        <Typography variant="h4" className="text-xl">
          {t("components.almanac.formulas.title")}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color="info"
              checked={areSizesSynced}
              disabled={readOnly}
              onChange={(event) => {
                const isSynced = event.currentTarget.checked;
                setSizesSynced(isSynced);
                if (isSynced) {
                  const firstTrust = category.formulas[SIZE_INDEX.SMALL];
                  if (!firstTrust) {
                    return;
                  }
                  const { formulas } = category;
                  for (const cardIndex of Object.values(SIZE_INDEX)) {
                    formulas[Number(cardIndex)] = firstTrust;
                  }

                  setUpdatedCategory({ ...category, formulas });
                }
              }}
            />
          }
          label={t("components.almanac.formulas.sync")}
        />
      </div>
      {areSizesSynced ? (
        <FormulaCard
          readOnly={readOnly}
          copiedFormula={copiedFormula}
          formula={getFormulaBySize(category, SIZE_INDEX.SMALL)}
          onChange={(newFormula) => {
            setUpdatedCategory({
              ...category,
              formulas: [newFormula, newFormula, newFormula],
            });
          }}
          setCopiedFormula={setCopiedFormula}
          title={t("components.almanac.formulas.all")}
        />
      ) : (
        [
          {
            sizeIndex: SIZE_INDEX.SMALL,
            title: t("utils.descriptors.small"),
          },
          {
            sizeIndex: SIZE_INDEX.MEDIUM,
            title: t("utils.descriptors.medium"),
          },
          {
            sizeIndex: SIZE_INDEX.LARGE,
            title: t("utils.descriptors.large"),
          },
        ].map(({ sizeIndex, title }) => (
          <FormulaCard
            key={title}
            readOnly={readOnly}
            copiedFormula={copiedFormula}
            formula={getFormulaBySize(category, sizeIndex)}
            onChange={(newFormula) => {
              const { formulas } = category;
              formulas[sizeIndex] = newFormula;
              setUpdatedCategory({ ...category, formulas });
            }}
            setCopiedFormula={setCopiedFormula}
            title={title}
          />
        ))
      )}
    </div>
  );
};
