import { DateTime } from "luxon";
import { Image } from "protos/portal/veselka";
import { isUndefined } from "./identity";
import { Point } from "./geo";

export const getImageLocation = (image: Image): [string, Point | undefined] => {
  const { geohash } = image;
  let point: Point | undefined;

  if (image.geoJson) {
    const geo = JSON.parse(image.geoJson);
    const latitude = geo?.lla?.lat;
    const longitude = geo?.lla?.lng;
    point = [latitude, longitude];
  }

  return [geohash, point];
};

export const getImageDate = (image: Image): DateTime | undefined => {
  if (image.capturedAt) {
    return DateTime.fromMillis(image.capturedAt);
  }
  if (image.geoJson) {
    const geo = JSON.parse(image.geoJson);
    const timestamp = geo?.lla?.timestamp_ms || geo?.ecef?.timestamp_ms;
    if (timestamp) {
      return DateTime.fromMillis(timestamp);
    }
  }
  const match = image.url.match(
    /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d)-(\d\d)-(\d\d)/
  );
  if (match) {
    const [, year, month, day, hour, minute, second] = match;
    if (
      isUndefined(year) ||
      isUndefined(month) ||
      isUndefined(day) ||
      isUndefined(hour) ||
      isUndefined(minute) ||
      isUndefined(second)
    ) {
      return undefined;
    }
    return DateTime.fromObject({
      year: Number.parseInt(year),
      month: Number.parseInt(month),
      day: Number.parseInt(day),
      hour: Number.parseInt(hour),
      minute: Number.parseInt(minute),
      second: Number.parseInt(second),
    });
  }

  return undefined;
};

export const splitS3Url = (s3Url: string): { bucket: string; key: string } => {
  // s3://bucket/arbitrary/key
  const match = s3Url.match(/^s3:\/\/([^/]+)\/(.+)$/);
  const error = new Error("Invalid S3 URL");
  if (!match) {
    throw error;
  }
  const [, bucket, key] = match;
  if (!bucket || !key) {
    throw error;
  }
  return { bucket, key };
};
