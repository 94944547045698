import { AccountDescription } from "portal/components/users/AccountDescription";
import { buildPermission, isExperimental } from "portal/utils/auth";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { CarbonCard } from "portal/components/CarbonCard";
import { classes, INPUT_DARK, LABEL, SELECT_DARK } from "portal/utils/theme";
import {
  DEFAULT_LANG,
  DISPLAY_LANGUAGES,
  isPortalLanguage,
  LANGUAGES,
  portalLanguageOrDefault,
} from "portal/i18nConstants";
import { DeleteAccount } from "portal/components/users/DeleteAccount";
import { Header } from "portal/components/header/Header";
import { isUndefined } from "portal/utils/identity";
import { Loading } from "portal/components/Loading";
import { MeasurementSystem } from "portal/utils/units/units";
import { Page } from "portal/components/Page";
import { Path } from "portal/utils/routing";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { setLocale } from "portal/utils/i18n";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  useAuthorizationRequired,
  WithAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { useUpdateUserMutation } from "portal/state/portalApi";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent, useEffect, useState } from "react";

const _Settings: FunctionComponent = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user, measurementSystem, isLoading } = useSelf();

  const [updateUser] = useMutationPopups(useUpdateUserMutation(), {
    success: capitalize(
      t("utils.actions.savedLong", {
        subject: t("models.users.user_one"),
      })
    ),
  });

  const [unit, setUnit] = useState<MeasurementSystem>(
    MeasurementSystem.imperial
  );

  useEffect(() => {
    setUnit(measurementSystem);
  }, [measurementSystem]);

  const isLabsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.portal_labs,
      PermissionDomain.self
    ),
  ]);

  if (!user) {
    return <Loading failed={!isLoading} />;
  }

  return (
    <>
      <Header title={t("views.settings.title")}>
        <div className="w-full flex items-center justify-end">
          <Button
            variant="text"
            classes={{ text: "text-white" }}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            {t("views.settings.logOut")}
          </Button>
        </div>
      </Header>
      <Page>
        <Grid container className="w-full items-stretch" spacing={2}>
          <CarbonCard
            title={t("views.settings.cards.localization")}
            error={false}
            success={true}
            content={
              <>
                <FormControl>
                  <InputLabel {...LABEL}>
                    {t("views.settings.fields.language")}
                  </InputLabel>
                  <Select<string>
                    {...SELECT_DARK}
                    classes={{
                      ...SELECT_DARK.classes,
                      root: classes(SELECT_DARK.classes?.root, "w-full"),
                    }}
                    input={
                      <OutlinedInput
                        {...INPUT_DARK}
                        label={t("views.settings.fields.language")}
                      />
                    }
                    defaultValue={DEFAULT_LANG}
                    placeholder={t("views.settings.fields.language")}
                    value={i18n.language}
                    onChange={(event) => {
                      const locale = portalLanguageOrDefault(
                        event.target.value
                      );
                      setLocale(i18n, locale);
                      if (user.userId) {
                        updateUser({
                          userId: user.userId,
                          user: { userMetadata: { language: locale } },
                        });
                      }
                    }}
                    renderValue={(value) =>
                      capitalize(
                        isPortalLanguage(value)
                          ? new Intl.DisplayNames([value], {
                              type: "language",
                            }).of(DISPLAY_LANGUAGES[value])
                          : ""
                      )
                    }
                  >
                    {LANGUAGES.map((language) => (
                      <MenuItem value={language} key={language}>
                        {capitalize(
                          new Intl.DisplayNames([language], {
                            type: "language",
                          }).of(DISPLAY_LANGUAGES[language])
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("views.settings.fields.measurement.name")}
                  </FormLabel>
                  <RadioGroup
                    row
                    name="measurement-system"
                    value={unit}
                    onChange={(event) => {
                      const value = event.target.value as MeasurementSystem;
                      setUnit(value);
                      if (!user.email || !user.userId) {
                        return;
                      }
                      updateUser({
                        userId: user.userId,
                        user: { userMetadata: { unit: value } },
                      });
                    }}
                  >
                    {[MeasurementSystem.imperial, MeasurementSystem.metric].map(
                      (unitType) => (
                        <FormControlLabel
                          key={unitType}
                          value={unitType}
                          control={<Radio />}
                          // carbon.actions.compareKeys.ignoreDynamic
                          label={t(
                            `views.settings.fields.measurement.values.${unitType}`
                          )}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </>
            }
          />
          <WithAuthorizationRequired
            permissionGroups={[
              buildPermission(
                PermissionAction.read,
                PermissionResource.portal_labs,
                PermissionDomain.self
              ),
            ]}
          >
            <CarbonCard
              title={t("views.settings.cards.advanced")}
              error={false}
              success={true}
              content={
                <FormControl>
                  <FormControlLabel
                    className="flex-shrink-0"
                    control={
                      <Switch
                        checked={isExperimental(user)}
                        color="warning"
                        disabled={!isLabsAuthorized}
                        onChange={(event, checked) => {
                          if (!user.email || !user.userId) {
                            return;
                          }
                          updateUser({
                            userId: user.userId,
                            user: {
                              userMetadata: { experimental: checked },
                            },
                          });
                        }}
                      />
                    }
                    label={t("views.settings.fields.experimental")}
                  />
                </FormControl>
              }
            />
          </WithAuthorizationRequired>
          <CarbonCard
            title={t("views.settings.cards.account")}
            error={false}
            success={!isUndefined(user)}
            content={<AccountDescription user={user} />}
            actions={
              <DeleteAccount
                user={user}
                onDelete={() => {
                  navigate(Path.LOGOUT);
                }}
              />
            }
          />
          <Grid xs={12} lg={6}>
            <span className="block font-mono text-xs text-right text-lighten-500">
              {t("views.settings.version", {
                version: window._jsenv.REACT_APP_RELEASE,
                hash: window._jsenv.REACT_APP_COMMITHASH.slice(0, 8),
              })}
            </span>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export const Settings = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.portal_settings,
        PermissionDomain.self
      ),
    ],
    _Settings
  )
);
