// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: veselka/label_point.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Pagination } from "./pagination";

export const protobufPackage = "carbon.veselka.label_point";

export interface Image {
  id: string;
  /** s3 url */
  url: string;
  /** Pixels per centimeter of the image */
  ppcm: number;
}

export interface LabelPoint {
  id: string;
  chipId?: string | undefined;
  x: number;
  y: number;
  radius: number;
  image: Image | undefined;
}

export interface ListLabelPointsResponse {
  data: LabelPoint[];
  pagination: Pagination | undefined;
}

function createBaseImage(): Image {
  return { id: "", url: "", ppcm: 0 };
}

export const Image: MessageFns<Image> = {
  encode(message: Image, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    if (message.ppcm !== 0) {
      writer.uint32(37).float(message.ppcm);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Image {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.ppcm = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Image {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      ppcm: isSet(object.ppcm) ? globalThis.Number(object.ppcm) : 0,
    };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.ppcm !== 0) {
      obj.ppcm = message.ppcm;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.id = object.id ?? "";
    message.url = object.url ?? "";
    message.ppcm = object.ppcm ?? 0;
    return message;
  },
};

function createBaseLabelPoint(): LabelPoint {
  return { id: "", chipId: undefined, x: 0, y: 0, radius: 0, image: undefined };
}

export const LabelPoint: MessageFns<LabelPoint> = {
  encode(message: LabelPoint, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.chipId !== undefined) {
      writer.uint32(18).string(message.chipId);
    }
    if (message.x !== 0) {
      writer.uint32(29).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(37).float(message.y);
    }
    if (message.radius !== 0) {
      writer.uint32(45).float(message.radius);
    }
    if (message.image !== undefined) {
      Image.encode(message.image, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LabelPoint {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabelPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.chipId = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.x = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.y = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.radius = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.image = Image.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LabelPoint {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      chipId: isSet(object.chipId) ? globalThis.String(object.chipId) : undefined,
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      radius: isSet(object.radius) ? globalThis.Number(object.radius) : 0,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
    };
  },

  toJSON(message: LabelPoint): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.chipId !== undefined) {
      obj.chipId = message.chipId;
    }
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.radius !== 0) {
      obj.radius = message.radius;
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LabelPoint>, I>>(base?: I): LabelPoint {
    return LabelPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LabelPoint>, I>>(object: I): LabelPoint {
    const message = createBaseLabelPoint();
    message.id = object.id ?? "";
    message.chipId = object.chipId ?? undefined;
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.radius = object.radius ?? 0;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    return message;
  },
};

function createBaseListLabelPointsResponse(): ListLabelPointsResponse {
  return { data: [], pagination: undefined };
}

export const ListLabelPointsResponse: MessageFns<ListLabelPointsResponse> = {
  encode(message: ListLabelPointsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.data) {
      LabelPoint.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListLabelPointsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListLabelPointsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(LabelPoint.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListLabelPointsResponse {
    return {
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => LabelPoint.fromJSON(e)) : [],
      pagination: isSet(object.pagination) ? Pagination.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: ListLabelPointsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => LabelPoint.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = Pagination.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListLabelPointsResponse>, I>>(base?: I): ListLabelPointsResponse {
    return ListLabelPointsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListLabelPointsResponse>, I>>(object: I): ListLabelPointsResponse {
    const message = createBaseListLabelPointsResponse();
    message.data = object.data?.map((e) => LabelPoint.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
