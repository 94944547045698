import {
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import { SELECT_DARK, theme } from "portal/utils/theme";
import React, { CSSProperties, FunctionComponent, useMemo } from "react";

export interface RadioChipOption {
  id: string;
  name: string;
  color?: {
    bg: string;
    text?: string;
  };
}
interface RadioChipsProps {
  isLoading: boolean;
  options: RadioChipOption[];
  activeId?: string;
  setActiveId: (id: string) => void;
  createLabel: (option: { id: string; name: string }) => string;
}
export const RadioChips: FunctionComponent<RadioChipsProps> = ({
  isLoading = false,
  options,
  activeId,
  setActiveId,
  createLabel,
}) => {
  const colorsStyle = (
    isActive: boolean,
    color?: RadioChipOption["color"]
  ): CSSProperties => ({
    borderColor: !isActive && color?.bg ? color.bg : theme.colors.white,
    color: isActive && color?.text ? color.text : theme.colors.white,
    backgroundColor: isActive
      ? color?.bg ?? theme.colors.carbon.orange
      : undefined,
  });

  const activeCategory = useMemo(
    () => options.find((o) => o.id === activeId),
    [activeId, options]
  );

  return (
    <>
      <div className="hidden md:flex">
        {isLoading ? (
          <div className="flex gap-2">
            {Array.from({ length: 5 })
              .fill(undefined)
              .map((_, index) => (
                <div key={index}>
                  <Skeleton
                    variant="rounded"
                    className="rounded-full h-6 w-28"
                  />
                </div>
              ))}
          </div>
        ) : (
          <div className="flex gap-4">
            {options.map(({ id, name, color }) => (
              <Chip
                key={id}
                className="font-normal"
                style={colorsStyle(activeId === id, color)}
                variant="outlined"
                label={createLabel({ id, name })}
                onClick={() => {
                  setActiveId(id);
                }}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex grow md:hidden">
        <Select<string>
          {...SELECT_DARK}
          className="w-full rounded-full border-solid border-1"
          style={colorsStyle(true, activeCategory?.color)}
          size="small"
          defaultValue={options[0] ? options[0].id : ""}
          value={activeId ?? ""}
          onChange={(event: SelectChangeEvent) => {
            setActiveId(event.target.value as string);
          }}
        >
          {options.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {createLabel({ id, name })}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
};
