import { Button } from "@mui/material";
import React, { FC } from "react";
import RemoveIcon from "@mui/icons-material/Close";

interface RemoveImageOverlayProps {
  dimensions: { width: number; height: number };
  onClick: () => void;
}
export const RemoveImageOverlay: FC<RemoveImageOverlayProps> = ({
  dimensions,
  onClick,
}) => {
  return (
    <Button
      component="div"
      className="absolute flex justify-end items-start top-0 right-0 p-0"
      style={dimensions}
      onClick={onClick}
    >
      <RemoveIcon className="text-white" />
    </Button>
  );
};
