// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: lib/common/camera/camera.proto

/* eslint-disable */

export const protobufPackage = "lib.common.camera";

export enum LightSourcePreset {
  kOff = 0,
  kDaylight5000K = 1,
  kDaylight6500K = 2,
  kTungsten2800K = 3,
  UNRECOGNIZED = -1,
}

export function lightSourcePresetFromJSON(object: any): LightSourcePreset {
  switch (object) {
    case 0:
    case "kOff":
      return LightSourcePreset.kOff;
    case 1:
    case "kDaylight5000K":
      return LightSourcePreset.kDaylight5000K;
    case 2:
    case "kDaylight6500K":
      return LightSourcePreset.kDaylight6500K;
    case 3:
    case "kTungsten2800K":
      return LightSourcePreset.kTungsten2800K;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LightSourcePreset.UNRECOGNIZED;
  }
}

export function lightSourcePresetToJSON(object: LightSourcePreset): string {
  switch (object) {
    case LightSourcePreset.kOff:
      return "kOff";
    case LightSourcePreset.kDaylight5000K:
      return "kDaylight5000K";
    case LightSourcePreset.kDaylight6500K:
      return "kDaylight6500K";
    case LightSourcePreset.kTungsten2800K:
      return "kTungsten2800K";
    case LightSourcePreset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
