// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: config/api/config_service.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.config.proto";

export enum ConfigType {
  NODE = 0,
  LIST = 1,
  STRING = 2,
  INT = 3,
  UINT = 4,
  FLOAT = 5,
  BOOL = 6,
  UNRECOGNIZED = -1,
}

export function configTypeFromJSON(object: any): ConfigType {
  switch (object) {
    case 0:
    case "NODE":
      return ConfigType.NODE;
    case 1:
    case "LIST":
      return ConfigType.LIST;
    case 2:
    case "STRING":
      return ConfigType.STRING;
    case 3:
    case "INT":
      return ConfigType.INT;
    case 4:
    case "UINT":
      return ConfigType.UINT;
    case 5:
    case "FLOAT":
      return ConfigType.FLOAT;
    case 6:
    case "BOOL":
      return ConfigType.BOOL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigType.UNRECOGNIZED;
  }
}

export function configTypeToJSON(object: ConfigType): string {
  switch (object) {
    case ConfigType.NODE:
      return "NODE";
    case ConfigType.LIST:
      return "LIST";
    case ConfigType.STRING:
      return "STRING";
    case ConfigType.INT:
      return "INT";
    case ConfigType.UINT:
      return "UINT";
    case ConfigType.FLOAT:
      return "FLOAT";
    case ConfigType.BOOL:
      return "BOOL";
    case ConfigType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ConfigComplexity {
  USER = 0,
  ADVANCED = 1,
  EXPERT = 2,
  DEVELOPER = 3,
  UNRECOGNIZED = -1,
}

export function configComplexityFromJSON(object: any): ConfigComplexity {
  switch (object) {
    case 0:
    case "USER":
      return ConfigComplexity.USER;
    case 1:
    case "ADVANCED":
      return ConfigComplexity.ADVANCED;
    case 2:
    case "EXPERT":
      return ConfigComplexity.EXPERT;
    case 3:
    case "DEVELOPER":
      return ConfigComplexity.DEVELOPER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigComplexity.UNRECOGNIZED;
  }
}

export function configComplexityToJSON(object: ConfigComplexity): string {
  switch (object) {
    case ConfigComplexity.USER:
      return "USER";
    case ConfigComplexity.ADVANCED:
      return "ADVANCED";
    case ConfigComplexity.EXPERT:
      return "EXPERT";
    case ConfigComplexity.DEVELOPER:
      return "DEVELOPER";
    case ConfigComplexity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PingRequest {
  x: number;
}

export interface PongResponse {
  x: number;
}

export interface ConfigValue {
  int64Val?: number | undefined;
  uint64Val?: number | undefined;
  boolVal?: boolean | undefined;
  floatVal?: number | undefined;
  stringVal?: string | undefined;
  timestampMs: number;
}

export interface IntConfigDef {
  min: number;
  max: number;
  step: number;
}

export interface UIntConfigDef {
  min: number;
  max: number;
  step: number;
}

export interface FloatConfigDef {
  min: number;
  max: number;
  step: number;
}

export interface StringConfigDef {
  sizeLimit: number;
  choices: string[];
}

export interface ConfigDef {
  type: ConfigType;
  complexity: ConfigComplexity;
  intDef?: IntConfigDef | undefined;
  uintDef?: UIntConfigDef | undefined;
  floatDef?: FloatConfigDef | undefined;
  stringDef?: StringConfigDef | undefined;
  hint: string;
  defaultRecommended: boolean;
  units: string;
}

export interface ConfigNode {
  name: string;
  value: ConfigValue | undefined;
  def: ConfigDef | undefined;
  children: ConfigNode[];
}

export interface ConfigLeaf {
  key: string;
  value: ConfigValue | undefined;
}

export interface SetValueRequest {
  key: string;
  value: ConfigValue | undefined;
}

export interface SetValueResponse {
}

export interface GetTreeRequest {
  key: string;
}

export interface GetTreeResponse {
  node: ConfigNode | undefined;
}

export interface SetTreeRequest {
  key: string;
  node: ConfigNode | undefined;
}

export interface SetTreeResponse {
}

export interface GetLeavesRequest {
  key: string;
}

export interface GetLeavesResponse {
  leaves: ConfigLeaf[];
}

export interface AddToListRequest {
  key: string;
  name: string;
}

export interface AddToListResponse {
}

export interface RemoveFromListRequest {
  key: string;
  name: string;
}

export interface RemoveFromListResponse {
}

export interface SubscriptionRequest {
  keys: string[];
}

export interface SubscriptionNotifyMessage {
  subscriptionKey: string;
  notifyKey: string;
}

export interface UpgradeCloudConfigRequest {
  robot: string;
  toTemplate: string;
  fromTemplate: string;
}

export interface UpgradeCloudConfigResponse {
}

function createBasePingRequest(): PingRequest {
  return { x: 0 };
}

export const PingRequest: MessageFns<PingRequest> = {
  encode(message: PingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PingRequest {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PingRequest): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PingRequest>, I>>(base?: I): PingRequest {
    return PingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PingRequest>, I>>(object: I): PingRequest {
    const message = createBasePingRequest();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBasePongResponse(): PongResponse {
  return { x: 0 };
}

export const PongResponse: MessageFns<PongResponse> = {
  encode(message: PongResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PongResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePongResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PongResponse {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PongResponse): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PongResponse>, I>>(base?: I): PongResponse {
    return PongResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PongResponse>, I>>(object: I): PongResponse {
    const message = createBasePongResponse();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBaseConfigValue(): ConfigValue {
  return {
    int64Val: undefined,
    uint64Val: undefined,
    boolVal: undefined,
    floatVal: undefined,
    stringVal: undefined,
    timestampMs: 0,
  };
}

export const ConfigValue: MessageFns<ConfigValue> = {
  encode(message: ConfigValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.int64Val !== undefined) {
      writer.uint32(8).int64(message.int64Val);
    }
    if (message.uint64Val !== undefined) {
      writer.uint32(16).uint64(message.uint64Val);
    }
    if (message.boolVal !== undefined) {
      writer.uint32(24).bool(message.boolVal);
    }
    if (message.floatVal !== undefined) {
      writer.uint32(33).double(message.floatVal);
    }
    if (message.stringVal !== undefined) {
      writer.uint32(42).string(message.stringVal);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(48).uint64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.int64Val = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.uint64Val = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.boolVal = reader.bool();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.floatVal = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.stringVal = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.timestampMs = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigValue {
    return {
      int64Val: isSet(object.int64Val) ? globalThis.Number(object.int64Val) : undefined,
      uint64Val: isSet(object.uint64Val) ? globalThis.Number(object.uint64Val) : undefined,
      boolVal: isSet(object.boolVal) ? globalThis.Boolean(object.boolVal) : undefined,
      floatVal: isSet(object.floatVal) ? globalThis.Number(object.floatVal) : undefined,
      stringVal: isSet(object.stringVal) ? globalThis.String(object.stringVal) : undefined,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
    };
  },

  toJSON(message: ConfigValue): unknown {
    const obj: any = {};
    if (message.int64Val !== undefined) {
      obj.int64Val = Math.round(message.int64Val);
    }
    if (message.uint64Val !== undefined) {
      obj.uint64Val = Math.round(message.uint64Val);
    }
    if (message.boolVal !== undefined) {
      obj.boolVal = message.boolVal;
    }
    if (message.floatVal !== undefined) {
      obj.floatVal = message.floatVal;
    }
    if (message.stringVal !== undefined) {
      obj.stringVal = message.stringVal;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigValue>, I>>(base?: I): ConfigValue {
    return ConfigValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigValue>, I>>(object: I): ConfigValue {
    const message = createBaseConfigValue();
    message.int64Val = object.int64Val ?? undefined;
    message.uint64Val = object.uint64Val ?? undefined;
    message.boolVal = object.boolVal ?? undefined;
    message.floatVal = object.floatVal ?? undefined;
    message.stringVal = object.stringVal ?? undefined;
    message.timestampMs = object.timestampMs ?? 0;
    return message;
  },
};

function createBaseIntConfigDef(): IntConfigDef {
  return { min: 0, max: 0, step: 0 };
}

export const IntConfigDef: MessageFns<IntConfigDef> = {
  encode(message: IntConfigDef, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.min !== 0) {
      writer.uint32(8).int64(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(16).int64(message.max);
    }
    if (message.step !== 0) {
      writer.uint32(24).int64(message.step);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IntConfigDef {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntConfigDef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.min = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.max = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.step = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntConfigDef {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
      step: isSet(object.step) ? globalThis.Number(object.step) : 0,
    };
  },

  toJSON(message: IntConfigDef): unknown {
    const obj: any = {};
    if (message.min !== 0) {
      obj.min = Math.round(message.min);
    }
    if (message.max !== 0) {
      obj.max = Math.round(message.max);
    }
    if (message.step !== 0) {
      obj.step = Math.round(message.step);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IntConfigDef>, I>>(base?: I): IntConfigDef {
    return IntConfigDef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IntConfigDef>, I>>(object: I): IntConfigDef {
    const message = createBaseIntConfigDef();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    message.step = object.step ?? 0;
    return message;
  },
};

function createBaseUIntConfigDef(): UIntConfigDef {
  return { min: 0, max: 0, step: 0 };
}

export const UIntConfigDef: MessageFns<UIntConfigDef> = {
  encode(message: UIntConfigDef, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.min !== 0) {
      writer.uint32(8).uint64(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(16).uint64(message.max);
    }
    if (message.step !== 0) {
      writer.uint32(24).uint64(message.step);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UIntConfigDef {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUIntConfigDef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.min = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.max = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.step = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UIntConfigDef {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
      step: isSet(object.step) ? globalThis.Number(object.step) : 0,
    };
  },

  toJSON(message: UIntConfigDef): unknown {
    const obj: any = {};
    if (message.min !== 0) {
      obj.min = Math.round(message.min);
    }
    if (message.max !== 0) {
      obj.max = Math.round(message.max);
    }
    if (message.step !== 0) {
      obj.step = Math.round(message.step);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UIntConfigDef>, I>>(base?: I): UIntConfigDef {
    return UIntConfigDef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UIntConfigDef>, I>>(object: I): UIntConfigDef {
    const message = createBaseUIntConfigDef();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    message.step = object.step ?? 0;
    return message;
  },
};

function createBaseFloatConfigDef(): FloatConfigDef {
  return { min: 0, max: 0, step: 0 };
}

export const FloatConfigDef: MessageFns<FloatConfigDef> = {
  encode(message: FloatConfigDef, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.min !== 0) {
      writer.uint32(9).double(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(17).double(message.max);
    }
    if (message.step !== 0) {
      writer.uint32(25).double(message.step);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FloatConfigDef {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFloatConfigDef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.min = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.max = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.step = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FloatConfigDef {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
      step: isSet(object.step) ? globalThis.Number(object.step) : 0,
    };
  },

  toJSON(message: FloatConfigDef): unknown {
    const obj: any = {};
    if (message.min !== 0) {
      obj.min = message.min;
    }
    if (message.max !== 0) {
      obj.max = message.max;
    }
    if (message.step !== 0) {
      obj.step = message.step;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FloatConfigDef>, I>>(base?: I): FloatConfigDef {
    return FloatConfigDef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FloatConfigDef>, I>>(object: I): FloatConfigDef {
    const message = createBaseFloatConfigDef();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    message.step = object.step ?? 0;
    return message;
  },
};

function createBaseStringConfigDef(): StringConfigDef {
  return { sizeLimit: 0, choices: [] };
}

export const StringConfigDef: MessageFns<StringConfigDef> = {
  encode(message: StringConfigDef, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.sizeLimit !== 0) {
      writer.uint32(8).uint32(message.sizeLimit);
    }
    for (const v of message.choices) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StringConfigDef {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringConfigDef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sizeLimit = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.choices.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringConfigDef {
    return {
      sizeLimit: isSet(object.sizeLimit) ? globalThis.Number(object.sizeLimit) : 0,
      choices: globalThis.Array.isArray(object?.choices) ? object.choices.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: StringConfigDef): unknown {
    const obj: any = {};
    if (message.sizeLimit !== 0) {
      obj.sizeLimit = Math.round(message.sizeLimit);
    }
    if (message.choices?.length) {
      obj.choices = message.choices;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringConfigDef>, I>>(base?: I): StringConfigDef {
    return StringConfigDef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StringConfigDef>, I>>(object: I): StringConfigDef {
    const message = createBaseStringConfigDef();
    message.sizeLimit = object.sizeLimit ?? 0;
    message.choices = object.choices?.map((e) => e) || [];
    return message;
  },
};

function createBaseConfigDef(): ConfigDef {
  return {
    type: 0,
    complexity: 0,
    intDef: undefined,
    uintDef: undefined,
    floatDef: undefined,
    stringDef: undefined,
    hint: "",
    defaultRecommended: false,
    units: "",
  };
}

export const ConfigDef: MessageFns<ConfigDef> = {
  encode(message: ConfigDef, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.complexity !== 0) {
      writer.uint32(16).int32(message.complexity);
    }
    if (message.intDef !== undefined) {
      IntConfigDef.encode(message.intDef, writer.uint32(26).fork()).join();
    }
    if (message.uintDef !== undefined) {
      UIntConfigDef.encode(message.uintDef, writer.uint32(34).fork()).join();
    }
    if (message.floatDef !== undefined) {
      FloatConfigDef.encode(message.floatDef, writer.uint32(42).fork()).join();
    }
    if (message.stringDef !== undefined) {
      StringConfigDef.encode(message.stringDef, writer.uint32(50).fork()).join();
    }
    if (message.hint !== "") {
      writer.uint32(58).string(message.hint);
    }
    if (message.defaultRecommended !== false) {
      writer.uint32(64).bool(message.defaultRecommended);
    }
    if (message.units !== "") {
      writer.uint32(74).string(message.units);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigDef {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigDef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.complexity = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.intDef = IntConfigDef.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.uintDef = UIntConfigDef.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.floatDef = FloatConfigDef.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.stringDef = StringConfigDef.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.hint = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.defaultRecommended = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.units = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigDef {
    return {
      type: isSet(object.type) ? configTypeFromJSON(object.type) : 0,
      complexity: isSet(object.complexity) ? configComplexityFromJSON(object.complexity) : 0,
      intDef: isSet(object.intDef) ? IntConfigDef.fromJSON(object.intDef) : undefined,
      uintDef: isSet(object.uintDef) ? UIntConfigDef.fromJSON(object.uintDef) : undefined,
      floatDef: isSet(object.floatDef) ? FloatConfigDef.fromJSON(object.floatDef) : undefined,
      stringDef: isSet(object.stringDef) ? StringConfigDef.fromJSON(object.stringDef) : undefined,
      hint: isSet(object.hint) ? globalThis.String(object.hint) : "",
      defaultRecommended: isSet(object.defaultRecommended) ? globalThis.Boolean(object.defaultRecommended) : false,
      units: isSet(object.units) ? globalThis.String(object.units) : "",
    };
  },

  toJSON(message: ConfigDef): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = configTypeToJSON(message.type);
    }
    if (message.complexity !== 0) {
      obj.complexity = configComplexityToJSON(message.complexity);
    }
    if (message.intDef !== undefined) {
      obj.intDef = IntConfigDef.toJSON(message.intDef);
    }
    if (message.uintDef !== undefined) {
      obj.uintDef = UIntConfigDef.toJSON(message.uintDef);
    }
    if (message.floatDef !== undefined) {
      obj.floatDef = FloatConfigDef.toJSON(message.floatDef);
    }
    if (message.stringDef !== undefined) {
      obj.stringDef = StringConfigDef.toJSON(message.stringDef);
    }
    if (message.hint !== "") {
      obj.hint = message.hint;
    }
    if (message.defaultRecommended !== false) {
      obj.defaultRecommended = message.defaultRecommended;
    }
    if (message.units !== "") {
      obj.units = message.units;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigDef>, I>>(base?: I): ConfigDef {
    return ConfigDef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigDef>, I>>(object: I): ConfigDef {
    const message = createBaseConfigDef();
    message.type = object.type ?? 0;
    message.complexity = object.complexity ?? 0;
    message.intDef = (object.intDef !== undefined && object.intDef !== null)
      ? IntConfigDef.fromPartial(object.intDef)
      : undefined;
    message.uintDef = (object.uintDef !== undefined && object.uintDef !== null)
      ? UIntConfigDef.fromPartial(object.uintDef)
      : undefined;
    message.floatDef = (object.floatDef !== undefined && object.floatDef !== null)
      ? FloatConfigDef.fromPartial(object.floatDef)
      : undefined;
    message.stringDef = (object.stringDef !== undefined && object.stringDef !== null)
      ? StringConfigDef.fromPartial(object.stringDef)
      : undefined;
    message.hint = object.hint ?? "";
    message.defaultRecommended = object.defaultRecommended ?? false;
    message.units = object.units ?? "";
    return message;
  },
};

function createBaseConfigNode(): ConfigNode {
  return { name: "", value: undefined, def: undefined, children: [] };
}

export const ConfigNode: MessageFns<ConfigNode> = {
  encode(message: ConfigNode, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      ConfigValue.encode(message.value, writer.uint32(18).fork()).join();
    }
    if (message.def !== undefined) {
      ConfigDef.encode(message.def, writer.uint32(26).fork()).join();
    }
    for (const v of message.children) {
      ConfigNode.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigNode {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigNode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ConfigValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.def = ConfigDef.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.children.push(ConfigNode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigNode {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? ConfigValue.fromJSON(object.value) : undefined,
      def: isSet(object.def) ? ConfigDef.fromJSON(object.def) : undefined,
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => ConfigNode.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ConfigNode): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== undefined) {
      obj.value = ConfigValue.toJSON(message.value);
    }
    if (message.def !== undefined) {
      obj.def = ConfigDef.toJSON(message.def);
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => ConfigNode.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigNode>, I>>(base?: I): ConfigNode {
    return ConfigNode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigNode>, I>>(object: I): ConfigNode {
    const message = createBaseConfigNode();
    message.name = object.name ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ConfigValue.fromPartial(object.value)
      : undefined;
    message.def = (object.def !== undefined && object.def !== null) ? ConfigDef.fromPartial(object.def) : undefined;
    message.children = object.children?.map((e) => ConfigNode.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConfigLeaf(): ConfigLeaf {
  return { key: "", value: undefined };
}

export const ConfigLeaf: MessageFns<ConfigLeaf> = {
  encode(message: ConfigLeaf, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ConfigValue.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigLeaf {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigLeaf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ConfigValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigLeaf {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ConfigValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ConfigLeaf): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ConfigValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigLeaf>, I>>(base?: I): ConfigLeaf {
    return ConfigLeaf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigLeaf>, I>>(object: I): ConfigLeaf {
    const message = createBaseConfigLeaf();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ConfigValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSetValueRequest(): SetValueRequest {
  return { key: "", value: undefined };
}

export const SetValueRequest: MessageFns<SetValueRequest> = {
  encode(message: SetValueRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ConfigValue.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetValueRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetValueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ConfigValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetValueRequest {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ConfigValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SetValueRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ConfigValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetValueRequest>, I>>(base?: I): SetValueRequest {
    return SetValueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetValueRequest>, I>>(object: I): SetValueRequest {
    const message = createBaseSetValueRequest();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ConfigValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSetValueResponse(): SetValueResponse {
  return {};
}

export const SetValueResponse: MessageFns<SetValueResponse> = {
  encode(_: SetValueResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetValueResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetValueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetValueResponse {
    return {};
  },

  toJSON(_: SetValueResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetValueResponse>, I>>(base?: I): SetValueResponse {
    return SetValueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetValueResponse>, I>>(_: I): SetValueResponse {
    const message = createBaseSetValueResponse();
    return message;
  },
};

function createBaseGetTreeRequest(): GetTreeRequest {
  return { key: "" };
}

export const GetTreeRequest: MessageFns<GetTreeRequest> = {
  encode(message: GetTreeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTreeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTreeRequest {
    return { key: isSet(object.key) ? globalThis.String(object.key) : "" };
  },

  toJSON(message: GetTreeRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTreeRequest>, I>>(base?: I): GetTreeRequest {
    return GetTreeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTreeRequest>, I>>(object: I): GetTreeRequest {
    const message = createBaseGetTreeRequest();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseGetTreeResponse(): GetTreeResponse {
  return { node: undefined };
}

export const GetTreeResponse: MessageFns<GetTreeResponse> = {
  encode(message: GetTreeResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.node !== undefined) {
      ConfigNode.encode(message.node, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTreeResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTreeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.node = ConfigNode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTreeResponse {
    return { node: isSet(object.node) ? ConfigNode.fromJSON(object.node) : undefined };
  },

  toJSON(message: GetTreeResponse): unknown {
    const obj: any = {};
    if (message.node !== undefined) {
      obj.node = ConfigNode.toJSON(message.node);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTreeResponse>, I>>(base?: I): GetTreeResponse {
    return GetTreeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTreeResponse>, I>>(object: I): GetTreeResponse {
    const message = createBaseGetTreeResponse();
    message.node = (object.node !== undefined && object.node !== null)
      ? ConfigNode.fromPartial(object.node)
      : undefined;
    return message;
  },
};

function createBaseSetTreeRequest(): SetTreeRequest {
  return { key: "", node: undefined };
}

export const SetTreeRequest: MessageFns<SetTreeRequest> = {
  encode(message: SetTreeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.node !== undefined) {
      ConfigNode.encode(message.node, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetTreeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.node = ConfigNode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetTreeRequest {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      node: isSet(object.node) ? ConfigNode.fromJSON(object.node) : undefined,
    };
  },

  toJSON(message: SetTreeRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.node !== undefined) {
      obj.node = ConfigNode.toJSON(message.node);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetTreeRequest>, I>>(base?: I): SetTreeRequest {
    return SetTreeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetTreeRequest>, I>>(object: I): SetTreeRequest {
    const message = createBaseSetTreeRequest();
    message.key = object.key ?? "";
    message.node = (object.node !== undefined && object.node !== null)
      ? ConfigNode.fromPartial(object.node)
      : undefined;
    return message;
  },
};

function createBaseSetTreeResponse(): SetTreeResponse {
  return {};
}

export const SetTreeResponse: MessageFns<SetTreeResponse> = {
  encode(_: SetTreeResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetTreeResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTreeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetTreeResponse {
    return {};
  },

  toJSON(_: SetTreeResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetTreeResponse>, I>>(base?: I): SetTreeResponse {
    return SetTreeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetTreeResponse>, I>>(_: I): SetTreeResponse {
    const message = createBaseSetTreeResponse();
    return message;
  },
};

function createBaseGetLeavesRequest(): GetLeavesRequest {
  return { key: "" };
}

export const GetLeavesRequest: MessageFns<GetLeavesRequest> = {
  encode(message: GetLeavesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLeavesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLeavesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLeavesRequest {
    return { key: isSet(object.key) ? globalThis.String(object.key) : "" };
  },

  toJSON(message: GetLeavesRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLeavesRequest>, I>>(base?: I): GetLeavesRequest {
    return GetLeavesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLeavesRequest>, I>>(object: I): GetLeavesRequest {
    const message = createBaseGetLeavesRequest();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseGetLeavesResponse(): GetLeavesResponse {
  return { leaves: [] };
}

export const GetLeavesResponse: MessageFns<GetLeavesResponse> = {
  encode(message: GetLeavesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.leaves) {
      ConfigLeaf.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetLeavesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLeavesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.leaves.push(ConfigLeaf.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLeavesResponse {
    return {
      leaves: globalThis.Array.isArray(object?.leaves) ? object.leaves.map((e: any) => ConfigLeaf.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetLeavesResponse): unknown {
    const obj: any = {};
    if (message.leaves?.length) {
      obj.leaves = message.leaves.map((e) => ConfigLeaf.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLeavesResponse>, I>>(base?: I): GetLeavesResponse {
    return GetLeavesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLeavesResponse>, I>>(object: I): GetLeavesResponse {
    const message = createBaseGetLeavesResponse();
    message.leaves = object.leaves?.map((e) => ConfigLeaf.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddToListRequest(): AddToListRequest {
  return { key: "", name: "" };
}

export const AddToListRequest: MessageFns<AddToListRequest> = {
  encode(message: AddToListRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AddToListRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddToListRequest {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: AddToListRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddToListRequest>, I>>(base?: I): AddToListRequest {
    return AddToListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddToListRequest>, I>>(object: I): AddToListRequest {
    const message = createBaseAddToListRequest();
    message.key = object.key ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAddToListResponse(): AddToListResponse {
  return {};
}

export const AddToListResponse: MessageFns<AddToListResponse> = {
  encode(_: AddToListResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AddToListResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddToListResponse {
    return {};
  },

  toJSON(_: AddToListResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddToListResponse>, I>>(base?: I): AddToListResponse {
    return AddToListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddToListResponse>, I>>(_: I): AddToListResponse {
    const message = createBaseAddToListResponse();
    return message;
  },
};

function createBaseRemoveFromListRequest(): RemoveFromListRequest {
  return { key: "", name: "" };
}

export const RemoveFromListRequest: MessageFns<RemoveFromListRequest> = {
  encode(message: RemoveFromListRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RemoveFromListRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveFromListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveFromListRequest {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: RemoveFromListRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveFromListRequest>, I>>(base?: I): RemoveFromListRequest {
    return RemoveFromListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveFromListRequest>, I>>(object: I): RemoveFromListRequest {
    const message = createBaseRemoveFromListRequest();
    message.key = object.key ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRemoveFromListResponse(): RemoveFromListResponse {
  return {};
}

export const RemoveFromListResponse: MessageFns<RemoveFromListResponse> = {
  encode(_: RemoveFromListResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RemoveFromListResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveFromListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveFromListResponse {
    return {};
  },

  toJSON(_: RemoveFromListResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveFromListResponse>, I>>(base?: I): RemoveFromListResponse {
    return RemoveFromListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveFromListResponse>, I>>(_: I): RemoveFromListResponse {
    const message = createBaseRemoveFromListResponse();
    return message;
  },
};

function createBaseSubscriptionRequest(): SubscriptionRequest {
  return { keys: [] };
}

export const SubscriptionRequest: MessageFns<SubscriptionRequest> = {
  encode(message: SubscriptionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SubscriptionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionRequest {
    return { keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: SubscriptionRequest): unknown {
    const obj: any = {};
    if (message.keys?.length) {
      obj.keys = message.keys;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionRequest>, I>>(base?: I): SubscriptionRequest {
    return SubscriptionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionRequest>, I>>(object: I): SubscriptionRequest {
    const message = createBaseSubscriptionRequest();
    message.keys = object.keys?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubscriptionNotifyMessage(): SubscriptionNotifyMessage {
  return { subscriptionKey: "", notifyKey: "" };
}

export const SubscriptionNotifyMessage: MessageFns<SubscriptionNotifyMessage> = {
  encode(message: SubscriptionNotifyMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.subscriptionKey !== "") {
      writer.uint32(10).string(message.subscriptionKey);
    }
    if (message.notifyKey !== "") {
      writer.uint32(18).string(message.notifyKey);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SubscriptionNotifyMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionNotifyMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.subscriptionKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.notifyKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionNotifyMessage {
    return {
      subscriptionKey: isSet(object.subscriptionKey) ? globalThis.String(object.subscriptionKey) : "",
      notifyKey: isSet(object.notifyKey) ? globalThis.String(object.notifyKey) : "",
    };
  },

  toJSON(message: SubscriptionNotifyMessage): unknown {
    const obj: any = {};
    if (message.subscriptionKey !== "") {
      obj.subscriptionKey = message.subscriptionKey;
    }
    if (message.notifyKey !== "") {
      obj.notifyKey = message.notifyKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionNotifyMessage>, I>>(base?: I): SubscriptionNotifyMessage {
    return SubscriptionNotifyMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionNotifyMessage>, I>>(object: I): SubscriptionNotifyMessage {
    const message = createBaseSubscriptionNotifyMessage();
    message.subscriptionKey = object.subscriptionKey ?? "";
    message.notifyKey = object.notifyKey ?? "";
    return message;
  },
};

function createBaseUpgradeCloudConfigRequest(): UpgradeCloudConfigRequest {
  return { robot: "", toTemplate: "", fromTemplate: "" };
}

export const UpgradeCloudConfigRequest: MessageFns<UpgradeCloudConfigRequest> = {
  encode(message: UpgradeCloudConfigRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.robot !== "") {
      writer.uint32(10).string(message.robot);
    }
    if (message.toTemplate !== "") {
      writer.uint32(18).string(message.toTemplate);
    }
    if (message.fromTemplate !== "") {
      writer.uint32(26).string(message.fromTemplate);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpgradeCloudConfigRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpgradeCloudConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.robot = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toTemplate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromTemplate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpgradeCloudConfigRequest {
    return {
      robot: isSet(object.robot) ? globalThis.String(object.robot) : "",
      toTemplate: isSet(object.toTemplate) ? globalThis.String(object.toTemplate) : "",
      fromTemplate: isSet(object.fromTemplate) ? globalThis.String(object.fromTemplate) : "",
    };
  },

  toJSON(message: UpgradeCloudConfigRequest): unknown {
    const obj: any = {};
    if (message.robot !== "") {
      obj.robot = message.robot;
    }
    if (message.toTemplate !== "") {
      obj.toTemplate = message.toTemplate;
    }
    if (message.fromTemplate !== "") {
      obj.fromTemplate = message.fromTemplate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpgradeCloudConfigRequest>, I>>(base?: I): UpgradeCloudConfigRequest {
    return UpgradeCloudConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpgradeCloudConfigRequest>, I>>(object: I): UpgradeCloudConfigRequest {
    const message = createBaseUpgradeCloudConfigRequest();
    message.robot = object.robot ?? "";
    message.toTemplate = object.toTemplate ?? "";
    message.fromTemplate = object.fromTemplate ?? "";
    return message;
  },
};

function createBaseUpgradeCloudConfigResponse(): UpgradeCloudConfigResponse {
  return {};
}

export const UpgradeCloudConfigResponse: MessageFns<UpgradeCloudConfigResponse> = {
  encode(_: UpgradeCloudConfigResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpgradeCloudConfigResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpgradeCloudConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpgradeCloudConfigResponse {
    return {};
  },

  toJSON(_: UpgradeCloudConfigResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpgradeCloudConfigResponse>, I>>(base?: I): UpgradeCloudConfigResponse {
    return UpgradeCloudConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpgradeCloudConfigResponse>, I>>(_: I): UpgradeCloudConfigResponse {
    const message = createBaseUpgradeCloudConfigResponse();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
