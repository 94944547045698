import {
  BLUE_LOADING_BUTTON,
  classes,
  SMALL_SECRET_RAINBOW_BUTTON,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { boolean, object, string } from "yup";
import { buildPermission } from "portal/utils/auth";
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { ConfigCrop } from "protos/portal/configs";
import { encode } from "ngeohash";
import { Field, Form, Formik } from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { getConfigKey } from "portal/utils/crops";
import { getModelinatorId } from "portal/utils/almanac";
import { getModelinatorPath } from "portal/utils/routing";
import { isProduction } from "./EnvironmentFlag";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { ModelinatorConfig } from "protos/almanac/almanac";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { Link as RouterLink } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { SwitchWithLabel } from "portal/components/SwitchWithLabel";
import { toQuery } from "portal/utils/browser";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "./auth/WithAuthorizationRequired";
import {
  useGetCropQuery,
  useGetRobotQuery,
  useListModelinatorsForRobotAndCropQuery,
  useSetConfigValueMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { ReactComponent as VeselkaIcon } from "portal/images/icons/veselka.svg";
import { ViewPlaceholder } from "./ViewPlaceholder";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import PinnedIcon from "@mui/icons-material/PushPin";
import React, { FunctionComponent, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import RecommendedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import remarkGfm from "remark-gfm";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import UnpinnedIcon from "@mui/icons-material/PushPinOutlined";

interface Props {
  serial?: string;
  configCrop?: ConfigCrop | undefined;
  open?: boolean;
  onClose: () => void;
}

const _CropEditor: FunctionComponent<Props> = ({
  serial,
  configCrop,
  open = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const canReadAdvanced = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);
  const canUpdateAdvanced = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);
  const hasVeselka = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.veselka,
      PermissionDomain.all
    ),
  ]);

  const [setConfigValue] = useMutationPopups(useSetConfigValueMutation(), {
    success: capitalize(
      t("utils.actions.updatedLong", {
        subject: t("models.configs.config_one"),
      })
    ),
  });
  const { data: robotSummary, isLoading: isRobotLoading } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken)
  );

  const geo4 = useMemo<string>(() => {
    const geohashOptions = ["NONE"]; // always include non-geo models
    const { x, y } = robotSummary?.robot?.health?.location ?? {};
    if (!isUndefined(x) && !isUndefined(y)) {
      const geohash = encode(x, y, 4);
      geohashOptions.unshift(geohash);
    }
    return geohashOptions.join(",");
  }, [robotSummary?.robot?.health?.location]);

  const { data: veselkaCrop } = useQueryPopups(
    useGetCropQuery(configCrop ? { cropId: configCrop.id } : skipToken)
  );

  const {
    data: modelinators,
    isLoading,
    isSuccess,
  } = useQueryPopups(
    useListModelinatorsForRobotAndCropQuery(
      serial && configCrop ? { serial, cropId: configCrop.id } : skipToken
    )
  );

  if (!canReadAdvanced || !serial || !open) {
    return;
  }

  const hasNotes = veselkaCrop?.description || veselkaCrop?.notes;

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{
        paper: classes(
          "h-auto p-4 bottom-0 flex flex-col gap-4",
          "top-0 md:top-16",
          "w-screen md:w-80"
        ),
      }}
    >
      <Formik<Omit<ConfigCrop, "id" | "carbonName">>
        enableReinitialize
        initialValues={{
          commonName: configCrop?.commonName ?? "",
          isEnabled: configCrop?.isEnabled ?? false,
          pinned: configCrop?.pinned ?? "",
          recommended: configCrop?.recommended ?? "",
        }}
        validationSchema={object({
          commonName: string(),
          isEnabled: boolean().required(),
          pinned: string(),
          recommended: string(),
        })}
        onSubmit={async (updated) => {
          if (!configCrop) {
            return;
          }
          await Promise.all([
            updated.commonName === configCrop.commonName
              ? undefined
              : setConfigValue({
                  serial,
                  path: [
                    getConfigKey(serial, configCrop.id),
                    "common_name",
                  ].join("/"),
                  value: updated.commonName,
                }),
            updated.isEnabled === configCrop.isEnabled
              ? undefined
              : setConfigValue({
                  serial,
                  path: [getConfigKey(serial, configCrop.id), "enabled"].join(
                    "/"
                  ),
                  value: updated.isEnabled,
                }),
            updated.pinned === configCrop.pinned
              ? undefined
              : setConfigValue({
                  serial,
                  path: [
                    getConfigKey(serial, configCrop.id),
                    "pinned_model",
                  ].join("/"),
                  value: updated.pinned.trim(),
                }),
            updated.recommended === configCrop.recommended
              ? undefined
              : setConfigValue({
                  serial,
                  path: [
                    getConfigKey(serial, configCrop.id),
                    "recommended_model",
                  ].join("/"),
                  value: updated.recommended.trim(),
                }),
          ]);
          onClose();
        }}
      >
        {({ submitForm, isSubmitting, dirty }) => (
          <Form className="flex flex-col items-justify gap-4">
            <div className="flex justify-end gap-4">
              <Button variant="text" onClick={onClose} className="text-white">
                {t("utils.actions.cancel")}
              </Button>
              <LoadingButton
                {...BLUE_LOADING_BUTTON}
                disabled={!dirty}
                loading={isSubmitting}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                {t("utils.actions.update")}
              </LoadingButton>
            </div>
            <TextField
              disabled
              value={configCrop?.id ?? t("components.Loading.placeholder")}
              label="Crop ID"
              InputProps={{
                className: "font-mono text-xs",
              }}
            />
            <Field
              {...TEXT_FIELD_DARK}
              component={FormikTextField}
              name="commonName"
              label={titleCase(t("models.crops.crop_one"))}
              disabled={!canUpdateAdvanced}
            />
            {canUpdateAdvanced && (
              <Field
                component={SwitchWithLabel}
                type="checkbox"
                name="isEnabled"
                disabled={!configCrop?.isEnabled && veselkaCrop?.archived}
                label={t("utils.descriptors.enabled")}
              />
            )}
            {hasNotes && (
              <>
                <Divider />
                <Typography variant="h6">
                  {t("models.crops.fields.notes")}
                </Typography>
                <Typography variant="body1">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {veselkaCrop.description}
                  </ReactMarkdown>
                </Typography>
                <Typography variant="body1">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {veselkaCrop.notes}
                  </ReactMarkdown>
                </Typography>
              </>
            )}
            <Divider />
            <div className="flex justify-between">
              <Typography variant="h6">
                {capitalize(t("models.models.model_other"))}
              </Typography>
              {hasVeselka && (
                <LoadingButton
                  {...SMALL_SECRET_RAINBOW_BUTTON}
                  LinkComponent="a"
                  className={classes(
                    SMALL_SECRET_RAINBOW_BUTTON.className,
                    "flex gap-1"
                  )}
                  target="_blank"
                  href={`${
                    window._jsenv.REACT_APP_VESELKA_URL
                  }/react/models/models?${toQuery({
                    deploy: "True",
                    isTraining: "False",
                    deploymentCrops: configCrop?.id,
                    sort_by: "created",
                    sort_order: "desc",
                    environment: isProduction ? "production" : "development",
                    geohashPrefixes: geo4,
                  })}`}
                  startIcon={<VeselkaIcon />}
                  loading={isRobotLoading}
                >
                  <span className="group-hover:text-rainbow">
                    {t("components.CropEditor.viewIn")}
                  </span>
                </LoadingButton>
              )}
            </div>
            <Field
              {...TEXT_FIELD_DARK}
              component={FormikTextField}
              name="pinned"
              label={t("models.crops.fields.pinned")}
              InputProps={{
                className: "font-mono",
              }}
            />
            <Field
              {...TEXT_FIELD_DARK}
              component={FormikTextField}
              name="recommended"
              label={t("models.crops.fields.recommended")}
              InputProps={{
                className: "font-mono",
              }}
              disabled={!canUpdateAdvanced}
            />
            {isLoading && (
              <ViewPlaceholder text={t("components.Loading.placeholder")} />
            )}
            {isSuccess && modelinators.length === 0 && (
              <ViewPlaceholder text="No models for crop" />
            )}
            <ul className="list-none p-0 m-0 flex flex-col gap-4">
              {modelinators?.map((modelinator) => (
                <Modelinator
                  serial={serial}
                  key={getModelinatorId(serial, modelinator)}
                  modelinator={modelinator}
                  recommended={modelinator.modelId === configCrop?.recommended}
                  pinned={modelinator.modelId === configCrop?.pinned}
                />
              ))}
            </ul>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export const CropEditor = withErrorBoundary(
  { i18nKey: "components.CropEditor.failed" },
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.crops_basic,
        PermissionDomain.all
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.crops_basic,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.crops_basic,
        PermissionDomain.all
      ),
    ],
    _CropEditor
  )
);

interface ModelinatorProps {
  serial: string;
  pinned?: boolean;
  recommended?: boolean;
  modelinator: ModelinatorConfig;
}

export const Modelinator: FunctionComponent<ModelinatorProps> = ({
  serial,
  pinned = false,
  recommended = false,
  modelinator,
}) => {
  const { t } = useTranslation();

  const canReadAdvanced = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);

  const [setConfigValue, { isLoading }] = useMutationPopups(
    useSetConfigValueMutation(),
    {
      success: capitalize(
        t("utils.actions.updatedLong", {
          subject: t("models.configs.config_one"),
        })
      ),
    }
  );

  return (
    <li className="flex items-center gap-2">
      <IconButton
        onClick={() => {
          setConfigValue({
            serial,
            path: [
              getConfigKey(serial, modelinator.cropId),
              "pinned_model",
            ].join("/"),
            value: pinned ? "" : modelinator.modelId,
          });
        }}
      >
        {(() => {
          if (isLoading) {
            return <CircularProgress size={24} color="info" />;
          }
          return pinned ? (
            <PinnedIcon className="text-yellow-500" />
          ) : (
            <UnpinnedIcon className="text-gray-600" />
          );
        })()}
      </IconButton>
      {recommended && (
        <Tooltip arrow title={t("models.crops.fields.recommended")}>
          <RecommendedIcon className="text-yellow-500" />
        </Tooltip>
      )}
      {canReadAdvanced ? (
        <Link
          component={RouterLink}
          className="font-mono text-xs cursor-pointer text-white"
          to={getModelinatorPath(
            serial,
            modelinator.cropId,
            modelinator.modelId
          )}
        >
          {modelinator.modelId}
        </Link>
      ) : (
        <span className="font-mono text-xs text-white">
          {modelinator.modelId}
        </span>
      )}
    </li>
  );
};
