import { useSelf } from "portal/state/store";
import React, {
  ComponentType,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
} from "react";

type Permission = string;
type PermissionSet = Record<Permission, true>;

// A permission group is either a single permission that must be granted, or an
// array of permissions that must ALL be granted for the group to be satisfied.
type PermissionGroup = Permission | Permission[];

const isSatisfied = (
  permissions: PermissionSet,
  group: PermissionGroup
): boolean => {
  return Array.isArray(group)
    ? group.every((permission) => Object.hasOwn(permissions, permission))
    : Object.hasOwn(permissions, group);
};

/**
 * core logic utility
 * we don't export this because we handle all three reasonable cases below
 *
 * Returns true if any of the permission groups is satisfied.
 */
const isAuthorized = (
  permissions: Record<Permission, true>,
  permissionGroups: PermissionGroup[]
): boolean => {
  return permissionGroups.some((group) => isSatisfied(permissions, group));
};

// hook version of isAuthorized
export const useAuthorizationRequired = (
  permissionGroups: PermissionGroup[]
): boolean => {
  const { permissions } = useSelf();
  return isAuthorized(permissions, permissionGroups);
};

// component version of isAuthorized
interface WithAuthorizationRequiredProps extends PropsWithChildren {
  permissionGroups?: PermissionGroup[];
}
export const WithAuthorizationRequired: FunctionComponent<
  WithAuthorizationRequiredProps
> = ({ children, permissionGroups = [] }) => {
  const { permissions } = useSelf();

  if (!isAuthorized(permissions, permissionGroups)) {
    return;
  }

  return children;
};

// HOC version of isAuthorized
export const withAuthorizationRequired = <P extends object>(
  permissionGroups: PermissionGroup[] = [],
  Component: ComponentType<P>,
  otherwise?: ReactNode
): FunctionComponent<P> =>
  function WithAuthorizationRequired(props: P): ReactNode {
    const { permissions } = useSelf();

    if (!isAuthorized(permissions, permissionGroups)) {
      return otherwise;
    }

    return <Component {...props} />;
  };
