import { AutotractorSubpath, Path, Slug } from "portal/utils/routing";
import { NewRobotAutotractorJob } from "./NewRobotAutotractorJob";
import { RobotAutotractorJobList } from "./RobotAutotractorJobList";
import { RouteObject, useRoutes } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

interface Props {
  serial: string;
}

const _RobotAutotractor: FunctionComponent<Props> = ({ serial }) => {
  const routes: RouteObject[] = [
    { path: Path.BASE, element: <RobotAutotractorJobList serial={serial} /> },
    {
      path: `/${AutotractorSubpath.JOBS}/${Slug.NEW}`,
      element: <NewRobotAutotractorJob serial={serial} />,
    },
  ];

  return useRoutes(routes);
};

export const RobotAutotractor = withAuthenticationRequired(_RobotAutotractor);
