import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { useNavigate } from "react-router-dom";
import React, {
  ComponentType,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
} from "react";

interface Props extends PropsWithChildren {
  flag: FeatureFlag;
  noFlag?: ReactElement | string | (() => void);
}

export const RequireFeatureFlag: FunctionComponent<Props> = ({
  flag,
  noFlag,
  children,
}) => {
  const { isEnabled, isLoading } = useFeatureFlag(flag);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isEnabled) {
      if (typeof noFlag === "string") {
        // noFlag is a path, redirect to it
        navigate(noFlag);
      } else if (typeof noFlag === "function") {
        // noFlag is a function, run it
        noFlag();
      }
      // noFlag is a ReactElement, it will render below
    }
  }, [isEnabled, isLoading, navigate, noFlag]);

  if (isLoading) {
    // flags are still loading, don't render anything for now
    return;
  }
  if (isEnabled) {
    // flag is enabled, render child
    return <>{children}</>;
  } else if (typeof noFlag === "string" || typeof noFlag === "function") {
    // flag is disabled and noFlag is a path or function, it's handled above
  } else {
    // flag is disabled and noFlag is a ReactElement, render it
    return noFlag;
  }
};

export const withFeatureFlag =
  <P extends object>({ flag, noFlag }: Props, Component: ComponentType<P>) =>
  (props: P) =>
    (
      <RequireFeatureFlag flag={flag} noFlag={noFlag}>
        <Component {...props} />
      </RequireFeatureFlag>
    );
