import { AlarmTable } from "portal/components/AlarmTable";
import { buildPermission } from "portal/utils/auth";
import { NoScroll } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useListAlarmsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent } from "react";

const _FleetAlarmList: FunctionComponent = () => {
  const { data: alarms } = useQueryPopups(useListAlarmsQuery());

  return (
    <NoScroll>
      <AlarmTable alarms={alarms} fleet className="flex-1 flex-grow-0" />
    </NoScroll>
  );
};

export const FleetAlarmList = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.alarms_customer,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.alarms_customer,
        PermissionDomain.all
      ),
    ],
    _FleetAlarmList
  )
);
