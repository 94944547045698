// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/farm.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { AbLine, Id, LineString, Point, Polygon } from "../geo/geo";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "carbon.portal.farm";

export interface Farm {
  id: Id | undefined;
  version: VersionInfo | undefined;
  name: string;
  customerId: number;
  pointDefs: PointDefinition[];
  zones: Zone[];
}

export interface VersionInfo {
  /** Sequence number for this version. Monotonically increasing. */
  ordinal: number;
  /** Update time for this version. */
  updateTime:
    | string
    | undefined;
  /**
   * Whether this entity is intended to be deleted in the latest version. The
   * entity is kept around as a tombstone so that we can tell that future syncs
   * aren't intending to recreate it.
   */
  deleted: boolean;
  /**
   * Input-only field: whether this entity is intended to be changed in a given
   * patch request. If this is false, then no edits to this entity's fields
   * will be incorporated into the merge, though edits to its descendants may
   * still be incorporated.
   */
  changed: boolean;
}

export interface PointDefinition {
  point: Point | undefined;
  version: VersionInfo | undefined;
}

export interface Zone {
  id: Id | undefined;
  version:
    | VersionInfo
    | undefined;
  /** Human-readable name for this zone. */
  name: string;
  /** The physical areas that this zone includes. */
  areas: Area[];
  contents: ZoneContents | undefined;
}

/**
 * This is a full message because `protoc-gen-go` generates bad code for
 * `oneof`s: https://github.com/golang/protobuf/issues/1326
 */
export interface ZoneContents {
  field?: FieldData | undefined;
  headland?: HeadlandData | undefined;
  privateRoad?: PrivateRoadData | undefined;
  obstacle?: ObstacleData | undefined;
}

/**
 * An area is a contiguous region of space. It comprises all points within
 * `buffer_meters` of the `geometry`. For example, a `line_string` in the shape
 * of a circle describes an annulus, whereas a `polygon` in the same shape
 * describes a disk.
 */
export interface Area {
  bufferMeters: number;
  point?: Point | undefined;
  lineString?: LineString | undefined;
  polygon?: Polygon | undefined;
}

export interface FieldData {
  /**
   * Heading along which crops are planted. Used for determining a default
   * direction of travel when plotting a GPS path through this field.
   */
  plantingHeading: PlantingHeading | undefined;
}

export interface HeadlandData {
}

export interface PrivateRoadData {
}

export interface ObstacleData {
}

/**
 * A planting heading can either be specified as an A-B line of recorded
 * points, or just a fixed numeric heading.
 */
export interface PlantingHeading {
  /** Degrees clockwise from north in the middle of the field. */
  azimuthDegrees?:
    | number
    | undefined;
  /** A vector between two points along the centerline of a single row. */
  abLine?: AbLine | undefined;
}

export interface CreateFarmRequest {
  farm: Farm | undefined;
}

export interface GetFarmRequest {
  id: number;
}

export interface UpdateFarmRequest {
  /**
   * Patch updates to the farm. Descendant `VersionInfo`s must retain the
   * `ordinal` of the last copy that this client received from the server, for
   * merging purposes. To delete an entity, flip `deleted` from `false` to
   * `true`. To update an entity, set `changed` to `true` and edit the fields
   * on the entity.
   */
  farm: Farm | undefined;
}

export interface ListFarmsRequest {
  /**
   * Cursor from `ListFarmsResponse.next_page_token` on a previous response.
   * If blank, starts from the beginning.
   */
  pageToken: string;
}

export interface ListFarmsResponse {
  farms: Farm[];
  /**
   * Cursor for `ListFarmsRequest.page_token`. If blank, there are no further
   * pages.
   */
  nextPageToken: string;
}

function createBaseFarm(): Farm {
  return { id: undefined, version: undefined, name: "", customerId: 0, pointDefs: [], zones: [] };
}

export const Farm: MessageFns<Farm> = {
  encode(message: Farm, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      Id.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.version !== undefined) {
      VersionInfo.encode(message.version, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.customerId !== 0) {
      writer.uint32(32).int64(message.customerId);
    }
    for (const v of message.pointDefs) {
      PointDefinition.encode(v!, writer.uint32(42).fork()).join();
    }
    for (const v of message.zones) {
      Zone.encode(v!, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Farm {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = Id.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = VersionInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.customerId = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pointDefs.push(PointDefinition.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.zones.push(Zone.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Farm {
    return {
      id: isSet(object.id) ? Id.fromJSON(object.id) : undefined,
      version: isSet(object.version) ? VersionInfo.fromJSON(object.version) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      customerId: isSet(object.customerId) ? globalThis.Number(object.customerId) : 0,
      pointDefs: globalThis.Array.isArray(object?.pointDefs)
        ? object.pointDefs.map((e: any) => PointDefinition.fromJSON(e))
        : [],
      zones: globalThis.Array.isArray(object?.zones) ? object.zones.map((e: any) => Zone.fromJSON(e)) : [],
    };
  },

  toJSON(message: Farm): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Id.toJSON(message.id);
    }
    if (message.version !== undefined) {
      obj.version = VersionInfo.toJSON(message.version);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.customerId !== 0) {
      obj.customerId = Math.round(message.customerId);
    }
    if (message.pointDefs?.length) {
      obj.pointDefs = message.pointDefs.map((e) => PointDefinition.toJSON(e));
    }
    if (message.zones?.length) {
      obj.zones = message.zones.map((e) => Zone.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Farm>, I>>(base?: I): Farm {
    return Farm.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Farm>, I>>(object: I): Farm {
    const message = createBaseFarm();
    message.id = (object.id !== undefined && object.id !== null) ? Id.fromPartial(object.id) : undefined;
    message.version = (object.version !== undefined && object.version !== null)
      ? VersionInfo.fromPartial(object.version)
      : undefined;
    message.name = object.name ?? "";
    message.customerId = object.customerId ?? 0;
    message.pointDefs = object.pointDefs?.map((e) => PointDefinition.fromPartial(e)) || [];
    message.zones = object.zones?.map((e) => Zone.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVersionInfo(): VersionInfo {
  return { ordinal: 0, updateTime: undefined, deleted: false, changed: false };
}

export const VersionInfo: MessageFns<VersionInfo> = {
  encode(message: VersionInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ordinal !== 0) {
      writer.uint32(8).int64(message.ordinal);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(18).fork()).join();
    }
    if (message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    if (message.changed !== false) {
      writer.uint32(32).bool(message.changed);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VersionInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVersionInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ordinal = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.changed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VersionInfo {
    return {
      ordinal: isSet(object.ordinal) ? globalThis.Number(object.ordinal) : 0,
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
      changed: isSet(object.changed) ? globalThis.Boolean(object.changed) : false,
    };
  },

  toJSON(message: VersionInfo): unknown {
    const obj: any = {};
    if (message.ordinal !== 0) {
      obj.ordinal = Math.round(message.ordinal);
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    if (message.changed !== false) {
      obj.changed = message.changed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VersionInfo>, I>>(base?: I): VersionInfo {
    return VersionInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VersionInfo>, I>>(object: I): VersionInfo {
    const message = createBaseVersionInfo();
    message.ordinal = object.ordinal ?? 0;
    message.updateTime = object.updateTime ?? undefined;
    message.deleted = object.deleted ?? false;
    message.changed = object.changed ?? false;
    return message;
  },
};

function createBasePointDefinition(): PointDefinition {
  return { point: undefined, version: undefined };
}

export const PointDefinition: MessageFns<PointDefinition> = {
  encode(message: PointDefinition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.point !== undefined) {
      Point.encode(message.point, writer.uint32(10).fork()).join();
    }
    if (message.version !== undefined) {
      VersionInfo.encode(message.version, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PointDefinition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePointDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.point = Point.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = VersionInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PointDefinition {
    return {
      point: isSet(object.point) ? Point.fromJSON(object.point) : undefined,
      version: isSet(object.version) ? VersionInfo.fromJSON(object.version) : undefined,
    };
  },

  toJSON(message: PointDefinition): unknown {
    const obj: any = {};
    if (message.point !== undefined) {
      obj.point = Point.toJSON(message.point);
    }
    if (message.version !== undefined) {
      obj.version = VersionInfo.toJSON(message.version);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PointDefinition>, I>>(base?: I): PointDefinition {
    return PointDefinition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PointDefinition>, I>>(object: I): PointDefinition {
    const message = createBasePointDefinition();
    message.point = (object.point !== undefined && object.point !== null) ? Point.fromPartial(object.point) : undefined;
    message.version = (object.version !== undefined && object.version !== null)
      ? VersionInfo.fromPartial(object.version)
      : undefined;
    return message;
  },
};

function createBaseZone(): Zone {
  return { id: undefined, version: undefined, name: "", areas: [], contents: undefined };
}

export const Zone: MessageFns<Zone> = {
  encode(message: Zone, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      Id.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.version !== undefined) {
      VersionInfo.encode(message.version, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.areas) {
      Area.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.contents !== undefined) {
      ZoneContents.encode(message.contents, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Zone {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = Id.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = VersionInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.areas.push(Area.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contents = ZoneContents.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Zone {
    return {
      id: isSet(object.id) ? Id.fromJSON(object.id) : undefined,
      version: isSet(object.version) ? VersionInfo.fromJSON(object.version) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      areas: globalThis.Array.isArray(object?.areas) ? object.areas.map((e: any) => Area.fromJSON(e)) : [],
      contents: isSet(object.contents) ? ZoneContents.fromJSON(object.contents) : undefined,
    };
  },

  toJSON(message: Zone): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Id.toJSON(message.id);
    }
    if (message.version !== undefined) {
      obj.version = VersionInfo.toJSON(message.version);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.areas?.length) {
      obj.areas = message.areas.map((e) => Area.toJSON(e));
    }
    if (message.contents !== undefined) {
      obj.contents = ZoneContents.toJSON(message.contents);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Zone>, I>>(base?: I): Zone {
    return Zone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Zone>, I>>(object: I): Zone {
    const message = createBaseZone();
    message.id = (object.id !== undefined && object.id !== null) ? Id.fromPartial(object.id) : undefined;
    message.version = (object.version !== undefined && object.version !== null)
      ? VersionInfo.fromPartial(object.version)
      : undefined;
    message.name = object.name ?? "";
    message.areas = object.areas?.map((e) => Area.fromPartial(e)) || [];
    message.contents = (object.contents !== undefined && object.contents !== null)
      ? ZoneContents.fromPartial(object.contents)
      : undefined;
    return message;
  },
};

function createBaseZoneContents(): ZoneContents {
  return { field: undefined, headland: undefined, privateRoad: undefined, obstacle: undefined };
}

export const ZoneContents: MessageFns<ZoneContents> = {
  encode(message: ZoneContents, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.field !== undefined) {
      FieldData.encode(message.field, writer.uint32(42).fork()).join();
    }
    if (message.headland !== undefined) {
      HeadlandData.encode(message.headland, writer.uint32(50).fork()).join();
    }
    if (message.privateRoad !== undefined) {
      PrivateRoadData.encode(message.privateRoad, writer.uint32(58).fork()).join();
    }
    if (message.obstacle !== undefined) {
      ObstacleData.encode(message.obstacle, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ZoneContents {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZoneContents();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.field = FieldData.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.headland = HeadlandData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.privateRoad = PrivateRoadData.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.obstacle = ObstacleData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZoneContents {
    return {
      field: isSet(object.field) ? FieldData.fromJSON(object.field) : undefined,
      headland: isSet(object.headland) ? HeadlandData.fromJSON(object.headland) : undefined,
      privateRoad: isSet(object.privateRoad) ? PrivateRoadData.fromJSON(object.privateRoad) : undefined,
      obstacle: isSet(object.obstacle) ? ObstacleData.fromJSON(object.obstacle) : undefined,
    };
  },

  toJSON(message: ZoneContents): unknown {
    const obj: any = {};
    if (message.field !== undefined) {
      obj.field = FieldData.toJSON(message.field);
    }
    if (message.headland !== undefined) {
      obj.headland = HeadlandData.toJSON(message.headland);
    }
    if (message.privateRoad !== undefined) {
      obj.privateRoad = PrivateRoadData.toJSON(message.privateRoad);
    }
    if (message.obstacle !== undefined) {
      obj.obstacle = ObstacleData.toJSON(message.obstacle);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ZoneContents>, I>>(base?: I): ZoneContents {
    return ZoneContents.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ZoneContents>, I>>(object: I): ZoneContents {
    const message = createBaseZoneContents();
    message.field = (object.field !== undefined && object.field !== null)
      ? FieldData.fromPartial(object.field)
      : undefined;
    message.headland = (object.headland !== undefined && object.headland !== null)
      ? HeadlandData.fromPartial(object.headland)
      : undefined;
    message.privateRoad = (object.privateRoad !== undefined && object.privateRoad !== null)
      ? PrivateRoadData.fromPartial(object.privateRoad)
      : undefined;
    message.obstacle = (object.obstacle !== undefined && object.obstacle !== null)
      ? ObstacleData.fromPartial(object.obstacle)
      : undefined;
    return message;
  },
};

function createBaseArea(): Area {
  return { bufferMeters: 0, point: undefined, lineString: undefined, polygon: undefined };
}

export const Area: MessageFns<Area> = {
  encode(message: Area, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bufferMeters !== 0) {
      writer.uint32(9).double(message.bufferMeters);
    }
    if (message.point !== undefined) {
      Point.encode(message.point, writer.uint32(18).fork()).join();
    }
    if (message.lineString !== undefined) {
      LineString.encode(message.lineString, writer.uint32(26).fork()).join();
    }
    if (message.polygon !== undefined) {
      Polygon.encode(message.polygon, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Area {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArea();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.bufferMeters = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.point = Point.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lineString = LineString.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.polygon = Polygon.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Area {
    return {
      bufferMeters: isSet(object.bufferMeters) ? globalThis.Number(object.bufferMeters) : 0,
      point: isSet(object.point) ? Point.fromJSON(object.point) : undefined,
      lineString: isSet(object.lineString) ? LineString.fromJSON(object.lineString) : undefined,
      polygon: isSet(object.polygon) ? Polygon.fromJSON(object.polygon) : undefined,
    };
  },

  toJSON(message: Area): unknown {
    const obj: any = {};
    if (message.bufferMeters !== 0) {
      obj.bufferMeters = message.bufferMeters;
    }
    if (message.point !== undefined) {
      obj.point = Point.toJSON(message.point);
    }
    if (message.lineString !== undefined) {
      obj.lineString = LineString.toJSON(message.lineString);
    }
    if (message.polygon !== undefined) {
      obj.polygon = Polygon.toJSON(message.polygon);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Area>, I>>(base?: I): Area {
    return Area.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Area>, I>>(object: I): Area {
    const message = createBaseArea();
    message.bufferMeters = object.bufferMeters ?? 0;
    message.point = (object.point !== undefined && object.point !== null) ? Point.fromPartial(object.point) : undefined;
    message.lineString = (object.lineString !== undefined && object.lineString !== null)
      ? LineString.fromPartial(object.lineString)
      : undefined;
    message.polygon = (object.polygon !== undefined && object.polygon !== null)
      ? Polygon.fromPartial(object.polygon)
      : undefined;
    return message;
  },
};

function createBaseFieldData(): FieldData {
  return { plantingHeading: undefined };
}

export const FieldData: MessageFns<FieldData> = {
  encode(message: FieldData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.plantingHeading !== undefined) {
      PlantingHeading.encode(message.plantingHeading, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FieldData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.plantingHeading = PlantingHeading.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldData {
    return {
      plantingHeading: isSet(object.plantingHeading) ? PlantingHeading.fromJSON(object.plantingHeading) : undefined,
    };
  },

  toJSON(message: FieldData): unknown {
    const obj: any = {};
    if (message.plantingHeading !== undefined) {
      obj.plantingHeading = PlantingHeading.toJSON(message.plantingHeading);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldData>, I>>(base?: I): FieldData {
    return FieldData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldData>, I>>(object: I): FieldData {
    const message = createBaseFieldData();
    message.plantingHeading = (object.plantingHeading !== undefined && object.plantingHeading !== null)
      ? PlantingHeading.fromPartial(object.plantingHeading)
      : undefined;
    return message;
  },
};

function createBaseHeadlandData(): HeadlandData {
  return {};
}

export const HeadlandData: MessageFns<HeadlandData> = {
  encode(_: HeadlandData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HeadlandData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeadlandData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): HeadlandData {
    return {};
  },

  toJSON(_: HeadlandData): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<HeadlandData>, I>>(base?: I): HeadlandData {
    return HeadlandData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HeadlandData>, I>>(_: I): HeadlandData {
    const message = createBaseHeadlandData();
    return message;
  },
};

function createBasePrivateRoadData(): PrivateRoadData {
  return {};
}

export const PrivateRoadData: MessageFns<PrivateRoadData> = {
  encode(_: PrivateRoadData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PrivateRoadData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivateRoadData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PrivateRoadData {
    return {};
  },

  toJSON(_: PrivateRoadData): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivateRoadData>, I>>(base?: I): PrivateRoadData {
    return PrivateRoadData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrivateRoadData>, I>>(_: I): PrivateRoadData {
    const message = createBasePrivateRoadData();
    return message;
  },
};

function createBaseObstacleData(): ObstacleData {
  return {};
}

export const ObstacleData: MessageFns<ObstacleData> = {
  encode(_: ObstacleData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ObstacleData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObstacleData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ObstacleData {
    return {};
  },

  toJSON(_: ObstacleData): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ObstacleData>, I>>(base?: I): ObstacleData {
    return ObstacleData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObstacleData>, I>>(_: I): ObstacleData {
    const message = createBaseObstacleData();
    return message;
  },
};

function createBasePlantingHeading(): PlantingHeading {
  return { azimuthDegrees: undefined, abLine: undefined };
}

export const PlantingHeading: MessageFns<PlantingHeading> = {
  encode(message: PlantingHeading, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.azimuthDegrees !== undefined) {
      writer.uint32(9).double(message.azimuthDegrees);
    }
    if (message.abLine !== undefined) {
      AbLine.encode(message.abLine, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlantingHeading {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlantingHeading();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.azimuthDegrees = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.abLine = AbLine.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlantingHeading {
    return {
      azimuthDegrees: isSet(object.azimuthDegrees) ? globalThis.Number(object.azimuthDegrees) : undefined,
      abLine: isSet(object.abLine) ? AbLine.fromJSON(object.abLine) : undefined,
    };
  },

  toJSON(message: PlantingHeading): unknown {
    const obj: any = {};
    if (message.azimuthDegrees !== undefined) {
      obj.azimuthDegrees = message.azimuthDegrees;
    }
    if (message.abLine !== undefined) {
      obj.abLine = AbLine.toJSON(message.abLine);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlantingHeading>, I>>(base?: I): PlantingHeading {
    return PlantingHeading.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlantingHeading>, I>>(object: I): PlantingHeading {
    const message = createBasePlantingHeading();
    message.azimuthDegrees = object.azimuthDegrees ?? undefined;
    message.abLine = (object.abLine !== undefined && object.abLine !== null)
      ? AbLine.fromPartial(object.abLine)
      : undefined;
    return message;
  },
};

function createBaseCreateFarmRequest(): CreateFarmRequest {
  return { farm: undefined };
}

export const CreateFarmRequest: MessageFns<CreateFarmRequest> = {
  encode(message: CreateFarmRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.farm !== undefined) {
      Farm.encode(message.farm, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateFarmRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFarmRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.farm = Farm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateFarmRequest {
    return { farm: isSet(object.farm) ? Farm.fromJSON(object.farm) : undefined };
  },

  toJSON(message: CreateFarmRequest): unknown {
    const obj: any = {};
    if (message.farm !== undefined) {
      obj.farm = Farm.toJSON(message.farm);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateFarmRequest>, I>>(base?: I): CreateFarmRequest {
    return CreateFarmRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFarmRequest>, I>>(object: I): CreateFarmRequest {
    const message = createBaseCreateFarmRequest();
    message.farm = (object.farm !== undefined && object.farm !== null) ? Farm.fromPartial(object.farm) : undefined;
    return message;
  },
};

function createBaseGetFarmRequest(): GetFarmRequest {
  return { id: 0 };
}

export const GetFarmRequest: MessageFns<GetFarmRequest> = {
  encode(message: GetFarmRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetFarmRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFarmRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFarmRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetFarmRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFarmRequest>, I>>(base?: I): GetFarmRequest {
    return GetFarmRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFarmRequest>, I>>(object: I): GetFarmRequest {
    const message = createBaseGetFarmRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseUpdateFarmRequest(): UpdateFarmRequest {
  return { farm: undefined };
}

export const UpdateFarmRequest: MessageFns<UpdateFarmRequest> = {
  encode(message: UpdateFarmRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.farm !== undefined) {
      Farm.encode(message.farm, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateFarmRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFarmRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.farm = Farm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFarmRequest {
    return { farm: isSet(object.farm) ? Farm.fromJSON(object.farm) : undefined };
  },

  toJSON(message: UpdateFarmRequest): unknown {
    const obj: any = {};
    if (message.farm !== undefined) {
      obj.farm = Farm.toJSON(message.farm);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFarmRequest>, I>>(base?: I): UpdateFarmRequest {
    return UpdateFarmRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFarmRequest>, I>>(object: I): UpdateFarmRequest {
    const message = createBaseUpdateFarmRequest();
    message.farm = (object.farm !== undefined && object.farm !== null) ? Farm.fromPartial(object.farm) : undefined;
    return message;
  },
};

function createBaseListFarmsRequest(): ListFarmsRequest {
  return { pageToken: "" };
}

export const ListFarmsRequest: MessageFns<ListFarmsRequest> = {
  encode(message: ListFarmsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListFarmsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFarmsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFarmsRequest {
    return { pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "" };
  },

  toJSON(message: ListFarmsRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFarmsRequest>, I>>(base?: I): ListFarmsRequest {
    return ListFarmsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFarmsRequest>, I>>(object: I): ListFarmsRequest {
    const message = createBaseListFarmsRequest();
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListFarmsResponse(): ListFarmsResponse {
  return { farms: [], nextPageToken: "" };
}

export const ListFarmsResponse: MessageFns<ListFarmsResponse> = {
  encode(message: ListFarmsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.farms) {
      Farm.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListFarmsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFarmsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.farms.push(Farm.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFarmsResponse {
    return {
      farms: globalThis.Array.isArray(object?.farms) ? object.farms.map((e: any) => Farm.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListFarmsResponse): unknown {
    const obj: any = {};
    if (message.farms?.length) {
      obj.farms = message.farms.map((e) => Farm.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFarmsResponse>, I>>(base?: I): ListFarmsResponse {
    return ListFarmsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFarmsResponse>, I>>(object: I): ListFarmsResponse {
    const message = createBaseListFarmsResponse();
    message.farms = object.farms?.map((e) => Farm.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
