import { BLUE_LOADING_BUTTON } from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CustomerSelector } from "../customers/CustomerSelector";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RobotSummaryResponse } from "protos/portal/robots";
import {
  useAssignRobotMutation,
  useListCustomersQuery,
} from "portal/state/portalApi";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "../auth/WithAuthorizationRequired";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/EditOutlined";
import React, { FunctionComponent, useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

const _RobotCustomerSelector: FunctionComponent<{
  summary?: RobotSummaryResponse;
  readOnly?: boolean;
}> = ({ summary, readOnly = false }) => {
  const { t } = useTranslation();

  const [customerId, setCustomerId] = useState<number | undefined>();
  useEffect(() => {
    setCustomerId(summary?.customer?.db?.id);
  }, [summary?.customer]);

  const { data: customers } = useQueryPopups(
    useListCustomersQuery(undefined, {
      skip: readOnly,
    })
  );
  const customer = readOnly
    ? summary?.customer
    : customers?.find(
        (customer) => customer.db?.id === summary?.customer?.db?.id
      );

  const [isOpen, setOpen] = useState<boolean>(false);

  const [assignRobot, { isLoading: isAssignLoading }] = useMutationPopups(
    useAssignRobotMutation(),
    {
      success: t("utils.actions.updatedLong", {
        subject: "models.configs.config_one",
      }),
    }
  );

  const canUpdate =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.update,
        PermissionResource.robots,
        PermissionDomain.all
      ),
    ]) && !readOnly;

  return (
    <>
      <div className="flex items-center justify-between">
        {customer?.name ?? t("components.Loading.placeholder")}
        {canUpdate && (
          <IconButton
            className="p-0 text-white"
            onClick={() => {
              setCustomerId(summary?.customer?.db?.id);
              setOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("components.customers.CustomerSelector.title")}
        </DialogTitle>
        <DialogContent>
          <CustomerSelector
            value={customerId}
            onChange={(newId) => setCustomerId(newId)}
            small
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            className="text-white"
            disabled={isAssignLoading}
            onClick={() => setOpen(false)}
          >
            {t("utils.actions.cancel")}
          </Button>
          <LoadingButton
            {...BLUE_LOADING_BUTTON}
            loading={isAssignLoading}
            startIcon={<SaveIcon />}
            onClick={async () => {
              if (!summary || !summary.robot?.serial) {
                return;
              }
              if (!isUndefined(customerId)) {
                await assignRobot({
                  serial: summary.robot.serial,
                  customerId,
                });
                setOpen(false);
              }
            }}
          >
            {t("utils.actions.save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const RobotCustomerSelector = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.read,
      PermissionResource.robots,
      PermissionDomain.all
    ),
  ],
  _RobotCustomerSelector
);
