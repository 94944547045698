// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: rtc/jobs.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { LineString, Point } from "../geo/geo";
import { Duration } from "../google/protobuf/duration";
import { Empty } from "../google/protobuf/empty";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "carbon.rtc";

/** Task API */
export interface Task {
  id: number;
  name: string;
  startedAt: string | undefined;
  endedAt: string | undefined;
  expectedDuration: Duration | undefined;
  statusInfo: string;
  expectedTractorState: TractorState[];
  state: Task_TaskState;
  /** core structural task types */
  sequence?: SequenceTask | undefined;
  manual?:
    | ManualTask
    | undefined;
  /** tasks with specific automations available */
  goToAndFace?: GoToAndFaceTask | undefined;
  followPath?: FollowPathTask | undefined;
  tractorState?: SetTractorStateTask | undefined;
}

export enum Task_TaskState {
  TASK_STATE_UNSPECIFIED = 0,
  PENDING = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function task_TaskStateFromJSON(object: any): Task_TaskState {
  switch (object) {
    case 0:
    case "TASK_STATE_UNSPECIFIED":
      return Task_TaskState.TASK_STATE_UNSPECIFIED;
    case 1:
    case "PENDING":
      return Task_TaskState.PENDING;
    case 2:
    case "IN_PROGRESS":
      return Task_TaskState.IN_PROGRESS;
    case 3:
    case "COMPLETED":
      return Task_TaskState.COMPLETED;
    case 4:
    case "CANCELLED":
      return Task_TaskState.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Task_TaskState.UNRECOGNIZED;
  }
}

export function task_TaskStateToJSON(object: Task_TaskState): string {
  switch (object) {
    case Task_TaskState.TASK_STATE_UNSPECIFIED:
      return "TASK_STATE_UNSPECIFIED";
    case Task_TaskState.PENDING:
      return "PENDING";
    case Task_TaskState.IN_PROGRESS:
      return "IN_PROGRESS";
    case Task_TaskState.COMPLETED:
      return "COMPLETED";
    case Task_TaskState.CANCELLED:
      return "CANCELLED";
    case Task_TaskState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * A `SequenceTask` requires completing all of its child tasks, in order, with
 * no overlap in time.
 */
export interface SequenceTask {
  items: Task[];
}

/**
 * A `ManualTask` can only be completed by a human and will always require an
 * intervention request.
 */
export interface ManualTask {
  instructions: string;
}

/**
 * A `GoToAndFaceTask` requires that the robot move to a particular position
 * and face in a particular direction. For example, this might be used to line
 * up at the start of a row.
 */
export interface GoToAndFaceTask {
  point: Point | undefined;
  heading: number;
}

export interface FollowPathTask {
  path: LineString | undefined;
  speed: SpeedSetting | undefined;
  stopOnCompletion: boolean;
}

export interface SpeedSetting {
  constantMph?: number | undefined;
  remoteOperatorControlled?: Empty | undefined;
  implementControlled?: Empty | undefined;
}

export interface SetTractorStateTask {
  state: TractorState[];
}

export interface TractorState {
  gear?: TractorState_Gear | undefined;
  hitch?: HitchState | undefined;
}

export enum TractorState_Gear {
  GEAR_UNSPECIFIED = 0,
  PARK = 1,
  REVERSE = 2,
  NEUTRAL = 3,
  FORWARD = 4,
  POWERZERO = 5,
  UNRECOGNIZED = -1,
}

export function tractorState_GearFromJSON(object: any): TractorState_Gear {
  switch (object) {
    case 0:
    case "GEAR_UNSPECIFIED":
      return TractorState_Gear.GEAR_UNSPECIFIED;
    case 1:
    case "PARK":
      return TractorState_Gear.PARK;
    case 2:
    case "REVERSE":
      return TractorState_Gear.REVERSE;
    case 3:
    case "NEUTRAL":
      return TractorState_Gear.NEUTRAL;
    case 4:
    case "FORWARD":
      return TractorState_Gear.FORWARD;
    case 5:
    case "POWERZERO":
      return TractorState_Gear.POWERZERO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TractorState_Gear.UNRECOGNIZED;
  }
}

export function tractorState_GearToJSON(object: TractorState_Gear): string {
  switch (object) {
    case TractorState_Gear.GEAR_UNSPECIFIED:
      return "GEAR_UNSPECIFIED";
    case TractorState_Gear.PARK:
      return "PARK";
    case TractorState_Gear.REVERSE:
      return "REVERSE";
    case TractorState_Gear.NEUTRAL:
      return "NEUTRAL";
    case TractorState_Gear.FORWARD:
      return "FORWARD";
    case TractorState_Gear.POWERZERO:
      return "POWERZERO";
    case TractorState_Gear.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface HitchState {
  command?:
    | HitchState_HitchCommand
    | undefined;
  /** Target position, between 0.0 (fully lowered) and 1.0 (fully raised). */
  position?: number | undefined;
}

export enum HitchState_HitchCommand {
  HITCH_COMMAND_UNSPECIFIED = 0,
  RAISED = 1,
  LOWERED = 2,
  UNRECOGNIZED = -1,
}

export function hitchState_HitchCommandFromJSON(object: any): HitchState_HitchCommand {
  switch (object) {
    case 0:
    case "HITCH_COMMAND_UNSPECIFIED":
      return HitchState_HitchCommand.HITCH_COMMAND_UNSPECIFIED;
    case 1:
    case "RAISED":
      return HitchState_HitchCommand.RAISED;
    case 2:
    case "LOWERED":
      return HitchState_HitchCommand.LOWERED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HitchState_HitchCommand.UNRECOGNIZED;
  }
}

export function hitchState_HitchCommandToJSON(object: HitchState_HitchCommand): string {
  switch (object) {
    case HitchState_HitchCommand.HITCH_COMMAND_UNSPECIFIED:
      return "HITCH_COMMAND_UNSPECIFIED";
    case HitchState_HitchCommand.RAISED:
      return "RAISED";
    case HitchState_HitchCommand.LOWERED:
      return "LOWERED";
    case HitchState_HitchCommand.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetTaskRequest {
  id: number;
}

export interface CreateTaskRequest {
  task: Task | undefined;
}

export interface InsertTaskRequest {
  parentTaskId: number;
  precedingTaskId: number;
  task: Task | undefined;
}

export interface UpdateTaskRequest {
  task: Task | undefined;
}

export interface DeleteTaskRequest {
  id: number;
}

/** TODO:(smt) query filters */
export interface ListTasksRequest {
}

export interface TaskList {
  tasks: Task[];
}

/** Jobs API */
export interface Job {
  id: number;
  name: string;
  startedAt: string | undefined;
  endedAt: string | undefined;
  task: Task | undefined;
  state: Job_JobState;
  robot: string;
  workOrderId?:
    | number
    | undefined;
  /** Farm ID per Portal, typically a 12-character alphanumeric string. */
  farmId: string;
  /** Customer ID per Portal, typically a UUID. */
  customerId: string;
}

export enum Job_JobState {
  JOB_STATE_UNSPECIFIED = 0,
  PENDING = 1,
  IN_PROGRESS = 2,
  PAUSED = 3,
  COMPLETED = 4,
  CANCELLED = 5,
  UNRECOGNIZED = -1,
}

export function job_JobStateFromJSON(object: any): Job_JobState {
  switch (object) {
    case 0:
    case "JOB_STATE_UNSPECIFIED":
      return Job_JobState.JOB_STATE_UNSPECIFIED;
    case 1:
    case "PENDING":
      return Job_JobState.PENDING;
    case 2:
    case "IN_PROGRESS":
      return Job_JobState.IN_PROGRESS;
    case 3:
    case "PAUSED":
      return Job_JobState.PAUSED;
    case 4:
    case "COMPLETED":
      return Job_JobState.COMPLETED;
    case 5:
    case "CANCELLED":
      return Job_JobState.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Job_JobState.UNRECOGNIZED;
  }
}

export function job_JobStateToJSON(object: Job_JobState): string {
  switch (object) {
    case Job_JobState.JOB_STATE_UNSPECIFIED:
      return "JOB_STATE_UNSPECIFIED";
    case Job_JobState.PENDING:
      return "PENDING";
    case Job_JobState.IN_PROGRESS:
      return "IN_PROGRESS";
    case Job_JobState.PAUSED:
      return "PAUSED";
    case Job_JobState.COMPLETED:
      return "COMPLETED";
    case Job_JobState.CANCELLED:
      return "CANCELLED";
    case Job_JobState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetJobRequest {
  id: number;
}

export interface CreateJobRequest {
  job: Job | undefined;
}

export interface UpdateJobRequest {
  job: Job | undefined;
}

export interface DeleteJobRequest {
  id: number;
}

/** TODO:(smt) query filters */
export interface ListJobsRequest {
}

export interface JobList {
  jobs: Job[];
}

/** Work Orders APIs */
export interface WorkOrder {
  id: number;
  name: string;
  scheduledAt: string | undefined;
  duration: Duration | undefined;
  jobs: Job[];
  state: WorkOrder_WorkOrderState;
}

export enum WorkOrder_WorkOrderState {
  WORK_ORDER_STATE_UNSPECIFIED = 0,
  PENDING = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function workOrder_WorkOrderStateFromJSON(object: any): WorkOrder_WorkOrderState {
  switch (object) {
    case 0:
    case "WORK_ORDER_STATE_UNSPECIFIED":
      return WorkOrder_WorkOrderState.WORK_ORDER_STATE_UNSPECIFIED;
    case 1:
    case "PENDING":
      return WorkOrder_WorkOrderState.PENDING;
    case 2:
    case "IN_PROGRESS":
      return WorkOrder_WorkOrderState.IN_PROGRESS;
    case 3:
    case "COMPLETED":
      return WorkOrder_WorkOrderState.COMPLETED;
    case 4:
    case "CANCELLED":
      return WorkOrder_WorkOrderState.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WorkOrder_WorkOrderState.UNRECOGNIZED;
  }
}

export function workOrder_WorkOrderStateToJSON(object: WorkOrder_WorkOrderState): string {
  switch (object) {
    case WorkOrder_WorkOrderState.WORK_ORDER_STATE_UNSPECIFIED:
      return "WORK_ORDER_STATE_UNSPECIFIED";
    case WorkOrder_WorkOrderState.PENDING:
      return "PENDING";
    case WorkOrder_WorkOrderState.IN_PROGRESS:
      return "IN_PROGRESS";
    case WorkOrder_WorkOrderState.COMPLETED:
      return "COMPLETED";
    case WorkOrder_WorkOrderState.CANCELLED:
      return "CANCELLED";
    case WorkOrder_WorkOrderState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetWorkOrderRequest {
  id: number;
}

export interface CreateWorkOrderRequest {
  workOrder: WorkOrder | undefined;
}

export interface WorkOrderAddJobsRequest {
  workOrderId: number;
  jobId: number[];
}

export interface WorkOrderRemoveJobsRequest {
  workOrderId: number;
  jobId: number[];
}

export interface UpdateWorkOrderRequest {
  workOrder: WorkOrder | undefined;
}

export interface DeleteWorkOrderRequest {
  id: number;
}

/** TODO:(smt) query filters */
export interface ListWorkOrdersRequest {
}

export interface WorkOrderList {
  workOrders: WorkOrder[];
}

/** Intervention APIs */
export interface Intervention {
  id: number;
  taskId: number;
  qualification: string;
  description: string;
  state: Intervention_InterventionState;
  robotSerial: string;
  jobId: number;
  cause: Intervention_InterventionCause;
}

export enum Intervention_InterventionState {
  INTERVENTION_STATE_UNSPECIFIED = 0,
  NEW = 1,
  ACKNOWLEDGED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  CANCELLED = 5,
  FAILED = 6,
  UNRECOGNIZED = -1,
}

export function intervention_InterventionStateFromJSON(object: any): Intervention_InterventionState {
  switch (object) {
    case 0:
    case "INTERVENTION_STATE_UNSPECIFIED":
      return Intervention_InterventionState.INTERVENTION_STATE_UNSPECIFIED;
    case 1:
    case "NEW":
      return Intervention_InterventionState.NEW;
    case 2:
    case "ACKNOWLEDGED":
      return Intervention_InterventionState.ACKNOWLEDGED;
    case 3:
    case "IN_PROGRESS":
      return Intervention_InterventionState.IN_PROGRESS;
    case 4:
    case "COMPLETED":
      return Intervention_InterventionState.COMPLETED;
    case 5:
    case "CANCELLED":
      return Intervention_InterventionState.CANCELLED;
    case 6:
    case "FAILED":
      return Intervention_InterventionState.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Intervention_InterventionState.UNRECOGNIZED;
  }
}

export function intervention_InterventionStateToJSON(object: Intervention_InterventionState): string {
  switch (object) {
    case Intervention_InterventionState.INTERVENTION_STATE_UNSPECIFIED:
      return "INTERVENTION_STATE_UNSPECIFIED";
    case Intervention_InterventionState.NEW:
      return "NEW";
    case Intervention_InterventionState.ACKNOWLEDGED:
      return "ACKNOWLEDGED";
    case Intervention_InterventionState.IN_PROGRESS:
      return "IN_PROGRESS";
    case Intervention_InterventionState.COMPLETED:
      return "COMPLETED";
    case Intervention_InterventionState.CANCELLED:
      return "CANCELLED";
    case Intervention_InterventionState.FAILED:
      return "FAILED";
    case Intervention_InterventionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Intervention_InterventionCause {
  INTERVENTION_CAUSE_UNSPECIFIED = 0,
  SENSOR_TRIGGERED = 1,
  SAFETY_DRIVER_ACTION = 2,
  TRACTOR_REQUEST = 3,
  UNRECOGNIZED = -1,
}

export function intervention_InterventionCauseFromJSON(object: any): Intervention_InterventionCause {
  switch (object) {
    case 0:
    case "INTERVENTION_CAUSE_UNSPECIFIED":
      return Intervention_InterventionCause.INTERVENTION_CAUSE_UNSPECIFIED;
    case 1:
    case "SENSOR_TRIGGERED":
      return Intervention_InterventionCause.SENSOR_TRIGGERED;
    case 2:
    case "SAFETY_DRIVER_ACTION":
      return Intervention_InterventionCause.SAFETY_DRIVER_ACTION;
    case 3:
    case "TRACTOR_REQUEST":
      return Intervention_InterventionCause.TRACTOR_REQUEST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Intervention_InterventionCause.UNRECOGNIZED;
  }
}

export function intervention_InterventionCauseToJSON(object: Intervention_InterventionCause): string {
  switch (object) {
    case Intervention_InterventionCause.INTERVENTION_CAUSE_UNSPECIFIED:
      return "INTERVENTION_CAUSE_UNSPECIFIED";
    case Intervention_InterventionCause.SENSOR_TRIGGERED:
      return "SENSOR_TRIGGERED";
    case Intervention_InterventionCause.SAFETY_DRIVER_ACTION:
      return "SAFETY_DRIVER_ACTION";
    case Intervention_InterventionCause.TRACTOR_REQUEST:
      return "TRACTOR_REQUEST";
    case Intervention_InterventionCause.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CreateInterventionRequest {
  intervention: Intervention | undefined;
}

export interface AcknowledgeInterventionRequest {
  intervention: Intervention | undefined;
}

export interface CompleteInterventionRequest {
  state: CompleteInterventionRequest_CompletionState;
  intervention: Intervention | undefined;
}

export enum CompleteInterventionRequest_CompletionState {
  COMPLETION_STATE_UNSPECIFIED = 0,
  COMPLETE = 1,
  CANCELLED = 2,
  FAILED = 3,
  UNRECOGNIZED = -1,
}

export function completeInterventionRequest_CompletionStateFromJSON(
  object: any,
): CompleteInterventionRequest_CompletionState {
  switch (object) {
    case 0:
    case "COMPLETION_STATE_UNSPECIFIED":
      return CompleteInterventionRequest_CompletionState.COMPLETION_STATE_UNSPECIFIED;
    case 1:
    case "COMPLETE":
      return CompleteInterventionRequest_CompletionState.COMPLETE;
    case 2:
    case "CANCELLED":
      return CompleteInterventionRequest_CompletionState.CANCELLED;
    case 3:
    case "FAILED":
      return CompleteInterventionRequest_CompletionState.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompleteInterventionRequest_CompletionState.UNRECOGNIZED;
  }
}

export function completeInterventionRequest_CompletionStateToJSON(
  object: CompleteInterventionRequest_CompletionState,
): string {
  switch (object) {
    case CompleteInterventionRequest_CompletionState.COMPLETION_STATE_UNSPECIFIED:
      return "COMPLETION_STATE_UNSPECIFIED";
    case CompleteInterventionRequest_CompletionState.COMPLETE:
      return "COMPLETE";
    case CompleteInterventionRequest_CompletionState.CANCELLED:
      return "CANCELLED";
    case CompleteInterventionRequest_CompletionState.FAILED:
      return "FAILED";
    case CompleteInterventionRequest_CompletionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SubscriptionRequest {
}

export interface SubscriptionResponse {
  intervention?: Intervention | undefined;
}

function createBaseTask(): Task {
  return {
    id: 0,
    name: "",
    startedAt: undefined,
    endedAt: undefined,
    expectedDuration: undefined,
    statusInfo: "",
    expectedTractorState: [],
    state: 0,
    sequence: undefined,
    manual: undefined,
    goToAndFace: undefined,
    followPath: undefined,
    tractorState: undefined,
  };
}

export const Task: MessageFns<Task> = {
  encode(message: Task, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(26).fork()).join();
    }
    if (message.endedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.endedAt), writer.uint32(34).fork()).join();
    }
    if (message.expectedDuration !== undefined) {
      Duration.encode(message.expectedDuration, writer.uint32(42).fork()).join();
    }
    if (message.statusInfo !== "") {
      writer.uint32(50).string(message.statusInfo);
    }
    for (const v of message.expectedTractorState) {
      TractorState.encode(v!, writer.uint32(58).fork()).join();
    }
    if (message.state !== 0) {
      writer.uint32(64).int32(message.state);
    }
    if (message.sequence !== undefined) {
      SequenceTask.encode(message.sequence, writer.uint32(74).fork()).join();
    }
    if (message.manual !== undefined) {
      ManualTask.encode(message.manual, writer.uint32(82).fork()).join();
    }
    if (message.goToAndFace !== undefined) {
      GoToAndFaceTask.encode(message.goToAndFace, writer.uint32(90).fork()).join();
    }
    if (message.followPath !== undefined) {
      FollowPathTask.encode(message.followPath, writer.uint32(98).fork()).join();
    }
    if (message.tractorState !== undefined) {
      SetTractorStateTask.encode(message.tractorState, writer.uint32(106).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Task {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expectedDuration = Duration.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.statusInfo = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.expectedTractorState.push(TractorState.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sequence = SequenceTask.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.manual = ManualTask.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.goToAndFace = GoToAndFaceTask.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.followPath = FollowPathTask.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tractorState = SetTractorStateTask.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Task {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      startedAt: isSet(object.startedAt) ? globalThis.String(object.startedAt) : undefined,
      endedAt: isSet(object.endedAt) ? globalThis.String(object.endedAt) : undefined,
      expectedDuration: isSet(object.expectedDuration) ? Duration.fromJSON(object.expectedDuration) : undefined,
      statusInfo: isSet(object.statusInfo) ? globalThis.String(object.statusInfo) : "",
      expectedTractorState: globalThis.Array.isArray(object?.expectedTractorState)
        ? object.expectedTractorState.map((e: any) => TractorState.fromJSON(e))
        : [],
      state: isSet(object.state) ? task_TaskStateFromJSON(object.state) : 0,
      sequence: isSet(object.sequence) ? SequenceTask.fromJSON(object.sequence) : undefined,
      manual: isSet(object.manual) ? ManualTask.fromJSON(object.manual) : undefined,
      goToAndFace: isSet(object.goToAndFace) ? GoToAndFaceTask.fromJSON(object.goToAndFace) : undefined,
      followPath: isSet(object.followPath) ? FollowPathTask.fromJSON(object.followPath) : undefined,
      tractorState: isSet(object.tractorState) ? SetTractorStateTask.fromJSON(object.tractorState) : undefined,
    };
  },

  toJSON(message: Task): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt;
    }
    if (message.endedAt !== undefined) {
      obj.endedAt = message.endedAt;
    }
    if (message.expectedDuration !== undefined) {
      obj.expectedDuration = Duration.toJSON(message.expectedDuration);
    }
    if (message.statusInfo !== "") {
      obj.statusInfo = message.statusInfo;
    }
    if (message.expectedTractorState?.length) {
      obj.expectedTractorState = message.expectedTractorState.map((e) => TractorState.toJSON(e));
    }
    if (message.state !== 0) {
      obj.state = task_TaskStateToJSON(message.state);
    }
    if (message.sequence !== undefined) {
      obj.sequence = SequenceTask.toJSON(message.sequence);
    }
    if (message.manual !== undefined) {
      obj.manual = ManualTask.toJSON(message.manual);
    }
    if (message.goToAndFace !== undefined) {
      obj.goToAndFace = GoToAndFaceTask.toJSON(message.goToAndFace);
    }
    if (message.followPath !== undefined) {
      obj.followPath = FollowPathTask.toJSON(message.followPath);
    }
    if (message.tractorState !== undefined) {
      obj.tractorState = SetTractorStateTask.toJSON(message.tractorState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Task>, I>>(base?: I): Task {
    return Task.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Task>, I>>(object: I): Task {
    const message = createBaseTask();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.startedAt = object.startedAt ?? undefined;
    message.endedAt = object.endedAt ?? undefined;
    message.expectedDuration = (object.expectedDuration !== undefined && object.expectedDuration !== null)
      ? Duration.fromPartial(object.expectedDuration)
      : undefined;
    message.statusInfo = object.statusInfo ?? "";
    message.expectedTractorState = object.expectedTractorState?.map((e) => TractorState.fromPartial(e)) || [];
    message.state = object.state ?? 0;
    message.sequence = (object.sequence !== undefined && object.sequence !== null)
      ? SequenceTask.fromPartial(object.sequence)
      : undefined;
    message.manual = (object.manual !== undefined && object.manual !== null)
      ? ManualTask.fromPartial(object.manual)
      : undefined;
    message.goToAndFace = (object.goToAndFace !== undefined && object.goToAndFace !== null)
      ? GoToAndFaceTask.fromPartial(object.goToAndFace)
      : undefined;
    message.followPath = (object.followPath !== undefined && object.followPath !== null)
      ? FollowPathTask.fromPartial(object.followPath)
      : undefined;
    message.tractorState = (object.tractorState !== undefined && object.tractorState !== null)
      ? SetTractorStateTask.fromPartial(object.tractorState)
      : undefined;
    return message;
  },
};

function createBaseSequenceTask(): SequenceTask {
  return { items: [] };
}

export const SequenceTask: MessageFns<SequenceTask> = {
  encode(message: SequenceTask, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.items) {
      Task.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SequenceTask {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSequenceTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(Task.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SequenceTask {
    return { items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => Task.fromJSON(e)) : [] };
  },

  toJSON(message: SequenceTask): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => Task.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SequenceTask>, I>>(base?: I): SequenceTask {
    return SequenceTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SequenceTask>, I>>(object: I): SequenceTask {
    const message = createBaseSequenceTask();
    message.items = object.items?.map((e) => Task.fromPartial(e)) || [];
    return message;
  },
};

function createBaseManualTask(): ManualTask {
  return { instructions: "" };
}

export const ManualTask: MessageFns<ManualTask> = {
  encode(message: ManualTask, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.instructions !== "") {
      writer.uint32(10).string(message.instructions);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ManualTask {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManualTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instructions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManualTask {
    return { instructions: isSet(object.instructions) ? globalThis.String(object.instructions) : "" };
  },

  toJSON(message: ManualTask): unknown {
    const obj: any = {};
    if (message.instructions !== "") {
      obj.instructions = message.instructions;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManualTask>, I>>(base?: I): ManualTask {
    return ManualTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManualTask>, I>>(object: I): ManualTask {
    const message = createBaseManualTask();
    message.instructions = object.instructions ?? "";
    return message;
  },
};

function createBaseGoToAndFaceTask(): GoToAndFaceTask {
  return { point: undefined, heading: 0 };
}

export const GoToAndFaceTask: MessageFns<GoToAndFaceTask> = {
  encode(message: GoToAndFaceTask, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.point !== undefined) {
      Point.encode(message.point, writer.uint32(10).fork()).join();
    }
    if (message.heading !== 0) {
      writer.uint32(17).double(message.heading);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GoToAndFaceTask {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToAndFaceTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.point = Point.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.heading = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GoToAndFaceTask {
    return {
      point: isSet(object.point) ? Point.fromJSON(object.point) : undefined,
      heading: isSet(object.heading) ? globalThis.Number(object.heading) : 0,
    };
  },

  toJSON(message: GoToAndFaceTask): unknown {
    const obj: any = {};
    if (message.point !== undefined) {
      obj.point = Point.toJSON(message.point);
    }
    if (message.heading !== 0) {
      obj.heading = message.heading;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToAndFaceTask>, I>>(base?: I): GoToAndFaceTask {
    return GoToAndFaceTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToAndFaceTask>, I>>(object: I): GoToAndFaceTask {
    const message = createBaseGoToAndFaceTask();
    message.point = (object.point !== undefined && object.point !== null) ? Point.fromPartial(object.point) : undefined;
    message.heading = object.heading ?? 0;
    return message;
  },
};

function createBaseFollowPathTask(): FollowPathTask {
  return { path: undefined, speed: undefined, stopOnCompletion: false };
}

export const FollowPathTask: MessageFns<FollowPathTask> = {
  encode(message: FollowPathTask, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.path !== undefined) {
      LineString.encode(message.path, writer.uint32(10).fork()).join();
    }
    if (message.speed !== undefined) {
      SpeedSetting.encode(message.speed, writer.uint32(18).fork()).join();
    }
    if (message.stopOnCompletion !== false) {
      writer.uint32(24).bool(message.stopOnCompletion);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FollowPathTask {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowPathTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = LineString.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.speed = SpeedSetting.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.stopOnCompletion = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FollowPathTask {
    return {
      path: isSet(object.path) ? LineString.fromJSON(object.path) : undefined,
      speed: isSet(object.speed) ? SpeedSetting.fromJSON(object.speed) : undefined,
      stopOnCompletion: isSet(object.stopOnCompletion) ? globalThis.Boolean(object.stopOnCompletion) : false,
    };
  },

  toJSON(message: FollowPathTask): unknown {
    const obj: any = {};
    if (message.path !== undefined) {
      obj.path = LineString.toJSON(message.path);
    }
    if (message.speed !== undefined) {
      obj.speed = SpeedSetting.toJSON(message.speed);
    }
    if (message.stopOnCompletion !== false) {
      obj.stopOnCompletion = message.stopOnCompletion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowPathTask>, I>>(base?: I): FollowPathTask {
    return FollowPathTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowPathTask>, I>>(object: I): FollowPathTask {
    const message = createBaseFollowPathTask();
    message.path = (object.path !== undefined && object.path !== null)
      ? LineString.fromPartial(object.path)
      : undefined;
    message.speed = (object.speed !== undefined && object.speed !== null)
      ? SpeedSetting.fromPartial(object.speed)
      : undefined;
    message.stopOnCompletion = object.stopOnCompletion ?? false;
    return message;
  },
};

function createBaseSpeedSetting(): SpeedSetting {
  return { constantMph: undefined, remoteOperatorControlled: undefined, implementControlled: undefined };
}

export const SpeedSetting: MessageFns<SpeedSetting> = {
  encode(message: SpeedSetting, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.constantMph !== undefined) {
      writer.uint32(9).double(message.constantMph);
    }
    if (message.remoteOperatorControlled !== undefined) {
      Empty.encode(message.remoteOperatorControlled, writer.uint32(18).fork()).join();
    }
    if (message.implementControlled !== undefined) {
      Empty.encode(message.implementControlled, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SpeedSetting {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpeedSetting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.constantMph = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.remoteOperatorControlled = Empty.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.implementControlled = Empty.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpeedSetting {
    return {
      constantMph: isSet(object.constantMph) ? globalThis.Number(object.constantMph) : undefined,
      remoteOperatorControlled: isSet(object.remoteOperatorControlled)
        ? Empty.fromJSON(object.remoteOperatorControlled)
        : undefined,
      implementControlled: isSet(object.implementControlled) ? Empty.fromJSON(object.implementControlled) : undefined,
    };
  },

  toJSON(message: SpeedSetting): unknown {
    const obj: any = {};
    if (message.constantMph !== undefined) {
      obj.constantMph = message.constantMph;
    }
    if (message.remoteOperatorControlled !== undefined) {
      obj.remoteOperatorControlled = Empty.toJSON(message.remoteOperatorControlled);
    }
    if (message.implementControlled !== undefined) {
      obj.implementControlled = Empty.toJSON(message.implementControlled);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpeedSetting>, I>>(base?: I): SpeedSetting {
    return SpeedSetting.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpeedSetting>, I>>(object: I): SpeedSetting {
    const message = createBaseSpeedSetting();
    message.constantMph = object.constantMph ?? undefined;
    message.remoteOperatorControlled =
      (object.remoteOperatorControlled !== undefined && object.remoteOperatorControlled !== null)
        ? Empty.fromPartial(object.remoteOperatorControlled)
        : undefined;
    message.implementControlled = (object.implementControlled !== undefined && object.implementControlled !== null)
      ? Empty.fromPartial(object.implementControlled)
      : undefined;
    return message;
  },
};

function createBaseSetTractorStateTask(): SetTractorStateTask {
  return { state: [] };
}

export const SetTractorStateTask: MessageFns<SetTractorStateTask> = {
  encode(message: SetTractorStateTask, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.state) {
      TractorState.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetTractorStateTask {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTractorStateTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.state.push(TractorState.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetTractorStateTask {
    return {
      state: globalThis.Array.isArray(object?.state) ? object.state.map((e: any) => TractorState.fromJSON(e)) : [],
    };
  },

  toJSON(message: SetTractorStateTask): unknown {
    const obj: any = {};
    if (message.state?.length) {
      obj.state = message.state.map((e) => TractorState.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetTractorStateTask>, I>>(base?: I): SetTractorStateTask {
    return SetTractorStateTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetTractorStateTask>, I>>(object: I): SetTractorStateTask {
    const message = createBaseSetTractorStateTask();
    message.state = object.state?.map((e) => TractorState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTractorState(): TractorState {
  return { gear: undefined, hitch: undefined };
}

export const TractorState: MessageFns<TractorState> = {
  encode(message: TractorState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.gear !== undefined) {
      writer.uint32(8).int32(message.gear);
    }
    if (message.hitch !== undefined) {
      HitchState.encode(message.hitch, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TractorState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTractorState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.gear = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hitch = HitchState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TractorState {
    return {
      gear: isSet(object.gear) ? tractorState_GearFromJSON(object.gear) : undefined,
      hitch: isSet(object.hitch) ? HitchState.fromJSON(object.hitch) : undefined,
    };
  },

  toJSON(message: TractorState): unknown {
    const obj: any = {};
    if (message.gear !== undefined) {
      obj.gear = tractorState_GearToJSON(message.gear);
    }
    if (message.hitch !== undefined) {
      obj.hitch = HitchState.toJSON(message.hitch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TractorState>, I>>(base?: I): TractorState {
    return TractorState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TractorState>, I>>(object: I): TractorState {
    const message = createBaseTractorState();
    message.gear = object.gear ?? undefined;
    message.hitch = (object.hitch !== undefined && object.hitch !== null)
      ? HitchState.fromPartial(object.hitch)
      : undefined;
    return message;
  },
};

function createBaseHitchState(): HitchState {
  return { command: undefined, position: undefined };
}

export const HitchState: MessageFns<HitchState> = {
  encode(message: HitchState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.command !== undefined) {
      writer.uint32(8).int32(message.command);
    }
    if (message.position !== undefined) {
      writer.uint32(17).double(message.position);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HitchState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHitchState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.command = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.position = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HitchState {
    return {
      command: isSet(object.command) ? hitchState_HitchCommandFromJSON(object.command) : undefined,
      position: isSet(object.position) ? globalThis.Number(object.position) : undefined,
    };
  },

  toJSON(message: HitchState): unknown {
    const obj: any = {};
    if (message.command !== undefined) {
      obj.command = hitchState_HitchCommandToJSON(message.command);
    }
    if (message.position !== undefined) {
      obj.position = message.position;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HitchState>, I>>(base?: I): HitchState {
    return HitchState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HitchState>, I>>(object: I): HitchState {
    const message = createBaseHitchState();
    message.command = object.command ?? undefined;
    message.position = object.position ?? undefined;
    return message;
  },
};

function createBaseGetTaskRequest(): GetTaskRequest {
  return { id: 0 };
}

export const GetTaskRequest: MessageFns<GetTaskRequest> = {
  encode(message: GetTaskRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTaskRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTaskRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetTaskRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTaskRequest>, I>>(base?: I): GetTaskRequest {
    return GetTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTaskRequest>, I>>(object: I): GetTaskRequest {
    const message = createBaseGetTaskRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseCreateTaskRequest(): CreateTaskRequest {
  return { task: undefined };
}

export const CreateTaskRequest: MessageFns<CreateTaskRequest> = {
  encode(message: CreateTaskRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateTaskRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTaskRequest {
    return { task: isSet(object.task) ? Task.fromJSON(object.task) : undefined };
  },

  toJSON(message: CreateTaskRequest): unknown {
    const obj: any = {};
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTaskRequest>, I>>(base?: I): CreateTaskRequest {
    return CreateTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTaskRequest>, I>>(object: I): CreateTaskRequest {
    const message = createBaseCreateTaskRequest();
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    return message;
  },
};

function createBaseInsertTaskRequest(): InsertTaskRequest {
  return { parentTaskId: 0, precedingTaskId: 0, task: undefined };
}

export const InsertTaskRequest: MessageFns<InsertTaskRequest> = {
  encode(message: InsertTaskRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parentTaskId !== 0) {
      writer.uint32(8).uint64(message.parentTaskId);
    }
    if (message.precedingTaskId !== 0) {
      writer.uint32(16).uint64(message.precedingTaskId);
    }
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InsertTaskRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInsertTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.parentTaskId = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.precedingTaskId = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InsertTaskRequest {
    return {
      parentTaskId: isSet(object.parentTaskId) ? globalThis.Number(object.parentTaskId) : 0,
      precedingTaskId: isSet(object.precedingTaskId) ? globalThis.Number(object.precedingTaskId) : 0,
      task: isSet(object.task) ? Task.fromJSON(object.task) : undefined,
    };
  },

  toJSON(message: InsertTaskRequest): unknown {
    const obj: any = {};
    if (message.parentTaskId !== 0) {
      obj.parentTaskId = Math.round(message.parentTaskId);
    }
    if (message.precedingTaskId !== 0) {
      obj.precedingTaskId = Math.round(message.precedingTaskId);
    }
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InsertTaskRequest>, I>>(base?: I): InsertTaskRequest {
    return InsertTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InsertTaskRequest>, I>>(object: I): InsertTaskRequest {
    const message = createBaseInsertTaskRequest();
    message.parentTaskId = object.parentTaskId ?? 0;
    message.precedingTaskId = object.precedingTaskId ?? 0;
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    return message;
  },
};

function createBaseUpdateTaskRequest(): UpdateTaskRequest {
  return { task: undefined };
}

export const UpdateTaskRequest: MessageFns<UpdateTaskRequest> = {
  encode(message: UpdateTaskRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateTaskRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTaskRequest {
    return { task: isSet(object.task) ? Task.fromJSON(object.task) : undefined };
  },

  toJSON(message: UpdateTaskRequest): unknown {
    const obj: any = {};
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTaskRequest>, I>>(base?: I): UpdateTaskRequest {
    return UpdateTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTaskRequest>, I>>(object: I): UpdateTaskRequest {
    const message = createBaseUpdateTaskRequest();
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    return message;
  },
};

function createBaseDeleteTaskRequest(): DeleteTaskRequest {
  return { id: 0 };
}

export const DeleteTaskRequest: MessageFns<DeleteTaskRequest> = {
  encode(message: DeleteTaskRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteTaskRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTaskRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: DeleteTaskRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTaskRequest>, I>>(base?: I): DeleteTaskRequest {
    return DeleteTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTaskRequest>, I>>(object: I): DeleteTaskRequest {
    const message = createBaseDeleteTaskRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseListTasksRequest(): ListTasksRequest {
  return {};
}

export const ListTasksRequest: MessageFns<ListTasksRequest> = {
  encode(_: ListTasksRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTasksRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTasksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListTasksRequest {
    return {};
  },

  toJSON(_: ListTasksRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListTasksRequest>, I>>(base?: I): ListTasksRequest {
    return ListTasksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTasksRequest>, I>>(_: I): ListTasksRequest {
    const message = createBaseListTasksRequest();
    return message;
  },
};

function createBaseTaskList(): TaskList {
  return { tasks: [] };
}

export const TaskList: MessageFns<TaskList> = {
  encode(message: TaskList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.tasks) {
      Task.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TaskList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tasks.push(Task.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskList {
    return { tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => Task.fromJSON(e)) : [] };
  },

  toJSON(message: TaskList): unknown {
    const obj: any = {};
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => Task.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskList>, I>>(base?: I): TaskList {
    return TaskList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TaskList>, I>>(object: I): TaskList {
    const message = createBaseTaskList();
    message.tasks = object.tasks?.map((e) => Task.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJob(): Job {
  return {
    id: 0,
    name: "",
    startedAt: undefined,
    endedAt: undefined,
    task: undefined,
    state: 0,
    robot: "",
    workOrderId: undefined,
    farmId: "",
    customerId: "",
  };
}

export const Job: MessageFns<Job> = {
  encode(message: Job, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(26).fork()).join();
    }
    if (message.endedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.endedAt), writer.uint32(34).fork()).join();
    }
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(42).fork()).join();
    }
    if (message.state !== 0) {
      writer.uint32(48).int32(message.state);
    }
    if (message.robot !== "") {
      writer.uint32(58).string(message.robot);
    }
    if (message.workOrderId !== undefined) {
      writer.uint32(64).uint64(message.workOrderId);
    }
    if (message.farmId !== "") {
      writer.uint32(74).string(message.farmId);
    }
    if (message.customerId !== "") {
      writer.uint32(82).string(message.customerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Job {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.robot = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.workOrderId = longToNumber(reader.uint64());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.farmId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Job {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      startedAt: isSet(object.startedAt) ? globalThis.String(object.startedAt) : undefined,
      endedAt: isSet(object.endedAt) ? globalThis.String(object.endedAt) : undefined,
      task: isSet(object.task) ? Task.fromJSON(object.task) : undefined,
      state: isSet(object.state) ? job_JobStateFromJSON(object.state) : 0,
      robot: isSet(object.robot) ? globalThis.String(object.robot) : "",
      workOrderId: isSet(object.workOrderId) ? globalThis.Number(object.workOrderId) : undefined,
      farmId: isSet(object.farmId) ? globalThis.String(object.farmId) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
    };
  },

  toJSON(message: Job): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt;
    }
    if (message.endedAt !== undefined) {
      obj.endedAt = message.endedAt;
    }
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    if (message.state !== 0) {
      obj.state = job_JobStateToJSON(message.state);
    }
    if (message.robot !== "") {
      obj.robot = message.robot;
    }
    if (message.workOrderId !== undefined) {
      obj.workOrderId = Math.round(message.workOrderId);
    }
    if (message.farmId !== "") {
      obj.farmId = message.farmId;
    }
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Job>, I>>(base?: I): Job {
    return Job.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Job>, I>>(object: I): Job {
    const message = createBaseJob();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.startedAt = object.startedAt ?? undefined;
    message.endedAt = object.endedAt ?? undefined;
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    message.state = object.state ?? 0;
    message.robot = object.robot ?? "";
    message.workOrderId = object.workOrderId ?? undefined;
    message.farmId = object.farmId ?? "";
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseGetJobRequest(): GetJobRequest {
  return { id: 0 };
}

export const GetJobRequest: MessageFns<GetJobRequest> = {
  encode(message: GetJobRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetJobRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetJobRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetJobRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetJobRequest>, I>>(base?: I): GetJobRequest {
    return GetJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetJobRequest>, I>>(object: I): GetJobRequest {
    const message = createBaseGetJobRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseCreateJobRequest(): CreateJobRequest {
  return { job: undefined };
}

export const CreateJobRequest: MessageFns<CreateJobRequest> = {
  encode(message: CreateJobRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.job !== undefined) {
      Job.encode(message.job, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateJobRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = Job.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateJobRequest {
    return { job: isSet(object.job) ? Job.fromJSON(object.job) : undefined };
  },

  toJSON(message: CreateJobRequest): unknown {
    const obj: any = {};
    if (message.job !== undefined) {
      obj.job = Job.toJSON(message.job);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateJobRequest>, I>>(base?: I): CreateJobRequest {
    return CreateJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateJobRequest>, I>>(object: I): CreateJobRequest {
    const message = createBaseCreateJobRequest();
    message.job = (object.job !== undefined && object.job !== null) ? Job.fromPartial(object.job) : undefined;
    return message;
  },
};

function createBaseUpdateJobRequest(): UpdateJobRequest {
  return { job: undefined };
}

export const UpdateJobRequest: MessageFns<UpdateJobRequest> = {
  encode(message: UpdateJobRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.job !== undefined) {
      Job.encode(message.job, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateJobRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = Job.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateJobRequest {
    return { job: isSet(object.job) ? Job.fromJSON(object.job) : undefined };
  },

  toJSON(message: UpdateJobRequest): unknown {
    const obj: any = {};
    if (message.job !== undefined) {
      obj.job = Job.toJSON(message.job);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateJobRequest>, I>>(base?: I): UpdateJobRequest {
    return UpdateJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateJobRequest>, I>>(object: I): UpdateJobRequest {
    const message = createBaseUpdateJobRequest();
    message.job = (object.job !== undefined && object.job !== null) ? Job.fromPartial(object.job) : undefined;
    return message;
  },
};

function createBaseDeleteJobRequest(): DeleteJobRequest {
  return { id: 0 };
}

export const DeleteJobRequest: MessageFns<DeleteJobRequest> = {
  encode(message: DeleteJobRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteJobRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteJobRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: DeleteJobRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteJobRequest>, I>>(base?: I): DeleteJobRequest {
    return DeleteJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteJobRequest>, I>>(object: I): DeleteJobRequest {
    const message = createBaseDeleteJobRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseListJobsRequest(): ListJobsRequest {
  return {};
}

export const ListJobsRequest: MessageFns<ListJobsRequest> = {
  encode(_: ListJobsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListJobsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListJobsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListJobsRequest {
    return {};
  },

  toJSON(_: ListJobsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListJobsRequest>, I>>(base?: I): ListJobsRequest {
    return ListJobsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListJobsRequest>, I>>(_: I): ListJobsRequest {
    const message = createBaseListJobsRequest();
    return message;
  },
};

function createBaseJobList(): JobList {
  return { jobs: [] };
}

export const JobList: MessageFns<JobList> = {
  encode(message: JobList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.jobs) {
      Job.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(Job.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobList {
    return { jobs: globalThis.Array.isArray(object?.jobs) ? object.jobs.map((e: any) => Job.fromJSON(e)) : [] };
  },

  toJSON(message: JobList): unknown {
    const obj: any = {};
    if (message.jobs?.length) {
      obj.jobs = message.jobs.map((e) => Job.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobList>, I>>(base?: I): JobList {
    return JobList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobList>, I>>(object: I): JobList {
    const message = createBaseJobList();
    message.jobs = object.jobs?.map((e) => Job.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWorkOrder(): WorkOrder {
  return { id: 0, name: "", scheduledAt: undefined, duration: undefined, jobs: [], state: 0 };
}

export const WorkOrder: MessageFns<WorkOrder> = {
  encode(message: WorkOrder, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.scheduledAt !== undefined) {
      Timestamp.encode(toTimestamp(message.scheduledAt), writer.uint32(26).fork()).join();
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(34).fork()).join();
    }
    for (const v of message.jobs) {
      Job.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.state !== 0) {
      writer.uint32(48).int32(message.state);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkOrder {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.jobs.push(Job.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkOrder {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      scheduledAt: isSet(object.scheduledAt) ? globalThis.String(object.scheduledAt) : undefined,
      duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
      jobs: globalThis.Array.isArray(object?.jobs) ? object.jobs.map((e: any) => Job.fromJSON(e)) : [],
      state: isSet(object.state) ? workOrder_WorkOrderStateFromJSON(object.state) : 0,
    };
  },

  toJSON(message: WorkOrder): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.scheduledAt !== undefined) {
      obj.scheduledAt = message.scheduledAt;
    }
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration);
    }
    if (message.jobs?.length) {
      obj.jobs = message.jobs.map((e) => Job.toJSON(e));
    }
    if (message.state !== 0) {
      obj.state = workOrder_WorkOrderStateToJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkOrder>, I>>(base?: I): WorkOrder {
    return WorkOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkOrder>, I>>(object: I): WorkOrder {
    const message = createBaseWorkOrder();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.scheduledAt = object.scheduledAt ?? undefined;
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.jobs = object.jobs?.map((e) => Job.fromPartial(e)) || [];
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseGetWorkOrderRequest(): GetWorkOrderRequest {
  return { id: 0 };
}

export const GetWorkOrderRequest: MessageFns<GetWorkOrderRequest> = {
  encode(message: GetWorkOrderRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetWorkOrderRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWorkOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWorkOrderRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetWorkOrderRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWorkOrderRequest>, I>>(base?: I): GetWorkOrderRequest {
    return GetWorkOrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWorkOrderRequest>, I>>(object: I): GetWorkOrderRequest {
    const message = createBaseGetWorkOrderRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseCreateWorkOrderRequest(): CreateWorkOrderRequest {
  return { workOrder: undefined };
}

export const CreateWorkOrderRequest: MessageFns<CreateWorkOrderRequest> = {
  encode(message: CreateWorkOrderRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.workOrder !== undefined) {
      WorkOrder.encode(message.workOrder, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateWorkOrderRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWorkOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workOrder = WorkOrder.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWorkOrderRequest {
    return { workOrder: isSet(object.workOrder) ? WorkOrder.fromJSON(object.workOrder) : undefined };
  },

  toJSON(message: CreateWorkOrderRequest): unknown {
    const obj: any = {};
    if (message.workOrder !== undefined) {
      obj.workOrder = WorkOrder.toJSON(message.workOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWorkOrderRequest>, I>>(base?: I): CreateWorkOrderRequest {
    return CreateWorkOrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateWorkOrderRequest>, I>>(object: I): CreateWorkOrderRequest {
    const message = createBaseCreateWorkOrderRequest();
    message.workOrder = (object.workOrder !== undefined && object.workOrder !== null)
      ? WorkOrder.fromPartial(object.workOrder)
      : undefined;
    return message;
  },
};

function createBaseWorkOrderAddJobsRequest(): WorkOrderAddJobsRequest {
  return { workOrderId: 0, jobId: [] };
}

export const WorkOrderAddJobsRequest: MessageFns<WorkOrderAddJobsRequest> = {
  encode(message: WorkOrderAddJobsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.workOrderId !== 0) {
      writer.uint32(8).uint64(message.workOrderId);
    }
    writer.uint32(18).fork();
    for (const v of message.jobId) {
      writer.uint64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkOrderAddJobsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkOrderAddJobsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.workOrderId = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag === 16) {
            message.jobId.push(longToNumber(reader.uint64()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.jobId.push(longToNumber(reader.uint64()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkOrderAddJobsRequest {
    return {
      workOrderId: isSet(object.workOrderId) ? globalThis.Number(object.workOrderId) : 0,
      jobId: globalThis.Array.isArray(object?.jobId) ? object.jobId.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: WorkOrderAddJobsRequest): unknown {
    const obj: any = {};
    if (message.workOrderId !== 0) {
      obj.workOrderId = Math.round(message.workOrderId);
    }
    if (message.jobId?.length) {
      obj.jobId = message.jobId.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkOrderAddJobsRequest>, I>>(base?: I): WorkOrderAddJobsRequest {
    return WorkOrderAddJobsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkOrderAddJobsRequest>, I>>(object: I): WorkOrderAddJobsRequest {
    const message = createBaseWorkOrderAddJobsRequest();
    message.workOrderId = object.workOrderId ?? 0;
    message.jobId = object.jobId?.map((e) => e) || [];
    return message;
  },
};

function createBaseWorkOrderRemoveJobsRequest(): WorkOrderRemoveJobsRequest {
  return { workOrderId: 0, jobId: [] };
}

export const WorkOrderRemoveJobsRequest: MessageFns<WorkOrderRemoveJobsRequest> = {
  encode(message: WorkOrderRemoveJobsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.workOrderId !== 0) {
      writer.uint32(8).uint64(message.workOrderId);
    }
    writer.uint32(18).fork();
    for (const v of message.jobId) {
      writer.uint64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkOrderRemoveJobsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkOrderRemoveJobsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.workOrderId = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag === 16) {
            message.jobId.push(longToNumber(reader.uint64()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.jobId.push(longToNumber(reader.uint64()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkOrderRemoveJobsRequest {
    return {
      workOrderId: isSet(object.workOrderId) ? globalThis.Number(object.workOrderId) : 0,
      jobId: globalThis.Array.isArray(object?.jobId) ? object.jobId.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: WorkOrderRemoveJobsRequest): unknown {
    const obj: any = {};
    if (message.workOrderId !== 0) {
      obj.workOrderId = Math.round(message.workOrderId);
    }
    if (message.jobId?.length) {
      obj.jobId = message.jobId.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkOrderRemoveJobsRequest>, I>>(base?: I): WorkOrderRemoveJobsRequest {
    return WorkOrderRemoveJobsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkOrderRemoveJobsRequest>, I>>(object: I): WorkOrderRemoveJobsRequest {
    const message = createBaseWorkOrderRemoveJobsRequest();
    message.workOrderId = object.workOrderId ?? 0;
    message.jobId = object.jobId?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateWorkOrderRequest(): UpdateWorkOrderRequest {
  return { workOrder: undefined };
}

export const UpdateWorkOrderRequest: MessageFns<UpdateWorkOrderRequest> = {
  encode(message: UpdateWorkOrderRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.workOrder !== undefined) {
      WorkOrder.encode(message.workOrder, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateWorkOrderRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWorkOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workOrder = WorkOrder.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateWorkOrderRequest {
    return { workOrder: isSet(object.workOrder) ? WorkOrder.fromJSON(object.workOrder) : undefined };
  },

  toJSON(message: UpdateWorkOrderRequest): unknown {
    const obj: any = {};
    if (message.workOrder !== undefined) {
      obj.workOrder = WorkOrder.toJSON(message.workOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateWorkOrderRequest>, I>>(base?: I): UpdateWorkOrderRequest {
    return UpdateWorkOrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateWorkOrderRequest>, I>>(object: I): UpdateWorkOrderRequest {
    const message = createBaseUpdateWorkOrderRequest();
    message.workOrder = (object.workOrder !== undefined && object.workOrder !== null)
      ? WorkOrder.fromPartial(object.workOrder)
      : undefined;
    return message;
  },
};

function createBaseDeleteWorkOrderRequest(): DeleteWorkOrderRequest {
  return { id: 0 };
}

export const DeleteWorkOrderRequest: MessageFns<DeleteWorkOrderRequest> = {
  encode(message: DeleteWorkOrderRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteWorkOrderRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteWorkOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteWorkOrderRequest {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: DeleteWorkOrderRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteWorkOrderRequest>, I>>(base?: I): DeleteWorkOrderRequest {
    return DeleteWorkOrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteWorkOrderRequest>, I>>(object: I): DeleteWorkOrderRequest {
    const message = createBaseDeleteWorkOrderRequest();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseListWorkOrdersRequest(): ListWorkOrdersRequest {
  return {};
}

export const ListWorkOrdersRequest: MessageFns<ListWorkOrdersRequest> = {
  encode(_: ListWorkOrdersRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListWorkOrdersRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWorkOrdersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListWorkOrdersRequest {
    return {};
  },

  toJSON(_: ListWorkOrdersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListWorkOrdersRequest>, I>>(base?: I): ListWorkOrdersRequest {
    return ListWorkOrdersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListWorkOrdersRequest>, I>>(_: I): ListWorkOrdersRequest {
    const message = createBaseListWorkOrdersRequest();
    return message;
  },
};

function createBaseWorkOrderList(): WorkOrderList {
  return { workOrders: [] };
}

export const WorkOrderList: MessageFns<WorkOrderList> = {
  encode(message: WorkOrderList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.workOrders) {
      WorkOrder.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkOrderList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkOrderList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workOrders.push(WorkOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkOrderList {
    return {
      workOrders: globalThis.Array.isArray(object?.workOrders)
        ? object.workOrders.map((e: any) => WorkOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WorkOrderList): unknown {
    const obj: any = {};
    if (message.workOrders?.length) {
      obj.workOrders = message.workOrders.map((e) => WorkOrder.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkOrderList>, I>>(base?: I): WorkOrderList {
    return WorkOrderList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkOrderList>, I>>(object: I): WorkOrderList {
    const message = createBaseWorkOrderList();
    message.workOrders = object.workOrders?.map((e) => WorkOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIntervention(): Intervention {
  return { id: 0, taskId: 0, qualification: "", description: "", state: 0, robotSerial: "", jobId: 0, cause: 0 };
}

export const Intervention: MessageFns<Intervention> = {
  encode(message: Intervention, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.taskId !== 0) {
      writer.uint32(16).uint64(message.taskId);
    }
    if (message.qualification !== "") {
      writer.uint32(26).string(message.qualification);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.state !== 0) {
      writer.uint32(40).int32(message.state);
    }
    if (message.robotSerial !== "") {
      writer.uint32(50).string(message.robotSerial);
    }
    if (message.jobId !== 0) {
      writer.uint32(56).uint64(message.jobId);
    }
    if (message.cause !== 0) {
      writer.uint32(64).int32(message.cause);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Intervention {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntervention();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.taskId = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.qualification = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.robotSerial = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.jobId = longToNumber(reader.uint64());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.cause = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Intervention {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      taskId: isSet(object.taskId) ? globalThis.Number(object.taskId) : 0,
      qualification: isSet(object.qualification) ? globalThis.String(object.qualification) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      state: isSet(object.state) ? intervention_InterventionStateFromJSON(object.state) : 0,
      robotSerial: isSet(object.robotSerial) ? globalThis.String(object.robotSerial) : "",
      jobId: isSet(object.jobId) ? globalThis.Number(object.jobId) : 0,
      cause: isSet(object.cause) ? intervention_InterventionCauseFromJSON(object.cause) : 0,
    };
  },

  toJSON(message: Intervention): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.taskId !== 0) {
      obj.taskId = Math.round(message.taskId);
    }
    if (message.qualification !== "") {
      obj.qualification = message.qualification;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.state !== 0) {
      obj.state = intervention_InterventionStateToJSON(message.state);
    }
    if (message.robotSerial !== "") {
      obj.robotSerial = message.robotSerial;
    }
    if (message.jobId !== 0) {
      obj.jobId = Math.round(message.jobId);
    }
    if (message.cause !== 0) {
      obj.cause = intervention_InterventionCauseToJSON(message.cause);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Intervention>, I>>(base?: I): Intervention {
    return Intervention.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Intervention>, I>>(object: I): Intervention {
    const message = createBaseIntervention();
    message.id = object.id ?? 0;
    message.taskId = object.taskId ?? 0;
    message.qualification = object.qualification ?? "";
    message.description = object.description ?? "";
    message.state = object.state ?? 0;
    message.robotSerial = object.robotSerial ?? "";
    message.jobId = object.jobId ?? 0;
    message.cause = object.cause ?? 0;
    return message;
  },
};

function createBaseCreateInterventionRequest(): CreateInterventionRequest {
  return { intervention: undefined };
}

export const CreateInterventionRequest: MessageFns<CreateInterventionRequest> = {
  encode(message: CreateInterventionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.intervention !== undefined) {
      Intervention.encode(message.intervention, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateInterventionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateInterventionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intervention = Intervention.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateInterventionRequest {
    return { intervention: isSet(object.intervention) ? Intervention.fromJSON(object.intervention) : undefined };
  },

  toJSON(message: CreateInterventionRequest): unknown {
    const obj: any = {};
    if (message.intervention !== undefined) {
      obj.intervention = Intervention.toJSON(message.intervention);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateInterventionRequest>, I>>(base?: I): CreateInterventionRequest {
    return CreateInterventionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateInterventionRequest>, I>>(object: I): CreateInterventionRequest {
    const message = createBaseCreateInterventionRequest();
    message.intervention = (object.intervention !== undefined && object.intervention !== null)
      ? Intervention.fromPartial(object.intervention)
      : undefined;
    return message;
  },
};

function createBaseAcknowledgeInterventionRequest(): AcknowledgeInterventionRequest {
  return { intervention: undefined };
}

export const AcknowledgeInterventionRequest: MessageFns<AcknowledgeInterventionRequest> = {
  encode(message: AcknowledgeInterventionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.intervention !== undefined) {
      Intervention.encode(message.intervention, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AcknowledgeInterventionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcknowledgeInterventionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intervention = Intervention.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeInterventionRequest {
    return { intervention: isSet(object.intervention) ? Intervention.fromJSON(object.intervention) : undefined };
  },

  toJSON(message: AcknowledgeInterventionRequest): unknown {
    const obj: any = {};
    if (message.intervention !== undefined) {
      obj.intervention = Intervention.toJSON(message.intervention);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AcknowledgeInterventionRequest>, I>>(base?: I): AcknowledgeInterventionRequest {
    return AcknowledgeInterventionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcknowledgeInterventionRequest>, I>>(
    object: I,
  ): AcknowledgeInterventionRequest {
    const message = createBaseAcknowledgeInterventionRequest();
    message.intervention = (object.intervention !== undefined && object.intervention !== null)
      ? Intervention.fromPartial(object.intervention)
      : undefined;
    return message;
  },
};

function createBaseCompleteInterventionRequest(): CompleteInterventionRequest {
  return { state: 0, intervention: undefined };
}

export const CompleteInterventionRequest: MessageFns<CompleteInterventionRequest> = {
  encode(message: CompleteInterventionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.state !== 0) {
      writer.uint32(8).int32(message.state);
    }
    if (message.intervention !== undefined) {
      Intervention.encode(message.intervention, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CompleteInterventionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteInterventionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.intervention = Intervention.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteInterventionRequest {
    return {
      state: isSet(object.state) ? completeInterventionRequest_CompletionStateFromJSON(object.state) : 0,
      intervention: isSet(object.intervention) ? Intervention.fromJSON(object.intervention) : undefined,
    };
  },

  toJSON(message: CompleteInterventionRequest): unknown {
    const obj: any = {};
    if (message.state !== 0) {
      obj.state = completeInterventionRequest_CompletionStateToJSON(message.state);
    }
    if (message.intervention !== undefined) {
      obj.intervention = Intervention.toJSON(message.intervention);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteInterventionRequest>, I>>(base?: I): CompleteInterventionRequest {
    return CompleteInterventionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteInterventionRequest>, I>>(object: I): CompleteInterventionRequest {
    const message = createBaseCompleteInterventionRequest();
    message.state = object.state ?? 0;
    message.intervention = (object.intervention !== undefined && object.intervention !== null)
      ? Intervention.fromPartial(object.intervention)
      : undefined;
    return message;
  },
};

function createBaseSubscriptionRequest(): SubscriptionRequest {
  return {};
}

export const SubscriptionRequest: MessageFns<SubscriptionRequest> = {
  encode(_: SubscriptionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SubscriptionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SubscriptionRequest {
    return {};
  },

  toJSON(_: SubscriptionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionRequest>, I>>(base?: I): SubscriptionRequest {
    return SubscriptionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionRequest>, I>>(_: I): SubscriptionRequest {
    const message = createBaseSubscriptionRequest();
    return message;
  },
};

function createBaseSubscriptionResponse(): SubscriptionResponse {
  return { intervention: undefined };
}

export const SubscriptionResponse: MessageFns<SubscriptionResponse> = {
  encode(message: SubscriptionResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.intervention !== undefined) {
      Intervention.encode(message.intervention, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SubscriptionResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intervention = Intervention.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionResponse {
    return { intervention: isSet(object.intervention) ? Intervention.fromJSON(object.intervention) : undefined };
  },

  toJSON(message: SubscriptionResponse): unknown {
    const obj: any = {};
    if (message.intervention !== undefined) {
      obj.intervention = Intervention.toJSON(message.intervention);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionResponse>, I>>(base?: I): SubscriptionResponse {
    return SubscriptionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionResponse>, I>>(object: I): SubscriptionResponse {
    const message = createBaseSubscriptionResponse();
    message.intervention = (object.intervention !== undefined && object.intervention !== null)
      ? Intervention.fromPartial(object.intervention)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
