import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import {
  BLUE_LOADING_BUTTON,
  INPUT_DARK,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { boolean, object, string } from "yup";
import { buildPermission } from "portal/utils/auth";
import { capitalize, titleCase } from "portal/utils/strings";
import { CheckboxWithLabel, Select, TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import { getClass } from "portal/utils/robots";
import { getRobotPath, RobotSubpath } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { isNotNil } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { TEMPLATE_BY_CLASS, TemplateSerial } from "portal/utils/configs";
import {
  useCreateRobotMutation,
  useListRobotsQuery,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useState } from "react";

interface NewRobotProps {
  hotkey?: string;
}

const _NewRobot: FunctionComponent<NewRobotProps> = ({ hotkey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [createRobot] = useMutationPopups(useCreateRobotMutation(), {
    success: capitalize(
      t("utils.actions.createdLong", {
        subject: t("models.robots.robot_one"),
      })
    ),
  });

  const { data: summaries, isLoading } = useQueryPopups(
    useListRobotsQuery({}, { skip: !isOpen })
  );

  return (
    <>
      {hotkey && (
        <GlobalHotKeys
          keyMap={{
            NEW_ROBOT: {
              name: titleCase(
                t("utils.actions.newLong", {
                  subject: t("models.robots.robot_one"),
                })
              ),
              action: "keyup",
              sequence: hotkey,
            },
          }}
          handlers={{
            NEW_ROBOT: () => setOpen(true),
          }}
        />
      )}
      <Button
        className="text-white"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        <span className="hidden sm:inline">
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.robots.robot_one"),
            })
          )}
        </span>
        <span className="sm:hidden">{t("utils.actions.new")}</span>
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.robots.robot_one"),
            })
          )}
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            serial: "",
            copyFrom: TemplateSerial.SLAYER,
            ignoreConfig: false,
          }}
          validationSchema={object({
            serial: string()
              .required(t("utils.form.required"))
              .notOneOf(
                summaries
                  ?.map((summary) => summary.robot?.serial)
                  .filter((summary) => isNotNil(summary)) ?? [],
                t("components.robots.dialogs.new.errors.exists")
              ),
            copyFrom: string(),
            ignoreConfig: boolean(),
          })}
          onSubmit={async ({ ignoreConfig, serial, copyFrom }) => {
            if (copyFrom === TemplateSerial.SLAYER) {
              copyFrom = TEMPLATE_BY_CLASS[getClass(serial)];
            }
            await createRobot({
              serial,
              copyFrom: ignoreConfig ? "" : copyFrom,
            }).unwrap();
            navigate(getRobotPath(serial, RobotSubpath.CONFIG));
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <DialogContent className="flex flex-col gap-4 pt-2">
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  name="serial"
                  label={t("models.robots.fields.serial")}
                />
                <Field
                  component={CheckboxWithLabel}
                  Label={{
                    label: t(
                      "components.robots.dialogs.new.fields.ignoreConfig"
                    ),
                  }}
                  name="ignoreConfig"
                />
                <Field
                  {...SELECT_DARK}
                  component={Select}
                  name="copyFrom"
                  label={t("components.robots.dialogs.new.fields.copyFrom")}
                  className="min-w-48"
                  input={
                    <OutlinedInput
                      {...INPUT_DARK}
                      label={t("components.robots.dialogs.new.fields.copyFrom")}
                    />
                  }
                  disabled={values.ignoreConfig}
                >
                  <MenuItem value={TemplateSerial.SLAYER}>
                    {t("components.robots.dialogs.new.template")}
                  </MenuItem>
                  {isLoading && (
                    <MenuItem disabled value={""}>
                      {t("components.Loading.placeholder")}
                    </MenuItem>
                  )}
                  {summaries?.map(({ robot }) => (
                    <MenuItem value={robot?.serial} key={robot?.serial}>
                      {robot?.serial}
                    </MenuItem>
                  ))}
                </Field>
                {values.ignoreConfig && (
                  <Alert severity="warning">
                    {t("components.robots.dialogs.new.warnings.ignoreConfig", {
                      serial: values.serial,
                    })}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!values.serial}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={<AddIcon />}
                >
                  {t("utils.actions.create")}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export const NewRobot = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.update,
      PermissionResource.robots,
      PermissionDomain.all
    ),
  ],
  _NewRobot
);
