import { AdminAlarms } from "./AdminAlarms";
import { AdminConfigs } from "./AdminConfigs";
import { AdminPortal } from "./AdminPortal";
import { AdminSubpath, getAdminPath } from "portal/utils/routing";
import { AlmanacList } from "portal/components/almanac/AlmanacList";
import { buildPermission } from "portal/utils/auth";
import { CategoryCollectionProfileList } from "portal/components/categoryCollectionProfile/CategoryCollectionProfileList";
import { Header } from "portal/components/header/Header";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { RobotClass } from "portal/utils/robots";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import {
  useDeleteGlobalAlmanacMutation,
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useGetGlobalAlmanacQuery,
  useGetGlobalCategoryCollectionProfileQuery,
  useGetGlobalTargetVelocityEstimatorQuery,
  useListGlobalAlmanacsQuery,
  useListGlobalCategoryCollectionProfilesQuery,
  useListGlobalTargetVelocityEstimatorsQuery,
  useSetGlobalAlmanacMutation,
  useSetGlobalCategoryCollectionProfileMutation,
  useSetGlobalTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import { useTranslation } from "react-i18next";
import { VelocityEstimatorList } from "portal/components/velocityEstimator/VelocityEstimatorList";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useState } from "react";

const _Admin: FunctionComponent = () => {
  const { t } = useTranslation();

  const [subtitle, setSubtitle] = useState<string>(t("views.admin.title"));
  const routes: RouteObject[] = [];

  const isConfigTemplatesAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.templates
    ),
  ]);
  if (isConfigTemplatesAuthorized) {
    routes.push(
      {
        path: `${AdminSubpath.CONFIG}/:class/*`,
        element: <AdminConfigs />,
      },
      {
        path: `${AdminSubpath.CONFIG}`,
        element: (
          <Navigate
            to={`${getAdminPath(AdminSubpath.CONFIG)}/${RobotClass.Slayers}`}
            replace
          />
        ),
      }
    );
  }

  const isAlarmsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_alarms,
      PermissionDomain.all
    ),
  ]);
  if (isAlarmsAuthorized) {
    routes.push({ path: AdminSubpath.ALARMS, element: <AdminAlarms /> });
  }

  const isAlmanacsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.almanacs,
      PermissionDomain.templates
    ),
  ]);
  if (isAlmanacsAuthorized) {
    routes.push({
      path: `${AdminSubpath.ALMANAC}/*`,
      element: (
        <AlmanacList
          adminEditing
          basePath={getAdminPath(AdminSubpath.ALMANAC)}
          useListQuery={useListGlobalAlmanacsQuery}
          useGetQuery={useGetGlobalAlmanacQuery}
          useDeleteMutation={useDeleteGlobalAlmanacMutation}
          useSetMutation={useSetGlobalAlmanacMutation}
        />
      ),
    });
  }

  const isVelocityEstimatorsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.velocity_estimators,
      PermissionDomain.templates
    ),
  ]);
  if (isVelocityEstimatorsAuthorized) {
    routes.push({
      path: `${AdminSubpath.VELOCITY_ESTIMATOR}/*`,
      element: (
        <VelocityEstimatorList
          adminEditing
          basePath={getAdminPath(AdminSubpath.VELOCITY_ESTIMATOR)}
          useListQuery={useListGlobalTargetVelocityEstimatorsQuery}
          useGetQuery={useGetGlobalTargetVelocityEstimatorQuery}
          useSetMutation={useSetGlobalTargetVelocityEstimatorMutation}
          useDeleteMutation={useDeleteGlobalTargetVelocityEstimatorMutation}
        />
      ),
    });
  }

  const isCategoryCollectionProfilesAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.plant_category_profiles,
      PermissionDomain.templates
    ),
  ]);
  if (isCategoryCollectionProfilesAuthorized) {
    routes.push({
      path: `${AdminSubpath.CATEGORY_COLLECTION_PROFILE}/*`,
      element: (
        <CategoryCollectionProfileList
          adminEditing
          basePath={getAdminPath(AdminSubpath.CATEGORY_COLLECTION_PROFILE)}
          useListQuery={useListGlobalCategoryCollectionProfilesQuery}
          useGetQuery={useGetGlobalCategoryCollectionProfileQuery}
          useSetMutation={useSetGlobalCategoryCollectionProfileMutation}
          // TODO: uncomment when we support deleting a profile
          // useDeleteMutation={useDeleteGlobalCategoryCollectionProfileMutation}
        />
      ),
    });
  }

  const isPortalAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);
  if (isPortalAuthorized) {
    routes.push({
      path: AdminSubpath.PORTAL,
      element: <AdminPortal />,
    });
  }

  // pick a default path based on what the user has access to
  let defaultPath: AdminSubpath = AdminSubpath.CONFIG;
  if (isConfigTemplatesAuthorized) {
    defaultPath = AdminSubpath.CONFIG;
  } else if (isAlarmsAuthorized) {
    defaultPath = AdminSubpath.ALARMS;
  } else if (isAlmanacsAuthorized) {
    defaultPath = AdminSubpath.ALMANAC;
  } else if (isVelocityEstimatorsAuthorized) {
    defaultPath = AdminSubpath.VELOCITY_ESTIMATOR;
  } else if (isCategoryCollectionProfilesAuthorized) {
    defaultPath = AdminSubpath.CATEGORY_COLLECTION_PROFILE;
  } else if (isPortalAuthorized) {
    defaultPath = AdminSubpath.PORTAL;
  }
  routes.push({
    path: "*",
    element: <Navigate to={getAdminPath(defaultPath)} replace />,
  });

  const element = useRoutes(routes);

  // if user has no access to child views, don't render anything
  if (
    !isConfigTemplatesAuthorized &&
    !isAlarmsAuthorized &&
    !isAlmanacsAuthorized &&
    !isVelocityEstimatorsAuthorized &&
    !isCategoryCollectionProfilesAuthorized &&
    !isPortalAuthorized
  ) {
    return;
  }

  return (
    <>
      <Header title={subtitle}>
        <ResponsiveSubnav
          onChange={(index, name) => setSubtitle(name)}
          pages={[
            {
              label: titleCase(t("models.configs.config_other")),
              to: `/admin${AdminSubpath.CONFIG}`,
              hidden: !isConfigTemplatesAuthorized,
            },
            {
              label: titleCase(t("models.alarms.alarm_other")),
              to: `/admin${AdminSubpath.ALARMS}`,
              hidden: !isAlarmsAuthorized,
            },
            {
              label: titleCase(t("models.almanacs.almanac_other")),
              to: `/admin${AdminSubpath.ALMANAC}`,
              hidden: !isAlmanacsAuthorized,
            },
            {
              label: titleCase(
                t("models.velocityEstimators.velocityEstimator_other")
              ),
              to: `/admin${AdminSubpath.VELOCITY_ESTIMATOR}`,
              hidden: !isVelocityEstimatorsAuthorized,
            },
            {
              label: titleCase(
                t(
                  "models.categoryCollectionProfiles.categoryCollectionProfile_other"
                )
              ),
              to: `/admin${AdminSubpath.CATEGORY_COLLECTION_PROFILE}`,
              hidden: !isCategoryCollectionProfilesAuthorized,
            },
            {
              label: titleCase(t("views.admin.portal.title")),
              to: `/admin${AdminSubpath.PORTAL}`,
              hidden: !isPortalAuthorized,
            },
          ]}
        />
      </Header>
      <Page>{element}</Page>
    </>
  );
};

export const Admin = withAuthenticationRequired(_Admin);
