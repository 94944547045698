import { Alert, Tab, Tabs } from "@mui/material";

import { AdminSubpath, getAdminPath } from "portal/utils/routing";
import { buildPermission } from "portal/utils/auth";
import { Config } from "portal/components/config/Config";
import {
  getTemplateSerial,
  RobotClass,
  toLocalizedRobotClass,
} from "portal/utils/robots";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { values } from "portal/utils/objects";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useEffect } from "react";

const ADMINS = new Set<string | undefined>([
  "scochrane@carbonrobotics.com",
  "brett@carbonrobotics.com ",
]);

const _AdminConfigs: FunctionComponent = () => {
  const { user, isInternal } = useSelf();
  const { class: inputClass } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!inputClass || !values(RobotClass).includes(inputClass as RobotClass)) {
      navigate(`${getAdminPath(AdminSubpath.CONFIG)}/${RobotClass.Slayers}`);
    }
  }, [inputClass, navigate]);
  const selected = inputClass as RobotClass;
  const { t } = useTranslation();
  const isWriteAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.configs,
      PermissionDomain.templates
    ),
  ]);

  return (
    <>
      {user && !ADMINS.has(user.email) && (
        <Alert severity="error" className="mb-4">
          {t("views.admin.config.warnings.notSimon")}
        </Alert>
      )}
      <Alert severity="warning" className="mb-8">
        {t("views.admin.config.warnings.global", {
          class: toLocalizedRobotClass(t, isInternal, selected),
        })}
      </Alert>
      <Tabs color="primary" className="mb-8" value={selected}>
        {[
          RobotClass.Slayers,
          RobotClass.Reapers,
          RobotClass.Rtcs,
          RobotClass.Simulators,
          RobotClass.Buds,
        ].map((robotClass) =>
          robotClass === RobotClass.Unknown ? undefined : (
            <Tab
              component={Link}
              key={robotClass}
              to={`${getAdminPath(AdminSubpath.CONFIG)}/${robotClass}`}
              value={robotClass}
              label={toLocalizedRobotClass(t, isInternal, robotClass)}
            />
          )
        )}
      </Tabs>
      {window._jsenv.REACT_APP_USE_ROBOT_SYNCER_TEMPLATES === "true" ? (
        <Config
          robotClass={selected}
          key={selected}
          basePath={`${getAdminPath(AdminSubpath.CONFIG)}/${selected}`}
          readOnly={isWriteAuthorized}
        />
      ) : (
        <Config
          serial={getTemplateSerial(selected)}
          key={getTemplateSerial(selected)}
          basePath={`${getAdminPath(AdminSubpath.CONFIG)}/${selected}`}
          readOnly={isWriteAuthorized}
        />
      )}
    </>
  );
};

export const AdminConfigs = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.configs,
        PermissionDomain.templates
      ),
    ],
    _AdminConfigs
  )
);
