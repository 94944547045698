import { Alert, Button } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { capitalize } from "portal/utils/strings";
import { CarbonCard } from "portal/components/CarbonCard";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RED_BUTTON } from "portal/utils/theme";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useDeleteCachesMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ClearCachesIcon from "@mui/icons-material/RestartAlt";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent } from "react";

const ADMINS = new Set<string | undefined>([
  "ansel@carbonrobotics.com",
  "benw@carbonrobotics.com",
  "wchargin@carbonrobotics.com",
]);

const _AdminPortal: FunctionComponent = () => {
  const { user } = useSelf();
  const { t } = useTranslation();

  const [clearCaches] = useMutationPopups(useDeleteCachesMutation(), {
    success: capitalize(t("utils.actions.updated")),
  });

  const canUpdate = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);

  return (
    <>
      {user && !ADMINS.has(user.email) && (
        <Alert severity="error" className="mb-4">
          {t("views.admin.portal.warnings.notPortalAdmin")}
        </Alert>
      )}
      <Alert severity="warning" className="mb-8">
        {t("views.admin.portal.warnings.global")}
      </Alert>
      <Grid container className="w-full items-stretch" spacing={2}>
        <CarbonCard
          title={t("views.admin.portal.clearCaches.action")}
          error={false}
          success={true}
          content={
            <p className="mt-0 mb-1">
              {t("views.admin.portal.clearCaches.description")}
            </p>
          }
          actions={
            <Button
              {...RED_BUTTON}
              onClick={() => clearCaches()}
              startIcon={<ClearCachesIcon />}
              disabled={!canUpdate}
            >
              {t("views.admin.portal.clearCaches.action")}
            </Button>
          }
        />
      </Grid>
    </>
  );
};

export const AdminPortal = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.update,
        PermissionResource.admin_cloud,
        PermissionDomain.all
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.admin_cloud,
        PermissionDomain.all
      ),
    ],
    _AdminPortal
  )
);
