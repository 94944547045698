import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
} from "@mui/material";
import { CarbonDateRangePicker } from "portal/components/CarbonDateRangePicker";
import { CertifiedMetrics } from "portal/components/CertifiedMetrics";
import { classes, TEXT_FIELD_DARK } from "portal/utils/theme";
import { DailyMetricResponse } from "protos/portal/metrics";
import { DATE_PATH_FORMAT, isToday } from "portal/utils/dates";
import { DateRange, TabContext, TabList, TabPanel } from "@mui/lab";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import {
  findWhere,
  Order,
  range,
  range as rangeArray,
  sortBy,
} from "portal/utils/arrays";
import { getRobotPath, RobotSubpath } from "portal/utils/routing";
import { isDay, isJob, JobSummary } from "portal/components/JobSummary";
import { isEqual } from "portal/utils/objects";
import { isNull, isUndefined } from "portal/utils/identity";
import { LOCALSTORAGE_MAP_BORDERS } from "portal/utils/localStorage";
import { Map } from "portal/components/map/Map";
import { PortalJob } from "protos/portal/jobs";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { skipToken } from "@reduxjs/toolkit/query";
import { titleCase } from "portal/utils/strings";
import {
  useGetRobotQuery,
  useLazyGetJobQuery,
  useLazyGetRobotMetricsQuery,
  useListRobotJobsQuery,
} from "portal/state/portalApi";
import { useLazyPopups, useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useRobotHistory } from "./useRobotHistory";
import { useTranslation } from "react-i18next";
import { ViewPlaceholder } from "portal/components/ViewPlaceholder";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithSkeleton } from "portal/components/WithSkeleton";
import DaysIcon from "@mui/icons-material/DateRangeOutlined";
import JobsIcon from "@mui/icons-material/AssignmentOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import MetricsIcon from "@mui/icons-material/SsidChartOutlined";
import NextIcon from "@mui/icons-material/ArrowForwardOutlined";
import PrevIcon from "@mui/icons-material/ArrowBackOutlined";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

enum JobsTab {
  JOBS = "jobs",
  DAYS = "days",
}

enum ContentTab {
  MAP = "map",
  METRICS = "metrics",
}

const getIsRangeValid = (
  range?: DateRange<DateTime>
): range is [DateTime, DateTime] =>
  isUndefined(range) ? false : !isNull(range[0]) && !isNull(range[1]);

const _RobotHistory: FunctionComponent = () => {
  const { isEnabled: hasSpatial } = useFeatureFlag(FeatureFlag.SPATIAL);
  const { t, i18n } = useTranslation();
  // generate base path
  const { serial, startDate, endDate } = useParams();

  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );

  const today = DateTime.local();

  const [showBorders, setShowBorders] = useLocalStorage(
    LOCALSTORAGE_MAP_BORDERS,
    false
  );

  const { isEnabled: hasJobs } = useFeatureFlag(FeatureFlag.JOBS);
  const [selectedJobIdParameter, setSelectedJobIdParameter] = useQuery<string>(
    "jobId",
    QueryType.STRING
  );
  const [selectedDayParameter, setSelectedDayParameter] = useQuery<string>(
    "day",
    QueryType.STRING
  );
  const [jobsTab, setJobsTab] = useState<JobsTab>(
    selectedDayParameter ? JobsTab.DAYS : JobsTab.JOBS
  );
  const [contentTab, setContentTab] = useState<ContentTab>(ContentTab.MAP);

  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken)
  );

  // get default date from URL
  const [selectedDates, setSelectedDates] = useState<DateRange<DateTime>>([
    startDate
      ? DateTime.fromFormat(startDate, DATE_PATH_FORMAT)
      : today.minus({ days: 7 }),
    endDate ? DateTime.fromFormat(endDate, DATE_PATH_FORMAT) : today,
  ]);
  const selectedRange = useMemo(
    () => selectedDates[1]?.diff(selectedDates[0] ?? DateTime.local()),
    [selectedDates]
  );
  const isRangeValid = getIsRangeValid(selectedDates);

  const {
    data: jobData,
    isLoading: isJobsLoading,
    isSuccess: isJobsSuccess,
  } = useQueryPopups(
    useListRobotJobsQuery(
      serial && selectedDates[0] && selectedDates[1]
        ? {
            serial,
            startDate: selectedDates[0].toFormat(DATE_PATH_FORMAT),
            endDate: selectedDates[1].toFormat(DATE_PATH_FORMAT),
          }
        : skipToken,
      {
        skip: !hasJobs || !isRangeValid,
      }
    ),
    {
      errorVariant: "warning",
    }
  );
  const jobList = useMemo<PortalJob[]>(
    () => sortBy(jobData ?? [], "timestampMs", Order.DESC),
    [jobData]
  );

  const dates = useMemo<Partial<PortalJob>[]>(() => {
    const dates: Partial<PortalJob>[] = [];
    if (selectedDates[0] && selectedRange) {
      for (const index of range(Math.round(selectedRange.as("days")) + 1)) {
        const date = selectedDates[0]
          .plus({ days: index })
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        dates.push({
          name: date.toLocaleString(
            { weekday: "long" },
            { locale: i18n.language }
          ),
          timestampMs: date.toMillis(),
        });
      }
    }
    return sortBy(dates, "timestampMs", Order.DESC);
  }, [i18n.language, selectedDates, selectedRange]);

  // selected job or date
  const [selectedJob, setSelectedJob] = useState<
    Partial<PortalJob> | undefined
  >();

  const autoSelectJob = useCallback((): void => {
    if (jobList.length > 0 && jobsTab === JobsTab.JOBS) {
      setSelectedJob(jobList[0]);
    } else if (dates.length > 0 && jobsTab === JobsTab.DAYS) {
      setSelectedJob(dates[0]);
    } else {
      setSelectedJob(undefined);
    }
  }, [dates, jobList, jobsTab]);

  // re-select job if it's out of range
  useEffect(() => {
    if (selectedJob && ![...jobList, ...dates].includes(selectedJob)) {
      autoSelectJob();
    }
  }, [jobList, dates, selectedJob, autoSelectJob]);

  // auto-select something
  useEffect(() => {
    if (selectedJob || !isJobsSuccess) {
      return;
    }
    autoSelectJob();
  }, [autoSelectJob, isJobsSuccess, selectedJob]);

  // update selected job based on URL
  const selectedDay = selectedDayParameter
    ? DateTime.fromFormat(String(selectedDayParameter), DATE_PATH_FORMAT)
    : undefined;
  useEffect(() => {
    // only one should be set. Ignore if they both are
    if (selectedJobIdParameter && selectedDayParameter) {
      return;
    }
    if (selectedJobIdParameter) {
      setSelectedJob(findWhere(jobList, { jobId: selectedJobIdParameter }));
    } else if (selectedDay) {
      setSelectedJob(
        findWhere(dates, {
          timestampMs: selectedDay.toMillis(),
        })
      );
    }
  }, [
    selectedJobIdParameter,
    selectedDayParameter,
    jobList,
    dates,
    selectedDay,
  ]);

  const {
    blocks,
    hasBlocks,
    isLoading: isHistoryLoading,
    logs,
  } = useRobotHistory(summary?.robot?.serial, selectedJob?.jobId, selectedDay);

  const [getRobotMetrics] = useLazyPopups(useLazyGetRobotMetricsQuery());
  const [getJob] = useLazyPopups(useLazyGetJobQuery());
  const [metrics] = useMemoAsync<DailyMetricResponse | undefined>(
    async () => {
      if (!serial || !selectedJob) {
        return;
      }
      if (isDay(selectedJob)) {
        const { data } = await getRobotMetrics(
          {
            serial,
            date: DateTime.fromMillis(selectedJob.timestampMs).toFormat(
              DATE_PATH_FORMAT
            ),
          },
          true
        );
        return data;
      }
      if (isJob(selectedJob)) {
        const { data } = await getJob({ jobId: selectedJob.jobId }, true);
        return data?.metrics;
      }
    },
    [serial, selectedJob, getRobotMetrics, getJob],
    undefined
  );

  // keep date path up to date
  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDates[0] && selectedDates[1]) {
      navigate(
        {
          pathname: `${getRobotPath(
            serial,
            RobotSubpath.HISTORY
          )}/${selectedDates[0].toFormat(
            DATE_PATH_FORMAT
          )}/${selectedDates[1].toFormat(DATE_PATH_FORMAT)}`,
          search,
        },
        { replace: true }
      );
    }
  }, [navigate, search, selectedDates, serial]);

  // zoom management
  const [canZoom, setCanZoom] = useState<boolean>(true);
  useEffect(() => {
    setCanZoom(true);
  }, [blocks, logs]);

  let visibleJobs: Partial<PortalJob>[] = jobList;
  if (!hasJobs || jobsTab === JobsTab.DAYS) {
    visibleJobs = dates;
  }

  return (
    <>
      <div className="flex flex-col md:items-stretch md:flex-row w-full gap-8 items-start h-full">
        <div className="w-full md:w-96 flex flex-col gap-4 items-stretch print:hidden">
          {hasJobs && (
            <Tabs
              value={jobsTab}
              onChange={(event, tab) => setJobsTab(tab)}
              variant="fullWidth"
              className="flex-shrink-0 mb-4"
            >
              <Tab
                className="min-h-0"
                icon={<JobsIcon />}
                iconPosition="start"
                label={t("models.jobs.job_other")}
                value={JobsTab.JOBS}
              />
              <Tab
                className="min-h-0"
                icon={<DaysIcon />}
                iconPosition="start"
                label={t("utils.units.dLong_other")}
                value={JobsTab.DAYS}
              />
            </Tabs>
          )}
          <FormGroup
            row
            className="flex items-center justify-center flex-nowrap"
          >
            <IconButton
              classes={{ root: "text-white" }}
              disabled={!isRangeValid}
              onClick={() => {
                if (!isRangeValid) {
                  return;
                }
                setSelectedDates([
                  selectedDates[0].minus({
                    days: selectedRange?.as("days"),
                  }),
                  selectedDates[1].minus({
                    days: selectedRange?.as("days"),
                  }),
                ]);
              }}
            >
              <PrevIcon />
            </IconButton>
            <CarbonDateRangePicker
              className="w-64"
              calendars={1}
              disableFuture
              value={selectedDates}
              slotProps={{
                textField: {
                  ...TEXT_FIELD_DARK,
                },
              }}
              onChange={(newRange) => setSelectedDates(newRange)}
            />
            <IconButton
              disabled={
                !isRangeValid || selectedDates[1].plus({ days: 1 }) > today
              }
              classes={{
                root: "text-white",
                disabled: "text-zinc-500",
              }}
              onClick={() => {
                if (!selectedDates[0] || !selectedDates[1]) {
                  return;
                }
                const nextStart = selectedDates[0].plus({
                  days: selectedRange?.as("days"),
                });
                const nextEnd = selectedDates[1].plus({
                  days: selectedRange?.as("days"),
                });
                setSelectedDates([
                  nextStart > today ? today : nextStart,
                  nextEnd > today ? today : nextEnd,
                ]);
              }}
            >
              <NextIcon />
            </IconButton>
          </FormGroup>
          <div className="flex flex-col gap-4 md:overflow-y-auto basis-0 flex-grow">
            {isJobsLoading ? (
              rangeArray(7, true).map((index) => (
                <Skeleton
                  variant="rectangular"
                  className="w-full h-24"
                  key={index}
                />
              ))
            ) : (
              <>
                {isRangeValid ? (
                  visibleJobs.map((job, index) => (
                    <JobSummary
                      className={classes("flex-shrink-0", {
                        "opacity-50": !isEqual(selectedJob, job),
                        "bg-green-500":
                          jobsTab === JobsTab.JOBS
                            ? summary?.robot?.health?.fieldConfig
                                ?.activeJobId &&
                              job.jobId ===
                                summary.robot.health.fieldConfig.activeJobId
                            : job.timestampMs &&
                              isToday(DateTime.fromMillis(job.timestampMs)),
                      })}
                      showMetrics={isEqual(selectedJob, job)}
                      metrics={metrics}
                      onMetricsClick={
                        contentTab === ContentTab.MAP
                          ? () => {
                              setContentTab(ContentTab.METRICS);
                              document
                                .querySelector("#robot-history-content")
                                ?.scrollIntoView({ behavior: "smooth" });
                            }
                          : undefined
                      }
                      job={job}
                      key={index}
                      onClick={() => {
                        if (isDay(job)) {
                          setSelectedJobIdParameter(undefined);
                          setSelectedDayParameter(
                            DateTime.fromMillis(job.timestampMs).toFormat(
                              DATE_PATH_FORMAT
                            )
                          );
                        } else if (isJob(job)) {
                          setSelectedDayParameter(undefined);
                          setSelectedJobIdParameter(job.jobId);
                        }
                        document
                          .querySelector("#robot-history-content")
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                    />
                  ))
                ) : (
                  <Alert severity="warning">
                    {t("views.fleet.robots.history.errors.invalidDate")}
                  </Alert>
                )}
                {jobsTab === JobsTab.JOBS &&
                  hasJobs &&
                  visibleJobs.length === 0 && (
                    <ViewPlaceholder
                      text={t("views.fleet.robots.history.errors.noJobs")}
                    />
                  )}
              </>
            )}
          </div>
        </div>
        <div
          className={classes("flex flex-col flex-grow gap-4 w-full md:w-auto")}
        >
          <TabContext value={contentTab}>
            <div
              className="flex justify-between items-center print:hidden"
              id="robot-history-content"
            >
              <TabList
                onChange={(event, tab) => {
                  setContentTab(tab);
                  setCanZoom(true);
                }}
                className="flex-shrink-0 flex-grow"
              >
                <Tab
                  className="min-h-0"
                  icon={<MapIcon />}
                  iconPosition="start"
                  label={titleCase(t("components.map.map"))}
                  value={ContentTab.MAP}
                />
                <Tab
                  className="min-h-0"
                  icon={<MetricsIcon />}
                  iconPosition="start"
                  label={titleCase(t("utils.metrics.metric_other"))}
                  value={ContentTab.METRICS}
                />
              </TabList>
              {hasSpatial && contentTab === ContentTab.MAP && (
                <FormControlLabel
                  className="flex-shrink-0"
                  control={
                    <Checkbox
                      checked={showBorders}
                      color="default"
                      onChange={(event, checked) => setShowBorders(checked)}
                    />
                  }
                  label={
                    <span className="whitespace-nowrap">
                      <span className="hidden sm:inline">
                        {t("utils.actions.showLong", {
                          subject: hasBlocks
                            ? t("views.fleet.robots.history.borders")
                            : t("views.fleet.robots.history.points"),
                        })}
                      </span>
                      <span className="sm:hidden">
                        {hasBlocks
                          ? t("views.fleet.robots.history.borders")
                          : t("views.fleet.robots.history.points")}
                      </span>
                    </span>
                  }
                />
              )}
              {contentTab === ContentTab.METRICS && hasUnvalidatedMetrics && (
                <Alert severity="info" variant="filled">
                  {t("views.fleet.robots.history.warnings.beta.description")}
                </Alert>
              )}
            </div>
            {selectedJob ? (
              <>
                <TabPanel
                  value={ContentTab.MAP}
                  className="p-0 flex-grow overflow-y-auto"
                >
                  <Map
                    className="w-full flex-grow h-[600px] max-h-[80vh] md:h-full md:max-h-full"
                    robots={summary ? [summary] : []}
                    history={hasBlocks ? undefined : logs}
                    blocks={blocks}
                    loading={isHistoryLoading}
                    canZoom={canZoom}
                    allowBorders
                    onZoom={() => setCanZoom(false)}
                    hideRobots={
                      selectedJob.timestampMs
                        ? !isToday(DateTime.fromMillis(selectedJob.timestampMs))
                        : false
                    }
                    key={summary?.robot?.serial}
                  />
                </TabPanel>
                <TabPanel
                  value={ContentTab.METRICS}
                  className="p-0 flex-grow overflow-y-auto"
                >
                  <WithSkeleton
                    success={!isUndefined(metrics)}
                    className="flex items-center justify-center"
                    variant="rectangular"
                  >
                    {!isUndefined(metrics) && (
                      <CertifiedMetrics
                        metrics={metrics}
                        className="flex flex-col"
                      />
                    )}
                  </WithSkeleton>
                </TabPanel>
              </>
            ) : (
              <ViewPlaceholder
                text={t("views.fleet.robots.history.placeholder")}
              />
            )}
          </TabContext>
        </div>
      </div>
    </>
  );
};

export const RobotHistory = withAuthenticationRequired(_RobotHistory);
