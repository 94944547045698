import { Alert, FormGroup, Switch, Typography } from "@mui/material";
import { Chat } from "portal/components/chat/Chat";
import { LegacyChat } from "portal/components/chat/LegacyChat";
import { Loading } from "portal/components/Loading";
import { LOCALSTORAGE_SUPPORT_CHAT } from "portal/utils/localStorage";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { useGetStreamTokenQuery } from "portal/state/idmApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, ReactNode } from "react";

enum ChatOptions {
  LEGACY = "legacy",
  STREAM = "stream",
}

// Note: There is no more UI toggle for legacy chat in new mode
//       but it is accessible via the URL query parameter `?mode=legacy`.

const _RobotSupport: FunctionComponent = () => {
  const { t } = useTranslation();
  const { serial } = useParams();
  const [mode, setMode] = useQuery<ChatOptions>("mode", QueryType.STRING);
  const [chatOption, setChatOption] = useLocalStorage<ChatOptions>(
    `${LOCALSTORAGE_SUPPORT_CHAT}-${serial}`,
    mode ?? ChatOptions.STREAM
  );

  if (mode) {
    setMode(undefined);
    setChatOption(mode);
  }

  const { data: token } = useGetStreamTokenQuery();
  const { user } = useSelf();

  if (!serial) {
    return <Loading />;
  }
  let chat: ReactNode;
  if (chatOption === ChatOptions.LEGACY) {
    chat = <LegacyChat />;
  } else if (!user || !token) {
    chat = <Loading />;
  } else {
    chat = (
      <Chat serial={serial} className="-mt-10" user={user} token={token} />
    );
  }
  return (
    <>
      {chat}
      {chatOption === ChatOptions.LEGACY && (
        <Alert className="fixed top-20" severity="info" icon={false}>
          <FormGroup row className="flex items-center">
            {" "}
            <Typography>
              {t("views.fleet.robots.support.chatMode.legacy")}
            </Typography>
            <Switch
              classes={{ thumb: "bg-white", track: "bg-gray-400" }}
              checked={false}
              onChange={(event, isEnabled) =>
                setChatOption(
                  isEnabled ? ChatOptions.STREAM : ChatOptions.LEGACY
                )
              }
            />
            <Typography>
              {t("views.fleet.robots.support.chatMode.new")}
            </Typography>
          </FormGroup>
        </Alert>
      )}
    </>
  );
};

export const RobotSupport = withAuthenticationRequired(_RobotSupport);
