import { classes } from "portal/utils/theme";
import { ExploreGraph } from "./ExploreGraph";
import { ExploreMap } from "./ExploreMap";
import { QueryType, useQuery } from "portal/utils/hooks/useQuery";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import GraphIcon from "@mui/icons-material/TimelineOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import React, { FunctionComponent, ReactElement } from "react";

enum TabType {
  MAP = "map",
  GRAPH = "graph",
}

interface TabDefinition {
  content: ReactElement;
  i18nKey: string;
  icon: ReactElement;
  value: TabType;
}

const _Explore: FunctionComponent = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useQuery<string>("type", QueryType.STRING, TabType.MAP);

  const tabs: TabDefinition[] = [
    {
      value: TabType.MAP,
      content: <ExploreMap />,
      icon: <MapIcon />,
      i18nKey: "components.map.map",
    },
    {
      value: TabType.GRAPH,
      content: <ExploreGraph />,
      icon: <GraphIcon />,
      i18nKey: "views.reports.explore.graph",
    },
  ];

  return (
    <>
      <TabContext value={tab ?? TabType.MAP}>
        <TabList
          onChange={(event, tab) => setTab(tab)}
          className="flex-shrink-0"
        >
          {tabs.map(({ value, icon, i18nKey }) => (
            <Tab
              className="min-h-0"
              key={value}
              icon={icon}
              iconPosition="start"
              label={t(i18nKey)}
              value={value}
            />
          ))}
        </TabList>
        {tabs.map(({ value, content }) => (
          <TabPanel
            key={value}
            value={value}
            className={classes(
              "p-0 pt-8",
              "flex-grow flex-1",
              "overflow-y-auto",
              "flex-col gap-8",
              { flex: tab === value }
            )}
          >
            {content}
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
};

export const Explore = withAuthenticationRequired(_Explore);
