import { AutocompleteProps } from "@mui/material";
import { isUndefined } from "./identity";
import { TFunction } from "i18next";

export const numberOrEmptyString = (
  value: number | string | undefined
): "" | number => {
  if (isUndefined(value)) {
    return "";
  }
  if (typeof value === "string" && value === "") {
    return "";
  }
  return Number(value);
};

export const getAutocompleteText = (
  t: TFunction
): Pick<
  AutocompleteProps<any, any, any, any>,
  "loadingText" | "noOptionsText" | "getLimitTagsText"
> => ({
  loadingText: t("components.Loading.placeholder"),
  noOptionsText: t("utils.form.noOptions"),
});
