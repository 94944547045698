// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/reports.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { CustomerResponse } from "./customers";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.reports";

export enum ReportMode {
  REPORT_MODE_UNSPECIFIED = 0,
  REPORT_MODE_JOBS = 1,
  REPORT_MODE_DAYS = 2,
  UNRECOGNIZED = -1,
}

export function reportModeFromJSON(object: any): ReportMode {
  switch (object) {
    case 0:
    case "REPORT_MODE_UNSPECIFIED":
      return ReportMode.REPORT_MODE_UNSPECIFIED;
    case 1:
    case "REPORT_MODE_JOBS":
      return ReportMode.REPORT_MODE_JOBS;
    case 2:
    case "REPORT_MODE_DAYS":
      return ReportMode.REPORT_MODE_DAYS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReportMode.UNRECOGNIZED;
  }
}

export function reportModeToJSON(object: ReportMode): string {
  switch (object) {
    case ReportMode.REPORT_MODE_UNSPECIFIED:
      return "REPORT_MODE_UNSPECIFIED";
    case ReportMode.REPORT_MODE_JOBS:
      return "REPORT_MODE_JOBS";
    case ReportMode.REPORT_MODE_DAYS:
      return "REPORT_MODE_DAYS";
    case ReportMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ReportInstanceResponse {
  db: DB | undefined;
  authorId: string;
  managerId: string;
  reportId: number;
  robotIds: number[];
  startDate: number;
  endDate: number;
  publishEmails: string[];
  slug: string;
  name: string;
  visibleColumns: string[];
  orderAsc: boolean;
  orderBy: string;
  showAverage: boolean;
  showTotal: boolean;
  authorName: string;
  automated: boolean;
  mode: ReportMode;
}

export interface ReportResponse {
  db: DB | undefined;
  authorId: string;
  visibleColumns: string[];
  orderAsc: boolean;
  orderBy: string;
  showAverage: boolean;
  showTotal: boolean;
  slug: string;
  name: string;
  customerId: number;
  robotIds: number[];
  startDate: number;
  endDate: number;
  automateWeekly: boolean;
  authorName: string;
  customer: CustomerResponse | undefined;
  mode: ReportMode;
}

function createBaseReportInstanceResponse(): ReportInstanceResponse {
  return {
    db: undefined,
    authorId: "",
    managerId: "",
    reportId: 0,
    robotIds: [],
    startDate: 0,
    endDate: 0,
    publishEmails: [],
    slug: "",
    name: "",
    visibleColumns: [],
    orderAsc: false,
    orderBy: "",
    showAverage: false,
    showTotal: false,
    authorName: "",
    automated: false,
    mode: 0,
  };
}

export const ReportInstanceResponse: MessageFns<ReportInstanceResponse> = {
  encode(message: ReportInstanceResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.authorId !== "") {
      writer.uint32(18).string(message.authorId);
    }
    if (message.managerId !== "") {
      writer.uint32(26).string(message.managerId);
    }
    if (message.reportId !== 0) {
      writer.uint32(32).uint64(message.reportId);
    }
    writer.uint32(42).fork();
    for (const v of message.robotIds) {
      writer.uint64(v);
    }
    writer.join();
    if (message.startDate !== 0) {
      writer.uint32(48).int64(message.startDate);
    }
    if (message.endDate !== 0) {
      writer.uint32(56).int64(message.endDate);
    }
    for (const v of message.publishEmails) {
      writer.uint32(66).string(v!);
    }
    if (message.slug !== "") {
      writer.uint32(122).string(message.slug);
    }
    if (message.name !== "") {
      writer.uint32(130).string(message.name);
    }
    for (const v of message.visibleColumns) {
      writer.uint32(138).string(v!);
    }
    if (message.orderAsc !== false) {
      writer.uint32(144).bool(message.orderAsc);
    }
    if (message.orderBy !== "") {
      writer.uint32(154).string(message.orderBy);
    }
    if (message.showAverage !== false) {
      writer.uint32(160).bool(message.showAverage);
    }
    if (message.showTotal !== false) {
      writer.uint32(168).bool(message.showTotal);
    }
    if (message.authorName !== "") {
      writer.uint32(178).string(message.authorName);
    }
    if (message.automated !== false) {
      writer.uint32(184).bool(message.automated);
    }
    if (message.mode !== 0) {
      writer.uint32(192).int32(message.mode);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ReportInstanceResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportInstanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.managerId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reportId = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag === 40) {
            message.robotIds.push(longToNumber(reader.uint64()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.robotIds.push(longToNumber(reader.uint64()));
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.startDate = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.endDate = longToNumber(reader.int64());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.publishEmails.push(reader.string());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.name = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.visibleColumns.push(reader.string());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.orderAsc = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.showAverage = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.showTotal = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.authorName = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.automated = reader.bool();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.mode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportInstanceResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      authorId: isSet(object.authorId) ? globalThis.String(object.authorId) : "",
      managerId: isSet(object.managerId) ? globalThis.String(object.managerId) : "",
      reportId: isSet(object.reportId) ? globalThis.Number(object.reportId) : 0,
      robotIds: globalThis.Array.isArray(object?.robotIds) ? object.robotIds.map((e: any) => globalThis.Number(e)) : [],
      startDate: isSet(object.startDate) ? globalThis.Number(object.startDate) : 0,
      endDate: isSet(object.endDate) ? globalThis.Number(object.endDate) : 0,
      publishEmails: globalThis.Array.isArray(object?.publishEmails)
        ? object.publishEmails.map((e: any) => globalThis.String(e))
        : [],
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      visibleColumns: globalThis.Array.isArray(object?.visibleColumns)
        ? object.visibleColumns.map((e: any) => globalThis.String(e))
        : [],
      orderAsc: isSet(object.orderAsc) ? globalThis.Boolean(object.orderAsc) : false,
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      showAverage: isSet(object.showAverage) ? globalThis.Boolean(object.showAverage) : false,
      showTotal: isSet(object.showTotal) ? globalThis.Boolean(object.showTotal) : false,
      authorName: isSet(object.authorName) ? globalThis.String(object.authorName) : "",
      automated: isSet(object.automated) ? globalThis.Boolean(object.automated) : false,
      mode: isSet(object.mode) ? reportModeFromJSON(object.mode) : 0,
    };
  },

  toJSON(message: ReportInstanceResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.authorId !== "") {
      obj.authorId = message.authorId;
    }
    if (message.managerId !== "") {
      obj.managerId = message.managerId;
    }
    if (message.reportId !== 0) {
      obj.reportId = Math.round(message.reportId);
    }
    if (message.robotIds?.length) {
      obj.robotIds = message.robotIds.map((e) => Math.round(e));
    }
    if (message.startDate !== 0) {
      obj.startDate = Math.round(message.startDate);
    }
    if (message.endDate !== 0) {
      obj.endDate = Math.round(message.endDate);
    }
    if (message.publishEmails?.length) {
      obj.publishEmails = message.publishEmails;
    }
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.visibleColumns?.length) {
      obj.visibleColumns = message.visibleColumns;
    }
    if (message.orderAsc !== false) {
      obj.orderAsc = message.orderAsc;
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.showAverage !== false) {
      obj.showAverage = message.showAverage;
    }
    if (message.showTotal !== false) {
      obj.showTotal = message.showTotal;
    }
    if (message.authorName !== "") {
      obj.authorName = message.authorName;
    }
    if (message.automated !== false) {
      obj.automated = message.automated;
    }
    if (message.mode !== 0) {
      obj.mode = reportModeToJSON(message.mode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportInstanceResponse>, I>>(base?: I): ReportInstanceResponse {
    return ReportInstanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportInstanceResponse>, I>>(object: I): ReportInstanceResponse {
    const message = createBaseReportInstanceResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.authorId = object.authorId ?? "";
    message.managerId = object.managerId ?? "";
    message.reportId = object.reportId ?? 0;
    message.robotIds = object.robotIds?.map((e) => e) || [];
    message.startDate = object.startDate ?? 0;
    message.endDate = object.endDate ?? 0;
    message.publishEmails = object.publishEmails?.map((e) => e) || [];
    message.slug = object.slug ?? "";
    message.name = object.name ?? "";
    message.visibleColumns = object.visibleColumns?.map((e) => e) || [];
    message.orderAsc = object.orderAsc ?? false;
    message.orderBy = object.orderBy ?? "";
    message.showAverage = object.showAverage ?? false;
    message.showTotal = object.showTotal ?? false;
    message.authorName = object.authorName ?? "";
    message.automated = object.automated ?? false;
    message.mode = object.mode ?? 0;
    return message;
  },
};

function createBaseReportResponse(): ReportResponse {
  return {
    db: undefined,
    authorId: "",
    visibleColumns: [],
    orderAsc: false,
    orderBy: "",
    showAverage: false,
    showTotal: false,
    slug: "",
    name: "",
    customerId: 0,
    robotIds: [],
    startDate: 0,
    endDate: 0,
    automateWeekly: false,
    authorName: "",
    customer: undefined,
    mode: 0,
  };
}

export const ReportResponse: MessageFns<ReportResponse> = {
  encode(message: ReportResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.authorId !== "") {
      writer.uint32(18).string(message.authorId);
    }
    for (const v of message.visibleColumns) {
      writer.uint32(26).string(v!);
    }
    if (message.orderAsc !== false) {
      writer.uint32(32).bool(message.orderAsc);
    }
    if (message.orderBy !== "") {
      writer.uint32(42).string(message.orderBy);
    }
    if (message.showAverage !== false) {
      writer.uint32(48).bool(message.showAverage);
    }
    if (message.showTotal !== false) {
      writer.uint32(56).bool(message.showTotal);
    }
    if (message.slug !== "") {
      writer.uint32(106).string(message.slug);
    }
    if (message.name !== "") {
      writer.uint32(114).string(message.name);
    }
    if (message.customerId !== 0) {
      writer.uint32(120).uint64(message.customerId);
    }
    writer.uint32(130).fork();
    for (const v of message.robotIds) {
      writer.uint64(v);
    }
    writer.join();
    if (message.startDate !== 0) {
      writer.uint32(136).int64(message.startDate);
    }
    if (message.endDate !== 0) {
      writer.uint32(144).int64(message.endDate);
    }
    if (message.automateWeekly !== false) {
      writer.uint32(152).bool(message.automateWeekly);
    }
    if (message.authorName !== "") {
      writer.uint32(162).string(message.authorName);
    }
    if (message.customer !== undefined) {
      CustomerResponse.encode(message.customer, writer.uint32(170).fork()).join();
    }
    if (message.mode !== 0) {
      writer.uint32(176).int32(message.mode);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ReportResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.visibleColumns.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.orderAsc = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.showAverage = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.showTotal = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.name = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.customerId = longToNumber(reader.uint64());
          continue;
        case 16:
          if (tag === 128) {
            message.robotIds.push(longToNumber(reader.uint64()));

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.robotIds.push(longToNumber(reader.uint64()));
            }

            continue;
          }

          break;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.startDate = longToNumber(reader.int64());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.endDate = longToNumber(reader.int64());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.automateWeekly = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.authorName = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.customer = CustomerResponse.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.mode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      authorId: isSet(object.authorId) ? globalThis.String(object.authorId) : "",
      visibleColumns: globalThis.Array.isArray(object?.visibleColumns)
        ? object.visibleColumns.map((e: any) => globalThis.String(e))
        : [],
      orderAsc: isSet(object.orderAsc) ? globalThis.Boolean(object.orderAsc) : false,
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      showAverage: isSet(object.showAverage) ? globalThis.Boolean(object.showAverage) : false,
      showTotal: isSet(object.showTotal) ? globalThis.Boolean(object.showTotal) : false,
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      customerId: isSet(object.customerId) ? globalThis.Number(object.customerId) : 0,
      robotIds: globalThis.Array.isArray(object?.robotIds) ? object.robotIds.map((e: any) => globalThis.Number(e)) : [],
      startDate: isSet(object.startDate) ? globalThis.Number(object.startDate) : 0,
      endDate: isSet(object.endDate) ? globalThis.Number(object.endDate) : 0,
      automateWeekly: isSet(object.automateWeekly) ? globalThis.Boolean(object.automateWeekly) : false,
      authorName: isSet(object.authorName) ? globalThis.String(object.authorName) : "",
      customer: isSet(object.customer) ? CustomerResponse.fromJSON(object.customer) : undefined,
      mode: isSet(object.mode) ? reportModeFromJSON(object.mode) : 0,
    };
  },

  toJSON(message: ReportResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.authorId !== "") {
      obj.authorId = message.authorId;
    }
    if (message.visibleColumns?.length) {
      obj.visibleColumns = message.visibleColumns;
    }
    if (message.orderAsc !== false) {
      obj.orderAsc = message.orderAsc;
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.showAverage !== false) {
      obj.showAverage = message.showAverage;
    }
    if (message.showTotal !== false) {
      obj.showTotal = message.showTotal;
    }
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.customerId !== 0) {
      obj.customerId = Math.round(message.customerId);
    }
    if (message.robotIds?.length) {
      obj.robotIds = message.robotIds.map((e) => Math.round(e));
    }
    if (message.startDate !== 0) {
      obj.startDate = Math.round(message.startDate);
    }
    if (message.endDate !== 0) {
      obj.endDate = Math.round(message.endDate);
    }
    if (message.automateWeekly !== false) {
      obj.automateWeekly = message.automateWeekly;
    }
    if (message.authorName !== "") {
      obj.authorName = message.authorName;
    }
    if (message.customer !== undefined) {
      obj.customer = CustomerResponse.toJSON(message.customer);
    }
    if (message.mode !== 0) {
      obj.mode = reportModeToJSON(message.mode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportResponse>, I>>(base?: I): ReportResponse {
    return ReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportResponse>, I>>(object: I): ReportResponse {
    const message = createBaseReportResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.authorId = object.authorId ?? "";
    message.visibleColumns = object.visibleColumns?.map((e) => e) || [];
    message.orderAsc = object.orderAsc ?? false;
    message.orderBy = object.orderBy ?? "";
    message.showAverage = object.showAverage ?? false;
    message.showTotal = object.showTotal ?? false;
    message.slug = object.slug ?? "";
    message.name = object.name ?? "";
    message.customerId = object.customerId ?? 0;
    message.robotIds = object.robotIds?.map((e) => e) || [];
    message.startDate = object.startDate ?? 0;
    message.endDate = object.endDate ?? 0;
    message.automateWeekly = object.automateWeekly ?? false;
    message.authorName = object.authorName ?? "";
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? CustomerResponse.fromPartial(object.customer)
      : undefined;
    message.mode = object.mode ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
