import {
  BLUE_LOADING_BUTTON,
  classes,
  SMALL_INPUT_DARK,
  SMALL_SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { boolean, number, object, string } from "yup";
import { buildPermission } from "portal/utils/auth";
import {
  Button,
  Drawer,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { CustomerResponse, FeatureFlags } from "protos/portal/customers";
import { DateTime } from "luxon";
import {
  entries,
  mapValues,
  values as objectValues,
} from "portal/utils/objects";
import {
  FeatureFlag,
  toggleableByFlag,
} from "portal/utils/hooks/useFeatureFlag";
import { Field, Form, Formik } from "formik";
import { i18n as I18n } from "i18next";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { range } from "portal/utils/arrays";
import { Select, TextField } from "formik-mui";
import { SwitchWithLabel } from "../SwitchWithLabel";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { useUpdateCustomerMutation } from "portal/state/portalApi";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import React, { Fragment, FunctionComponent, useMemo } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

const formatDay = (i18n: I18n, weekday: number): string => {
  if (weekday < 0 || weekday > 6) {
    return "Invalid";
  }
  return DateTime.local()
    .set({ weekday })
    .toLocaleString({ weekday: "long" }, { locale: i18n.language });
};

const formatHour = (i18n: I18n, hour: number): string => {
  if (hour < 0 || hour > 24) {
    return "Invalid";
  }
  return DateTime.local()
    .set({ hour, minute: 0 })
    .toLocaleString(DateTime.TIME_SIMPLE, { locale: i18n.language });
};

interface Props {
  customer?: CustomerResponse | undefined;
  open?: boolean;
  onClose: () => void;
}

const _CustomerEditor: FunctionComponent<Props> = ({
  customer,
  open = false,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const [updateCustomer] = useMutationPopups(useUpdateCustomerMutation(), {
    success: capitalize(
      t("utils.actions.saved", {
        subject: t("models.customers.customer_one"),
      })
    ),
  });
  const canRead = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);
  const canUpdate = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);

  const flags = useMemo(() => {
    const result = FeatureFlags.fromPartial({});
    for (const flag of objectValues(FeatureFlag)) {
      result[flag] = Boolean(customer?.featureFlags?.[flag]);
    }
    return result;
  }, [customer]);

  if (!canRead || !customer || !open) {
    return;
  }

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="right"
        classes={{
          paper: classes(
            "h-auto p-4 bottom-0 flex flex-col gap-4",
            "top-0 md:top-16",
            "w-screen md:w-80"
          ),
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: customer.name,
            emails: customer.emails.join("\n"),
            weeklyReportEnabled: customer.weeklyReportEnabled || false,
            weeklyReportDay: customer.weeklyReportDay || 1,
            weeklyReportHour: customer.weeklyReportHour || 9,
            weeklyReportTimezone:
              customer.weeklyReportTimezone ||
              DateTime.local().toLocaleString(
                { timeZoneName: "short" },
                { locale: i18n.language }
              ),
            weeklyReportLookbackDays: customer.weeklyReportLookbackDays || 14,
            ...flags,
          }}
          validationSchema={object({
            name: string(),
            emails: string().matches(
              /^[^ ,]*$/g,
              t("models.customers.fields.emails.errors.formatting")
            ),
            weeklyReportEnabled: boolean(),
            weeklyReportDay: number().min(0).max(6),
            weeklyReportHour: number().min(0).max(23),
            weeklyReportTimezone: string(),
            weeklyReportLookbackDays: number().min(1),
            // flatten FeatureFlags
            ...Object.fromEntries(
              entries(flags).map(([flag]) => [flag, boolean()])
            ),
          })}
          onSubmit={async (values) => {
            if (!canUpdate || !customer.db?.id) {
              return;
            }
            const data = CustomerResponse.fromPartial({
              name: values.name,
              sfdcAccountId: "",
              emails: values.emails ? values.emails.split("\n") : [],
              weeklyReportEnabled: values.weeklyReportEnabled,
              weeklyReportDay: isUndefined(values.weeklyReportDay)
                ? 1
                : Number(values.weeklyReportDay),
              weeklyReportHour: isUndefined(values.weeklyReportHour)
                ? 9
                : Number(values.weeklyReportHour),
              weeklyReportLookbackDays: values.weeklyReportLookbackDays || 14,
              weeklyReportTimezone:
                values.weeklyReportTimezone ||
                DateTime.local().toLocaleString(
                  { timeZoneName: "short" },
                  { locale: i18n.language }
                ),
              featureFlags: FeatureFlags.fromPartial(
                mapValues(flags, (_, flag) => values[flag])
              ),
            });

            await updateCustomer({
              customerId: customer.db.id,
              customer: data,
            });
          }}
        >
          {({ submitForm, isSubmitting, dirty }) => (
            <Form className="flex flex-col gap-4">
              <div className="flex justify-end gap-4">
                <Button variant="text" onClick={onClose} className="text-white">
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!canUpdate || !dirty}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                >
                  {t("utils.actions.update")}
                </LoadingButton>
              </div>
              <Field
                {...TEXT_FIELD_DARK}
                component={TextField}
                name="name"
                label={t("models.customers.fields.name")}
                disabled={!canUpdate}
              />
              <Typography variant="h6" className="mt-8">
                {titleCase(t("models.reports.report_other"))}
              </Typography>
              <Field
                {...TEXT_FIELD_DARK}
                component={TextField}
                multiline
                minRows={3}
                name="emails"
                label={t("models.customers.fields.emails.name")}
                disabled={!canUpdate}
              />
              <span className="text-xs mt-2 text-zinc-300">
                {t("models.customers.fields.weeklyReportEnabled.description")}
              </span>
              <Field
                component={SwitchWithLabel}
                type="checkbox"
                name="weeklyReportEnabled"
                label={t("models.customers.fields.weeklyReportEnabled.name")}
                disabled={!canUpdate}
              />
              <Field
                {...SMALL_SELECT_DARK}
                component={Select}
                input={<OutlinedInput {...SMALL_INPUT_DARK} label="Run on" />}
                name="weeklyReportDay"
                label={t("models.customers.fields.weeklyReportDay")}
                defaultValue={1}
                renderValue={(value: number) => formatDay(i18n, value)}
                disabled={!canUpdate}
              >
                {range(7).map((day) => (
                  <MenuItem value={day} key={day}>
                    {formatDay(i18n, day)}
                  </MenuItem>
                ))}
              </Field>
              <Field
                {...SMALL_SELECT_DARK}
                component={Select}
                input={<OutlinedInput {...SMALL_INPUT_DARK} label="Run at" />}
                name="weeklyReportHour"
                label={t("models.customers.fields.weeklyReportHour")}
                defaultValue={9}
                renderValue={(value: number) => formatHour(i18n, value)}
                disabled={!canUpdate}
              >
                {range(24).map((hour) => (
                  <MenuItem value={hour} key={hour}>
                    {formatHour(i18n, hour)}
                  </MenuItem>
                ))}
              </Field>
              <Field
                {...SMALL_SELECT_DARK}
                component={Select}
                input={<OutlinedInput {...SMALL_INPUT_DARK} label="Run in" />}
                name="weeklyReportTimezone"
                label={t("models.customers.fields.weeklyReportTimezone")}
                defaultValue={DateTime.local().toLocaleString(
                  { timeZoneName: "short" },
                  { locale: i18n.language }
                )}
                disabled={!canUpdate}
              >
                {Intl.supportedValuesOf("timeZone").map((timezone) => (
                  <MenuItem value={timezone} key={timezone}>
                    {timezone}
                  </MenuItem>
                ))}
              </Field>
              <Field
                {...TEXT_FIELD_DARK}
                component={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t("utils.units.dLong_other")}
                    </InputAdornment>
                  ),
                }}
                type="number"
                name="weeklyReportLookbackDays"
                label={t("models.customers.fields.weeklyReportLookbackDays")}
                disabled={!canUpdate}
              />
              <Typography variant="h6" className="mt-8">
                {t("models.customers.fields.featureFlags.name")}
              </Typography>
              <span className="text-xs -mt-2 text-zinc-300">
                {t("models.customers.fields.featureFlags.description")}
              </span>
              {objectValues(FeatureFlag).map((flag) => {
                if (!toggleableByFlag[flag]) {
                  return;
                }
                return (
                  <Fragment key={flag}>
                    <Field
                      className="font-mono font-bold"
                      key={flag}
                      component={SwitchWithLabel}
                      type="checkbox"
                      name={flag}
                      disabled={!canUpdate}
                      label={titleCase(
                        // carbon.actions.compareKeys.ignoreDynamic
                        t(`models.customers.fields.featureFlags.${flag}.name`)
                      )}
                    />
                    <span className="text-xs mb-4 -mt-2 text-zinc-300">
                      {/* carbon.actions.compareKeys.ignoreDynamic */}
                      {t(
                        `models.customers.fields.featureFlags.${flag}.description`
                      )}
                    </span>
                  </Fragment>
                );
              })}
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export const CustomerEditor = withErrorBoundary(
  { i18nKey: "components.customers.CustomerEditor.errors.load" },
  _CustomerEditor
);
