// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: category_profile/category_profile.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.category_profile";

export interface CategoryCollection {
  id: string;
  customerId?: string | undefined;
  name: string;
  categoryIds: string[];
  protected: boolean;
}

export interface Category {
  id: string;
  customerId?: string | undefined;
  name: string;
  chipIds: string[];
  protected: boolean;
}

function createBaseCategoryCollection(): CategoryCollection {
  return { id: "", customerId: undefined, name: "", categoryIds: [], protected: false };
}

export const CategoryCollection: MessageFns<CategoryCollection> = {
  encode(message: CategoryCollection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.customerId !== undefined) {
      writer.uint32(18).string(message.customerId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.categoryIds) {
      writer.uint32(34).string(v!);
    }
    if (message.protected !== false) {
      writer.uint32(40).bool(message.protected);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CategoryCollection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCategoryCollection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.categoryIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.protected = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CategoryCollection {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      categoryIds: globalThis.Array.isArray(object?.categoryIds)
        ? object.categoryIds.map((e: any) => globalThis.String(e))
        : [],
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
    };
  },

  toJSON(message: CategoryCollection): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.customerId !== undefined) {
      obj.customerId = message.customerId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.categoryIds?.length) {
      obj.categoryIds = message.categoryIds;
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CategoryCollection>, I>>(base?: I): CategoryCollection {
    return CategoryCollection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CategoryCollection>, I>>(object: I): CategoryCollection {
    const message = createBaseCategoryCollection();
    message.id = object.id ?? "";
    message.customerId = object.customerId ?? undefined;
    message.name = object.name ?? "";
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    message.protected = object.protected ?? false;
    return message;
  },
};

function createBaseCategory(): Category {
  return { id: "", customerId: undefined, name: "", chipIds: [], protected: false };
}

export const Category: MessageFns<Category> = {
  encode(message: Category, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.customerId !== undefined) {
      writer.uint32(18).string(message.customerId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.chipIds) {
      writer.uint32(34).string(v!);
    }
    if (message.protected !== false) {
      writer.uint32(40).bool(message.protected);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Category {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.chipIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.protected = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Category {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      chipIds: globalThis.Array.isArray(object?.chipIds) ? object.chipIds.map((e: any) => globalThis.String(e)) : [],
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
    };
  },

  toJSON(message: Category): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.customerId !== undefined) {
      obj.customerId = message.customerId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.chipIds?.length) {
      obj.chipIds = message.chipIds;
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Category>, I>>(base?: I): Category {
    return Category.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Category>, I>>(object: I): Category {
    const message = createBaseCategory();
    message.id = object.id ?? "";
    message.customerId = object.customerId ?? undefined;
    message.name = object.name ?? "";
    message.chipIds = object.chipIds?.map((e) => e) || [];
    message.protected = object.protected ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
