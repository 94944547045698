// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/auth.proto

/* eslint-disable */

export const protobufPackage = "carbon.portal.auth";

export enum UserDisplayRole {
  unknown_role = 0,
  robot_role = 1,
  operator_basic = 2,
  farm_manager = 3,
  carbon_tech = 4,
  carbon_basic = 5,
  operator_advanced = 6,
  UNRECOGNIZED = -1,
}

export function userDisplayRoleFromJSON(object: any): UserDisplayRole {
  switch (object) {
    case 0:
    case "unknown_role":
      return UserDisplayRole.unknown_role;
    case 1:
    case "robot_role":
      return UserDisplayRole.robot_role;
    case 2:
    case "operator_basic":
      return UserDisplayRole.operator_basic;
    case 3:
    case "farm_manager":
      return UserDisplayRole.farm_manager;
    case 4:
    case "carbon_tech":
      return UserDisplayRole.carbon_tech;
    case 5:
    case "carbon_basic":
      return UserDisplayRole.carbon_basic;
    case 6:
    case "operator_advanced":
      return UserDisplayRole.operator_advanced;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserDisplayRole.UNRECOGNIZED;
  }
}

export function userDisplayRoleToJSON(object: UserDisplayRole): string {
  switch (object) {
    case UserDisplayRole.unknown_role:
      return "unknown_role";
    case UserDisplayRole.robot_role:
      return "robot_role";
    case UserDisplayRole.operator_basic:
      return "operator_basic";
    case UserDisplayRole.farm_manager:
      return "farm_manager";
    case UserDisplayRole.carbon_tech:
      return "carbon_tech";
    case UserDisplayRole.carbon_basic:
      return "carbon_basic";
    case UserDisplayRole.operator_advanced:
      return "operator_advanced";
    case UserDisplayRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PermissionAction {
  unknown_action = 0,
  read = 1,
  update = 2,
  UNRECOGNIZED = -1,
}

export function permissionActionFromJSON(object: any): PermissionAction {
  switch (object) {
    case 0:
    case "unknown_action":
      return PermissionAction.unknown_action;
    case 1:
    case "read":
      return PermissionAction.read;
    case 2:
    case "update":
      return PermissionAction.update;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PermissionAction.UNRECOGNIZED;
  }
}

export function permissionActionToJSON(object: PermissionAction): string {
  switch (object) {
    case PermissionAction.unknown_action:
      return "unknown_action";
    case PermissionAction.read:
      return "read";
    case PermissionAction.update:
      return "update";
    case PermissionAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PermissionResource {
  unknown = 0,
  alarms_customer = 1,
  alarms_internal = 2,
  almanacs = 3,
  operator_settings = 4,
  banding = 5,
  banding_basic = 6,
  banding_advanced = 7,
  calibration = 8,
  cameras = 9,
  capture = 10,
  chat = 11,
  configs = 12,
  crops = 13,
  models_basic = 14,
  models_pinned = 15,
  models_nickname = 16,
  thresholds = 17,
  diagnostics = 18,
  guides = 19,
  jobs = 20,
  captcha = 21,
  lasers_row = 22,
  lasers_basic = 23,
  lasers_disable = 24,
  lasers_enable = 25,
  lasers_advanced = 26,
  lasers = 27,
  feeds = 28,
  models_advanced = 29,
  hardware = 30,
  quick_tune = 31,
  robot_ui = 32,
  thinning = 33,
  thinning_basic = 34,
  thinning_advanced = 35,
  software_basic = 36,
  software_advanced = 37,
  velocity_estimators = 38,
  vizualization_basic = 39,
  vizualization_advanced = 40,
  metrics = 41,
  mode = 42,
  jobs_select = 43,
  portal_settings = 44,
  portal_labs = 45,
  shortcuts_internal = 46,
  admin_alarms = 47,
  admin_cloud = 48,
  customers = 49,
  users = 50,
  users_invite = 51,
  users_permissions = 52,
  reports = 53,
  robots = 54,
  robots_assign = 55,
  robots_status = 56,
  robots_health = 57,
  veselka = 58,
  metrics_internal = 59,
  metrics_customer = 60,
  crops_basic = 61,
  crops_advanced = 62,
  discriminators = 63,
  operator_map = 64,
  uploads = 65,
  farms = 66,
  images = 67,
  autotractor_jobs = 68,
  plant_category_profiles = 69,
  UNRECOGNIZED = -1,
}

export function permissionResourceFromJSON(object: any): PermissionResource {
  switch (object) {
    case 0:
    case "unknown":
      return PermissionResource.unknown;
    case 1:
    case "alarms_customer":
      return PermissionResource.alarms_customer;
    case 2:
    case "alarms_internal":
      return PermissionResource.alarms_internal;
    case 3:
    case "almanacs":
      return PermissionResource.almanacs;
    case 4:
    case "operator_settings":
      return PermissionResource.operator_settings;
    case 5:
    case "banding":
      return PermissionResource.banding;
    case 6:
    case "banding_basic":
      return PermissionResource.banding_basic;
    case 7:
    case "banding_advanced":
      return PermissionResource.banding_advanced;
    case 8:
    case "calibration":
      return PermissionResource.calibration;
    case 9:
    case "cameras":
      return PermissionResource.cameras;
    case 10:
    case "capture":
      return PermissionResource.capture;
    case 11:
    case "chat":
      return PermissionResource.chat;
    case 12:
    case "configs":
      return PermissionResource.configs;
    case 13:
    case "crops":
      return PermissionResource.crops;
    case 14:
    case "models_basic":
      return PermissionResource.models_basic;
    case 15:
    case "models_pinned":
      return PermissionResource.models_pinned;
    case 16:
    case "models_nickname":
      return PermissionResource.models_nickname;
    case 17:
    case "thresholds":
      return PermissionResource.thresholds;
    case 18:
    case "diagnostics":
      return PermissionResource.diagnostics;
    case 19:
    case "guides":
      return PermissionResource.guides;
    case 20:
    case "jobs":
      return PermissionResource.jobs;
    case 21:
    case "captcha":
      return PermissionResource.captcha;
    case 22:
    case "lasers_row":
      return PermissionResource.lasers_row;
    case 23:
    case "lasers_basic":
      return PermissionResource.lasers_basic;
    case 24:
    case "lasers_disable":
      return PermissionResource.lasers_disable;
    case 25:
    case "lasers_enable":
      return PermissionResource.lasers_enable;
    case 26:
    case "lasers_advanced":
      return PermissionResource.lasers_advanced;
    case 27:
    case "lasers":
      return PermissionResource.lasers;
    case 28:
    case "feeds":
      return PermissionResource.feeds;
    case 29:
    case "models_advanced":
      return PermissionResource.models_advanced;
    case 30:
    case "hardware":
      return PermissionResource.hardware;
    case 31:
    case "quick_tune":
      return PermissionResource.quick_tune;
    case 32:
    case "robot_ui":
      return PermissionResource.robot_ui;
    case 33:
    case "thinning":
      return PermissionResource.thinning;
    case 34:
    case "thinning_basic":
      return PermissionResource.thinning_basic;
    case 35:
    case "thinning_advanced":
      return PermissionResource.thinning_advanced;
    case 36:
    case "software_basic":
      return PermissionResource.software_basic;
    case 37:
    case "software_advanced":
      return PermissionResource.software_advanced;
    case 38:
    case "velocity_estimators":
      return PermissionResource.velocity_estimators;
    case 39:
    case "vizualization_basic":
      return PermissionResource.vizualization_basic;
    case 40:
    case "vizualization_advanced":
      return PermissionResource.vizualization_advanced;
    case 41:
    case "metrics":
      return PermissionResource.metrics;
    case 42:
    case "mode":
      return PermissionResource.mode;
    case 43:
    case "jobs_select":
      return PermissionResource.jobs_select;
    case 44:
    case "portal_settings":
      return PermissionResource.portal_settings;
    case 45:
    case "portal_labs":
      return PermissionResource.portal_labs;
    case 46:
    case "shortcuts_internal":
      return PermissionResource.shortcuts_internal;
    case 47:
    case "admin_alarms":
      return PermissionResource.admin_alarms;
    case 48:
    case "admin_cloud":
      return PermissionResource.admin_cloud;
    case 49:
    case "customers":
      return PermissionResource.customers;
    case 50:
    case "users":
      return PermissionResource.users;
    case 51:
    case "users_invite":
      return PermissionResource.users_invite;
    case 52:
    case "users_permissions":
      return PermissionResource.users_permissions;
    case 53:
    case "reports":
      return PermissionResource.reports;
    case 54:
    case "robots":
      return PermissionResource.robots;
    case 55:
    case "robots_assign":
      return PermissionResource.robots_assign;
    case 56:
    case "robots_status":
      return PermissionResource.robots_status;
    case 57:
    case "robots_health":
      return PermissionResource.robots_health;
    case 58:
    case "veselka":
      return PermissionResource.veselka;
    case 59:
    case "metrics_internal":
      return PermissionResource.metrics_internal;
    case 60:
    case "metrics_customer":
      return PermissionResource.metrics_customer;
    case 61:
    case "crops_basic":
      return PermissionResource.crops_basic;
    case 62:
    case "crops_advanced":
      return PermissionResource.crops_advanced;
    case 63:
    case "discriminators":
      return PermissionResource.discriminators;
    case 64:
    case "operator_map":
      return PermissionResource.operator_map;
    case 65:
    case "uploads":
      return PermissionResource.uploads;
    case 66:
    case "farms":
      return PermissionResource.farms;
    case 67:
    case "images":
      return PermissionResource.images;
    case 68:
    case "autotractor_jobs":
      return PermissionResource.autotractor_jobs;
    case 69:
    case "plant_category_profiles":
      return PermissionResource.plant_category_profiles;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PermissionResource.UNRECOGNIZED;
  }
}

export function permissionResourceToJSON(object: PermissionResource): string {
  switch (object) {
    case PermissionResource.unknown:
      return "unknown";
    case PermissionResource.alarms_customer:
      return "alarms_customer";
    case PermissionResource.alarms_internal:
      return "alarms_internal";
    case PermissionResource.almanacs:
      return "almanacs";
    case PermissionResource.operator_settings:
      return "operator_settings";
    case PermissionResource.banding:
      return "banding";
    case PermissionResource.banding_basic:
      return "banding_basic";
    case PermissionResource.banding_advanced:
      return "banding_advanced";
    case PermissionResource.calibration:
      return "calibration";
    case PermissionResource.cameras:
      return "cameras";
    case PermissionResource.capture:
      return "capture";
    case PermissionResource.chat:
      return "chat";
    case PermissionResource.configs:
      return "configs";
    case PermissionResource.crops:
      return "crops";
    case PermissionResource.models_basic:
      return "models_basic";
    case PermissionResource.models_pinned:
      return "models_pinned";
    case PermissionResource.models_nickname:
      return "models_nickname";
    case PermissionResource.thresholds:
      return "thresholds";
    case PermissionResource.diagnostics:
      return "diagnostics";
    case PermissionResource.guides:
      return "guides";
    case PermissionResource.jobs:
      return "jobs";
    case PermissionResource.captcha:
      return "captcha";
    case PermissionResource.lasers_row:
      return "lasers_row";
    case PermissionResource.lasers_basic:
      return "lasers_basic";
    case PermissionResource.lasers_disable:
      return "lasers_disable";
    case PermissionResource.lasers_enable:
      return "lasers_enable";
    case PermissionResource.lasers_advanced:
      return "lasers_advanced";
    case PermissionResource.lasers:
      return "lasers";
    case PermissionResource.feeds:
      return "feeds";
    case PermissionResource.models_advanced:
      return "models_advanced";
    case PermissionResource.hardware:
      return "hardware";
    case PermissionResource.quick_tune:
      return "quick_tune";
    case PermissionResource.robot_ui:
      return "robot_ui";
    case PermissionResource.thinning:
      return "thinning";
    case PermissionResource.thinning_basic:
      return "thinning_basic";
    case PermissionResource.thinning_advanced:
      return "thinning_advanced";
    case PermissionResource.software_basic:
      return "software_basic";
    case PermissionResource.software_advanced:
      return "software_advanced";
    case PermissionResource.velocity_estimators:
      return "velocity_estimators";
    case PermissionResource.vizualization_basic:
      return "vizualization_basic";
    case PermissionResource.vizualization_advanced:
      return "vizualization_advanced";
    case PermissionResource.metrics:
      return "metrics";
    case PermissionResource.mode:
      return "mode";
    case PermissionResource.jobs_select:
      return "jobs_select";
    case PermissionResource.portal_settings:
      return "portal_settings";
    case PermissionResource.portal_labs:
      return "portal_labs";
    case PermissionResource.shortcuts_internal:
      return "shortcuts_internal";
    case PermissionResource.admin_alarms:
      return "admin_alarms";
    case PermissionResource.admin_cloud:
      return "admin_cloud";
    case PermissionResource.customers:
      return "customers";
    case PermissionResource.users:
      return "users";
    case PermissionResource.users_invite:
      return "users_invite";
    case PermissionResource.users_permissions:
      return "users_permissions";
    case PermissionResource.reports:
      return "reports";
    case PermissionResource.robots:
      return "robots";
    case PermissionResource.robots_assign:
      return "robots_assign";
    case PermissionResource.robots_status:
      return "robots_status";
    case PermissionResource.robots_health:
      return "robots_health";
    case PermissionResource.veselka:
      return "veselka";
    case PermissionResource.metrics_internal:
      return "metrics_internal";
    case PermissionResource.metrics_customer:
      return "metrics_customer";
    case PermissionResource.crops_basic:
      return "crops_basic";
    case PermissionResource.crops_advanced:
      return "crops_advanced";
    case PermissionResource.discriminators:
      return "discriminators";
    case PermissionResource.operator_map:
      return "operator_map";
    case PermissionResource.uploads:
      return "uploads";
    case PermissionResource.farms:
      return "farms";
    case PermissionResource.images:
      return "images";
    case PermissionResource.autotractor_jobs:
      return "autotractor_jobs";
    case PermissionResource.plant_category_profiles:
      return "plant_category_profiles";
    case PermissionResource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PermissionDomain {
  unknown_domain = 0,
  none = 1,
  self = 2,
  robot = 3,
  customer = 4,
  all = 5,
  templates = 6,
  UNRECOGNIZED = -1,
}

export function permissionDomainFromJSON(object: any): PermissionDomain {
  switch (object) {
    case 0:
    case "unknown_domain":
      return PermissionDomain.unknown_domain;
    case 1:
    case "none":
      return PermissionDomain.none;
    case 2:
    case "self":
      return PermissionDomain.self;
    case 3:
    case "robot":
      return PermissionDomain.robot;
    case 4:
    case "customer":
      return PermissionDomain.customer;
    case 5:
    case "all":
      return PermissionDomain.all;
    case 6:
    case "templates":
      return PermissionDomain.templates;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PermissionDomain.UNRECOGNIZED;
  }
}

export function permissionDomainToJSON(object: PermissionDomain): string {
  switch (object) {
    case PermissionDomain.unknown_domain:
      return "unknown_domain";
    case PermissionDomain.none:
      return "none";
    case PermissionDomain.self:
      return "self";
    case PermissionDomain.robot:
      return "robot";
    case PermissionDomain.customer:
      return "customer";
    case PermissionDomain.all:
      return "all";
    case PermissionDomain.templates:
      return "templates";
    case PermissionDomain.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
