// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/hardware.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.hardware";

export interface LaserStats {
  db: DB | undefined;
  robotId: number;
  rowNumber: number;
  laserId: number;
  cameraId: string;
  laserSerial: string;
  firing: boolean;
  enabled: boolean;
  error: boolean;
  totalFireCount: number;
  totalFireTimeMs: number;
  deltaTemp: number;
  current: number;
  targetTrajectoryId: number;
  lifetimeSec: number;
  powerLevel: number;
  installedAt: number;
  removedAt: number;
}

export interface HardwareResponse {
  lasers: LaserStats[];
  hostSerials: { [key: string]: string };
}

export interface HardwareResponse_HostSerialsEntry {
  key: string;
  value: string;
}

function createBaseLaserStats(): LaserStats {
  return {
    db: undefined,
    robotId: 0,
    rowNumber: 0,
    laserId: 0,
    cameraId: "",
    laserSerial: "",
    firing: false,
    enabled: false,
    error: false,
    totalFireCount: 0,
    totalFireTimeMs: 0,
    deltaTemp: 0,
    current: 0,
    targetTrajectoryId: 0,
    lifetimeSec: 0,
    powerLevel: 0,
    installedAt: 0,
    removedAt: 0,
  };
}

export const LaserStats: MessageFns<LaserStats> = {
  encode(message: LaserStats, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.robotId !== 0) {
      writer.uint32(16).uint64(message.robotId);
    }
    if (message.rowNumber !== 0) {
      writer.uint32(24).uint64(message.rowNumber);
    }
    if (message.laserId !== 0) {
      writer.uint32(32).uint64(message.laserId);
    }
    if (message.cameraId !== "") {
      writer.uint32(42).string(message.cameraId);
    }
    if (message.laserSerial !== "") {
      writer.uint32(50).string(message.laserSerial);
    }
    if (message.firing !== false) {
      writer.uint32(56).bool(message.firing);
    }
    if (message.enabled !== false) {
      writer.uint32(64).bool(message.enabled);
    }
    if (message.error !== false) {
      writer.uint32(72).bool(message.error);
    }
    if (message.totalFireCount !== 0) {
      writer.uint32(80).uint64(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      writer.uint32(88).uint64(message.totalFireTimeMs);
    }
    if (message.deltaTemp !== 0) {
      writer.uint32(101).float(message.deltaTemp);
    }
    if (message.current !== 0) {
      writer.uint32(109).float(message.current);
    }
    if (message.targetTrajectoryId !== 0) {
      writer.uint32(112).uint32(message.targetTrajectoryId);
    }
    if (message.lifetimeSec !== 0) {
      writer.uint32(120).uint64(message.lifetimeSec);
    }
    if (message.powerLevel !== 0) {
      writer.uint32(133).float(message.powerLevel);
    }
    if (message.installedAt !== 0) {
      writer.uint32(136).uint64(message.installedAt);
    }
    if (message.removedAt !== 0) {
      writer.uint32(144).uint64(message.removedAt);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserStats {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.robotId = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rowNumber = longToNumber(reader.uint64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.laserId = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cameraId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.laserSerial = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.firing = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.error = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalFireCount = longToNumber(reader.uint64());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalFireTimeMs = longToNumber(reader.uint64());
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.deltaTemp = reader.float();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.current = reader.float();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.targetTrajectoryId = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.lifetimeSec = longToNumber(reader.uint64());
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.powerLevel = reader.float();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.installedAt = longToNumber(reader.uint64());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.removedAt = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserStats {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
      rowNumber: isSet(object.rowNumber) ? globalThis.Number(object.rowNumber) : 0,
      laserId: isSet(object.laserId) ? globalThis.Number(object.laserId) : 0,
      cameraId: isSet(object.cameraId) ? globalThis.String(object.cameraId) : "",
      laserSerial: isSet(object.laserSerial) ? globalThis.String(object.laserSerial) : "",
      firing: isSet(object.firing) ? globalThis.Boolean(object.firing) : false,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      error: isSet(object.error) ? globalThis.Boolean(object.error) : false,
      totalFireCount: isSet(object.totalFireCount) ? globalThis.Number(object.totalFireCount) : 0,
      totalFireTimeMs: isSet(object.totalFireTimeMs) ? globalThis.Number(object.totalFireTimeMs) : 0,
      deltaTemp: isSet(object.deltaTemp) ? globalThis.Number(object.deltaTemp) : 0,
      current: isSet(object.current) ? globalThis.Number(object.current) : 0,
      targetTrajectoryId: isSet(object.targetTrajectoryId) ? globalThis.Number(object.targetTrajectoryId) : 0,
      lifetimeSec: isSet(object.lifetimeSec) ? globalThis.Number(object.lifetimeSec) : 0,
      powerLevel: isSet(object.powerLevel) ? globalThis.Number(object.powerLevel) : 0,
      installedAt: isSet(object.installedAt) ? globalThis.Number(object.installedAt) : 0,
      removedAt: isSet(object.removedAt) ? globalThis.Number(object.removedAt) : 0,
    };
  },

  toJSON(message: LaserStats): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    if (message.rowNumber !== 0) {
      obj.rowNumber = Math.round(message.rowNumber);
    }
    if (message.laserId !== 0) {
      obj.laserId = Math.round(message.laserId);
    }
    if (message.cameraId !== "") {
      obj.cameraId = message.cameraId;
    }
    if (message.laserSerial !== "") {
      obj.laserSerial = message.laserSerial;
    }
    if (message.firing !== false) {
      obj.firing = message.firing;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.error !== false) {
      obj.error = message.error;
    }
    if (message.totalFireCount !== 0) {
      obj.totalFireCount = Math.round(message.totalFireCount);
    }
    if (message.totalFireTimeMs !== 0) {
      obj.totalFireTimeMs = Math.round(message.totalFireTimeMs);
    }
    if (message.deltaTemp !== 0) {
      obj.deltaTemp = message.deltaTemp;
    }
    if (message.current !== 0) {
      obj.current = message.current;
    }
    if (message.targetTrajectoryId !== 0) {
      obj.targetTrajectoryId = Math.round(message.targetTrajectoryId);
    }
    if (message.lifetimeSec !== 0) {
      obj.lifetimeSec = Math.round(message.lifetimeSec);
    }
    if (message.powerLevel !== 0) {
      obj.powerLevel = message.powerLevel;
    }
    if (message.installedAt !== 0) {
      obj.installedAt = Math.round(message.installedAt);
    }
    if (message.removedAt !== 0) {
      obj.removedAt = Math.round(message.removedAt);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserStats>, I>>(base?: I): LaserStats {
    return LaserStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserStats>, I>>(object: I): LaserStats {
    const message = createBaseLaserStats();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.robotId = object.robotId ?? 0;
    message.rowNumber = object.rowNumber ?? 0;
    message.laserId = object.laserId ?? 0;
    message.cameraId = object.cameraId ?? "";
    message.laserSerial = object.laserSerial ?? "";
    message.firing = object.firing ?? false;
    message.enabled = object.enabled ?? false;
    message.error = object.error ?? false;
    message.totalFireCount = object.totalFireCount ?? 0;
    message.totalFireTimeMs = object.totalFireTimeMs ?? 0;
    message.deltaTemp = object.deltaTemp ?? 0;
    message.current = object.current ?? 0;
    message.targetTrajectoryId = object.targetTrajectoryId ?? 0;
    message.lifetimeSec = object.lifetimeSec ?? 0;
    message.powerLevel = object.powerLevel ?? 0;
    message.installedAt = object.installedAt ?? 0;
    message.removedAt = object.removedAt ?? 0;
    return message;
  },
};

function createBaseHardwareResponse(): HardwareResponse {
  return { lasers: [], hostSerials: {} };
}

export const HardwareResponse: MessageFns<HardwareResponse> = {
  encode(message: HardwareResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.lasers) {
      LaserStats.encode(v!, writer.uint32(10).fork()).join();
    }
    Object.entries(message.hostSerials).forEach(([key, value]) => {
      HardwareResponse_HostSerialsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HardwareResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHardwareResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lasers.push(LaserStats.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = HardwareResponse_HostSerialsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.hostSerials[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HardwareResponse {
    return {
      lasers: globalThis.Array.isArray(object?.lasers) ? object.lasers.map((e: any) => LaserStats.fromJSON(e)) : [],
      hostSerials: isObject(object.hostSerials)
        ? Object.entries(object.hostSerials).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: HardwareResponse): unknown {
    const obj: any = {};
    if (message.lasers?.length) {
      obj.lasers = message.lasers.map((e) => LaserStats.toJSON(e));
    }
    if (message.hostSerials) {
      const entries = Object.entries(message.hostSerials);
      if (entries.length > 0) {
        obj.hostSerials = {};
        entries.forEach(([k, v]) => {
          obj.hostSerials[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HardwareResponse>, I>>(base?: I): HardwareResponse {
    return HardwareResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HardwareResponse>, I>>(object: I): HardwareResponse {
    const message = createBaseHardwareResponse();
    message.lasers = object.lasers?.map((e) => LaserStats.fromPartial(e)) || [];
    message.hostSerials = Object.entries(object.hostSerials ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseHardwareResponse_HostSerialsEntry(): HardwareResponse_HostSerialsEntry {
  return { key: "", value: "" };
}

export const HardwareResponse_HostSerialsEntry: MessageFns<HardwareResponse_HostSerialsEntry> = {
  encode(message: HardwareResponse_HostSerialsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HardwareResponse_HostSerialsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHardwareResponse_HostSerialsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HardwareResponse_HostSerialsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: HardwareResponse_HostSerialsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HardwareResponse_HostSerialsEntry>, I>>(
    base?: I,
  ): HardwareResponse_HostSerialsEntry {
    return HardwareResponse_HostSerialsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HardwareResponse_HostSerialsEntry>, I>>(
    object: I,
  ): HardwareResponse_HostSerialsEntry {
    const message = createBaseHardwareResponse_HostSerialsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
