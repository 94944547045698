import { buildPermission, isExperimental } from "portal/utils/auth";
import { Explore } from "./Explore";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { GlobalHotKeys } from "react-hotkeys";
import { GROUP_REPORTS } from "portal/utils/hotkeys";
import { Header } from "portal/components/header/Header";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { Page } from "portal/components/Page";
import { Path, ReportSubpath } from "portal/utils/routing";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { ReportList } from "./ReportList";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { titleCase } from "portal/utils/strings";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent } from "react";

const _Reports: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isEnabled: isExploreFlagEnabled } = useFeatureFlag(
    FeatureFlag.EXPLORE
  );

  const { user } = useSelf();
  const isExperimentsEnabled = isExperimental(user);
  const hasExplore = isExploreFlagEnabled && isExperimentsEnabled;

  const { pathname } = window.location;

  // ### ROUTING
  const element = useRoutes([
    {
      path: Path.BASE,
      element: (
        <Navigate to={`${Path.REPORTS}${ReportSubpath.SCHEDULED}`} replace />
      ),
    },
    {
      path: `${ReportSubpath.SCHEDULED}`,
      element: <ReportList />,
    },
    ...(hasExplore
      ? [
          {
            path: `${ReportSubpath.EXPLORE}`,
            element: <Explore />,
          },
        ]
      : []),
    // redirect old reports paths
    {
      path: "*",
      element: (
        <>
          {pathname.startsWith(Path.REPORTS) &&
            !pathname.startsWith(`${Path.REPORTS}${ReportSubpath.SCHEDULED}`) &&
            !pathname.startsWith(`${Path.REPORTS}${ReportSubpath.EXPLORE}`) && (
              <Navigate
                to={`${Path.REPORTS}${ReportSubpath.SCHEDULED}${pathname.slice(
                  Math.max(0, Path.REPORTS.length)
                )}`}
                replace
              />
            )}
        </>
      ),
    },
  ]);

  return (
    <>
      <GlobalHotKeys
        allowChanges
        keyMap={{
          GO_SCHEDULED: {
            name: t("utils.actions.goToLong", {
              subject: titleCase(t("views.reports.explore.title")),
            }),
            group: GROUP_REPORTS,
            action: "keyup",
            sequence: "r r",
          },
          ...(hasExplore
            ? {
                GO_EXPLORE: {
                  name: t("utils.actions.goToLong", {
                    subject: titleCase(t("views.reports.scheduled.title")),
                  }),
                  group: GROUP_REPORTS,
                  action: "keyup",
                  sequence: "r e",
                },
              }
            : {}),
        }}
        handlers={{
          GO_SCHEDULED: () =>
            navigate(`${Path.REPORTS}${ReportSubpath.SCHEDULED}`),
          GO_EXPLORE: () => navigate(`${Path.REPORTS}${ReportSubpath.EXPLORE}`),
        }}
      />
      <Header title={titleCase(t("models.reports.report_other"))}>
        <ResponsiveSubnav
          pages={[
            {
              label: titleCase(t("views.reports.scheduled.title")),
              to: `${Path.REPORTS}${ReportSubpath.SCHEDULED}`,
            },
            {
              label: titleCase(t("views.reports.explore.title")),
              to: `${Path.REPORTS}${ReportSubpath.EXPLORE}`,
              hidden: !hasExplore,
            },
          ]}
        />
      </Header>
      <Page>{element}</Page>
    </>
  );
};

export const Reports = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.self
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.all
      ),
    ],
    _Reports
  )
);
