import { BlockColors, BlockRange } from "./blocks";
import { BlockResponse } from "protos/portal/spatial";
import {
  CarbonUnit,
  convert,
  isUnitNumberType,
  MeasurementSystem,
  UnitNumberType,
} from "./units/units";
import { DailyMetricResponse } from "protos/portal/metrics";
import { DateTime } from "luxon";
import { i18n as I18n, ParseKeys } from "i18next";
import { isEqual } from "./objects";
import { isUndefined } from "./identity";
import { TimeUnits } from "convert-units/definitions/time";

export enum MetricValidation {
  VALIDATED = "validated",
  PENDING = "pending",
  INTERNAL = "internal",
}

export enum CycleSlot {
  ALTITUDE = "metric-altitude",
  AREA = "metric-area",
  DENSITY = "metric-density",
  DISTANCE = "metric-distance",
  SHOOT = "metric-shoot",
  SIZE = "metric-size",
  SPEED = "metric-speed",
  COVERAGE = "metric-coverage",
  TIME = "metric-time",
  COMPONENT_ENCODERS = "encoders",
  STATUS_AREA = "status-area",
  STATUS_TIME = "status-time",
}

export interface MetricBase {
  canAverage: boolean;
  canTotal: boolean;
  getValue: (
    value: any,
    metrics: DailyMetricResponse,
    canReadInternalMetrics?: boolean
  ) => any;
  id: ParseKeys;
  validation: MetricValidation;
  cycleSlot?: CycleSlot;
  cycle?: { [K in MeasurementSystem]?: CarbonUnit[] };
}

export interface SpatialMetricBase extends MetricBase {
  canMap: boolean;
  popup?: SpatialMetric[];
  getValue: (block: BlockResponse) => any;
  colors: BlockColors;
  minY?: number;
  maxY?: number;
  fill?: boolean;
  getColor: (value: any, range: BlockRange, isRelative?: boolean) => string;
  renderWhenNotCountable?: boolean;
}

export interface MetricTimeBase {
  type: "dateTime";
  units: TimeUnits;
}

export type MetricTime = MetricBase & MetricTimeBase;
export type SpatialMetricTime = SpatialMetricBase & MetricTimeBase;

export interface MetricStringBase {
  type: "string";
}

export type MetricString = MetricBase & MetricStringBase;
export type SpatialMetricString = SpatialMetricBase & MetricStringBase;

export interface MetricBooleanBase {
  type: "boolean";
  truei18nKey?: string;
  falsei18nKey?: string;
}

export type MetricBoolean = MetricBase & MetricBooleanBase;
export type SpatialMetricBoolean = SpatialMetricBase & MetricBooleanBase;

export interface MetricNumberBase {
  type: "number";
  units: CarbonUnit;
  unitsDelimiter?: string;
  toUnits?: CarbonUnit;
  decimalPlaces?: number;
}

export type MetricNumber = MetricBase & MetricNumberBase;
export type SpatialMetricNumber = SpatialMetricBase & MetricNumberBase;

export interface MetricDurationBase {
  type: "number";
  isDuration: true;
  units: TimeUnits;
}

export type MetricDuration = MetricBase & MetricDurationBase;
export type SpatialMetricDuration = SpatialMetricBase & MetricDurationBase;

export type Metric =
  | MetricTime
  | MetricString
  | MetricBoolean
  | MetricNumber
  | MetricDuration;

export type SpatialMetric =
  | SpatialMetricTime
  | SpatialMetricString
  | SpatialMetricBoolean
  | SpatialMetricNumber
  | SpatialMetricDuration;

export type AnyMetric = Metric | SpatialMetric;

export const carbonConvert = (
  value: number,
  fromUnits: CarbonUnit,
  toUnits: CarbonUnit
): number => {
  if (
    fromUnits === toUnits ||
    isEqual(fromUnits, toUnits) ||
    isUnitNumberType(fromUnits) ||
    isUnitNumberType(toUnits)
  ) {
    return value;
  }
  return convert(value).from(fromUnits).to(toUnits);
};

export const isCount = (
  unit: MetricNumberBase["units"] | undefined
): unit is UnitNumberType.COUNT => UnitNumberType.COUNT === unit;

export const isPercent = (
  unit: MetricNumberBase["units"] | undefined
): unit is UnitNumberType.PERCENT => UnitNumberType.PERCENT === unit;

export const isSpatialMetric = (metric: AnyMetric): metric is SpatialMetric =>
  "canMap" in metric;

export const isMetricTime = (metric: AnyMetric): metric is MetricTime =>
  metric.type === "dateTime" && !isSpatialMetric(metric);

export const isMetricString = (metric: AnyMetric): metric is MetricString =>
  metric.type === "string" && !isSpatialMetric(metric);

export const isMetricBoolean = (metric: AnyMetric): metric is MetricBoolean =>
  metric.type === "boolean" && !isSpatialMetric(metric);

export const isMetricNumber = (metric: AnyMetric): metric is MetricNumber =>
  metric.type === "number" && !isSpatialMetric(metric);

export const isMetricDuration = (metric: AnyMetric): metric is MetricDuration =>
  metric.type === "number" &&
  "isDuration" in metric &&
  !isSpatialMetric(metric);

export const isSpatialMetricTime = (
  metric: AnyMetric
): metric is SpatialMetricTime =>
  metric.type === "dateTime" && isSpatialMetric(metric);

export const isSpatialMetricString = (
  metric: AnyMetric
): metric is SpatialMetricString =>
  metric.type === "string" && isSpatialMetric(metric);

export const isSpatialMetricBoolean = (
  metric: AnyMetric
): metric is SpatialMetricBoolean =>
  metric.type === "boolean" && isSpatialMetric(metric);

export const isSpatialMetricNumber = (
  metric: AnyMetric
): metric is SpatialMetricNumber =>
  metric.type === "number" && isSpatialMetric(metric);

export const isSpatialMetricDuration = (
  metric: AnyMetric
): metric is SpatialMetricDuration =>
  metric.type === "number" && "isDuration" in metric && isSpatialMetric(metric);

export const unitsToI18nKey = (units: CarbonUnit | UnitNumberType): string => {
  if (isCount(units)) {
    return "";
  } else if (isPercent(units)) {
    return "utils.units.%";
  } else {
    return units;
  }
};

export const formatTime = (i18n: I18n, value?: number | undefined): string => {
  if (isUndefined(value) || value === 0) {
    return "";
  }
  return DateTime.fromMillis(value).toLocaleString(
    {
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
      weekday: "long",
      month: "long",
      day: "numeric",
    },
    { locale: i18n.language }
  );
};

export const getAsPercentOfCategorized = (
  value: number | undefined,
  {
    weedsTypeCountBroadleaf,
    weedsTypeCountGrass,
    weedsTypeCountOffshoot,
    weedsTypeCountPurslane,
  }: DailyMetricResponse
): number | undefined => {
  const totalCategorized =
    weedsTypeCountBroadleaf +
    weedsTypeCountGrass +
    weedsTypeCountOffshoot +
    weedsTypeCountPurslane;
  if (isUndefined(value) || !totalCategorized) {
    return;
  }
  return (value / totalCategorized) * 100;
};
