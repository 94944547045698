import { addNotification } from "portal/state/notifications";
import {
  BLUE_LOADING_BUTTON,
  classes,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { Field, Form, Formik } from "formik";
import { GlobalHotKeys } from "react-hotkeys";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { Select, TextField } from "formik-mui";
import { useAuthorizationRequired } from "./auth/WithAuthorizationRequired";
import { useDispatch } from "react-redux";
import {
  useInviteUserMutation,
  useListCustomersQuery,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import InviteIcon from "@mui/icons-material/PersonAddOutlined";
import React, { FunctionComponent, useState } from "react";

interface InviteUserProps {
  hotkey?: string;
}
export const InviteUser: FunctionComponent<InviteUserProps> = ({ hotkey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canReadCustomers = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);

  const [inviteUser] = useMutationPopups(useInviteUserMutation(), {
    success: capitalize(
      t("utils.actions.invitedLong", {
        subject: t("models.users.user_one"),
      })
    ),
  });

  const [isOpen, setOpen] = useState<boolean>(false);

  const { data: customers, isLoading } = useQueryPopups(
    useListCustomersQuery(undefined, {
      skip: !isOpen || !canReadCustomers,
    }),
    { errorVariant: "warning" }
  );

  return (
    <>
      {hotkey && (
        <GlobalHotKeys
          keyMap={{
            INVITE_USER: {
              name: titleCase(
                t("utils.actions.inviteLong", {
                  subject: t("models.users.user_one"),
                })
              ),
              action: "keyup",
              sequence: hotkey,
            },
          }}
          handlers={{
            INVITE_USER: () => setOpen(true),
          }}
        />
      )}
      <Button
        className="text-white"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        <span className="hidden sm:inline">
          {titleCase(
            t("utils.actions.inviteLong", {
              subject: t("models.users.user_one"),
            })
          )}
        </span>
        <span className="sm:hidden">{t("utils.actions.invite")}</span>
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {titleCase(
            t("utils.actions.inviteLong", {
              subject: t("models.users.user_one"),
            })
          )}
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            customer: "",
            email: "",
          }}
          validationSchema={object({
            customer: string(),
            email: string().email().required(),
          })}
          onSubmit={async ({ email, customer }) => {
            if (canReadCustomers && !customer) {
              dispatch(
                addNotification({
                  message: t("components.InviteUser.errors.customerRequired"),
                  variant: "error",
                })
              );
              return;
            }
            const { error } = await inviteUser({
              email,
              customerId: Number(customer),
            });
            if (!error) {
              setOpen(false);
            }
          }}
        >
          {({ submitForm, isSubmitting, dirty }) => (
            <Form>
              <DialogContent className="flex flex-col gap-4 pt-2">
                {canReadCustomers && (
                  <Field
                    {...SELECT_DARK}
                    component={Select}
                    name="customer"
                    disabled={isLoading}
                    label={capitalize(t("models.customers.customer_one"))}
                    className={classes("min-w-48")}
                    placeholder={
                      isLoading ? t("components.Loading.placeholder") : ""
                    }
                  >
                    {customers?.map(({ db, name }) => (
                      <MenuItem value={String(db?.id)} key={String(db?.id)}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                )}
                <Field
                  {...TEXT_FIELD_DARK}
                  // the whole dialog opens as a response to user action,
                  // so it's fine to autofocus the email field
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  component={TextField}
                  label={t("models.users.fields.email")}
                  name="email"
                  type="email"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={<InviteIcon />}
                >
                  {t("utils.actions.invite")}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
