import {
  AlarmImpact,
  alarmImpactFromJSON,
  AlarmLevel,
  alarmLevelFromJSON,
} from "protos/frontend/alarm";
import { AlarmResponse } from "protos/portal/alarms";
import { buildPermission } from "portal/utils/auth";
import { capitalize, Link, Tooltip } from "@mui/material";
import { CarbonDataGrid, CarbonFooter } from "portal/components/CarbonDataGrid";
import { classes, STATUS_BG } from "portal/utils/theme";
import { DateTime } from "luxon";
import {
  DATETIME_EXCEL,
  formatDateOffset,
  isSameDate,
} from "portal/utils/dates";
import {
  formatAlarmImpact,
  formatAlarmLevel,
  isOngoing,
} from "portal/utils/alarms";
import { formatDuration } from "portal/utils/strings";
import { getCustomerSerial } from "portal/utils/robots";
import {
  GridColDef,
  GridSortModel,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { range } from "portal/utils/arrays";
import { SearchField } from "./header/SearchField";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "./auth/WithAuthorizationRequired";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import { useListRobotsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { Fragment, FunctionComponent, useMemo, useState } from "react";

const getColorByLevel = (input: AlarmLevel | number | undefined): string => {
  const level = typeof input === "string" ? input : alarmLevelFromJSON(input);
  switch (level) {
    case alarmLevelFromJSON(AlarmLevel.AL_CRITICAL): {
      return classes(STATUS_BG.RED, "text-white");
    }
    case alarmLevelFromJSON(AlarmLevel.AL_HIGH):
    case alarmLevelFromJSON(AlarmLevel.AL_MEDIUM): {
      return classes(STATUS_BG.YELLOW, "text-white");
    }
    case alarmLevelFromJSON(AlarmLevel.AL_LOW): {
      return "bg-yellow-500 text-black";
    }
    case alarmLevelFromJSON(AlarmLevel.AL_HIDDEN): {
      return "bg-zinc-800 text-white";
    }
    default: {
      return "";
    }
  }
};

const getColorByImpact = (input: AlarmImpact | number | undefined): string => {
  const impact = typeof input === "string" ? input : alarmImpactFromJSON(input);
  switch (impact) {
    case alarmImpactFromJSON(AlarmImpact.AI_CRITICAL): {
      return classes(STATUS_BG.RED, "text-white");
    }
    case alarmImpactFromJSON(AlarmImpact.AI_DEGRADED):
    case alarmImpactFromJSON(AlarmImpact.AI_OFFLINE): {
      return classes(STATUS_BG.YELLOW, "text-white");
    }
    default: {
      return "";
    }
  }
};

const CELL_PADDING_CLASS = "py-4";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

interface BaseProps {
  alarms: AlarmResponse[] | undefined;
  className?: string;
}

interface FleetProps extends BaseProps {
  fleet: true;
}

interface RobotProps extends BaseProps {
  serial: string | undefined;
}

const isFleet = (props: Props): props is FleetProps => "fleet" in props;

const isRobot = (props: Props): props is RobotProps => "serial" in props;

type Props = FleetProps | RobotProps;

const _AlarmTable: FunctionComponent<Props> = (props) => {
  const alarmsInput = props.alarms;
  const { isInternal } = useSelf();
  const today = useMemo(() => DateTime.local(), []);

  const { t, i18n } = useTranslation();

  const { data: summaries, isLoading } = useQueryPopups(useListRobotsQuery({}));

  const canSeeHidden = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_internal,
      PermissionDomain.all
    ),
  ]);

  const [datePage, setDatePage] = useState<number>(0);
  const alarms = useMemo(
    () =>
      alarmsInput
        ?.map((alarm) => ({
          ...alarm,
          endedAt: alarm.endedAt === 0 ? Infinity : alarm.endedAt,
        }))
        .filter((alarm) => {
          // hide hidden alarms for customer
          if (
            !canSeeHidden &&
            alarm.alarm?.level === alarmLevelFromJSON(AlarmLevel.AL_HIDDEN)
          ) {
            return false;
          }
          // show ongoing alarms on today page
          if (datePage === 0 && isOngoing(alarm)) {
            return true;
          }
          // only show alarms for selected offset
          const date = DateTime.fromMillis(alarm.endedAt);
          return isSameDate(date, today.plus({ days: datePage }));
        }),
    [alarmsInput, canSeeHidden, today, datePage]
  );

  const { searchText, setSearchText, results } = useFuzzySearch<AlarmResponse>({
    items: alarms ?? [],
    options: {
      keys: [
        "alarm.robotId",
        "alarm.alarmCode",
        "alarm.description",
        "alarm.identifier",
        "alarm.impact",
      ],
    },
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "endedAt", sort: "desc" },
  ]);

  const columns = useMemo<GridColDef<AlarmResponse>[]>(
    () => [
      {
        ...defaultColumn,
        field: "alarm.robotId",
        headerName: t("models.robots.robot_one"),
        cellClassName: classes(CELL_PADDING_CLASS, "font-mono font-bold"),
        valueGetter: (value, alarm) => {
          if (isLoading) {
            return "Loading...";
          }
          const serial = summaries?.find(
            (summary) => summary.robot?.db?.id === alarm.robotId
          )?.robot?.serial;
          if (!serial) {
            return "Unknown";
          }
          return isInternal ? serial : getCustomerSerial(t, serial);
        },
      },
      {
        ...defaultColumn,
        field: "alarm.identifier",
        headerName: t("models.alarms.fields.identifier"),
        cellClassName: classes(CELL_PADDING_CLASS, "font-mono font-bold"),
        valueGetter: (value, alarm) => alarm.alarm?.identifier,
      },
      {
        ...defaultColumn,
        field: "alarm.code",
        headerName: t("models.alarms.fields.code"),
        cellClassName: classes(CELL_PADDING_CLASS, "font-mono font-bold"),
        valueGetter: (value, alarm) => alarm.alarm?.alarmCode,
      },
      {
        ...defaultColumn,
        field: "alarm.level",
        headerName: t("models.alarms.fields.level.name"),
        cellClassName: ({ row: alarm }) =>
          classes(CELL_PADDING_CLASS, {
            [getColorByLevel(alarm.alarm?.level)]: isOngoing(alarm),
          }),
        valueGetter: (value, alarm) =>
          formatAlarmLevel(t, alarm.alarm?.level ?? 0),
      },
      {
        ...defaultColumn,
        field: "alarm.impact",
        headerName: t("models.alarms.fields.impact.name"),
        cellClassName: ({ row: alarm }) =>
          classes(CELL_PADDING_CLASS, {
            [getColorByImpact(alarm.alarm?.impact)]: isOngoing(alarm),
          }),
        valueGetter: (value, alarm) =>
          formatAlarmImpact(t, alarm.alarm?.impact ?? 0),
      },
      {
        ...defaultColumn,
        field: "alarm.description",
        headerName: t("models.alarms.fields.description"),
        valueGetter: (value, alarm) => alarm.alarm?.description,
      },
      {
        ...defaultColumn,
        field: "startedAt",
        headerName: t("models.alarms.fields.started"),
        renderCell: ({ row: alarm }) => (
          <Tooltip
            title={DateTime.fromMillis(alarm.startedAt).toLocaleString(
              DateTime.DATETIME_FULL,
              { locale: i18n.language }
            )}
            arrow
          >
            <span className="whitespace-nowrap">
              {DateTime.fromMillis(alarm.startedAt).toRelative({
                locale: i18n.language,
              })}
            </span>
          </Tooltip>
        ),
        valueFormatter: (value) =>
          DateTime.fromMillis(value).toFormat(DATETIME_EXCEL),
      },
      {
        ...defaultColumn,
        field: "endedAt",
        headerName: t("models.alarms.fields.duration.name"),
        renderCell: ({ row: alarm }) => {
          return isOngoing(alarm) ? (
            <Tooltip
              title={formatDuration(
                DateTime.fromMillis(alarm.startedAt).diffNow().milliseconds,
                i18n
              )}
              arrow
            >
              <span className="whitespace-nowrap text-yellow-300 font-bold">
                {t("models.alarms.fields.duration.values.ongoing")}
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={DateTime.fromMillis(alarm.endedAt).toLocaleString(
                DateTime.DATETIME_FULL,
                { locale: i18n.language }
              )}
              arrow
            >
              <span className="whitespace-nowrap">
                {formatDuration(
                  DateTime.fromMillis(alarm.endedAt).diff(
                    DateTime.fromMillis(alarm.startedAt)
                  ).milliseconds,
                  i18n
                )}
              </span>
            </Tooltip>
          );
        },
      },
    ],
    [i18n, isInternal, isLoading, summaries, t]
  );

  if (isRobot(props) && !props.serial) {
    return;
  }

  return (
    <>
      <CarbonDataGrid<AlarmResponse>
        header={
          <>
            <SearchField
              value={searchText}
              onChange={setSearchText}
              label={t("utils.actions.searchLong", {
                subject: capitalize(t("models.alarms.alarm_other")),
              })}
            />
            <GridToolbarExport
              csvOptions={{
                fileName: t("components.AlarmTable.export", {
                  robots: isFleet(props)
                    ? t("views.fleet.title")
                    : getCustomerSerial(t, props.serial),
                  date: DateTime.local().toISODate(),
                }),
              }}
              printOptions={{ disableToolbarButton: true }}
              excelOptions={{ disableToolbarButton: true }}
            />
          </>
        }
        columnVisibilityModel={{
          "alarm.robotId": isFleet(props),
          "alarm.identifier": canSeeHidden,
        }}
        slots={{
          footer: CarbonFooter,
        }}
        slotProps={{
          footer: {
            children: (
              <>
                <div />
                <div className="flex gap-4">
                  {range(7, false)
                    .reverse()
                    .map((offset) => (
                      <Link
                        key={offset}
                        component="button"
                        className={classes("no-underline hover:underline", {
                          "text-white": datePage !== -1 * offset,
                          "text-blue-500 font-bold": datePage === -1 * offset,
                        })}
                        onClick={() => setDatePage(-1 * offset)}
                      >
                        {formatDateOffset(t, -1 * offset)}
                      </Link>
                    ))}
                </div>
              </>
            ),
          },
        }}
        className="flex flex-1"
        rows={results}
        getRowId={(row) => row.db?.id ?? -1}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        getRowHeight={() => "auto"}
        disableRowSelectionOnClick
        getRowClassName={({ row: alarm }) =>
          classes({
            "text-white": isOngoing(alarm),
            "text-gray-500": !isOngoing(alarm),
          })
        }
        loading={!alarmsInput}
      />
    </>
  );
};

export const AlarmTable = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.all
    ),
  ],
  _AlarmTable
);
