import { classes } from "portal/utils/theme";
import { convert } from "portal/utils/units/units";
import { FleetSubpath, getFleetPath } from "portal/utils/routing";
import { GlobalHotKeys } from "react-hotkeys";
import { IconButton, Tooltip } from "@mui/material";
import { isUndefined } from "portal/utils/identity";
import { Loading } from "portal/components/Loading";
import { Map } from "portal/components/map/Map";
import { RobotSummaryCard } from "portal/components/robots/RobotSummaryCard";
import { useInterval } from "portal/utils/hooks/useInterval";
import { useListRobotsQuery } from "portal/state/portalApi";
import { useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useRobotHistory } from "../robots/useRobotHistory";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import { WithSkeleton } from "portal/components/WithSkeleton";
import ExitIcon from "@mui/icons-material/CancelPresentationOutlined";
import NextIcon from "@mui/icons-material/SkipNextOutlined";
import PauseIcon from "@mui/icons-material/PauseOutlined";
import PlayIcon from "@mui/icons-material/PlayArrowOutlined";
import PreviousIcon from "@mui/icons-material/SkipPreviousOutlined";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";

const SLIDE_DURATION = convert(15).from("s").to("ms");
const ANIMATION_DURATION = convert(5).from("s").to("ms");

const _MissionControl: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: summaries,
    isLoading: isRobotsLoading,
    isError,
    error,
  } = useQueryPopups(
    useListRobotsQuery({}, { pollingInterval: convert(1).from("min").to("ms") })
  );

  // has to be a ref because it can't be included in dependency arrays
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currentRobot = summaries?.[currentIndex];
  const next = useCallback((): void => {
    if (!summaries) {
      return;
    }
    setCanZoom(true);
    if (currentIndex === summaries.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, summaries]);
  const previous = (): void => {
    if (!summaries) {
      return;
    }
    setCanZoom(true);
    if (currentIndex === 0) {
      setCurrentIndex(summaries.length - 1);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const [canZoom, setCanZoom] = useState<boolean>(true);

  const { isPaused, pause, play, toggle } = useInterval(next, SLIDE_DURATION);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    logs,
    blocks,
    hasBlocks,
    isLoading: isHistoryLoading,
  } = useRobotHistory(currentRobot?.robot?.serial);

  const isLoading = isRobotsLoading || isHistoryLoading;
  if (isLoading) {
    return <Loading failed={isError} error={error} />;
  }

  return (
    <>
      <GlobalHotKeys
        allowChanges
        keyMap={{
          NEXT: {
            name: t("utils.actions.next"),
            action: "keyup",
            sequence: "right",
          },
          PREVIOUS: {
            name: t("utils.actions.previous"),
            action: "keyup",
            sequence: "left",
          },
          PAUSE: {
            name: t("utils.actions.pause"),
            action: "keyup",
            sequence: "space",
          },
          EXIT: {
            name: t("utils.actions.exit"),
            action: "keyup",
            sequence: "esc",
          },
        }}
        handlers={{
          NEXT: next,
          PREVIOUS: previous,
          PAUSE: toggle,
          EXIT: () => navigate(getFleetPath(FleetSubpath.ROBOTS)),
        }}
      />
      <Map
        animationDuration={ANIMATION_DURATION}
        className="fixed inset-0 z-[1300]"
        boundsOffset={{
          bottom: 100,
          top: 100,
          left: 100,
          right:
            (wrapperRef.current
              ? wrapperRef.current.offsetWidth
              : window.innerWidth / 3) + 100,
        }}
        legendClassName="top-auto left-10 bottom-28 right-auto w-[210px]"
        robots={currentRobot ? [currentRobot] : []}
        loading={isLoading}
        emptyError={t("views.fleet.missionControl.errors.empty")}
        history={hasBlocks ? undefined : logs}
        blocks={blocks}
        onZoom={() => setCanZoom(true)}
        canZoom={canZoom}
        extraControls={
          <Tooltip title={t("utils.actions.exit")} placement="right" arrow>
            <div
              className={classes(
                "mt-[10px] ml-[10px] print:hidden",
                "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
              )}
            >
              <button
                onClick={() => navigate(getFleetPath(FleetSubpath.ROBOTS))}
              >
                <ExitIcon className="p-1 mapboxgl-ctrl-icon" />
              </button>
            </div>
          </Tooltip>
        }
      />
      <div className="fixed left-10 bottom-10 z-[1301] flex gap-5">
        <Tooltip title={t("utils.actions.previous")} placement="top" arrow>
          <IconButton
            onClick={() => previous()}
            className="text-white w-14 h-14"
          >
            <PreviousIcon className="w-14 h-14" />
          </IconButton>
        </Tooltip>
        {isPaused ? (
          <Tooltip title={t("utils.actions.play")} placement="top" arrow>
            <IconButton
              size="large"
              onClick={() => play()}
              className="text-white w-14 h-14"
            >
              <PlayIcon className="w-14 h-14" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("utils.actions.pause")} placement="top" arrow>
            <IconButton
              size="large"
              onClick={() => pause()}
              className="text-white w-14 h-14"
            >
              <PauseIcon className="w-14 h-14" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("utils.actions.next")} placement="top" arrow>
          <IconButton
            size="large"
            onClick={() => next()}
            className="text-white w-14 h-14"
          >
            <NextIcon className="w-14 h-14" />
          </IconButton>
        </Tooltip>
      </div>
      <WithSkeleton
        variant="rectangular"
        success={!isUndefined(currentRobot?.robot)}
        className="fixed top-10 right-10 z-[1301] max-w-1/3 shadow-xl"
      >
        {!isUndefined(currentRobot?.robot) && (
          <div ref={wrapperRef}>
            <RobotSummaryCard
              serial={currentRobot.robot.serial}
              summary={currentRobot}
            />
          </div>
        )}
      </WithSkeleton>
    </>
  );
};

export const MissionControl = withAuthenticationRequired(
  withErrorBoundary({}, _MissionControl)
);
