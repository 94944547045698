import { buildPermission } from "portal/utils/auth";
import { capitalize, titleCase } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { CustomerEditor } from "portal/components/customers/CustomerEditor";
import { CustomerResponse } from "protos/portal/customers";
import { entries } from "portal/utils/objects";
import { getCustomerPath } from "portal/utils/routing";
import { Header } from "portal/components/header/Header";
import { isUndefined } from "portal/utils/identity";
import { NewCustomer } from "portal/components/NewCustomer";
import { NoScroll, Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SearchField } from "portal/components/header/SearchField";
import { toggleableByFlag } from "portal/utils/hooks/useFeatureFlag";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import { useListCustomersQuery } from "portal/state/portalApi";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent, useEffect, useState } from "react";

const _CustomerList: FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: customers,
    isLoading,
    isSuccess,
  } = useQueryPopups(useListCustomersQuery());
  const { t } = useTranslation();

  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerResponse | undefined
  >();
  useEffect(() => {
    if (customers) {
      for (const customer of customers) {
        if (customer.db?.id === Number(id)) {
          setSelectedCustomer(customer);
        }
      }
    }
  }, [customers, id]);

  // fuzzy search
  const { searchText, setSearchText, results } =
    useFuzzySearch<CustomerResponse>({
      items: customers ?? [],
    });

  return (
    <>
      <Header title={titleCase(t("models.customers.customer_other"))}>
        <SearchField
          value={searchText}
          label={t("utils.actions.searchLong", {
            subject: capitalize(
              t("models.customers.customer", {
                count: customers?.length ?? 0,
              })
            ),
          })}
          onChange={(value) => setSearchText(value)}
          success={isSuccess}
        />
        <NewCustomer hotkey="n" />
      </Header>
      <Page>
        <NoScroll>
          <CarbonDataGrid<CustomerResponse>
            className="flex flex-1"
            dimensionClasses={classes({
              "mr-80": !isUndefined(selectedCustomer),
            })}
            rows={results}
            getRowId={(customer) => customers?.indexOf(customer) ?? -1}
            getRowClassName={({ row: customer }) =>
              classes("cursor-pointer", {
                "bg-blue-500": customer === selectedCustomer,
              })
            }
            hideFooter
            onRowClick={({ row: customer }) =>
              navigate(getCustomerPath(customer.db.id))
            }
            columns={[
              {
                disableColumnMenu: true,
                field: "name",
                headerName: t("models.customers.fields.name"),
                flex: 1,
              },
              {
                disableColumnMenu: true,
                type: "boolean",
                field: "weeklyReportEnabled",
                headerName: t(
                  "models.customers.fields.weeklyReportEnabled.name"
                ),
                cellClassName: ({ row: customer }) =>
                  classes({
                    "text-green-500 font-bold": customer.weeklyReportEnabled,
                  }),
                valueFormatter: (value) =>
                  // idk why valueFormatter's value is never because it definitely is the result of valueGetter
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  value
                    ? t("utils.descriptors.enabled")
                    : t("utils.descriptors.disabled"),
              },
              {
                disableColumnMenu: true,
                field: "featureFlags",
                headerName: t("models.customers.fields.featureFlags.name"),
                cellClassName: ({ value }) =>
                  classes({
                    "text-yellow-500 font-bold": value > 0,
                  }),
                valueGetter: (value, customer) => {
                  let count = 0;
                  if (isUndefined(customer.featureFlags)) {
                    return 0;
                  }
                  for (const [flag, canToggle] of entries(toggleableByFlag)) {
                    if (!canToggle) {
                      continue;
                    }
                    if (customer.featureFlags[flag]) {
                      count++;
                    }
                  }
                  return count;
                },
              },
            ]}
            disableRowSelectionOnClick
            loading={isLoading}
          />
          <CustomerEditor
            customer={selectedCustomer}
            open={!isUndefined(selectedCustomer)}
            onClose={() => setSelectedCustomer(undefined)}
          />
        </NoScroll>
      </Page>
    </>
  );
};

export const CustomerList = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.customers,
        PermissionDomain.all
      ),
    ],
    _CustomerList
  )
);
