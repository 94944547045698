// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/health.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { AlarmRow as AlarmRow1 } from "../frontend/alarm";
import { LaserStateList } from "../frontend/laser";
import { Status, statusFromJSON, statusToJSON } from "../frontend/status_bar";
import { LaserChangeTimes } from "../metrics/metrics";

export const protobufPackage = "carbon.portal.health";

/** ########### START DEPRECATED ALARMS */
export enum AlarmLevel {
  /** @deprecated */
  ALARM_UNKNOWN = 0,
  /** @deprecated */
  ALARM_CRITICAL = 1,
  /** @deprecated */
  ALARM_HIGH = 2,
  /** @deprecated */
  ALARM_MEDIUM = 3,
  /** @deprecated */
  ALARM_LOW = 4,
  /** @deprecated */
  ALARM_HIDDEN = 5,
  UNRECOGNIZED = -1,
}

export function alarmLevelFromJSON(object: any): AlarmLevel {
  switch (object) {
    case 0:
    case "ALARM_UNKNOWN":
      return AlarmLevel.ALARM_UNKNOWN;
    case 1:
    case "ALARM_CRITICAL":
      return AlarmLevel.ALARM_CRITICAL;
    case 2:
    case "ALARM_HIGH":
      return AlarmLevel.ALARM_HIGH;
    case 3:
    case "ALARM_MEDIUM":
      return AlarmLevel.ALARM_MEDIUM;
    case 4:
    case "ALARM_LOW":
      return AlarmLevel.ALARM_LOW;
    case 5:
    case "ALARM_HIDDEN":
      return AlarmLevel.ALARM_HIDDEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlarmLevel.UNRECOGNIZED;
  }
}

export function alarmLevelToJSON(object: AlarmLevel): string {
  switch (object) {
    case AlarmLevel.ALARM_UNKNOWN:
      return "ALARM_UNKNOWN";
    case AlarmLevel.ALARM_CRITICAL:
      return "ALARM_CRITICAL";
    case AlarmLevel.ALARM_HIGH:
      return "ALARM_HIGH";
    case AlarmLevel.ALARM_MEDIUM:
      return "ALARM_MEDIUM";
    case AlarmLevel.ALARM_LOW:
      return "ALARM_LOW";
    case AlarmLevel.ALARM_HIDDEN:
      return "ALARM_HIDDEN";
    case AlarmLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AlarmImpact {
  /** @deprecated */
  IMPACT_UNDEFINED = 0,
  /** @deprecated */
  IMPACT_CRITICAL = 1,
  /** @deprecated */
  IMPACT_OFFLINE = 2,
  /** @deprecated */
  IMPACT_DEGRADED = 3,
  /** @deprecated */
  IMPACT_NONE = 4,
  UNRECOGNIZED = -1,
}

export function alarmImpactFromJSON(object: any): AlarmImpact {
  switch (object) {
    case 0:
    case "IMPACT_UNDEFINED":
      return AlarmImpact.IMPACT_UNDEFINED;
    case 1:
    case "IMPACT_CRITICAL":
      return AlarmImpact.IMPACT_CRITICAL;
    case 2:
    case "IMPACT_OFFLINE":
      return AlarmImpact.IMPACT_OFFLINE;
    case 3:
    case "IMPACT_DEGRADED":
      return AlarmImpact.IMPACT_DEGRADED;
    case 4:
    case "IMPACT_NONE":
      return AlarmImpact.IMPACT_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlarmImpact.UNRECOGNIZED;
  }
}

export function alarmImpactToJSON(object: AlarmImpact): string {
  switch (object) {
    case AlarmImpact.IMPACT_UNDEFINED:
      return "IMPACT_UNDEFINED";
    case AlarmImpact.IMPACT_CRITICAL:
      return "IMPACT_CRITICAL";
    case AlarmImpact.IMPACT_OFFLINE:
      return "IMPACT_OFFLINE";
    case AlarmImpact.IMPACT_DEGRADED:
      return "IMPACT_DEGRADED";
    case AlarmImpact.IMPACT_NONE:
      return "IMPACT_NONE";
    case AlarmImpact.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** @deprecated */
export interface AlarmRow {
  timestampMs: number;
  alarmCode: string;
  description: string;
  level: AlarmLevel;
  identifier: string;
  acknowledged: boolean;
  impact: AlarmImpact;
}

export interface Location {
  x: number;
  y: number;
  z: number;
}

export interface FieldConfig {
  bandingEnabled: boolean;
  bandingDynamic: boolean;
  activeBandConfig: string;
  activeThinningConfigId: string;
  activeJobId: string;
  activeAlmanacId: string;
  activeDiscriminatorId: string;
  isWeeding: boolean;
  isThinning: boolean;
  activeBandConfigName: string;
  activeThinningConfigName: string;
  activeJobName: string;
  activeAlmanacName: string;
  activeDiscriminatorName: string;
  activeModelinatorId: string;
  activeVelocityEstimatorId: string;
  activeVelocityEstimatorName: string;
  activeCategoryCollectionId: string;
}

export interface Versions {
  current: string;
  latest: string;
}

export interface WeedingPerformance {
  areaWeededTotal: number;
  areaWeededToday: number;
  timeWeededToday: number;
}

export interface Performance {
  weeding: WeedingPerformance | undefined;
}

export interface DailyMetrics {
  metrics: { [key: string]: string };
}

export interface DailyMetrics_MetricsEntry {
  key: string;
  value: string;
}

export interface Metrics {
  dailyMetrics: { [key: string]: DailyMetrics };
}

export interface Metrics_DailyMetricsEntry {
  key: string;
  value: DailyMetrics | undefined;
}

export interface HealthLog {
  /** @deprecated */
  alarms: AlarmRow[];
  location: Location | undefined;
  model: string;
  models: string[];
  performance: Performance | undefined;
  reportedAt: number;
  robotSerial: string;
  status: Status;
  statusChangedAt: number;
  /**
   * DEPRECATED: use crop_id
   *
   * @deprecated
   */
  crop: string;
  p2p: string;
  softwareVersion: string;
  targetVersion: string;
  targetVersionReady: boolean;
  statusMessage: string;
  metricTotals: { [key: string]: number };
  alarmList: AlarmRow1[];
  fieldConfig: FieldConfig | undefined;
  metrics: Metrics | undefined;
  cropId: string;
  robotRuntime240v: number;
  laserState: LaserStateList | undefined;
  laserChangeTimes: LaserChangeTimes | undefined;
  hostSerials: { [key: string]: string };
  featureFlags: { [key: string]: boolean };
}

export interface HealthLog_MetricTotalsEntry {
  key: string;
  value: number;
}

export interface HealthLog_HostSerialsEntry {
  key: string;
  value: string;
}

export interface HealthLog_FeatureFlagsEntry {
  key: string;
  value: boolean;
}

export interface HistoryResponse {
  logs: HealthLog[];
  queryLimitReached: boolean;
}

export interface IssueReport {
  description: string;
  phoneNumber: string;
  robotSerial: string;
  reportedAt: number;
  /**
   * Deprecated, use crop_id
   *
   * @deprecated
   */
  crop: string;
  modelId: string;
  softwareVersion: string;
  cropId: string;
}

function createBaseAlarmRow(): AlarmRow {
  return { timestampMs: 0, alarmCode: "", description: "", level: 0, identifier: "", acknowledged: false, impact: 0 };
}

export const AlarmRow: MessageFns<AlarmRow> = {
  encode(message: AlarmRow, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampMs !== 0) {
      writer.uint32(8).int64(message.timestampMs);
    }
    if (message.alarmCode !== "") {
      writer.uint32(18).string(message.alarmCode);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.level !== 0) {
      writer.uint32(32).int32(message.level);
    }
    if (message.identifier !== "") {
      writer.uint32(42).string(message.identifier);
    }
    if (message.acknowledged !== false) {
      writer.uint32(48).bool(message.acknowledged);
    }
    if (message.impact !== 0) {
      writer.uint32(56).int32(message.impact);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlarmRow {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlarmRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.acknowledged = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.impact = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlarmRow {
    return {
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      alarmCode: isSet(object.alarmCode) ? globalThis.String(object.alarmCode) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      level: isSet(object.level) ? alarmLevelFromJSON(object.level) : 0,
      identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "",
      acknowledged: isSet(object.acknowledged) ? globalThis.Boolean(object.acknowledged) : false,
      impact: isSet(object.impact) ? alarmImpactFromJSON(object.impact) : 0,
    };
  },

  toJSON(message: AlarmRow): unknown {
    const obj: any = {};
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.alarmCode !== "") {
      obj.alarmCode = message.alarmCode;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.level !== 0) {
      obj.level = alarmLevelToJSON(message.level);
    }
    if (message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    if (message.acknowledged !== false) {
      obj.acknowledged = message.acknowledged;
    }
    if (message.impact !== 0) {
      obj.impact = alarmImpactToJSON(message.impact);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlarmRow>, I>>(base?: I): AlarmRow {
    return AlarmRow.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlarmRow>, I>>(object: I): AlarmRow {
    const message = createBaseAlarmRow();
    message.timestampMs = object.timestampMs ?? 0;
    message.alarmCode = object.alarmCode ?? "";
    message.description = object.description ?? "";
    message.level = object.level ?? 0;
    message.identifier = object.identifier ?? "";
    message.acknowledged = object.acknowledged ?? false;
    message.impact = object.impact ?? 0;
    return message;
  },
};

function createBaseLocation(): Location {
  return { x: 0, y: 0, z: 0 };
}

export const Location: MessageFns<Location> = {
  encode(message: Location, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(9).double(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(17).double(message.y);
    }
    if (message.z !== 0) {
      writer.uint32(25).double(message.z);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Location {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.x = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.y = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.z = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Location {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      z: isSet(object.z) ? globalThis.Number(object.z) : 0,
    };
  },

  toJSON(message: Location): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.z !== 0) {
      obj.z = message.z;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Location>, I>>(base?: I): Location {
    return Location.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Location>, I>>(object: I): Location {
    const message = createBaseLocation();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.z = object.z ?? 0;
    return message;
  },
};

function createBaseFieldConfig(): FieldConfig {
  return {
    bandingEnabled: false,
    bandingDynamic: false,
    activeBandConfig: "",
    activeThinningConfigId: "",
    activeJobId: "",
    activeAlmanacId: "",
    activeDiscriminatorId: "",
    isWeeding: false,
    isThinning: false,
    activeBandConfigName: "",
    activeThinningConfigName: "",
    activeJobName: "",
    activeAlmanacName: "",
    activeDiscriminatorName: "",
    activeModelinatorId: "",
    activeVelocityEstimatorId: "",
    activeVelocityEstimatorName: "",
    activeCategoryCollectionId: "",
  };
}

export const FieldConfig: MessageFns<FieldConfig> = {
  encode(message: FieldConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bandingEnabled !== false) {
      writer.uint32(8).bool(message.bandingEnabled);
    }
    if (message.bandingDynamic !== false) {
      writer.uint32(16).bool(message.bandingDynamic);
    }
    if (message.activeBandConfig !== "") {
      writer.uint32(26).string(message.activeBandConfig);
    }
    if (message.activeThinningConfigId !== "") {
      writer.uint32(34).string(message.activeThinningConfigId);
    }
    if (message.activeJobId !== "") {
      writer.uint32(42).string(message.activeJobId);
    }
    if (message.activeAlmanacId !== "") {
      writer.uint32(50).string(message.activeAlmanacId);
    }
    if (message.activeDiscriminatorId !== "") {
      writer.uint32(58).string(message.activeDiscriminatorId);
    }
    if (message.isWeeding !== false) {
      writer.uint32(64).bool(message.isWeeding);
    }
    if (message.isThinning !== false) {
      writer.uint32(72).bool(message.isThinning);
    }
    if (message.activeBandConfigName !== "") {
      writer.uint32(82).string(message.activeBandConfigName);
    }
    if (message.activeThinningConfigName !== "") {
      writer.uint32(90).string(message.activeThinningConfigName);
    }
    if (message.activeJobName !== "") {
      writer.uint32(98).string(message.activeJobName);
    }
    if (message.activeAlmanacName !== "") {
      writer.uint32(106).string(message.activeAlmanacName);
    }
    if (message.activeDiscriminatorName !== "") {
      writer.uint32(114).string(message.activeDiscriminatorName);
    }
    if (message.activeModelinatorId !== "") {
      writer.uint32(122).string(message.activeModelinatorId);
    }
    if (message.activeVelocityEstimatorId !== "") {
      writer.uint32(130).string(message.activeVelocityEstimatorId);
    }
    if (message.activeVelocityEstimatorName !== "") {
      writer.uint32(138).string(message.activeVelocityEstimatorName);
    }
    if (message.activeCategoryCollectionId !== "") {
      writer.uint32(146).string(message.activeCategoryCollectionId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FieldConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bandingEnabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bandingDynamic = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activeBandConfig = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeThinningConfigId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.activeJobId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.activeAlmanacId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.activeDiscriminatorId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.isWeeding = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isThinning = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.activeBandConfigName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.activeThinningConfigName = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.activeJobName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.activeAlmanacName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.activeDiscriminatorName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.activeModelinatorId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.activeVelocityEstimatorId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.activeVelocityEstimatorName = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.activeCategoryCollectionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldConfig {
    return {
      bandingEnabled: isSet(object.bandingEnabled) ? globalThis.Boolean(object.bandingEnabled) : false,
      bandingDynamic: isSet(object.bandingDynamic) ? globalThis.Boolean(object.bandingDynamic) : false,
      activeBandConfig: isSet(object.activeBandConfig) ? globalThis.String(object.activeBandConfig) : "",
      activeThinningConfigId: isSet(object.activeThinningConfigId)
        ? globalThis.String(object.activeThinningConfigId)
        : "",
      activeJobId: isSet(object.activeJobId) ? globalThis.String(object.activeJobId) : "",
      activeAlmanacId: isSet(object.activeAlmanacId) ? globalThis.String(object.activeAlmanacId) : "",
      activeDiscriminatorId: isSet(object.activeDiscriminatorId) ? globalThis.String(object.activeDiscriminatorId) : "",
      isWeeding: isSet(object.isWeeding) ? globalThis.Boolean(object.isWeeding) : false,
      isThinning: isSet(object.isThinning) ? globalThis.Boolean(object.isThinning) : false,
      activeBandConfigName: isSet(object.activeBandConfigName) ? globalThis.String(object.activeBandConfigName) : "",
      activeThinningConfigName: isSet(object.activeThinningConfigName)
        ? globalThis.String(object.activeThinningConfigName)
        : "",
      activeJobName: isSet(object.activeJobName) ? globalThis.String(object.activeJobName) : "",
      activeAlmanacName: isSet(object.activeAlmanacName) ? globalThis.String(object.activeAlmanacName) : "",
      activeDiscriminatorName: isSet(object.activeDiscriminatorName)
        ? globalThis.String(object.activeDiscriminatorName)
        : "",
      activeModelinatorId: isSet(object.activeModelinatorId) ? globalThis.String(object.activeModelinatorId) : "",
      activeVelocityEstimatorId: isSet(object.activeVelocityEstimatorId)
        ? globalThis.String(object.activeVelocityEstimatorId)
        : "",
      activeVelocityEstimatorName: isSet(object.activeVelocityEstimatorName)
        ? globalThis.String(object.activeVelocityEstimatorName)
        : "",
      activeCategoryCollectionId: isSet(object.activeCategoryCollectionId)
        ? globalThis.String(object.activeCategoryCollectionId)
        : "",
    };
  },

  toJSON(message: FieldConfig): unknown {
    const obj: any = {};
    if (message.bandingEnabled !== false) {
      obj.bandingEnabled = message.bandingEnabled;
    }
    if (message.bandingDynamic !== false) {
      obj.bandingDynamic = message.bandingDynamic;
    }
    if (message.activeBandConfig !== "") {
      obj.activeBandConfig = message.activeBandConfig;
    }
    if (message.activeThinningConfigId !== "") {
      obj.activeThinningConfigId = message.activeThinningConfigId;
    }
    if (message.activeJobId !== "") {
      obj.activeJobId = message.activeJobId;
    }
    if (message.activeAlmanacId !== "") {
      obj.activeAlmanacId = message.activeAlmanacId;
    }
    if (message.activeDiscriminatorId !== "") {
      obj.activeDiscriminatorId = message.activeDiscriminatorId;
    }
    if (message.isWeeding !== false) {
      obj.isWeeding = message.isWeeding;
    }
    if (message.isThinning !== false) {
      obj.isThinning = message.isThinning;
    }
    if (message.activeBandConfigName !== "") {
      obj.activeBandConfigName = message.activeBandConfigName;
    }
    if (message.activeThinningConfigName !== "") {
      obj.activeThinningConfigName = message.activeThinningConfigName;
    }
    if (message.activeJobName !== "") {
      obj.activeJobName = message.activeJobName;
    }
    if (message.activeAlmanacName !== "") {
      obj.activeAlmanacName = message.activeAlmanacName;
    }
    if (message.activeDiscriminatorName !== "") {
      obj.activeDiscriminatorName = message.activeDiscriminatorName;
    }
    if (message.activeModelinatorId !== "") {
      obj.activeModelinatorId = message.activeModelinatorId;
    }
    if (message.activeVelocityEstimatorId !== "") {
      obj.activeVelocityEstimatorId = message.activeVelocityEstimatorId;
    }
    if (message.activeVelocityEstimatorName !== "") {
      obj.activeVelocityEstimatorName = message.activeVelocityEstimatorName;
    }
    if (message.activeCategoryCollectionId !== "") {
      obj.activeCategoryCollectionId = message.activeCategoryCollectionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldConfig>, I>>(base?: I): FieldConfig {
    return FieldConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldConfig>, I>>(object: I): FieldConfig {
    const message = createBaseFieldConfig();
    message.bandingEnabled = object.bandingEnabled ?? false;
    message.bandingDynamic = object.bandingDynamic ?? false;
    message.activeBandConfig = object.activeBandConfig ?? "";
    message.activeThinningConfigId = object.activeThinningConfigId ?? "";
    message.activeJobId = object.activeJobId ?? "";
    message.activeAlmanacId = object.activeAlmanacId ?? "";
    message.activeDiscriminatorId = object.activeDiscriminatorId ?? "";
    message.isWeeding = object.isWeeding ?? false;
    message.isThinning = object.isThinning ?? false;
    message.activeBandConfigName = object.activeBandConfigName ?? "";
    message.activeThinningConfigName = object.activeThinningConfigName ?? "";
    message.activeJobName = object.activeJobName ?? "";
    message.activeAlmanacName = object.activeAlmanacName ?? "";
    message.activeDiscriminatorName = object.activeDiscriminatorName ?? "";
    message.activeModelinatorId = object.activeModelinatorId ?? "";
    message.activeVelocityEstimatorId = object.activeVelocityEstimatorId ?? "";
    message.activeVelocityEstimatorName = object.activeVelocityEstimatorName ?? "";
    message.activeCategoryCollectionId = object.activeCategoryCollectionId ?? "";
    return message;
  },
};

function createBaseVersions(): Versions {
  return { current: "", latest: "" };
}

export const Versions: MessageFns<Versions> = {
  encode(message: Versions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.current !== "") {
      writer.uint32(10).string(message.current);
    }
    if (message.latest !== "") {
      writer.uint32(18).string(message.latest);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Versions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVersions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.current = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latest = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Versions {
    return {
      current: isSet(object.current) ? globalThis.String(object.current) : "",
      latest: isSet(object.latest) ? globalThis.String(object.latest) : "",
    };
  },

  toJSON(message: Versions): unknown {
    const obj: any = {};
    if (message.current !== "") {
      obj.current = message.current;
    }
    if (message.latest !== "") {
      obj.latest = message.latest;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Versions>, I>>(base?: I): Versions {
    return Versions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Versions>, I>>(object: I): Versions {
    const message = createBaseVersions();
    message.current = object.current ?? "";
    message.latest = object.latest ?? "";
    return message;
  },
};

function createBaseWeedingPerformance(): WeedingPerformance {
  return { areaWeededTotal: 0, areaWeededToday: 0, timeWeededToday: 0 };
}

export const WeedingPerformance: MessageFns<WeedingPerformance> = {
  encode(message: WeedingPerformance, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.areaWeededTotal !== 0) {
      writer.uint32(9).double(message.areaWeededTotal);
    }
    if (message.areaWeededToday !== 0) {
      writer.uint32(17).double(message.areaWeededToday);
    }
    if (message.timeWeededToday !== 0) {
      writer.uint32(24).int64(message.timeWeededToday);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WeedingPerformance {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWeedingPerformance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.areaWeededTotal = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.areaWeededToday = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timeWeededToday = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WeedingPerformance {
    return {
      areaWeededTotal: isSet(object.areaWeededTotal) ? globalThis.Number(object.areaWeededTotal) : 0,
      areaWeededToday: isSet(object.areaWeededToday) ? globalThis.Number(object.areaWeededToday) : 0,
      timeWeededToday: isSet(object.timeWeededToday) ? globalThis.Number(object.timeWeededToday) : 0,
    };
  },

  toJSON(message: WeedingPerformance): unknown {
    const obj: any = {};
    if (message.areaWeededTotal !== 0) {
      obj.areaWeededTotal = message.areaWeededTotal;
    }
    if (message.areaWeededToday !== 0) {
      obj.areaWeededToday = message.areaWeededToday;
    }
    if (message.timeWeededToday !== 0) {
      obj.timeWeededToday = Math.round(message.timeWeededToday);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WeedingPerformance>, I>>(base?: I): WeedingPerformance {
    return WeedingPerformance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WeedingPerformance>, I>>(object: I): WeedingPerformance {
    const message = createBaseWeedingPerformance();
    message.areaWeededTotal = object.areaWeededTotal ?? 0;
    message.areaWeededToday = object.areaWeededToday ?? 0;
    message.timeWeededToday = object.timeWeededToday ?? 0;
    return message;
  },
};

function createBasePerformance(): Performance {
  return { weeding: undefined };
}

export const Performance: MessageFns<Performance> = {
  encode(message: Performance, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.weeding !== undefined) {
      WeedingPerformance.encode(message.weeding, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Performance {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePerformance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.weeding = WeedingPerformance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Performance {
    return { weeding: isSet(object.weeding) ? WeedingPerformance.fromJSON(object.weeding) : undefined };
  },

  toJSON(message: Performance): unknown {
    const obj: any = {};
    if (message.weeding !== undefined) {
      obj.weeding = WeedingPerformance.toJSON(message.weeding);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Performance>, I>>(base?: I): Performance {
    return Performance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Performance>, I>>(object: I): Performance {
    const message = createBasePerformance();
    message.weeding = (object.weeding !== undefined && object.weeding !== null)
      ? WeedingPerformance.fromPartial(object.weeding)
      : undefined;
    return message;
  },
};

function createBaseDailyMetrics(): DailyMetrics {
  return { metrics: {} };
}

export const DailyMetrics: MessageFns<DailyMetrics> = {
  encode(message: DailyMetrics, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.metrics).forEach(([key, value]) => {
      DailyMetrics_MetricsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetrics {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetrics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DailyMetrics_MetricsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.metrics[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetrics {
    return {
      metrics: isObject(object.metrics)
        ? Object.entries(object.metrics).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DailyMetrics): unknown {
    const obj: any = {};
    if (message.metrics) {
      const entries = Object.entries(message.metrics);
      if (entries.length > 0) {
        obj.metrics = {};
        entries.forEach(([k, v]) => {
          obj.metrics[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetrics>, I>>(base?: I): DailyMetrics {
    return DailyMetrics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetrics>, I>>(object: I): DailyMetrics {
    const message = createBaseDailyMetrics();
    message.metrics = Object.entries(object.metrics ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDailyMetrics_MetricsEntry(): DailyMetrics_MetricsEntry {
  return { key: "", value: "" };
}

export const DailyMetrics_MetricsEntry: MessageFns<DailyMetrics_MetricsEntry> = {
  encode(message: DailyMetrics_MetricsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetrics_MetricsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetrics_MetricsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetrics_MetricsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: DailyMetrics_MetricsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetrics_MetricsEntry>, I>>(base?: I): DailyMetrics_MetricsEntry {
    return DailyMetrics_MetricsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetrics_MetricsEntry>, I>>(object: I): DailyMetrics_MetricsEntry {
    const message = createBaseDailyMetrics_MetricsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseMetrics(): Metrics {
  return { dailyMetrics: {} };
}

export const Metrics: MessageFns<Metrics> = {
  encode(message: Metrics, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.dailyMetrics).forEach(([key, value]) => {
      Metrics_DailyMetricsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Metrics {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetrics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Metrics_DailyMetricsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.dailyMetrics[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Metrics {
    return {
      dailyMetrics: isObject(object.dailyMetrics)
        ? Object.entries(object.dailyMetrics).reduce<{ [key: string]: DailyMetrics }>((acc, [key, value]) => {
          acc[key] = DailyMetrics.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Metrics): unknown {
    const obj: any = {};
    if (message.dailyMetrics) {
      const entries = Object.entries(message.dailyMetrics);
      if (entries.length > 0) {
        obj.dailyMetrics = {};
        entries.forEach(([k, v]) => {
          obj.dailyMetrics[k] = DailyMetrics.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Metrics>, I>>(base?: I): Metrics {
    return Metrics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Metrics>, I>>(object: I): Metrics {
    const message = createBaseMetrics();
    message.dailyMetrics = Object.entries(object.dailyMetrics ?? {}).reduce<{ [key: string]: DailyMetrics }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DailyMetrics.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseMetrics_DailyMetricsEntry(): Metrics_DailyMetricsEntry {
  return { key: "", value: undefined };
}

export const Metrics_DailyMetricsEntry: MessageFns<Metrics_DailyMetricsEntry> = {
  encode(message: Metrics_DailyMetricsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DailyMetrics.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Metrics_DailyMetricsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetrics_DailyMetricsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DailyMetrics.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Metrics_DailyMetricsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DailyMetrics.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Metrics_DailyMetricsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DailyMetrics.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Metrics_DailyMetricsEntry>, I>>(base?: I): Metrics_DailyMetricsEntry {
    return Metrics_DailyMetricsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Metrics_DailyMetricsEntry>, I>>(object: I): Metrics_DailyMetricsEntry {
    const message = createBaseMetrics_DailyMetricsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DailyMetrics.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseHealthLog(): HealthLog {
  return {
    alarms: [],
    location: undefined,
    model: "",
    models: [],
    performance: undefined,
    reportedAt: 0,
    robotSerial: "",
    status: 0,
    statusChangedAt: 0,
    crop: "",
    p2p: "",
    softwareVersion: "",
    targetVersion: "",
    targetVersionReady: false,
    statusMessage: "",
    metricTotals: {},
    alarmList: [],
    fieldConfig: undefined,
    metrics: undefined,
    cropId: "",
    robotRuntime240v: 0,
    laserState: undefined,
    laserChangeTimes: undefined,
    hostSerials: {},
    featureFlags: {},
  };
}

export const HealthLog: MessageFns<HealthLog> = {
  encode(message: HealthLog, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.alarms) {
      AlarmRow.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(18).fork()).join();
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    for (const v of message.models) {
      writer.uint32(34).string(v!);
    }
    if (message.performance !== undefined) {
      Performance.encode(message.performance, writer.uint32(42).fork()).join();
    }
    if (message.reportedAt !== 0) {
      writer.uint32(48).int64(message.reportedAt);
    }
    if (message.robotSerial !== "") {
      writer.uint32(58).string(message.robotSerial);
    }
    if (message.status !== 0) {
      writer.uint32(72).int32(message.status);
    }
    if (message.statusChangedAt !== 0) {
      writer.uint32(80).int64(message.statusChangedAt);
    }
    if (message.crop !== "") {
      writer.uint32(90).string(message.crop);
    }
    if (message.p2p !== "") {
      writer.uint32(98).string(message.p2p);
    }
    if (message.softwareVersion !== "") {
      writer.uint32(106).string(message.softwareVersion);
    }
    if (message.targetVersion !== "") {
      writer.uint32(114).string(message.targetVersion);
    }
    if (message.targetVersionReady !== false) {
      writer.uint32(120).bool(message.targetVersionReady);
    }
    if (message.statusMessage !== "") {
      writer.uint32(130).string(message.statusMessage);
    }
    Object.entries(message.metricTotals).forEach(([key, value]) => {
      HealthLog_MetricTotalsEntry.encode({ key: key as any, value }, writer.uint32(138).fork()).join();
    });
    for (const v of message.alarmList) {
      AlarmRow1.encode(v!, writer.uint32(146).fork()).join();
    }
    if (message.fieldConfig !== undefined) {
      FieldConfig.encode(message.fieldConfig, writer.uint32(154).fork()).join();
    }
    if (message.metrics !== undefined) {
      Metrics.encode(message.metrics, writer.uint32(162).fork()).join();
    }
    if (message.cropId !== "") {
      writer.uint32(170).string(message.cropId);
    }
    if (message.robotRuntime240v !== 0) {
      writer.uint32(176).uint32(message.robotRuntime240v);
    }
    if (message.laserState !== undefined) {
      LaserStateList.encode(message.laserState, writer.uint32(186).fork()).join();
    }
    if (message.laserChangeTimes !== undefined) {
      LaserChangeTimes.encode(message.laserChangeTimes, writer.uint32(194).fork()).join();
    }
    Object.entries(message.hostSerials).forEach(([key, value]) => {
      HealthLog_HostSerialsEntry.encode({ key: key as any, value }, writer.uint32(202).fork()).join();
    });
    Object.entries(message.featureFlags).forEach(([key, value]) => {
      HealthLog_FeatureFlagsEntry.encode({ key: key as any, value }, writer.uint32(210).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HealthLog {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(AlarmRow.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.models.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.performance = Performance.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.reportedAt = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.robotSerial = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.statusChangedAt = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.p2p = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.softwareVersion = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.targetVersion = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.targetVersionReady = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.statusMessage = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          const entry17 = HealthLog_MetricTotalsEntry.decode(reader, reader.uint32());
          if (entry17.value !== undefined) {
            message.metricTotals[entry17.key] = entry17.value;
          }
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.alarmList.push(AlarmRow1.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.fieldConfig = FieldConfig.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.metrics = Metrics.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.robotRuntime240v = reader.uint32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.laserState = LaserStateList.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.laserChangeTimes = LaserChangeTimes.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          const entry25 = HealthLog_HostSerialsEntry.decode(reader, reader.uint32());
          if (entry25.value !== undefined) {
            message.hostSerials[entry25.key] = entry25.value;
          }
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          const entry26 = HealthLog_FeatureFlagsEntry.decode(reader, reader.uint32());
          if (entry26.value !== undefined) {
            message.featureFlags[entry26.key] = entry26.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HealthLog {
    return {
      alarms: globalThis.Array.isArray(object?.alarms) ? object.alarms.map((e: any) => AlarmRow.fromJSON(e)) : [],
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      models: globalThis.Array.isArray(object?.models) ? object.models.map((e: any) => globalThis.String(e)) : [],
      performance: isSet(object.performance) ? Performance.fromJSON(object.performance) : undefined,
      reportedAt: isSet(object.reportedAt) ? globalThis.Number(object.reportedAt) : 0,
      robotSerial: isSet(object.robotSerial) ? globalThis.String(object.robotSerial) : "",
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      statusChangedAt: isSet(object.statusChangedAt) ? globalThis.Number(object.statusChangedAt) : 0,
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      p2p: isSet(object.p2p) ? globalThis.String(object.p2p) : "",
      softwareVersion: isSet(object.softwareVersion) ? globalThis.String(object.softwareVersion) : "",
      targetVersion: isSet(object.targetVersion) ? globalThis.String(object.targetVersion) : "",
      targetVersionReady: isSet(object.targetVersionReady) ? globalThis.Boolean(object.targetVersionReady) : false,
      statusMessage: isSet(object.statusMessage) ? globalThis.String(object.statusMessage) : "",
      metricTotals: isObject(object.metricTotals)
        ? Object.entries(object.metricTotals).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      alarmList: globalThis.Array.isArray(object?.alarmList)
        ? object.alarmList.map((e: any) => AlarmRow1.fromJSON(e))
        : [],
      fieldConfig: isSet(object.fieldConfig) ? FieldConfig.fromJSON(object.fieldConfig) : undefined,
      metrics: isSet(object.metrics) ? Metrics.fromJSON(object.metrics) : undefined,
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      robotRuntime240v: isSet(object.robotRuntime240v) ? globalThis.Number(object.robotRuntime240v) : 0,
      laserState: isSet(object.laserState) ? LaserStateList.fromJSON(object.laserState) : undefined,
      laserChangeTimes: isSet(object.laserChangeTimes) ? LaserChangeTimes.fromJSON(object.laserChangeTimes) : undefined,
      hostSerials: isObject(object.hostSerials)
        ? Object.entries(object.hostSerials).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      featureFlags: isObject(object.featureFlags)
        ? Object.entries(object.featureFlags).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: HealthLog): unknown {
    const obj: any = {};
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => AlarmRow.toJSON(e));
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.models?.length) {
      obj.models = message.models;
    }
    if (message.performance !== undefined) {
      obj.performance = Performance.toJSON(message.performance);
    }
    if (message.reportedAt !== 0) {
      obj.reportedAt = Math.round(message.reportedAt);
    }
    if (message.robotSerial !== "") {
      obj.robotSerial = message.robotSerial;
    }
    if (message.status !== 0) {
      obj.status = statusToJSON(message.status);
    }
    if (message.statusChangedAt !== 0) {
      obj.statusChangedAt = Math.round(message.statusChangedAt);
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.p2p !== "") {
      obj.p2p = message.p2p;
    }
    if (message.softwareVersion !== "") {
      obj.softwareVersion = message.softwareVersion;
    }
    if (message.targetVersion !== "") {
      obj.targetVersion = message.targetVersion;
    }
    if (message.targetVersionReady !== false) {
      obj.targetVersionReady = message.targetVersionReady;
    }
    if (message.statusMessage !== "") {
      obj.statusMessage = message.statusMessage;
    }
    if (message.metricTotals) {
      const entries = Object.entries(message.metricTotals);
      if (entries.length > 0) {
        obj.metricTotals = {};
        entries.forEach(([k, v]) => {
          obj.metricTotals[k] = Math.round(v);
        });
      }
    }
    if (message.alarmList?.length) {
      obj.alarmList = message.alarmList.map((e) => AlarmRow1.toJSON(e));
    }
    if (message.fieldConfig !== undefined) {
      obj.fieldConfig = FieldConfig.toJSON(message.fieldConfig);
    }
    if (message.metrics !== undefined) {
      obj.metrics = Metrics.toJSON(message.metrics);
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.robotRuntime240v !== 0) {
      obj.robotRuntime240v = Math.round(message.robotRuntime240v);
    }
    if (message.laserState !== undefined) {
      obj.laserState = LaserStateList.toJSON(message.laserState);
    }
    if (message.laserChangeTimes !== undefined) {
      obj.laserChangeTimes = LaserChangeTimes.toJSON(message.laserChangeTimes);
    }
    if (message.hostSerials) {
      const entries = Object.entries(message.hostSerials);
      if (entries.length > 0) {
        obj.hostSerials = {};
        entries.forEach(([k, v]) => {
          obj.hostSerials[k] = v;
        });
      }
    }
    if (message.featureFlags) {
      const entries = Object.entries(message.featureFlags);
      if (entries.length > 0) {
        obj.featureFlags = {};
        entries.forEach(([k, v]) => {
          obj.featureFlags[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HealthLog>, I>>(base?: I): HealthLog {
    return HealthLog.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HealthLog>, I>>(object: I): HealthLog {
    const message = createBaseHealthLog();
    message.alarms = object.alarms?.map((e) => AlarmRow.fromPartial(e)) || [];
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.model = object.model ?? "";
    message.models = object.models?.map((e) => e) || [];
    message.performance = (object.performance !== undefined && object.performance !== null)
      ? Performance.fromPartial(object.performance)
      : undefined;
    message.reportedAt = object.reportedAt ?? 0;
    message.robotSerial = object.robotSerial ?? "";
    message.status = object.status ?? 0;
    message.statusChangedAt = object.statusChangedAt ?? 0;
    message.crop = object.crop ?? "";
    message.p2p = object.p2p ?? "";
    message.softwareVersion = object.softwareVersion ?? "";
    message.targetVersion = object.targetVersion ?? "";
    message.targetVersionReady = object.targetVersionReady ?? false;
    message.statusMessage = object.statusMessage ?? "";
    message.metricTotals = Object.entries(object.metricTotals ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.alarmList = object.alarmList?.map((e) => AlarmRow1.fromPartial(e)) || [];
    message.fieldConfig = (object.fieldConfig !== undefined && object.fieldConfig !== null)
      ? FieldConfig.fromPartial(object.fieldConfig)
      : undefined;
    message.metrics = (object.metrics !== undefined && object.metrics !== null)
      ? Metrics.fromPartial(object.metrics)
      : undefined;
    message.cropId = object.cropId ?? "";
    message.robotRuntime240v = object.robotRuntime240v ?? 0;
    message.laserState = (object.laserState !== undefined && object.laserState !== null)
      ? LaserStateList.fromPartial(object.laserState)
      : undefined;
    message.laserChangeTimes = (object.laserChangeTimes !== undefined && object.laserChangeTimes !== null)
      ? LaserChangeTimes.fromPartial(object.laserChangeTimes)
      : undefined;
    message.hostSerials = Object.entries(object.hostSerials ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.featureFlags = Object.entries(object.featureFlags ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Boolean(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseHealthLog_MetricTotalsEntry(): HealthLog_MetricTotalsEntry {
  return { key: "", value: 0 };
}

export const HealthLog_MetricTotalsEntry: MessageFns<HealthLog_MetricTotalsEntry> = {
  encode(message: HealthLog_MetricTotalsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint64(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HealthLog_MetricTotalsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLog_MetricTotalsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HealthLog_MetricTotalsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: HealthLog_MetricTotalsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HealthLog_MetricTotalsEntry>, I>>(base?: I): HealthLog_MetricTotalsEntry {
    return HealthLog_MetricTotalsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HealthLog_MetricTotalsEntry>, I>>(object: I): HealthLog_MetricTotalsEntry {
    const message = createBaseHealthLog_MetricTotalsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseHealthLog_HostSerialsEntry(): HealthLog_HostSerialsEntry {
  return { key: "", value: "" };
}

export const HealthLog_HostSerialsEntry: MessageFns<HealthLog_HostSerialsEntry> = {
  encode(message: HealthLog_HostSerialsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HealthLog_HostSerialsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLog_HostSerialsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HealthLog_HostSerialsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: HealthLog_HostSerialsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HealthLog_HostSerialsEntry>, I>>(base?: I): HealthLog_HostSerialsEntry {
    return HealthLog_HostSerialsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HealthLog_HostSerialsEntry>, I>>(object: I): HealthLog_HostSerialsEntry {
    const message = createBaseHealthLog_HostSerialsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseHealthLog_FeatureFlagsEntry(): HealthLog_FeatureFlagsEntry {
  return { key: "", value: false };
}

export const HealthLog_FeatureFlagsEntry: MessageFns<HealthLog_FeatureFlagsEntry> = {
  encode(message: HealthLog_FeatureFlagsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== false) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HealthLog_FeatureFlagsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLog_FeatureFlagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HealthLog_FeatureFlagsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
    };
  },

  toJSON(message: HealthLog_FeatureFlagsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== false) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HealthLog_FeatureFlagsEntry>, I>>(base?: I): HealthLog_FeatureFlagsEntry {
    return HealthLog_FeatureFlagsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HealthLog_FeatureFlagsEntry>, I>>(object: I): HealthLog_FeatureFlagsEntry {
    const message = createBaseHealthLog_FeatureFlagsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseHistoryResponse(): HistoryResponse {
  return { logs: [], queryLimitReached: false };
}

export const HistoryResponse: MessageFns<HistoryResponse> = {
  encode(message: HistoryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.logs) {
      HealthLog.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.queryLimitReached !== false) {
      writer.uint32(16).bool(message.queryLimitReached);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HistoryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.logs.push(HealthLog.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.queryLimitReached = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoryResponse {
    return {
      logs: globalThis.Array.isArray(object?.logs) ? object.logs.map((e: any) => HealthLog.fromJSON(e)) : [],
      queryLimitReached: isSet(object.queryLimitReached) ? globalThis.Boolean(object.queryLimitReached) : false,
    };
  },

  toJSON(message: HistoryResponse): unknown {
    const obj: any = {};
    if (message.logs?.length) {
      obj.logs = message.logs.map((e) => HealthLog.toJSON(e));
    }
    if (message.queryLimitReached !== false) {
      obj.queryLimitReached = message.queryLimitReached;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoryResponse>, I>>(base?: I): HistoryResponse {
    return HistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoryResponse>, I>>(object: I): HistoryResponse {
    const message = createBaseHistoryResponse();
    message.logs = object.logs?.map((e) => HealthLog.fromPartial(e)) || [];
    message.queryLimitReached = object.queryLimitReached ?? false;
    return message;
  },
};

function createBaseIssueReport(): IssueReport {
  return {
    description: "",
    phoneNumber: "",
    robotSerial: "",
    reportedAt: 0,
    crop: "",
    modelId: "",
    softwareVersion: "",
    cropId: "",
  };
}

export const IssueReport: MessageFns<IssueReport> = {
  encode(message: IssueReport, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    if (message.robotSerial !== "") {
      writer.uint32(26).string(message.robotSerial);
    }
    if (message.reportedAt !== 0) {
      writer.uint32(32).int64(message.reportedAt);
    }
    if (message.crop !== "") {
      writer.uint32(42).string(message.crop);
    }
    if (message.modelId !== "") {
      writer.uint32(50).string(message.modelId);
    }
    if (message.softwareVersion !== "") {
      writer.uint32(58).string(message.softwareVersion);
    }
    if (message.cropId !== "") {
      writer.uint32(66).string(message.cropId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IssueReport {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.robotSerial = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reportedAt = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.modelId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.softwareVersion = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.cropId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueReport {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
      robotSerial: isSet(object.robotSerial) ? globalThis.String(object.robotSerial) : "",
      reportedAt: isSet(object.reportedAt) ? globalThis.Number(object.reportedAt) : 0,
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      modelId: isSet(object.modelId) ? globalThis.String(object.modelId) : "",
      softwareVersion: isSet(object.softwareVersion) ? globalThis.String(object.softwareVersion) : "",
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
    };
  },

  toJSON(message: IssueReport): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.robotSerial !== "") {
      obj.robotSerial = message.robotSerial;
    }
    if (message.reportedAt !== 0) {
      obj.reportedAt = Math.round(message.reportedAt);
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.modelId !== "") {
      obj.modelId = message.modelId;
    }
    if (message.softwareVersion !== "") {
      obj.softwareVersion = message.softwareVersion;
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueReport>, I>>(base?: I): IssueReport {
    return IssueReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueReport>, I>>(object: I): IssueReport {
    const message = createBaseIssueReport();
    message.description = object.description ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.robotSerial = object.robotSerial ?? "";
    message.reportedAt = object.reportedAt ?? 0;
    message.crop = object.crop ?? "";
    message.modelId = object.modelId ?? "";
    message.softwareVersion = object.softwareVersion ?? "";
    message.cropId = object.cropId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
