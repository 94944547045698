import { classes } from "portal/utils/theme";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
  MultiInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useShadowValue } from "portal/utils/hooks/useShadowValue";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import React, { FunctionComponent } from "react";

export const getDateRangeDescription = (range: DateRange<DateTime>): string =>
  `${range[0]?.toFormat(DATE_PATH_FORMAT)} - ${range[1]?.toFormat(
    DATE_PATH_FORMAT
  )}`;

const _CarbonDateRangePicker: FunctionComponent<
  DateRangePickerProps<DateTime> & {
    openEnded?: boolean;
  }
> = ({
  slots,
  slotProps,
  value: externalValue,
  readOnly,
  onChange,
  openEnded = false,
  ...props
}) => {
  const { t } = useTranslation();

  const [value, setValue, { skipRender }] = useShadowValue<
    DateRange<DateTime> | undefined
  >(externalValue);

  if (skipRender) {
    return;
  }

  return (
    <DateRangePicker<DateTime>
      readOnly={readOnly}
      localeText={{
        start: t("components.DateRangePicker.startDate"),
        end: t("components.DateRangePicker.endDate"),
        clearButtonLabel: t("components.DateRangePicker.clear"),
      }}
      slots={{
        field: MultiInputDateRangeField,
        ...slots,
      }}
      value={value ?? externalValue}
      onChange={([startDate, endDate], event) => {
        setValue([startDate, endDate]);
        if (!openEnded && (!startDate || !endDate)) {
          return;
        }

        if (
          (startDate && !startDate.isValid) ||
          (endDate && !endDate.isValid)
        ) {
          return;
        }
        onChange?.([startDate, endDate], event);
      }}
      slotProps={{
        fieldRoot: {
          className: classes("group flex gap-2", props.className, {
            "Mui-readOnly": readOnly,
          }),
        },
        fieldSeparator: {
          className: "hidden",
        },
        ...slotProps,
      }}
      {...props}
    />
  );
};

export const CarbonDateRangePicker = withErrorBoundary(
  {
    i18nKey: "components.DateRangePicker.error",
    small: true,
  },
  _CarbonDateRangePicker
);
