import { BLUE_LOADING_BUTTON, classes } from "portal/utils/theme";
import { captureException } from "@sentry/react";
import {
  FeedbackContextProvider,
  FeedbackDialog,
  FeedbackForm,
} from "./FeedbackDialog";
import { isAbort } from "portal/state/portalApi";
import { LoadingButton } from "@mui/lab";
import { ParseKeys, t } from "i18next";
import ErrorIcon from "@mui/icons-material/WarningOutlined";
import FeedbackIcon from "@mui/icons-material/FeedbackOutlined";
import React, {
  Component,
  ComponentType,
  forwardRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithChildren,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
  useState,
} from "react";
import ReloadIcon from "@mui/icons-material/AutorenewOutlined";

const ICON_SIZE_LARGE = "text-6xl";
const ICON_SIZE_SMALL = "text-2xl";

interface ErrorProps {
  small?: boolean;
  errorMessage: string;
}

export const Error: FunctionComponent<ErrorProps> = ({
  errorMessage,
  small,
}) => {
  const [isReloading, setReloading] = useState<boolean>(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  return (
    <div className="w-full h-full flex-1 flex items-center justify-center">
      <div
        className={classes("text-gray-500 flex gap-4 items-center", {
          "flex-col": !small,
        })}
      >
        {isReloading ? (
          <ReloadIcon
            className={classes("animate-spin", {
              [ICON_SIZE_LARGE]: !small,
              [ICON_SIZE_SMALL]: small,
            })}
          />
        ) : (
          <ErrorIcon
            className={classes({
              [ICON_SIZE_LARGE]: !small,
              [ICON_SIZE_SMALL]: small,
            })}
          />
        )}
        <span>{errorMessage}</span>
        {small && (
          <>
            <LoadingButton
              {...BLUE_LOADING_BUTTON}
              onClick={() => setFeedbackOpen(true)}
              startIcon={<FeedbackIcon />}
            >
              {t("components.FeedbackDialog.feedback")}
            </LoadingButton>
            <FeedbackContextProvider>
              <FeedbackDialog
                open={isFeedbackOpen}
                setOpen={(open) => setFeedbackOpen(open)}
                onSuccess={() => window.location.reload()}
              />
            </FeedbackContextProvider>
            <LoadingButton
              {...BLUE_LOADING_BUTTON}
              loading={isReloading}
              onClick={() => {
                setReloading(true);
                window.location.reload();
              }}
              startIcon={<FeedbackIcon />}
            >
              {t("utils.actions.reload")}
            </LoadingButton>
          </>
        )}
        {!small && (
          <FeedbackContextProvider>
            <FeedbackForm />
          </FeedbackContextProvider>
        )}
      </div>
    </div>
  );
};

/**
 * Version of error boundary that:
 * * Logs to Sentry if configured
 * * Supports multiple layouts
 * * Supports custom messages
 */
interface ErrorBoundaryProps extends PropsWithChildren {
  i18nKey?: ParseKeys;
  small?: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: {
    hasError: boolean;
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): any {
    return { hasError: true };
  }

  componentDidCatch(error: Error, ...arguments_: any[]): void {
    // ignore aborted request errors
    if (isAbort(error)) {
      return;
    }
    if (window._jsenv.REACT_APP_SENTRY_DSN) {
      captureException(error);
    } else {
      console.error(error, ...arguments_);
    }
  }

  render(): ReactNode {
    let errorMessage = this.props.i18nKey ? t(this.props.i18nKey) : "";
    if (!errorMessage) {
      errorMessage = this.props.small
        ? t("utils.descriptors.error")
        : t("components.ErrorBoundary.error");
    }

    return this.state.hasError ? (
      <Error errorMessage={errorMessage} small={this.props.small} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;

/**
 * HOC version of ErrorBoundary
 */
export const withErrorBoundary = <P extends object, R>(
  errorBoundaryProps: ErrorBoundaryProps,
  WrappedComponent: ComponentType<P>
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<R>> =>
  forwardRef<R, P>((props, ref) => (
    <ErrorBoundary {...errorBoundaryProps}>
      <WrappedComponent {...props} ref={ref} />
    </ErrorBoundary>
  ));
