import { createBrowserHistory } from "history";
import { DATE_PATH_FORMAT } from "./dates";
import { DateTime } from "luxon";
import { ElementType, ReactNode } from "react";
import { toQuery } from "./browser";

export const history = createBrowserHistory();

interface Props {
  [index: string]: any;
}

interface RenderProps extends Props {
  render: () => ReactNode;
}

interface ComponentProps extends Props {
  component: ElementType;
}

export type RouteProps = RenderProps | ComponentProps;

// Gets a URL ecoded pathname for the current page
export const getSafePath = (): string =>
  encodeURIComponent(window.location.pathname);

export enum Slug {
  CUSTOMER_ID = ":id",
  USER_ID = ":id",
  NEW = "new",
  REPORT = ":report",
  REPORT_INSTANCE = ":instance",
  SERIAL = ":serial",
}

export enum Path {
  ACCOUNT = "/account",
  ADMIN = "/admin",
  BASE = "/",
  CALLBACK = "/callback",
  CUSTOMERS = "/customers",
  FARMS = "/farms",
  FLEET = "/fleet",
  GDPR_CONSENT = "/consent",
  KNOWLEDGE = "/knowledge",
  LOGIN = "/login",
  LOGOUT = "/logout",
  OFFLINE = "/offline",
  REPORTS = "/reports",
  ROBOTS = `${Path.FLEET}/robots`,
  SETTINGS = "/settings",
  USERS = "/users",
}

export enum RobotSubpath {
  ADMIN = "/admin",
  ALARMS = "/alarms",
  ALMANAC = "/almanac",
  AUTOTRACTOR = "/autotractor",
  CONFIG = "/config",
  CROPS = "/crops",
  DISCRIMINATOR = "/discriminator",
  FIELDS = "/fields",
  HARDWARE = "/hardware",
  HARDWARE_COMPUTERS = "/hardware/computers",
  HARDWARE_VERSIONS = "/hardware/versions",
  HARDWARE_LASERS = "/hardware/lasers",
  HISTORY = "/history",
  SUMMARY = "/",
  SUPPORT = "/support",
  UPLOADS = "/uploads",
  VELOCITY_ESTIMATOR = "/velocity",
}

export enum AutotractorSubpath {
  JOBS = "/jobs",
}

export enum FleetSubpath {
  ALARMS = "/alarms",
  HARDWARE = "/hardware",
  ROBOTS = "/robots",
  MISSION_CONTROL = "/mission-control",
}

export enum ReportSubpath {
  SCHEDULED = "/scheduled",
  EXPLORE = "/explore",
}

export enum AdminSubpath {
  ALARMS = "/alarms",
  ALMANAC = "/almanac",
  CONFIG = "/config",
  DISCRIMINATOR = "/discriminator",
  VELOCITY_ESTIMATOR = "/velocity",
  PORTAL = "/portal",
  CATEGORY_COLLECTION_PROFILE = "/category-collection",
}

export const getCustomerPath = (id: string = Slug.CUSTOMER_ID): string =>
  `${Path.CUSTOMERS}/${id}`;

export const getReportPath = (slug: string = Slug.REPORT): string =>
  `${Path.REPORTS}${ReportSubpath.SCHEDULED}/${slug}`;

export const getCropPath = (serial: string, cropId: string): string =>
  `${getRobotPath(serial, RobotSubpath.CROPS)}/${cropId}`;

export const getModelinatorPath = (
  serial: string,
  cropId: string,
  modelId: string
): string => `${getCropPath(serial, cropId)}/${modelId}`;

export const getAlmanacPath = (serial: string, uuid: string): string =>
  `${getRobotPath(serial, RobotSubpath.ALMANAC)}/${uuid}`;

export const getDiscriminatorPath = (serial: string, uuid: string): string =>
  `${getRobotPath(serial, RobotSubpath.DISCRIMINATOR)}/${uuid}`;

export const getReportInstancesPath = (slug: string = Slug.REPORT): string =>
  `${Path.REPORTS}${ReportSubpath.SCHEDULED}/${slug}/runs`;

export const getReportInstancePath = (
  reportSlug: string = Slug.REPORT,
  instanceSlug: string = Slug.REPORT_INSTANCE
): string =>
  `${Path.REPORTS}${ReportSubpath.SCHEDULED}/${reportSlug}/runs/${instanceSlug}`;

export const getRobotPath = (
  serial: string = Slug.SERIAL,
  subpage?: RobotSubpath | undefined
): string =>
  `${Path.ROBOTS}/${serial}${serial === Slug.SERIAL ? "/*" : subpage ?? ""}`;

export const getRobotHistoryPath = (
  serial: string = Slug.SERIAL,
  jobId: string
): string => {
  const today = DateTime.local();
  const lastWeek = today.minus({ days: 7 });
  return `${getRobotPath(serial, RobotSubpath.HISTORY)}/${lastWeek.toFormat(
    DATE_PATH_FORMAT
  )}/${today.toFormat(DATE_PATH_FORMAT)}?${toQuery({ jobId })}`;
};

export const getFleetPath = (subpage?: FleetSubpath | undefined): string =>
  `${Path.FLEET}${subpage ?? "/*"}`;

export const getAdminPath = (subpage?: AdminSubpath | undefined): string =>
  `${Path.ADMIN}${subpage ?? "/*"}`;

export const getUserPath = (id: string = Slug.USER_ID): string =>
  `${Path.USERS}/${id}`;

export const getFarmPath = (farmId: string): string =>
  `${Path.FARMS}/${farmId}`;
