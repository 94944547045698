import { Alert, Link } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import {
  DataGridPremiumProps,
  GridColDef,
  GridEventListener,
  GridGroupingColDefOverride,
  GridGroupNode,
  GridToolbarExport,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { findWhere } from "portal/utils/arrays";
import { FleetView } from "protos/portal/users";
import {
  getColumns,
  GroupedRobotSummaryResponse,
} from "portal/utils/fleetViews";
import { getRobotPath } from "portal/utils/routing";
import { isUndefined } from "portal/utils/identity";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RobotSummaryResponse } from "protos/portal/robots";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useCallback, useMemo } from "react";

interface Props {
  columns?: FleetView["columns"];
  isLoading?: boolean;
  name: string;
  pinnedRobotIds?: FleetView["pinnedRobotIds"];
  setSelectedView: (id?: number) => void;
  visibleRobots: RobotSummaryResponse[];
}

const _FleetRobotTable: FunctionComponent<Props> = ({
  columns: columnIds,
  isLoading,
  name,
  pinnedRobotIds,
  visibleRobots,
}) => {
  const navigate = useNavigate();
  const { user, measurementSystem } = useSelf();
  const { t, i18n } = useTranslation();
  const gridApi = useGridApiRef();

  const canReadInternalMetrics = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.metrics_internal,
      PermissionDomain.all
    ),
  ]);

  const initialRowGroupingState = useKeepGroupedColumnsHidden({
    apiRef: gridApi,
    initialState: {
      rowGrouping: {
        model: ["group"],
      },
    },
  });

  const isEmpty = visibleRobots.length === 0 && !isLoading;

  const columns = useMemo<GridColDef<GroupedRobotSummaryResponse>[]>(() => {
    if (!user || !columnIds) {
      return [];
    }
    const allColumns = getColumns(i18n, t, user, measurementSystem);
    return columnIds
      .map((id) => {
        const column = findWhere(allColumns, { id });
        if (!column) {
          // we can't find the column for some reason, we filter this below
          return;
        }
        if (!canReadInternalMetrics && column.isInternal) {
          // don't show internal columns to customers, we filter this below
          return;
        }
        const tableColumn = { ...column };
        if (!isUndefined(column.getLink)) {
          tableColumn.renderCell = ({ row: summary, value }) => {
            const link = column.getLink?.(summary);
            return link ? (
              <Link
                component={RouterLink}
                to={link}
                underline="hover"
                color="inherit"
                onClick={(event) => event.stopPropagation()}
              >
                {value}
              </Link>
            ) : (
              value
            );
          };
        }
        return tableColumn;
      })
      .filter((column) => !isUndefined(column));
  }, [columnIds, i18n, canReadInternalMetrics, measurementSystem, t, user]);

  const pinnedRobots = useMemo(
    () =>
      visibleRobots.filter(
        (summary) =>
          summary.robot?.db?.id && pinnedRobotIds?.includes(summary.robot.db.id)
      ),
    [pinnedRobotIds, visibleRobots]
  );
  const hasPinnedRobots = pinnedRobots.length > 0;
  const otherRobots = useMemo(
    () =>
      visibleRobots.filter(
        (summary) =>
          summary.robot?.db?.id &&
          !pinnedRobotIds?.includes(summary.robot.db.id)
      ),
    [pinnedRobotIds, visibleRobots]
  );
  const hasOtherRobots = hasPinnedRobots && otherRobots.length > 0;

  const robots = useMemo<GroupedRobotSummaryResponse[]>(
    () => [
      ...pinnedRobots.map((summary) => ({
        ...summary,
        group: t("views.fleet.views.fields.pinnedRobotIds"),
      })),
      ...otherRobots.map((summary) => ({
        ...summary,
        group: hasOtherRobots
          ? t("views.fleet.views.fields.otherRobots", {
              robotCount: otherRobots.length,
            })
          : "",
      })),
    ],
    [hasOtherRobots, otherRobots, pinnedRobots, t]
  );

  const header = useMemo(
    () => (
      <>
        <div />
        <GridToolbarExport
          csvOptions={{
            fileName: name,
          }}
          excelOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
      </>
    ),
    [name]
  );

  const isGroupExpandedByDefault = useCallback(
    (node: GridGroupNode) =>
      Boolean(
        node.groupingKey === t("views.fleet.views.fields.pinnedRobotIds")
      ),
    [t]
  );

  const groupingColDefinition = useMemo<
    GridGroupingColDefOverride<GroupedRobotSummaryResponse>
  >(
    () => ({
      hideDescendantCount: true,
      width: 200,
    }),
    []
  );

  const initialState = useMemo(
    () => ({
      ...initialRowGroupingState,
      columns: {
        columnVisibilityModel: {
          group: false,
        },
      },
    }),
    [initialRowGroupingState]
  );

  // MUI requires null
  // eslint-disable-next-line unicorn/no-null
  const getAggregationPosition = useCallback(() => null, []);

  const getRowId = useCallback(
    (summary: GroupedRobotSummaryResponse) => summary.robot?.db?.id ?? "",
    []
  );

  const getRowClassName = useCallback(() => classes("cursor-pointer"), []);

  const onRowClick = useCallback<GridEventListener<"rowClick">>(
    ({ row: summary, id }, event) => {
      // if it's a group, toggle it
      const rowNode = gridApi.current.getRowNode(id);
      if (rowNode && rowNode.type === "group") {
        gridApi.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        return;
      }

      // open in new tab if requested
      const path = getRobotPath(summary.robot.serial);
      if (event.ctrlKey || event.metaKey) {
        window.open(path, "_blank");
        return;
      }

      // otherwise navigate here
      navigate(path);
    },
    [gridApi, navigate]
  );

  // hide columns other than the grouping column for group rows
  const getCellClassName = useCallback<
    Exclude<DataGridPremiumProps["getCellClassName"], undefined>
  >(
    ({ rowNode, colDef }) =>
      rowNode.type === "group" && colDef.type !== "custom"
        ? classes("text-transparent")
        : "",
    []
  );

  return (
    <>
      {isEmpty && (
        <Alert severity="warning">{t("views.fleet.robots.errors.empty")}</Alert>
      )}
      <CarbonDataGrid<GroupedRobotSummaryResponse>
        className="basis-0 flex-grow"
        apiRef={gridApi}
        header={header}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        rowSelectionModel={[]}
        disableAggregation
        disableRowSelectionOnClick
        getAggregationPosition={getAggregationPosition}
        disableRowGrouping={!hasPinnedRobots}
        groupingColDef={groupingColDefinition}
        initialState={initialState}
        loading={isLoading}
        rows={robots}
        columns={columns}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        onRowClick={onRowClick}
        getCellClassName={getCellClassName}
      />
    </>
  );
};

export const FleetRobotTable = withAuthenticationRequired(_FleetRobotTable);
