import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { CarbonDateRangePicker } from "../CarbonDateRangePicker";
import { ConfigCrop } from "protos/portal/configs";
import { cropToConfigCrop, getCrops, SortedCrops } from "portal/utils/crops";
import { DateRange } from "@mui/lab";
import { DateTime } from "luxon";
import { getAutocompleteText } from "portal/utils/forms";
import { getDateRangeShortcuts } from "portal/utils/reports";
import { isUndefined } from "portal/utils/identity";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  SMALL_AUTOCOMPLETE_DARK,
  SMALL_TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import {
  useGetConfigQuery,
  useListCropsQuery,
  useListRobotCropsQuery,
  useListRobotsQuery,
} from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useMemo } from "react";
import WarningIcon from "@mui/icons-material/WarningAmber";

export interface Entity {
  id: string;
  name: string;
}

export interface LabelPointFilter {
  crops: Entity[];
  robots: Entity[];
  capturedAt: DateRange<DateTime> | undefined;
}
interface LabelPointFiltersProps {
  serial?: string;
  filters: LabelPointFilter;
  onChange: (filters: LabelPointFilter) => void;
}

export const LabelPointFilters: FunctionComponent<LabelPointFiltersProps> = ({
  serial,
  filters,
  onChange,
}) => {
  const { t } = useTranslation();

  const canSeeAllCrops = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.crops_basic,
      PermissionDomain.all
    ),
  ]);

  // crops
  const {
    data: config,
    isFetching: isConfigLoading,
    isError: isConfigError,
  } = useQueryPopups(useGetConfigQuery(serial ? { serial } : skipToken), {
    errorVariant: "warning",
  });

  const {
    data: allCrops,
    isFetching: isCropsLoading,
    isError: isAllCropsError,
  } = useQueryPopups(
    useListCropsQuery(undefined, {
      skip: !isUndefined(serial),
    })
  );

  const {
    data: robotCrops,
    isFetching: isRobotLoading,
    isError: isCropsError,
  } = useQueryPopups(useListRobotCropsQuery(serial ? { serial } : skipToken));

  const sortedCrops = useMemo<SortedCrops | undefined>(() => {
    if (!serial) {
      if (!allCrops) {
        return;
      }
      const archived: ConfigCrop[] = [];
      const all: ConfigCrop[] = [];
      for (const crop of allCrops) {
        const configCrop = cropToConfigCrop(crop);
        if (crop.archived) {
          archived.push(configCrop);
        }
        all.push(configCrop);
      }
      return {
        all,
        enabled: all,
        disabled: [],
        archived,
        pinnedAndEnabledCrops: 0,
      };
    }

    if (!config) {
      return;
    }

    return getCrops(serial, config.config, robotCrops);
  }, [serial, config, robotCrops, allCrops]);
  const crops = canSeeAllCrops ? sortedCrops?.all : sortedCrops?.enabled;

  // robots
  const {
    data: robotSummaries,
    isLoading: isRobotsLoading,
    error: robotError,
  } = useQueryPopups(useListRobotsQuery({}), {
    errorVariant: "warning",
  });

  const robots = useMemo<Entity[] | undefined>(() => {
    if (!robotSummaries) {
      return;
    }
    const allRobots = [];
    for (const robotSummary of robotSummaries) {
      const { robot } = robotSummary;
      if (!robot) {
        continue;
      }
      const { serial } = robot;
      allRobots.push({
        id: serial,
        name: serial,
      });
    }
    return allRobots;
  }, [robotSummaries]);

  const classNames = "md:max-w-1/3";
  return (
    <div className="flex gap-6 flex-wrap">
      <div className={classNames}>
        <Filter
          loading={isConfigLoading || isCropsLoading || isRobotLoading}
          loadingError={isConfigError || isAllCropsError || isCropsError}
          label={titleCase(t("models.crops.crop_other"))}
          options={(crops || []).map(({ id, commonName }) => ({
            id,
            name: commonName,
          }))}
          value={filters.crops}
          onChange={(value) => onChange({ ...filters, crops: value })}
        />
      </div>
      <div className={classNames}>
        <Filter
          loading={isRobotsLoading}
          loadingError={Boolean(robotError)}
          label={titleCase(t("models.robots.robot_other"))}
          options={robots || []}
          value={filters.robots}
          onChange={(value) => onChange({ ...filters, robots: value })}
        />
      </div>
      <div className={classNames}>
        <CarbonDateRangePicker
          calendars={1}
          openEnded
          disableFuture
          value={filters.capturedAt}
          slotProps={{
            shortcuts: { items: getDateRangeShortcuts(t) },
            textField: {
              ...SMALL_TEXT_FIELD_DARK,
            },
            actionBar: {
              actions: ["clear"],
            },
          }}
          onChange={(newRange) => {
            onChange({ ...filters, capturedAt: newRange });
          }}
        />
      </div>
    </div>
  );
};

type FilterProps = Pick<
  AutocompleteProps<Entity, true, true, false>,
  "loading" | "options" | "value"
> & {
  onChange: (value: Entity[]) => void;
  loadingError: boolean;
  label: TextFieldProps["label"];
};

const Filter: FunctionComponent<FilterProps> = ({
  loading,
  options,
  value,
  label,
  onChange,
  loadingError,
}) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      {...getAutocompleteText(t)}
      {...SMALL_AUTOCOMPLETE_DARK}
      multiple
      loading={loading}
      options={options}
      value={value}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={3}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={option.name}
            size="small"
            classes={{
              root: "bg-white bg-opacity-80 text-gray-800",
              deleteIcon: "text-gray-800",
            }}
          />
        ));
      }}
      onChange={(_: React.SyntheticEvent<Element, Event>, value) => {
        onChange(value);
      }}
      renderInput={(parameters: TextFieldProps) => (
        <TextField
          {...parameters}
          size="small"
          className="min-w-32 md:min-w-48"
          label={label}
          InputProps={{
            ...parameters.InputProps,
            ...SMALL_TEXT_FIELD_DARK.InputProps,
            endAdornment: loadingError ? (
              <InputAdornment
                position="end"
                className="text-yellow-500 text-sm"
              >
                <WarningIcon />
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
    />
  );
};
