import { buildPermission } from "portal/utils/auth";
import { LaserTable } from "portal/components/LaserTable";
import { NoScroll } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useGetHardwareQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent } from "react";

const _FleetHardware: FunctionComponent = () => {
  const { data: hardware, isLoading } = useQueryPopups(useGetHardwareQuery());

  return (
    <NoScroll>
      <LaserTable lasers={hardware?.lasers} fleet loading={isLoading} />
    </NoScroll>
  );
};

export const FleetHardware = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.hardware,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.hardware,
        PermissionDomain.all
      ),
    ],
    _FleetHardware
  )
);
