import { Alert, InputAdornment } from "@mui/material";
import { BLUE_LOADING_BUTTON, TEXT_FIELD_DARK } from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import { capitalize, uncapitalize } from "portal/utils/strings";
import { CarbonCard } from "portal/components/CarbonCard";
import { Field } from "formik";
import { findWhere, without } from "portal/utils/arrays";
import { TextField as FormikTextField } from "formik-mui";
import { isUndefined } from "portal/utils/identity";
import { ListManager } from "portal/components/ListManager";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RobotAllowedAlarm, RobotBlockedAlarm } from "protos/portal/admin";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetRobotAlarmListsQuery,
  useGetRobotQuery,
  useSetRobotAlarmListsMutation,
  useUpdateRobotMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

const _RobotAdmin: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();

  const [setRobotAlarmLists, { isLoading: isSetAlarmListsLoading }] =
    useMutationPopups(useSetRobotAlarmListsMutation(), {
      success: capitalize(
        t("utils.actions.savedLong", {
          subject: t("views.admin.alarms.lists"),
        })
      ),
    });

  const {
    data: robot,
    isError: isGetRobotError,
    isLoading: isGetRobotLoading,
    isSuccess: isGetRobotSuccess,
    isFetching: isGetRobotFetching,
  } = useQueryPopups(useGetRobotQuery(serial ? { serial } : skipToken));

  const [updateRobot] = useMutationPopups(useUpdateRobotMutation(), {
    success: capitalize(
      t("utils.actions.updatedLong", {
        subject: uncapitalize(t("models.robots.fields.supportSlack")),
      })
    ),
  });

  const { data: lists } = useQueryPopups(
    useGetRobotAlarmListsQuery(serial ? { serial } : skipToken)
  );

  if (!serial || !lists) {
    return;
  }

  return (
    <Grid container className="w-full" spacing={2}>
      <CarbonCard
        title={t("models.robots.fields.supportSlack")}
        error={isGetRobotError}
        success={isGetRobotSuccess}
        gridProps={{ xs: 12, md: 12 }}
        formikProps={{
          validationSchema: object({
            supportSlack: string().required(),
          }),
          initialValues: {
            supportSlack: robot?.robot?.supportSlack.replaceAll("#", "") ?? "",
          },
          onSubmit: async ({ supportSlack }): Promise<void> => {
            if (!supportSlack) {
              return;
            }
            await updateRobot({
              serial,
              robot: { supportSlack: `#${supportSlack}` },
            });
          },
        }}
        content={
          <Field
            {...TEXT_FIELD_DARK}
            disabled={isGetRobotLoading}
            component={FormikTextField}
            name="supportSlack"
            label={t("models.robots.fields.supportSlack")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />
        }
        actions={({ isSubmitting, dirty }) => (
          <LoadingButton
            {...BLUE_LOADING_BUTTON}
            type="submit"
            loading={isSubmitting}
            disabled={!dirty || isGetRobotFetching}
            startIcon={<SaveIcon />}
          >
            {t("utils.actions.save")}
          </LoadingButton>
        )}
      />
      <CarbonCard
        title={t("views.admin.alarms.titleAllow")}
        error={false}
        success={true}
        content={
          <>
            <Alert severity="warning">
              {t("views.admin.alarms.allowWarning")}
            </Alert>
            <ListManager
              list={lists.allowed?.alarms.map((alarm) => alarm.code)}
              loading={isSetAlarmListsLoading}
              onAdd={(newCode) => {
                setRobotAlarmLists({
                  serial,
                  lists: {
                    ...lists,
                    allowed: {
                      alarms: [
                        ...(lists.allowed?.alarms ?? []),
                        RobotAllowedAlarm.fromPartial({ code: newCode }),
                      ],
                    },
                  },
                });
              }}
              onRemove={(removedCode) => {
                setRobotAlarmLists({
                  serial,
                  lists: {
                    ...lists,
                    allowed: {
                      alarms: without(
                        lists.allowed?.alarms,
                        findWhere(lists.allowed?.alarms, {
                          code: removedCode,
                        })
                      ).filter((alarm) => !isUndefined(alarm)),
                    },
                  },
                });
              }}
            />
          </>
        }
      />
      <CarbonCard
        title={t("views.admin.alarms.titleBlock")}
        error={false}
        success={true}
        content={
          <>
            <Alert severity="warning">
              {t("views.admin.alarms.blockWarning")}
            </Alert>
            <ListManager
              list={lists.blocked?.alarms.map((alarm) => alarm.code)}
              loading={isSetAlarmListsLoading}
              onAdd={(newCode) => {
                setRobotAlarmLists({
                  serial,
                  lists: {
                    ...lists,
                    blocked: {
                      alarms: [
                        ...(lists.blocked?.alarms ?? []),
                        RobotBlockedAlarm.fromPartial({ code: newCode }),
                      ],
                    },
                  },
                });
              }}
              onRemove={(removedCode) => {
                setRobotAlarmLists({
                  serial,
                  lists: {
                    ...lists,
                    blocked: {
                      alarms: without(
                        lists.blocked?.alarms,
                        findWhere(lists.blocked?.alarms, {
                          code: removedCode,
                        })
                      ).filter((alarm) => !isUndefined(alarm)),
                    },
                  },
                });
              }}
            />
          </>
        }
      />
    </Grid>
  );
};

export const RobotAdmin = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.admin_cloud,
        PermissionDomain.all
      ),
    ],
    _RobotAdmin
  )
);
