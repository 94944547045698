import { buildPermission } from "portal/utils/auth";
import { CarbonUploadCard } from "portal/components/CarbonUploadCard";
import { Header } from "portal/components/header/Header";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent } from "react";

const _Offline: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t("views.offline.title")} />
      <Page>
        <div className="flex flex-col sm:flex-row gap-10">
          <CarbonUploadCard />
        </div>
      </Page>
    </>
  );
};

export const Offline = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.update,
        PermissionResource.uploads,
        PermissionDomain.all
      ),
      buildPermission(
        PermissionAction.update,
        PermissionResource.uploads,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.update,
        PermissionResource.uploads,
        PermissionDomain.self
      ),
    ],
    _Offline
  )
);
