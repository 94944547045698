// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/spatial.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { SpatialMetricBlock } from "../metrics/metrics";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.spatial";

export interface BlockResponse {
  db: DB | undefined;
  block: SpatialMetricBlock | undefined;
  serial: string;
}

export interface BlocksResponse {
  blocks: BlockResponse[];
  queryLimitReached: boolean;
}

export interface BlocksByDateResponse {
  blocks: { [key: string]: BlocksResponse };
}

export interface BlocksByDateResponse_BlocksEntry {
  key: string;
  value: BlocksResponse | undefined;
}

export interface BlocksByDateByRobotResponse {
  blocks: { [key: string]: BlocksByDateResponse };
}

export interface BlocksByDateByRobotResponse_BlocksEntry {
  key: string;
  value: BlocksByDateResponse | undefined;
}

function createBaseBlockResponse(): BlockResponse {
  return { db: undefined, block: undefined, serial: "" };
}

export const BlockResponse: MessageFns<BlockResponse> = {
  encode(message: BlockResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.block !== undefined) {
      SpatialMetricBlock.encode(message.block, writer.uint32(18).fork()).join();
    }
    if (message.serial !== "") {
      writer.uint32(26).string(message.serial);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlockResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlockResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.block = SpatialMetricBlock.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serial = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlockResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      block: isSet(object.block) ? SpatialMetricBlock.fromJSON(object.block) : undefined,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
    };
  },

  toJSON(message: BlockResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.block !== undefined) {
      obj.block = SpatialMetricBlock.toJSON(message.block);
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlockResponse>, I>>(base?: I): BlockResponse {
    return BlockResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlockResponse>, I>>(object: I): BlockResponse {
    const message = createBaseBlockResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.block = (object.block !== undefined && object.block !== null)
      ? SpatialMetricBlock.fromPartial(object.block)
      : undefined;
    message.serial = object.serial ?? "";
    return message;
  },
};

function createBaseBlocksResponse(): BlocksResponse {
  return { blocks: [], queryLimitReached: false };
}

export const BlocksResponse: MessageFns<BlocksResponse> = {
  encode(message: BlocksResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.blocks) {
      BlockResponse.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.queryLimitReached !== false) {
      writer.uint32(16).bool(message.queryLimitReached);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlocksResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlocksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.blocks.push(BlockResponse.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.queryLimitReached = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlocksResponse {
    return {
      blocks: globalThis.Array.isArray(object?.blocks) ? object.blocks.map((e: any) => BlockResponse.fromJSON(e)) : [],
      queryLimitReached: isSet(object.queryLimitReached) ? globalThis.Boolean(object.queryLimitReached) : false,
    };
  },

  toJSON(message: BlocksResponse): unknown {
    const obj: any = {};
    if (message.blocks?.length) {
      obj.blocks = message.blocks.map((e) => BlockResponse.toJSON(e));
    }
    if (message.queryLimitReached !== false) {
      obj.queryLimitReached = message.queryLimitReached;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlocksResponse>, I>>(base?: I): BlocksResponse {
    return BlocksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlocksResponse>, I>>(object: I): BlocksResponse {
    const message = createBaseBlocksResponse();
    message.blocks = object.blocks?.map((e) => BlockResponse.fromPartial(e)) || [];
    message.queryLimitReached = object.queryLimitReached ?? false;
    return message;
  },
};

function createBaseBlocksByDateResponse(): BlocksByDateResponse {
  return { blocks: {} };
}

export const BlocksByDateResponse: MessageFns<BlocksByDateResponse> = {
  encode(message: BlocksByDateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.blocks).forEach(([key, value]) => {
      BlocksByDateResponse_BlocksEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlocksByDateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlocksByDateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BlocksByDateResponse_BlocksEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.blocks[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlocksByDateResponse {
    return {
      blocks: isObject(object.blocks)
        ? Object.entries(object.blocks).reduce<{ [key: string]: BlocksResponse }>((acc, [key, value]) => {
          acc[key] = BlocksResponse.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: BlocksByDateResponse): unknown {
    const obj: any = {};
    if (message.blocks) {
      const entries = Object.entries(message.blocks);
      if (entries.length > 0) {
        obj.blocks = {};
        entries.forEach(([k, v]) => {
          obj.blocks[k] = BlocksResponse.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlocksByDateResponse>, I>>(base?: I): BlocksByDateResponse {
    return BlocksByDateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlocksByDateResponse>, I>>(object: I): BlocksByDateResponse {
    const message = createBaseBlocksByDateResponse();
    message.blocks = Object.entries(object.blocks ?? {}).reduce<{ [key: string]: BlocksResponse }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = BlocksResponse.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseBlocksByDateResponse_BlocksEntry(): BlocksByDateResponse_BlocksEntry {
  return { key: "", value: undefined };
}

export const BlocksByDateResponse_BlocksEntry: MessageFns<BlocksByDateResponse_BlocksEntry> = {
  encode(message: BlocksByDateResponse_BlocksEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      BlocksResponse.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlocksByDateResponse_BlocksEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlocksByDateResponse_BlocksEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = BlocksResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlocksByDateResponse_BlocksEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? BlocksResponse.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: BlocksByDateResponse_BlocksEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = BlocksResponse.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlocksByDateResponse_BlocksEntry>, I>>(
    base?: I,
  ): BlocksByDateResponse_BlocksEntry {
    return BlocksByDateResponse_BlocksEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlocksByDateResponse_BlocksEntry>, I>>(
    object: I,
  ): BlocksByDateResponse_BlocksEntry {
    const message = createBaseBlocksByDateResponse_BlocksEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? BlocksResponse.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseBlocksByDateByRobotResponse(): BlocksByDateByRobotResponse {
  return { blocks: {} };
}

export const BlocksByDateByRobotResponse: MessageFns<BlocksByDateByRobotResponse> = {
  encode(message: BlocksByDateByRobotResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.blocks).forEach(([key, value]) => {
      BlocksByDateByRobotResponse_BlocksEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlocksByDateByRobotResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlocksByDateByRobotResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BlocksByDateByRobotResponse_BlocksEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.blocks[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlocksByDateByRobotResponse {
    return {
      blocks: isObject(object.blocks)
        ? Object.entries(object.blocks).reduce<{ [key: string]: BlocksByDateResponse }>((acc, [key, value]) => {
          acc[key] = BlocksByDateResponse.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: BlocksByDateByRobotResponse): unknown {
    const obj: any = {};
    if (message.blocks) {
      const entries = Object.entries(message.blocks);
      if (entries.length > 0) {
        obj.blocks = {};
        entries.forEach(([k, v]) => {
          obj.blocks[k] = BlocksByDateResponse.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlocksByDateByRobotResponse>, I>>(base?: I): BlocksByDateByRobotResponse {
    return BlocksByDateByRobotResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlocksByDateByRobotResponse>, I>>(object: I): BlocksByDateByRobotResponse {
    const message = createBaseBlocksByDateByRobotResponse();
    message.blocks = Object.entries(object.blocks ?? {}).reduce<{ [key: string]: BlocksByDateResponse }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = BlocksByDateResponse.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseBlocksByDateByRobotResponse_BlocksEntry(): BlocksByDateByRobotResponse_BlocksEntry {
  return { key: "", value: undefined };
}

export const BlocksByDateByRobotResponse_BlocksEntry: MessageFns<BlocksByDateByRobotResponse_BlocksEntry> = {
  encode(message: BlocksByDateByRobotResponse_BlocksEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      BlocksByDateResponse.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BlocksByDateByRobotResponse_BlocksEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlocksByDateByRobotResponse_BlocksEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = BlocksByDateResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlocksByDateByRobotResponse_BlocksEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? BlocksByDateResponse.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: BlocksByDateByRobotResponse_BlocksEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = BlocksByDateResponse.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlocksByDateByRobotResponse_BlocksEntry>, I>>(
    base?: I,
  ): BlocksByDateByRobotResponse_BlocksEntry {
    return BlocksByDateByRobotResponse_BlocksEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlocksByDateByRobotResponse_BlocksEntry>, I>>(
    object: I,
  ): BlocksByDateByRobotResponse_BlocksEntry {
    const message = createBaseBlocksByDateByRobotResponse_BlocksEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? BlocksByDateResponse.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
