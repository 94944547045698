import { Alert, Link } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { Trans, useTranslation } from "react-i18next";
import { useGetRobotQuery } from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

const _RobotVersionsList: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();

  const { data: summary, isSuccess } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken)
  );

  return (
    <div>
      <div>
        <span className="font-bold mr-2">
          {t("views.fleet.robots.hardware.installedVersion")}
        </span>
        {isSuccess
          ? summary.robot?.health?.softwareVersion
          : t("components.Loading.placeholder")}
      </div>
      <div>
        <span className="font-bold mr-2">
          {t("views.fleet.robots.hardware.targetVersion")}
        </span>
        {isSuccess
          ? summary.robot?.health?.targetVersion
          : t("components.Loading.placeholder")}
      </div>
      <div>
        <span className="font-bold mr-2">
          {t("views.fleet.robots.hardware.ready.name")}:
        </span>
        {(() => {
          if (!isSuccess) {
            return t("components.Loading.placeholder");
          }
          return summary.robot?.health?.targetVersionReady
            ? t("views.fleet.robots.hardware.ready.values.true")
            : t("views.fleet.robots.hardware.ready.values.false");
        })()}
      </div>
      <Alert severity="info" variant="filled" className="mt-8">
        <Trans
          i18nKey="views.fleet.robots.hardware.updateHistory"
          t={t}
          components={[
            <Link
              key="terms"
              className="text-orange-500"
              href="https://carbonrobotics.atlassian.net/browse/SOFTWARE-218"
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        />
      </Alert>
    </div>
  );
};

export const RobotVersionsList = withAuthenticationRequired(_RobotVersionsList);
