import { buildPermission } from "portal/utils/auth";
import { Button } from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { CategoryCollection } from "protos/category_profile/category_profile";
import { CategoryCollectionProfile } from "./CategoryCollectionProfile";
import { classes } from "portal/utils/theme";
import { getDefaultCategoryCollectionProfile } from "portal/utils/categoryCollectionProfile";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NoScroll } from "../Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SearchField } from "../header/SearchField";
import { SetCategoryCollectionProfile } from "./SetCategoryCollectionProfile";
import { skipToken } from "@reduxjs/toolkit/query";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useGetGlobalCategoryCollectionProfileQuery,
  useGetRobotQuery,
  useListGlobalCategoryCollectionProfilesQuery,
  useSetGlobalCategoryCollectionProfileMutation,
} from "portal/state/portalApi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useMemo } from "react";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  flex: 1,
};

// TODO: support deleting
// TODO: support reporting profile active/inactive status

interface Props {
  adminEditing?: boolean;
  serial?: string;
  basePath: string;
  useListQuery: typeof useListGlobalCategoryCollectionProfilesQuery;
  useGetQuery: typeof useGetGlobalCategoryCollectionProfileQuery;
  useSetMutation: typeof useSetGlobalCategoryCollectionProfileMutation;
  // useDeleteMutation: typeof useDeleteGlobalCategoryCollectionProfileMutation;
}

const _CategoryCollectionProfileList: FunctionComponent<Props> = ({
  adminEditing = false,
  serial,
  basePath,
  useListQuery,
  useGetQuery,
  useSetMutation,
  // useDeleteMutation,
}) => {
  const { t } = useTranslation();

  const { isSuccess: isRobotSuccess } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken),
    { errorVariant: "warning" }
  );

  const { data: categoryCollectionProfiles, isSuccess: hasLoaded } =
    useQueryPopups(useListQuery({ serial }));

  const [setCategoryCollectionProfile] = useMutationPopups(useSetMutation(), {
    success: capitalize(
      t("utils.actions.createdLong", {
        subject: t(
          "models.categoryCollectionProfiles.categoryCollectionProfile_one"
        ),
      })
    ),
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let uuid: string = "";
  if (!pathname.endsWith(basePath)) {
    uuid = pathname.replace(`${basePath}/`, "");
  }

  const { searchText, setSearchText, results } =
    useFuzzySearch<CategoryCollection>({
      items: categoryCollectionProfiles ?? [],
    });

  const columns: readonly GridColDef<CategoryCollection>[] = useMemo(
    () => [
      {
        ...defaultColumn,
        field: "name",
        headerName: titleCase(
          t("models.categoryCollectionProfiles.fields.name")
        ),
      },
      {
        ...defaultColumn,
        field: "isActive",
        headerName: t("utils.descriptors.active"),
        cellClassName: () => {
          return classes("text-gray-500");
        },
        valueGetter: () => {
          if (!isRobotSuccess) {
            return t("components.Loading.placeholder");
          }
          return t("utils.descriptors.inactive");
        },
      },
    ],
    [isRobotSuccess, t]
  );

  const defaultProfile = useMemo(() => {
    // For M1 we specifically need the 5 specific categories to be named in English
    const SHOULD_INTERNATIONALIZE = false;
    return getDefaultCategoryCollectionProfile(t, SHOULD_INTERNATIONALIZE);
  }, [t]);

  if (uuid) {
    return (
      <CategoryCollectionProfile
        adminEditing={adminEditing}
        parentLink={basePath}
        uuid={uuid}
        useGetQuery={useGetQuery}
        useSetMutation={useSetMutation}
        // useDeleteMutation={useDeleteMutation}
      />
    );
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<CategoryCollection>
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={t("utils.actions.searchLong", {
                  subject: titleCase(
                    t(
                      "models.categoryCollectionProfiles.categoryCollectionProfile_other",
                      {
                        count: categoryCollectionProfiles?.length ?? 0,
                      }
                    )
                  ),
                })}
              />
              <SetCategoryCollectionProfile
                triggerButton={(onClick) => (
                  <Button
                    className="text-white"
                    startIcon={<AddIcon />}
                    onClick={onClick}
                  >
                    <span className="hidden sm:inline">
                      {titleCase(
                        t("utils.actions.newLong", {
                          subject: t(
                            "models.categoryCollectionProfiles.categoryCollectionProfile_one"
                          ),
                        })
                      )}
                    </span>
                    <span className="sm:hidden">{t("utils.actions.new")}</span>
                  </Button>
                )}
                hotkey={{
                  sequence: "n",
                  key: "NEW_CATEGORY_COLLECTION_PROFILE",
                }}
                title={t("utils.actions.newLong", {
                  subject: t(
                    "models.categoryCollectionProfiles.categoryCollectionProfile_one"
                  ),
                })}
                okText={t("utils.actions.create")}
                okIcon={<AddIcon />}
                initialValue={defaultProfile}
                onSubmit={async (newProfile) => {
                  await setCategoryCollectionProfile({
                    expandedCategoryCollection: newProfile,
                  });
                  navigate(
                    `${basePath}/${
                      (newProfile.profile as CategoryCollection).id
                    }`
                  );
                }}
              />
            </>
          }
          className="flex flex-1"
          rows={results}
          getRowId={({ id }) => id}
          columns={columns}
          columnVisibilityModel={{
            isActive: typeof serial === "string",
          }}
          hideFooter
          onRowClick={({ row: categoryCollectionProfile }) =>
            navigate(`${basePath}/${categoryCollectionProfile.id}`)
          }
          disableRowSelectionOnClick
          getRowClassName={() => "cursor-pointer"}
          loading={!hasLoaded}
        />
      </div>
    </NoScroll>
  );
};

export const CategoryCollectionProfileList = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.read,
      PermissionResource.plant_category_profiles,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.plant_category_profiles,
      PermissionDomain.all
    ),
  ],
  _CategoryCollectionProfileList
);
