import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import { classes } from "portal/utils/theme";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowModel,
  GridToolbarContainer,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";
import { DATETIME_EXCEL } from "portal/utils/dates";
import { debounce } from "portal/utils/timing";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { i18n as I18n } from "i18next";
import { isUndefined } from "portal/utils/identity";
import { Paper, Skeleton, Tooltip } from "@mui/material";
import { range } from "portal/utils/arrays";
import { withErrorBoundary } from "./ErrorBoundary";
import ErrorIcon from "@mui/icons-material/WarningOutlined";
import React, {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

export const booleanValueFormatter = (value: boolean): string =>
  value ? "TRUE" : "FALSE";

export const renderHeaderWithoutUnits = ({
  colDef,
}: GridColumnHeaderParams<any, any, any>): string | undefined =>
  colDef.headerName?.replace(/ \(.*\)$/, "");

export const excelFormatter = (value: DateTime | undefined): string =>
  value ? value.toFormat(DATETIME_EXCEL) : "";

export const getRenderDateTime =
  (i18n: I18n, variant: "relative" | "short" | "localtime") =>
  <RowModel extends GridRowModel>({
    value,
  }: GridRenderCellParams<RowModel, DateTime>): ReactNode =>
    value ? (
      <Tooltip
        title={value.toLocaleString(DateTime.DATETIME_FULL, {
          locale: i18n.language,
        })}
        arrow
      >
        <span className="whitespace-nowrap">
          {(() => {
            switch (variant) {
              case "relative": {
                return value.toRelative({
                  locale: i18n.language,
                });
              }
              case "short": {
                return value.toLocaleString(DateTime.DATETIME_SHORT, {
                  locale: i18n.language,
                });
              }
              case "localtime": {
                return value.toLocaleString(
                  { ...DateTime.TIME_SIMPLE, weekday: "short", day: "numeric" },
                  { locale: i18n.language }
                );
              }
            }
          })()}
        </span>
      </Tooltip>
    ) : undefined;

/**
 * Wrapper for DataGrid:
 * * Default Carbon styles
 * * Auto column sizing
 * * Custom loading graphic
 * * total/average footer
 */
function _CarbonDataGrid<R extends GridValidRowModel>({
  averages,
  classes: gridClasses,
  columns,
  dimensionClasses,
  getRowId,
  header,
  errorMessage,
  loading = false,
  onColumnOrderChange,
  pinnedColumns,
  pinnedRows: inputPinnedRows,
  rows,
  slotProps: inputSlotProps,
  slots: inputSlots,
  totals,
  ...props
}: DataGridPremiumProps<R> &
  RefAttributes<HTMLDivElement> & {
    averages?: R | undefined;
    dimensionClasses?: string;
    getRowId: GridRowIdGetter<R>;
    header?: ReactNode;
    totals?: R | undefined;
    errorMessage?: ReactNode;
  }): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);
  // MUI types are not correct here, it can be null
  const apiRef = useGridApiRef() as MutableRefObject<GridApiPremium | null>;

  const autosizeOptions = useMemo(
    () => ({
      columns: columns
        .filter((column) => isUndefined(column.width))
        .map(({ field }) => field),
      includeHeaders: true,
      expand: true,
      includeOutliers: true,
    }),
    [columns]
  );
  const slots = useMemo(
    () => ({
      ...inputSlots,
      panel: Paper,
      toolbar: header ? CarbonToolbar : undefined,
    }),
    [inputSlots, header]
  );

  const slotProps = useMemo(
    () => ({
      ...inputSlotProps,
      toolbar: { ...inputSlotProps?.toolbar, children: header },
    }),
    [inputSlotProps, header]
  );
  const autosize = useCallback(() => {
    if (apiRef.current && "autosizeColumns" in apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions);
    }
  }, [apiRef, autosizeOptions]);

  // auto resize on scroll in case new virtualized rows are larger (or smaller)
  useEffect(() => {
    ref.current
      ?.querySelector(".MuiDataGrid-virtualScroller")
      ?.addEventListener("scroll", debounce(autosize));
  }, [autosize, ref]);

  const breakpoint = useBreakpoint();
  const isSmall = breakpoint <= Breakpoint.sm;

  // extra bottom rows
  const extraBottom = useMemo<R[]>(() => {
    const extraBottom: R[] = [];
    let totalsRow: R | undefined;
    if (totals) {
      totalsRow = structuredClone(totals);
      extraBottom.push(totalsRow);
    }

    let averagesRow: R | undefined;
    if (averages) {
      averagesRow = structuredClone(averages);
      extraBottom.push(averagesRow);
    }
    return extraBottom;
  }, [totals, averages]);
  const allRows = useMemo(
    () => (isSmall ? [...(rows ?? []), ...extraBottom] : rows),
    [rows, extraBottom, isSmall]
  );

  const pinnedRows = useMemo(
    () => ({
      ...inputPinnedRows,
      bottom: isSmall
        ? []
        : [...(inputPinnedRows?.bottom ?? []), ...extraBottom],
    }),
    [inputPinnedRows, extraBottom, isSmall]
  );
  const classesProps = useMemo(
    () => ({
      cell: "border-zinc-500 outline-none",
      root: classes("border-0 flex-1 h-full", dimensionClasses, "bg-zinc-700", {
        invisible: loading,
      }),
      footerContainer: "border-0",
      sortIcon: "text-white",
      groupingCriteriaCellToggle: "text-white",
      toolbarContainer: "text-white",
      ...gridClasses,
    }),
    [gridClasses, dimensionClasses, loading]
  );

  useEffect(autosize, [autosize]);

  // global error state
  if (errorMessage) {
    return (
      <Paper className="flex-1 bg-zinc-600 flex items-center justify-center">
        <div className="flex flex-col gap-4 text-gray-200 items-center">
          <ErrorIcon className="text-6xl" />
          {errorMessage}
        </div>
      </Paper>
    );
  }

  return (
    <>
      {loading && <CarbonDataGridLoading />}
      <DataGridPremium<R>
        ref={ref}
        // MUI types are not correct here but MUI doesn't know that
        apiRef={apiRef as MutableRefObject<GridApiPremium>}
        autosizeOptions={autosizeOptions}
        columnBufferPx={1000}
        getRowId={getRowId}
        columns={columns}
        rows={allRows}
        slots={slots}
        slotProps={slotProps}
        pinnedColumns={pinnedColumns}
        pinnedRows={pinnedRows}
        classes={classesProps}
        onColumnOrderChange={onColumnOrderChange}
        disableColumnReorder={!onColumnOrderChange}
        {...props}
      />
    </>
  );
}

// wrap with error boundary. No runtime problems but I can't figure out
// how to pass generic types through the wrapper component
export const CarbonDataGrid = withErrorBoundary(
  {},
  _CarbonDataGrid
) as typeof _CarbonDataGrid;

// carbon style table toolbar
interface CarbonToolbarProps extends PropsWithChildren {
  className?: string;
}

const CarbonToolbar: FunctionComponent<CarbonToolbarProps> = ({
  className,
  children,
}) => (
  <GridToolbarContainer
    className={classes("p-0", className)}
    // `classes` not supported here yet in MUI :-(
    sx={{ "& .MuiButton-root": { color: "white" } }}
  >
    <Paper
      className={classes(
        "bg-zinc-600",
        "flex justify-between w-full",
        "p-2 border-solid border-0 border-b border-darken-400",
        "rounded-b-none"
      )}
    >
      {children}
    </Paper>
  </GridToolbarContainer>
);

// grid style skeleton loader
interface CarbonDataGridLoadingProps {
  className?: string;
}
const CarbonDataGridLoading: FunctionComponent<CarbonDataGridLoadingProps> = ({
  className,
}) => (
  <div className={classes("mt-8", className)}>
    {range(10).map((index) => (
      <Skeleton
        variant="rectangular"
        className="w-full h-14 mb-2"
        key={index}
      />
    ))}
  </div>
);

// carbon style table footer
export const CarbonFooter: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <Paper
    className={classes(
      "bg-zinc-600",
      "flex justify-between w-full",
      "p-2 border-solid border-0 border-b border-darken-400",
      "rounded-t-none"
    )}
  >
    {children}
  </Paper>
);
