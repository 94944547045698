import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { classes } from "portal/utils/theme";
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { PropsFor } from "@mui/system";
import { WithSkeleton, WithSkeletonProps } from "./WithSkeleton";
import Grid from "@mui/system/Unstable_Grid";
import React, { ReactNode } from "react";

interface CarbonCardBaseProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  gridProps?: PropsFor<typeof Grid>;
  title: string;
  error?: boolean;
  success: boolean;
  withSkeletonProps?: WithSkeletonProps;
}

export interface CarbonCardBasicProps extends CarbonCardBaseProps {
  content: ReactNode;
  actions?: ReactNode;
}

export interface CarbonCardFormikProps<Values extends FormikValues>
  extends CarbonCardBaseProps {
  content: ReactNode | ((formikProps: FormikProps<Values>) => ReactNode);
  actions?: ReactNode | ((formikProps: FormikProps<Values>) => ReactNode);
  formikProps: FormikConfig<Values>;
}

export type CarbonCardProps<Values extends FormikValues> =
  | CarbonCardBasicProps
  | CarbonCardFormikProps<Values>;

const isPropsFormik = (
  props: CarbonCardProps<any>
): props is CarbonCardFormikProps<any> => "formikProps" in props;

const isPropsBasic = (
  props: CarbonCardProps<any>
): props is CarbonCardBasicProps => !isPropsFormik(props);

export const CarbonCard: <Values extends FormikValues>(
  props: CarbonCardProps<Values>
) => ReactNode = (props) => {
  const { gridProps, title, error, success, withSkeletonProps } = props;

  const wrapBasic = (content: ReactNode, actions: ReactNode): ReactNode => (
    <>
      <CardContent className="flex flex-col gap-4 flex-1">
        <Typography variant="h3" className="mb-4 text-xl">
          {title}
        </Typography>
        {content}
      </CardContent>
      <CardActions disableSpacing className="p-4 justify-end gap-4 w-full">
        {actions}
      </CardActions>
    </>
  );

  const wrapFormik = <Values extends FormikValues>(
    content: CarbonCardFormikProps<Values>["content"],
    actions: CarbonCardFormikProps<Values>["actions"],
    formikProps: CarbonCardFormikProps<Values>["formikProps"]
  ): ReactNode => (
    <Formik enableReinitialize {...formikProps}>
      {(formProps) => (
        <Form className="h-full flex flex-col">
          {wrapBasic(
            typeof content === "function" ? content(formProps) : content,
            typeof actions === "function" ? actions(formProps) : actions
          )}
        </Form>
      )}
    </Formik>
  );

  return (
    <Grid xs={12} md={6} {...gridProps}>
      <WithSkeleton
        variant="rectangular"
        error={error}
        success={success}
        className={classes("h-full", withSkeletonProps?.className)}
        {...withSkeletonProps}
      >
        <Card>
          {isPropsFormik(props) &&
            wrapFormik(props.content, props.actions, props.formikProps)}
          {isPropsBasic(props) && wrapBasic(props.content, props.actions)}
        </Card>
      </WithSkeleton>
    </Grid>
  );
};
