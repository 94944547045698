import React, { FunctionComponent } from "react";

interface IconImageProps {
  icon: (className: string) => React.ReactNode;
}

export const IconImage: FunctionComponent<IconImageProps> = ({ icon }) => (
  <div className="w-full h-full bg-gray-600 flex items-center justify-center rounded-sm">
    {icon("text-gray-400 text-xl")}
  </div>
);
