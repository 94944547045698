import { clamp, mean } from "./math";
import { convert, MeasurementSystem, UnitNumberType } from "./units/units";
import {
  CycleSlot,
  getAsPercentOfCategorized,
  Metric,
  MetricValidation,
} from "./metrics";
import { DailyMetricResponse } from "protos/portal/metrics";
import { isUndefined } from "./identity";
import { keys } from "./objects";
import { ParseKeys, TFunction } from "i18next";
import { titleCase } from "portal/utils/strings";

export const isCertifiedMetricId = (
  id: string
): id is Exclude<keyof DailyMetricResponse, "db"> => {
  console.debug(DailyMetricResponse.fromPartial({}));
  return keys(DailyMetricResponse.fromPartial({})).includes(
    id as keyof DailyMetricResponse
  );
};

export const getMetricName = (t: TFunction, metric: Metric): string =>
  titleCase(
    // carbon.actions.compareKeys.ignoreDynamic
    t(`utils.metrics.certified.metrics.${metric.id}`)
  );

const DEFAULT_METRIC: Pick<
  Metric,
  "type" | "canAverage" | "canTotal" | "getValue" | "validation"
> = {
  type: "string",
  canAverage: true,
  canTotal: true,
  getValue: (value: any) => value,
  validation: MetricValidation.PENDING,
};

export const METRIC_ACRES_WEEDED: Metric = {
  ...DEFAULT_METRIC,
  id: "acresWeeded",
  type: "number",
  units: "ac",
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.AREA,
};

export const METRIC_AVERAGE_SPEED: Metric = {
  ...DEFAULT_METRIC,
  id: "avgSpeedMph",
  type: "number",
  units: "mph",
  canTotal: false,
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.SPEED,
};

export const METRIC_AVERAGE_WEED_SIZE: Metric = {
  ...DEFAULT_METRIC,
  id: "avgWeedSizeMm",
  type: "number",
  units: "mm",
  toUnits: "mm",
  cycle: {
    [MeasurementSystem.imperial]: ["in", "mm"],
    [MeasurementSystem.metric]: ["mm"],
  },
  canTotal: false,
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.SIZE,
};

export const METRIC_AVERAGE_CROP_SIZE: Metric = {
  ...DEFAULT_METRIC,
  id: "avgCropSizeMm",
  type: "number",
  units: "mm",
  toUnits: "mm",
  cycle: {
    [MeasurementSystem.imperial]: ["in", "mm"],
    [MeasurementSystem.metric]: ["mm"],
  },
  canTotal: false,
  cycleSlot: CycleSlot.SIZE,
};

export const METRIC_AVERAGE_TARGETABLE_REQ_LASER_TIME: Metric = {
  ...DEFAULT_METRIC,
  id: "avgTargetableReqLaserTime",
  type: "number",
  units: "ms",
  toUnits: "ms",
  cycle: {
    [MeasurementSystem.imperial]: ["ms"],
    [MeasurementSystem.metric]: ["ms"],
  },
  canTotal: false,
  cycleSlot: CycleSlot.SHOOT,
};

export const METRIC_AVERAGE_UNTARGETABLE_REQ_LASER_TIME: Metric = {
  ...DEFAULT_METRIC,
  id: "avgUntargetableReqLaserTime",
  type: "number",
  units: "ms",
  toUnits: "ms",
  cycle: {
    [MeasurementSystem.imperial]: ["ms"],
    [MeasurementSystem.metric]: ["ms"],
  },
  canTotal: false,
  validation: MetricValidation.INTERNAL,
  cycleSlot: CycleSlot.SHOOT,
};

export const METRIC_BANDING_CONFIG: Metric = {
  ...DEFAULT_METRIC,
  id: "bandingConfigName",
  type: "string",
  canTotal: false,
  canAverage: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_BANDING_ENABLED: Metric = {
  ...DEFAULT_METRIC,
  id: "bandingEnabled",
  type: "boolean",
  truei18nKey: "utils.descriptors.enabled",
  falsei18nKey: "utils.descriptors.disabled",
  canTotal: false,
  canAverage: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_COVERAGE_SPEED: Metric = {
  ...DEFAULT_METRIC,
  id: "coverageSpeedAcresHr",
  type: "number",
  units: "ac/h",
  canTotal: false,
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.COVERAGE,
};

export const METRIC_DISTANCE_WEEDED: Metric = {
  ...DEFAULT_METRIC,
  id: "distanceWeededMeters",
  type: "number",
  units: "mi",
  getValue: (value) => (value ? convert(value).from("m").to("mi") : undefined),
  cycleSlot: CycleSlot.DISTANCE,
};

export const METRIC_KILLED_WEEDS: Metric = {
  ...DEFAULT_METRIC,
  type: "number",
  id: "killedWeeds",
  units: UnitNumberType.COUNT,
};

export const METRIC_MISSED_WEEDS: Metric = {
  ...DEFAULT_METRIC,
  type: "number",
  id: "missedWeeds",
  units: UnitNumberType.COUNT,
};

export const METRIC_SKIPPED_WEEDS: Metric = {
  ...DEFAULT_METRIC,
  type: "number",
  id: "skippedWeeds",
  units: UnitNumberType.COUNT,
};

export const METRIC_TIME_EFFICIENCY: Metric = {
  ...DEFAULT_METRIC,
  id: "timeEfficiency",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  getValue: (value) => value * 100,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_TOTAL_WEEDS: Metric = {
  ...DEFAULT_METRIC,
  id: "totalWeeds",
  type: "number",
  units: UnitNumberType.COUNT,
  getValue: (value, { notWeeding }, canReadInternalMetrics = false) => {
    if (isUndefined(value)) {
      return value;
    }
    if (canReadInternalMetrics) {
      return value;
    }
    return value - notWeeding;
  },
  validation: MetricValidation.INTERNAL,
};

export const METRIC_TOTAL_WEEDS_IN_BAND: Metric = {
  ...DEFAULT_METRIC,
  id: "totalWeedsInBand",
  type: "number",
  units: UnitNumberType.COUNT,
  getValue: (value, { killedWeeds, missedWeeds }) => {
    if (!isUndefined(value)) {
      return value;
    }
    if (killedWeeds && missedWeeds) {
      return killedWeeds + missedWeeds;
    }
  },
  validation: MetricValidation.VALIDATED,
};

export const METRIC_UPTIME: Metric = {
  ...DEFAULT_METRIC,
  id: "uptimeSeconds",
  type: "number",
  isDuration: true,
  units: "h",
  cycle: {
    imperial: ["h", "min", "s"],
    metric: ["h", "min", "s"],
  },
  getValue: (value) => (value ? convert(value).from("s").to("h") : undefined),
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.TIME,
};

export const METRIC_WEED_DENSITY: Metric = {
  ...DEFAULT_METRIC,
  id: "weedDensitySqFt",
  type: "number",
  units: "/ft2",
  unitsDelimiter: "",
  canTotal: false,
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.DENSITY,
};

export const METRIC_CROP_DENSITY: Metric = {
  ...DEFAULT_METRIC,
  id: "cropDensitySqFt",
  type: "number",
  units: "/ft2",
  unitsDelimiter: "",
  canTotal: false,
  cycleSlot: CycleSlot.DENSITY,
};

export const METRIC_WEEDING_EFFICIENCY: Metric = {
  ...DEFAULT_METRIC,
  id: "weedingEfficiency",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  getValue: (value) => value * 100,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_WEEDING_TIME: Metric = {
  ...DEFAULT_METRIC,
  id: "weedingUptimeSeconds",
  type: "number",
  isDuration: true,
  units: "h",
  cycle: {
    imperial: ["h", "min", "s"],
    metric: ["h", "min", "s"],
  },
  getValue: (value) => (value ? convert(value).from("s").to("h") : undefined),
  validation: MetricValidation.VALIDATED,
  cycleSlot: CycleSlot.TIME,
};

export const METRIC_TARGET_WEEDING_TIME: Metric = {
  ...DEFAULT_METRIC,
  id: "targetWeedingTimeSeconds",
  type: "number",
  isDuration: true,
  units: "h",
  cycle: {
    imperial: ["h", "min", "s"],
    metric: ["h", "min", "s"],
  },
  getValue: (value) => (value ? convert(value).from("s").to("h") : undefined),
  validation: MetricValidation.PENDING,
  cycleSlot: CycleSlot.TIME,
};

export const METRIC_COUNT_BROADLEAF: Metric = {
  ...DEFAULT_METRIC,
  id: "weedsTypeCountBroadleaf",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  getValue: getAsPercentOfCategorized,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_COUNT_GRASS: Metric = {
  ...DEFAULT_METRIC,
  id: "weedsTypeCountGrass",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  getValue: getAsPercentOfCategorized,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_COUNT_PURSLANE: Metric = {
  ...DEFAULT_METRIC,
  id: "weedsTypeCountPurslane",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  getValue: getAsPercentOfCategorized,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_COUNT_OFFSHOOT: Metric = {
  ...DEFAULT_METRIC,
  id: "weedsTypeCountOffshoot",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  decimalPlaces: 0,
  getValue: getAsPercentOfCategorized,
  canTotal: false,
  validation: MetricValidation.VALIDATED,
};

export const METRIC_NOT_WEEDING_WEEDS: Metric = {
  ...DEFAULT_METRIC,
  id: "notWeedingWeeds",
  type: "number",
  units: UnitNumberType.COUNT,
  validation: MetricValidation.INTERNAL,
};

export const METRIC_NOT_WEEDING: Metric = {
  ...DEFAULT_METRIC,
  id: "notWeeding",
  type: "number",
  units: UnitNumberType.COUNT,
  getValue: (value, { notWeedingWeeds }) =>
    notWeedingWeeds === 0 ? value : notWeedingWeeds,
  validation: MetricValidation.INTERNAL,
};

export const METRIC_THINNED_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "thinnedCrops",
  type: "number",
  units: UnitNumberType.COUNT,
};

export const METRIC_SKIPPED_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "skippedCrops",
  type: "number",
  units: UnitNumberType.COUNT,
};

export const METRIC_MISSED_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "missedCrops",
  type: "number",
  units: UnitNumberType.COUNT,
};

export const METRIC_TOTAL_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "totalCrops",
  type: "number",
  units: UnitNumberType.COUNT,
  validation: MetricValidation.INTERNAL,
};

export const METRIC_VALID_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "validCrops",
  type: "number",
  units: UnitNumberType.COUNT,
  getValue: (value, metrics) => {
    if (value) {
      return value;
    }
    return METRIC_TOTAL_CROPS.getValue(metrics.totalCrops, metrics);
  },
};

export const METRIC_KEPT_CROPS: Metric = {
  ...DEFAULT_METRIC,
  id: "keptCrops",
  type: "number",
  units: UnitNumberType.COUNT,
};

export const METRIC_NOT_THINNING: Metric = {
  ...DEFAULT_METRIC,
  id: "notThinning",
  type: "number",
  units: UnitNumberType.COUNT,
  validation: MetricValidation.INTERNAL,
};

export const METRIC_BANDING_PERCENTAGE: Metric = {
  ...DEFAULT_METRIC,
  id: "bandingPercentage",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  canTotal: false,
  getValue: (value) => clamp(0, 100, value),
};

export const METRIC_THINNING_EFFICIENCY: Metric = {
  ...DEFAULT_METRIC,
  id: "thinningEfficiency",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  getValue: (value) => value * 100,
  canTotal: false,
};

export const METRIC_OPERATOR_EFFECTIVENESS: Metric = {
  ...DEFAULT_METRIC,
  id: "operatorEffectiveness",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  getValue: (value) => value * 100,
  canTotal: false,
  validation: MetricValidation.PENDING,
};

export const METRIC_OVERALL_EFFICIENCY: Metric = {
  ...DEFAULT_METRIC,
  id: "overallEfficiency",
  type: "number",
  units: UnitNumberType.PERCENT,
  unitsDelimiter: "",
  getValue: (value, metrics) => {
    const weeding = METRIC_WEEDING_EFFICIENCY.getValue(
      metrics.weedingEfficiency,
      metrics
    );
    const thinning = METRIC_THINNING_EFFICIENCY.getValue(
      metrics.thinningEfficiency,
      metrics
    );
    if (weeding && !thinning) {
      return weeding;
    } else if (thinning && !weeding) {
      return thinning;
    } else if (thinning && weeding) {
      return mean([weeding, thinning]);
    }
  },
  canTotal: false,
};

export const METRIC_CROP: Metric = {
  ...DEFAULT_METRIC,
  id: "crop",
  type: "string",
  getValue: (value, { crop, cropId }) => crop || cropId,
  canTotal: false,
  canAverage: false,
  validation: MetricValidation.VALIDATED,
};

export const CERTIFIED_METRICS = [
  METRIC_CROP,
  METRIC_UPTIME,
  METRIC_WEEDING_TIME,
  METRIC_TARGET_WEEDING_TIME,
  METRIC_TIME_EFFICIENCY,
  METRIC_ACRES_WEEDED,
  METRIC_COVERAGE_SPEED,
  METRIC_AVERAGE_SPEED,
  METRIC_OPERATOR_EFFECTIVENESS,
  METRIC_DISTANCE_WEEDED,
  METRIC_BANDING_CONFIG,
  METRIC_BANDING_ENABLED,
  METRIC_AVERAGE_WEED_SIZE,
  METRIC_AVERAGE_CROP_SIZE,
  METRIC_AVERAGE_TARGETABLE_REQ_LASER_TIME,
  METRIC_WEED_DENSITY,
  METRIC_COUNT_BROADLEAF,
  METRIC_COUNT_GRASS,
  METRIC_COUNT_PURSLANE,
  METRIC_COUNT_OFFSHOOT,
  METRIC_TOTAL_WEEDS,
  METRIC_TOTAL_WEEDS_IN_BAND,
  METRIC_KILLED_WEEDS,
  METRIC_WEEDING_EFFICIENCY,
  METRIC_SKIPPED_WEEDS,
  METRIC_MISSED_WEEDS,
  METRIC_NOT_WEEDING,
  METRIC_CROP_DENSITY,
  METRIC_THINNED_CROPS,
  METRIC_SKIPPED_CROPS,
  METRIC_MISSED_CROPS,
  METRIC_TOTAL_CROPS,
  METRIC_VALID_CROPS,
  METRIC_KEPT_CROPS,
  METRIC_NOT_THINNING,
  METRIC_BANDING_PERCENTAGE,
  METRIC_THINNING_EFFICIENCY,
  METRIC_OVERALL_EFFICIENCY,
];

export interface MetricGroup {
  i18nKey: ParseKeys;
  metrics: Metric[];
}

export const METRIC_GROUPS: MetricGroup[] = [
  {
    i18nKey: "utils.metrics.groups.performance",
    metrics: [
      METRIC_OVERALL_EFFICIENCY,
      METRIC_WEEDING_EFFICIENCY,
      METRIC_THINNING_EFFICIENCY,
      METRIC_OPERATOR_EFFECTIVENESS,
      METRIC_AVERAGE_TARGETABLE_REQ_LASER_TIME,
      METRIC_AVERAGE_UNTARGETABLE_REQ_LASER_TIME,
    ],
  },
  {
    i18nKey: "utils.metrics.groups.usage",
    metrics: [
      METRIC_UPTIME,
      METRIC_WEEDING_TIME,
      METRIC_TARGET_WEEDING_TIME,
      METRIC_TIME_EFFICIENCY,
    ],
  },
  {
    i18nKey: "utils.metrics.groups.coverage",
    metrics: [
      METRIC_ACRES_WEEDED,
      METRIC_COVERAGE_SPEED,
      METRIC_AVERAGE_SPEED,
      METRIC_DISTANCE_WEEDED,
    ],
  },
  {
    i18nKey: "models.crops.crop_other",
    metrics: [
      METRIC_CROP_DENSITY,
      METRIC_AVERAGE_CROP_SIZE,
      METRIC_TOTAL_CROPS,
      METRIC_VALID_CROPS,
      METRIC_THINNED_CROPS,
      METRIC_KEPT_CROPS,
      METRIC_SKIPPED_CROPS,
      METRIC_NOT_THINNING,
      METRIC_MISSED_CROPS,
    ],
  },
  {
    i18nKey: "models.weeds.weed_other",
    metrics: [
      METRIC_WEED_DENSITY,
      METRIC_AVERAGE_WEED_SIZE,
      METRIC_TOTAL_WEEDS,
      METRIC_TOTAL_WEEDS_IN_BAND,
      METRIC_KILLED_WEEDS,
      METRIC_SKIPPED_WEEDS,
      METRIC_NOT_WEEDING,
      METRIC_MISSED_WEEDS,
      METRIC_COUNT_BROADLEAF,
      METRIC_COUNT_GRASS,
      METRIC_COUNT_PURSLANE,
      METRIC_COUNT_OFFSHOOT,
    ],
  },
  {
    i18nKey: "utils.metrics.groups.field",
    metrics: [
      METRIC_CROP,
      METRIC_BANDING_CONFIG,
      METRIC_BANDING_PERCENTAGE,
      METRIC_BANDING_ENABLED,
    ],
  },
];

export const VALIDATED_METRICS = CERTIFIED_METRICS.filter(
  (metric) => metric.validation === MetricValidation.VALIDATED
);

const metricsById: Map<Metric["id"], Metric> = new Map();
for (const metric of CERTIFIED_METRICS) {
  metricsById.set(metric.id, metric);
}

export const getMetricById = (
  key: keyof DailyMetricResponse
): Metric | undefined => metricsById.get(key);
