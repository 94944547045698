import { Farm } from "./Farm";
import { FarmList } from "./FarmList";
import { useRoutes } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

const _Farms: FunctionComponent = () => {
  return useRoutes([
    { path: "/", element: <FarmList /> },
    { path: "/:farmId", element: <Farm /> },
  ]);
};

export const Farms = withAuthenticationRequired(_Farms);
