import { capitalize } from "portal/utils/strings";
import { classes, SMALL_TEXT_FIELD_DARK } from "portal/utils/theme";
import { ConfigNode } from "protos/config/api/config_service";
import { debounce } from "portal/utils/timing";
import {
  filterConfigTree,
  indexConfigTree,
  searchConfigTree,
  SearchIndex,
} from "portal/utils/configs";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
} from "@mui/material";
import { GlobalHotKeys } from "react-hotkeys";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "portal/components/ErrorBoundary";
import { without } from "portal/utils/arrays";
import ChangedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import CollapsedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandedIcon from "@mui/icons-material/ChevronRightOutlined";
import React, {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const getParentPath = (path: string): string =>
  path.split("/").slice(0, -1).join("/");

// takes a path or array of paths and expands them to include all ancestors
export const expandPathsTo = (paths: string[] | string): string[] => {
  let expandedPaths: string[] = [];
  if (Array.isArray(paths)) {
    for (const path of paths) {
      expandedPaths = [...expandedPaths, ...expandPathsTo(path)];
    }
  } else {
    const transformedPaths = paths
      .split("/")
      .map((segment, index, segments) => segments.slice(0, index).join("/"));
    expandedPaths = [...expandedPaths, ...transformedPaths];
  }
  // remove dupes
  return [...new Set(expandedPaths)];
};

interface NodeProps {
  node: ConfigNode;
  path?: string;
  onSelect: (path: string) => void;
  changedPaths: string[] | undefined;
}

export const TreeNode: FunctionComponent<NodeProps> = ({
  node,
  onSelect,
  path: parentPath = "",
  changedPaths,
}) => {
  const breadcrumbs = parentPath ? `${parentPath}/` : "";
  const path = `${breadcrumbs}${node.name}`;
  return (
    <TreeItem
      classes={{
        label: "font-mono",
        selected: "font-bold bg-blue-600",
        content: "p-0",
      }}
      itemId={path}
      label={
        <span className="flex items-center">
          {changedPaths?.includes(path) ? (
            <ChangedIcon className="mr-2 text-yellow-400 text-xs" />
          ) : (
            <></>
          )}
          {node.name}
        </span>
      }
      onClick={() => {
        onSelect(path);
      }}
    >
      {node.children.map((child) => (
        <TreeNode
          key={`${path}/${child.name}`}
          node={child}
          onSelect={onSelect}
          path={path}
          changedPaths={changedPaths}
        />
      ))}
    </TreeItem>
  );
};

const TREE_SKELETON = (
  <>
    <Skeleton variant="text" className="w-10" />
    <Skeleton variant="text" className="w-16 ml-4" />
    <Skeleton variant="text" className="w-10 ml-4" />
    <Skeleton variant="text" className="w-12 ml-4" />
    <Skeleton variant="text" className="w-10" />
  </>
);

interface TreeProps {
  className?: string;
  config?: ConfigNode;
  initialShowChanged: boolean;
  onSelect: (path: string | null) => void;
  selectedPath: string;
  addedPaths: string[] | undefined;
  removedPaths: string[] | undefined;
  changedPathsExpanded: string[] | undefined;
  changedPathsTree?: ConfigNode;
  isTemplate?: boolean;
}

const _Tree: FunctionComponent<TreeProps> = ({
  className,
  config,
  initialShowChanged,
  onSelect,
  selectedPath,
  changedPathsExpanded,
  changedPathsTree,
  isTemplate = false,
}) => {
  const { t } = useTranslation();

  const { state } = useLocation();

  const [expanded, _setExpanded] = useState<string[]>(
    // expand path to selected node
    expandPathsTo(selectedPath)
  );

  const setExpanded = useCallback(
    (paths: string[]): void => {
      // keep parent of selected node expanded
      _setExpanded([...paths, getParentPath(selectedPath)]);
    },
    [selectedPath]
  );

  const [isSearching, setSearching] = useState<boolean>(false);
  const [savedExpanded, saveExpanded] = useState<string[]>([]);
  // searchInput is live text box; searchQuery is debounced
  const [searchInput, setSearchInput] = useState<string>(state?.search ?? "");
  const [searchQuery, setSearchQuery] = useState<string>(state?.search ?? "");
  const debounceSearchState = useRef(0); // increment to cancel outstanding callback
  const finalizeSearchQuery = useMemo(
    () =>
      debounce((query, expectedState) => {
        if (debounceSearchState.current !== expectedState) {
          return;
        }
        setSearchQuery(query);
        setSearching(false);
      }),
    []
  );

  const handleSearchChange = (value: string): void => {
    if (!value) {
      clearSearch();
      return;
    }
    // save expanded nodes so we can restore later
    if (value && !searchInput) {
      saveExpanded(expanded);
    }
    // update value
    setSearchInput(value);
    setSearching(true);
    finalizeSearchQuery(value, ++debounceSearchState.current);
  };
  const clearSearch = (): void => {
    ++debounceSearchState.current;
    setSearchInput("");
    setSearchQuery("");
    setSearching(false);
    setExpanded([selectedPath, ...savedExpanded]);
  };

  const searchIndex: SearchIndex = useMemo(
    () => indexConfigTree(config),
    [config]
  );
  const filteredConfig = useMemo(() => {
    const resultPaths = searchConfigTree(searchIndex, searchQuery);
    return filterConfigTree(config, resultPaths);
  }, [config, searchIndex, searchQuery]);

  // Reset set of expanded nodes when search query changes. This doesn't
  // depend on `config`/`searchIndex` because we don't want to reset
  // expansion after the user saves a config value. It's okay to omit that
  // dep because we expect changes to the config to only change the leaf
  // values (not the structure), which this callback doesn't actually need.
  useEffect(() => {
    if (!searchQuery) {
      return; // handled by `clearSearch`
    }
    const resultPaths = searchConfigTree(searchIndex, searchQuery);
    setExpanded(
      expandPathsTo(
        // slice off the root node because the tree doesn't know about it
        resultPaths.map((path) => path.split("/").slice(1).join("/"))
      )
    );
  }, [searchIndex, searchQuery, setExpanded]);

  // controlled tree
  const handleToggle = (event: SyntheticEvent, nodeId: string): void => {
    if (expanded.includes(nodeId)) {
      setExpanded(without(expanded, nodeId));
    } else {
      setExpanded([...expanded, nodeId]);
    }
  };
  const handleSelect = (event: SyntheticEvent, nodeId: string | null): void => {
    onSelect(nodeId);
  };
  const [showChanged, setShowChanged] = useState<boolean>(initialShowChanged);
  const inputRef = useRef<HTMLInputElement>();

  if (!config) {
    return (
      <div className={classes(className, "flex")}>
        <div className="min-w-32">{TREE_SKELETON}</div>
        <div className="h-full flex-grow flex pl-8">
          <Skeleton variant="rectangular" className="h-8 w-40" />
          <Skeleton variant="rectangular" className="h-8 w-28 ml-2" />
        </div>
      </div>
    );
  }

  const configTree = showChanged ? changedPathsTree : filteredConfig;

  return (
    <div className="flex flex-col gap-4">
      <GlobalHotKeys
        keyMap={{ FOCUS: ["/", "ctrl+f"] }}
        handlers={{
          FOCUS: (event) => {
            inputRef.current?.focus();
            event?.preventDefault();
          },
        }}
      />

      <TextField
        {...SMALL_TEXT_FIELD_DARK}
        inputRef={inputRef}
        value={searchInput}
        onChange={(event) => handleSearchChange(event.target.value)}
        className="w-full mr-5"
        label={t("utils.actions.search", {
          subject: capitalize(t("models.configs.config_other")),
        })}
        disabled={showChanged}
        inputProps={{ className: "font-mono" }}
        InputProps={{
          ...SMALL_TEXT_FIELD_DARK.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch} edge="end">
                <ClearIcon
                  className={classes("text-white", {
                    hidden: !searchInput,
                  })}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!isTemplate && (
        <FormControlLabel
          className="ml-1 text-sm"
          control={
            <Switch
              size="small"
              classes={{
                thumb: showChanged ? "bg-yellow-500" : "bg-white",
                track: showChanged ? "bg-yellow-100" : "bg-gray-200",
              }}
              checked={showChanged}
              onChange={(event, checked) => setShowChanged(checked)}
              name="changed"
            />
          }
          label={t("views.fleet.robots.config.onlyChanged")}
        />
      )}
      <div className="overflow-y-visible md:overflow-y-auto overflow-x-hidden basis-full md:basis-0 flex-grow-0 md:flex-grow">
        <SimpleTreeView
          className={classes(className)}
          selectedItems={selectedPath}
          expandedItems={expanded}
          onSelectedItemsChange={handleSelect}
          onItemExpansionToggle={handleToggle}
          slots={{
            collapseIcon: CollapsedIcon,
            expandIcon: ExpandedIcon,
          }}
        >
          {isSearching && TREE_SKELETON}
          {!isSearching &&
            (showChanged ? changedPathsTree : configTree)?.children.map(
              (child) => (
                <TreeNode
                  key={child.name}
                  node={child}
                  onSelect={onSelect}
                  changedPaths={changedPathsExpanded}
                />
              )
            )}
        </SimpleTreeView>
      </div>
    </div>
  );
};

export const Tree = withErrorBoundary(
  { i18nKey: "views.fleet.robots.config.errors.failed" },
  _Tree
);
