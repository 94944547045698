import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { buildPermission } from "portal/utils/auth";
import { formatList } from "portal/utils/strings";
import { getClass, RobotClass } from "portal/utils/robots";
import { getRobotPath, Path, RobotSubpath } from "portal/utils/routing";
import {
  Link,
  Navigate,
  useLocation,
  useParams,
  useRoutes,
} from "react-router-dom";
import { NoScroll } from "portal/components/Page";
import { OUTLINED_BUTTON_DARK, WHITE_BUTTON } from "portal/utils/theme";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RobotComputersList } from "./RobotComputersList";
import { RobotLasersList } from "./RobotLasersList";
import { RobotVersionsList } from "./RobotVersionHistory";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetRobotHardwareQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent, useMemo } from "react";

const SLAYER_SLOTS = [
  "1-1",
  "1-2",
  "1-3",
  "1-4",
  "1-5",
  "1-6",
  "1-7",
  "1-8",
  "1-9",
  "1-10",
  "2-1",
  "2-2",
  "2-3",
  "2-4",
  "2-5",
  "2-6",
  "2-7",
  "2-8",
  "2-9",
  "2-10",
  "3-1",
  "3-2",
  "3-3",
  "3-4",
  "3-5",
  "3-6",
  "3-7",
  "3-8",
  "3-9",
  "3-10",
];

const _RobotHardware: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();

  const { pathname } = useLocation();

  // ### ROUTING
  const element = useRoutes([
    {
      path: Path.BASE,
      element: (
        <Navigate
          to={getRobotPath(serial, RobotSubpath.HARDWARE_COMPUTERS)}
          replace
        />
      ),
    },
    {
      path: `${RobotSubpath.HARDWARE_COMPUTERS.replace(
        `${RobotSubpath.HARDWARE}/`,
        ""
      )}`,
      element: (
        <NoScroll className="md:inset-0">
          <RobotComputersList />
        </NoScroll>
      ),
    },
    {
      path: `${RobotSubpath.HARDWARE_VERSIONS.replace(
        `${RobotSubpath.HARDWARE}/`,
        ""
      )}`,
      element: <RobotVersionsList />,
    },
    {
      path: `${RobotSubpath.HARDWARE_LASERS.replace(
        `${RobotSubpath.HARDWARE}/`,
        ""
      )}`,
      element: (
        <NoScroll className="md:inset-0">
          <RobotLasersList />
        </NoScroll>
      ),
    },
  ]);

  const sections: { path: string; name: string }[] = [
    {
      path: getRobotPath(serial, RobotSubpath.HARDWARE_COMPUTERS),
      name: t("views.fleet.robots.hardware.tabs.computers"),
    },
    {
      path: getRobotPath(serial, RobotSubpath.HARDWARE_VERSIONS),
      name: t("views.fleet.robots.hardware.tabs.versions"),
    },
    {
      path: getRobotPath(serial, RobotSubpath.HARDWARE_LASERS),
      name: t("models.lasers.laser_other"),
    },
  ];

  const isSlayer = getClass(serial) === RobotClass.Slayers;
  const { data: hardware } = useQueryPopups(
    useGetRobotHardwareQuery(serial ? { serial } : skipToken, {
      skip: !isSlayer,
    })
  );

  const installedLasers = useMemo(
    () => hardware?.lasers.filter(({ removedAt }) => !removedAt) ?? [],
    [hardware]
  );

  const missingSlots = useMemo<string[]>(() => {
    if (!hardware || !isSlayer) {
      return [];
    }
    return SLAYER_SLOTS.filter(
      (slot) =>
        !installedLasers.some(
          ({ rowNumber, laserId }) => slot === `${rowNumber}-${laserId}`
        )
    );
  }, [hardware, installedLasers, isSlayer]);

  const duplicateSlots = useMemo<string[]>(() => {
    if (!hardware || !isSlayer) {
      return [];
    }

    const lasersBySlot: Record<string, number> = {};
    for (const { rowNumber, laserId } of installedLasers) {
      const slot = `${rowNumber}-${laserId}`;
      lasersBySlot[slot] = (lasersBySlot[slot] ?? 0) + 1;
    }

    return SLAYER_SLOTS.filter((slot) => (lasersBySlot[slot] ?? 0) > 1);
  }, [hardware, installedLasers, isSlayer]);

  return (
    <div className="w-full h-full flex flex-col">
      <ToggleButtonGroup
        color="primary"
        className="mb-8"
        exclusive
        value={pathname}
      >
        {sections.map(({ path, name }) => (
          <ToggleButton
            key={name}
            component={Link}
            to={path}
            value={path}
            {...(pathname.endsWith(path) ? WHITE_BUTTON : OUTLINED_BUTTON_DARK)}
          >
            {name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {pathname.endsWith(RobotSubpath.HARDWARE_LASERS) && (
        <>
          {missingSlots.length > 0 && (
            <Alert severity="warning" className="mb-4">
              {t("components.LaserTable.warnings.emptySlot", {
                slots: formatList(t, missingSlots),
              })}
            </Alert>
          )}
          {duplicateSlots.length > 0 && (
            <Alert severity="warning" className="mb-4">
              {t("components.LaserTable.warnings.duplicate", {
                slots: formatList(t, duplicateSlots),
              })}
            </Alert>
          )}
        </>
      )}
      <div className="flex-grow relative">{element}</div>
    </div>
  );
};

export const RobotHardware = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.hardware,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.hardware,
        PermissionDomain.all
      ),
    ],
    _RobotHardware
  )
);
