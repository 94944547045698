import { AccountDescription } from "portal/components/users/AccountDescription";
import {
  Alert,
  Avatar,
  Button,
  Chip,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import {
  BLUE_LOADING_BUTTON,
  GREEN_BUTTON,
  INPUT_DARK,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { boolean, number, object, string } from "yup";
import {
  buildPermission,
  CarbonUser,
  getCustomerId,
  isActivated,
} from "portal/utils/auth";
import { capitalize } from "portal/utils/strings";
import { CarbonCard } from "portal/components/CarbonCard";
import { CustomerSelector } from "portal/components/customers/CustomerSelector";
import { DeleteAccount } from "portal/components/users/DeleteAccount";
import { Field } from "formik";
import { TextField as FormikTextField, Select } from "formik-mui";
import { Header } from "portal/components/header/Header";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { Page } from "portal/components/Page";
import { Path } from "portal/utils/routing";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
  UserDisplayRole,
  userDisplayRoleToJSON,
} from "protos/portal/auth";
import { skipToken } from "@reduxjs/toolkit/query";
import { SwitchWithLabel } from "portal/components/SwitchWithLabel";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import { useGetUserQuery, useUpdateUserMutation } from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate, useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithSkeleton } from "portal/components/WithSkeleton";
import ExternalIcon from "@mui/icons-material/OpenInNewOutlined";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

const AVATAR_SIZE = 80;

export const isStaff = (user: CarbonUser): boolean => {
  const firstIdentity = user.identities?.[0];
  if (!firstIdentity) {
    return false;
  }
  return Boolean(
    user.email?.endsWith("@carbonrobotics.com") && firstIdentity.isSocial
  );
};

export const isContractor = (user: CarbonUser): boolean =>
  (user.email?.endsWith("@brainsurprise.com") ||
    user.email?.endsWith("@imerit.net") ||
    user.email?.endsWith("@dropsource.com") ||
    user.email?.endsWith("@tidyops.co")) ??
  false;

const _User: FunctionComponent = () => {
  const { t } = useTranslation();
  const { user: self, customer } = useSelf();
  const { id } = useParams();
  const navigate = useNavigate();
  const isSelf = self?.userId === id;

  const {
    data: userResponse,
    isError: isUserError,
    isSuccess: isUserSuccess,
  } = useQueryPopups(useGetUserQuery(id ? { userId: id } : skipToken));

  const user = userResponse?.user;
  const [updateUser] = useMutationPopups(useUpdateUserMutation(), {
    success: capitalize(
      t("utils.actions.saved", {
        subject: t("models.users.user_one"),
      })
    ),
  });

  const auth0Slug = window._jsenv.REACT_APP_AUTH0_AUTH_DOMAIN.replace(
    /\..*$/,
    ""
  );

  const canReadAll = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.users,
      PermissionDomain.all
    ),
  ]);
  const canReadCustomer =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.customer
      ),
    ]) && customer?.db?.id === user?.appMetadata?.customerId;

  const canRead = canReadAll || canReadCustomer;

  const canUpdateAll = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.users,
      PermissionDomain.all
    ),
  ]);

  const canUpdateCustomer =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.update,
        PermissionResource.users,
        PermissionDomain.customer
      ),
    ]) && customer?.db?.id === user?.appMetadata?.customerId;

  const canUpdate = canUpdateAll || canUpdateCustomer;

  const canUpdateAllPermissions = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.users_permissions,
      PermissionDomain.all
    ),
  ]);

  const canUpdateCustomerPermissions =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.update,
        PermissionResource.users_permissions,
        PermissionDomain.customer
      ),
    ]) && customer?.db?.id === user?.appMetadata?.customerId;

  const canUpdatePermissions =
    canUpdateAllPermissions || canUpdateCustomerPermissions;

  const visibleRoles: UserDisplayRole[] = canReadAll
    ? [
        UserDisplayRole.operator_basic,
        UserDisplayRole.operator_advanced,
        UserDisplayRole.farm_manager,
        UserDisplayRole.carbon_basic,
        UserDisplayRole.carbon_tech,
      ]
    : [
        UserDisplayRole.operator_basic,
        UserDisplayRole.operator_advanced,
        UserDisplayRole.farm_manager,
      ];

  if (!canReadAll && !canReadCustomer) {
    return;
  }

  return (
    <>
      <Header
        title={user?.name ?? user?.email ?? t("components.Loading.placeholder")}
        parentLink={Path.USERS}
      />
      <Page>
        {((isUserSuccess && !user) || isUserError) && (
          <Alert severity="error">{t("views.users.errors.notFound")}</Alert>
        )}
        <div className="flex gap-8 items-center">
          <WithSkeleton
            variant="circular"
            width={AVATAR_SIZE}
            height={AVATAR_SIZE}
            error={isUserError}
            success={isUserSuccess}
          >
            <Avatar
              sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
              src={user?.picture}
              alt={user?.name}
            />
          </WithSkeleton>
          <div className="flex flex-col items-start justify-center">
            <WithSkeleton
              variant="text"
              error={isUserError}
              success={isUserSuccess}
              className="min-h-8 min-2-24"
            >
              <Typography variant="h1" className="text-xl">
                {user?.name ?? user?.email}
              </Typography>
            </WithSkeleton>
            <WithSkeleton
              variant="text"
              error={isUserError}
              success={isUserSuccess}
              className="min-h-8 min-2-24"
            >
              {user && <AccountDescription user={user} className="text-sm" />}
              {user && isStaff(user) && (
                <Chip
                  label={t("models.users.staff")}
                  className="font-normal ml-4 bg-orange-500 text-white"
                />
              )}
            </WithSkeleton>
          </div>
        </div>
        <Grid container className="w-full items-stretch mt-8" spacing={2}>
          <CarbonCard
            title={t("views.users.sections.profile.title")}
            error={isUserError}
            success={isUserSuccess}
            formikProps={{
              initialValues: {
                name: user?.name ?? "",
              },
              validationSchema: object({
                name: string().required(),
              }),
              onSubmit: async ({ name }) => {
                if (!user || !canUpdate) {
                  return;
                }
                await updateUser({
                  userId: user.userId,
                  user: { name },
                });
              },
            }}
            content={
              <>
                <TextField
                  {...TEXT_FIELD_DARK}
                  disabled
                  value={user?.email}
                  label={t("models.users.fields.email")}
                  className="min-w-80 w-full"
                />
                {user && !isStaff(user) && (
                  <Field
                    {...TEXT_FIELD_DARK}
                    className="w-full"
                    component={FormikTextField}
                    name="name"
                    label={t("models.users.fields.name")}
                    disabled={!canUpdate}
                  />
                )}
              </>
            }
            actions={({ submitForm, isSubmitting, dirty }) => (
              <>
                {user && (
                  <DeleteAccount
                    user={user}
                    onDelete={() => navigate(Path.USERS)}
                  />
                )}
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  loading={isSubmitting}
                  onClick={submitForm}
                  disabled={!canUpdate || !dirty}
                  startIcon={<SaveIcon />}
                >
                  {t("utils.actions.save")}
                </LoadingButton>
              </>
            )}
          />
          {canRead && (
            <CarbonCard
              title={t("views.users.sections.permissions.title")}
              error={isUserError}
              success={isUserSuccess}
              formikProps={{
                initialValues: {
                  role: user?.appMetadata?.role ?? 0,
                },
                validationSchema: object({
                  role: number().required(),
                }),
                onSubmit: async ({ role }) => {
                  if (!user) {
                    return;
                  }
                  await updateUser({
                    userId: user.userId,
                    user: {
                      appMetadata: {
                        role,
                      },
                    },
                  });
                },
              }}
              content={
                <>
                  <Field
                    {...SELECT_DARK}
                    component={Select}
                    input={
                      <OutlinedInput
                        {...INPUT_DARK}
                        label={t("models.users.role_one")}
                      />
                    }
                    name="role"
                    disabled={!canUpdatePermissions || isSelf}
                    className="w-full"
                    label={t("models.users.role_one")}
                    renderValue={(encoded: string) => {
                      const role = Number(encoded);
                      const slug = userDisplayRoleToJSON(role);
                      // carbon.actions.compareKeys.ignoreDynamic
                      return t(`models.users.roles.${slug}`);
                    }}
                  >
                    {visibleRoles.map((role) => {
                      const slug = userDisplayRoleToJSON(role);
                      return (
                        <MenuItem value={role} key={role}>
                          {/* carbon.actions.compareKeys.ignoreDynamic */}
                          {t(`models.users.roles.${slug}`)}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </>
              }
              actions={({ submitForm, isSubmitting, dirty }) => (
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  loading={isSubmitting}
                  onClick={submitForm}
                  disabled={!canUpdate || !dirty}
                  startIcon={<SaveIcon />}
                >
                  {t("utils.actions.save")}
                </LoadingButton>
              )}
            />
          )}
          {canReadAll && (
            <CarbonCard
              title={t("views.users.sections.admin.title")}
              error={isUserError}
              success={!isUndefined(user)}
              formikProps={{
                initialValues: {
                  isActivated: isActivated(user),
                  customerId: getCustomerId(user)
                    ? Number(getCustomerId(user))
                    : "",
                },
                validationSchema: object({
                  isActivated: boolean().required(),
                  customerId: number().required(),
                }),
                onSubmit: async (values) => {
                  if (!canUpdate) {
                    return;
                  }
                  if (!user?.email) {
                    return;
                  }
                  await updateUser({
                    userId: user.userId,
                    user: {
                      appMetadata: {
                        isActivated: values.isActivated,
                        customerId: Number(values.customerId),
                      },
                    },
                  });
                },
              }}
              content={({ values, setFieldValue }) => (
                <>
                  <TextField
                    {...TEXT_FIELD_DARK}
                    disabled
                    value={user?.userId}
                    label={t("utils.descriptors.id")}
                    InputProps={{
                      ...TEXT_FIELD_DARK.InputProps,
                      className: "font-mono",
                    }}
                  />
                  <CustomerSelector
                    value={Number(values.customerId)}
                    onChange={(customerId) => {
                      setFieldValue("customerId", customerId);
                    }}
                    readOnly={!canUpdateAll}
                    className="mt-0"
                  />
                  {user && !isStaff(user) && (
                    <Field
                      component={SwitchWithLabel}
                      type="checkbox"
                      name="isActivated"
                      label={t("models.users.fields.isActivated")}
                      disabled={!canUpdateAll}
                      classes={{
                        thumb: values.isActivated ? "bg-green-500" : "",
                        track: values.isActivated ? "bg-green-700" : "",
                      }}
                    />
                  )}
                </>
              )}
              actions={({ submitForm, isSubmitting, dirty }) => (
                <>
                  {canUpdateAll && (
                    <Button
                      {...GREEN_BUTTON}
                      component="a"
                      href={`https://manage.auth0.com/dashboard/us/${auth0Slug}/users/${btoa(
                        encodeURIComponent(user?.userId ?? "")
                      )}`}
                      target="_blank"
                      endIcon={<ExternalIcon />}
                    >
                      {t("views.users.sections.admin.manage")}
                    </Button>
                  )}
                  <LoadingButton
                    {...BLUE_LOADING_BUTTON}
                    loading={isSubmitting}
                    onClick={submitForm}
                    disabled={!canUpdate || !dirty}
                    startIcon={<SaveIcon />}
                  >
                    {t("utils.actions.save")}
                  </LoadingButton>
                </>
              )}
            />
          )}
        </Grid>
      </Page>
    </>
  );
};

export const User = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.all
      ),
    ],
    _User
  )
);
