import { buildPermission, CarbonUser } from "portal/utils/auth";
import { CarbonMessage } from "./CarbonMessage";
import {
  Channel,
  MessageInput,
  ReactionOptions,
  Chat as StreamWrapper,
  Thread,
  UnreadMessagesNotification,
  UnreadMessagesSeparator,
  VirtualizedMessageList,
  Window,
} from "stream-chat-react";
import { classes } from "portal/utils/theme";
import { getCustomerSerial } from "portal/utils/robots";
import { IDMToken } from "portal/state/idmApi";
import { isAPIErrorResponse } from "portal/state/portalApi";
import { Loading } from "../Loading";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { sleepMs } from "portal/utils/sleepMs";
import { StreamChat } from "stream-chat";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useTranslation } from "react-i18next";
import { useUnmountEffect } from "portal/utils/hooks/useUnmountEffect";
import { WaitGroup } from "portal/utils/waitGroup";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import { withErrorBoundary } from "../ErrorBoundary";
import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";

const CARBON_REACTIONS: ReactionOptions = [
  {
    Component: () => <>✅</>,
    name: "Green Check Mark",
    type: "white_check_mark",
  },
  {
    Component: () => <>❌</>,
    name: "Cross",
    type: "x",
  },
  {
    Component: () => <>👀</>,
    name: "Eyeballs",
    type: "eyes",
  },
  {
    Component: () => <>🤔</>,
    name: "Thinker",
    type: "thinking_face",
  },
  {
    Component: () => <>❤️</>,
    name: "Love Heart",
    type: "heart",
  },
];

interface Props {
  serial: string;
  className?: string;
  user: CarbonUser;
  token: IDMToken;
}

const _Chat: FunctionComponent<Props> = ({
  serial,
  className,
  user,
  token,
}) => {
  const { t } = useTranslation();

  const waitGroupRef = useRef<WaitGroup | undefined>(undefined);
  if (!waitGroupRef.current) {
    waitGroupRef.current = new WaitGroup();
  }
  const waitGroup: WaitGroup = waitGroupRef.current;

  const [streamClient] = useMemoAsync<StreamChat | undefined>(
    async () => {
      let streamClient: StreamChat;
      try {
        streamClient = new StreamChat(window._jsenv.REACT_APP_STREAM_API_KEY);
        await streamClient.connectUser(
          {
            id: token.userId,
            name: user.name,
          },
          token.token
        );
        return streamClient;
      } catch (error: any) {
        if (isAPIErrorResponse(error)) {
          console.error(String(error.StatusCode), error.message);
        }
      }
    },
    // connect websocket once and only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
    undefined
  );

  useUnmountEffect(async () => {
    // We try to clean up our own tasks, but `stream-chat-react`'s
    // `Channel` misses a few. Wait a while before disconnecting to help
    // them succeed.
    await sleepMs(5 * 1000);
    // Now, wait for our own tasks; this is a background cleanup, so we
    // can afford to wait a while.
    await waitGroup.wait(10 * 1000);
    console.debug("Chat: disconnecting from client", streamClient);
    streamClient?.disconnectUser();
  });

  const channel = useMemo(() => {
    if (!streamClient?.user) {
      return;
    }
    return streamClient.channel("team", serial, {
      name: getCustomerSerial(t, serial),
    });
  }, [serial, t, streamClient]);
  useEffect(() => {
    if (channel) {
      waitGroup.add(channel.watch());
      return () => {
        if (!channel.disconnected) {
          waitGroup.add(channel.stopWatching());
        }
      };
    }
  }, [channel, waitGroup /* constant */]);

  if (!streamClient || streamClient.wsConnection?.isConnecting) {
    return <Loading />;
  }

  return (
    <div
      className={classes(
        "flex basis-0 flex-grow flex-shrink flex-col w-full overflow-y-auto",
        className
      )}
    >
      <StreamWrapper client={streamClient}>
        <Channel
          channel={channel}
          reactionOptions={CARBON_REACTIONS}
          UnreadMessagesNotification={UnreadMessagesNotification}
          UnreadMessagesSeparator={UnreadMessagesSeparator}
        >
          <Window>
            <VirtualizedMessageList Message={CarbonMessage} shouldGroupByUser />
            <MessageInput grow disableMentions />
          </Window>
          <div className="mt-10">
            <Thread Message={CarbonMessage} />
          </div>
        </Channel>
      </StreamWrapper>
    </div>
  );
};

export const Chat = withErrorBoundary(
  {},
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.chat,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.chat,
        PermissionDomain.all
      ),
    ],
    _Chat
  )
);
