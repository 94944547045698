import { Alert, Button } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { classes } from "portal/utils/theme";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useDeleteConfigCacheMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import React, { FunctionComponent, useState } from "react";
import RefreshIcon from "@mui/icons-material/RestartAlt";

interface ConfigCacheRefreshProps {
  serial: string | undefined;
}

const _ConfigCacheRefresh: FunctionComponent<ConfigCacheRefreshProps> = ({
  serial,
}) => {
  const { t } = useTranslation();
  const [deleteConfigCache] = useMutationPopups(useDeleteConfigCacheMutation());
  const [isLoading, setLoading] = useState(false);

  if (!serial) {
    return;
  }

  return (
    <Alert
      variant="filled"
      severity="info"
      className="mb-8"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={async () => {
            setLoading(true);
            const { error } = await deleteConfigCache({ serial });
            if (error) {
              setLoading(false);
            } else {
              window.location.reload();
            }
          }}
          startIcon={
            <RefreshIcon
              className={classes({
                "animate-reverse-spin": isLoading,
              })}
            />
          }
          disabled={isLoading}
        >
          {t("views.admin.config.clearCaches.action")}
        </Button>
      }
    >
      {t("views.admin.config.clearCaches.description")}
    </Alert>
  );
};

export const ConfigCacheRefresh = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.update,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ],
  _ConfigCacheRefresh
);
