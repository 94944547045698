import { buildPermission } from "portal/utils/auth";
import { Button } from "@mui/material";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { FieldDefinition } from "protos/portal/geo";
import { getRobotPath, RobotSubpath, Slug } from "portal/utils/routing";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NoScroll } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SearchField } from "portal/components/header/SearchField";
import { skipToken } from "@reduxjs/toolkit/query";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useListFieldDefinitionsQuery } from "portal/state/portalApi";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useState } from "react";

const CELL_PADDING_CLASS = "py-4";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

const _RobotFieldList: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>("");

  const { data: fields, isLoading } = useListFieldDefinitionsQuery(
    serial ? { serial } : skipToken
  );

  const columns: GridColDef<FieldDefinition>[] = [
    {
      ...defaultColumn,
      field: "name",
      headerName: t("models.fieldDefinitions.fields.name"),
    },
  ];

  const canRead = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.farms,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.farms,
      PermissionDomain.customer
    ),
  ]);
  const canUpdate = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.farms,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.farms,
      PermissionDomain.customer
    ),
  ]);

  if (!canRead) {
    return;
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<FieldDefinition>
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={t("utils.actions.searchLong", {
                  subject: titleCase(
                    t("models.fieldDefinitions.fieldDefinition", {
                      count: fields?.length ?? 0,
                    })
                  ),
                })}
              />
              <Button
                className="text-white"
                startIcon={<AddIcon />}
                onClick={() =>
                  navigate(
                    `${getRobotPath(serial, RobotSubpath.FIELDS)}/${Slug.NEW}`
                  )
                }
                disabled={!canUpdate}
              >
                <span className="hidden sm:inline">
                  {titleCase(
                    t("utils.actions.newLong", {
                      subject: t("models.fieldDefinitions.fieldDefinition_one"),
                    })
                  )}
                </span>
                <span className="sm:hidden">{t("utils.actions.new")}</span>
              </Button>
            </>
          }
          getRowId={({ fieldId }) => fieldId}
          className="flex flex-1"
          rows={fields ?? []}
          loading={isLoading}
          columns={columns}
          getRowHeight={() => "auto"}
          hideFooter
          disableRowSelectionOnClick
        />
      </div>
    </NoScroll>
  );
};

export const RobotFieldList = withAuthenticationRequired(_RobotFieldList);
