import { Alert, Button, Typography } from "@mui/material";
import { buildPermission } from "portal/utils/auth";
import { classes, OUTLINED_BUTTON } from "portal/utils/theme";
import { FeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { getReportInstancesPath } from "portal/utils/routing";
import { Header } from "portal/components/header/Header";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { ReportTable } from "portal/components/reports/ReportTable";
import { ReportTools } from "portal/components/reports/ReportTools";
import { RunReportDialog } from "portal/components/reports/RunReportDialog";
import { secondsToRange, useDateRange } from "portal/utils/reports";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetReportInstanceQuery,
  useGetReportQuery,
} from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { withFeatureFlag } from "portal/components/RequireFeatureFlag";
import React, { FunctionComponent, useState } from "react";

const _ReportInstance: FunctionComponent = () => {
  const { user } = useSelf();
  const { report: reportSlug, instance: instanceSlug } = useParams();
  const { t } = useTranslation();

  /**
   * Report
   */
  const {
    data: report,
    isLoading: isReportLoading,
    isError: isReportError,
  } = useQueryPopups(
    useGetReportQuery(
      reportSlug ? { reportSlug, instance: instanceSlug } : skipToken
    ),
    { errorVariant: "warning" }
  );

  const {
    data: instance,
    isLoading: isInstanceLoading,
    isError: isInstanceError,
  } = useQueryPopups(
    useGetReportInstanceQuery(
      reportSlug && instanceSlug ? { reportSlug, instanceSlug } : skipToken
    )
  );

  const dateRange = useDateRange(
    secondsToRange(instance?.startDate, instance?.endDate)
  );

  // edit report
  const [isEditing, setEditing] = useState<boolean>(false);
  const [saveAsCopy, setSaveAsCopy] = useState<boolean>(false);

  return (
    <>
      <Header
        title={
          isReportLoading ? t("components.Loading.placeholder") : instance?.name
        }
        parentLink={report ? getReportInstancesPath(report.slug) : undefined}
        hideMascot
      >
        <div className="flex-grow"></div>
        <div className="flex items-center gap-2">
          {!isInstanceError && (
            <>
              <ReportTools
                className="hidden md:flex"
                readOnly
                dateRange={dateRange}
                selectedRobots={instance?.robotIds ?? []}
                instance={instance}
              />
            </>
          )}
          {instance?.authorId === user?.userId && (
            <>
              <Button {...OUTLINED_BUTTON} onClick={() => setEditing(true)}>
                <span className="ml-1 hidden sm:block">
                  {t("utils.actions.editLong", {
                    subject: t("models.reportInstances.run_one"),
                  })}
                </span>
                <span className="ml-1 sm:hidden">
                  {t("utils.actions.edit")}
                </span>
              </Button>
              <Button
                {...OUTLINED_BUTTON}
                onClick={() => {
                  setSaveAsCopy(true);
                  setEditing(true);
                }}
              >
                <span className="sm:hidden">{t("utils.actions.copy")}</span>
                <span className="hidden sm:block">
                  {t("views.reports.scheduled.runDialog.runAgain")}
                </span>
              </Button>
            </>
          )}
        </div>
      </Header>
      <Page>
        {isInstanceError && (
          <Alert severity="warning">
            {t("views.reports.scheduled.errors.noReport")}
          </Alert>
        )}
        <div className={classes("flex flex-col h-full")}>
          <ReportTools
            className="md:hidden"
            readOnly
            dateRange={secondsToRange(instance?.startDate, instance?.endDate)}
            selectedRobots={instance?.robotIds}
          />
          <div className="flex justify-between items-center pb-4">
            <div>
              <Typography variant="h3" className="text-2xl md:text-4xl">
                {instance?.name}
              </Typography>
            </div>
          </div>
          {!isInstanceError && (
            <ReportTable
              instance={instance}
              loading={isReportLoading || isInstanceLoading}
            />
          )}
          {!isReportError && report && (
            <RunReportDialog
              saveAs={saveAsCopy}
              report={report}
              instance={instance}
              open={isEditing}
              onCancel={() => {
                setEditing(false);
                setSaveAsCopy(false);
              }}
            />
          )}
        </div>
      </Page>
    </>
  );
};

export const ReportInstance = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.self
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.reports,
        PermissionDomain.all
      ),
    ],
    withFeatureFlag(
      {
        flag: FeatureFlag.REPORTS,
        noFlag: "/",
      },
      _ReportInstance
    )
  )
);
