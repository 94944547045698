// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/category_profile.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Category, CategoryCollection } from "../category_profile/category_profile";

export const protobufPackage = "carbon.portal.category_profile";

export interface ExpandedCategoryCollectionResponse {
  profile: CategoryCollection | undefined;
  categories: Category[];
}

export interface UnsavedCategory {
  id: string;
  customerId?: string | undefined;
  name: string;
  chipIds: string[];
  /** selected images that need chip generation. */
  labelPointIds: string[];
  protected: boolean;
}

export interface ExpandedCategoryCollectionRequest {
  profile: CategoryCollection | undefined;
  categories: UnsavedCategory[];
}

function createBaseExpandedCategoryCollectionResponse(): ExpandedCategoryCollectionResponse {
  return { profile: undefined, categories: [] };
}

export const ExpandedCategoryCollectionResponse: MessageFns<ExpandedCategoryCollectionResponse> = {
  encode(message: ExpandedCategoryCollectionResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profile !== undefined) {
      CategoryCollection.encode(message.profile, writer.uint32(10).fork()).join();
    }
    for (const v of message.categories) {
      Category.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExpandedCategoryCollectionResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpandedCategoryCollectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profile = CategoryCollection.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.categories.push(Category.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpandedCategoryCollectionResponse {
    return {
      profile: isSet(object.profile) ? CategoryCollection.fromJSON(object.profile) : undefined,
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => Category.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExpandedCategoryCollectionResponse): unknown {
    const obj: any = {};
    if (message.profile !== undefined) {
      obj.profile = CategoryCollection.toJSON(message.profile);
    }
    if (message.categories?.length) {
      obj.categories = message.categories.map((e) => Category.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpandedCategoryCollectionResponse>, I>>(
    base?: I,
  ): ExpandedCategoryCollectionResponse {
    return ExpandedCategoryCollectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExpandedCategoryCollectionResponse>, I>>(
    object: I,
  ): ExpandedCategoryCollectionResponse {
    const message = createBaseExpandedCategoryCollectionResponse();
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? CategoryCollection.fromPartial(object.profile)
      : undefined;
    message.categories = object.categories?.map((e) => Category.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUnsavedCategory(): UnsavedCategory {
  return { id: "", customerId: undefined, name: "", chipIds: [], labelPointIds: [], protected: false };
}

export const UnsavedCategory: MessageFns<UnsavedCategory> = {
  encode(message: UnsavedCategory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.customerId !== undefined) {
      writer.uint32(18).string(message.customerId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.chipIds) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.labelPointIds) {
      writer.uint32(42).string(v!);
    }
    if (message.protected !== false) {
      writer.uint32(48).bool(message.protected);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UnsavedCategory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsavedCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.chipIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.labelPointIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.protected = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnsavedCategory {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      chipIds: globalThis.Array.isArray(object?.chipIds) ? object.chipIds.map((e: any) => globalThis.String(e)) : [],
      labelPointIds: globalThis.Array.isArray(object?.labelPointIds)
        ? object.labelPointIds.map((e: any) => globalThis.String(e))
        : [],
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
    };
  },

  toJSON(message: UnsavedCategory): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.customerId !== undefined) {
      obj.customerId = message.customerId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.chipIds?.length) {
      obj.chipIds = message.chipIds;
    }
    if (message.labelPointIds?.length) {
      obj.labelPointIds = message.labelPointIds;
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnsavedCategory>, I>>(base?: I): UnsavedCategory {
    return UnsavedCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnsavedCategory>, I>>(object: I): UnsavedCategory {
    const message = createBaseUnsavedCategory();
    message.id = object.id ?? "";
    message.customerId = object.customerId ?? undefined;
    message.name = object.name ?? "";
    message.chipIds = object.chipIds?.map((e) => e) || [];
    message.labelPointIds = object.labelPointIds?.map((e) => e) || [];
    message.protected = object.protected ?? false;
    return message;
  },
};

function createBaseExpandedCategoryCollectionRequest(): ExpandedCategoryCollectionRequest {
  return { profile: undefined, categories: [] };
}

export const ExpandedCategoryCollectionRequest: MessageFns<ExpandedCategoryCollectionRequest> = {
  encode(message: ExpandedCategoryCollectionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profile !== undefined) {
      CategoryCollection.encode(message.profile, writer.uint32(10).fork()).join();
    }
    for (const v of message.categories) {
      UnsavedCategory.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExpandedCategoryCollectionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpandedCategoryCollectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profile = CategoryCollection.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.categories.push(UnsavedCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpandedCategoryCollectionRequest {
    return {
      profile: isSet(object.profile) ? CategoryCollection.fromJSON(object.profile) : undefined,
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => UnsavedCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExpandedCategoryCollectionRequest): unknown {
    const obj: any = {};
    if (message.profile !== undefined) {
      obj.profile = CategoryCollection.toJSON(message.profile);
    }
    if (message.categories?.length) {
      obj.categories = message.categories.map((e) => UnsavedCategory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpandedCategoryCollectionRequest>, I>>(
    base?: I,
  ): ExpandedCategoryCollectionRequest {
    return ExpandedCategoryCollectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExpandedCategoryCollectionRequest>, I>>(
    object: I,
  ): ExpandedCategoryCollectionRequest {
    const message = createBaseExpandedCategoryCollectionRequest();
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? CategoryCollection.fromPartial(object.profile)
      : undefined;
    message.categories = object.categories?.map((e) => UnsavedCategory.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
