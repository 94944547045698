// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: frontend/status_bar.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../util/util";
import { DurationValue } from "./translation";

export const protobufPackage = "carbon.frontend.status_bar";

export enum Status {
  STATUS_ERROR = 0,
  STATUS_ESTOPPED = 1,
  STATUS_PRE_ARMED = 2,
  STATUS_POWERED_DOWN = 3,
  STATUS_POWERING_UP = 4,
  STATUS_UPDATE_INSTALLING = 5,
  STATUS_MODEL_LOADING = 6,
  STATUS_MODEL_INSTALLING = 7,
  STATUS_WEEDING = 8,
  STATUS_STANDBY = 9,
  STATUS_UNKNOWN = 10,
  STATUS_DISCONNECTED = 11,
  STATUS_LIFTED = 12,
  STATUS_LOADING = 13,
  STATUS_ALARM_AUTOFIX_IN_PROGRESS = 14,
  STATUS_FAILED_TO_POWER_UP = 15,
  STATUS_SERVER_CABINET_COOLDOWN = 16,
  STATUS_CHILLER_COOLDOWN = 17,
  UNRECOGNIZED = -1,
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "STATUS_ERROR":
      return Status.STATUS_ERROR;
    case 1:
    case "STATUS_ESTOPPED":
      return Status.STATUS_ESTOPPED;
    case 2:
    case "STATUS_PRE_ARMED":
      return Status.STATUS_PRE_ARMED;
    case 3:
    case "STATUS_POWERED_DOWN":
      return Status.STATUS_POWERED_DOWN;
    case 4:
    case "STATUS_POWERING_UP":
      return Status.STATUS_POWERING_UP;
    case 5:
    case "STATUS_UPDATE_INSTALLING":
      return Status.STATUS_UPDATE_INSTALLING;
    case 6:
    case "STATUS_MODEL_LOADING":
      return Status.STATUS_MODEL_LOADING;
    case 7:
    case "STATUS_MODEL_INSTALLING":
      return Status.STATUS_MODEL_INSTALLING;
    case 8:
    case "STATUS_WEEDING":
      return Status.STATUS_WEEDING;
    case 9:
    case "STATUS_STANDBY":
      return Status.STATUS_STANDBY;
    case 10:
    case "STATUS_UNKNOWN":
      return Status.STATUS_UNKNOWN;
    case 11:
    case "STATUS_DISCONNECTED":
      return Status.STATUS_DISCONNECTED;
    case 12:
    case "STATUS_LIFTED":
      return Status.STATUS_LIFTED;
    case 13:
    case "STATUS_LOADING":
      return Status.STATUS_LOADING;
    case 14:
    case "STATUS_ALARM_AUTOFIX_IN_PROGRESS":
      return Status.STATUS_ALARM_AUTOFIX_IN_PROGRESS;
    case 15:
    case "STATUS_FAILED_TO_POWER_UP":
      return Status.STATUS_FAILED_TO_POWER_UP;
    case 16:
    case "STATUS_SERVER_CABINET_COOLDOWN":
      return Status.STATUS_SERVER_CABINET_COOLDOWN;
    case 17:
    case "STATUS_CHILLER_COOLDOWN":
      return Status.STATUS_CHILLER_COOLDOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.STATUS_ERROR:
      return "STATUS_ERROR";
    case Status.STATUS_ESTOPPED:
      return "STATUS_ESTOPPED";
    case Status.STATUS_PRE_ARMED:
      return "STATUS_PRE_ARMED";
    case Status.STATUS_POWERED_DOWN:
      return "STATUS_POWERED_DOWN";
    case Status.STATUS_POWERING_UP:
      return "STATUS_POWERING_UP";
    case Status.STATUS_UPDATE_INSTALLING:
      return "STATUS_UPDATE_INSTALLING";
    case Status.STATUS_MODEL_LOADING:
      return "STATUS_MODEL_LOADING";
    case Status.STATUS_MODEL_INSTALLING:
      return "STATUS_MODEL_INSTALLING";
    case Status.STATUS_WEEDING:
      return "STATUS_WEEDING";
    case Status.STATUS_STANDBY:
      return "STATUS_STANDBY";
    case Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case Status.STATUS_DISCONNECTED:
      return "STATUS_DISCONNECTED";
    case Status.STATUS_LIFTED:
      return "STATUS_LIFTED";
    case Status.STATUS_LOADING:
      return "STATUS_LOADING";
    case Status.STATUS_ALARM_AUTOFIX_IN_PROGRESS:
      return "STATUS_ALARM_AUTOFIX_IN_PROGRESS";
    case Status.STATUS_FAILED_TO_POWER_UP:
      return "STATUS_FAILED_TO_POWER_UP";
    case Status.STATUS_SERVER_CABINET_COOLDOWN:
      return "STATUS_SERVER_CABINET_COOLDOWN";
    case Status.STATUS_CHILLER_COOLDOWN:
      return "STATUS_CHILLER_COOLDOWN";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum StatusLevel {
  INVALID = 0,
  READY = 1,
  LOADING = 2,
  /** @deprecated */
  ESTOPPED = 3,
  UNRECOGNIZED = -1,
}

export function statusLevelFromJSON(object: any): StatusLevel {
  switch (object) {
    case 0:
    case "INVALID":
      return StatusLevel.INVALID;
    case 1:
    case "READY":
      return StatusLevel.READY;
    case 2:
    case "LOADING":
      return StatusLevel.LOADING;
    case 3:
    case "ESTOPPED":
      return StatusLevel.ESTOPPED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StatusLevel.UNRECOGNIZED;
  }
}

export function statusLevelToJSON(object: StatusLevel): string {
  switch (object) {
    case StatusLevel.INVALID:
      return "INVALID";
    case StatusLevel.READY:
      return "READY";
    case StatusLevel.LOADING:
      return "LOADING";
    case StatusLevel.ESTOPPED:
      return "ESTOPPED";
    case StatusLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GlobalStatus {
  hint: string;
  iconName: string;
  iconColor: string;
}

export interface ServiceStatus {
  name: string;
  statusLevel: StatusLevel;
}

export interface ServerStatus {
  statusLevel: StatusLevel;
  serviceStatus: ServiceStatus[];
}

export interface TranslatedStatusMessageDetails {
  detailsStringKey?: string | undefined;
  timer?: DurationValue | undefined;
}

export interface TranslatedStatusMessage {
  prefix: string;
  details: TranslatedStatusMessageDetails | undefined;
}

export interface StatusBarMessage {
  ts:
    | Timestamp
    | undefined;
  /** @deprecated */
  estopped: boolean;
  lasersEnabled: boolean;
  weedingEnabled: boolean;
  statusLevel: Status;
  statusMessage: string;
  serial: string;
  rowStatus: { [key: number]: ServerStatus };
  commandStatus: ServerStatus | undefined;
  globalStatuses: GlobalStatus[];
  translatedStatusMessage: TranslatedStatusMessage | undefined;
}

export interface StatusBarMessage_RowStatusEntry {
  key: number;
  value: ServerStatus | undefined;
}

export interface ReportIssueRequest {
  description: string;
  phoneNumber: string;
}

export interface SupportPhoneMessage {
  supportPhone: string;
}

function createBaseGlobalStatus(): GlobalStatus {
  return { hint: "", iconName: "", iconColor: "" };
}

export const GlobalStatus: MessageFns<GlobalStatus> = {
  encode(message: GlobalStatus, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hint !== "") {
      writer.uint32(10).string(message.hint);
    }
    if (message.iconName !== "") {
      writer.uint32(18).string(message.iconName);
    }
    if (message.iconColor !== "") {
      writer.uint32(26).string(message.iconColor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GlobalStatus {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGlobalStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.hint = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.iconColor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GlobalStatus {
    return {
      hint: isSet(object.hint) ? globalThis.String(object.hint) : "",
      iconName: isSet(object.iconName) ? globalThis.String(object.iconName) : "",
      iconColor: isSet(object.iconColor) ? globalThis.String(object.iconColor) : "",
    };
  },

  toJSON(message: GlobalStatus): unknown {
    const obj: any = {};
    if (message.hint !== "") {
      obj.hint = message.hint;
    }
    if (message.iconName !== "") {
      obj.iconName = message.iconName;
    }
    if (message.iconColor !== "") {
      obj.iconColor = message.iconColor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GlobalStatus>, I>>(base?: I): GlobalStatus {
    return GlobalStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GlobalStatus>, I>>(object: I): GlobalStatus {
    const message = createBaseGlobalStatus();
    message.hint = object.hint ?? "";
    message.iconName = object.iconName ?? "";
    message.iconColor = object.iconColor ?? "";
    return message;
  },
};

function createBaseServiceStatus(): ServiceStatus {
  return { name: "", statusLevel: 0 };
}

export const ServiceStatus: MessageFns<ServiceStatus> = {
  encode(message: ServiceStatus, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.statusLevel !== 0) {
      writer.uint32(16).int32(message.statusLevel);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServiceStatus {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.statusLevel = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServiceStatus {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      statusLevel: isSet(object.statusLevel) ? statusLevelFromJSON(object.statusLevel) : 0,
    };
  },

  toJSON(message: ServiceStatus): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.statusLevel !== 0) {
      obj.statusLevel = statusLevelToJSON(message.statusLevel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServiceStatus>, I>>(base?: I): ServiceStatus {
    return ServiceStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceStatus>, I>>(object: I): ServiceStatus {
    const message = createBaseServiceStatus();
    message.name = object.name ?? "";
    message.statusLevel = object.statusLevel ?? 0;
    return message;
  },
};

function createBaseServerStatus(): ServerStatus {
  return { statusLevel: 0, serviceStatus: [] };
}

export const ServerStatus: MessageFns<ServerStatus> = {
  encode(message: ServerStatus, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.statusLevel !== 0) {
      writer.uint32(8).int32(message.statusLevel);
    }
    for (const v of message.serviceStatus) {
      ServiceStatus.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServerStatus {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusLevel = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceStatus.push(ServiceStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerStatus {
    return {
      statusLevel: isSet(object.statusLevel) ? statusLevelFromJSON(object.statusLevel) : 0,
      serviceStatus: globalThis.Array.isArray(object?.serviceStatus)
        ? object.serviceStatus.map((e: any) => ServiceStatus.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerStatus): unknown {
    const obj: any = {};
    if (message.statusLevel !== 0) {
      obj.statusLevel = statusLevelToJSON(message.statusLevel);
    }
    if (message.serviceStatus?.length) {
      obj.serviceStatus = message.serviceStatus.map((e) => ServiceStatus.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerStatus>, I>>(base?: I): ServerStatus {
    return ServerStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerStatus>, I>>(object: I): ServerStatus {
    const message = createBaseServerStatus();
    message.statusLevel = object.statusLevel ?? 0;
    message.serviceStatus = object.serviceStatus?.map((e) => ServiceStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTranslatedStatusMessageDetails(): TranslatedStatusMessageDetails {
  return { detailsStringKey: undefined, timer: undefined };
}

export const TranslatedStatusMessageDetails: MessageFns<TranslatedStatusMessageDetails> = {
  encode(message: TranslatedStatusMessageDetails, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.detailsStringKey !== undefined) {
      writer.uint32(10).string(message.detailsStringKey);
    }
    if (message.timer !== undefined) {
      DurationValue.encode(message.timer, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TranslatedStatusMessageDetails {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranslatedStatusMessageDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detailsStringKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timer = DurationValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranslatedStatusMessageDetails {
    return {
      detailsStringKey: isSet(object.detailsStringKey) ? globalThis.String(object.detailsStringKey) : undefined,
      timer: isSet(object.timer) ? DurationValue.fromJSON(object.timer) : undefined,
    };
  },

  toJSON(message: TranslatedStatusMessageDetails): unknown {
    const obj: any = {};
    if (message.detailsStringKey !== undefined) {
      obj.detailsStringKey = message.detailsStringKey;
    }
    if (message.timer !== undefined) {
      obj.timer = DurationValue.toJSON(message.timer);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TranslatedStatusMessageDetails>, I>>(base?: I): TranslatedStatusMessageDetails {
    return TranslatedStatusMessageDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TranslatedStatusMessageDetails>, I>>(
    object: I,
  ): TranslatedStatusMessageDetails {
    const message = createBaseTranslatedStatusMessageDetails();
    message.detailsStringKey = object.detailsStringKey ?? undefined;
    message.timer = (object.timer !== undefined && object.timer !== null)
      ? DurationValue.fromPartial(object.timer)
      : undefined;
    return message;
  },
};

function createBaseTranslatedStatusMessage(): TranslatedStatusMessage {
  return { prefix: "", details: undefined };
}

export const TranslatedStatusMessage: MessageFns<TranslatedStatusMessage> = {
  encode(message: TranslatedStatusMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.prefix !== "") {
      writer.uint32(10).string(message.prefix);
    }
    if (message.details !== undefined) {
      TranslatedStatusMessageDetails.encode(message.details, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TranslatedStatusMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranslatedStatusMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details = TranslatedStatusMessageDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranslatedStatusMessage {
    return {
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      details: isSet(object.details) ? TranslatedStatusMessageDetails.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: TranslatedStatusMessage): unknown {
    const obj: any = {};
    if (message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.details !== undefined) {
      obj.details = TranslatedStatusMessageDetails.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TranslatedStatusMessage>, I>>(base?: I): TranslatedStatusMessage {
    return TranslatedStatusMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TranslatedStatusMessage>, I>>(object: I): TranslatedStatusMessage {
    const message = createBaseTranslatedStatusMessage();
    message.prefix = object.prefix ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? TranslatedStatusMessageDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseStatusBarMessage(): StatusBarMessage {
  return {
    ts: undefined,
    estopped: false,
    lasersEnabled: false,
    weedingEnabled: false,
    statusLevel: 0,
    statusMessage: "",
    serial: "",
    rowStatus: {},
    commandStatus: undefined,
    globalStatuses: [],
    translatedStatusMessage: undefined,
  };
}

export const StatusBarMessage: MessageFns<StatusBarMessage> = {
  encode(message: StatusBarMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    if (message.estopped !== false) {
      writer.uint32(16).bool(message.estopped);
    }
    if (message.lasersEnabled !== false) {
      writer.uint32(24).bool(message.lasersEnabled);
    }
    if (message.weedingEnabled !== false) {
      writer.uint32(32).bool(message.weedingEnabled);
    }
    if (message.statusLevel !== 0) {
      writer.uint32(40).int32(message.statusLevel);
    }
    if (message.statusMessage !== "") {
      writer.uint32(50).string(message.statusMessage);
    }
    if (message.serial !== "") {
      writer.uint32(58).string(message.serial);
    }
    Object.entries(message.rowStatus).forEach(([key, value]) => {
      StatusBarMessage_RowStatusEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.commandStatus !== undefined) {
      ServerStatus.encode(message.commandStatus, writer.uint32(74).fork()).join();
    }
    for (const v of message.globalStatuses) {
      GlobalStatus.encode(v!, writer.uint32(82).fork()).join();
    }
    if (message.translatedStatusMessage !== undefined) {
      TranslatedStatusMessage.encode(message.translatedStatusMessage, writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StatusBarMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatusBarMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.estopped = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lasersEnabled = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.weedingEnabled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statusLevel = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.statusMessage = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = StatusBarMessage_RowStatusEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.rowStatus[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.commandStatus = ServerStatus.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.globalStatuses.push(GlobalStatus.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.translatedStatusMessage = TranslatedStatusMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StatusBarMessage {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      estopped: isSet(object.estopped) ? globalThis.Boolean(object.estopped) : false,
      lasersEnabled: isSet(object.lasersEnabled) ? globalThis.Boolean(object.lasersEnabled) : false,
      weedingEnabled: isSet(object.weedingEnabled) ? globalThis.Boolean(object.weedingEnabled) : false,
      statusLevel: isSet(object.statusLevel) ? statusFromJSON(object.statusLevel) : 0,
      statusMessage: isSet(object.statusMessage) ? globalThis.String(object.statusMessage) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      rowStatus: isObject(object.rowStatus)
        ? Object.entries(object.rowStatus).reduce<{ [key: number]: ServerStatus }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = ServerStatus.fromJSON(value);
          return acc;
        }, {})
        : {},
      commandStatus: isSet(object.commandStatus) ? ServerStatus.fromJSON(object.commandStatus) : undefined,
      globalStatuses: globalThis.Array.isArray(object?.globalStatuses)
        ? object.globalStatuses.map((e: any) => GlobalStatus.fromJSON(e))
        : [],
      translatedStatusMessage: isSet(object.translatedStatusMessage)
        ? TranslatedStatusMessage.fromJSON(object.translatedStatusMessage)
        : undefined,
    };
  },

  toJSON(message: StatusBarMessage): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.estopped !== false) {
      obj.estopped = message.estopped;
    }
    if (message.lasersEnabled !== false) {
      obj.lasersEnabled = message.lasersEnabled;
    }
    if (message.weedingEnabled !== false) {
      obj.weedingEnabled = message.weedingEnabled;
    }
    if (message.statusLevel !== 0) {
      obj.statusLevel = statusToJSON(message.statusLevel);
    }
    if (message.statusMessage !== "") {
      obj.statusMessage = message.statusMessage;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.rowStatus) {
      const entries = Object.entries(message.rowStatus);
      if (entries.length > 0) {
        obj.rowStatus = {};
        entries.forEach(([k, v]) => {
          obj.rowStatus[k] = ServerStatus.toJSON(v);
        });
      }
    }
    if (message.commandStatus !== undefined) {
      obj.commandStatus = ServerStatus.toJSON(message.commandStatus);
    }
    if (message.globalStatuses?.length) {
      obj.globalStatuses = message.globalStatuses.map((e) => GlobalStatus.toJSON(e));
    }
    if (message.translatedStatusMessage !== undefined) {
      obj.translatedStatusMessage = TranslatedStatusMessage.toJSON(message.translatedStatusMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StatusBarMessage>, I>>(base?: I): StatusBarMessage {
    return StatusBarMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StatusBarMessage>, I>>(object: I): StatusBarMessage {
    const message = createBaseStatusBarMessage();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.estopped = object.estopped ?? false;
    message.lasersEnabled = object.lasersEnabled ?? false;
    message.weedingEnabled = object.weedingEnabled ?? false;
    message.statusLevel = object.statusLevel ?? 0;
    message.statusMessage = object.statusMessage ?? "";
    message.serial = object.serial ?? "";
    message.rowStatus = Object.entries(object.rowStatus ?? {}).reduce<{ [key: number]: ServerStatus }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = ServerStatus.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.commandStatus = (object.commandStatus !== undefined && object.commandStatus !== null)
      ? ServerStatus.fromPartial(object.commandStatus)
      : undefined;
    message.globalStatuses = object.globalStatuses?.map((e) => GlobalStatus.fromPartial(e)) || [];
    message.translatedStatusMessage =
      (object.translatedStatusMessage !== undefined && object.translatedStatusMessage !== null)
        ? TranslatedStatusMessage.fromPartial(object.translatedStatusMessage)
        : undefined;
    return message;
  },
};

function createBaseStatusBarMessage_RowStatusEntry(): StatusBarMessage_RowStatusEntry {
  return { key: 0, value: undefined };
}

export const StatusBarMessage_RowStatusEntry: MessageFns<StatusBarMessage_RowStatusEntry> = {
  encode(message: StatusBarMessage_RowStatusEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== undefined) {
      ServerStatus.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StatusBarMessage_RowStatusEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatusBarMessage_RowStatusEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ServerStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StatusBarMessage_RowStatusEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? ServerStatus.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: StatusBarMessage_RowStatusEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = ServerStatus.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StatusBarMessage_RowStatusEntry>, I>>(base?: I): StatusBarMessage_RowStatusEntry {
    return StatusBarMessage_RowStatusEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StatusBarMessage_RowStatusEntry>, I>>(
    object: I,
  ): StatusBarMessage_RowStatusEntry {
    const message = createBaseStatusBarMessage_RowStatusEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? ServerStatus.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseReportIssueRequest(): ReportIssueRequest {
  return { description: "", phoneNumber: "" };
}

export const ReportIssueRequest: MessageFns<ReportIssueRequest> = {
  encode(message: ReportIssueRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ReportIssueRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportIssueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportIssueRequest {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
    };
  },

  toJSON(message: ReportIssueRequest): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportIssueRequest>, I>>(base?: I): ReportIssueRequest {
    return ReportIssueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportIssueRequest>, I>>(object: I): ReportIssueRequest {
    const message = createBaseReportIssueRequest();
    message.description = object.description ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseSupportPhoneMessage(): SupportPhoneMessage {
  return { supportPhone: "" };
}

export const SupportPhoneMessage: MessageFns<SupportPhoneMessage> = {
  encode(message: SupportPhoneMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.supportPhone !== "") {
      writer.uint32(10).string(message.supportPhone);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SupportPhoneMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupportPhoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.supportPhone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupportPhoneMessage {
    return { supportPhone: isSet(object.supportPhone) ? globalThis.String(object.supportPhone) : "" };
  },

  toJSON(message: SupportPhoneMessage): unknown {
    const obj: any = {};
    if (message.supportPhone !== "") {
      obj.supportPhone = message.supportPhone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SupportPhoneMessage>, I>>(base?: I): SupportPhoneMessage {
    return SupportPhoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SupportPhoneMessage>, I>>(object: I): SupportPhoneMessage {
    const message = createBaseSupportPhoneMessage();
    message.supportPhone = object.supportPhone ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
