// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: cv/cv.proto

/* eslint-disable */

export const protobufPackage = "carbon.aimbot.cv";

export enum P2PCaptureReason {
  /** P2PCaptureReason_MISS - Must keep order and increment by one as we iterate based on size */
  P2PCaptureReason_MISS = 0,
  P2PCaptureReason_SUCCESS = 1,
  P2PCaptureReason_JUMP = 2,
  P2PCaptureReason_First_P2P = 3,
  UNRECOGNIZED = -1,
}

export function p2PCaptureReasonFromJSON(object: any): P2PCaptureReason {
  switch (object) {
    case 0:
    case "P2PCaptureReason_MISS":
      return P2PCaptureReason.P2PCaptureReason_MISS;
    case 1:
    case "P2PCaptureReason_SUCCESS":
      return P2PCaptureReason.P2PCaptureReason_SUCCESS;
    case 2:
    case "P2PCaptureReason_JUMP":
      return P2PCaptureReason.P2PCaptureReason_JUMP;
    case 3:
    case "P2PCaptureReason_First_P2P":
      return P2PCaptureReason.P2PCaptureReason_First_P2P;
    case -1:
    case "UNRECOGNIZED":
    default:
      return P2PCaptureReason.UNRECOGNIZED;
  }
}

export function p2PCaptureReasonToJSON(object: P2PCaptureReason): string {
  switch (object) {
    case P2PCaptureReason.P2PCaptureReason_MISS:
      return "P2PCaptureReason_MISS";
    case P2PCaptureReason.P2PCaptureReason_SUCCESS:
      return "P2PCaptureReason_SUCCESS";
    case P2PCaptureReason.P2PCaptureReason_JUMP:
      return "P2PCaptureReason_JUMP";
    case P2PCaptureReason.P2PCaptureReason_First_P2P:
      return "P2PCaptureReason_First_P2P";
    case P2PCaptureReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
