import {
  ExpandedCategoryCollectionRequest,
  UnsavedCategory,
} from "protos/portal/category_profile";
import { TFunction } from "i18next";
import { titleCase } from "./strings";
import { v4 as uuid } from "uuid";

export interface PaginationParameters {
  page: number;
  pageSize: number;
}

// for M1, the robot will be able to match categories to the previous static ones specifically by matching the English name.
export const getDefaultCategoryNames = (
  t: TFunction,
  i18n: boolean = true
): string[] => {
  return i18n
    ? [
        t("models.weeds.categories.broadleaf"),
        t("models.weeds.categories.grass"),
        t("models.weeds.categories.offshoot"),
        t("models.weeds.categories.purslane"),
        t("models.crops.crop_one"),
      ].map((categoryName) => titleCase(categoryName))
    : ["Broadleaf", "Grass", "Offshoot", "Purslane", "Crop"];
};

export const getDefaultCategoryCollectionProfile = (
  t: TFunction,
  i18n: boolean = true
): ExpandedCategoryCollectionRequest => {
  const categories = getDefaultCategoryNames(t, i18n).map((name) =>
    UnsavedCategory.fromPartial({
      id: uuid(),
      name,
      chipIds: [],
      protected: true,
    })
  );
  return ExpandedCategoryCollectionRequest.fromPartial({
    profile: {
      id: uuid(),
      name: "",
      protected: true,
      categoryIds: categories.map(({ id }) => id),
    },
    categories,
  });
};

export interface CategorizationMap {
  chip: Record<string, string>; // chip id to category id
  labelPoint: Record<string, string>; // label point id to category id
}

/** Creates a convenience lookup for which images are categorized */
export const imagesToCategoryIds = (
  profile: ExpandedCategoryCollectionRequest | undefined
): CategorizationMap => {
  const categoryMap: CategorizationMap = { chip: {}, labelPoint: {} };
  if (!profile) {
    return categoryMap;
  }
  for (const { id, chipIds, labelPointIds } of profile.categories) {
    for (const chipId of chipIds) {
      categoryMap.chip[chipId] = id;
    }
    for (const labelPointId of labelPointIds) {
      categoryMap.labelPoint[labelPointId] = id;
    }
  }
  return categoryMap;
};

export const categoryCollectionProfileImageToggle = (
  profile: ExpandedCategoryCollectionRequest,
  activeCategoryId: string | undefined,
  {
    chipId,
    labelPointId,
  }: {
    chipId?: string;
    labelPointId: string;
  }
): ExpandedCategoryCollectionRequest => {
  if (!activeCategoryId || (!chipId && !labelPointId)) {
    return profile;
  }

  const updatedCategories = profile.categories.map((category) => {
    const isActiveCategory = category.id === activeCategoryId;

    const updatedChipIds = [...category.chipIds];
    if (chipId) {
      const existingIndex = category.chipIds.indexOf(chipId);
      if (existingIndex > -1) {
        // remove the chip id
        updatedChipIds.splice(existingIndex, 1);
      } else if (isActiveCategory) {
        // add the chip id to the active category
        updatedChipIds.push(chipId);
      }
    }

    const updatedLabelPointIds = [...category.labelPointIds];
    if (!chipId && labelPointId) {
      const existingIndex = category.labelPointIds.indexOf(labelPointId);
      if (existingIndex > -1) {
        // remove the label point id
        updatedLabelPointIds.splice(existingIndex, 1);
      } else if (isActiveCategory) {
        // add the label point id to the active category
        updatedLabelPointIds.push(labelPointId);
      }
    }

    return UnsavedCategory.fromJSON({
      ...category,
      chipIds: [...new Set(updatedChipIds)],
      labelPointIds: [...new Set(updatedLabelPointIds)],
    });
  });
  return {
    ...profile,
    categories: updatedCategories,
  };
};

export const getPointDetectionThumbnailPaddingFactor = (
  radius: number
): number => (radius < 30 ? 0.5 : 0.1);
