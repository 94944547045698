// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: veselka/pagination.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.veselka.pagination";

export enum ResultsCountType {
  UNKNOWN = 0,
  /** PRECISE - the exact number of results that exist */
  PRECISE = 1,
  /** ESTIMATED - a rough estimate of results */
  ESTIMATED = 2,
  UNRECOGNIZED = -1,
}

export function resultsCountTypeFromJSON(object: any): ResultsCountType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return ResultsCountType.UNKNOWN;
    case 1:
    case "PRECISE":
      return ResultsCountType.PRECISE;
    case 2:
    case "ESTIMATED":
      return ResultsCountType.ESTIMATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResultsCountType.UNRECOGNIZED;
  }
}

export function resultsCountTypeToJSON(object: ResultsCountType): string {
  switch (object) {
    case ResultsCountType.UNKNOWN:
      return "UNKNOWN";
    case ResultsCountType.PRECISE:
      return "PRECISE";
    case ResultsCountType.ESTIMATED:
      return "ESTIMATED";
    case ResultsCountType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Pagination {
  totalRecords: number;
  countType: ResultsCountType;
  currentPage: number;
  totalPages: number;
}

function createBasePagination(): Pagination {
  return { totalRecords: 0, countType: 0, currentPage: 0, totalPages: 0 };
}

export const Pagination: MessageFns<Pagination> = {
  encode(message: Pagination, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.totalRecords !== 0) {
      writer.uint32(8).int32(message.totalRecords);
    }
    if (message.countType !== 0) {
      writer.uint32(16).int32(message.countType);
    }
    if (message.currentPage !== 0) {
      writer.uint32(24).int32(message.currentPage);
    }
    if (message.totalPages !== 0) {
      writer.uint32(32).int32(message.totalPages);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Pagination {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.totalRecords = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.countType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentPage = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalPages = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pagination {
    return {
      totalRecords: isSet(object.totalRecords) ? globalThis.Number(object.totalRecords) : 0,
      countType: isSet(object.countType) ? resultsCountTypeFromJSON(object.countType) : 0,
      currentPage: isSet(object.currentPage) ? globalThis.Number(object.currentPage) : 0,
      totalPages: isSet(object.totalPages) ? globalThis.Number(object.totalPages) : 0,
    };
  },

  toJSON(message: Pagination): unknown {
    const obj: any = {};
    if (message.totalRecords !== 0) {
      obj.totalRecords = Math.round(message.totalRecords);
    }
    if (message.countType !== 0) {
      obj.countType = resultsCountTypeToJSON(message.countType);
    }
    if (message.currentPage !== 0) {
      obj.currentPage = Math.round(message.currentPage);
    }
    if (message.totalPages !== 0) {
      obj.totalPages = Math.round(message.totalPages);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pagination>, I>>(base?: I): Pagination {
    return Pagination.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pagination>, I>>(object: I): Pagination {
    const message = createBasePagination();
    message.totalRecords = object.totalRecords ?? 0;
    message.countType = object.countType ?? 0;
    message.currentPage = object.currentPage ?? 0;
    message.totalPages = object.totalPages ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
