// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/metrics.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.metrics";

export interface DailyMetricResponse {
  db: DB | undefined;
  serial: string;
  robotId: number;
  acresWeeded: number;
  avgSpeedMph: number;
  avgWeedSizeMm: number;
  bandingConfigName: string;
  bandingEnabled: boolean;
  coverageSpeedAcresHr: number;
  distanceWeededMeters: number;
  killedWeeds: number;
  missedWeeds: number;
  skippedWeeds: number;
  timeEfficiency: number;
  totalWeeds: number;
  uptimeSeconds: number;
  weedDensitySqFt: number;
  weedingUptimeSeconds: number;
  weedingEfficiency: number;
  weedsTypeCountBroadleaf: number;
  weedsTypeCountGrass: number;
  weedsTypeCountOffshoot: number;
  weedsTypeCountPurslane: number;
  notWeedingWeeds: number;
  date: string;
  keptCrops: number;
  missedCrops: number;
  notThinning: number;
  notWeeding: number;
  skippedCrops: number;
  thinnedCrops: number;
  totalCrops: number;
  bandingPercentage: number;
  thinningEfficiency: number;
  cropId: string;
  crop: string;
  cropDensitySqFt: number;
  avgCropSizeMm: number;
  avgTargetableReqLaserTime: number;
  avgUntargetableReqLaserTime: number;
  validCrops: number;
  operatorEffectiveness: number;
  targetWeedingTimeSeconds: number;
}

export interface DailyMetricsByDateResponse {
  metrics: { [key: string]: DailyMetricResponse };
}

export interface DailyMetricsByDateResponse_MetricsEntry {
  key: string;
  value: DailyMetricResponse | undefined;
}

export interface DailyMetricsByDateByRobotResponse {
  metrics: { [key: string]: DailyMetricsByDateResponse };
}

export interface DailyMetricsByDateByRobotResponse_MetricsEntry {
  key: string;
  value: DailyMetricsByDateResponse | undefined;
}

function createBaseDailyMetricResponse(): DailyMetricResponse {
  return {
    db: undefined,
    serial: "",
    robotId: 0,
    acresWeeded: 0,
    avgSpeedMph: 0,
    avgWeedSizeMm: 0,
    bandingConfigName: "",
    bandingEnabled: false,
    coverageSpeedAcresHr: 0,
    distanceWeededMeters: 0,
    killedWeeds: 0,
    missedWeeds: 0,
    skippedWeeds: 0,
    timeEfficiency: 0,
    totalWeeds: 0,
    uptimeSeconds: 0,
    weedDensitySqFt: 0,
    weedingUptimeSeconds: 0,
    weedingEfficiency: 0,
    weedsTypeCountBroadleaf: 0,
    weedsTypeCountGrass: 0,
    weedsTypeCountOffshoot: 0,
    weedsTypeCountPurslane: 0,
    notWeedingWeeds: 0,
    date: "",
    keptCrops: 0,
    missedCrops: 0,
    notThinning: 0,
    notWeeding: 0,
    skippedCrops: 0,
    thinnedCrops: 0,
    totalCrops: 0,
    bandingPercentage: 0,
    thinningEfficiency: 0,
    cropId: "",
    crop: "",
    cropDensitySqFt: 0,
    avgCropSizeMm: 0,
    avgTargetableReqLaserTime: 0,
    avgUntargetableReqLaserTime: 0,
    validCrops: 0,
    operatorEffectiveness: 0,
    targetWeedingTimeSeconds: 0,
  };
}

export const DailyMetricResponse: MessageFns<DailyMetricResponse> = {
  encode(message: DailyMetricResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.serial !== "") {
      writer.uint32(18).string(message.serial);
    }
    if (message.robotId !== 0) {
      writer.uint32(24).uint64(message.robotId);
    }
    if (message.acresWeeded !== 0) {
      writer.uint32(37).float(message.acresWeeded);
    }
    if (message.avgSpeedMph !== 0) {
      writer.uint32(45).float(message.avgSpeedMph);
    }
    if (message.avgWeedSizeMm !== 0) {
      writer.uint32(53).float(message.avgWeedSizeMm);
    }
    if (message.bandingConfigName !== "") {
      writer.uint32(58).string(message.bandingConfigName);
    }
    if (message.bandingEnabled !== false) {
      writer.uint32(64).bool(message.bandingEnabled);
    }
    if (message.coverageSpeedAcresHr !== 0) {
      writer.uint32(77).float(message.coverageSpeedAcresHr);
    }
    if (message.distanceWeededMeters !== 0) {
      writer.uint32(85).float(message.distanceWeededMeters);
    }
    if (message.killedWeeds !== 0) {
      writer.uint32(88).int64(message.killedWeeds);
    }
    if (message.missedWeeds !== 0) {
      writer.uint32(96).int64(message.missedWeeds);
    }
    if (message.skippedWeeds !== 0) {
      writer.uint32(104).int64(message.skippedWeeds);
    }
    if (message.timeEfficiency !== 0) {
      writer.uint32(117).float(message.timeEfficiency);
    }
    if (message.totalWeeds !== 0) {
      writer.uint32(120).int64(message.totalWeeds);
    }
    if (message.uptimeSeconds !== 0) {
      writer.uint32(133).float(message.uptimeSeconds);
    }
    if (message.weedDensitySqFt !== 0) {
      writer.uint32(141).float(message.weedDensitySqFt);
    }
    if (message.weedingUptimeSeconds !== 0) {
      writer.uint32(149).float(message.weedingUptimeSeconds);
    }
    if (message.weedingEfficiency !== 0) {
      writer.uint32(157).float(message.weedingEfficiency);
    }
    if (message.weedsTypeCountBroadleaf !== 0) {
      writer.uint32(160).int64(message.weedsTypeCountBroadleaf);
    }
    if (message.weedsTypeCountGrass !== 0) {
      writer.uint32(168).int64(message.weedsTypeCountGrass);
    }
    if (message.weedsTypeCountOffshoot !== 0) {
      writer.uint32(176).int64(message.weedsTypeCountOffshoot);
    }
    if (message.weedsTypeCountPurslane !== 0) {
      writer.uint32(184).int64(message.weedsTypeCountPurslane);
    }
    if (message.notWeedingWeeds !== 0) {
      writer.uint32(192).int64(message.notWeedingWeeds);
    }
    if (message.date !== "") {
      writer.uint32(202).string(message.date);
    }
    if (message.keptCrops !== 0) {
      writer.uint32(208).int64(message.keptCrops);
    }
    if (message.missedCrops !== 0) {
      writer.uint32(216).int64(message.missedCrops);
    }
    if (message.notThinning !== 0) {
      writer.uint32(224).int64(message.notThinning);
    }
    if (message.notWeeding !== 0) {
      writer.uint32(232).int64(message.notWeeding);
    }
    if (message.skippedCrops !== 0) {
      writer.uint32(240).int64(message.skippedCrops);
    }
    if (message.thinnedCrops !== 0) {
      writer.uint32(248).int64(message.thinnedCrops);
    }
    if (message.totalCrops !== 0) {
      writer.uint32(256).int64(message.totalCrops);
    }
    if (message.bandingPercentage !== 0) {
      writer.uint32(269).float(message.bandingPercentage);
    }
    if (message.thinningEfficiency !== 0) {
      writer.uint32(277).float(message.thinningEfficiency);
    }
    if (message.cropId !== "") {
      writer.uint32(282).string(message.cropId);
    }
    if (message.crop !== "") {
      writer.uint32(290).string(message.crop);
    }
    if (message.cropDensitySqFt !== 0) {
      writer.uint32(301).float(message.cropDensitySqFt);
    }
    if (message.avgCropSizeMm !== 0) {
      writer.uint32(309).float(message.avgCropSizeMm);
    }
    if (message.avgTargetableReqLaserTime !== 0) {
      writer.uint32(312).int64(message.avgTargetableReqLaserTime);
    }
    if (message.avgUntargetableReqLaserTime !== 0) {
      writer.uint32(320).int64(message.avgUntargetableReqLaserTime);
    }
    if (message.validCrops !== 0) {
      writer.uint32(328).int64(message.validCrops);
    }
    if (message.operatorEffectiveness !== 0) {
      writer.uint32(341).float(message.operatorEffectiveness);
    }
    if (message.targetWeedingTimeSeconds !== 0) {
      writer.uint32(344).int64(message.targetWeedingTimeSeconds);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetricResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetricResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.robotId = longToNumber(reader.uint64());
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.acresWeeded = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.avgSpeedMph = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.avgWeedSizeMm = reader.float();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.bandingConfigName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.bandingEnabled = reader.bool();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.coverageSpeedAcresHr = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.distanceWeededMeters = reader.float();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.killedWeeds = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.missedWeeds = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.skippedWeeds = longToNumber(reader.int64());
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.timeEfficiency = reader.float();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.totalWeeds = longToNumber(reader.int64());
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.uptimeSeconds = reader.float();
          continue;
        case 17:
          if (tag !== 141) {
            break;
          }

          message.weedDensitySqFt = reader.float();
          continue;
        case 18:
          if (tag !== 149) {
            break;
          }

          message.weedingUptimeSeconds = reader.float();
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.weedingEfficiency = reader.float();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.weedsTypeCountBroadleaf = longToNumber(reader.int64());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.weedsTypeCountGrass = longToNumber(reader.int64());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.weedsTypeCountOffshoot = longToNumber(reader.int64());
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.weedsTypeCountPurslane = longToNumber(reader.int64());
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.notWeedingWeeds = longToNumber(reader.int64());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.date = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.keptCrops = longToNumber(reader.int64());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.missedCrops = longToNumber(reader.int64());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.notThinning = longToNumber(reader.int64());
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.notWeeding = longToNumber(reader.int64());
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.skippedCrops = longToNumber(reader.int64());
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.thinnedCrops = longToNumber(reader.int64());
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.totalCrops = longToNumber(reader.int64());
          continue;
        case 33:
          if (tag !== 269) {
            break;
          }

          message.bandingPercentage = reader.float();
          continue;
        case 34:
          if (tag !== 277) {
            break;
          }

          message.thinningEfficiency = reader.float();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.cropId = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.crop = reader.string();
          continue;
        case 37:
          if (tag !== 301) {
            break;
          }

          message.cropDensitySqFt = reader.float();
          continue;
        case 38:
          if (tag !== 309) {
            break;
          }

          message.avgCropSizeMm = reader.float();
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.avgTargetableReqLaserTime = longToNumber(reader.int64());
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.avgUntargetableReqLaserTime = longToNumber(reader.int64());
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.validCrops = longToNumber(reader.int64());
          continue;
        case 42:
          if (tag !== 341) {
            break;
          }

          message.operatorEffectiveness = reader.float();
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.targetWeedingTimeSeconds = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetricResponse {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
      acresWeeded: isSet(object.acresWeeded) ? globalThis.Number(object.acresWeeded) : 0,
      avgSpeedMph: isSet(object.avgSpeedMph) ? globalThis.Number(object.avgSpeedMph) : 0,
      avgWeedSizeMm: isSet(object.avgWeedSizeMm) ? globalThis.Number(object.avgWeedSizeMm) : 0,
      bandingConfigName: isSet(object.bandingConfigName) ? globalThis.String(object.bandingConfigName) : "",
      bandingEnabled: isSet(object.bandingEnabled) ? globalThis.Boolean(object.bandingEnabled) : false,
      coverageSpeedAcresHr: isSet(object.coverageSpeedAcresHr) ? globalThis.Number(object.coverageSpeedAcresHr) : 0,
      distanceWeededMeters: isSet(object.distanceWeededMeters) ? globalThis.Number(object.distanceWeededMeters) : 0,
      killedWeeds: isSet(object.killedWeeds) ? globalThis.Number(object.killedWeeds) : 0,
      missedWeeds: isSet(object.missedWeeds) ? globalThis.Number(object.missedWeeds) : 0,
      skippedWeeds: isSet(object.skippedWeeds) ? globalThis.Number(object.skippedWeeds) : 0,
      timeEfficiency: isSet(object.timeEfficiency) ? globalThis.Number(object.timeEfficiency) : 0,
      totalWeeds: isSet(object.totalWeeds) ? globalThis.Number(object.totalWeeds) : 0,
      uptimeSeconds: isSet(object.uptimeSeconds) ? globalThis.Number(object.uptimeSeconds) : 0,
      weedDensitySqFt: isSet(object.weedDensitySqFt) ? globalThis.Number(object.weedDensitySqFt) : 0,
      weedingUptimeSeconds: isSet(object.weedingUptimeSeconds) ? globalThis.Number(object.weedingUptimeSeconds) : 0,
      weedingEfficiency: isSet(object.weedingEfficiency) ? globalThis.Number(object.weedingEfficiency) : 0,
      weedsTypeCountBroadleaf: isSet(object.weedsTypeCountBroadleaf)
        ? globalThis.Number(object.weedsTypeCountBroadleaf)
        : 0,
      weedsTypeCountGrass: isSet(object.weedsTypeCountGrass) ? globalThis.Number(object.weedsTypeCountGrass) : 0,
      weedsTypeCountOffshoot: isSet(object.weedsTypeCountOffshoot)
        ? globalThis.Number(object.weedsTypeCountOffshoot)
        : 0,
      weedsTypeCountPurslane: isSet(object.weedsTypeCountPurslane)
        ? globalThis.Number(object.weedsTypeCountPurslane)
        : 0,
      notWeedingWeeds: isSet(object.notWeedingWeeds) ? globalThis.Number(object.notWeedingWeeds) : 0,
      date: isSet(object.date) ? globalThis.String(object.date) : "",
      keptCrops: isSet(object.keptCrops) ? globalThis.Number(object.keptCrops) : 0,
      missedCrops: isSet(object.missedCrops) ? globalThis.Number(object.missedCrops) : 0,
      notThinning: isSet(object.notThinning) ? globalThis.Number(object.notThinning) : 0,
      notWeeding: isSet(object.notWeeding) ? globalThis.Number(object.notWeeding) : 0,
      skippedCrops: isSet(object.skippedCrops) ? globalThis.Number(object.skippedCrops) : 0,
      thinnedCrops: isSet(object.thinnedCrops) ? globalThis.Number(object.thinnedCrops) : 0,
      totalCrops: isSet(object.totalCrops) ? globalThis.Number(object.totalCrops) : 0,
      bandingPercentage: isSet(object.bandingPercentage) ? globalThis.Number(object.bandingPercentage) : 0,
      thinningEfficiency: isSet(object.thinningEfficiency) ? globalThis.Number(object.thinningEfficiency) : 0,
      cropId: isSet(object.cropId) ? globalThis.String(object.cropId) : "",
      crop: isSet(object.crop) ? globalThis.String(object.crop) : "",
      cropDensitySqFt: isSet(object.cropDensitySqFt) ? globalThis.Number(object.cropDensitySqFt) : 0,
      avgCropSizeMm: isSet(object.avgCropSizeMm) ? globalThis.Number(object.avgCropSizeMm) : 0,
      avgTargetableReqLaserTime: isSet(object.avgTargetableReqLaserTime)
        ? globalThis.Number(object.avgTargetableReqLaserTime)
        : 0,
      avgUntargetableReqLaserTime: isSet(object.avgUntargetableReqLaserTime)
        ? globalThis.Number(object.avgUntargetableReqLaserTime)
        : 0,
      validCrops: isSet(object.validCrops) ? globalThis.Number(object.validCrops) : 0,
      operatorEffectiveness: isSet(object.operatorEffectiveness) ? globalThis.Number(object.operatorEffectiveness) : 0,
      targetWeedingTimeSeconds: isSet(object.targetWeedingTimeSeconds)
        ? globalThis.Number(object.targetWeedingTimeSeconds)
        : 0,
    };
  },

  toJSON(message: DailyMetricResponse): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    if (message.acresWeeded !== 0) {
      obj.acresWeeded = message.acresWeeded;
    }
    if (message.avgSpeedMph !== 0) {
      obj.avgSpeedMph = message.avgSpeedMph;
    }
    if (message.avgWeedSizeMm !== 0) {
      obj.avgWeedSizeMm = message.avgWeedSizeMm;
    }
    if (message.bandingConfigName !== "") {
      obj.bandingConfigName = message.bandingConfigName;
    }
    if (message.bandingEnabled !== false) {
      obj.bandingEnabled = message.bandingEnabled;
    }
    if (message.coverageSpeedAcresHr !== 0) {
      obj.coverageSpeedAcresHr = message.coverageSpeedAcresHr;
    }
    if (message.distanceWeededMeters !== 0) {
      obj.distanceWeededMeters = message.distanceWeededMeters;
    }
    if (message.killedWeeds !== 0) {
      obj.killedWeeds = Math.round(message.killedWeeds);
    }
    if (message.missedWeeds !== 0) {
      obj.missedWeeds = Math.round(message.missedWeeds);
    }
    if (message.skippedWeeds !== 0) {
      obj.skippedWeeds = Math.round(message.skippedWeeds);
    }
    if (message.timeEfficiency !== 0) {
      obj.timeEfficiency = message.timeEfficiency;
    }
    if (message.totalWeeds !== 0) {
      obj.totalWeeds = Math.round(message.totalWeeds);
    }
    if (message.uptimeSeconds !== 0) {
      obj.uptimeSeconds = message.uptimeSeconds;
    }
    if (message.weedDensitySqFt !== 0) {
      obj.weedDensitySqFt = message.weedDensitySqFt;
    }
    if (message.weedingUptimeSeconds !== 0) {
      obj.weedingUptimeSeconds = message.weedingUptimeSeconds;
    }
    if (message.weedingEfficiency !== 0) {
      obj.weedingEfficiency = message.weedingEfficiency;
    }
    if (message.weedsTypeCountBroadleaf !== 0) {
      obj.weedsTypeCountBroadleaf = Math.round(message.weedsTypeCountBroadleaf);
    }
    if (message.weedsTypeCountGrass !== 0) {
      obj.weedsTypeCountGrass = Math.round(message.weedsTypeCountGrass);
    }
    if (message.weedsTypeCountOffshoot !== 0) {
      obj.weedsTypeCountOffshoot = Math.round(message.weedsTypeCountOffshoot);
    }
    if (message.weedsTypeCountPurslane !== 0) {
      obj.weedsTypeCountPurslane = Math.round(message.weedsTypeCountPurslane);
    }
    if (message.notWeedingWeeds !== 0) {
      obj.notWeedingWeeds = Math.round(message.notWeedingWeeds);
    }
    if (message.date !== "") {
      obj.date = message.date;
    }
    if (message.keptCrops !== 0) {
      obj.keptCrops = Math.round(message.keptCrops);
    }
    if (message.missedCrops !== 0) {
      obj.missedCrops = Math.round(message.missedCrops);
    }
    if (message.notThinning !== 0) {
      obj.notThinning = Math.round(message.notThinning);
    }
    if (message.notWeeding !== 0) {
      obj.notWeeding = Math.round(message.notWeeding);
    }
    if (message.skippedCrops !== 0) {
      obj.skippedCrops = Math.round(message.skippedCrops);
    }
    if (message.thinnedCrops !== 0) {
      obj.thinnedCrops = Math.round(message.thinnedCrops);
    }
    if (message.totalCrops !== 0) {
      obj.totalCrops = Math.round(message.totalCrops);
    }
    if (message.bandingPercentage !== 0) {
      obj.bandingPercentage = message.bandingPercentage;
    }
    if (message.thinningEfficiency !== 0) {
      obj.thinningEfficiency = message.thinningEfficiency;
    }
    if (message.cropId !== "") {
      obj.cropId = message.cropId;
    }
    if (message.crop !== "") {
      obj.crop = message.crop;
    }
    if (message.cropDensitySqFt !== 0) {
      obj.cropDensitySqFt = message.cropDensitySqFt;
    }
    if (message.avgCropSizeMm !== 0) {
      obj.avgCropSizeMm = message.avgCropSizeMm;
    }
    if (message.avgTargetableReqLaserTime !== 0) {
      obj.avgTargetableReqLaserTime = Math.round(message.avgTargetableReqLaserTime);
    }
    if (message.avgUntargetableReqLaserTime !== 0) {
      obj.avgUntargetableReqLaserTime = Math.round(message.avgUntargetableReqLaserTime);
    }
    if (message.validCrops !== 0) {
      obj.validCrops = Math.round(message.validCrops);
    }
    if (message.operatorEffectiveness !== 0) {
      obj.operatorEffectiveness = message.operatorEffectiveness;
    }
    if (message.targetWeedingTimeSeconds !== 0) {
      obj.targetWeedingTimeSeconds = Math.round(message.targetWeedingTimeSeconds);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetricResponse>, I>>(base?: I): DailyMetricResponse {
    return DailyMetricResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetricResponse>, I>>(object: I): DailyMetricResponse {
    const message = createBaseDailyMetricResponse();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.serial = object.serial ?? "";
    message.robotId = object.robotId ?? 0;
    message.acresWeeded = object.acresWeeded ?? 0;
    message.avgSpeedMph = object.avgSpeedMph ?? 0;
    message.avgWeedSizeMm = object.avgWeedSizeMm ?? 0;
    message.bandingConfigName = object.bandingConfigName ?? "";
    message.bandingEnabled = object.bandingEnabled ?? false;
    message.coverageSpeedAcresHr = object.coverageSpeedAcresHr ?? 0;
    message.distanceWeededMeters = object.distanceWeededMeters ?? 0;
    message.killedWeeds = object.killedWeeds ?? 0;
    message.missedWeeds = object.missedWeeds ?? 0;
    message.skippedWeeds = object.skippedWeeds ?? 0;
    message.timeEfficiency = object.timeEfficiency ?? 0;
    message.totalWeeds = object.totalWeeds ?? 0;
    message.uptimeSeconds = object.uptimeSeconds ?? 0;
    message.weedDensitySqFt = object.weedDensitySqFt ?? 0;
    message.weedingUptimeSeconds = object.weedingUptimeSeconds ?? 0;
    message.weedingEfficiency = object.weedingEfficiency ?? 0;
    message.weedsTypeCountBroadleaf = object.weedsTypeCountBroadleaf ?? 0;
    message.weedsTypeCountGrass = object.weedsTypeCountGrass ?? 0;
    message.weedsTypeCountOffshoot = object.weedsTypeCountOffshoot ?? 0;
    message.weedsTypeCountPurslane = object.weedsTypeCountPurslane ?? 0;
    message.notWeedingWeeds = object.notWeedingWeeds ?? 0;
    message.date = object.date ?? "";
    message.keptCrops = object.keptCrops ?? 0;
    message.missedCrops = object.missedCrops ?? 0;
    message.notThinning = object.notThinning ?? 0;
    message.notWeeding = object.notWeeding ?? 0;
    message.skippedCrops = object.skippedCrops ?? 0;
    message.thinnedCrops = object.thinnedCrops ?? 0;
    message.totalCrops = object.totalCrops ?? 0;
    message.bandingPercentage = object.bandingPercentage ?? 0;
    message.thinningEfficiency = object.thinningEfficiency ?? 0;
    message.cropId = object.cropId ?? "";
    message.crop = object.crop ?? "";
    message.cropDensitySqFt = object.cropDensitySqFt ?? 0;
    message.avgCropSizeMm = object.avgCropSizeMm ?? 0;
    message.avgTargetableReqLaserTime = object.avgTargetableReqLaserTime ?? 0;
    message.avgUntargetableReqLaserTime = object.avgUntargetableReqLaserTime ?? 0;
    message.validCrops = object.validCrops ?? 0;
    message.operatorEffectiveness = object.operatorEffectiveness ?? 0;
    message.targetWeedingTimeSeconds = object.targetWeedingTimeSeconds ?? 0;
    return message;
  },
};

function createBaseDailyMetricsByDateResponse(): DailyMetricsByDateResponse {
  return { metrics: {} };
}

export const DailyMetricsByDateResponse: MessageFns<DailyMetricsByDateResponse> = {
  encode(message: DailyMetricsByDateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.metrics).forEach(([key, value]) => {
      DailyMetricsByDateResponse_MetricsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetricsByDateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetricsByDateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DailyMetricsByDateResponse_MetricsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.metrics[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetricsByDateResponse {
    return {
      metrics: isObject(object.metrics)
        ? Object.entries(object.metrics).reduce<{ [key: string]: DailyMetricResponse }>((acc, [key, value]) => {
          acc[key] = DailyMetricResponse.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DailyMetricsByDateResponse): unknown {
    const obj: any = {};
    if (message.metrics) {
      const entries = Object.entries(message.metrics);
      if (entries.length > 0) {
        obj.metrics = {};
        entries.forEach(([k, v]) => {
          obj.metrics[k] = DailyMetricResponse.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetricsByDateResponse>, I>>(base?: I): DailyMetricsByDateResponse {
    return DailyMetricsByDateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetricsByDateResponse>, I>>(object: I): DailyMetricsByDateResponse {
    const message = createBaseDailyMetricsByDateResponse();
    message.metrics = Object.entries(object.metrics ?? {}).reduce<{ [key: string]: DailyMetricResponse }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DailyMetricResponse.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseDailyMetricsByDateResponse_MetricsEntry(): DailyMetricsByDateResponse_MetricsEntry {
  return { key: "", value: undefined };
}

export const DailyMetricsByDateResponse_MetricsEntry: MessageFns<DailyMetricsByDateResponse_MetricsEntry> = {
  encode(message: DailyMetricsByDateResponse_MetricsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DailyMetricResponse.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetricsByDateResponse_MetricsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetricsByDateResponse_MetricsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DailyMetricResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetricsByDateResponse_MetricsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DailyMetricResponse.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DailyMetricsByDateResponse_MetricsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DailyMetricResponse.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetricsByDateResponse_MetricsEntry>, I>>(
    base?: I,
  ): DailyMetricsByDateResponse_MetricsEntry {
    return DailyMetricsByDateResponse_MetricsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetricsByDateResponse_MetricsEntry>, I>>(
    object: I,
  ): DailyMetricsByDateResponse_MetricsEntry {
    const message = createBaseDailyMetricsByDateResponse_MetricsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DailyMetricResponse.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDailyMetricsByDateByRobotResponse(): DailyMetricsByDateByRobotResponse {
  return { metrics: {} };
}

export const DailyMetricsByDateByRobotResponse: MessageFns<DailyMetricsByDateByRobotResponse> = {
  encode(message: DailyMetricsByDateByRobotResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.metrics).forEach(([key, value]) => {
      DailyMetricsByDateByRobotResponse_MetricsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetricsByDateByRobotResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetricsByDateByRobotResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DailyMetricsByDateByRobotResponse_MetricsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.metrics[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetricsByDateByRobotResponse {
    return {
      metrics: isObject(object.metrics)
        ? Object.entries(object.metrics).reduce<{ [key: string]: DailyMetricsByDateResponse }>((acc, [key, value]) => {
          acc[key] = DailyMetricsByDateResponse.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DailyMetricsByDateByRobotResponse): unknown {
    const obj: any = {};
    if (message.metrics) {
      const entries = Object.entries(message.metrics);
      if (entries.length > 0) {
        obj.metrics = {};
        entries.forEach(([k, v]) => {
          obj.metrics[k] = DailyMetricsByDateResponse.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetricsByDateByRobotResponse>, I>>(
    base?: I,
  ): DailyMetricsByDateByRobotResponse {
    return DailyMetricsByDateByRobotResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetricsByDateByRobotResponse>, I>>(
    object: I,
  ): DailyMetricsByDateByRobotResponse {
    const message = createBaseDailyMetricsByDateByRobotResponse();
    message.metrics = Object.entries(object.metrics ?? {}).reduce<{ [key: string]: DailyMetricsByDateResponse }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DailyMetricsByDateResponse.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseDailyMetricsByDateByRobotResponse_MetricsEntry(): DailyMetricsByDateByRobotResponse_MetricsEntry {
  return { key: "", value: undefined };
}

export const DailyMetricsByDateByRobotResponse_MetricsEntry: MessageFns<
  DailyMetricsByDateByRobotResponse_MetricsEntry
> = {
  encode(
    message: DailyMetricsByDateByRobotResponse_MetricsEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DailyMetricsByDateResponse.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DailyMetricsByDateByRobotResponse_MetricsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyMetricsByDateByRobotResponse_MetricsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DailyMetricsByDateResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyMetricsByDateByRobotResponse_MetricsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DailyMetricsByDateResponse.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DailyMetricsByDateByRobotResponse_MetricsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DailyMetricsByDateResponse.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyMetricsByDateByRobotResponse_MetricsEntry>, I>>(
    base?: I,
  ): DailyMetricsByDateByRobotResponse_MetricsEntry {
    return DailyMetricsByDateByRobotResponse_MetricsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyMetricsByDateByRobotResponse_MetricsEntry>, I>>(
    object: I,
  ): DailyMetricsByDateByRobotResponse_MetricsEntry {
    const message = createBaseDailyMetricsByDateByRobotResponse_MetricsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DailyMetricsByDateResponse.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
