import { theme } from "portal/utils/theme";
import { titleCase } from "portal/utils/strings";
import { Tooltip } from "@mui/material";
import React, { FC } from "react";

interface CategoryImageOverlayProps {
  name: string;
  dimensions: {
    width: number;
    height: number;
  };
  color?: {
    bg: string;
    text?: string;
  };
}
export const CategoryImageOverlay: FC<CategoryImageOverlayProps> = ({
  name,
  dimensions,
  color = {
    bg: theme.colors.carbon.orange,
    text: theme.colors.white,
  },
}) => (
  <div
    className="absolute top-0 left-0 bg-transparent border-solid border-[6px] border-b-0 pointer-events-none"
    style={{ ...dimensions, borderColor: color.bg }}
  >
    <div className="flex flex-col justify-end h-full truncate">
      <Tooltip title={titleCase(name)}>
        <p
          className="text-xs m-0 pointer-events-auto"
          style={{ backgroundColor: color.bg, color: color.text }}
        >
          {name.toLocaleUpperCase()}
        </p>
      </Tooltip>
    </div>
  </div>
);
