import { Button, capitalize } from "@mui/material";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { ConfirmationDialog } from "portal/components/ConfirmationDialog";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { findWhere } from "portal/utils/arrays";
import { formatList, titleCase } from "portal/utils/strings";
import { getCustomerSerial } from "portal/utils/robots";
import { getReportInstancePath, getReportPath } from "portal/utils/routing";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { Header } from "portal/components/header/Header";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { Page } from "portal/components/Page";
import { RED_LOADING_BUTTON } from "portal/utils/theme";
import { ReportInstanceResponse } from "protos/portal/reports";
import { RunReportDialog } from "portal/components/reports/RunReportDialog";
import { SearchField } from "portal/components/header/SearchField";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteReportInstanceMutation,
  useGetReportQuery,
  useListReportInstancesQuery,
  useListRobotsQuery,
} from "portal/state/portalApi";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate, useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withFeatureFlag } from "portal/components/RequireFeatureFlag";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, { FunctionComponent, useState } from "react";

const defaultColumn: Partial<GridColDef> = {
  sortable: true,
  disableColumnMenu: true,
};

const _ReportInstanceList: FunctionComponent = () => {
  const { report: reportSlug } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isInternal } = useSelf();

  const { data: report, isSuccess: isReportSuccess } = useQueryPopups(
    useGetReportQuery(reportSlug ? { reportSlug } : skipToken),
    { errorVariant: "warning" }
  );

  const [deleteInstance, { isLoading: isDeleteLoading }] = useMutationPopups(
    useDeleteReportInstanceMutation(),
    {
      success: capitalize(
        t("utils.actions.deletedLong", {
          subject: capitalize(t("models.reports.report_one")),
        })
      ),
    }
  );
  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { data: instances, isLoading: isInstancesLoading } = useQueryPopups(
    useListReportInstancesQuery(reportSlug ? { slug: reportSlug } : skipToken)
  );

  const firstInstance = instances?.[0];

  const { data: summaries } = useQueryPopups(
    useListRobotsQuery({
      instance: firstInstance?.slug,
      latestMetrics: false,
    })
  );

  const { searchText, setSearchText, results } =
    useFuzzySearch<ReportInstanceResponse>({
      items: instances ?? [],
      options: {
        keys: ["name", "slug"],
      },
    });

  const [selectedSlugs, setSelectedSlugs] = useState<GridRowSelectionModel>([]);
  const [createNew, setCreateNew] = useState<boolean>(false);

  return (
    <>
      <Header
        title={
          isReportSuccess ? report.name : t("components.Loading.placeholder")
        }
        parentLink={getReportPath(reportSlug)}
      />
      <Page>
        <CarbonDataGrid<ReportInstanceResponse>
          className="flex flex-1"
          rows={results}
          getRowId={(report) => report.slug}
          getRowClassName={() => "cursor-pointer"}
          sortModel={[{ field: "name", sort: "asc" }]}
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={titleCase(
                  t("utils.actions.searchLong", {
                    subject: t("models.reportInstances.run", {
                      count: instances?.length ?? 0,
                    }),
                  })
                )}
              />
              <div className="flex gap-2">
                <LoadingButton
                  {...RED_LOADING_BUTTON}
                  loading={isDeleteLoading}
                  disabled={selectedSlugs.length === 0}
                  onClick={() => setConfirmDelete(true)}
                  startIcon={<DeleteIcon />}
                >
                  <span className="ml-1 hidden md:inline">
                    {t("utils.actions.deleteLong", {
                      subject: t("utils.table.selected"),
                    })}
                  </span>
                  <span className="ml-1 md:hidden">
                    {t("utils.actions.delete")}
                  </span>
                </LoadingButton>
                {confirmDelete && (
                  <ConfirmationDialog
                    title={t("utils.actions.deleteLong", {
                      subject: t("models.reportInstances.run", {
                        count: selectedSlugs.length,
                      }),
                    })}
                    description={t(
                      "components.ConfirmationDialog.delete.description",
                      {
                        subject: formatList(
                          t,
                          selectedSlugs.map(
                            (slug) =>
                              findWhere(instances, { slug })?.name ??
                              t("views.reports.scheduled.table.unknownReport")
                          )
                        ),
                      }
                    )}
                    destructive
                    yesText={t("utils.actions.deleteLong", {
                      subject: t("models.reportInstances.run", {
                        count: selectedSlugs.length,
                      }),
                    })}
                    onClose={() => setConfirmDelete(false)}
                    onYes={async () => {
                      if (!report) {
                        return;
                      }
                      await Promise.all(
                        selectedSlugs.map((slug) =>
                          deleteInstance({
                            reportSlug: report.slug,
                            instanceSlug: String(slug),
                          })
                        )
                      );
                    }}
                  />
                )}
                <Button
                  className="text-white justify-self-end"
                  startIcon={<AddIcon />}
                  onClick={() => setCreateNew(true)}
                >
                  <span className="ml-1 sm:hidden">
                    {t("utils.actions.new")}
                  </span>
                  <span className="ml-1 hidden sm:block">
                    {t("utils.actions.newLong", {
                      subject: t("models.reportInstances.run_one"),
                    })}
                  </span>
                </Button>
              </div>
            </>
          }
          hideFooter
          onRowClick={({ row: instance }) =>
            navigate(getReportInstancePath(reportSlug, instance.slug))
          }
          columns={[
            {
              ...defaultColumn,
              field: "db.createdAt",
              headerName: t("models.reportInstances.fields.createdAt"),
              valueGetter: (value, instance) =>
                instance.db?.createdAt ?? Number.NaN,
              valueFormatter: (value) =>
                Number.isNaN(value)
                  ? ""
                  : DateTime.fromMillis(value).toFormat(DATE_PATH_FORMAT),
            },
            {
              ...defaultColumn,
              field: "authorId",
              headerName: t("models.reportInstances.fields.authorId"),
              valueGetter: (value, instance) => {
                if (instance.authorName === "Carbon Robotics") {
                  return instance.authorName;
                }
                if (instance.automated) {
                  return t("views.reports.scheduled.authorCarbonBot");
                }
                return instance.authorName;
              },
            },
            {
              ...defaultColumn,
              field: "robots",
              headerName: titleCase(t("models.robots.robot_other")),
              valueGetter: (value, instance) =>
                formatList(
                  t,
                  instance.robotIds.map((robotId) => {
                    const robot = summaries?.find(
                      (summary) => summary.robot?.db?.id === robotId
                    )?.robot;
                    if (!robot) {
                      return "";
                    }
                    return isInternal
                      ? robot.serial
                      : getCustomerSerial(t, robot.serial);
                  })
                ),
            },
            {
              ...defaultColumn,
              field: "startDate",
              headerName: t("components.DateRangePicker.startDate"),
              valueFormatter: (value) =>
                isUndefined(value)
                  ? ""
                  : DateTime.fromSeconds(value).toFormat(DATE_PATH_FORMAT),
            },
            {
              ...defaultColumn,
              field: "endDate",
              headerName: t("components.DateRangePicker.endDate"),
              valueFormatter: (value) =>
                isUndefined(value)
                  ? ""
                  : DateTime.fromSeconds(value).toFormat(DATE_PATH_FORMAT),
            },
            {
              ...defaultColumn,
              field: "name",
              headerName: t("models.reportInstances.fields.name"),
            },
          ]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) =>
            setSelectedSlugs(newRowSelectionModel)
          }
          rowSelectionModel={selectedSlugs}
          disableRowSelectionOnClick
          loading={isInstancesLoading}
        />
        <RunReportDialog
          report={report}
          open={createNew}
          onCancel={() => setCreateNew(false)}
        />
      </Page>
    </>
  );
};

export const ReportInstanceList = withAuthenticationRequired(
  withFeatureFlag(
    {
      flag: FeatureFlag.REPORTS,
      noFlag: "/",
    },
    _ReportInstanceList
  )
);
