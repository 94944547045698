// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: frontend/alarm.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../util/util";
import { TranslationParameter } from "./translation";

export const protobufPackage = "carbon.frontend.alarm";

export enum AlarmLevel {
  AL_UNKNOWN = 0,
  AL_CRITICAL = 1,
  AL_HIGH = 2,
  AL_MEDIUM = 3,
  AL_LOW = 4,
  AL_HIDDEN = 5,
  UNRECOGNIZED = -1,
}

export function alarmLevelFromJSON(object: any): AlarmLevel {
  switch (object) {
    case 0:
    case "AL_UNKNOWN":
      return AlarmLevel.AL_UNKNOWN;
    case 1:
    case "AL_CRITICAL":
      return AlarmLevel.AL_CRITICAL;
    case 2:
    case "AL_HIGH":
      return AlarmLevel.AL_HIGH;
    case 3:
    case "AL_MEDIUM":
      return AlarmLevel.AL_MEDIUM;
    case 4:
    case "AL_LOW":
      return AlarmLevel.AL_LOW;
    case 5:
    case "AL_HIDDEN":
      return AlarmLevel.AL_HIDDEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlarmLevel.UNRECOGNIZED;
  }
}

export function alarmLevelToJSON(object: AlarmLevel): string {
  switch (object) {
    case AlarmLevel.AL_UNKNOWN:
      return "AL_UNKNOWN";
    case AlarmLevel.AL_CRITICAL:
      return "AL_CRITICAL";
    case AlarmLevel.AL_HIGH:
      return "AL_HIGH";
    case AlarmLevel.AL_MEDIUM:
      return "AL_MEDIUM";
    case AlarmLevel.AL_LOW:
      return "AL_LOW";
    case AlarmLevel.AL_HIDDEN:
      return "AL_HIDDEN";
    case AlarmLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AlarmImpact {
  AI_UNKNOWN = 0,
  AI_CRITICAL = 1,
  AI_OFFLINE = 2,
  AI_DEGRADED = 3,
  AI_NONE = 4,
  UNRECOGNIZED = -1,
}

export function alarmImpactFromJSON(object: any): AlarmImpact {
  switch (object) {
    case 0:
    case "AI_UNKNOWN":
      return AlarmImpact.AI_UNKNOWN;
    case 1:
    case "AI_CRITICAL":
      return AlarmImpact.AI_CRITICAL;
    case 2:
    case "AI_OFFLINE":
      return AlarmImpact.AI_OFFLINE;
    case 3:
    case "AI_DEGRADED":
      return AlarmImpact.AI_DEGRADED;
    case 4:
    case "AI_NONE":
      return AlarmImpact.AI_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlarmImpact.UNRECOGNIZED;
  }
}

export function alarmImpactToJSON(object: AlarmImpact): string {
  switch (object) {
    case AlarmImpact.AI_UNKNOWN:
      return "AI_UNKNOWN";
    case AlarmImpact.AI_CRITICAL:
      return "AI_CRITICAL";
    case AlarmImpact.AI_OFFLINE:
      return "AI_OFFLINE";
    case AlarmImpact.AI_DEGRADED:
      return "AI_DEGRADED";
    case AlarmImpact.AI_NONE:
      return "AI_NONE";
    case AlarmImpact.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AlarmRow {
  timestampMs: number;
  alarmCode: string;
  subsystem: string;
  description: string;
  level: AlarmLevel;
  identifier: string;
  acknowledged: boolean;
  impact: AlarmImpact;
  stopTimestampMs: number;
  autofixAvailable: boolean;
  autofixAttempted: boolean;
  autofixDurationSec: number;
  descriptionKey: string;
  translationParameters: TranslationParameter[];
}

export interface AlarmTable {
  ts: Timestamp | undefined;
  alarms: AlarmRow[];
}

export interface AlarmCount {
  ts: Timestamp | undefined;
  count: number;
}

export interface AcknowledgeRequest {
  identifier: string;
}

export interface GetNextAlarmLogRequest {
  fromIdx: number;
  toIdx: number;
  ts: Timestamp | undefined;
  visibleOnly: boolean;
}

export interface GetNextAlarmLogResponse {
  alarms: AlarmRow[];
  ts: Timestamp | undefined;
}

export interface GetNextAlarmLogCountRequest {
  ts: Timestamp | undefined;
  visibleOnly: boolean;
}

export interface GetNextAlarmLogCountResponse {
  numAlarms: number;
  ts: Timestamp | undefined;
}

export interface AttemptAutofixAlarmRequest {
  identifier: string;
}

export interface GetNextAutofixAlarmStatusRequest {
  ts: Timestamp | undefined;
}

export interface GetNextAutofixAlarmStatusResponse {
  ts: Timestamp | undefined;
  completed: boolean;
  errorMessage: string;
}

function createBaseAlarmRow(): AlarmRow {
  return {
    timestampMs: 0,
    alarmCode: "",
    subsystem: "",
    description: "",
    level: 0,
    identifier: "",
    acknowledged: false,
    impact: 0,
    stopTimestampMs: 0,
    autofixAvailable: false,
    autofixAttempted: false,
    autofixDurationSec: 0,
    descriptionKey: "",
    translationParameters: [],
  };
}

export const AlarmRow: MessageFns<AlarmRow> = {
  encode(message: AlarmRow, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampMs !== 0) {
      writer.uint32(8).int64(message.timestampMs);
    }
    if (message.alarmCode !== "") {
      writer.uint32(18).string(message.alarmCode);
    }
    if (message.subsystem !== "") {
      writer.uint32(26).string(message.subsystem);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.level !== 0) {
      writer.uint32(40).int32(message.level);
    }
    if (message.identifier !== "") {
      writer.uint32(50).string(message.identifier);
    }
    if (message.acknowledged !== false) {
      writer.uint32(56).bool(message.acknowledged);
    }
    if (message.impact !== 0) {
      writer.uint32(64).int32(message.impact);
    }
    if (message.stopTimestampMs !== 0) {
      writer.uint32(72).int64(message.stopTimestampMs);
    }
    if (message.autofixAvailable !== false) {
      writer.uint32(80).bool(message.autofixAvailable);
    }
    if (message.autofixAttempted !== false) {
      writer.uint32(88).bool(message.autofixAttempted);
    }
    if (message.autofixDurationSec !== 0) {
      writer.uint32(96).uint32(message.autofixDurationSec);
    }
    if (message.descriptionKey !== "") {
      writer.uint32(106).string(message.descriptionKey);
    }
    for (const v of message.translationParameters) {
      TranslationParameter.encode(v!, writer.uint32(114).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlarmRow {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlarmRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subsystem = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.acknowledged = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.impact = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.stopTimestampMs = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.autofixAvailable = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.autofixAttempted = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.autofixDurationSec = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.descriptionKey = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.translationParameters.push(TranslationParameter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlarmRow {
    return {
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      alarmCode: isSet(object.alarmCode) ? globalThis.String(object.alarmCode) : "",
      subsystem: isSet(object.subsystem) ? globalThis.String(object.subsystem) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      level: isSet(object.level) ? alarmLevelFromJSON(object.level) : 0,
      identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "",
      acknowledged: isSet(object.acknowledged) ? globalThis.Boolean(object.acknowledged) : false,
      impact: isSet(object.impact) ? alarmImpactFromJSON(object.impact) : 0,
      stopTimestampMs: isSet(object.stopTimestampMs) ? globalThis.Number(object.stopTimestampMs) : 0,
      autofixAvailable: isSet(object.autofixAvailable) ? globalThis.Boolean(object.autofixAvailable) : false,
      autofixAttempted: isSet(object.autofixAttempted) ? globalThis.Boolean(object.autofixAttempted) : false,
      autofixDurationSec: isSet(object.autofixDurationSec) ? globalThis.Number(object.autofixDurationSec) : 0,
      descriptionKey: isSet(object.descriptionKey) ? globalThis.String(object.descriptionKey) : "",
      translationParameters: globalThis.Array.isArray(object?.translationParameters)
        ? object.translationParameters.map((e: any) => TranslationParameter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AlarmRow): unknown {
    const obj: any = {};
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.alarmCode !== "") {
      obj.alarmCode = message.alarmCode;
    }
    if (message.subsystem !== "") {
      obj.subsystem = message.subsystem;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.level !== 0) {
      obj.level = alarmLevelToJSON(message.level);
    }
    if (message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    if (message.acknowledged !== false) {
      obj.acknowledged = message.acknowledged;
    }
    if (message.impact !== 0) {
      obj.impact = alarmImpactToJSON(message.impact);
    }
    if (message.stopTimestampMs !== 0) {
      obj.stopTimestampMs = Math.round(message.stopTimestampMs);
    }
    if (message.autofixAvailable !== false) {
      obj.autofixAvailable = message.autofixAvailable;
    }
    if (message.autofixAttempted !== false) {
      obj.autofixAttempted = message.autofixAttempted;
    }
    if (message.autofixDurationSec !== 0) {
      obj.autofixDurationSec = Math.round(message.autofixDurationSec);
    }
    if (message.descriptionKey !== "") {
      obj.descriptionKey = message.descriptionKey;
    }
    if (message.translationParameters?.length) {
      obj.translationParameters = message.translationParameters.map((e) => TranslationParameter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlarmRow>, I>>(base?: I): AlarmRow {
    return AlarmRow.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlarmRow>, I>>(object: I): AlarmRow {
    const message = createBaseAlarmRow();
    message.timestampMs = object.timestampMs ?? 0;
    message.alarmCode = object.alarmCode ?? "";
    message.subsystem = object.subsystem ?? "";
    message.description = object.description ?? "";
    message.level = object.level ?? 0;
    message.identifier = object.identifier ?? "";
    message.acknowledged = object.acknowledged ?? false;
    message.impact = object.impact ?? 0;
    message.stopTimestampMs = object.stopTimestampMs ?? 0;
    message.autofixAvailable = object.autofixAvailable ?? false;
    message.autofixAttempted = object.autofixAttempted ?? false;
    message.autofixDurationSec = object.autofixDurationSec ?? 0;
    message.descriptionKey = object.descriptionKey ?? "";
    message.translationParameters = object.translationParameters?.map((e) => TranslationParameter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAlarmTable(): AlarmTable {
  return { ts: undefined, alarms: [] };
}

export const AlarmTable: MessageFns<AlarmTable> = {
  encode(message: AlarmTable, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    for (const v of message.alarms) {
      AlarmRow.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlarmTable {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlarmTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarms.push(AlarmRow.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlarmTable {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      alarms: globalThis.Array.isArray(object?.alarms) ? object.alarms.map((e: any) => AlarmRow.fromJSON(e)) : [],
    };
  },

  toJSON(message: AlarmTable): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => AlarmRow.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlarmTable>, I>>(base?: I): AlarmTable {
    return AlarmTable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlarmTable>, I>>(object: I): AlarmTable {
    const message = createBaseAlarmTable();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.alarms = object.alarms?.map((e) => AlarmRow.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAlarmCount(): AlarmCount {
  return { ts: undefined, count: 0 };
}

export const AlarmCount: MessageFns<AlarmCount> = {
  encode(message: AlarmCount, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlarmCount {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlarmCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlarmCount {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: AlarmCount): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlarmCount>, I>>(base?: I): AlarmCount {
    return AlarmCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlarmCount>, I>>(object: I): AlarmCount {
    const message = createBaseAlarmCount();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseAcknowledgeRequest(): AcknowledgeRequest {
  return { identifier: "" };
}

export const AcknowledgeRequest: MessageFns<AcknowledgeRequest> = {
  encode(message: AcknowledgeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AcknowledgeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcknowledgeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeRequest {
    return { identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "" };
  },

  toJSON(message: AcknowledgeRequest): unknown {
    const obj: any = {};
    if (message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AcknowledgeRequest>, I>>(base?: I): AcknowledgeRequest {
    return AcknowledgeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcknowledgeRequest>, I>>(object: I): AcknowledgeRequest {
    const message = createBaseAcknowledgeRequest();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseGetNextAlarmLogRequest(): GetNextAlarmLogRequest {
  return { fromIdx: 0, toIdx: 0, ts: undefined, visibleOnly: false };
}

export const GetNextAlarmLogRequest: MessageFns<GetNextAlarmLogRequest> = {
  encode(message: GetNextAlarmLogRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.fromIdx !== 0) {
      writer.uint32(8).int32(message.fromIdx);
    }
    if (message.toIdx !== 0) {
      writer.uint32(16).int32(message.toIdx);
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(26).fork()).join();
    }
    if (message.visibleOnly !== false) {
      writer.uint32(32).bool(message.visibleOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAlarmLogRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAlarmLogRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fromIdx = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.toIdx = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.visibleOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAlarmLogRequest {
    return {
      fromIdx: isSet(object.fromIdx) ? globalThis.Number(object.fromIdx) : 0,
      toIdx: isSet(object.toIdx) ? globalThis.Number(object.toIdx) : 0,
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      visibleOnly: isSet(object.visibleOnly) ? globalThis.Boolean(object.visibleOnly) : false,
    };
  },

  toJSON(message: GetNextAlarmLogRequest): unknown {
    const obj: any = {};
    if (message.fromIdx !== 0) {
      obj.fromIdx = Math.round(message.fromIdx);
    }
    if (message.toIdx !== 0) {
      obj.toIdx = Math.round(message.toIdx);
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.visibleOnly !== false) {
      obj.visibleOnly = message.visibleOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAlarmLogRequest>, I>>(base?: I): GetNextAlarmLogRequest {
    return GetNextAlarmLogRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAlarmLogRequest>, I>>(object: I): GetNextAlarmLogRequest {
    const message = createBaseGetNextAlarmLogRequest();
    message.fromIdx = object.fromIdx ?? 0;
    message.toIdx = object.toIdx ?? 0;
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.visibleOnly = object.visibleOnly ?? false;
    return message;
  },
};

function createBaseGetNextAlarmLogResponse(): GetNextAlarmLogResponse {
  return { alarms: [], ts: undefined };
}

export const GetNextAlarmLogResponse: MessageFns<GetNextAlarmLogResponse> = {
  encode(message: GetNextAlarmLogResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.alarms) {
      AlarmRow.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAlarmLogResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAlarmLogResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(AlarmRow.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAlarmLogResponse {
    return {
      alarms: globalThis.Array.isArray(object?.alarms) ? object.alarms.map((e: any) => AlarmRow.fromJSON(e)) : [],
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
    };
  },

  toJSON(message: GetNextAlarmLogResponse): unknown {
    const obj: any = {};
    if (message.alarms?.length) {
      obj.alarms = message.alarms.map((e) => AlarmRow.toJSON(e));
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAlarmLogResponse>, I>>(base?: I): GetNextAlarmLogResponse {
    return GetNextAlarmLogResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAlarmLogResponse>, I>>(object: I): GetNextAlarmLogResponse {
    const message = createBaseGetNextAlarmLogResponse();
    message.alarms = object.alarms?.map((e) => AlarmRow.fromPartial(e)) || [];
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    return message;
  },
};

function createBaseGetNextAlarmLogCountRequest(): GetNextAlarmLogCountRequest {
  return { ts: undefined, visibleOnly: false };
}

export const GetNextAlarmLogCountRequest: MessageFns<GetNextAlarmLogCountRequest> = {
  encode(message: GetNextAlarmLogCountRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    if (message.visibleOnly !== false) {
      writer.uint32(16).bool(message.visibleOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAlarmLogCountRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAlarmLogCountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.visibleOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAlarmLogCountRequest {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      visibleOnly: isSet(object.visibleOnly) ? globalThis.Boolean(object.visibleOnly) : false,
    };
  },

  toJSON(message: GetNextAlarmLogCountRequest): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.visibleOnly !== false) {
      obj.visibleOnly = message.visibleOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAlarmLogCountRequest>, I>>(base?: I): GetNextAlarmLogCountRequest {
    return GetNextAlarmLogCountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAlarmLogCountRequest>, I>>(object: I): GetNextAlarmLogCountRequest {
    const message = createBaseGetNextAlarmLogCountRequest();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.visibleOnly = object.visibleOnly ?? false;
    return message;
  },
};

function createBaseGetNextAlarmLogCountResponse(): GetNextAlarmLogCountResponse {
  return { numAlarms: 0, ts: undefined };
}

export const GetNextAlarmLogCountResponse: MessageFns<GetNextAlarmLogCountResponse> = {
  encode(message: GetNextAlarmLogCountResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.numAlarms !== 0) {
      writer.uint32(8).int32(message.numAlarms);
    }
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAlarmLogCountResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAlarmLogCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numAlarms = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAlarmLogCountResponse {
    return {
      numAlarms: isSet(object.numAlarms) ? globalThis.Number(object.numAlarms) : 0,
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
    };
  },

  toJSON(message: GetNextAlarmLogCountResponse): unknown {
    const obj: any = {};
    if (message.numAlarms !== 0) {
      obj.numAlarms = Math.round(message.numAlarms);
    }
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAlarmLogCountResponse>, I>>(base?: I): GetNextAlarmLogCountResponse {
    return GetNextAlarmLogCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAlarmLogCountResponse>, I>>(object: I): GetNextAlarmLogCountResponse {
    const message = createBaseGetNextAlarmLogCountResponse();
    message.numAlarms = object.numAlarms ?? 0;
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    return message;
  },
};

function createBaseAttemptAutofixAlarmRequest(): AttemptAutofixAlarmRequest {
  return { identifier: "" };
}

export const AttemptAutofixAlarmRequest: MessageFns<AttemptAutofixAlarmRequest> = {
  encode(message: AttemptAutofixAlarmRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AttemptAutofixAlarmRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttemptAutofixAlarmRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttemptAutofixAlarmRequest {
    return { identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "" };
  },

  toJSON(message: AttemptAutofixAlarmRequest): unknown {
    const obj: any = {};
    if (message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttemptAutofixAlarmRequest>, I>>(base?: I): AttemptAutofixAlarmRequest {
    return AttemptAutofixAlarmRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttemptAutofixAlarmRequest>, I>>(object: I): AttemptAutofixAlarmRequest {
    const message = createBaseAttemptAutofixAlarmRequest();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseGetNextAutofixAlarmStatusRequest(): GetNextAutofixAlarmStatusRequest {
  return { ts: undefined };
}

export const GetNextAutofixAlarmStatusRequest: MessageFns<GetNextAutofixAlarmStatusRequest> = {
  encode(message: GetNextAutofixAlarmStatusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAutofixAlarmStatusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAutofixAlarmStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAutofixAlarmStatusRequest {
    return { ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined };
  },

  toJSON(message: GetNextAutofixAlarmStatusRequest): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAutofixAlarmStatusRequest>, I>>(
    base?: I,
  ): GetNextAutofixAlarmStatusRequest {
    return GetNextAutofixAlarmStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAutofixAlarmStatusRequest>, I>>(
    object: I,
  ): GetNextAutofixAlarmStatusRequest {
    const message = createBaseGetNextAutofixAlarmStatusRequest();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    return message;
  },
};

function createBaseGetNextAutofixAlarmStatusResponse(): GetNextAutofixAlarmStatusResponse {
  return { ts: undefined, completed: false, errorMessage: "" };
}

export const GetNextAutofixAlarmStatusResponse: MessageFns<GetNextAutofixAlarmStatusResponse> = {
  encode(message: GetNextAutofixAlarmStatusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ts !== undefined) {
      Timestamp.encode(message.ts, writer.uint32(10).fork()).join();
    }
    if (message.completed !== false) {
      writer.uint32(16).bool(message.completed);
    }
    if (message.errorMessage !== "") {
      writer.uint32(26).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNextAutofixAlarmStatusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNextAutofixAlarmStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ts = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.completed = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNextAutofixAlarmStatusResponse {
    return {
      ts: isSet(object.ts) ? Timestamp.fromJSON(object.ts) : undefined,
      completed: isSet(object.completed) ? globalThis.Boolean(object.completed) : false,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
    };
  },

  toJSON(message: GetNextAutofixAlarmStatusResponse): unknown {
    const obj: any = {};
    if (message.ts !== undefined) {
      obj.ts = Timestamp.toJSON(message.ts);
    }
    if (message.completed !== false) {
      obj.completed = message.completed;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNextAutofixAlarmStatusResponse>, I>>(
    base?: I,
  ): GetNextAutofixAlarmStatusResponse {
    return GetNextAutofixAlarmStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNextAutofixAlarmStatusResponse>, I>>(
    object: I,
  ): GetNextAutofixAlarmStatusResponse {
    const message = createBaseGetNextAutofixAlarmStatusResponse();
    message.ts = (object.ts !== undefined && object.ts !== null) ? Timestamp.fromPartial(object.ts) : undefined;
    message.completed = object.completed ?? false;
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
