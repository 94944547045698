import {
  Alert,
  Button,
  capitalize,
  Card,
  IconButton,
  Tab,
  Typography,
} from "@mui/material";
import { BLUE_LOADING_BUTTON, CHIP_COLORS } from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import { CategorizedImages } from "./CategorizedImages";
import {
  categoryCollectionProfileImageToggle,
  imagesToCategoryIds,
} from "portal/utils/categoryCollectionProfile";
import { ExpandedCategoryCollectionRequest } from "protos/portal/category_profile";
import { findWhere } from "portal/utils/arrays";
import { ImageCategorizationOptions } from "./ImageCategorizationOptions";
import { ImageSizeSlider } from "../images/ImageSizeSlider";
import { LabelPointFilter, LabelPointFilters } from "./LabelPointFilters";
import { Link } from "react-router-dom";
import { Loading } from "../Loading";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RadioChipOption, RadioChips } from "../RadioChips";
import { SetCategoryCollectionProfile } from "./SetCategoryCollectionProfile";
import { ThumbnailBaseInfo } from "../images/ThumbnailImage";
import { titleCase } from "portal/utils/strings";
import { updateCategoryCollectionPreferences } from "portal/state/userPreferences";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import { useDispatch } from "react-redux";
import {
  useGetGlobalCategoryCollectionProfileQuery,
  useSetGlobalCategoryCollectionProfileMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { useUserPreferences } from "portal/state/store";
import EditIcon from "@mui/icons-material/ModeEditOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

enum ImageViewTabs {
  ALL = "All",
}

// TODO: support delete
interface Props {
  adminEditing?: boolean;
  parentLink?: string;
  uuid: string;
  serial?: string;
  useGetQuery: typeof useGetGlobalCategoryCollectionProfileQuery;
  useSetMutation: typeof useSetGlobalCategoryCollectionProfileMutation;
  // useDeleteMutation: typeof useDeleteGlobalCategoryCollectionProfileMutation;
}

export const CategoryCollectionProfile: FunctionComponent<Props> = ({
  adminEditing = false,
  uuid,
  serial,
  parentLink,
  useGetQuery,
  useSetMutation,
  // useDeleteMutation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPreferences = useUserPreferences();
  const dimensions = useMemo(
    () => ({
      width: userPreferences.categoryCollection.thumbnailSize,
      height: userPreferences.categoryCollection.thumbnailSize,
    }),
    [userPreferences.categoryCollection.thumbnailSize]
  );

  const [imageTab, setImageTab] = useState(ImageViewTabs.ALL);
  // const navigate = useNavigate();

  // local changes
  const [filters, setFilters] = useState<LabelPointFilter>({
    crops: [],
    robots: [],
    capturedAt: undefined,
  });

  const [
    updatedCategoryCollectionProfile,
    setUpdatedCategoryCollectionProfile,
  ] = useState<ExpandedCategoryCollectionRequest | undefined>();
  const [unsavedCategorizedThumbnails, setUnsavedCategorizedThumbnails] =
    useState<{ [categoryId: string]: ThumbnailBaseInfo[] }>({});

  const resetUnsavedChanges = useCallback(() => {
    setUpdatedCategoryCollectionProfile(undefined);
    setUnsavedCategorizedThumbnails({});
  }, []);

  // remote data
  const {
    data: freezedCategoryCollectionProfile,
    isFetching,
    isError,
    error,
  } = useQueryPopups(useGetQuery({ uuid }));

  // mutations
  const [updateCategoryCollectionProfile, { isLoading: isSaving }] =
    useMutationPopups(useSetMutation(), {
      success: capitalize(
        t("utils.actions.savedLong", {
          subject: titleCase(
            t("models.categoryCollectionProfiles.categoryCollectionProfile_one")
          ),
        })
      ),
    });
  // const [deleteCategoryCollectionProfile] = useMutationPopups(
  //   useDeleteMutation(),
  //   {
  //     success: titleCase(
  //       t("utils.actions.deletedLong", {
  //         subject: titleCase(
  //           t("models.categoryCollectionProfiles.categoryCollectionProfile_one")
  //         ),
  //       })
  //     ),
  //   }
  // );

  // confirmation dialog
  // const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [activeCategoryId, setActiveCategoryId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (
      !activeCategoryId &&
      freezedCategoryCollectionProfile &&
      freezedCategoryCollectionProfile.categories.length > 0 &&
      freezedCategoryCollectionProfile.categories[0]
    ) {
      setActiveCategoryId(freezedCategoryCollectionProfile.categories[0].id);
    }
  }, [activeCategoryId, freezedCategoryCollectionProfile]);

  // unfreezed state
  const unfreezedCategoryCollectionProfile = useMemo<
    ExpandedCategoryCollectionRequest | undefined
  >(
    () =>
      freezedCategoryCollectionProfile
        ? ExpandedCategoryCollectionRequest.fromPartial(
            structuredClone(freezedCategoryCollectionProfile)
          )
        : undefined,
    [freezedCategoryCollectionProfile]
  );

  // visible state
  const categoryCollectionProfile =
    updatedCategoryCollectionProfile ?? unfreezedCategoryCollectionProfile;
  // uncomment when field config supports active category collection profile
  // const isActive =
  //   !isUndefined(categoryCollectionProfile?.profile?.id) &&
  //   categoryCollectionProfile.id ===
  //     summary?.robot?.health?.fieldConfig?.activeCategoryCollectionProfileId;

  const categoryOptions = useMemo(
    () =>
      (categoryCollectionProfile?.categories ?? []).map(
        ({ id, name }, index) => ({
          id,
          name,
          color: CHIP_COLORS[index % CHIP_COLORS.length],
        })
      ),
    [categoryCollectionProfile?.categories]
  );

  const activeCategory = useMemo(
    () => categoryOptions.find(({ id }) => id === activeCategoryId),
    [activeCategoryId, categoryOptions]
  );

  const categorizationMap = useMemo(
    () => imagesToCategoryIds(categoryCollectionProfile),
    [categoryCollectionProfile]
  );

  const createCategoryRadioChipLabel = useCallback(
    ({ id, name }: RadioChipOption): string => {
      if (!categoryCollectionProfile) {
        return "";
      }
      const match = findWhere(categoryCollectionProfile.categories, {
        id,
      });
      if (!match) {
        return name;
      }
      const { chipIds, labelPointIds } = match;
      const count =
        chipIds.length + labelPointIds.length > 0
          ? ` (${chipIds.length + labelPointIds.length})`
          : "";
      return capitalize(`${name}${count}`);
    },
    [categoryCollectionProfile]
  );

  const onImageToggle = useCallback(
    (
      { chipId, labelPointId }: { chipId?: string; labelPointId: string },
      thumbnail: ThumbnailBaseInfo
    ) => {
      if (!categoryCollectionProfile) {
        return;
      }
      const updated = categoryCollectionProfileImageToggle(
        categoryCollectionProfile,
        activeCategoryId,
        { chipId, labelPointId }
      );

      if (!chipId && labelPointId) {
        const shouldAdd = updated.categories.some((c) =>
          c.labelPointIds.includes(labelPointId)
        );
        setUnsavedCategorizedThumbnails((previous) => {
          if (!activeCategoryId) {
            return previous;
          }
          const previousCategoryThumbnails = previous[activeCategoryId] ?? [];
          previous[activeCategoryId] = shouldAdd
            ? [...previousCategoryThumbnails, thumbnail]
            : previousCategoryThumbnails.filter((t) => t.id !== labelPointId);
          return previous;
        });
      }

      setUpdatedCategoryCollectionProfile(updated);
    },
    [activeCategoryId, categoryCollectionProfile]
  );

  const canRead = useAuthorizationRequired(
    adminEditing
      ? [
          buildPermission(
            PermissionAction.read,
            PermissionResource.plant_category_profiles,
            PermissionDomain.templates
          ),
        ]
      : [
          buildPermission(
            PermissionAction.read,
            PermissionResource.plant_category_profiles,
            PermissionDomain.all
          ),
          buildPermission(
            PermissionAction.read,
            PermissionResource.plant_category_profiles,
            PermissionDomain.customer
          ),
        ]
  );
  const canUpdate =
    useAuthorizationRequired(
      adminEditing
        ? [
            buildPermission(
              PermissionAction.update,
              PermissionResource.plant_category_profiles,
              PermissionDomain.templates
            ),
          ]
        : [
            buildPermission(
              PermissionAction.update,
              PermissionResource.plant_category_profiles,
              PermissionDomain.all
            ),
            buildPermission(
              PermissionAction.update,
              PermissionResource.plant_category_profiles,
              PermissionDomain.customer
            ),
          ]
    ) &&
    (!categoryCollectionProfile?.profile?.protected || adminEditing);

  if (!canRead) {
    return;
  }

  if (!categoryCollectionProfile || !categoryCollectionProfile.profile) {
    return <Loading failed={isError} error={error} />;
  }

  const EditButton: FunctionComponent = () => (
    <SetCategoryCollectionProfile
      triggerButton={(onClick) => (
        <IconButton
          onClick={onClick}
          className="text-lighten-400 hover:text-white"
        >
          <EditIcon />
        </IconButton>
      )}
      hotkey={{ sequence: "ec", key: "EDIT_CATEGORY_COLLECTION_PROFILE" }}
      title={t("utils.actions.editLong", {
        subject: t(
          "models.categoryCollectionProfiles.categoryCollectionProfile_one"
        ),
      })}
      okText={t("utils.actions.next")}
      initialValue={{ ...categoryCollectionProfile }}
      onSubmit={(newProfile) => {
        setUpdatedCategoryCollectionProfile(newProfile);
      }}
    />
  );

  return (
    <div className="flex flex-col min-h-full">
      {canUpdate && adminEditing && (
        <Alert severity="warning" className="mb-8">
          {t("components.categoryCollectionProfile.warnings.admin")}
        </Alert>
      )}

      {/* {isActive && (
        <Alert severity="warning" className="mb-8">
          {t(
            "components.categoryCollectionProfile.warnings.production"
          )}
        </Alert>
      )} */}

      {updatedCategoryCollectionProfile &&
        updatedCategoryCollectionProfile !==
          unfreezedCategoryCollectionProfile && (
          <Alert severity="warning" className="mb-8">
            {t("components.categoryCollectionProfile.warnings.unsavedChanges")}
          </Alert>
        )}

      {categoryCollectionProfile.profile.protected && !adminEditing && (
        <Alert severity="warning" className="mb-8">
          {t("components.categoryCollectionProfile.warnings.protected")}
        </Alert>
      )}
      {/* Header */}
      <div className="flex flex-wrap items-start md:items-center justify-between mb-8 w-full gap-4">
        {/* Left Controls */}
        <div className="flex items-center">
          {/* Breadcrumb */}
          {parentLink && (
            <IconButton component={Link} to={parentLink} className="text-white">
              <ParentIcon />
            </IconButton>
          )}
        </div>

        {/* Title */}
        <div className="flex">
          <Typography variant="h4">
            {categoryCollectionProfile.profile.name}
          </Typography>
          {canUpdate && <EditButton />}
        </div>

        {/* Right Controls */}
        <div className="flex items-center gap-4">
          {/* {canUpdate && (
            <Button
              {...RED_BUTTON}
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              {t("utils.actions.delete")}
            </Button>
          )}
          {confirmDelete && (
            <ConfirmationDialog
              title={t("utils.actions.deleteLong", {
                subject: t(
                  "models.categoryCollectionProfiles.categoryCollectionProfile_one"
                ),
              })}
              description={
                isActive ? (
                  <Alert severity="warning" className="mb-8">
                    {t(
                      "components.ConfirmationDialog.delete.descriptionActive",
                      {
                        subject: t(
                          "models.categoryCollectionProfiles.categoryCollectionProfile_one"
                        ),
                      }
                    )}
                  </Alert>
                ) : (
                  t("components.ConfirmationDialog.delete.description", {
                    subject: t(
                      "models.categoryCollectionProfiles.categoryCollectionProfile_one"
                    ),
                  })
                )
              }
              destructive
              yesText={t("utils.actions.delete", {
                subject: categoryCollectionProfile.profile.name,
              })}
              yesDisabled={isActive}
              onClose={() => setConfirmDelete(false)}
              onYes={async () => {
                if (categoryCollectionProfile.profile) {
                  await deleteCategoryCollectionProfile({
                    uuid: categoryCollectionProfile.profile.id,
                  });
                }
                if (parentLink) {
                  navigate(parentLink);
                }
              }}
            />
          )} */}
          {canUpdate && (
            <>
              <Button
                variant="text"
                color="info"
                classes={{ disabled: "text-lighten-200" }}
                onClick={() => {
                  resetUnsavedChanges();
                }}
              >
                {t("utils.actions.cancel")}
              </Button>
              <LoadingButton
                {...BLUE_LOADING_BUTTON}
                loading={isSaving}
                onClick={async () => {
                  if (!categoryCollectionProfile.profile) {
                    return;
                  }
                  setUpdatedCategoryCollectionProfile(
                    categoryCollectionProfile
                  );
                  const result = await updateCategoryCollectionProfile({
                    expandedCategoryCollection: categoryCollectionProfile,
                  });
                  if (!result.error) {
                    resetUnsavedChanges();
                  }
                }}
                disabled={!updatedCategoryCollectionProfile}
                startIcon={<SaveIcon />}
              >
                {t("utils.actions.save")}
              </LoadingButton>
            </>
          )}
        </div>
      </div>

      {/* Main View */}
      <Card className="p-4 mt-4">
        <TabContext value={imageTab}>
          <div className="flex justify-between items-end">
            <TabList
              onChange={(_: React.SyntheticEvent, newValue: ImageViewTabs) => {
                setImageTab(newValue);
              }}
              className="text-white"
            >
              <Tab
                label={t(
                  "components.categoryCollectionProfile.images.allImages"
                )}
                className="text-inherit"
                value={ImageViewTabs.ALL}
              />
            </TabList>
            <ImageSizeSlider
              className="w-36 text-white"
              min={50}
              max={400}
              value={userPreferences.categoryCollection.thumbnailSize}
              onChange={(size) => {
                dispatch(
                  updateCategoryCollectionPreferences({
                    thumbnailSize: size,
                  })
                );
              }}
            />
          </div>
          <TabPanel value={ImageViewTabs.ALL} className="pt-2">
            <div className="flex items-center py-2">
              <RadioChips
                isLoading={isFetching}
                options={categoryOptions}
                activeId={activeCategoryId}
                setActiveId={setActiveCategoryId}
                createLabel={createCategoryRadioChipLabel}
              />
              {canUpdate && (
                <div className="pl-2">
                  <EditButton />
                </div>
              )}
            </div>
            {activeCategory && (
              <div className="pb-4">
                <CategorizedImages
                  category={activeCategory}
                  chipIds={
                    categoryCollectionProfile.categories.find(
                      ({ id }) => id === activeCategoryId
                    )?.chipIds ?? []
                  }
                  thumbnailImages={
                    activeCategoryId
                      ? unsavedCategorizedThumbnails[activeCategoryId] ?? []
                      : []
                  }
                  dimensions={dimensions}
                  onRemove={onImageToggle}
                />
              </div>
            )}
            <div className="pt-2 pb-4">
              <LabelPointFilters
                serial={serial}
                filters={filters}
                onChange={setFilters}
              />
            </div>
            <div className="overflow-y-scroll max-h-[75vh]">
              <ImageCategorizationOptions
                isSaving={isSaving}
                filters={filters}
                dimensions={dimensions}
                categoryOptions={categoryOptions}
                categorizationMap={categorizationMap}
                onImageClick={onImageToggle}
              />
            </div>
          </TabPanel>
        </TabContext>
      </Card>
    </div>
  );
};
