import { ButtonBase, Tooltip } from "@mui/material";
import { IconImage } from "./IconImage";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import React, { FunctionComponent, useCallback, useState } from "react";

export interface ImageProperties {
  id: string;
  alt?: string;
  src?: string;
  isSrcLoading?: boolean;
  isSrcError?: boolean;
  tooltip?: string;
  onClick?: () => void;
  renderOverlay?: () => React.ReactNode;
}
interface ImageProps {
  image?: ImageProperties;
  dimensions: {
    width: number;
    height: number;
  };
  onImageLoaded?: (id: string) => void;
}

enum LoadingState {
  NOT_LOADED,
  SUCCESS,
  ERROR,
}
export const Image: FunctionComponent<ImageProps> = ({
  image,
  dimensions,
  onImageLoaded,
}) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.NOT_LOADED
  );

  const onLoad = useCallback(
    (id: string, success: boolean) => {
      setLoadingState(success ? LoadingState.SUCCESS : LoadingState.ERROR);
      onImageLoaded?.(id);
    },
    [onImageLoaded]
  );

  if (!image) {
    return (
      <IconImage
        icon={(className) => <ImageNotSupportedIcon className={className} />}
      />
    );
  }

  if (loadingState === LoadingState.ERROR || image.isSrcError) {
    return (
      <IconImage
        icon={(className) => <BrokenImageIcon className={className} />}
      />
    );
  }

  const { id, alt, src, tooltip, renderOverlay, onClick } = image;
  return (
    <>
      <Tooltip
        title={
          tooltip ? (
            <div className="whitespace-pre-line">{tooltip}</div>
          ) : undefined
        }
        placement="bottom"
      >
        <ButtonBase onClick={onClick} className="w-full h-full p-0">
          {src && (
            <img
              className={"object-cover w-full h-full relative"}
              src={src}
              alt={alt}
              loading="lazy"
              onLoad={() => {
                onLoad(id, true);
              }}
              onError={() => {
                onLoad(id, false);
              }}
            />
          )}
        </ButtonBase>
      </Tooltip>

      {loadingState === LoadingState.NOT_LOADED || image.isSrcLoading ? (
        <div
          className="absolute top-0 right-0 object-cover bg-gray-600 animate-pulse"
          style={dimensions}
        ></div>
      ) : undefined}
      {renderOverlay?.()}
    </>
  );
};
