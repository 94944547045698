import { Alert } from "@mui/material";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { entries } from "portal/utils/objects";
import { GridActionsColDef, GridColDef } from "@mui/x-data-grid-premium";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetRobotHardwareQuery } from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useMemo } from "react";

const defaultColumn: Partial<GridColDef> = {
  sortable: true,
  disableColumnMenu: true,
  cellClassName: "font-mono",
};

interface ComputerRow {
  hostname: string;
  serial: string;
}

const _RobotComputersList: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();

  const { data: hardware, isSuccess } = useQueryPopups(
    useGetRobotHardwareQuery(serial ? { serial } : skipToken)
  );

  const hostSerials = hardware?.hostSerials;

  const rows = useMemo<ComputerRow[]>(
    () =>
      hostSerials
        ? entries(hostSerials).map(([hostname, serial]) => ({
            hostname,
            serial,
          }))
        : [],
    [hostSerials]
  );

  const columns: Array<GridColDef<ComputerRow> | GridActionsColDef> = [
    {
      ...defaultColumn,
      field: "hostname",
      headerName: t("views.fleet.robots.hardware.fields.hostname"),
    },
    {
      ...defaultColumn,
      field: "serial",
      headerName: t("models.lasers.fields.laserSerial.name"),
    },
  ];

  if (isSuccess && rows.length === 0) {
    return (
      <Alert severity="warning">
        {t("views.fleet.robots.hardware.errors.old")}
      </Alert>
    );
  }

  return (
    <CarbonDataGrid<ComputerRow>
      getRowId={(row) => row.hostname}
      className={classes("flex flex-1")}
      loading={!isSuccess}
      rows={rows}
      columns={columns}
      hideFooter
      disableRowSelectionOnClick
    />
  );
};

export const RobotComputersList =
  withAuthenticationRequired(_RobotComputersList);
