import { AlarmTable } from "portal/components/AlarmTable";
import { NoScroll } from "portal/components/Page";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetRobotQuery } from "portal/state/portalApi";
import { useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

const _RobotAlarmList: FunctionComponent = () => {
  const { serial } = useParams();

  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken)
  );

  return (
    <NoScroll>
      <AlarmTable alarms={summary?.alarmList ?? []} serial={serial} />
    </NoScroll>
  );
};

export const RobotAlarmList = withAuthenticationRequired(_RobotAlarmList);
