import { Auth0User } from "protos/portal/users";
import { CarbonUser } from "portal/utils/auth";
import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

const getIdentityProvider = (t: TFunction, userId: string): string => {
  if (userId.startsWith("google-oauth2|")) {
    return t("views.settings.accountProvider.google");
  } else if (userId.startsWith("apple|")) {
    return t("views.settings.accountProvider.apple");
  } else if (userId.startsWith("auth0|")) {
    return t("views.settings.accountProvider.auth0");
  } else {
    return t("views.settings.accountProvider.unknown");
  }
};

interface AccountDescriptionProps {
  user: CarbonUser | Auth0User;
  className?: string;
}

export const AccountDescription: FunctionComponent<AccountDescriptionProps> = ({
  user,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Typography title={user.userId} className={className}>
      {user.userId ? (
        <Trans
          i18nKey="views.settings.accountProvider.account"
          t={t}
          values={{
            email: user.email,
            identityProvider: getIdentityProvider(t, user.userId),
          }}
          components={[<strong key="email" />, <span key="identityProvider" />]}
        />
      ) : (
        <strong>{user.email}</strong>
      )}
    </Typography>
  );
};
