// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: portal/geo.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { LineString, Point } from "../geo/geo";
import { DB } from "./db";

export const protobufPackage = "carbon.portal.field";

export interface GeoJSON {
  /** JSON comprising a GeoJSON feature object, like `{"type":"Feature",...}`. */
  feature: string;
}

export interface PlanPathRequest {
  /** Robot serial, like "slayer1". */
  serial: string;
  /**
   * Field ID, typically a UUID in text format (hex with dashes). The backend
   * will attempt to plan a path through this field, respecting its boundary.
   */
  fieldId: string;
  /**
   * The robot's current location. The planned path will start from the point
   * on the target line that is closest to this point.
   */
  currentPoint:
    | Point
    | undefined;
  /**
   * The A-B line along which the robot wants to drive. This represents a
   * directed line with no distinguished start point or extent. It should have
   * exactly two points and should have positive length.
   */
  targetLine:
    | LineString
    | undefined;
  /**
   * Radius of a safety buffer around the tractor: the path will only pass
   * through points around which the ball of this radius lies entirely within
   * the field boundary.
   */
  bufferMeters: number;
}

export interface PlanPathResponse {
  /**
   * The path that the robot should drive, as a LineString with two or more
   * points.
   */
  track:
    | LineString
    | undefined;
  /** Distance from the robot's current point to the track, in meters. */
  distanceMeters: number;
}

export interface FieldDefinition {
  db: DB | undefined;
  fieldId: string;
  robotId: number;
  /** Human-readable name of this field. */
  name: string;
  boundary: GeoJSON | undefined;
  plantingHeading: GeoJSON | undefined;
}

function createBaseGeoJSON(): GeoJSON {
  return { feature: "" };
}

export const GeoJSON: MessageFns<GeoJSON> = {
  encode(message: GeoJSON, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.feature !== "") {
      writer.uint32(10).string(message.feature);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GeoJSON {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoJSON();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeoJSON {
    return { feature: isSet(object.feature) ? globalThis.String(object.feature) : "" };
  },

  toJSON(message: GeoJSON): unknown {
    const obj: any = {};
    if (message.feature !== "") {
      obj.feature = message.feature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeoJSON>, I>>(base?: I): GeoJSON {
    return GeoJSON.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeoJSON>, I>>(object: I): GeoJSON {
    const message = createBaseGeoJSON();
    message.feature = object.feature ?? "";
    return message;
  },
};

function createBasePlanPathRequest(): PlanPathRequest {
  return { serial: "", fieldId: "", currentPoint: undefined, targetLine: undefined, bufferMeters: 0 };
}

export const PlanPathRequest: MessageFns<PlanPathRequest> = {
  encode(message: PlanPathRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.serial !== "") {
      writer.uint32(42).string(message.serial);
    }
    if (message.fieldId !== "") {
      writer.uint32(10).string(message.fieldId);
    }
    if (message.currentPoint !== undefined) {
      Point.encode(message.currentPoint, writer.uint32(18).fork()).join();
    }
    if (message.targetLine !== undefined) {
      LineString.encode(message.targetLine, writer.uint32(26).fork()).join();
    }
    if (message.bufferMeters !== 0) {
      writer.uint32(33).double(message.bufferMeters);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlanPathRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlanPathRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currentPoint = Point.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetLine = LineString.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.bufferMeters = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlanPathRequest {
    return {
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      fieldId: isSet(object.fieldId) ? globalThis.String(object.fieldId) : "",
      currentPoint: isSet(object.currentPoint) ? Point.fromJSON(object.currentPoint) : undefined,
      targetLine: isSet(object.targetLine) ? LineString.fromJSON(object.targetLine) : undefined,
      bufferMeters: isSet(object.bufferMeters) ? globalThis.Number(object.bufferMeters) : 0,
    };
  },

  toJSON(message: PlanPathRequest): unknown {
    const obj: any = {};
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.fieldId !== "") {
      obj.fieldId = message.fieldId;
    }
    if (message.currentPoint !== undefined) {
      obj.currentPoint = Point.toJSON(message.currentPoint);
    }
    if (message.targetLine !== undefined) {
      obj.targetLine = LineString.toJSON(message.targetLine);
    }
    if (message.bufferMeters !== 0) {
      obj.bufferMeters = message.bufferMeters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlanPathRequest>, I>>(base?: I): PlanPathRequest {
    return PlanPathRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlanPathRequest>, I>>(object: I): PlanPathRequest {
    const message = createBasePlanPathRequest();
    message.serial = object.serial ?? "";
    message.fieldId = object.fieldId ?? "";
    message.currentPoint = (object.currentPoint !== undefined && object.currentPoint !== null)
      ? Point.fromPartial(object.currentPoint)
      : undefined;
    message.targetLine = (object.targetLine !== undefined && object.targetLine !== null)
      ? LineString.fromPartial(object.targetLine)
      : undefined;
    message.bufferMeters = object.bufferMeters ?? 0;
    return message;
  },
};

function createBasePlanPathResponse(): PlanPathResponse {
  return { track: undefined, distanceMeters: 0 };
}

export const PlanPathResponse: MessageFns<PlanPathResponse> = {
  encode(message: PlanPathResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.track !== undefined) {
      LineString.encode(message.track, writer.uint32(10).fork()).join();
    }
    if (message.distanceMeters !== 0) {
      writer.uint32(17).double(message.distanceMeters);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlanPathResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlanPathResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.track = LineString.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.distanceMeters = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlanPathResponse {
    return {
      track: isSet(object.track) ? LineString.fromJSON(object.track) : undefined,
      distanceMeters: isSet(object.distanceMeters) ? globalThis.Number(object.distanceMeters) : 0,
    };
  },

  toJSON(message: PlanPathResponse): unknown {
    const obj: any = {};
    if (message.track !== undefined) {
      obj.track = LineString.toJSON(message.track);
    }
    if (message.distanceMeters !== 0) {
      obj.distanceMeters = message.distanceMeters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlanPathResponse>, I>>(base?: I): PlanPathResponse {
    return PlanPathResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlanPathResponse>, I>>(object: I): PlanPathResponse {
    const message = createBasePlanPathResponse();
    message.track = (object.track !== undefined && object.track !== null)
      ? LineString.fromPartial(object.track)
      : undefined;
    message.distanceMeters = object.distanceMeters ?? 0;
    return message;
  },
};

function createBaseFieldDefinition(): FieldDefinition {
  return { db: undefined, fieldId: "", robotId: 0, name: "", boundary: undefined, plantingHeading: undefined };
}

export const FieldDefinition: MessageFns<FieldDefinition> = {
  encode(message: FieldDefinition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.db !== undefined) {
      DB.encode(message.db, writer.uint32(10).fork()).join();
    }
    if (message.fieldId !== "") {
      writer.uint32(18).string(message.fieldId);
    }
    if (message.robotId !== 0) {
      writer.uint32(24).int64(message.robotId);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.boundary !== undefined) {
      GeoJSON.encode(message.boundary, writer.uint32(42).fork()).join();
    }
    if (message.plantingHeading !== undefined) {
      GeoJSON.encode(message.plantingHeading, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FieldDefinition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.db = DB.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.robotId = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.boundary = GeoJSON.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.plantingHeading = GeoJSON.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldDefinition {
    return {
      db: isSet(object.db) ? DB.fromJSON(object.db) : undefined,
      fieldId: isSet(object.fieldId) ? globalThis.String(object.fieldId) : "",
      robotId: isSet(object.robotId) ? globalThis.Number(object.robotId) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      boundary: isSet(object.boundary) ? GeoJSON.fromJSON(object.boundary) : undefined,
      plantingHeading: isSet(object.plantingHeading) ? GeoJSON.fromJSON(object.plantingHeading) : undefined,
    };
  },

  toJSON(message: FieldDefinition): unknown {
    const obj: any = {};
    if (message.db !== undefined) {
      obj.db = DB.toJSON(message.db);
    }
    if (message.fieldId !== "") {
      obj.fieldId = message.fieldId;
    }
    if (message.robotId !== 0) {
      obj.robotId = Math.round(message.robotId);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.boundary !== undefined) {
      obj.boundary = GeoJSON.toJSON(message.boundary);
    }
    if (message.plantingHeading !== undefined) {
      obj.plantingHeading = GeoJSON.toJSON(message.plantingHeading);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldDefinition>, I>>(base?: I): FieldDefinition {
    return FieldDefinition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldDefinition>, I>>(object: I): FieldDefinition {
    const message = createBaseFieldDefinition();
    message.db = (object.db !== undefined && object.db !== null) ? DB.fromPartial(object.db) : undefined;
    message.fieldId = object.fieldId ?? "";
    message.robotId = object.robotId ?? 0;
    message.name = object.name ?? "";
    message.boundary = (object.boundary !== undefined && object.boundary !== null)
      ? GeoJSON.fromPartial(object.boundary)
      : undefined;
    message.plantingHeading = (object.plantingHeading !== undefined && object.plantingHeading !== null)
      ? GeoJSON.fromPartial(object.plantingHeading)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
