import { Button, ButtonProps } from "@mui/material";
import { classes, WHITE_BUTTON } from "portal/utils/theme";
import MinusIcon from "@mui/icons-material/RemoveOutlined";
import PlusIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent } from "react";

const TUNING_BUTTON: ButtonProps = {
  ...WHITE_BUTTON,
  variant: "contained",
  classes: {
    root: "min-w-0 w-10 p-0",
  },
};

interface Props {
  interval?: number;
  label: string;
  max?: number;
  min?: number;
  onChange: (newValue: number) => void;
  value: number;
  disabled?: boolean;
}

export const TuningControl: FunctionComponent<Props> = ({
  interval = 1,
  label,
  max = Infinity,
  min = -Infinity,
  onChange,
  value = 5,
  disabled = false,
}) => {
  return (
    <div className="flex flex-col items-center">
      <span className="text-lg my-2 md:mt-0 md:mb-4">{label}</span>
      <div className="flex items-center">
        <Button
          {...TUNING_BUTTON}
          className={classes(TUNING_BUTTON.className, "rounded-r-none")}
          disabled={disabled || value === min}
          onClick={() => onChange(Math.max(min, value - interval))}
        >
          <MinusIcon />
        </Button>
        <div className="h-10 w-10 flex items-center justify-center text-white border-y border-x-0 border-y-white border-solid">
          {value}
        </div>
        <Button
          {...TUNING_BUTTON}
          className={classes(TUNING_BUTTON.className, "rounded-l-none")}
          disabled={disabled || value === max}
          onClick={() => onChange(Math.min(max, value + interval))}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
