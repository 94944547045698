import { Auth0User } from "protos/portal/users";
import { buildPermission, CarbonUser } from "portal/utils/auth";
import { Button } from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { ConfirmationDialog } from "../ConfirmationDialog";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RED_BUTTON } from "portal/utils/theme";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import { useDeleteUserMutation } from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import DeleteAccountIcon from "@mui/icons-material/DeleteForever";
import React, { FunctionComponent, useState } from "react";

interface DeleteAccountProps {
  user: CarbonUser | Auth0User;
  onDelete?: () => void;
}
export const DeleteAccount: FunctionComponent<DeleteAccountProps> = ({
  user,
  onDelete,
}) => {
  const { customer } = useSelf();
  const { t } = useTranslation();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const [deleteUser] = useMutationPopups(useDeleteUserMutation(), {
    success: capitalize(
      t("utils.actions.deletedLong", {
        subject: t("models.users.user_one"),
      })
    ),
  });

  const canReadAll = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.users,
      PermissionDomain.all
    ),
  ]);
  const canReadCustomer =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.read,
        PermissionResource.users,
        PermissionDomain.all
      ),
    ]) && customer?.db?.id === user.appMetadata?.customerId;

  const canRead = canReadAll || canReadCustomer;

  const canUpdateAll = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.users,
      PermissionDomain.all
    ),
  ]);

  const canUpdateCustomer =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.update,
        PermissionResource.users,
        PermissionDomain.customer
      ),
    ]) && customer?.db?.id === user.appMetadata?.customerId;

  const canUpdate = canUpdateAll || canUpdateCustomer;

  if (!canRead) {
    return;
  }

  return (
    <>
      {confirmDelete && (
        <ConfirmationDialog
          title={t("views.settings.cards.account")}
          description={t("views.settings.delete.dialog.description")}
          destructive
          yesText={t("utils.actions.deleteLong", {
            subject: user.email,
          })}
          onClose={() => setConfirmDelete(false)}
          onYes={async () => {
            if (user.userId) {
              await deleteUser({ userId: user.userId });
              onDelete?.();
            }
          }}
        />
      )}
      <Button
        {...RED_BUTTON}
        onClick={() => setConfirmDelete(true)}
        startIcon={<DeleteAccountIcon />}
        disabled={!canUpdate}
      >
        {t("views.settings.delete.deleteAccount")}
      </Button>
    </>
  );
};
