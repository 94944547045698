import {
  ConfigNode,
  ConfigType,
  configTypeFromJSON,
} from "protos/config/api/config_service";
import { ConfigResponse } from "protos/portal/configs";
import { entries, isKeyOf } from "./objects";
import { isEmpty, sortBy } from "./arrays";
import { isUndefined } from "./identity";
import { RobotClass } from "./robots";

export enum TemplateSerial {
  BUD = "BudTemplate",
  REAPER = "ReaperTemplate",
  RTC = "RtcTemplate",
  SIMULATOR = "SimTemplate",
  SLAYER = "Template",
}

export const TEMPLATE_BY_CLASS: Record<RobotClass, TemplateSerial> = {
  buds: TemplateSerial.BUD,
  reapers: TemplateSerial.REAPER,
  rtcs: TemplateSerial.RTC,
  simulators: TemplateSerial.SIMULATOR,
  slayers: TemplateSerial.SLAYER,
  unknown: TemplateSerial.SIMULATOR,
};

export const eachNode = (
  node: ConfigNode | undefined,
  iteratee: (
    node: ConfigNode,
    path: string,
    parent?: ConfigNode
  ) => boolean | void,
  parentPath: string = "",
  parent?: ConfigNode
): void => {
  if (!node) {
    return;
  }
  const path = parentPath ? `${parentPath}/${node.name}` : node.name;
  const keepGoing = iteratee(node, path, parent);
  if (!keepGoing) {
    return;
  }
  for (const child of node.children) {
    eachNode(child, iteratee, path, node);
  }
};

export const isLeafNode = (node: ConfigNode | undefined): boolean =>
  Boolean(node?.def && "type" in node.def && isEmpty(node.children));

export const isListNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.LIST)
  );

export const isIntNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.INT)
  );

export const isUintNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.UINT)
  );

export const isFloatNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.FLOAT)
  );

export const isNumericNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      [
        configTypeFromJSON(ConfigType.INT),
        configTypeFromJSON(ConfigType.UINT),
        configTypeFromJSON(ConfigType.FLOAT),
      ].includes(node.def.type)
  );

export const isBooleanNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.BOOL)
  );

export const isStringNode = (node: ConfigNode | undefined): boolean =>
  Boolean(
    node?.def &&
      "type" in node.def &&
      node.def.type === configTypeFromJSON(ConfigType.STRING)
  );

export const getValue = <T extends number | string | boolean>(
  node: ConfigNode | undefined
): undefined | T => {
  if (!node || !isLeafNode(node)) {
    return;
  }
  if (isIntNode(node)) {
    return node.value?.int64Val as T;
  } else if (isUintNode(node)) {
    return node.value?.uint64Val as T;
  } else if (isFloatNode(node)) {
    return node.value?.floatVal as T;
  } else if (isStringNode(node)) {
    return node.value?.stringVal as T;
  } else if (isBooleanNode(node)) {
    return node.value?.boolVal as T;
  }
};

export const setValue = <T extends number | string | boolean>(
  node: ConfigNode | undefined,
  value: T
): ConfigNode | undefined => {
  if (!node || !isLeafNode(node)) {
    return;
  }
  node.value = node.value ?? {
    timestampMs: Date.now(),
  };
  if (isIntNode(node)) {
    node.value.int64Val = value as number;
  } else if (isUintNode(node)) {
    node.value.uint64Val = value as number;
  } else if (isFloatNode(node)) {
    node.value.floatVal = value as number;
  } else if (isStringNode(node)) {
    node.value.stringVal = value as string;
  } else if (isBooleanNode(node)) {
    node.value.boolVal = value as boolean;
  }
  return node;
};

export const getParentPath = (path: string): string => {
  const segments = path.split("/");
  return segments.slice(0, -1).join("/");
};

export const getParentNodeFromPath = (
  tree: ConfigNode | undefined,
  path: string
): ConfigNode | undefined => getNodeFromPath(tree, getParentPath(path));

export const getNodeFromPath = (
  tree: ConfigNode | undefined,
  path: string
): ConfigNode | undefined => {
  if (!tree) {
    return;
  }
  const segments = path.split("/");
  let current: ConfigNode | undefined = tree;
  for (const segment of segments) {
    if (!current) {
      continue;
    }
    current = current.children.find((child) => child.name === segment);
  }
  return current;
};

export const sortByName = (node: ConfigNode): ConfigNode => {
  const { children, ...rest } = node;
  return {
    ...rest,
    children: sortBy(children, "name").map((child) => sortByName(child)),
  };
};

interface ConfigChanges {
  changedPaths: string[];
  addedPaths: string[];
  removedPaths: string[];
  allChangedPaths: string[];
}

export const getConfigChanges = (
  config?: ConfigNode,
  template?: ConfigNode
): ConfigChanges => {
  const result: ConfigChanges = {
    changedPaths: [],
    addedPaths: [],
    removedPaths: [],
    allChangedPaths: [],
  };
  if (!config || !template) {
    return result;
  }
  for (const root of config.children) {
    eachNode(root, (node, path, parent) => {
      const templateNode = getNodeFromPath(template, path);
      const hasTemplate = !isUndefined(templateNode);
      const isParentList = parent && isListNode(parent);
      const isParentRecommended =
        parent && Boolean(parent.def?.defaultRecommended);
      const isRecommended = Boolean(node.def?.defaultRecommended);
      const hasChangedValue = getValue(node) !== getValue(templateNode);

      if (!hasTemplate && isParentList && isParentRecommended) {
        result.addedPaths.push(path);
      } else if (hasTemplate && isRecommended && hasChangedValue) {
        result.changedPaths.push(path);
      }
      return true;
    });
  }
  for (const root of template.children) {
    eachNode(root, (node, path) => {
      const hasNode = !isUndefined(getNodeFromPath(config, path));
      const isList = isListNode(node);
      const isRecommended = Boolean(node.def?.defaultRecommended);
      if (isList && !isRecommended) {
        return false;
      }
      if (!hasNode) {
        result.removedPaths.push(path);
      }
      return true;
    });
  }
  result.allChangedPaths = [
    ...result.addedPaths,
    ...result.removedPaths,
    ...result.changedPaths,
  ];
  return result;
};

export type SearchIndex = Record<string, string>;

export const toSearchString = (input?: string): string =>
  input
    ? input
        .toLowerCase()
        .replaceAll(/[^\d/a-z]/g, "")
        .replaceAll("/", "-")
    : "";

export const indexConfigTree = (
  node?: ConfigNode,
  parentPath: string = ""
): SearchIndex => {
  if (!node) {
    return {};
  }
  const path = parentPath ? `${parentPath}/${node.name}` : node.name;
  const searchable = `${toSearchString(parentPath)}-${toSearchString(
    node.name
  )}`;
  let self = { [searchable]: path };
  for (const child of node.children) {
    self = { ...self, ...indexConfigTree(child, path) };
  }
  return self;
};

export const searchConfigTree = (
  index: SearchIndex,
  query: string
): string[] => {
  const search = new RegExp(`^[^-]*-.*${toSearchString(query)}.*$`);
  const resultPaths: string[] = [];
  for (const [searchable, path] of entries(index)) {
    if (search.test(searchable)) {
      resultPaths.push(path);
    }
  }
  return resultPaths;
};

export const shallowCopyConfigTree = (
  node: ConfigNode | undefined
): ConfigNode | undefined => {
  // we don't exist somehow
  if (!node) {
    return;
  }
  return {
    ...node,
    children: node.children
      .map((child) => shallowCopyConfigTree(child))
      .filter((child) => child !== undefined),
  };
};

export const filterConfigTree = (
  node?: ConfigNode,
  paths: string[] = [],
  parentPath: string = "",
  options: {
    ignoreRoot?: boolean;
    showChildren?: boolean;
    isRoot?: boolean;
  } = {}
): ConfigNode | undefined => {
  const { ignoreRoot = false, showChildren = true, isRoot = true } = options;
  // we don't exist somehow
  if (!node) {
    return;
  }
  const copy = { ...node };
  // full path to this node
  let path = parentPath ? `${parentPath}/${node.name}` : node.name;
  if (ignoreRoot && isRoot) {
    path = "";
  }
  if (node.children.length > 0) {
    // branch node
    const isPartial = Boolean(
      paths.some((contender) => contender.startsWith(path))
    );
    // none of our children are matches, bail here
    if (!isPartial) {
      return;
    }
    // recursively filter children
    copy.children = node.children
      .map((child) =>
        filterConfigTree(child, paths, path, { ...options, isRoot: false })
      )
      .filter((child) => child !== undefined);
    return copy;
  } else {
    // check if us or any of our ancestors are matches
    for (const contender of paths) {
      if (contender === path || (showChildren && path.startsWith(contender))) {
        return copy;
      }
    }
  }
};

interface SpecialRootNode {
  node: ConfigResponse;
}

export const isSpecialRootNode = (node: unknown): node is SpecialRootNode =>
  isKeyOf(node as Record<any, unknown>, "node");

export const getConfigNode = (config?: ConfigNode): ConfigNode | undefined => {
  if (!config) {
    return;
  }
  return isSpecialRootNode(config)
    ? (config.node as unknown as ConfigNode)
    : config;
};
