import { Auth0User } from "protos/portal/users";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import {
  PermissionAction,
  permissionActionToJSON,
  PermissionDomain,
  permissionDomainToJSON,
  PermissionResource,
  permissionResourceToJSON,
} from "protos/portal/auth";

// Make everything optional in the Auth0User type and within the metadata fields
export interface CarbonUser
  extends Partial<Omit<Auth0User, "appMetadata" | "userMetadata">> {
  appMetadata?: Partial<Auth0User["appMetadata"]>;
  userMetadata?: Partial<Auth0User["userMetadata"]>;
}

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

export const auth = {
  getAccessTokenSilently: () => getAccessTokenSilently(),
  setAccessTokenSilently: (
    function_: (
      options?: GetTokenSilentlyOptions | undefined
    ) => Promise<string>
  ) => (getAccessTokenSilently = function_),
};

export const isActivated = (
  user?: Pick<CarbonUser, "email" | "userId" | "appMetadata">
): boolean => (isInternal(user) || user?.appMetadata?.isActivated) ?? false;

export const isExperimental = (user?: CarbonUser): boolean =>
  user?.userMetadata?.experimental ?? false;

export const getCustomerId = (user?: CarbonUser): number | undefined =>
  user?.appMetadata?.customerId;

export const isInternal = (
  user?: Pick<CarbonUser, "email" | "userId">
): boolean =>
  Boolean(
    user?.email?.endsWith("@carbonrobotics.com") &&
      user.userId?.startsWith("google-oauth2|")
  );

export const buildPermission = (
  action: PermissionAction,
  resource: PermissionResource,
  domain?: PermissionDomain | undefined
): string => {
  const permission: string[] = [
    permissionActionToJSON(action),
    permissionResourceToJSON(resource),
  ];
  if (domain) {
    permission.push(permissionDomainToJSON(domain));
  }
  return permission.join(":");
};
