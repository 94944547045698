import { capitalize } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { Discriminator } from "portal/components/discriminator/Discriminator";
import { DiscriminatorConfig } from "protos/almanac/almanac";
import { getRobotPath, RobotSubpath } from "portal/utils/routing";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NewDiscriminator } from "portal/components/discriminator/NewDiscriminator";
import { NoScroll } from "portal/components/Page";
import { SearchField } from "portal/components/header/SearchField";
import { skipToken } from "@reduxjs/toolkit/query";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useGetRobotQuery,
  useListDiscriminatorsForRobotQuery,
} from "portal/state/portalApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";

const CELL_PADDING_CLASS = "py-4";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

const _RobotDiscriminatorList: FunctionComponent = () => {
  const { serial } = useParams();
  const { t } = useTranslation();

  const { data: summary, isSuccess: isRobotSuccess } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken),
    { errorVariant: "warning" }
  );

  const { data: discriminators, isSuccess: hasLoaded } = useQueryPopups(
    useListDiscriminatorsForRobotQuery(serial ? { serial } : skipToken)
  );

  const navigate = useNavigate();
  const discriminatorListUrl = getRobotPath(serial, RobotSubpath.DISCRIMINATOR);
  const { pathname } = useLocation();
  let uuid: string = "";
  if (!pathname.endsWith(discriminatorListUrl)) {
    uuid = pathname.replace(`${discriminatorListUrl}/`, "");
  }

  const { searchText, setSearchText, results } =
    useFuzzySearch<DiscriminatorConfig>({
      items: discriminators ?? [],
    });

  const columns: GridColDef<DiscriminatorConfig>[] = [
    {
      ...defaultColumn,
      field: "name",
      headerName: t("models.discriminators.fields.name"),
    },
    {
      ...defaultColumn,
      field: "isActive",
      headerName: capitalize(t("utils.descriptors.active")),
      cellClassName: ({ row: discriminator }) => {
        const isActive =
          discriminator.id ===
          summary?.robot?.health?.fieldConfig?.activeDiscriminatorId;
        return classes(
          CELL_PADDING_CLASS,
          isActive ? "font-bold bg-green-500 text-white" : "text-gray-500"
        );
      },
      valueGetter: (value, discriminator) => {
        if (!isRobotSuccess) {
          return t("components.Loading.placeholder");
        }
        const isActive =
          discriminator.id ===
          summary.robot?.health?.fieldConfig?.activeDiscriminatorId;
        return isActive
          ? t("utils.descriptors.active")
          : t("utils.descriptors.inactive");
      },
    },
  ];

  if (serial && uuid) {
    return (
      <Discriminator
        parentLink={discriminatorListUrl}
        serial={serial}
        uuid={uuid}
      />
    );
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<DiscriminatorConfig>
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={t("utils.actions.searchLong", {
                  subject: capitalize(
                    t("models.discriminators.discriminator", {
                      count: discriminators?.length ?? 0,
                    })
                  ),
                })}
              />
              {serial && <NewDiscriminator serial={serial} hotkey="n" />}
            </>
          }
          className="flex flex-1"
          rows={results}
          getRowId={(discriminator) => discriminator.id}
          columns={columns}
          getRowHeight={() => "auto"}
          hideFooter
          onRowClick={({ row: discriminator }) =>
            navigate(`${discriminatorListUrl}/${discriminator.id}`)
          }
          disableRowSelectionOnClick
          getRowClassName={() => "cursor-pointer"}
          loading={!hasLoaded}
        />
      </div>
    </NoScroll>
  );
};

export const RobotDiscriminatorList = withAuthenticationRequired(
  _RobotDiscriminatorList
);
