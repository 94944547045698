import { AlmanacConfig } from "protos/almanac/almanac";
import { BLUE_LOADING_BUTTON, TEXT_FIELD_DARK } from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { capitalize, titleCase } from "portal/utils/strings";
import { Field, Form, Formik } from "formik";
import { getDefaultAlmanac } from "portal/utils/almanac";
import { GlobalHotKeys } from "react-hotkeys";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { TextField } from "formik-mui";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import {
  useSetAlmanacMutation,
  useSetGlobalAlmanacMutation,
} from "portal/state/portalApi";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  hotkey?: string;
  serial?: string;
  basePath: string;
  useSetMutation:
    | typeof useSetAlmanacMutation
    | typeof useSetGlobalAlmanacMutation;
}
const _NewAlmanac: FunctionComponent<Props> = ({
  hotkey,
  serial,
  basePath,
  useSetMutation,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState<boolean>(false);

  const [setAlmanac] = useMutationPopups(useSetMutation(), {
    success: capitalize(
      t("utils.actions.createdLong", {
        subject: t("models.almanacs.almanac_one"),
      })
    ),
  });

  return (
    <>
      {hotkey && (
        <GlobalHotKeys
          keyMap={{
            NEW_ALMANAC: {
              name: titleCase(
                t("utils.actions.newLong", {
                  subject: t("models.almanacs.almanac_one"),
                })
              ),
              action: "keyup",
              sequence: hotkey,
            },
          }}
          handlers={{
            NEW_ALMANAC: () => setOpen(true),
          }}
        />
      )}
      <Button
        className="text-white"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        <span className="hidden sm:inline">
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.almanacs.almanac_one"),
            })
          )}
        </span>
        <span className="sm:hidden">{t("utils.actions.new")}</span>
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {titleCase(
            t("utils.actions.newLong", {
              subject: t("models.almanacs.almanac_one"),
            })
          )}
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
          }}
          validationSchema={object({
            name: string().required(),
          })}
          onSubmit={async ({ name }) => {
            if (!serial) {
              return;
            }
            const id = uuid();
            await setAlmanac({
              serial,
              almanac: AlmanacConfig.fromPartial({
                ...getDefaultAlmanac(t),
                id,
                name,
              }),
            });
            navigate(`${basePath}/${id}`);
          }}
        >
          {({ submitForm, isSubmitting, dirty }) => (
            <Form>
              <DialogContent className="flex flex-col gap-4 pt-2">
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  name="name"
                  label={t("models.almanacs.fields.name")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={<AddIcon />}
                >
                  {t("utils.actions.create")}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export const NewAlmanac = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.update,
      PermissionResource.almanacs,
      PermissionDomain.self
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.almanacs,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.almanacs,
      PermissionDomain.all
    ),
  ],
  _NewAlmanac
);
