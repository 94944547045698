import { capitalize } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { CustomerResponse } from "protos/portal/customers";
import { CustomerSelector } from "portal/components/customers/CustomerSelector";
import { Farm } from "protos/portal/farm";
import { getFarmPath } from "portal/utils/routing";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Header } from "portal/components/header/Header";
import { NoScroll, Page } from "portal/components/Page";
import {
  useListCustomersQuery,
  useListFarmsQuery,
} from "portal/state/portalApi";
import { useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useMemo, useState } from "react";

const getRowId = (farm: Farm): string => {
  return farm.id?.id ?? "";
};

export const FarmList: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: farms, isLoading } = useQueryPopups(useListFarmsQuery());
  const { data: allCustomers } = useQueryPopups(useListCustomersQuery());

  const [customerId, setCustomerId] = useState<number | undefined>(undefined);
  const customersById = useMemo(() => {
    const result = new Map<number, CustomerResponse>();
    for (const customer of allCustomers ?? []) {
      const id = customer.db?.id;
      if (id !== undefined) {
        result.set(id, customer);
      }
    }
    return result;
  }, [allCustomers]);

  const filteredFarms = useMemo(() => {
    if (!farms) {
      return [];
    }
    if (customerId === undefined) {
      return farms;
    }
    return farms.filter((farm) => farm.customerId === customerId);
  }, [farms, customerId]);

  const columns = useMemo<GridColDef<Farm>[]>(() => {
    const defaultColumn: Partial<GridColDef> = {
      disableColumnMenu: true,
      flex: 1,
    };
    return [
      {
        ...defaultColumn,
        field: "name",
        headerName: capitalize(t("utils.descriptors.name")),
      },
      {
        ...defaultColumn,
        field: "customerId",
        headerName: capitalize(t("models.customers.customer_one")),
        valueFormatter: (customerId: number) =>
          customersById.get(customerId)?.name ?? t("utils.descriptors.unknown"),
      },
    ];
  }, [t, customersById]);

  return (
    <>
      <Header title={capitalize(t("models.farms.farm_other"))} />
      <Page>
        <NoScroll>
          <CarbonDataGrid<Farm>
            columns={columns}
            rows={filteredFarms}
            loading={isLoading}
            getRowId={getRowId}
            getRowClassName={() => "cursor-pointer"}
            onRowClick={({ row: farm }) => navigate(getFarmPath(farm.id?.id))}
            disableRowSelectionOnClick
            hideFooter
            header={
              <CustomerSelector
                small
                value={customerId}
                onChange={setCustomerId}
                emptyText={t("utils.table.showAll", {
                  objects: t("models.customers.customer_other"),
                })}
                className="md:max-w-80"
              />
            }
          />
        </NoScroll>
      </Page>
    </>
  );
};
