import {
  BLUE_BUTTON,
  SMALL_TEXT_FIELD_DARK,
  WHITE_BUTTON,
} from "portal/utils/theme";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { Formula } from "protos/almanac/almanac";
import { numberOrEmptyString } from "portal/utils/forms";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import PasteIcon from "@mui/icons-material/ContentPasteOutlined";
import React, { Fragment, FunctionComponent } from "react";

const getFormula = (
  t: TFunction
): Array<{
  label: string;
  description: string;
  key: keyof Formula;
  min?: number;
  max?: number;
  step?: number;
}> => [
  {
    label: t("components.almanac.formulas.multiplier.label"),
    key: "multiplier",
    description: t("components.almanac.formulas.multiplier.description"),
    step: 0.1,
  },
  {
    label: t("components.almanac.formulas.offset.label"),
    key: "offset",
    description: t("components.almanac.formulas.offset.description"),
    step: 100,
  },
  {
    label: t("components.almanac.formulas.exponent.label"),
    key: "exponent",
    description: t("components.almanac.formulas.exponent.description"),
    min: 1,
    step: 0.1,
  },
  {
    label: t("components.almanac.formulas.maxTime.label"),
    key: "maxTime",
    description: t("components.almanac.formulas.maxTime.description"),
    min: 0,
    step: 100,
  },
  {
    label: t("components.almanac.formulas.fineTuneMultiplier.label"),
    key: "fineTuneMultiplier",
    description: t(
      "components.almanac.formulas.fineTuneMultiplier.description"
    ),
    min: 1,
    max: 10,
    step: 1,
  },
  {
    label: t("components.almanac.formulas.fineTuneMultiplierVal.label"),
    key: "fineTuneMultiplierVal",
    description: t(
      "components.almanac.formulas.fineTuneMultiplierVal.description"
    ),
    max: 1,
    step: 0.1,
  },
];

interface Props {
  copiedFormula: Formula | undefined;
  formula: Formula;
  onChange: (newFormula: Formula) => void;
  setCopiedFormula: (formula: Formula | undefined) => void;
  title: string;
  readOnly?: boolean;
}

export const FormulaCard: FunctionComponent<Props> = ({
  copiedFormula,
  formula,
  onChange,
  setCopiedFormula,
  title,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  return (
    <Card className="p-8 my-4">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        <Typography variant="h6">{title}</Typography>
        <div className="flex items-center gap-2">
          <Button
            {...WHITE_BUTTON}
            startIcon={<CopyIcon />}
            onClick={() => setCopiedFormula(formula)}
          >
            {t("components.almanac.formulas.copyFormula")}
          </Button>
          {copiedFormula && (
            <Button
              {...BLUE_BUTTON}
              startIcon={<PasteIcon />}
              onClick={() => {
                onChange({
                  ...formula,
                  ...(readOnly
                    ? {
                        fineTuneMultiplier: copiedFormula.fineTuneMultiplier,
                      }
                    : copiedFormula),
                });
              }}
            >
              {t("components.almanac.formulas.pasteFormula")}
            </Button>
          )}
        </div>
      </div>

      {/* Reference */}
      <div className="flex flex-col items-center text-sm my-4">
        <strong>
          {t("components.almanac.formulas.laserTime")} ={" "}
          {/* don't try to localize formula */}
          {/* eslint-disable i18next/no-literal-string */}
          <span className="font-mono">
            F × (A × r<sup>e</sup> + b)
          </span>
        </strong>
        <span className="font-mono">F = 1 + ((FI - 5) × FM)</span>
        {/* eslint-enable i18next/no-literal-string */}
      </div>

      {/* Form */}
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        className="w-full items-center justify-center"
      >
        {getFormula(t).map(
          ({ label, description, key, min = 0, max = Infinity, step = 1 }) => (
            <Fragment key={key}>
              <Grid item xs={6} md={1.5} className="font-bold">
                {label}
              </Grid>
              <Grid item xs={6} md={1.5}>
                <TextField
                  {...SMALL_TEXT_FIELD_DARK}
                  disabled={readOnly && key !== "fineTuneMultiplier"}
                  type="number"
                  inputProps={{ min, max, step }}
                  value={formula[key]}
                  className="w-full text-center"
                  onChange={(event) =>
                    onChange({
                      ...formula,
                      [key]: numberOrEmptyString(event.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={8.5} className="text-sm">
                {description}
              </Grid>
            </Fragment>
          )
        )}
      </Grid>
    </Card>
  );
};
