import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  useSortable as useSortableKit,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import ErrorBoundary, {
  withErrorBoundary,
} from "portal/components/ErrorBoundary";
import React, { FunctionComponent, PropsWithChildren } from "react";

interface SortableContainerProps<T> extends PropsWithChildren {
  id: string;
  items: T[];
  render: (item: T) => JSX.Element;
}

export const SortableContainer = <T extends { id: string }>({
  id,
  items = [],
  render,
}: SortableContainerProps<T>): JSX.Element => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <ErrorBoundary>
      <SortableContext
        id={id}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <ul className="list-none p-0 m-0" ref={setNodeRef}>
          {items.map((item) => (
            <SortableItem key={item.id} id={item.id}>
              {render(item)}
            </SortableItem>
          ))}
        </ul>
      </SortableContext>
    </ErrorBoundary>
  );
};

interface SortableItemProps extends PropsWithChildren {
  id: string;
}

const _SortableItem: FunctionComponent<SortableItemProps> = ({
  id,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortableKit({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} id={id} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export const SortableItem = withErrorBoundary({ small: true }, _SortableItem);
