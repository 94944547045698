import { array, object, string } from "yup";
import { Autocomplete, TextField } from "formik-mui";
import { BLUE_LOADING_BUTTON, TEXT_FIELD_DARK } from "portal/utils/theme";
import { buildPermission, getCustomerId } from "portal/utils/auth";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField as TextFieldMui,
  TextFieldProps,
} from "@mui/material";
import { Category } from "protos/category_profile/category_profile";
import {
  ExpandedCategoryCollectionRequest,
  ExpandedCategoryCollectionResponse,
  UnsavedCategory,
} from "protos/portal/category_profile";
import { Field, Form, Formik } from "formik";
import { getAutocompleteText } from "portal/utils/forms";
import { GlobalHotKeys } from "react-hotkeys";
import { LoadingButton } from "@mui/lab";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { titleCase } from "portal/utils/strings";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { withAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import DeleteIcon from "@mui/icons-material/CloseOutlined";
import React, { FunctionComponent, useState } from "react";

// this count enforces M1 requirement of strict categories
const REQUIRED_CATEGORY_COUNT = 5;

// used to differentiate input values of plain text string vs a fully fledged Category object
const isCategoryNameOnly = (value: Category | string): value is string =>
  typeof value === "string";

// TODO: enable category editing when the robot allows for category flexibility
const CATEGORIES_EDITABLE = false;
interface Props {
  triggerButton: (onClick: () => void) => React.ReactNode;
  hotkey?: {
    sequence: string;
    key: string;
  };
  title: string;
  okText: string;
  okIcon?: JSX.Element;
  initialValue: ExpandedCategoryCollectionResponse;
  onSubmit: (value: ExpandedCategoryCollectionRequest) => void;
}
const _SetCategoryCollectionProfile: FunctionComponent<Props> = ({
  triggerButton,
  hotkey,
  title,
  okText,
  okIcon,
  initialValue,
  onSubmit,
}) => {
  const { user } = useSelf();
  const customerId = getCustomerId(user);
  const customerIdString = customerId ? String(customerId) : undefined;

  const dialogTitle = titleCase(title);
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      {hotkey && (
        <GlobalHotKeys
          keyMap={{
            [hotkey.key]: {
              name: dialogTitle,
              action: "keyup",
              sequence: hotkey.sequence,
            },
          }}
          handlers={{
            [hotkey.key]: () => setOpen(true),
          }}
        />
      )}
      {triggerButton(() => {
        setOpen(true);
      })}
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            name: initialValue.profile?.name ?? "",
            categories: initialValue.categories,
          }}
          validationSchema={object({
            name: string().required(),
            categories: array()
              .of(
                object().shape({
                  name: string().required(),
                })
              )
              .min(REQUIRED_CATEGORY_COUNT)
              .max(REQUIRED_CATEGORY_COUNT)
              .required(),
          })}
          onSubmit={({ name, categories }) => {
            const newProfileData = {
              name,
              categoryIds: categories.map(({ id }) => id),
            };
            const newProfile = initialValue.profile
              ? {
                  ...initialValue.profile,
                  ...newProfileData,
                }
              : {
                  ...newProfileData,
                  id: uuid(),
                  protected: false,
                  customerId: customerIdString,
                };
            return onSubmit({
              profile: newProfile,
              categories: categories.map((c) => UnsavedCategory.fromPartial(c)),
            });
          }}
        >
          {({
            submitForm,
            isSubmitting,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            touched,
            errors,
          }) => (
            <Form>
              <DialogContent className="flex flex-col gap-4 pt-2">
                <Field
                  {...TEXT_FIELD_DARK}
                  component={TextField}
                  name="name"
                  label={t("models.categoryCollectionProfiles.fields.name")}
                />
                <Field
                  disabled={!CATEGORIES_EDITABLE}
                  name="categories"
                  component={Autocomplete}
                  {...getAutocompleteText(t)}
                  multiple
                  freeSolo
                  options={[]}
                  value={values.categories}
                  getOptionLabel={(option: Category) => option.name}
                  isOptionEqualToValue={(option: Category, value: Category) =>
                    option.id === value.id
                  }
                  renderTags={(tagValue: Category[], getTagProps: any) => {
                    return tagValue.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.id}
                        label={option.name}
                        size="small"
                        onDelete={undefined}
                      />
                    ));
                  }}
                  onChange={(
                    _: React.SyntheticEvent<Element, Event>,
                    value: Array<Category | string>
                  ) => {
                    const newCategories = value.map((category) =>
                      isCategoryNameOnly(category)
                        ? Category.fromPartial({
                            id: uuid(),
                            name: category,
                            customerId: customerIdString,
                            protected: initialValue.profile?.protected,
                          })
                        : category
                    );
                    setFieldValue("categories", newCategories);
                    setFieldTouched("categories", true);
                  }}
                  renderInput={(parameters: TextFieldProps) => (
                    <TextFieldMui
                      {...TEXT_FIELD_DARK}
                      {...parameters}
                      error={touched.categories && Boolean(errors.categories)}
                      helperText={
                        errors.categories
                          ? JSON.stringify(errors.categories)
                          : undefined
                      }
                      label={t(
                        "models.categoryCollectionProfiles.fields.categories"
                      )}
                    />
                  )}
                />
                <div className="flex flex-col">
                  {values.categories.map((category) => (
                    <div key={category.name} className="flex items-center">
                      <p className="text-xs m-0">
                        {category.name}
                        {category.chipIds.length > 0
                          ? ` (${category.chipIds.length} ${t(
                              "models.images.image",
                              { count: category.chipIds.length }
                            )})`
                          : ""}
                      </p>
                      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                      {CATEGORIES_EDITABLE && (
                        <IconButton
                          className="text-white"
                          size="small"
                          onClick={() => {
                            setFieldValue(
                              "categories",
                              values.categories.filter(
                                (value) => value.name !== category.name
                              )
                            );
                          }}
                        >
                          <DeleteIcon className="text-xs" />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  className="text-white"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                >
                  {t("utils.actions.cancel")}
                </Button>
                <LoadingButton
                  {...BLUE_LOADING_BUTTON}
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={submitForm}
                  startIcon={okIcon}
                >
                  {okText}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export const SetCategoryCollectionProfile = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.update,
      PermissionResource.plant_category_profiles,
      PermissionDomain.self
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.plant_category_profiles,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.plant_category_profiles,
      PermissionDomain.all
    ),
  ],
  _SetCategoryCollectionProfile
);
