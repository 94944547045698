// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: weed_tracking/weed_tracking.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "weed_tracking";

export enum InvalidScoreReason {
  NONE = 0,
  ANOTHER_LASER_SHOOTING = 1,
  SCANNER_POSITION_INVALID = 2,
  WEED_ALREADY_KILLED = 3,
  WEED_SHOT_BY_ANOTHER_LASER = 4,
  WEED_OUT_OF_BAND = 5,
  NOT_A_WEED = 6,
  SCORE_NEGATIVE = 7,
  INTERSECTED_WITH_NONSHOOTABLE = 8,
  EXTERMINATION_FAILURES_EXCEEDED_MAX = 9,
  DETECTIONS_OVER_OPPORTUNITIES_BELOW_MIN = 10,
  UNRECOGNIZED = -1,
}

export function invalidScoreReasonFromJSON(object: any): InvalidScoreReason {
  switch (object) {
    case 0:
    case "NONE":
      return InvalidScoreReason.NONE;
    case 1:
    case "ANOTHER_LASER_SHOOTING":
      return InvalidScoreReason.ANOTHER_LASER_SHOOTING;
    case 2:
    case "SCANNER_POSITION_INVALID":
      return InvalidScoreReason.SCANNER_POSITION_INVALID;
    case 3:
    case "WEED_ALREADY_KILLED":
      return InvalidScoreReason.WEED_ALREADY_KILLED;
    case 4:
    case "WEED_SHOT_BY_ANOTHER_LASER":
      return InvalidScoreReason.WEED_SHOT_BY_ANOTHER_LASER;
    case 5:
    case "WEED_OUT_OF_BAND":
      return InvalidScoreReason.WEED_OUT_OF_BAND;
    case 6:
    case "NOT_A_WEED":
      return InvalidScoreReason.NOT_A_WEED;
    case 7:
    case "SCORE_NEGATIVE":
      return InvalidScoreReason.SCORE_NEGATIVE;
    case 8:
    case "INTERSECTED_WITH_NONSHOOTABLE":
      return InvalidScoreReason.INTERSECTED_WITH_NONSHOOTABLE;
    case 9:
    case "EXTERMINATION_FAILURES_EXCEEDED_MAX":
      return InvalidScoreReason.EXTERMINATION_FAILURES_EXCEEDED_MAX;
    case 10:
    case "DETECTIONS_OVER_OPPORTUNITIES_BELOW_MIN":
      return InvalidScoreReason.DETECTIONS_OVER_OPPORTUNITIES_BELOW_MIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InvalidScoreReason.UNRECOGNIZED;
  }
}

export function invalidScoreReasonToJSON(object: InvalidScoreReason): string {
  switch (object) {
    case InvalidScoreReason.NONE:
      return "NONE";
    case InvalidScoreReason.ANOTHER_LASER_SHOOTING:
      return "ANOTHER_LASER_SHOOTING";
    case InvalidScoreReason.SCANNER_POSITION_INVALID:
      return "SCANNER_POSITION_INVALID";
    case InvalidScoreReason.WEED_ALREADY_KILLED:
      return "WEED_ALREADY_KILLED";
    case InvalidScoreReason.WEED_SHOT_BY_ANOTHER_LASER:
      return "WEED_SHOT_BY_ANOTHER_LASER";
    case InvalidScoreReason.WEED_OUT_OF_BAND:
      return "WEED_OUT_OF_BAND";
    case InvalidScoreReason.NOT_A_WEED:
      return "NOT_A_WEED";
    case InvalidScoreReason.SCORE_NEGATIVE:
      return "SCORE_NEGATIVE";
    case InvalidScoreReason.INTERSECTED_WITH_NONSHOOTABLE:
      return "INTERSECTED_WITH_NONSHOOTABLE";
    case InvalidScoreReason.EXTERMINATION_FAILURES_EXCEEDED_MAX:
      return "EXTERMINATION_FAILURES_EXCEEDED_MAX";
    case InvalidScoreReason.DETECTIONS_OVER_OPPORTUNITIES_BELOW_MIN:
      return "DETECTIONS_OVER_OPPORTUNITIES_BELOW_MIN";
    case InvalidScoreReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum KillStatus {
  STATUS_NOT_SHOT = 0,
  STATUS_BEING_SHOT = 1,
  STATUS_SHOT = 2,
  STATUS_PARTIALLY_SHOT = 3,
  STATUS_P2P_NOT_FOUND = 4,
  STATUS_ERROR = 5,
  STATUS_P2P_MISSING_CONTEXT = 6,
  UNRECOGNIZED = -1,
}

export function killStatusFromJSON(object: any): KillStatus {
  switch (object) {
    case 0:
    case "STATUS_NOT_SHOT":
      return KillStatus.STATUS_NOT_SHOT;
    case 1:
    case "STATUS_BEING_SHOT":
      return KillStatus.STATUS_BEING_SHOT;
    case 2:
    case "STATUS_SHOT":
      return KillStatus.STATUS_SHOT;
    case 3:
    case "STATUS_PARTIALLY_SHOT":
      return KillStatus.STATUS_PARTIALLY_SHOT;
    case 4:
    case "STATUS_P2P_NOT_FOUND":
      return KillStatus.STATUS_P2P_NOT_FOUND;
    case 5:
    case "STATUS_ERROR":
      return KillStatus.STATUS_ERROR;
    case 6:
    case "STATUS_P2P_MISSING_CONTEXT":
      return KillStatus.STATUS_P2P_MISSING_CONTEXT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KillStatus.UNRECOGNIZED;
  }
}

export function killStatusToJSON(object: KillStatus): string {
  switch (object) {
    case KillStatus.STATUS_NOT_SHOT:
      return "STATUS_NOT_SHOT";
    case KillStatus.STATUS_BEING_SHOT:
      return "STATUS_BEING_SHOT";
    case KillStatus.STATUS_SHOT:
      return "STATUS_SHOT";
    case KillStatus.STATUS_PARTIALLY_SHOT:
      return "STATUS_PARTIALLY_SHOT";
    case KillStatus.STATUS_P2P_NOT_FOUND:
      return "STATUS_P2P_NOT_FOUND";
    case KillStatus.STATUS_ERROR:
      return "STATUS_ERROR";
    case KillStatus.STATUS_P2P_MISSING_CONTEXT:
      return "STATUS_P2P_MISSING_CONTEXT";
    case KillStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DuplicateStatus {
  UNIQUE = 0,
  PRIMARY = 1,
  DUPLICATE = 2,
  UNRECOGNIZED = -1,
}

export function duplicateStatusFromJSON(object: any): DuplicateStatus {
  switch (object) {
    case 0:
    case "UNIQUE":
      return DuplicateStatus.UNIQUE;
    case 1:
    case "PRIMARY":
      return DuplicateStatus.PRIMARY;
    case 2:
    case "DUPLICATE":
      return DuplicateStatus.DUPLICATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DuplicateStatus.UNRECOGNIZED;
  }
}

export function duplicateStatusToJSON(object: DuplicateStatus): string {
  switch (object) {
    case DuplicateStatus.UNIQUE:
      return "UNIQUE";
    case DuplicateStatus.PRIMARY:
      return "PRIMARY";
    case DuplicateStatus.DUPLICATE:
      return "DUPLICATE";
    case DuplicateStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ThinningState {
  THINNING_UNSET = 0,
  THINNING_MARKED_FOR_THINNING = 1,
  THINNING_KEPT = 2,
  THINNING_IGNORED = 3,
  UNRECOGNIZED = -1,
}

export function thinningStateFromJSON(object: any): ThinningState {
  switch (object) {
    case 0:
    case "THINNING_UNSET":
      return ThinningState.THINNING_UNSET;
    case 1:
    case "THINNING_MARKED_FOR_THINNING":
      return ThinningState.THINNING_MARKED_FOR_THINNING;
    case 2:
    case "THINNING_KEPT":
      return ThinningState.THINNING_KEPT;
    case 3:
    case "THINNING_IGNORED":
      return ThinningState.THINNING_IGNORED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ThinningState.UNRECOGNIZED;
  }
}

export function thinningStateToJSON(object: ThinningState): string {
  switch (object) {
    case ThinningState.THINNING_UNSET:
      return "THINNING_UNSET";
    case ThinningState.THINNING_MARKED_FOR_THINNING:
      return "THINNING_MARKED_FOR_THINNING";
    case ThinningState.THINNING_KEPT:
      return "THINNING_KEPT";
    case ThinningState.THINNING_IGNORED:
      return "THINNING_IGNORED";
    case ThinningState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TargetableState {
  TARGET_NOT_IN_SCHEDULER = 0,
  TARGET_SCORED = 1,
  TARGET_INTERSECTS_NON_SHOOTABLE = 2,
  TARGET_TOO_MANY_FAILURES = 3,
  TARGET_DOO_TOO_LOW = 4,
  TARGET_IGNORED_FROM_ALMANAC = 5,
  TARGET_OUT_OF_BAND = 6,
  TARGET_AVOID_FROM_ALMANAC = 7,
  UNRECOGNIZED = -1,
}

export function targetableStateFromJSON(object: any): TargetableState {
  switch (object) {
    case 0:
    case "TARGET_NOT_IN_SCHEDULER":
      return TargetableState.TARGET_NOT_IN_SCHEDULER;
    case 1:
    case "TARGET_SCORED":
      return TargetableState.TARGET_SCORED;
    case 2:
    case "TARGET_INTERSECTS_NON_SHOOTABLE":
      return TargetableState.TARGET_INTERSECTS_NON_SHOOTABLE;
    case 3:
    case "TARGET_TOO_MANY_FAILURES":
      return TargetableState.TARGET_TOO_MANY_FAILURES;
    case 4:
    case "TARGET_DOO_TOO_LOW":
      return TargetableState.TARGET_DOO_TOO_LOW;
    case 5:
    case "TARGET_IGNORED_FROM_ALMANAC":
      return TargetableState.TARGET_IGNORED_FROM_ALMANAC;
    case 6:
    case "TARGET_OUT_OF_BAND":
      return TargetableState.TARGET_OUT_OF_BAND;
    case 7:
    case "TARGET_AVOID_FROM_ALMANAC":
      return TargetableState.TARGET_AVOID_FROM_ALMANAC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TargetableState.UNRECOGNIZED;
  }
}

export function targetableStateToJSON(object: TargetableState): string {
  switch (object) {
    case TargetableState.TARGET_NOT_IN_SCHEDULER:
      return "TARGET_NOT_IN_SCHEDULER";
    case TargetableState.TARGET_SCORED:
      return "TARGET_SCORED";
    case TargetableState.TARGET_INTERSECTS_NON_SHOOTABLE:
      return "TARGET_INTERSECTS_NON_SHOOTABLE";
    case TargetableState.TARGET_TOO_MANY_FAILURES:
      return "TARGET_TOO_MANY_FAILURES";
    case TargetableState.TARGET_DOO_TOO_LOW:
      return "TARGET_DOO_TOO_LOW";
    case TargetableState.TARGET_IGNORED_FROM_ALMANAC:
      return "TARGET_IGNORED_FROM_ALMANAC";
    case TargetableState.TARGET_OUT_OF_BAND:
      return "TARGET_OUT_OF_BAND";
    case TargetableState.TARGET_AVOID_FROM_ALMANAC:
      return "TARGET_AVOID_FROM_ALMANAC";
    case TargetableState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RecordingStatus {
  NOT_RECORDING = 0,
  RECORDING_STARTED = 1,
  RECORDING_FINISHED = 2,
  RECORDING_FAILED = 3,
  UNRECOGNIZED = -1,
}

export function recordingStatusFromJSON(object: any): RecordingStatus {
  switch (object) {
    case 0:
    case "NOT_RECORDING":
      return RecordingStatus.NOT_RECORDING;
    case 1:
    case "RECORDING_STARTED":
      return RecordingStatus.RECORDING_STARTED;
    case 2:
    case "RECORDING_FINISHED":
      return RecordingStatus.RECORDING_FINISHED;
    case 3:
    case "RECORDING_FAILED":
      return RecordingStatus.RECORDING_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RecordingStatus.UNRECOGNIZED;
  }
}

export function recordingStatusToJSON(object: RecordingStatus): string {
  switch (object) {
    case RecordingStatus.NOT_RECORDING:
      return "NOT_RECORDING";
    case RecordingStatus.RECORDING_STARTED:
      return "RECORDING_STARTED";
    case RecordingStatus.RECORDING_FINISHED:
      return "RECORDING_FINISHED";
    case RecordingStatus.RECORDING_FAILED:
      return "RECORDING_FAILED";
    case RecordingStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ConclusionType {
  NOT_WEEDING = 0,
  OUT_OF_BAND = 1,
  INTERSECTS_WITH_NON_SHOOTABLE = 2,
  OUT_OF_RANGE = 3,
  UNIMPORTANT = 4,
  NOT_SHOT = 5,
  PARTIALLY_SHOT = 6,
  SHOT = 7,
  P2P_NOT_FOUND = 8,
  ERROR = 9,
  FLICKER = 10,
  MARKED_FOR_THINNING = 11,
  NOT_TARGETED = 12,
  P2P_MISSING_CONTEXT = 13,
  UNRECOGNIZED = -1,
}

export function conclusionTypeFromJSON(object: any): ConclusionType {
  switch (object) {
    case 0:
    case "NOT_WEEDING":
      return ConclusionType.NOT_WEEDING;
    case 1:
    case "OUT_OF_BAND":
      return ConclusionType.OUT_OF_BAND;
    case 2:
    case "INTERSECTS_WITH_NON_SHOOTABLE":
      return ConclusionType.INTERSECTS_WITH_NON_SHOOTABLE;
    case 3:
    case "OUT_OF_RANGE":
      return ConclusionType.OUT_OF_RANGE;
    case 4:
    case "UNIMPORTANT":
      return ConclusionType.UNIMPORTANT;
    case 5:
    case "NOT_SHOT":
      return ConclusionType.NOT_SHOT;
    case 6:
    case "PARTIALLY_SHOT":
      return ConclusionType.PARTIALLY_SHOT;
    case 7:
    case "SHOT":
      return ConclusionType.SHOT;
    case 8:
    case "P2P_NOT_FOUND":
      return ConclusionType.P2P_NOT_FOUND;
    case 9:
    case "ERROR":
      return ConclusionType.ERROR;
    case 10:
    case "FLICKER":
      return ConclusionType.FLICKER;
    case 11:
    case "MARKED_FOR_THINNING":
      return ConclusionType.MARKED_FOR_THINNING;
    case 12:
    case "NOT_TARGETED":
      return ConclusionType.NOT_TARGETED;
    case 13:
    case "P2P_MISSING_CONTEXT":
      return ConclusionType.P2P_MISSING_CONTEXT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConclusionType.UNRECOGNIZED;
  }
}

export function conclusionTypeToJSON(object: ConclusionType): string {
  switch (object) {
    case ConclusionType.NOT_WEEDING:
      return "NOT_WEEDING";
    case ConclusionType.OUT_OF_BAND:
      return "OUT_OF_BAND";
    case ConclusionType.INTERSECTS_WITH_NON_SHOOTABLE:
      return "INTERSECTS_WITH_NON_SHOOTABLE";
    case ConclusionType.OUT_OF_RANGE:
      return "OUT_OF_RANGE";
    case ConclusionType.UNIMPORTANT:
      return "UNIMPORTANT";
    case ConclusionType.NOT_SHOT:
      return "NOT_SHOT";
    case ConclusionType.PARTIALLY_SHOT:
      return "PARTIALLY_SHOT";
    case ConclusionType.SHOT:
      return "SHOT";
    case ConclusionType.P2P_NOT_FOUND:
      return "P2P_NOT_FOUND";
    case ConclusionType.ERROR:
      return "ERROR";
    case ConclusionType.FLICKER:
      return "FLICKER";
    case ConclusionType.MARKED_FOR_THINNING:
      return "MARKED_FOR_THINNING";
    case ConclusionType.NOT_TARGETED:
      return "NOT_TARGETED";
    case ConclusionType.P2P_MISSING_CONTEXT:
      return "P2P_MISSING_CONTEXT";
    case ConclusionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PlantCaptchaStatus {
  NOT_STARTED = 0,
  CAPTCHA_STARTED = 1,
  CAPTCHA_FINISHED = 2,
  CAPTCHA_FAILED = 3,
  CAPTCHA_CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function plantCaptchaStatusFromJSON(object: any): PlantCaptchaStatus {
  switch (object) {
    case 0:
    case "NOT_STARTED":
      return PlantCaptchaStatus.NOT_STARTED;
    case 1:
    case "CAPTCHA_STARTED":
      return PlantCaptchaStatus.CAPTCHA_STARTED;
    case 2:
    case "CAPTCHA_FINISHED":
      return PlantCaptchaStatus.CAPTCHA_FINISHED;
    case 3:
    case "CAPTCHA_FAILED":
      return PlantCaptchaStatus.CAPTCHA_FAILED;
    case 4:
    case "CAPTCHA_CANCELLED":
      return PlantCaptchaStatus.CAPTCHA_CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlantCaptchaStatus.UNRECOGNIZED;
  }
}

export function plantCaptchaStatusToJSON(object: PlantCaptchaStatus): string {
  switch (object) {
    case PlantCaptchaStatus.NOT_STARTED:
      return "NOT_STARTED";
    case PlantCaptchaStatus.CAPTCHA_STARTED:
      return "CAPTCHA_STARTED";
    case PlantCaptchaStatus.CAPTCHA_FINISHED:
      return "CAPTCHA_FINISHED";
    case PlantCaptchaStatus.CAPTCHA_FAILED:
      return "CAPTCHA_FAILED";
    case PlantCaptchaStatus.CAPTCHA_CANCELLED:
      return "CAPTCHA_CANCELLED";
    case PlantCaptchaStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PlantCaptchaUserPrediction {
  WEED = 0,
  CROP = 1,
  UNKNOWN = 2,
  OTHER = 3,
  /** IGNORE - Only used in initial state */
  IGNORE = 4,
  VOLUNTEER = 5,
  BENEFICIAL = 6,
  DEBRIS = 7,
  UNRECOGNIZED = -1,
}

export function plantCaptchaUserPredictionFromJSON(object: any): PlantCaptchaUserPrediction {
  switch (object) {
    case 0:
    case "WEED":
      return PlantCaptchaUserPrediction.WEED;
    case 1:
    case "CROP":
      return PlantCaptchaUserPrediction.CROP;
    case 2:
    case "UNKNOWN":
      return PlantCaptchaUserPrediction.UNKNOWN;
    case 3:
    case "OTHER":
      return PlantCaptchaUserPrediction.OTHER;
    case 4:
    case "IGNORE":
      return PlantCaptchaUserPrediction.IGNORE;
    case 5:
    case "VOLUNTEER":
      return PlantCaptchaUserPrediction.VOLUNTEER;
    case 6:
    case "BENEFICIAL":
      return PlantCaptchaUserPrediction.BENEFICIAL;
    case 7:
    case "DEBRIS":
      return PlantCaptchaUserPrediction.DEBRIS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlantCaptchaUserPrediction.UNRECOGNIZED;
  }
}

export function plantCaptchaUserPredictionToJSON(object: PlantCaptchaUserPrediction): string {
  switch (object) {
    case PlantCaptchaUserPrediction.WEED:
      return "WEED";
    case PlantCaptchaUserPrediction.CROP:
      return "CROP";
    case PlantCaptchaUserPrediction.UNKNOWN:
      return "UNKNOWN";
    case PlantCaptchaUserPrediction.OTHER:
      return "OTHER";
    case PlantCaptchaUserPrediction.IGNORE:
      return "IGNORE";
    case PlantCaptchaUserPrediction.VOLUNTEER:
      return "VOLUNTEER";
    case PlantCaptchaUserPrediction.BENEFICIAL:
      return "BENEFICIAL";
    case PlantCaptchaUserPrediction.DEBRIS:
      return "DEBRIS";
    case PlantCaptchaUserPrediction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** @deprecated */
export interface Trajectory {
  id: number;
  trackerId: number;
  status: number;
  isWeed: boolean;
  xMm: number;
  yMm: number;
  zMm: number;
  intersectedWithNonshootable: boolean;
  nonshootableTypeString: string;
  deduplicatedAcrossTracker: boolean;
}

/** @deprecated */
export interface Target {
  scannerId: number;
  trajectoryId: number;
  nextTrajectoryId: number;
  startingPosY: number;
  endingPosY: number;
}

/** @deprecated */
export interface Bounds {
  trackerId: string;
  maxPosMmY: number;
}

/** @deprecated */
export interface TrackingStatusReply {
  targets: Target[];
  trajectories: Trajectory[];
  bounds: Bounds[];
}

export interface GetDetectionsRequest {
  camId: string;
  timestampMs: number;
  maxX: number;
}

export interface Detection {
  x: number;
  y: number;
  size: number;
  clz: string;
  isWeed: boolean;
  outOfBand: boolean;
  id: number;
}

export interface Detections {
  detections: Detection[];
  timestampMs: number;
}

export interface Band {
  startXPx: number;
  endXPx: number;
}

export interface Bands {
  band: Band[];
  bandingEnabled: boolean;
  rowHasBandsDefined: boolean;
}

export interface GetDetectionsResponse {
  detections: Detections | undefined;
  bands: Bands | undefined;
}

export interface PingRequest {
  x: number;
}

export interface PongReply {
  x: number;
}

export interface TrajectoryScore {
  score: number;
  scoreTilt: number;
  scorePan: number;
  scoreBusy: number;
  scoreInRange: number;
  scoreImportance: number;
  scoreSize: number;
}

export interface PerScannerScore {
  scannerId: number;
  score: number;
}

export interface ScoreState {
  targetState: TargetableState;
  scores: PerScannerScore[];
}

export interface Pos2D {
  x: number;
  y: number;
}

export interface KillBox {
  scannerId: number;
  topLeft: Pos2D | undefined;
  bottomRight: Pos2D | undefined;
}

export interface Thresholds {
  /** @deprecated */
  weeding: number;
  /** @deprecated */
  thinning: number;
  /** @deprecated */
  banding: number;
  passedWeeding: boolean;
  passedThinning: boolean;
  passedBanding: boolean;
}

export interface TrajectorySnapshot {
  id: number;
  killStatus: KillStatus;
  isWeed: boolean;
  xMm: number;
  yMm: number;
  zMm: number;
  score?: TrajectoryScore | undefined;
  invalidScore?: InvalidScoreReason | undefined;
  radiusMm: number;
  category: string;
  shootTimeRequestedMs: number;
  shootTimeActualMs: number;
  markedForThinning: boolean;
  trackerId: number;
  duplicateStatus: DuplicateStatus;
  duplicateTrajectoryId: number;
  assignedLasers: number[];
  /** deprecated 1.16 use score state */
  outOfBand: boolean;
  /** deprecated 1.16 */
  intersectedWithNonshootable: boolean;
  detectionClasses: { [key: string]: number };
  confidence: number;
  thinningState: ThinningState;
  globalPos: number;
  scoreState: ScoreState | undefined;
  doo: number;
  distancePerspectivesCount: number;
  /** as returned from almanac, 0 for small, 1 for medium, 2 for large */
  sizeCategoryIndex: number;
  speculativeAllowed: boolean;
  /** crop protect / reverse crop protect */
  protectedByTraj: boolean;
  thresholds: Thresholds | undefined;
}

export interface TrajectorySnapshot_DetectionClassesEntry {
  key: string;
  value: number;
}

export interface BandDefinition {
  offsetMm: number;
  widthMm: number;
  id: number;
}

export interface DiagnosticsSnapshot {
  timestampMs: number;
  trajectories: TrajectorySnapshot[];
  bands: BandDefinition[];
  killBoxes: KillBox[];
}

export interface RecordDiagnosticsRequest {
  ttlSec: number;
  cropImagesPerSec: number;
  weedImagesPerSec: number;
}

export interface GetRecordingStatusResponse {
  status: RecordingStatus;
}

export interface StartSavingCropLineDetectionReplayRequest {
  filename: string;
  ttlMs: number;
}

export interface RecordAimbotInputRequest {
  name: string;
  ttlMs: number;
  rotaryTicks?: boolean | undefined;
  deepweed?: boolean | undefined;
  laneHeights?: boolean | undefined;
}

export interface ConclusionCount {
  type: ConclusionType;
  count: number;
}

export interface ConclusionCounter {
  counts: ConclusionCount[];
}

export interface BandDefinitions {
  bands: BandDefinition[];
  bandingEnabled: boolean;
  rowHasBandsDefined: boolean;
}

export interface PlantCaptchaStatusResponse {
  status: PlantCaptchaStatus;
  totalImages: number;
  imagesTaken: number;
  metadataTaken: number;
}

export interface Embedding {
  elements: number[];
}

export interface PlantCaptchaItemMetadata {
  confidence: number;
  xPx: number;
  yPx: number;
  xMm: number;
  yMm: number;
  zMm: number;
  sizeMm: number;
  categories: { [key: string]: number };
  doo: number;
  isWeed: boolean;
  intersectedWithNonshootable: boolean;
  confidenceHistory: number[];
  isInBand: boolean;
  id: string;
  sizePx: number;
  shootTimeMs: number;
  weedConfidenceHistory: number[];
  cropConfidenceHistory: number[];
  /** as returned from almanac, 0 for small, 1 for medium, 2 for large */
  sizeCategoryIndex: number;
  weedCategories: { [key: string]: number };
  embeddingHistory: Embedding[];
  initialLabel: PlantCaptchaUserPrediction;
}

export interface PlantCaptchaItemMetadata_CategoriesEntry {
  key: string;
  value: number;
}

export interface PlantCaptchaItemMetadata_WeedCategoriesEntry {
  key: string;
  value: number;
}

export interface GetTargetingEnabledRequest {
}

export interface GetTargetingEnabledResponse {
  enabled: boolean;
}

function createBaseTrajectory(): Trajectory {
  return {
    id: 0,
    trackerId: 0,
    status: 0,
    isWeed: false,
    xMm: 0,
    yMm: 0,
    zMm: 0,
    intersectedWithNonshootable: false,
    nonshootableTypeString: "",
    deduplicatedAcrossTracker: false,
  };
}

export const Trajectory: MessageFns<Trajectory> = {
  encode(message: Trajectory, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.trackerId !== 0) {
      writer.uint32(16).uint32(message.trackerId);
    }
    if (message.status !== 0) {
      writer.uint32(24).uint32(message.status);
    }
    if (message.isWeed !== false) {
      writer.uint32(32).bool(message.isWeed);
    }
    if (message.xMm !== 0) {
      writer.uint32(41).double(message.xMm);
    }
    if (message.yMm !== 0) {
      writer.uint32(49).double(message.yMm);
    }
    if (message.zMm !== 0) {
      writer.uint32(57).double(message.zMm);
    }
    if (message.intersectedWithNonshootable !== false) {
      writer.uint32(64).bool(message.intersectedWithNonshootable);
    }
    if (message.nonshootableTypeString !== "") {
      writer.uint32(74).string(message.nonshootableTypeString);
    }
    if (message.deduplicatedAcrossTracker !== false) {
      writer.uint32(80).bool(message.deduplicatedAcrossTracker);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Trajectory {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.trackerId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isWeed = reader.bool();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.xMm = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.yMm = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.zMm = reader.double();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.intersectedWithNonshootable = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.nonshootableTypeString = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.deduplicatedAcrossTracker = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Trajectory {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      trackerId: isSet(object.trackerId) ? globalThis.Number(object.trackerId) : 0,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      isWeed: isSet(object.isWeed) ? globalThis.Boolean(object.isWeed) : false,
      xMm: isSet(object.xMm) ? globalThis.Number(object.xMm) : 0,
      yMm: isSet(object.yMm) ? globalThis.Number(object.yMm) : 0,
      zMm: isSet(object.zMm) ? globalThis.Number(object.zMm) : 0,
      intersectedWithNonshootable: isSet(object.intersectedWithNonshootable)
        ? globalThis.Boolean(object.intersectedWithNonshootable)
        : false,
      nonshootableTypeString: isSet(object.nonshootableTypeString)
        ? globalThis.String(object.nonshootableTypeString)
        : "",
      deduplicatedAcrossTracker: isSet(object.deduplicatedAcrossTracker)
        ? globalThis.Boolean(object.deduplicatedAcrossTracker)
        : false,
    };
  },

  toJSON(message: Trajectory): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.trackerId !== 0) {
      obj.trackerId = Math.round(message.trackerId);
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.isWeed !== false) {
      obj.isWeed = message.isWeed;
    }
    if (message.xMm !== 0) {
      obj.xMm = message.xMm;
    }
    if (message.yMm !== 0) {
      obj.yMm = message.yMm;
    }
    if (message.zMm !== 0) {
      obj.zMm = message.zMm;
    }
    if (message.intersectedWithNonshootable !== false) {
      obj.intersectedWithNonshootable = message.intersectedWithNonshootable;
    }
    if (message.nonshootableTypeString !== "") {
      obj.nonshootableTypeString = message.nonshootableTypeString;
    }
    if (message.deduplicatedAcrossTracker !== false) {
      obj.deduplicatedAcrossTracker = message.deduplicatedAcrossTracker;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trajectory>, I>>(base?: I): Trajectory {
    return Trajectory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Trajectory>, I>>(object: I): Trajectory {
    const message = createBaseTrajectory();
    message.id = object.id ?? 0;
    message.trackerId = object.trackerId ?? 0;
    message.status = object.status ?? 0;
    message.isWeed = object.isWeed ?? false;
    message.xMm = object.xMm ?? 0;
    message.yMm = object.yMm ?? 0;
    message.zMm = object.zMm ?? 0;
    message.intersectedWithNonshootable = object.intersectedWithNonshootable ?? false;
    message.nonshootableTypeString = object.nonshootableTypeString ?? "";
    message.deduplicatedAcrossTracker = object.deduplicatedAcrossTracker ?? false;
    return message;
  },
};

function createBaseTarget(): Target {
  return { scannerId: 0, trajectoryId: 0, nextTrajectoryId: 0, startingPosY: 0, endingPosY: 0 };
}

export const Target: MessageFns<Target> = {
  encode(message: Target, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(16).uint32(message.trajectoryId);
    }
    if (message.nextTrajectoryId !== 0) {
      writer.uint32(24).uint32(message.nextTrajectoryId);
    }
    if (message.startingPosY !== 0) {
      writer.uint32(37).float(message.startingPosY);
    }
    if (message.endingPosY !== 0) {
      writer.uint32(45).float(message.endingPosY);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Target {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.nextTrajectoryId = reader.uint32();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.startingPosY = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.endingPosY = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Target {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
      nextTrajectoryId: isSet(object.nextTrajectoryId) ? globalThis.Number(object.nextTrajectoryId) : 0,
      startingPosY: isSet(object.startingPosY) ? globalThis.Number(object.startingPosY) : 0,
      endingPosY: isSet(object.endingPosY) ? globalThis.Number(object.endingPosY) : 0,
    };
  },

  toJSON(message: Target): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    if (message.nextTrajectoryId !== 0) {
      obj.nextTrajectoryId = Math.round(message.nextTrajectoryId);
    }
    if (message.startingPosY !== 0) {
      obj.startingPosY = message.startingPosY;
    }
    if (message.endingPosY !== 0) {
      obj.endingPosY = message.endingPosY;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Target>, I>>(base?: I): Target {
    return Target.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Target>, I>>(object: I): Target {
    const message = createBaseTarget();
    message.scannerId = object.scannerId ?? 0;
    message.trajectoryId = object.trajectoryId ?? 0;
    message.nextTrajectoryId = object.nextTrajectoryId ?? 0;
    message.startingPosY = object.startingPosY ?? 0;
    message.endingPosY = object.endingPosY ?? 0;
    return message;
  },
};

function createBaseBounds(): Bounds {
  return { trackerId: "", maxPosMmY: 0 };
}

export const Bounds: MessageFns<Bounds> = {
  encode(message: Bounds, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.trackerId !== "") {
      writer.uint32(10).string(message.trackerId);
    }
    if (message.maxPosMmY !== 0) {
      writer.uint32(21).float(message.maxPosMmY);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Bounds {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBounds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.trackerId = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.maxPosMmY = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Bounds {
    return {
      trackerId: isSet(object.trackerId) ? globalThis.String(object.trackerId) : "",
      maxPosMmY: isSet(object.maxPosMmY) ? globalThis.Number(object.maxPosMmY) : 0,
    };
  },

  toJSON(message: Bounds): unknown {
    const obj: any = {};
    if (message.trackerId !== "") {
      obj.trackerId = message.trackerId;
    }
    if (message.maxPosMmY !== 0) {
      obj.maxPosMmY = message.maxPosMmY;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Bounds>, I>>(base?: I): Bounds {
    return Bounds.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Bounds>, I>>(object: I): Bounds {
    const message = createBaseBounds();
    message.trackerId = object.trackerId ?? "";
    message.maxPosMmY = object.maxPosMmY ?? 0;
    return message;
  },
};

function createBaseTrackingStatusReply(): TrackingStatusReply {
  return { targets: [], trajectories: [], bounds: [] };
}

export const TrackingStatusReply: MessageFns<TrackingStatusReply> = {
  encode(message: TrackingStatusReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.targets) {
      Target.encode(v!, writer.uint32(10).fork()).join();
    }
    for (const v of message.trajectories) {
      Trajectory.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.bounds) {
      Bounds.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrackingStatusReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrackingStatusReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targets.push(Target.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trajectories.push(Trajectory.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bounds.push(Bounds.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrackingStatusReply {
    return {
      targets: globalThis.Array.isArray(object?.targets) ? object.targets.map((e: any) => Target.fromJSON(e)) : [],
      trajectories: globalThis.Array.isArray(object?.trajectories)
        ? object.trajectories.map((e: any) => Trajectory.fromJSON(e))
        : [],
      bounds: globalThis.Array.isArray(object?.bounds) ? object.bounds.map((e: any) => Bounds.fromJSON(e)) : [],
    };
  },

  toJSON(message: TrackingStatusReply): unknown {
    const obj: any = {};
    if (message.targets?.length) {
      obj.targets = message.targets.map((e) => Target.toJSON(e));
    }
    if (message.trajectories?.length) {
      obj.trajectories = message.trajectories.map((e) => Trajectory.toJSON(e));
    }
    if (message.bounds?.length) {
      obj.bounds = message.bounds.map((e) => Bounds.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrackingStatusReply>, I>>(base?: I): TrackingStatusReply {
    return TrackingStatusReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrackingStatusReply>, I>>(object: I): TrackingStatusReply {
    const message = createBaseTrackingStatusReply();
    message.targets = object.targets?.map((e) => Target.fromPartial(e)) || [];
    message.trajectories = object.trajectories?.map((e) => Trajectory.fromPartial(e)) || [];
    message.bounds = object.bounds?.map((e) => Bounds.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDetectionsRequest(): GetDetectionsRequest {
  return { camId: "", timestampMs: 0, maxX: 0 };
}

export const GetDetectionsRequest: MessageFns<GetDetectionsRequest> = {
  encode(message: GetDetectionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.camId !== "") {
      writer.uint32(10).string(message.camId);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    if (message.maxX !== 0) {
      writer.uint32(24).int32(message.maxX);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDetectionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDetectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxX = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDetectionsRequest {
    return {
      camId: isSet(object.camId) ? globalThis.String(object.camId) : "",
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      maxX: isSet(object.maxX) ? globalThis.Number(object.maxX) : 0,
    };
  },

  toJSON(message: GetDetectionsRequest): unknown {
    const obj: any = {};
    if (message.camId !== "") {
      obj.camId = message.camId;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.maxX !== 0) {
      obj.maxX = Math.round(message.maxX);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDetectionsRequest>, I>>(base?: I): GetDetectionsRequest {
    return GetDetectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDetectionsRequest>, I>>(object: I): GetDetectionsRequest {
    const message = createBaseGetDetectionsRequest();
    message.camId = object.camId ?? "";
    message.timestampMs = object.timestampMs ?? 0;
    message.maxX = object.maxX ?? 0;
    return message;
  },
};

function createBaseDetection(): Detection {
  return { x: 0, y: 0, size: 0, clz: "", isWeed: false, outOfBand: false, id: 0 };
}

export const Detection: MessageFns<Detection> = {
  encode(message: Detection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    if (message.size !== 0) {
      writer.uint32(29).float(message.size);
    }
    if (message.clz !== "") {
      writer.uint32(34).string(message.clz);
    }
    if (message.isWeed !== false) {
      writer.uint32(40).bool(message.isWeed);
    }
    if (message.outOfBand !== false) {
      writer.uint32(48).bool(message.outOfBand);
    }
    if (message.id !== 0) {
      writer.uint32(56).uint32(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Detection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.size = reader.float();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clz = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isWeed = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.outOfBand = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.id = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Detection {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      clz: isSet(object.clz) ? globalThis.String(object.clz) : "",
      isWeed: isSet(object.isWeed) ? globalThis.Boolean(object.isWeed) : false,
      outOfBand: isSet(object.outOfBand) ? globalThis.Boolean(object.outOfBand) : false,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
    };
  },

  toJSON(message: Detection): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.size !== 0) {
      obj.size = message.size;
    }
    if (message.clz !== "") {
      obj.clz = message.clz;
    }
    if (message.isWeed !== false) {
      obj.isWeed = message.isWeed;
    }
    if (message.outOfBand !== false) {
      obj.outOfBand = message.outOfBand;
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Detection>, I>>(base?: I): Detection {
    return Detection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Detection>, I>>(object: I): Detection {
    const message = createBaseDetection();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.size = object.size ?? 0;
    message.clz = object.clz ?? "";
    message.isWeed = object.isWeed ?? false;
    message.outOfBand = object.outOfBand ?? false;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseDetections(): Detections {
  return { detections: [], timestampMs: 0 };
}

export const Detections: MessageFns<Detections> = {
  encode(message: Detections, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.detections) {
      Detection.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.timestampMs !== 0) {
      writer.uint32(16).int64(message.timestampMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Detections {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetections();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detections.push(Detection.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Detections {
    return {
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => Detection.fromJSON(e))
        : [],
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
    };
  },

  toJSON(message: Detections): unknown {
    const obj: any = {};
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => Detection.toJSON(e));
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Detections>, I>>(base?: I): Detections {
    return Detections.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Detections>, I>>(object: I): Detections {
    const message = createBaseDetections();
    message.detections = object.detections?.map((e) => Detection.fromPartial(e)) || [];
    message.timestampMs = object.timestampMs ?? 0;
    return message;
  },
};

function createBaseBand(): Band {
  return { startXPx: 0, endXPx: 0 };
}

export const Band: MessageFns<Band> = {
  encode(message: Band, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startXPx !== 0) {
      writer.uint32(9).double(message.startXPx);
    }
    if (message.endXPx !== 0) {
      writer.uint32(17).double(message.endXPx);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Band {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.startXPx = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.endXPx = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Band {
    return {
      startXPx: isSet(object.startXPx) ? globalThis.Number(object.startXPx) : 0,
      endXPx: isSet(object.endXPx) ? globalThis.Number(object.endXPx) : 0,
    };
  },

  toJSON(message: Band): unknown {
    const obj: any = {};
    if (message.startXPx !== 0) {
      obj.startXPx = message.startXPx;
    }
    if (message.endXPx !== 0) {
      obj.endXPx = message.endXPx;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Band>, I>>(base?: I): Band {
    return Band.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Band>, I>>(object: I): Band {
    const message = createBaseBand();
    message.startXPx = object.startXPx ?? 0;
    message.endXPx = object.endXPx ?? 0;
    return message;
  },
};

function createBaseBands(): Bands {
  return { band: [], bandingEnabled: false, rowHasBandsDefined: false };
}

export const Bands: MessageFns<Bands> = {
  encode(message: Bands, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.band) {
      Band.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.bandingEnabled !== false) {
      writer.uint32(16).bool(message.bandingEnabled);
    }
    if (message.rowHasBandsDefined !== false) {
      writer.uint32(24).bool(message.rowHasBandsDefined);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Bands {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBands();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.band.push(Band.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bandingEnabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rowHasBandsDefined = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Bands {
    return {
      band: globalThis.Array.isArray(object?.band) ? object.band.map((e: any) => Band.fromJSON(e)) : [],
      bandingEnabled: isSet(object.bandingEnabled) ? globalThis.Boolean(object.bandingEnabled) : false,
      rowHasBandsDefined: isSet(object.rowHasBandsDefined) ? globalThis.Boolean(object.rowHasBandsDefined) : false,
    };
  },

  toJSON(message: Bands): unknown {
    const obj: any = {};
    if (message.band?.length) {
      obj.band = message.band.map((e) => Band.toJSON(e));
    }
    if (message.bandingEnabled !== false) {
      obj.bandingEnabled = message.bandingEnabled;
    }
    if (message.rowHasBandsDefined !== false) {
      obj.rowHasBandsDefined = message.rowHasBandsDefined;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Bands>, I>>(base?: I): Bands {
    return Bands.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Bands>, I>>(object: I): Bands {
    const message = createBaseBands();
    message.band = object.band?.map((e) => Band.fromPartial(e)) || [];
    message.bandingEnabled = object.bandingEnabled ?? false;
    message.rowHasBandsDefined = object.rowHasBandsDefined ?? false;
    return message;
  },
};

function createBaseGetDetectionsResponse(): GetDetectionsResponse {
  return { detections: undefined, bands: undefined };
}

export const GetDetectionsResponse: MessageFns<GetDetectionsResponse> = {
  encode(message: GetDetectionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.detections !== undefined) {
      Detections.encode(message.detections, writer.uint32(10).fork()).join();
    }
    if (message.bands !== undefined) {
      Bands.encode(message.bands, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDetectionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDetectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detections = Detections.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bands = Bands.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDetectionsResponse {
    return {
      detections: isSet(object.detections) ? Detections.fromJSON(object.detections) : undefined,
      bands: isSet(object.bands) ? Bands.fromJSON(object.bands) : undefined,
    };
  },

  toJSON(message: GetDetectionsResponse): unknown {
    const obj: any = {};
    if (message.detections !== undefined) {
      obj.detections = Detections.toJSON(message.detections);
    }
    if (message.bands !== undefined) {
      obj.bands = Bands.toJSON(message.bands);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDetectionsResponse>, I>>(base?: I): GetDetectionsResponse {
    return GetDetectionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDetectionsResponse>, I>>(object: I): GetDetectionsResponse {
    const message = createBaseGetDetectionsResponse();
    message.detections = (object.detections !== undefined && object.detections !== null)
      ? Detections.fromPartial(object.detections)
      : undefined;
    message.bands = (object.bands !== undefined && object.bands !== null) ? Bands.fromPartial(object.bands) : undefined;
    return message;
  },
};

function createBasePingRequest(): PingRequest {
  return { x: 0 };
}

export const PingRequest: MessageFns<PingRequest> = {
  encode(message: PingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).uint32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PingRequest {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PingRequest): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PingRequest>, I>>(base?: I): PingRequest {
    return PingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PingRequest>, I>>(object: I): PingRequest {
    const message = createBasePingRequest();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBasePongReply(): PongReply {
  return { x: 0 };
}

export const PongReply: MessageFns<PongReply> = {
  encode(message: PongReply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(8).uint32(message.x);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PongReply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePongReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PongReply {
    return { x: isSet(object.x) ? globalThis.Number(object.x) : 0 };
  },

  toJSON(message: PongReply): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PongReply>, I>>(base?: I): PongReply {
    return PongReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PongReply>, I>>(object: I): PongReply {
    const message = createBasePongReply();
    message.x = object.x ?? 0;
    return message;
  },
};

function createBaseTrajectoryScore(): TrajectoryScore {
  return { score: 0, scoreTilt: 0, scorePan: 0, scoreBusy: 0, scoreInRange: 0, scoreImportance: 0, scoreSize: 0 };
}

export const TrajectoryScore: MessageFns<TrajectoryScore> = {
  encode(message: TrajectoryScore, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.score !== 0) {
      writer.uint32(8).uint64(message.score);
    }
    if (message.scoreTilt !== 0) {
      writer.uint32(17).double(message.scoreTilt);
    }
    if (message.scorePan !== 0) {
      writer.uint32(25).double(message.scorePan);
    }
    if (message.scoreBusy !== 0) {
      writer.uint32(33).double(message.scoreBusy);
    }
    if (message.scoreInRange !== 0) {
      writer.uint32(41).double(message.scoreInRange);
    }
    if (message.scoreImportance !== 0) {
      writer.uint32(49).double(message.scoreImportance);
    }
    if (message.scoreSize !== 0) {
      writer.uint32(57).double(message.scoreSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectoryScore {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectoryScore();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.score = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.scoreTilt = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.scorePan = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.scoreBusy = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.scoreInRange = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.scoreImportance = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.scoreSize = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectoryScore {
    return {
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      scoreTilt: isSet(object.scoreTilt) ? globalThis.Number(object.scoreTilt) : 0,
      scorePan: isSet(object.scorePan) ? globalThis.Number(object.scorePan) : 0,
      scoreBusy: isSet(object.scoreBusy) ? globalThis.Number(object.scoreBusy) : 0,
      scoreInRange: isSet(object.scoreInRange) ? globalThis.Number(object.scoreInRange) : 0,
      scoreImportance: isSet(object.scoreImportance) ? globalThis.Number(object.scoreImportance) : 0,
      scoreSize: isSet(object.scoreSize) ? globalThis.Number(object.scoreSize) : 0,
    };
  },

  toJSON(message: TrajectoryScore): unknown {
    const obj: any = {};
    if (message.score !== 0) {
      obj.score = Math.round(message.score);
    }
    if (message.scoreTilt !== 0) {
      obj.scoreTilt = message.scoreTilt;
    }
    if (message.scorePan !== 0) {
      obj.scorePan = message.scorePan;
    }
    if (message.scoreBusy !== 0) {
      obj.scoreBusy = message.scoreBusy;
    }
    if (message.scoreInRange !== 0) {
      obj.scoreInRange = message.scoreInRange;
    }
    if (message.scoreImportance !== 0) {
      obj.scoreImportance = message.scoreImportance;
    }
    if (message.scoreSize !== 0) {
      obj.scoreSize = message.scoreSize;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectoryScore>, I>>(base?: I): TrajectoryScore {
    return TrajectoryScore.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectoryScore>, I>>(object: I): TrajectoryScore {
    const message = createBaseTrajectoryScore();
    message.score = object.score ?? 0;
    message.scoreTilt = object.scoreTilt ?? 0;
    message.scorePan = object.scorePan ?? 0;
    message.scoreBusy = object.scoreBusy ?? 0;
    message.scoreInRange = object.scoreInRange ?? 0;
    message.scoreImportance = object.scoreImportance ?? 0;
    message.scoreSize = object.scoreSize ?? 0;
    return message;
  },
};

function createBasePerScannerScore(): PerScannerScore {
  return { scannerId: 0, score: 0 };
}

export const PerScannerScore: MessageFns<PerScannerScore> = {
  encode(message: PerScannerScore, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.score !== 0) {
      writer.uint32(16).int32(message.score);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PerScannerScore {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePerScannerScore();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.score = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PerScannerScore {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
    };
  },

  toJSON(message: PerScannerScore): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.score !== 0) {
      obj.score = Math.round(message.score);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PerScannerScore>, I>>(base?: I): PerScannerScore {
    return PerScannerScore.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PerScannerScore>, I>>(object: I): PerScannerScore {
    const message = createBasePerScannerScore();
    message.scannerId = object.scannerId ?? 0;
    message.score = object.score ?? 0;
    return message;
  },
};

function createBaseScoreState(): ScoreState {
  return { targetState: 0, scores: [] };
}

export const ScoreState: MessageFns<ScoreState> = {
  encode(message: ScoreState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetState !== 0) {
      writer.uint32(8).int32(message.targetState);
    }
    for (const v of message.scores) {
      PerScannerScore.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ScoreState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScoreState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.targetState = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scores.push(PerScannerScore.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScoreState {
    return {
      targetState: isSet(object.targetState) ? targetableStateFromJSON(object.targetState) : 0,
      scores: globalThis.Array.isArray(object?.scores)
        ? object.scores.map((e: any) => PerScannerScore.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScoreState): unknown {
    const obj: any = {};
    if (message.targetState !== 0) {
      obj.targetState = targetableStateToJSON(message.targetState);
    }
    if (message.scores?.length) {
      obj.scores = message.scores.map((e) => PerScannerScore.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScoreState>, I>>(base?: I): ScoreState {
    return ScoreState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScoreState>, I>>(object: I): ScoreState {
    const message = createBaseScoreState();
    message.targetState = object.targetState ?? 0;
    message.scores = object.scores?.map((e) => PerScannerScore.fromPartial(e)) || [];
    return message;
  },
};

function createBasePos2D(): Pos2D {
  return { x: 0, y: 0 };
}

export const Pos2D: MessageFns<Pos2D> = {
  encode(message: Pos2D, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(9).double(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(17).double(message.y);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Pos2D {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePos2D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.x = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.y = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pos2D {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: Pos2D): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pos2D>, I>>(base?: I): Pos2D {
    return Pos2D.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pos2D>, I>>(object: I): Pos2D {
    const message = createBasePos2D();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseKillBox(): KillBox {
  return { scannerId: 0, topLeft: undefined, bottomRight: undefined };
}

export const KillBox: MessageFns<KillBox> = {
  encode(message: KillBox, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scannerId !== 0) {
      writer.uint32(8).uint32(message.scannerId);
    }
    if (message.topLeft !== undefined) {
      Pos2D.encode(message.topLeft, writer.uint32(18).fork()).join();
    }
    if (message.bottomRight !== undefined) {
      Pos2D.encode(message.bottomRight, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KillBox {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKillBox();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scannerId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.topLeft = Pos2D.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bottomRight = Pos2D.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KillBox {
    return {
      scannerId: isSet(object.scannerId) ? globalThis.Number(object.scannerId) : 0,
      topLeft: isSet(object.topLeft) ? Pos2D.fromJSON(object.topLeft) : undefined,
      bottomRight: isSet(object.bottomRight) ? Pos2D.fromJSON(object.bottomRight) : undefined,
    };
  },

  toJSON(message: KillBox): unknown {
    const obj: any = {};
    if (message.scannerId !== 0) {
      obj.scannerId = Math.round(message.scannerId);
    }
    if (message.topLeft !== undefined) {
      obj.topLeft = Pos2D.toJSON(message.topLeft);
    }
    if (message.bottomRight !== undefined) {
      obj.bottomRight = Pos2D.toJSON(message.bottomRight);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KillBox>, I>>(base?: I): KillBox {
    return KillBox.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KillBox>, I>>(object: I): KillBox {
    const message = createBaseKillBox();
    message.scannerId = object.scannerId ?? 0;
    message.topLeft = (object.topLeft !== undefined && object.topLeft !== null)
      ? Pos2D.fromPartial(object.topLeft)
      : undefined;
    message.bottomRight = (object.bottomRight !== undefined && object.bottomRight !== null)
      ? Pos2D.fromPartial(object.bottomRight)
      : undefined;
    return message;
  },
};

function createBaseThresholds(): Thresholds {
  return { weeding: 0, thinning: 0, banding: 0, passedWeeding: false, passedThinning: false, passedBanding: false };
}

export const Thresholds: MessageFns<Thresholds> = {
  encode(message: Thresholds, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.weeding !== 0) {
      writer.uint32(13).float(message.weeding);
    }
    if (message.thinning !== 0) {
      writer.uint32(21).float(message.thinning);
    }
    if (message.banding !== 0) {
      writer.uint32(29).float(message.banding);
    }
    if (message.passedWeeding !== false) {
      writer.uint32(32).bool(message.passedWeeding);
    }
    if (message.passedThinning !== false) {
      writer.uint32(40).bool(message.passedThinning);
    }
    if (message.passedBanding !== false) {
      writer.uint32(48).bool(message.passedBanding);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Thresholds {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseThresholds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.weeding = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.thinning = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.banding = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.passedWeeding = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.passedThinning = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.passedBanding = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Thresholds {
    return {
      weeding: isSet(object.weeding) ? globalThis.Number(object.weeding) : 0,
      thinning: isSet(object.thinning) ? globalThis.Number(object.thinning) : 0,
      banding: isSet(object.banding) ? globalThis.Number(object.banding) : 0,
      passedWeeding: isSet(object.passedWeeding) ? globalThis.Boolean(object.passedWeeding) : false,
      passedThinning: isSet(object.passedThinning) ? globalThis.Boolean(object.passedThinning) : false,
      passedBanding: isSet(object.passedBanding) ? globalThis.Boolean(object.passedBanding) : false,
    };
  },

  toJSON(message: Thresholds): unknown {
    const obj: any = {};
    if (message.weeding !== 0) {
      obj.weeding = message.weeding;
    }
    if (message.thinning !== 0) {
      obj.thinning = message.thinning;
    }
    if (message.banding !== 0) {
      obj.banding = message.banding;
    }
    if (message.passedWeeding !== false) {
      obj.passedWeeding = message.passedWeeding;
    }
    if (message.passedThinning !== false) {
      obj.passedThinning = message.passedThinning;
    }
    if (message.passedBanding !== false) {
      obj.passedBanding = message.passedBanding;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Thresholds>, I>>(base?: I): Thresholds {
    return Thresholds.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Thresholds>, I>>(object: I): Thresholds {
    const message = createBaseThresholds();
    message.weeding = object.weeding ?? 0;
    message.thinning = object.thinning ?? 0;
    message.banding = object.banding ?? 0;
    message.passedWeeding = object.passedWeeding ?? false;
    message.passedThinning = object.passedThinning ?? false;
    message.passedBanding = object.passedBanding ?? false;
    return message;
  },
};

function createBaseTrajectorySnapshot(): TrajectorySnapshot {
  return {
    id: 0,
    killStatus: 0,
    isWeed: false,
    xMm: 0,
    yMm: 0,
    zMm: 0,
    score: undefined,
    invalidScore: undefined,
    radiusMm: 0,
    category: "",
    shootTimeRequestedMs: 0,
    shootTimeActualMs: 0,
    markedForThinning: false,
    trackerId: 0,
    duplicateStatus: 0,
    duplicateTrajectoryId: 0,
    assignedLasers: [],
    outOfBand: false,
    intersectedWithNonshootable: false,
    detectionClasses: {},
    confidence: 0,
    thinningState: 0,
    globalPos: 0,
    scoreState: undefined,
    doo: 0,
    distancePerspectivesCount: 0,
    sizeCategoryIndex: 0,
    speculativeAllowed: false,
    protectedByTraj: false,
    thresholds: undefined,
  };
}

export const TrajectorySnapshot: MessageFns<TrajectorySnapshot> = {
  encode(message: TrajectorySnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.killStatus !== 0) {
      writer.uint32(16).int32(message.killStatus);
    }
    if (message.isWeed !== false) {
      writer.uint32(24).bool(message.isWeed);
    }
    if (message.xMm !== 0) {
      writer.uint32(33).double(message.xMm);
    }
    if (message.yMm !== 0) {
      writer.uint32(41).double(message.yMm);
    }
    if (message.zMm !== 0) {
      writer.uint32(49).double(message.zMm);
    }
    if (message.score !== undefined) {
      TrajectoryScore.encode(message.score, writer.uint32(58).fork()).join();
    }
    if (message.invalidScore !== undefined) {
      writer.uint32(64).int32(message.invalidScore);
    }
    if (message.radiusMm !== 0) {
      writer.uint32(73).double(message.radiusMm);
    }
    if (message.category !== "") {
      writer.uint32(82).string(message.category);
    }
    if (message.shootTimeRequestedMs !== 0) {
      writer.uint32(88).uint64(message.shootTimeRequestedMs);
    }
    if (message.shootTimeActualMs !== 0) {
      writer.uint32(96).uint64(message.shootTimeActualMs);
    }
    if (message.markedForThinning !== false) {
      writer.uint32(104).bool(message.markedForThinning);
    }
    if (message.trackerId !== 0) {
      writer.uint32(112).uint32(message.trackerId);
    }
    if (message.duplicateStatus !== 0) {
      writer.uint32(120).int32(message.duplicateStatus);
    }
    if (message.duplicateTrajectoryId !== 0) {
      writer.uint32(128).uint32(message.duplicateTrajectoryId);
    }
    writer.uint32(138).fork();
    for (const v of message.assignedLasers) {
      writer.uint32(v);
    }
    writer.join();
    if (message.outOfBand !== false) {
      writer.uint32(144).bool(message.outOfBand);
    }
    if (message.intersectedWithNonshootable !== false) {
      writer.uint32(152).bool(message.intersectedWithNonshootable);
    }
    Object.entries(message.detectionClasses).forEach(([key, value]) => {
      TrajectorySnapshot_DetectionClassesEntry.encode({ key: key as any, value }, writer.uint32(162).fork()).join();
    });
    if (message.confidence !== 0) {
      writer.uint32(169).double(message.confidence);
    }
    if (message.thinningState !== 0) {
      writer.uint32(176).int32(message.thinningState);
    }
    if (message.globalPos !== 0) {
      writer.uint32(185).double(message.globalPos);
    }
    if (message.scoreState !== undefined) {
      ScoreState.encode(message.scoreState, writer.uint32(194).fork()).join();
    }
    if (message.doo !== 0) {
      writer.uint32(205).float(message.doo);
    }
    if (message.distancePerspectivesCount !== 0) {
      writer.uint32(208).uint32(message.distancePerspectivesCount);
    }
    if (message.sizeCategoryIndex !== 0) {
      writer.uint32(216).int32(message.sizeCategoryIndex);
    }
    if (message.speculativeAllowed !== false) {
      writer.uint32(224).bool(message.speculativeAllowed);
    }
    if (message.protectedByTraj !== false) {
      writer.uint32(232).bool(message.protectedByTraj);
    }
    if (message.thresholds !== undefined) {
      Thresholds.encode(message.thresholds, writer.uint32(242).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectorySnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectorySnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.killStatus = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isWeed = reader.bool();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.xMm = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.yMm = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.zMm = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.score = TrajectoryScore.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.invalidScore = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.radiusMm = reader.double();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.category = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.shootTimeRequestedMs = longToNumber(reader.uint64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.shootTimeActualMs = longToNumber(reader.uint64());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.markedForThinning = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.trackerId = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.duplicateStatus = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.duplicateTrajectoryId = reader.uint32();
          continue;
        case 17:
          if (tag === 136) {
            message.assignedLasers.push(reader.uint32());

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.assignedLasers.push(reader.uint32());
            }

            continue;
          }

          break;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.outOfBand = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.intersectedWithNonshootable = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          const entry20 = TrajectorySnapshot_DetectionClassesEntry.decode(reader, reader.uint32());
          if (entry20.value !== undefined) {
            message.detectionClasses[entry20.key] = entry20.value;
          }
          continue;
        case 21:
          if (tag !== 169) {
            break;
          }

          message.confidence = reader.double();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.thinningState = reader.int32() as any;
          continue;
        case 23:
          if (tag !== 185) {
            break;
          }

          message.globalPos = reader.double();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.scoreState = ScoreState.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 205) {
            break;
          }

          message.doo = reader.float();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.distancePerspectivesCount = reader.uint32();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.sizeCategoryIndex = reader.int32();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.speculativeAllowed = reader.bool();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.protectedByTraj = reader.bool();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.thresholds = Thresholds.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectorySnapshot {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      killStatus: isSet(object.killStatus) ? killStatusFromJSON(object.killStatus) : 0,
      isWeed: isSet(object.isWeed) ? globalThis.Boolean(object.isWeed) : false,
      xMm: isSet(object.xMm) ? globalThis.Number(object.xMm) : 0,
      yMm: isSet(object.yMm) ? globalThis.Number(object.yMm) : 0,
      zMm: isSet(object.zMm) ? globalThis.Number(object.zMm) : 0,
      score: isSet(object.score) ? TrajectoryScore.fromJSON(object.score) : undefined,
      invalidScore: isSet(object.invalidScore) ? invalidScoreReasonFromJSON(object.invalidScore) : undefined,
      radiusMm: isSet(object.radiusMm) ? globalThis.Number(object.radiusMm) : 0,
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      shootTimeRequestedMs: isSet(object.shootTimeRequestedMs) ? globalThis.Number(object.shootTimeRequestedMs) : 0,
      shootTimeActualMs: isSet(object.shootTimeActualMs) ? globalThis.Number(object.shootTimeActualMs) : 0,
      markedForThinning: isSet(object.markedForThinning) ? globalThis.Boolean(object.markedForThinning) : false,
      trackerId: isSet(object.trackerId) ? globalThis.Number(object.trackerId) : 0,
      duplicateStatus: isSet(object.duplicateStatus) ? duplicateStatusFromJSON(object.duplicateStatus) : 0,
      duplicateTrajectoryId: isSet(object.duplicateTrajectoryId) ? globalThis.Number(object.duplicateTrajectoryId) : 0,
      assignedLasers: globalThis.Array.isArray(object?.assignedLasers)
        ? object.assignedLasers.map((e: any) => globalThis.Number(e))
        : [],
      outOfBand: isSet(object.outOfBand) ? globalThis.Boolean(object.outOfBand) : false,
      intersectedWithNonshootable: isSet(object.intersectedWithNonshootable)
        ? globalThis.Boolean(object.intersectedWithNonshootable)
        : false,
      detectionClasses: isObject(object.detectionClasses)
        ? Object.entries(object.detectionClasses).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      thinningState: isSet(object.thinningState) ? thinningStateFromJSON(object.thinningState) : 0,
      globalPos: isSet(object.globalPos) ? globalThis.Number(object.globalPos) : 0,
      scoreState: isSet(object.scoreState) ? ScoreState.fromJSON(object.scoreState) : undefined,
      doo: isSet(object.doo) ? globalThis.Number(object.doo) : 0,
      distancePerspectivesCount: isSet(object.distancePerspectivesCount)
        ? globalThis.Number(object.distancePerspectivesCount)
        : 0,
      sizeCategoryIndex: isSet(object.sizeCategoryIndex) ? globalThis.Number(object.sizeCategoryIndex) : 0,
      speculativeAllowed: isSet(object.speculativeAllowed) ? globalThis.Boolean(object.speculativeAllowed) : false,
      protectedByTraj: isSet(object.protectedByTraj) ? globalThis.Boolean(object.protectedByTraj) : false,
      thresholds: isSet(object.thresholds) ? Thresholds.fromJSON(object.thresholds) : undefined,
    };
  },

  toJSON(message: TrajectorySnapshot): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.killStatus !== 0) {
      obj.killStatus = killStatusToJSON(message.killStatus);
    }
    if (message.isWeed !== false) {
      obj.isWeed = message.isWeed;
    }
    if (message.xMm !== 0) {
      obj.xMm = message.xMm;
    }
    if (message.yMm !== 0) {
      obj.yMm = message.yMm;
    }
    if (message.zMm !== 0) {
      obj.zMm = message.zMm;
    }
    if (message.score !== undefined) {
      obj.score = TrajectoryScore.toJSON(message.score);
    }
    if (message.invalidScore !== undefined) {
      obj.invalidScore = invalidScoreReasonToJSON(message.invalidScore);
    }
    if (message.radiusMm !== 0) {
      obj.radiusMm = message.radiusMm;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.shootTimeRequestedMs !== 0) {
      obj.shootTimeRequestedMs = Math.round(message.shootTimeRequestedMs);
    }
    if (message.shootTimeActualMs !== 0) {
      obj.shootTimeActualMs = Math.round(message.shootTimeActualMs);
    }
    if (message.markedForThinning !== false) {
      obj.markedForThinning = message.markedForThinning;
    }
    if (message.trackerId !== 0) {
      obj.trackerId = Math.round(message.trackerId);
    }
    if (message.duplicateStatus !== 0) {
      obj.duplicateStatus = duplicateStatusToJSON(message.duplicateStatus);
    }
    if (message.duplicateTrajectoryId !== 0) {
      obj.duplicateTrajectoryId = Math.round(message.duplicateTrajectoryId);
    }
    if (message.assignedLasers?.length) {
      obj.assignedLasers = message.assignedLasers.map((e) => Math.round(e));
    }
    if (message.outOfBand !== false) {
      obj.outOfBand = message.outOfBand;
    }
    if (message.intersectedWithNonshootable !== false) {
      obj.intersectedWithNonshootable = message.intersectedWithNonshootable;
    }
    if (message.detectionClasses) {
      const entries = Object.entries(message.detectionClasses);
      if (entries.length > 0) {
        obj.detectionClasses = {};
        entries.forEach(([k, v]) => {
          obj.detectionClasses[k] = v;
        });
      }
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.thinningState !== 0) {
      obj.thinningState = thinningStateToJSON(message.thinningState);
    }
    if (message.globalPos !== 0) {
      obj.globalPos = message.globalPos;
    }
    if (message.scoreState !== undefined) {
      obj.scoreState = ScoreState.toJSON(message.scoreState);
    }
    if (message.doo !== 0) {
      obj.doo = message.doo;
    }
    if (message.distancePerspectivesCount !== 0) {
      obj.distancePerspectivesCount = Math.round(message.distancePerspectivesCount);
    }
    if (message.sizeCategoryIndex !== 0) {
      obj.sizeCategoryIndex = Math.round(message.sizeCategoryIndex);
    }
    if (message.speculativeAllowed !== false) {
      obj.speculativeAllowed = message.speculativeAllowed;
    }
    if (message.protectedByTraj !== false) {
      obj.protectedByTraj = message.protectedByTraj;
    }
    if (message.thresholds !== undefined) {
      obj.thresholds = Thresholds.toJSON(message.thresholds);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectorySnapshot>, I>>(base?: I): TrajectorySnapshot {
    return TrajectorySnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectorySnapshot>, I>>(object: I): TrajectorySnapshot {
    const message = createBaseTrajectorySnapshot();
    message.id = object.id ?? 0;
    message.killStatus = object.killStatus ?? 0;
    message.isWeed = object.isWeed ?? false;
    message.xMm = object.xMm ?? 0;
    message.yMm = object.yMm ?? 0;
    message.zMm = object.zMm ?? 0;
    message.score = (object.score !== undefined && object.score !== null)
      ? TrajectoryScore.fromPartial(object.score)
      : undefined;
    message.invalidScore = object.invalidScore ?? undefined;
    message.radiusMm = object.radiusMm ?? 0;
    message.category = object.category ?? "";
    message.shootTimeRequestedMs = object.shootTimeRequestedMs ?? 0;
    message.shootTimeActualMs = object.shootTimeActualMs ?? 0;
    message.markedForThinning = object.markedForThinning ?? false;
    message.trackerId = object.trackerId ?? 0;
    message.duplicateStatus = object.duplicateStatus ?? 0;
    message.duplicateTrajectoryId = object.duplicateTrajectoryId ?? 0;
    message.assignedLasers = object.assignedLasers?.map((e) => e) || [];
    message.outOfBand = object.outOfBand ?? false;
    message.intersectedWithNonshootable = object.intersectedWithNonshootable ?? false;
    message.detectionClasses = Object.entries(object.detectionClasses ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.confidence = object.confidence ?? 0;
    message.thinningState = object.thinningState ?? 0;
    message.globalPos = object.globalPos ?? 0;
    message.scoreState = (object.scoreState !== undefined && object.scoreState !== null)
      ? ScoreState.fromPartial(object.scoreState)
      : undefined;
    message.doo = object.doo ?? 0;
    message.distancePerspectivesCount = object.distancePerspectivesCount ?? 0;
    message.sizeCategoryIndex = object.sizeCategoryIndex ?? 0;
    message.speculativeAllowed = object.speculativeAllowed ?? false;
    message.protectedByTraj = object.protectedByTraj ?? false;
    message.thresholds = (object.thresholds !== undefined && object.thresholds !== null)
      ? Thresholds.fromPartial(object.thresholds)
      : undefined;
    return message;
  },
};

function createBaseTrajectorySnapshot_DetectionClassesEntry(): TrajectorySnapshot_DetectionClassesEntry {
  return { key: "", value: 0 };
}

export const TrajectorySnapshot_DetectionClassesEntry: MessageFns<TrajectorySnapshot_DetectionClassesEntry> = {
  encode(message: TrajectorySnapshot_DetectionClassesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrajectorySnapshot_DetectionClassesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrajectorySnapshot_DetectionClassesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrajectorySnapshot_DetectionClassesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: TrajectorySnapshot_DetectionClassesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrajectorySnapshot_DetectionClassesEntry>, I>>(
    base?: I,
  ): TrajectorySnapshot_DetectionClassesEntry {
    return TrajectorySnapshot_DetectionClassesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrajectorySnapshot_DetectionClassesEntry>, I>>(
    object: I,
  ): TrajectorySnapshot_DetectionClassesEntry {
    const message = createBaseTrajectorySnapshot_DetectionClassesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseBandDefinition(): BandDefinition {
  return { offsetMm: 0, widthMm: 0, id: 0 };
}

export const BandDefinition: MessageFns<BandDefinition> = {
  encode(message: BandDefinition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.offsetMm !== 0) {
      writer.uint32(13).float(message.offsetMm);
    }
    if (message.widthMm !== 0) {
      writer.uint32(21).float(message.widthMm);
    }
    if (message.id !== 0) {
      writer.uint32(24).int32(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BandDefinition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBandDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.offsetMm = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.widthMm = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BandDefinition {
    return {
      offsetMm: isSet(object.offsetMm) ? globalThis.Number(object.offsetMm) : 0,
      widthMm: isSet(object.widthMm) ? globalThis.Number(object.widthMm) : 0,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
    };
  },

  toJSON(message: BandDefinition): unknown {
    const obj: any = {};
    if (message.offsetMm !== 0) {
      obj.offsetMm = message.offsetMm;
    }
    if (message.widthMm !== 0) {
      obj.widthMm = message.widthMm;
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BandDefinition>, I>>(base?: I): BandDefinition {
    return BandDefinition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BandDefinition>, I>>(object: I): BandDefinition {
    const message = createBaseBandDefinition();
    message.offsetMm = object.offsetMm ?? 0;
    message.widthMm = object.widthMm ?? 0;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseDiagnosticsSnapshot(): DiagnosticsSnapshot {
  return { timestampMs: 0, trajectories: [], bands: [], killBoxes: [] };
}

export const DiagnosticsSnapshot: MessageFns<DiagnosticsSnapshot> = {
  encode(message: DiagnosticsSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampMs !== 0) {
      writer.uint32(8).uint64(message.timestampMs);
    }
    for (const v of message.trajectories) {
      TrajectorySnapshot.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.bands) {
      BandDefinition.encode(v!, writer.uint32(26).fork()).join();
    }
    for (const v of message.killBoxes) {
      KillBox.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DiagnosticsSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiagnosticsSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampMs = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trajectories.push(TrajectorySnapshot.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bands.push(BandDefinition.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.killBoxes.push(KillBox.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiagnosticsSnapshot {
    return {
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      trajectories: globalThis.Array.isArray(object?.trajectories)
        ? object.trajectories.map((e: any) => TrajectorySnapshot.fromJSON(e))
        : [],
      bands: globalThis.Array.isArray(object?.bands) ? object.bands.map((e: any) => BandDefinition.fromJSON(e)) : [],
      killBoxes: globalThis.Array.isArray(object?.killBoxes)
        ? object.killBoxes.map((e: any) => KillBox.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DiagnosticsSnapshot): unknown {
    const obj: any = {};
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.trajectories?.length) {
      obj.trajectories = message.trajectories.map((e) => TrajectorySnapshot.toJSON(e));
    }
    if (message.bands?.length) {
      obj.bands = message.bands.map((e) => BandDefinition.toJSON(e));
    }
    if (message.killBoxes?.length) {
      obj.killBoxes = message.killBoxes.map((e) => KillBox.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiagnosticsSnapshot>, I>>(base?: I): DiagnosticsSnapshot {
    return DiagnosticsSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiagnosticsSnapshot>, I>>(object: I): DiagnosticsSnapshot {
    const message = createBaseDiagnosticsSnapshot();
    message.timestampMs = object.timestampMs ?? 0;
    message.trajectories = object.trajectories?.map((e) => TrajectorySnapshot.fromPartial(e)) || [];
    message.bands = object.bands?.map((e) => BandDefinition.fromPartial(e)) || [];
    message.killBoxes = object.killBoxes?.map((e) => KillBox.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRecordDiagnosticsRequest(): RecordDiagnosticsRequest {
  return { ttlSec: 0, cropImagesPerSec: 0, weedImagesPerSec: 0 };
}

export const RecordDiagnosticsRequest: MessageFns<RecordDiagnosticsRequest> = {
  encode(message: RecordDiagnosticsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ttlSec !== 0) {
      writer.uint32(8).uint32(message.ttlSec);
    }
    if (message.cropImagesPerSec !== 0) {
      writer.uint32(21).float(message.cropImagesPerSec);
    }
    if (message.weedImagesPerSec !== 0) {
      writer.uint32(29).float(message.weedImagesPerSec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RecordDiagnosticsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordDiagnosticsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ttlSec = reader.uint32();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.cropImagesPerSec = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.weedImagesPerSec = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordDiagnosticsRequest {
    return {
      ttlSec: isSet(object.ttlSec) ? globalThis.Number(object.ttlSec) : 0,
      cropImagesPerSec: isSet(object.cropImagesPerSec) ? globalThis.Number(object.cropImagesPerSec) : 0,
      weedImagesPerSec: isSet(object.weedImagesPerSec) ? globalThis.Number(object.weedImagesPerSec) : 0,
    };
  },

  toJSON(message: RecordDiagnosticsRequest): unknown {
    const obj: any = {};
    if (message.ttlSec !== 0) {
      obj.ttlSec = Math.round(message.ttlSec);
    }
    if (message.cropImagesPerSec !== 0) {
      obj.cropImagesPerSec = message.cropImagesPerSec;
    }
    if (message.weedImagesPerSec !== 0) {
      obj.weedImagesPerSec = message.weedImagesPerSec;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordDiagnosticsRequest>, I>>(base?: I): RecordDiagnosticsRequest {
    return RecordDiagnosticsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordDiagnosticsRequest>, I>>(object: I): RecordDiagnosticsRequest {
    const message = createBaseRecordDiagnosticsRequest();
    message.ttlSec = object.ttlSec ?? 0;
    message.cropImagesPerSec = object.cropImagesPerSec ?? 0;
    message.weedImagesPerSec = object.weedImagesPerSec ?? 0;
    return message;
  },
};

function createBaseGetRecordingStatusResponse(): GetRecordingStatusResponse {
  return { status: 0 };
}

export const GetRecordingStatusResponse: MessageFns<GetRecordingStatusResponse> = {
  encode(message: GetRecordingStatusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRecordingStatusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecordingStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecordingStatusResponse {
    return { status: isSet(object.status) ? recordingStatusFromJSON(object.status) : 0 };
  },

  toJSON(message: GetRecordingStatusResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = recordingStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRecordingStatusResponse>, I>>(base?: I): GetRecordingStatusResponse {
    return GetRecordingStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRecordingStatusResponse>, I>>(object: I): GetRecordingStatusResponse {
    const message = createBaseGetRecordingStatusResponse();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseStartSavingCropLineDetectionReplayRequest(): StartSavingCropLineDetectionReplayRequest {
  return { filename: "", ttlMs: 0 };
}

export const StartSavingCropLineDetectionReplayRequest: MessageFns<StartSavingCropLineDetectionReplayRequest> = {
  encode(message: StartSavingCropLineDetectionReplayRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.filename !== "") {
      writer.uint32(10).string(message.filename);
    }
    if (message.ttlMs !== 0) {
      writer.uint32(16).uint32(message.ttlMs);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StartSavingCropLineDetectionReplayRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartSavingCropLineDetectionReplayRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ttlMs = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartSavingCropLineDetectionReplayRequest {
    return {
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      ttlMs: isSet(object.ttlMs) ? globalThis.Number(object.ttlMs) : 0,
    };
  },

  toJSON(message: StartSavingCropLineDetectionReplayRequest): unknown {
    const obj: any = {};
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.ttlMs !== 0) {
      obj.ttlMs = Math.round(message.ttlMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartSavingCropLineDetectionReplayRequest>, I>>(
    base?: I,
  ): StartSavingCropLineDetectionReplayRequest {
    return StartSavingCropLineDetectionReplayRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartSavingCropLineDetectionReplayRequest>, I>>(
    object: I,
  ): StartSavingCropLineDetectionReplayRequest {
    const message = createBaseStartSavingCropLineDetectionReplayRequest();
    message.filename = object.filename ?? "";
    message.ttlMs = object.ttlMs ?? 0;
    return message;
  },
};

function createBaseRecordAimbotInputRequest(): RecordAimbotInputRequest {
  return { name: "", ttlMs: 0, rotaryTicks: undefined, deepweed: undefined, laneHeights: undefined };
}

export const RecordAimbotInputRequest: MessageFns<RecordAimbotInputRequest> = {
  encode(message: RecordAimbotInputRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.ttlMs !== 0) {
      writer.uint32(16).uint32(message.ttlMs);
    }
    if (message.rotaryTicks !== undefined) {
      writer.uint32(24).bool(message.rotaryTicks);
    }
    if (message.deepweed !== undefined) {
      writer.uint32(32).bool(message.deepweed);
    }
    if (message.laneHeights !== undefined) {
      writer.uint32(40).bool(message.laneHeights);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RecordAimbotInputRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordAimbotInputRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ttlMs = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rotaryTicks = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deepweed = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.laneHeights = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordAimbotInputRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ttlMs: isSet(object.ttlMs) ? globalThis.Number(object.ttlMs) : 0,
      rotaryTicks: isSet(object.rotaryTicks) ? globalThis.Boolean(object.rotaryTicks) : undefined,
      deepweed: isSet(object.deepweed) ? globalThis.Boolean(object.deepweed) : undefined,
      laneHeights: isSet(object.laneHeights) ? globalThis.Boolean(object.laneHeights) : undefined,
    };
  },

  toJSON(message: RecordAimbotInputRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ttlMs !== 0) {
      obj.ttlMs = Math.round(message.ttlMs);
    }
    if (message.rotaryTicks !== undefined) {
      obj.rotaryTicks = message.rotaryTicks;
    }
    if (message.deepweed !== undefined) {
      obj.deepweed = message.deepweed;
    }
    if (message.laneHeights !== undefined) {
      obj.laneHeights = message.laneHeights;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordAimbotInputRequest>, I>>(base?: I): RecordAimbotInputRequest {
    return RecordAimbotInputRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordAimbotInputRequest>, I>>(object: I): RecordAimbotInputRequest {
    const message = createBaseRecordAimbotInputRequest();
    message.name = object.name ?? "";
    message.ttlMs = object.ttlMs ?? 0;
    message.rotaryTicks = object.rotaryTicks ?? undefined;
    message.deepweed = object.deepweed ?? undefined;
    message.laneHeights = object.laneHeights ?? undefined;
    return message;
  },
};

function createBaseConclusionCount(): ConclusionCount {
  return { type: 0, count: 0 };
}

export const ConclusionCount: MessageFns<ConclusionCount> = {
  encode(message: ConclusionCount, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConclusionCount {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConclusionCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConclusionCount {
    return {
      type: isSet(object.type) ? conclusionTypeFromJSON(object.type) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: ConclusionCount): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = conclusionTypeToJSON(message.type);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConclusionCount>, I>>(base?: I): ConclusionCount {
    return ConclusionCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConclusionCount>, I>>(object: I): ConclusionCount {
    const message = createBaseConclusionCount();
    message.type = object.type ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseConclusionCounter(): ConclusionCounter {
  return { counts: [] };
}

export const ConclusionCounter: MessageFns<ConclusionCounter> = {
  encode(message: ConclusionCounter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.counts) {
      ConclusionCount.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConclusionCounter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConclusionCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.counts.push(ConclusionCount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConclusionCounter {
    return {
      counts: globalThis.Array.isArray(object?.counts)
        ? object.counts.map((e: any) => ConclusionCount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ConclusionCounter): unknown {
    const obj: any = {};
    if (message.counts?.length) {
      obj.counts = message.counts.map((e) => ConclusionCount.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConclusionCounter>, I>>(base?: I): ConclusionCounter {
    return ConclusionCounter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConclusionCounter>, I>>(object: I): ConclusionCounter {
    const message = createBaseConclusionCounter();
    message.counts = object.counts?.map((e) => ConclusionCount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBandDefinitions(): BandDefinitions {
  return { bands: [], bandingEnabled: false, rowHasBandsDefined: false };
}

export const BandDefinitions: MessageFns<BandDefinitions> = {
  encode(message: BandDefinitions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.bands) {
      BandDefinition.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.bandingEnabled !== false) {
      writer.uint32(16).bool(message.bandingEnabled);
    }
    if (message.rowHasBandsDefined !== false) {
      writer.uint32(24).bool(message.rowHasBandsDefined);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BandDefinitions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBandDefinitions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bands.push(BandDefinition.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bandingEnabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rowHasBandsDefined = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BandDefinitions {
    return {
      bands: globalThis.Array.isArray(object?.bands) ? object.bands.map((e: any) => BandDefinition.fromJSON(e)) : [],
      bandingEnabled: isSet(object.bandingEnabled) ? globalThis.Boolean(object.bandingEnabled) : false,
      rowHasBandsDefined: isSet(object.rowHasBandsDefined) ? globalThis.Boolean(object.rowHasBandsDefined) : false,
    };
  },

  toJSON(message: BandDefinitions): unknown {
    const obj: any = {};
    if (message.bands?.length) {
      obj.bands = message.bands.map((e) => BandDefinition.toJSON(e));
    }
    if (message.bandingEnabled !== false) {
      obj.bandingEnabled = message.bandingEnabled;
    }
    if (message.rowHasBandsDefined !== false) {
      obj.rowHasBandsDefined = message.rowHasBandsDefined;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BandDefinitions>, I>>(base?: I): BandDefinitions {
    return BandDefinitions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BandDefinitions>, I>>(object: I): BandDefinitions {
    const message = createBaseBandDefinitions();
    message.bands = object.bands?.map((e) => BandDefinition.fromPartial(e)) || [];
    message.bandingEnabled = object.bandingEnabled ?? false;
    message.rowHasBandsDefined = object.rowHasBandsDefined ?? false;
    return message;
  },
};

function createBasePlantCaptchaStatusResponse(): PlantCaptchaStatusResponse {
  return { status: 0, totalImages: 0, imagesTaken: 0, metadataTaken: 0 };
}

export const PlantCaptchaStatusResponse: MessageFns<PlantCaptchaStatusResponse> = {
  encode(message: PlantCaptchaStatusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.totalImages !== 0) {
      writer.uint32(16).int32(message.totalImages);
    }
    if (message.imagesTaken !== 0) {
      writer.uint32(24).int32(message.imagesTaken);
    }
    if (message.metadataTaken !== 0) {
      writer.uint32(32).int32(message.metadataTaken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlantCaptchaStatusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlantCaptchaStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalImages = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.imagesTaken = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.metadataTaken = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlantCaptchaStatusResponse {
    return {
      status: isSet(object.status) ? plantCaptchaStatusFromJSON(object.status) : 0,
      totalImages: isSet(object.totalImages) ? globalThis.Number(object.totalImages) : 0,
      imagesTaken: isSet(object.imagesTaken) ? globalThis.Number(object.imagesTaken) : 0,
      metadataTaken: isSet(object.metadataTaken) ? globalThis.Number(object.metadataTaken) : 0,
    };
  },

  toJSON(message: PlantCaptchaStatusResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = plantCaptchaStatusToJSON(message.status);
    }
    if (message.totalImages !== 0) {
      obj.totalImages = Math.round(message.totalImages);
    }
    if (message.imagesTaken !== 0) {
      obj.imagesTaken = Math.round(message.imagesTaken);
    }
    if (message.metadataTaken !== 0) {
      obj.metadataTaken = Math.round(message.metadataTaken);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlantCaptchaStatusResponse>, I>>(base?: I): PlantCaptchaStatusResponse {
    return PlantCaptchaStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlantCaptchaStatusResponse>, I>>(object: I): PlantCaptchaStatusResponse {
    const message = createBasePlantCaptchaStatusResponse();
    message.status = object.status ?? 0;
    message.totalImages = object.totalImages ?? 0;
    message.imagesTaken = object.imagesTaken ?? 0;
    message.metadataTaken = object.metadataTaken ?? 0;
    return message;
  },
};

function createBaseEmbedding(): Embedding {
  return { elements: [] };
}

export const Embedding: MessageFns<Embedding> = {
  encode(message: Embedding, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.elements) {
      writer.float(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Embedding {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmbedding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 13) {
            message.elements.push(reader.float());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.elements.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Embedding {
    return {
      elements: globalThis.Array.isArray(object?.elements) ? object.elements.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: Embedding): unknown {
    const obj: any = {};
    if (message.elements?.length) {
      obj.elements = message.elements;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Embedding>, I>>(base?: I): Embedding {
    return Embedding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Embedding>, I>>(object: I): Embedding {
    const message = createBaseEmbedding();
    message.elements = object.elements?.map((e) => e) || [];
    return message;
  },
};

function createBasePlantCaptchaItemMetadata(): PlantCaptchaItemMetadata {
  return {
    confidence: 0,
    xPx: 0,
    yPx: 0,
    xMm: 0,
    yMm: 0,
    zMm: 0,
    sizeMm: 0,
    categories: {},
    doo: 0,
    isWeed: false,
    intersectedWithNonshootable: false,
    confidenceHistory: [],
    isInBand: false,
    id: "",
    sizePx: 0,
    shootTimeMs: 0,
    weedConfidenceHistory: [],
    cropConfidenceHistory: [],
    sizeCategoryIndex: 0,
    weedCategories: {},
    embeddingHistory: [],
    initialLabel: 0,
  };
}

export const PlantCaptchaItemMetadata: MessageFns<PlantCaptchaItemMetadata> = {
  encode(message: PlantCaptchaItemMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.confidence !== 0) {
      writer.uint32(13).float(message.confidence);
    }
    if (message.xPx !== 0) {
      writer.uint32(16).int32(message.xPx);
    }
    if (message.yPx !== 0) {
      writer.uint32(24).int32(message.yPx);
    }
    if (message.xMm !== 0) {
      writer.uint32(33).double(message.xMm);
    }
    if (message.yMm !== 0) {
      writer.uint32(41).double(message.yMm);
    }
    if (message.zMm !== 0) {
      writer.uint32(49).double(message.zMm);
    }
    if (message.sizeMm !== 0) {
      writer.uint32(61).float(message.sizeMm);
    }
    Object.entries(message.categories).forEach(([key, value]) => {
      PlantCaptchaItemMetadata_CategoriesEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.doo !== 0) {
      writer.uint32(77).float(message.doo);
    }
    if (message.isWeed !== false) {
      writer.uint32(80).bool(message.isWeed);
    }
    if (message.intersectedWithNonshootable !== false) {
      writer.uint32(88).bool(message.intersectedWithNonshootable);
    }
    writer.uint32(98).fork();
    for (const v of message.confidenceHistory) {
      writer.float(v);
    }
    writer.join();
    if (message.isInBand !== false) {
      writer.uint32(104).bool(message.isInBand);
    }
    if (message.id !== "") {
      writer.uint32(114).string(message.id);
    }
    if (message.sizePx !== 0) {
      writer.uint32(125).float(message.sizePx);
    }
    if (message.shootTimeMs !== 0) {
      writer.uint32(128).uint32(message.shootTimeMs);
    }
    writer.uint32(138).fork();
    for (const v of message.weedConfidenceHistory) {
      writer.float(v);
    }
    writer.join();
    writer.uint32(146).fork();
    for (const v of message.cropConfidenceHistory) {
      writer.float(v);
    }
    writer.join();
    if (message.sizeCategoryIndex !== 0) {
      writer.uint32(152).int32(message.sizeCategoryIndex);
    }
    Object.entries(message.weedCategories).forEach(([key, value]) => {
      PlantCaptchaItemMetadata_WeedCategoriesEntry.encode({ key: key as any, value }, writer.uint32(162).fork()).join();
    });
    for (const v of message.embeddingHistory) {
      Embedding.encode(v!, writer.uint32(170).fork()).join();
    }
    if (message.initialLabel !== 0) {
      writer.uint32(176).int32(message.initialLabel);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlantCaptchaItemMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlantCaptchaItemMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.xPx = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.yPx = reader.int32();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.xMm = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.yMm = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.zMm = reader.double();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.sizeMm = reader.float();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = PlantCaptchaItemMetadata_CategoriesEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.categories[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.doo = reader.float();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isWeed = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.intersectedWithNonshootable = reader.bool();
          continue;
        case 12:
          if (tag === 101) {
            message.confidenceHistory.push(reader.float());

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.confidenceHistory.push(reader.float());
            }

            continue;
          }

          break;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isInBand = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.id = reader.string();
          continue;
        case 15:
          if (tag !== 125) {
            break;
          }

          message.sizePx = reader.float();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.shootTimeMs = reader.uint32();
          continue;
        case 17:
          if (tag === 141) {
            message.weedConfidenceHistory.push(reader.float());

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.weedConfidenceHistory.push(reader.float());
            }

            continue;
          }

          break;
        case 18:
          if (tag === 149) {
            message.cropConfidenceHistory.push(reader.float());

            continue;
          }

          if (tag === 146) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cropConfidenceHistory.push(reader.float());
            }

            continue;
          }

          break;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.sizeCategoryIndex = reader.int32();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          const entry20 = PlantCaptchaItemMetadata_WeedCategoriesEntry.decode(reader, reader.uint32());
          if (entry20.value !== undefined) {
            message.weedCategories[entry20.key] = entry20.value;
          }
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.embeddingHistory.push(Embedding.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.initialLabel = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlantCaptchaItemMetadata {
    return {
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      xPx: isSet(object.xPx) ? globalThis.Number(object.xPx) : 0,
      yPx: isSet(object.yPx) ? globalThis.Number(object.yPx) : 0,
      xMm: isSet(object.xMm) ? globalThis.Number(object.xMm) : 0,
      yMm: isSet(object.yMm) ? globalThis.Number(object.yMm) : 0,
      zMm: isSet(object.zMm) ? globalThis.Number(object.zMm) : 0,
      sizeMm: isSet(object.sizeMm) ? globalThis.Number(object.sizeMm) : 0,
      categories: isObject(object.categories)
        ? Object.entries(object.categories).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      doo: isSet(object.doo) ? globalThis.Number(object.doo) : 0,
      isWeed: isSet(object.isWeed) ? globalThis.Boolean(object.isWeed) : false,
      intersectedWithNonshootable: isSet(object.intersectedWithNonshootable)
        ? globalThis.Boolean(object.intersectedWithNonshootable)
        : false,
      confidenceHistory: globalThis.Array.isArray(object?.confidenceHistory)
        ? object.confidenceHistory.map((e: any) => globalThis.Number(e))
        : [],
      isInBand: isSet(object.isInBand) ? globalThis.Boolean(object.isInBand) : false,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sizePx: isSet(object.sizePx) ? globalThis.Number(object.sizePx) : 0,
      shootTimeMs: isSet(object.shootTimeMs) ? globalThis.Number(object.shootTimeMs) : 0,
      weedConfidenceHistory: globalThis.Array.isArray(object?.weedConfidenceHistory)
        ? object.weedConfidenceHistory.map((e: any) => globalThis.Number(e))
        : [],
      cropConfidenceHistory: globalThis.Array.isArray(object?.cropConfidenceHistory)
        ? object.cropConfidenceHistory.map((e: any) => globalThis.Number(e))
        : [],
      sizeCategoryIndex: isSet(object.sizeCategoryIndex) ? globalThis.Number(object.sizeCategoryIndex) : 0,
      weedCategories: isObject(object.weedCategories)
        ? Object.entries(object.weedCategories).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      embeddingHistory: globalThis.Array.isArray(object?.embeddingHistory)
        ? object.embeddingHistory.map((e: any) => Embedding.fromJSON(e))
        : [],
      initialLabel: isSet(object.initialLabel) ? plantCaptchaUserPredictionFromJSON(object.initialLabel) : 0,
    };
  },

  toJSON(message: PlantCaptchaItemMetadata): unknown {
    const obj: any = {};
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.xPx !== 0) {
      obj.xPx = Math.round(message.xPx);
    }
    if (message.yPx !== 0) {
      obj.yPx = Math.round(message.yPx);
    }
    if (message.xMm !== 0) {
      obj.xMm = message.xMm;
    }
    if (message.yMm !== 0) {
      obj.yMm = message.yMm;
    }
    if (message.zMm !== 0) {
      obj.zMm = message.zMm;
    }
    if (message.sizeMm !== 0) {
      obj.sizeMm = message.sizeMm;
    }
    if (message.categories) {
      const entries = Object.entries(message.categories);
      if (entries.length > 0) {
        obj.categories = {};
        entries.forEach(([k, v]) => {
          obj.categories[k] = v;
        });
      }
    }
    if (message.doo !== 0) {
      obj.doo = message.doo;
    }
    if (message.isWeed !== false) {
      obj.isWeed = message.isWeed;
    }
    if (message.intersectedWithNonshootable !== false) {
      obj.intersectedWithNonshootable = message.intersectedWithNonshootable;
    }
    if (message.confidenceHistory?.length) {
      obj.confidenceHistory = message.confidenceHistory;
    }
    if (message.isInBand !== false) {
      obj.isInBand = message.isInBand;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sizePx !== 0) {
      obj.sizePx = message.sizePx;
    }
    if (message.shootTimeMs !== 0) {
      obj.shootTimeMs = Math.round(message.shootTimeMs);
    }
    if (message.weedConfidenceHistory?.length) {
      obj.weedConfidenceHistory = message.weedConfidenceHistory;
    }
    if (message.cropConfidenceHistory?.length) {
      obj.cropConfidenceHistory = message.cropConfidenceHistory;
    }
    if (message.sizeCategoryIndex !== 0) {
      obj.sizeCategoryIndex = Math.round(message.sizeCategoryIndex);
    }
    if (message.weedCategories) {
      const entries = Object.entries(message.weedCategories);
      if (entries.length > 0) {
        obj.weedCategories = {};
        entries.forEach(([k, v]) => {
          obj.weedCategories[k] = v;
        });
      }
    }
    if (message.embeddingHistory?.length) {
      obj.embeddingHistory = message.embeddingHistory.map((e) => Embedding.toJSON(e));
    }
    if (message.initialLabel !== 0) {
      obj.initialLabel = plantCaptchaUserPredictionToJSON(message.initialLabel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlantCaptchaItemMetadata>, I>>(base?: I): PlantCaptchaItemMetadata {
    return PlantCaptchaItemMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlantCaptchaItemMetadata>, I>>(object: I): PlantCaptchaItemMetadata {
    const message = createBasePlantCaptchaItemMetadata();
    message.confidence = object.confidence ?? 0;
    message.xPx = object.xPx ?? 0;
    message.yPx = object.yPx ?? 0;
    message.xMm = object.xMm ?? 0;
    message.yMm = object.yMm ?? 0;
    message.zMm = object.zMm ?? 0;
    message.sizeMm = object.sizeMm ?? 0;
    message.categories = Object.entries(object.categories ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.doo = object.doo ?? 0;
    message.isWeed = object.isWeed ?? false;
    message.intersectedWithNonshootable = object.intersectedWithNonshootable ?? false;
    message.confidenceHistory = object.confidenceHistory?.map((e) => e) || [];
    message.isInBand = object.isInBand ?? false;
    message.id = object.id ?? "";
    message.sizePx = object.sizePx ?? 0;
    message.shootTimeMs = object.shootTimeMs ?? 0;
    message.weedConfidenceHistory = object.weedConfidenceHistory?.map((e) => e) || [];
    message.cropConfidenceHistory = object.cropConfidenceHistory?.map((e) => e) || [];
    message.sizeCategoryIndex = object.sizeCategoryIndex ?? 0;
    message.weedCategories = Object.entries(object.weedCategories ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.embeddingHistory = object.embeddingHistory?.map((e) => Embedding.fromPartial(e)) || [];
    message.initialLabel = object.initialLabel ?? 0;
    return message;
  },
};

function createBasePlantCaptchaItemMetadata_CategoriesEntry(): PlantCaptchaItemMetadata_CategoriesEntry {
  return { key: "", value: 0 };
}

export const PlantCaptchaItemMetadata_CategoriesEntry: MessageFns<PlantCaptchaItemMetadata_CategoriesEntry> = {
  encode(message: PlantCaptchaItemMetadata_CategoriesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlantCaptchaItemMetadata_CategoriesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlantCaptchaItemMetadata_CategoriesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlantCaptchaItemMetadata_CategoriesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: PlantCaptchaItemMetadata_CategoriesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlantCaptchaItemMetadata_CategoriesEntry>, I>>(
    base?: I,
  ): PlantCaptchaItemMetadata_CategoriesEntry {
    return PlantCaptchaItemMetadata_CategoriesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlantCaptchaItemMetadata_CategoriesEntry>, I>>(
    object: I,
  ): PlantCaptchaItemMetadata_CategoriesEntry {
    const message = createBasePlantCaptchaItemMetadata_CategoriesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBasePlantCaptchaItemMetadata_WeedCategoriesEntry(): PlantCaptchaItemMetadata_WeedCategoriesEntry {
  return { key: "", value: 0 };
}

export const PlantCaptchaItemMetadata_WeedCategoriesEntry: MessageFns<PlantCaptchaItemMetadata_WeedCategoriesEntry> = {
  encode(
    message: PlantCaptchaItemMetadata_WeedCategoriesEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlantCaptchaItemMetadata_WeedCategoriesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlantCaptchaItemMetadata_WeedCategoriesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlantCaptchaItemMetadata_WeedCategoriesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: PlantCaptchaItemMetadata_WeedCategoriesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlantCaptchaItemMetadata_WeedCategoriesEntry>, I>>(
    base?: I,
  ): PlantCaptchaItemMetadata_WeedCategoriesEntry {
    return PlantCaptchaItemMetadata_WeedCategoriesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlantCaptchaItemMetadata_WeedCategoriesEntry>, I>>(
    object: I,
  ): PlantCaptchaItemMetadata_WeedCategoriesEntry {
    const message = createBasePlantCaptchaItemMetadata_WeedCategoriesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseGetTargetingEnabledRequest(): GetTargetingEnabledRequest {
  return {};
}

export const GetTargetingEnabledRequest: MessageFns<GetTargetingEnabledRequest> = {
  encode(_: GetTargetingEnabledRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTargetingEnabledRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTargetingEnabledRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTargetingEnabledRequest {
    return {};
  },

  toJSON(_: GetTargetingEnabledRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTargetingEnabledRequest>, I>>(base?: I): GetTargetingEnabledRequest {
    return GetTargetingEnabledRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTargetingEnabledRequest>, I>>(_: I): GetTargetingEnabledRequest {
    const message = createBaseGetTargetingEnabledRequest();
    return message;
  },
};

function createBaseGetTargetingEnabledResponse(): GetTargetingEnabledResponse {
  return { enabled: false };
}

export const GetTargetingEnabledResponse: MessageFns<GetTargetingEnabledResponse> = {
  encode(message: GetTargetingEnabledResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.enabled !== false) {
      writer.uint32(8).bool(message.enabled);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTargetingEnabledResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTargetingEnabledResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTargetingEnabledResponse {
    return { enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false };
  },

  toJSON(message: GetTargetingEnabledResponse): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTargetingEnabledResponse>, I>>(base?: I): GetTargetingEnabledResponse {
    return GetTargetingEnabledResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTargetingEnabledResponse>, I>>(object: I): GetTargetingEnabledResponse {
    const message = createBaseGetTargetingEnabledResponse();
    message.enabled = object.enabled ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
