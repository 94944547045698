import { AdvancedAlmanac } from "./AdvancedAlmanac";
import { Alert, Button, IconButton, Typography } from "@mui/material";
import { AlmanacConfig } from "protos/almanac/almanac";
import { BasicAlmanac } from "./BasicAlmanac";
import { buildPermission } from "portal/utils/auth";
import { ConfigCrop } from "protos/portal/configs";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { cropToConfigCrop, getCrops, SortedCrops } from "portal/utils/crops";
import { EditableTypography } from "../EditableTypography";
import { isUndefined } from "portal/utils/identity";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "portal/components/Loading";
import { LOCALSTORAGE_ALMANAC_MODE } from "portal/utils/localStorage";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RED_BUTTON, WHITE_BUTTON } from "portal/utils/theme";
import { skipToken } from "@reduxjs/toolkit/query";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "../auth/WithAuthorizationRequired";
import {
  useDeleteAlmanacMutation,
  useDeleteGlobalAlmanacMutation,
  useGetAlmanacQuery,
  useGetConfigQuery,
  useGetGlobalAlmanacQuery,
  useGetRobotQuery,
  useListCropsQuery,
  useListRobotCropsQuery,
  useSetAlmanacMutation,
  useSetGlobalAlmanacMutation,
} from "portal/state/portalApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, useMemo, useState } from "react";

interface Props {
  adminEditing?: boolean;
  parentLink?: string;
  serial?: string;
  uuid: string;
  useGetQuery: typeof useGetAlmanacQuery | typeof useGetGlobalAlmanacQuery;
  useDeleteMutation:
    | typeof useDeleteAlmanacMutation
    | typeof useDeleteGlobalAlmanacMutation;
  useSetMutation:
    | typeof useSetAlmanacMutation
    | typeof useSetGlobalAlmanacMutation;
}

export const Almanac: FunctionComponent<Props> = ({
  adminEditing = false,
  uuid,
  parentLink,
  serial,
  useGetQuery,
  useDeleteMutation,
  useSetMutation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canEnableCrops = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.crops_advanced,
      PermissionDomain.all
    ),
  ]);

  // robots
  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken),
    {
      errorVariant: "warning",
    }
  );

  // crops
  const {
    data: config,
    isError: isConfigError,
    error: configError,
  } = useQueryPopups(useGetConfigQuery(serial ? { serial } : skipToken), {
    errorVariant: "warning",
  });

  const {
    data: allCrops,
    isError: isAllCropsError,
    error: allCropsError,
  } = useQueryPopups(
    useListCropsQuery(undefined, {
      skip: !isUndefined(serial),
    })
  );

  const {
    data: robotCrops,
    isError: isCropsError,
    error: cropsError,
  } = useQueryPopups(useListRobotCropsQuery(serial ? { serial } : skipToken));

  const sortedCrops = useMemo<SortedCrops | undefined>(() => {
    if (!serial) {
      if (!allCrops) {
        return;
      }
      const archived: ConfigCrop[] = [];
      const all: ConfigCrop[] = [];
      for (const crop of allCrops) {
        const configCrop = cropToConfigCrop(crop);
        if (crop.archived) {
          archived.push(configCrop);
        }
        all.push(configCrop);
      }
      return {
        all,
        enabled: all,
        disabled: [],
        archived,
        pinnedAndEnabledCrops: 0,
      };
    }

    if (!config) {
      return;
    }

    return getCrops(serial, config.config, robotCrops);
  }, [serial, config, robotCrops, allCrops]);
  const crops = canEnableCrops ? sortedCrops?.all : sortedCrops?.enabled;

  // view state
  const [isAdvanced, setAdvanced] = useLocalStorage<boolean>(
    LOCALSTORAGE_ALMANAC_MODE,
    false
  );

  // local changes
  const [updatedAlmanac, setUpdatedAlmanac] = useState<
    AlmanacConfig | undefined
  >();

  // remote data
  const {
    data: freezedAlmanac,
    isError: isAlmanacError,
    error: almanacError,
  } = useQueryPopups(useGetQuery({ uuid }));

  // mutations
  const [deleteAlmanac] = useMutationPopups(useDeleteMutation(), {
    success: titleCase(
      t("utils.actions.deletedLong", {
        subject: t("models.almanacs.almanac_one"),
      })
    ),
  });

  const [updateAlmanac] = useMutationPopups(useSetMutation(), {
    success: titleCase(
      t("utils.actions.savedLong", {
        subject: t("models.almanacs.almanac_one"),
      })
    ),
  });

  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  // unfreezed almanac state
  const unfreezedAlmanac = useMemo<AlmanacConfig | undefined>(
    () =>
      freezedAlmanac ? JSON.parse(JSON.stringify(freezedAlmanac)) : undefined,
    [freezedAlmanac]
  );

  // visible state
  const almanac = updatedAlmanac ?? unfreezedAlmanac;
  const isActive =
    !isUndefined(almanac?.id) &&
    almanac.id === summary?.robot?.health?.fieldConfig?.activeAlmanacId;

  const canRead = useAuthorizationRequired(
    adminEditing
      ? [
          buildPermission(
            PermissionAction.read,
            PermissionResource.almanacs,
            PermissionDomain.templates
          ),
        ]
      : [
          buildPermission(
            PermissionAction.read,
            PermissionResource.almanacs,
            PermissionDomain.all
          ),
          buildPermission(
            PermissionAction.read,
            PermissionResource.almanacs,
            PermissionDomain.customer
          ),
        ]
  );
  const canUpdate =
    useAuthorizationRequired(
      adminEditing
        ? [
            buildPermission(
              PermissionAction.update,
              PermissionResource.almanacs,
              PermissionDomain.templates
            ),
          ]
        : [
            buildPermission(
              PermissionAction.update,
              PermissionResource.almanacs,
              PermissionDomain.all
            ),
            buildPermission(
              PermissionAction.update,
              PermissionResource.almanacs,
              PermissionDomain.customer
            ),
          ]
    ) &&
    (!almanac?.protected || adminEditing);

  if (!canRead) {
    return;
  }

  if (!almanac || !crops) {
    return (
      <Loading
        failed={
          isConfigError || isCropsError || isAllCropsError || isAlmanacError
        }
        error={configError ?? cropsError ?? allCropsError ?? almanacError}
      />
    );
  }

  return (
    <div className="flex flex-col min-h-full">
      {canUpdate && adminEditing && (
        <Alert severity="warning" className="mb-8">
          {t("components.almanac.warnings.admin")}
        </Alert>
      )}

      {isActive && (
        <Alert severity="warning" className="mb-8">
          {t("components.almanac.warnings.production")}
        </Alert>
      )}

      {almanac.protected && !adminEditing && (
        <Alert severity="warning" className="mb-8">
          {t("components.almanac.warnings.carbon")}
        </Alert>
      )}

      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        {/* Left Controls */}
        <div className="flex items-center">
          {/* Breadcrumb */}
          {parentLink && (
            <IconButton component={Link} to={parentLink} className="text-white">
              <ParentIcon />
            </IconButton>
          )}

          {/* Mode switcher */}
          <Button {...WHITE_BUTTON} onClick={() => setAdvanced(!isAdvanced)}>
            {isAdvanced
              ? t("components.almanac.switchModeBasic")
              : t("components.almanac.switchModeAdvanced")}
          </Button>
        </div>

        {/* Title */}
        {canUpdate ? (
          <EditableTypography
            variant="h4"
            value={almanac.name}
            onEdit={async (name) => {
              const newAlmanac = {
                ...almanac,
                name,
              };
              setUpdatedAlmanac(newAlmanac);
              await updateAlmanac({ serial, almanac: newAlmanac });
              setUpdatedAlmanac(undefined);
            }}
          />
        ) : (
          <Typography variant="h4">{almanac.name}</Typography>
        )}

        {/* Right Controls */}
        <div className="flex items-center gap-4">
          {canUpdate && (
            <Button
              {...RED_BUTTON}
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              {t("utils.actions.delete")}
            </Button>
          )}
          {confirmDelete && (
            <ConfirmationDialog
              title={t("utils.actions.deleteLong", {
                subject: t("models.almanacs.almanac_one"),
              })}
              description={
                isActive ? (
                  <Alert severity="warning" className="mb-8">
                    {t(
                      "components.ConfirmationDialog.delete.descriptionActive",
                      {
                        subject: t("models.almanacs.almanac_one"),
                      }
                    )}
                  </Alert>
                ) : (
                  t("components.ConfirmationDialog.delete.description", {
                    subject: t("models.almanacs.almanac_one"),
                  })
                )
              }
              destructive
              yesText={t("utils.actions.delete", {
                subject: almanac.name,
              })}
              yesDisabled={isActive}
              onClose={() => setConfirmDelete(false)}
              onYes={async () => {
                await deleteAlmanac({ uuid: almanac.id });
                if (parentLink) {
                  navigate(parentLink);
                }
              }}
            />
          )}
        </div>
      </div>

      {/* Main View */}
      {isAdvanced ? (
        <AdvancedAlmanac
          almanac={almanac}
          crops={crops}
          onChange={async (newAlmanac) => {
            setUpdatedAlmanac(newAlmanac);
            await updateAlmanac({ serial, almanac: newAlmanac });
            setUpdatedAlmanac(undefined);
          }}
        />
      ) : (
        <BasicAlmanac
          almanac={almanac}
          crops={crops}
          onChange={async (newAlmanac) => {
            setUpdatedAlmanac(newAlmanac);
            await updateAlmanac({ serial, almanac: newAlmanac });
            setUpdatedAlmanac(undefined);
          }}
        />
      )}
    </div>
  );
};
