import {
  CropThumbnailRequest,
  ImageOp,
  useGetCropThumbnailQuery,
} from "portal/state/s3CacheProxyApi";
import { Image, ImageProperties } from "./Image";
import { skipToken } from "@reduxjs/toolkit/query";
import { splitS3Url } from "portal/utils/images";
import { theme } from "portal/utils/theme";
import React, { FunctionComponent } from "react";

export type ThumbnailImageItem = ImageProperties &
  Omit<CropThumbnailRequest, "bucket" | "key" | "op"> & {
    url: string;
  };

export type ThumbnailBaseInfo = Pick<
  ThumbnailImageItem,
  "id" | "x" | "y" | "width" | "height" | "url"
>;

interface ThumbnailImageProps {
  image?: ThumbnailImageItem;
  dimensions: {
    width: number;
    height: number;
  };
  onImageLoaded?: (id: string) => void;
}

const assembleRequestParameters = ({
  url,
  x,
  y,
  width,
  height,
  fill,
}: ThumbnailImageItem): CropThumbnailRequest => {
  const { bucket, key } = splitS3Url(url);
  return {
    op: ImageOp.CROP,
    bucket,
    key,
    x,
    y,
    width,
    height,
    fill: fill ?? theme.colors.gray["600"],
  };
};

export const ThumbnailImage: FunctionComponent<ThumbnailImageProps> = ({
  image,
  dimensions,
  onImageLoaded,
}) => {
  const {
    data: fetchedImageSource,
    isLoading: isFetchingImageSource,
    error: fetchingImageSourceError,
  } = useGetCropThumbnailQuery(
    image ? assembleRequestParameters(image) : skipToken
  );

  return (
    <Image
      image={
        image
          ? {
              ...image,
              src: fetchedImageSource,
              isSrcLoading: isFetchingImageSource,
              isSrcError: Boolean(fetchingImageSourceError),
            }
          : undefined
      }
      dimensions={dimensions}
      onImageLoaded={onImageLoaded}
    />
  );
};
