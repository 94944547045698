import { Pagination, ResultsCountType } from "protos/veselka/pagination";

export type ResultsMetadata<T> = {
  data: {
    ids: Set<string>;
    data: T[];
  };
  areAllResultsLoaded: boolean;
  loadableImagesCount: number;
  totalResultCount?: {
    type: ResultsCountType;
    count: number;
  };
};

/**
 * Processes new paginated results to be used for infinite scroll
 */
export const processImageResults = <T extends { id: string; image?: any }>(
  previousState: ResultsMetadata<T>,
  currentQueryResult?: {
    data: T[];
    pagination?: Pagination;
  }
): ResultsMetadata<T> => {
  if (!currentQueryResult || !currentQueryResult.pagination) {
    return previousState;
  }
  const nextData = {
    ids: new Set(previousState.data.ids),
    data: [...previousState.data.data],
  };
  for (const result of currentQueryResult.data) {
    const { id } = result;
    if (!previousState.data.ids.has(id)) {
      nextData.ids.add(id);
      nextData.data.push(result);
    }
  }

  const { countType, totalRecords: reportedTotalRecords } =
    currentQueryResult.pagination;
  const noMoreResults = currentQueryResult.data.length === 0;
  const inferTotalCount =
    countType === ResultsCountType.ESTIMATED && noMoreResults;

  return {
    data: nextData,
    areAllResultsLoaded:
      noMoreResults ||
      nextData.data.length >= currentQueryResult.pagination.totalRecords,
    loadableImagesCount: nextData.data.filter(({ image }) => image).length,
    totalResultCount: {
      type: inferTotalCount ? ResultsCountType.PRECISE : countType,
      count: inferTotalCount ? nextData.data.length : reportedTotalRecords,
    },
  };
};
