import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { BetaFlag } from "portal/components/BetaFlag";
import { buildPermission } from "portal/utils/auth";
import { classes } from "portal/utils/theme";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { filterWhere } from "portal/utils/arrays";
import { MetricValidation, SpatialMetric } from "portal/utils/metrics";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import {
  SPATIAL_COVERAGE,
  SPATIAL_DEFAULT,
  SPATIAL_METRIC_GROUPS,
} from "portal/utils/spatialMetrics";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import ExpandIcon from "@mui/icons-material/ExpandMoreOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import LayersIcon from "@mui/icons-material/LayersOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  onClose: () => void;
  selectedMetricId: string;
  setSelectedMetricId: (id: string) => void;
}

export const HeatmapPane: FunctionComponent<Props> = ({
  onClose,
  selectedMetricId,
  setSelectedMetricId,
}) => {
  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );

  const canReadInternalMetrics = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.metrics_internal,
      PermissionDomain.all
    ),
  ]);

  const { t } = useTranslation();

  const [openSection, setOpenSection] = useState<number>(
    SPATIAL_METRIC_GROUPS.findIndex((group) =>
      group.metrics.map((metric) => metric.id).includes(selectedMetricId)
    )
  );

  return (
    <div
      className={classes(
        "absolute top-[10px] left-[50px] z-[3]",
        "text-black bg-white rounded",
        "max-h-full overflow-x-hidden overflow-y-auto",
        "cursor-default"
      )}
    >
      <BetaFlag />
      <Alert
        severity="info"
        className="min-w-full w-64 border-0 border-b border-solid border-blue-300"
        classes={{ message: "text-xs" }}
      >
        <AlertTitle className="text-sm">
          {t("components.BetaFlag.spatial.title")}
        </AlertTitle>
        {t("components.BetaFlag.spatial.description")}
      </Alert>
      <div className="flex justify-end">
        <IconButton
          onClick={onClose}
          className="mr-1"
          edge="end"
          size="small"
          disableRipple
        >
          <ClearIcon className="text-sm" />
        </IconButton>
      </div>
      <div className="m-6 mt-0">
        <div className="flex items-center">
          <LayersIcon />
          <h3 className="mx-1 my-0">{t("components.map.heatmaps.name")}</h3>
          {selectedMetricId !== SPATIAL_DEFAULT.id && (
            <Button
              color="secondary"
              className="ml-2 px-1 py-0"
              onClick={() => {
                setSelectedMetricId(SPATIAL_DEFAULT.id);
                onClose();
              }}
              variant="outlined"
              size="small"
            >
              {titleCase(
                t("utils.actions.resetLong", {
                  subject: t("components.map.heatmaps.name"),
                })
              )}
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-1 mt-2">
          <Metric
            className="mt-2 ml-1"
            metric={SPATIAL_COVERAGE}
            selected={selectedMetricId === SPATIAL_COVERAGE.id}
            onSelect={() => {
              if (selectedMetricId !== SPATIAL_COVERAGE.id) {
                setSelectedMetricId(SPATIAL_COVERAGE.id);
              }
            }}
          />
          {SPATIAL_METRIC_GROUPS.map(
            ({ i18nKey: titleKey, metrics }, index) => {
              const isAllPending =
                filterWhere(metrics, {
                  validation: MetricValidation.VALIDATED,
                  canMap: true,
                })?.length === 0;

              if (
                isAllPending &&
                !canReadInternalMetrics &&
                !hasUnvalidatedMetrics
              ) {
                return;
              }
              return (
                <Accordion
                  disableGutters
                  elevation={0}
                  key={titleKey}
                  expanded={index === openSection}
                  onChange={() => {
                    setOpenSection(index);
                  }}
                  classes={{ root: "bg-white text-black" }}
                >
                  <AccordionSummary
                    className="font-bold mt-2"
                    expandIcon={<ExpandIcon />}
                  >
                    {titleCase(t(titleKey))}
                  </AccordionSummary>
                  <AccordionDetails className="flex flex-col">
                    {metrics.map((metric) => {
                      // don't show unmappable metrics
                      if (!metric.canMap) {
                        return;
                      }
                      // don't show unvalidated metrics
                      if (
                        metric.validation === MetricValidation.PENDING &&
                        !hasUnvalidatedMetrics
                      ) {
                        return;
                      }
                      // don't show internal metrics
                      if (
                        metric.validation === MetricValidation.INTERNAL &&
                        !canReadInternalMetrics
                      ) {
                        return;
                      }
                      return (
                        <Metric
                          key={metric.id}
                          metric={metric}
                          selected={selectedMetricId === metric.id}
                          onSelect={() => {
                            if (selectedMetricId !== metric.id) {
                              setSelectedMetricId(metric.id);
                            }
                          }}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

interface MetricProps {
  className?: string;
  disabled?: boolean;
  metric: SpatialMetric;
  onSelect: () => void;
  selected: boolean;
}

const Metric: FunctionComponent<MetricProps> = ({
  className,
  disabled = false,
  metric,
  onSelect,
  selected,
}) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      key={metric.id}
      className={classes(className, { "opacity-50": disabled })}
      disabled={disabled}
      label={
        <Typography
          variant="body1"
          className={classes({
            "text-blue-500 italic":
              metric.validation === MetricValidation.PENDING,
            "text-orange-200 italic":
              metric.validation === MetricValidation.INTERNAL,
          })}
        >
          {/* carbon.actions.compareKeys.ignoreDynamic */}
          {t(`utils.metrics.spatial.metrics.${metric.id}`)}
        </Typography>
      }
      control={
        <Checkbox
          className="p-0 pr-2 ml-4"
          onChange={() => onSelect()}
          disabled={disabled}
          checked={selected}
          indeterminateIcon={
            <IndeterminateCheckBoxIcon className="text-black text-lg" />
          }
          icon={<RadioButtonUncheckedIcon className="text-black text-lg" />}
          checkedIcon={
            <RadioButtonCheckedIcon className="text-black text-lg" />
          }
          size="small"
          color="secondary"
        />
      }
    />
  );
};
