// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: recorder/recorder.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "recorder";

export interface DetectionClass {
  class: string;
  score: number;
}

export interface DeepweedDetection {
  x: number;
  y: number;
  size: number;
  score: number;
  hitClass: DeepweedDetection_HitClass;
  detectionClasses: DetectionClass[];
  maskIntersections: Uint8Array[];
  trajectoryId: number;
  weedScore: number;
  cropScore: number;
}

export enum DeepweedDetection_HitClass {
  WEED = 0,
  CROP = 1,
  UNRECOGNIZED = -1,
}

export function deepweedDetection_HitClassFromJSON(object: any): DeepweedDetection_HitClass {
  switch (object) {
    case 0:
    case "WEED":
      return DeepweedDetection_HitClass.WEED;
    case 1:
    case "CROP":
      return DeepweedDetection_HitClass.CROP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DeepweedDetection_HitClass.UNRECOGNIZED;
  }
}

export function deepweedDetection_HitClassToJSON(object: DeepweedDetection_HitClass): string {
  switch (object) {
    case DeepweedDetection_HitClass.WEED:
      return "WEED";
    case DeepweedDetection_HitClass.CROP:
      return "CROP";
    case DeepweedDetection_HitClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DeepweedPredictionFrame {
  timestampMs: number;
  detections: DeepweedDetection[];
}

export interface DeepweedPredictionRecord {
  recordTimestampMs: number;
  frame: DeepweedPredictionFrame | undefined;
}

export interface LaneHeightSnapshot {
  weedHeight: number[];
  cropHeight: number[];
}

export interface LaneHeightRecord {
  recordTimestampMs: number;
  snapshot: LaneHeightSnapshot | undefined;
}

export interface RotaryTicksSnapshot {
  timestampUs: number;
  fl: number;
  fr: number;
  bl: number;
  br: number;
  flEnabled: boolean;
  frEnabled: boolean;
  blEnabled: boolean;
  brEnabled: boolean;
}

export interface RotaryTicksRecord {
  recordTimestampMs: number;
  snapshot: RotaryTicksSnapshot | undefined;
}

function createBaseDetectionClass(): DetectionClass {
  return { class: "", score: 0 };
}

export const DetectionClass: MessageFns<DetectionClass> = {
  encode(message: DetectionClass, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.class !== "") {
      writer.uint32(10).string(message.class);
    }
    if (message.score !== 0) {
      writer.uint32(21).float(message.score);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DetectionClass {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectionClass();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.class = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.score = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectionClass {
    return {
      class: isSet(object.class) ? globalThis.String(object.class) : "",
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
    };
  },

  toJSON(message: DetectionClass): unknown {
    const obj: any = {};
    if (message.class !== "") {
      obj.class = message.class;
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectionClass>, I>>(base?: I): DetectionClass {
    return DetectionClass.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectionClass>, I>>(object: I): DetectionClass {
    const message = createBaseDetectionClass();
    message.class = object.class ?? "";
    message.score = object.score ?? 0;
    return message;
  },
};

function createBaseDeepweedDetection(): DeepweedDetection {
  return {
    x: 0,
    y: 0,
    size: 0,
    score: 0,
    hitClass: 0,
    detectionClasses: [],
    maskIntersections: [],
    trajectoryId: 0,
    weedScore: 0,
    cropScore: 0,
  };
}

export const DeepweedDetection: MessageFns<DeepweedDetection> = {
  encode(message: DeepweedDetection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    if (message.size !== 0) {
      writer.uint32(29).float(message.size);
    }
    if (message.score !== 0) {
      writer.uint32(37).float(message.score);
    }
    if (message.hitClass !== 0) {
      writer.uint32(40).int32(message.hitClass);
    }
    for (const v of message.detectionClasses) {
      DetectionClass.encode(v!, writer.uint32(50).fork()).join();
    }
    for (const v of message.maskIntersections) {
      writer.uint32(58).bytes(v!);
    }
    if (message.trajectoryId !== 0) {
      writer.uint32(64).uint32(message.trajectoryId);
    }
    if (message.weedScore !== 0) {
      writer.uint32(77).float(message.weedScore);
    }
    if (message.cropScore !== 0) {
      writer.uint32(85).float(message.cropScore);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedDetection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedDetection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.size = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.score = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hitClass = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.detectionClasses.push(DetectionClass.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.maskIntersections.push(reader.bytes());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.trajectoryId = reader.uint32();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.weedScore = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.cropScore = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedDetection {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      hitClass: isSet(object.hitClass) ? deepweedDetection_HitClassFromJSON(object.hitClass) : 0,
      detectionClasses: globalThis.Array.isArray(object?.detectionClasses)
        ? object.detectionClasses.map((e: any) => DetectionClass.fromJSON(e))
        : [],
      maskIntersections: globalThis.Array.isArray(object?.maskIntersections)
        ? object.maskIntersections.map((e: any) => bytesFromBase64(e))
        : [],
      trajectoryId: isSet(object.trajectoryId) ? globalThis.Number(object.trajectoryId) : 0,
      weedScore: isSet(object.weedScore) ? globalThis.Number(object.weedScore) : 0,
      cropScore: isSet(object.cropScore) ? globalThis.Number(object.cropScore) : 0,
    };
  },

  toJSON(message: DeepweedDetection): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.size !== 0) {
      obj.size = message.size;
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.hitClass !== 0) {
      obj.hitClass = deepweedDetection_HitClassToJSON(message.hitClass);
    }
    if (message.detectionClasses?.length) {
      obj.detectionClasses = message.detectionClasses.map((e) => DetectionClass.toJSON(e));
    }
    if (message.maskIntersections?.length) {
      obj.maskIntersections = message.maskIntersections.map((e) => base64FromBytes(e));
    }
    if (message.trajectoryId !== 0) {
      obj.trajectoryId = Math.round(message.trajectoryId);
    }
    if (message.weedScore !== 0) {
      obj.weedScore = message.weedScore;
    }
    if (message.cropScore !== 0) {
      obj.cropScore = message.cropScore;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedDetection>, I>>(base?: I): DeepweedDetection {
    return DeepweedDetection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedDetection>, I>>(object: I): DeepweedDetection {
    const message = createBaseDeepweedDetection();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.size = object.size ?? 0;
    message.score = object.score ?? 0;
    message.hitClass = object.hitClass ?? 0;
    message.detectionClasses = object.detectionClasses?.map((e) => DetectionClass.fromPartial(e)) || [];
    message.maskIntersections = object.maskIntersections?.map((e) => e) || [];
    message.trajectoryId = object.trajectoryId ?? 0;
    message.weedScore = object.weedScore ?? 0;
    message.cropScore = object.cropScore ?? 0;
    return message;
  },
};

function createBaseDeepweedPredictionFrame(): DeepweedPredictionFrame {
  return { timestampMs: 0, detections: [] };
}

export const DeepweedPredictionFrame: MessageFns<DeepweedPredictionFrame> = {
  encode(message: DeepweedPredictionFrame, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampMs !== 0) {
      writer.uint32(8).int64(message.timestampMs);
    }
    for (const v of message.detections) {
      DeepweedDetection.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedPredictionFrame {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedPredictionFrame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampMs = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.detections.push(DeepweedDetection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedPredictionFrame {
    return {
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => DeepweedDetection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DeepweedPredictionFrame): unknown {
    const obj: any = {};
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => DeepweedDetection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedPredictionFrame>, I>>(base?: I): DeepweedPredictionFrame {
    return DeepweedPredictionFrame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedPredictionFrame>, I>>(object: I): DeepweedPredictionFrame {
    const message = createBaseDeepweedPredictionFrame();
    message.timestampMs = object.timestampMs ?? 0;
    message.detections = object.detections?.map((e) => DeepweedDetection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeepweedPredictionRecord(): DeepweedPredictionRecord {
  return { recordTimestampMs: 0, frame: undefined };
}

export const DeepweedPredictionRecord: MessageFns<DeepweedPredictionRecord> = {
  encode(message: DeepweedPredictionRecord, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordTimestampMs !== 0) {
      writer.uint32(8).uint64(message.recordTimestampMs);
    }
    if (message.frame !== undefined) {
      DeepweedPredictionFrame.encode(message.frame, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeepweedPredictionRecord {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeepweedPredictionRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.recordTimestampMs = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.frame = DeepweedPredictionFrame.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeepweedPredictionRecord {
    return {
      recordTimestampMs: isSet(object.recordTimestampMs) ? globalThis.Number(object.recordTimestampMs) : 0,
      frame: isSet(object.frame) ? DeepweedPredictionFrame.fromJSON(object.frame) : undefined,
    };
  },

  toJSON(message: DeepweedPredictionRecord): unknown {
    const obj: any = {};
    if (message.recordTimestampMs !== 0) {
      obj.recordTimestampMs = Math.round(message.recordTimestampMs);
    }
    if (message.frame !== undefined) {
      obj.frame = DeepweedPredictionFrame.toJSON(message.frame);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeepweedPredictionRecord>, I>>(base?: I): DeepweedPredictionRecord {
    return DeepweedPredictionRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeepweedPredictionRecord>, I>>(object: I): DeepweedPredictionRecord {
    const message = createBaseDeepweedPredictionRecord();
    message.recordTimestampMs = object.recordTimestampMs ?? 0;
    message.frame = (object.frame !== undefined && object.frame !== null)
      ? DeepweedPredictionFrame.fromPartial(object.frame)
      : undefined;
    return message;
  },
};

function createBaseLaneHeightSnapshot(): LaneHeightSnapshot {
  return { weedHeight: [], cropHeight: [] };
}

export const LaneHeightSnapshot: MessageFns<LaneHeightSnapshot> = {
  encode(message: LaneHeightSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.weedHeight) {
      writer.double(v);
    }
    writer.join();
    writer.uint32(18).fork();
    for (const v of message.cropHeight) {
      writer.double(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaneHeightSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaneHeightSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 9) {
            message.weedHeight.push(reader.double());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.weedHeight.push(reader.double());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 17) {
            message.cropHeight.push(reader.double());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cropHeight.push(reader.double());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaneHeightSnapshot {
    return {
      weedHeight: globalThis.Array.isArray(object?.weedHeight)
        ? object.weedHeight.map((e: any) => globalThis.Number(e))
        : [],
      cropHeight: globalThis.Array.isArray(object?.cropHeight)
        ? object.cropHeight.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: LaneHeightSnapshot): unknown {
    const obj: any = {};
    if (message.weedHeight?.length) {
      obj.weedHeight = message.weedHeight;
    }
    if (message.cropHeight?.length) {
      obj.cropHeight = message.cropHeight;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaneHeightSnapshot>, I>>(base?: I): LaneHeightSnapshot {
    return LaneHeightSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaneHeightSnapshot>, I>>(object: I): LaneHeightSnapshot {
    const message = createBaseLaneHeightSnapshot();
    message.weedHeight = object.weedHeight?.map((e) => e) || [];
    message.cropHeight = object.cropHeight?.map((e) => e) || [];
    return message;
  },
};

function createBaseLaneHeightRecord(): LaneHeightRecord {
  return { recordTimestampMs: 0, snapshot: undefined };
}

export const LaneHeightRecord: MessageFns<LaneHeightRecord> = {
  encode(message: LaneHeightRecord, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordTimestampMs !== 0) {
      writer.uint32(8).uint64(message.recordTimestampMs);
    }
    if (message.snapshot !== undefined) {
      LaneHeightSnapshot.encode(message.snapshot, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaneHeightRecord {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaneHeightRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.recordTimestampMs = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.snapshot = LaneHeightSnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaneHeightRecord {
    return {
      recordTimestampMs: isSet(object.recordTimestampMs) ? globalThis.Number(object.recordTimestampMs) : 0,
      snapshot: isSet(object.snapshot) ? LaneHeightSnapshot.fromJSON(object.snapshot) : undefined,
    };
  },

  toJSON(message: LaneHeightRecord): unknown {
    const obj: any = {};
    if (message.recordTimestampMs !== 0) {
      obj.recordTimestampMs = Math.round(message.recordTimestampMs);
    }
    if (message.snapshot !== undefined) {
      obj.snapshot = LaneHeightSnapshot.toJSON(message.snapshot);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaneHeightRecord>, I>>(base?: I): LaneHeightRecord {
    return LaneHeightRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaneHeightRecord>, I>>(object: I): LaneHeightRecord {
    const message = createBaseLaneHeightRecord();
    message.recordTimestampMs = object.recordTimestampMs ?? 0;
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? LaneHeightSnapshot.fromPartial(object.snapshot)
      : undefined;
    return message;
  },
};

function createBaseRotaryTicksSnapshot(): RotaryTicksSnapshot {
  return {
    timestampUs: 0,
    fl: 0,
    fr: 0,
    bl: 0,
    br: 0,
    flEnabled: false,
    frEnabled: false,
    blEnabled: false,
    brEnabled: false,
  };
}

export const RotaryTicksSnapshot: MessageFns<RotaryTicksSnapshot> = {
  encode(message: RotaryTicksSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestampUs !== 0) {
      writer.uint32(8).uint64(message.timestampUs);
    }
    if (message.fl !== 0) {
      writer.uint32(16).int32(message.fl);
    }
    if (message.fr !== 0) {
      writer.uint32(24).int32(message.fr);
    }
    if (message.bl !== 0) {
      writer.uint32(32).int32(message.bl);
    }
    if (message.br !== 0) {
      writer.uint32(40).int32(message.br);
    }
    if (message.flEnabled !== false) {
      writer.uint32(48).bool(message.flEnabled);
    }
    if (message.frEnabled !== false) {
      writer.uint32(56).bool(message.frEnabled);
    }
    if (message.blEnabled !== false) {
      writer.uint32(64).bool(message.blEnabled);
    }
    if (message.brEnabled !== false) {
      writer.uint32(72).bool(message.brEnabled);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RotaryTicksSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRotaryTicksSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestampUs = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fl = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fr = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bl = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.br = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.flEnabled = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.frEnabled = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.blEnabled = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.brEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RotaryTicksSnapshot {
    return {
      timestampUs: isSet(object.timestampUs) ? globalThis.Number(object.timestampUs) : 0,
      fl: isSet(object.fl) ? globalThis.Number(object.fl) : 0,
      fr: isSet(object.fr) ? globalThis.Number(object.fr) : 0,
      bl: isSet(object.bl) ? globalThis.Number(object.bl) : 0,
      br: isSet(object.br) ? globalThis.Number(object.br) : 0,
      flEnabled: isSet(object.flEnabled) ? globalThis.Boolean(object.flEnabled) : false,
      frEnabled: isSet(object.frEnabled) ? globalThis.Boolean(object.frEnabled) : false,
      blEnabled: isSet(object.blEnabled) ? globalThis.Boolean(object.blEnabled) : false,
      brEnabled: isSet(object.brEnabled) ? globalThis.Boolean(object.brEnabled) : false,
    };
  },

  toJSON(message: RotaryTicksSnapshot): unknown {
    const obj: any = {};
    if (message.timestampUs !== 0) {
      obj.timestampUs = Math.round(message.timestampUs);
    }
    if (message.fl !== 0) {
      obj.fl = Math.round(message.fl);
    }
    if (message.fr !== 0) {
      obj.fr = Math.round(message.fr);
    }
    if (message.bl !== 0) {
      obj.bl = Math.round(message.bl);
    }
    if (message.br !== 0) {
      obj.br = Math.round(message.br);
    }
    if (message.flEnabled !== false) {
      obj.flEnabled = message.flEnabled;
    }
    if (message.frEnabled !== false) {
      obj.frEnabled = message.frEnabled;
    }
    if (message.blEnabled !== false) {
      obj.blEnabled = message.blEnabled;
    }
    if (message.brEnabled !== false) {
      obj.brEnabled = message.brEnabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RotaryTicksSnapshot>, I>>(base?: I): RotaryTicksSnapshot {
    return RotaryTicksSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RotaryTicksSnapshot>, I>>(object: I): RotaryTicksSnapshot {
    const message = createBaseRotaryTicksSnapshot();
    message.timestampUs = object.timestampUs ?? 0;
    message.fl = object.fl ?? 0;
    message.fr = object.fr ?? 0;
    message.bl = object.bl ?? 0;
    message.br = object.br ?? 0;
    message.flEnabled = object.flEnabled ?? false;
    message.frEnabled = object.frEnabled ?? false;
    message.blEnabled = object.blEnabled ?? false;
    message.brEnabled = object.brEnabled ?? false;
    return message;
  },
};

function createBaseRotaryTicksRecord(): RotaryTicksRecord {
  return { recordTimestampMs: 0, snapshot: undefined };
}

export const RotaryTicksRecord: MessageFns<RotaryTicksRecord> = {
  encode(message: RotaryTicksRecord, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recordTimestampMs !== 0) {
      writer.uint32(8).uint64(message.recordTimestampMs);
    }
    if (message.snapshot !== undefined) {
      RotaryTicksSnapshot.encode(message.snapshot, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RotaryTicksRecord {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRotaryTicksRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.recordTimestampMs = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.snapshot = RotaryTicksSnapshot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RotaryTicksRecord {
    return {
      recordTimestampMs: isSet(object.recordTimestampMs) ? globalThis.Number(object.recordTimestampMs) : 0,
      snapshot: isSet(object.snapshot) ? RotaryTicksSnapshot.fromJSON(object.snapshot) : undefined,
    };
  },

  toJSON(message: RotaryTicksRecord): unknown {
    const obj: any = {};
    if (message.recordTimestampMs !== 0) {
      obj.recordTimestampMs = Math.round(message.recordTimestampMs);
    }
    if (message.snapshot !== undefined) {
      obj.snapshot = RotaryTicksSnapshot.toJSON(message.snapshot);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RotaryTicksRecord>, I>>(base?: I): RotaryTicksRecord {
    return RotaryTicksRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RotaryTicksRecord>, I>>(object: I): RotaryTicksRecord {
    const message = createBaseRotaryTicksRecord();
    message.recordTimestampMs = object.recordTimestampMs ?? 0;
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? RotaryTicksSnapshot.fromPartial(object.snapshot)
      : undefined;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
