import {
  AutotractorSubpath,
  getRobotPath,
  RobotSubpath,
  Slug,
} from "portal/utils/routing";
import { buildPermission } from "portal/utils/auth";
import { Button } from "@mui/material";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Job } from "protos/rtc/jobs";
import { NoScroll } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { titleCase } from "portal/utils/strings";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useListAutotractorJobsQuery } from "portal/state/portalApi";
import { useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/AddOutlined";
import React, { FunctionComponent, useMemo } from "react";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  flex: 1,
};

interface Props {
  serial: string;
}

const _RobotAutotractorJobList: FunctionComponent<Props> = ({ serial }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: jobs, isLoading } = useQueryPopups(
    useListAutotractorJobsQuery({ serial })
  );

  const canRead = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.autotractor_jobs,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.autotractor_jobs,
      PermissionDomain.customer
    ),
  ]);
  const canUpdate = useAuthorizationRequired([
    buildPermission(
      PermissionAction.update,
      PermissionResource.autotractor_jobs,
      PermissionDomain.all
    ),
    buildPermission(
      PermissionAction.update,
      PermissionResource.autotractor_jobs,
      PermissionDomain.customer
    ),
  ]);

  const columns: GridColDef<Job>[] = useMemo(() => {
    return [
      {
        ...defaultColumn,
        field: "name",
        headerName: t("utils.descriptors.name"),
      },
    ];
  }, [t]);

  if (!canRead) {
    return;
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<Job>
          getRowId={({ id }) => id}
          rows={jobs ?? []}
          loading={isLoading}
          columns={columns}
          hideFooter
          disableRowSelectionOnClick
          header={
            canUpdate && (
              <div className="flex w-full justify-end">
                <Button
                  className="text-white"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(
                      `${getRobotPath(serial, RobotSubpath.AUTOTRACTOR)}${
                        AutotractorSubpath.JOBS
                      }/${Slug.NEW}`
                    )
                  }
                  disabled={!canUpdate}
                >
                  <span className="hidden sm:inline">
                    {titleCase(
                      t("utils.actions.newLong", {
                        subject: t("models.autotractor.job_one"),
                      })
                    )}
                  </span>
                  <span className="sm:hidden">{t("utils.actions.new")}</span>
                </Button>
              </div>
            )
          }
        />
      </div>
    </NoScroll>
  );
};

export const RobotAutotractorJobList = withAuthenticationRequired(
  _RobotAutotractorJobList
);
