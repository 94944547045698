import { addNotification, type VariantType } from "portal/state/notifications";
import {
  BaseQueryError,
  BaseQueryFn,
  BaseQueryResult,
  ResultTypeFrom,
} from "@reduxjs/toolkit/query";
import { buildPermission } from "../auth";
import { handleError, isAbort } from "portal/state/portalApi";
import { isObject } from "../objects";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { SerializedError } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import {
  TypedLazyQueryTrigger,
  TypedMutationTrigger,
  TypedUseLazyQueryStateResult,
  TypedUseMutationResult,
  TypedUseQueryHookResult,
} from "@reduxjs/toolkit/query/react";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useStableObject } from "./useStable";
import { useTranslation } from "react-i18next";

/**
 * Wrappers for RTK Query and Mutation hooks (including lazy ones) to handle
 * automatic error and success toast notifications.
 *
 * If success string is passed, that message will be shown on query success
 *
 * If user-facing error message is included in error response, it will be shown
 * (special error message will be shown for queryLimitReached errors)
 * errorVariant can be set to "warning" to show warning style toast instead of
 * error style toast on errors
 */

export interface UseApiPopupsOptions {
  success?: string;
  errorVariant?: VariantType;
}

const DEFAULT_OPTIONS: UseApiPopupsOptions = {};

export const onPopupStateChange = <ResultType, BaseQuery extends BaseQueryFn>(
  t: TFunction,
  data: ResultType | ResultTypeFrom<BaseQueryResult<BaseQuery>>,
  showFullErrors: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  isError: boolean,
  error: SerializedError | BaseQueryError<BaseQuery> | undefined,
  isSuccess: boolean,
  { success, errorVariant }: UseApiPopupsOptions
): void => {
  if (isObject(data) && "queryLimitReached" in data && data.queryLimitReached) {
    dispatch(
      addNotification({
        message: t("components.ErrorBoundary.queryLimitReached"),
        variant: "warning",
      })
    );
  }

  if (isError) {
    handleError(t, isError, error, (message) => {
      // ignore aborted request errors
      if (isAbort(error)) {
        return;
      }
      const variant = errorVariant ?? "error";
      if (variant === "error" || showFullErrors) {
        dispatch(
          addNotification({
            message: message ?? t("components.ErrorBoundary.error"),
            variant,
          })
        );
      }
    });
  }
  if (success && isSuccess) {
    dispatch(
      addNotification({
        message: success,
        variant: "success",
      })
    );
  }
};

export const useQueryPopups = <
  ResultType,
  QueryArgument,
  BaseQuery extends BaseQueryFn,
  HookResult extends TypedUseQueryHookResult<
    ResultType,
    QueryArgument,
    BaseQuery
  >
>(
  useQueryHookResult: HookResult,
  options?: UseApiPopupsOptions
): HookResult => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showFullErrors = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);
  const { data, isError, error, isSuccess } = useQueryHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    onPopupStateChange(
      t,
      data,
      showFullErrors,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [
    isError,
    error,
    isSuccess,
    t,
    data,
    showFullErrors,
    dispatch,
    stableOptions,
  ]);

  return useQueryHookResult;
};

export const useLazyPopups = <
  ResultType,
  QueryArgument,
  BaseQuery extends BaseQueryFn,
  LastPromiseInfo
>(
  useLazyQueryHookResult: [
    TypedLazyQueryTrigger<ResultType, QueryArgument, BaseQuery>,
    TypedUseLazyQueryStateResult<ResultType, QueryArgument, BaseQuery>,
    LastPromiseInfo
  ],
  options?: UseApiPopupsOptions
): [
  TypedLazyQueryTrigger<ResultType, QueryArgument, BaseQuery>,
  TypedUseLazyQueryStateResult<ResultType, QueryArgument, BaseQuery>,
  LastPromiseInfo
] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showFullErrors = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);
  const [, { data, isError, error, isSuccess }] = useLazyQueryHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    onPopupStateChange(
      t,
      data,
      showFullErrors,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [
    isError,
    error,
    isSuccess,
    t,
    data,
    showFullErrors,
    dispatch,
    stableOptions,
  ]);

  return useLazyQueryHookResult;
};

export const useMutationPopups = <
  ResultType,
  QueryArgument,
  BaseQuery extends BaseQueryFn
>(
  useMutationHookResult: readonly [
    TypedMutationTrigger<ResultType, QueryArgument, BaseQuery>,
    TypedUseMutationResult<ResultType, QueryArgument, BaseQuery>
  ],
  options?: UseApiPopupsOptions
): readonly [
  TypedMutationTrigger<ResultType, QueryArgument, BaseQuery>,
  TypedUseMutationResult<ResultType, QueryArgument, BaseQuery>
] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showFullErrors = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);
  const [, { data, isError, error, isSuccess }] = useMutationHookResult;
  const stableOptions = useStableObject(options ?? DEFAULT_OPTIONS);
  useEffect(() => {
    onPopupStateChange(
      t,
      data,
      showFullErrors,
      dispatch,
      isError,
      error,
      isSuccess,
      stableOptions
    );
  }, [
    isError,
    error,
    isSuccess,
    t,
    data,
    showFullErrors,
    dispatch,
    stableOptions,
  ]);

  return useMutationHookResult;
};
