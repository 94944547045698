import { Button, Typography } from "@mui/material";
import { titleCase } from "portal/utils/strings";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

interface LoadMoreImagesButtonProps {
  isInitialRequestLoading: boolean;
  canLoadMore: boolean;
  onClick: () => void;
}
export const LoadMoreImagesButton: FunctionComponent<
  LoadMoreImagesButtonProps
> = ({ isInitialRequestLoading, canLoadMore, onClick }) => {
  const { t } = useTranslation();
  if (isInitialRequestLoading) {
    return (
      <Typography className="italic">
        {titleCase(t("components.Loading.placeholder"))}
      </Typography>
    );
  }
  return canLoadMore ? (
    <Button onClick={onClick}>{titleCase(t("utils.lists.loadMore"))}...</Button>
  ) : (
    <Typography className="italic">
      {titleCase(t("utils.lists.noMoreResults"))}
    </Typography>
  );
};
