// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: metrics/metrics.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.metrics";

export interface LaserPosition {
  row: number;
  slot: number;
}

export interface LaserIdentifier {
  position: LaserPosition | undefined;
  serial: string;
}

export interface LaserLifeTime {
  id: LaserIdentifier | undefined;
  lifetimeSec: number;
}

export interface LaserEventTime {
  id: LaserIdentifier | undefined;
  timestampSec: number;
}

export interface LaserLifeTimes {
  lifetimes: LaserLifeTime[];
}

export interface LaserChangeTimes {
  installs: LaserEventTime[];
  removals: LaserEventTime[];
}

export interface CountsByConclusionType {
  disarmedWeed: number[];
  armedWeed: number[];
  disarmedCrop: number[];
  armedCrop: number[];
}

export interface TargetSizeData {
  cumulativeSize: number;
  count: number;
}

export interface RequiredLaserTimeData {
  cumulativeTime: number;
  count: number;
}

export interface SpatialPosition {
  latitude: number;
  longitude: number;
  heightMm: number;
  timestampMs: number;
  ecefX: number;
  ecefY: number;
  ecefZ: number;
}

export interface WeedCounterChunk {
  conclusionCounts: CountsByConclusionType | undefined;
  weedSizeData: TargetSizeData | undefined;
  cropSizeData: TargetSizeData | undefined;
  countsByCategory: { [key: string]: number };
  targetedLaserTimeData: RequiredLaserTimeData | undefined;
  untargetedLaserTimeData: RequiredLaserTimeData | undefined;
  validCropCount: number;
}

export interface WeedCounterChunk_CountsByCategoryEntry {
  key: string;
  value: number;
}

export interface WheelEncoderSpatialData {
  startPosM: number;
  endPosM: number;
}

export interface BandingSpatialData {
  percentBanded: number;
}

export interface ImplementWidthSpatialData {
  widthMm: number;
}

export interface VelocitySpatialMetric {
  avgTargetVel: { [key: string]: number };
}

export interface VelocitySpatialMetric_AvgTargetVelEntry {
  key: string;
  value: number;
}

export interface JobMetric {
  jobId: string;
}

export interface HWMetric {
  lifted: boolean;
  estopped: boolean;
  laserKey: boolean;
  interlock: boolean;
  waterProtect: boolean;
}

export interface SpatialMetricBlock {
  start: SpatialPosition | undefined;
  end: SpatialPosition | undefined;
  weedCount: WeedCounterChunk | undefined;
  weData: WheelEncoderSpatialData | undefined;
  bandingData: BandingSpatialData | undefined;
  implementWidthData: ImplementWidthSpatialData | undefined;
  velData: VelocitySpatialMetric | undefined;
  startLeft: SpatialPosition | undefined;
  startRight: SpatialPosition | undefined;
  endLeft: SpatialPosition | undefined;
  endRight: SpatialPosition | undefined;
  jobMetric:
    | JobMetric
    | undefined;
  /** May not be a valid block */
  suspicious: boolean;
  hwMetric: HWMetric | undefined;
}

function createBaseLaserPosition(): LaserPosition {
  return { row: 0, slot: 0 };
}

export const LaserPosition: MessageFns<LaserPosition> = {
  encode(message: LaserPosition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.row !== 0) {
      writer.uint32(8).uint32(message.row);
    }
    if (message.slot !== 0) {
      writer.uint32(16).uint32(message.slot);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserPosition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.row = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.slot = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserPosition {
    return {
      row: isSet(object.row) ? globalThis.Number(object.row) : 0,
      slot: isSet(object.slot) ? globalThis.Number(object.slot) : 0,
    };
  },

  toJSON(message: LaserPosition): unknown {
    const obj: any = {};
    if (message.row !== 0) {
      obj.row = Math.round(message.row);
    }
    if (message.slot !== 0) {
      obj.slot = Math.round(message.slot);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserPosition>, I>>(base?: I): LaserPosition {
    return LaserPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserPosition>, I>>(object: I): LaserPosition {
    const message = createBaseLaserPosition();
    message.row = object.row ?? 0;
    message.slot = object.slot ?? 0;
    return message;
  },
};

function createBaseLaserIdentifier(): LaserIdentifier {
  return { position: undefined, serial: "" };
}

export const LaserIdentifier: MessageFns<LaserIdentifier> = {
  encode(message: LaserIdentifier, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.position !== undefined) {
      LaserPosition.encode(message.position, writer.uint32(10).fork()).join();
    }
    if (message.serial !== "") {
      writer.uint32(18).string(message.serial);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserIdentifier {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserIdentifier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.position = LaserPosition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serial = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserIdentifier {
    return {
      position: isSet(object.position) ? LaserPosition.fromJSON(object.position) : undefined,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
    };
  },

  toJSON(message: LaserIdentifier): unknown {
    const obj: any = {};
    if (message.position !== undefined) {
      obj.position = LaserPosition.toJSON(message.position);
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserIdentifier>, I>>(base?: I): LaserIdentifier {
    return LaserIdentifier.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserIdentifier>, I>>(object: I): LaserIdentifier {
    const message = createBaseLaserIdentifier();
    message.position = (object.position !== undefined && object.position !== null)
      ? LaserPosition.fromPartial(object.position)
      : undefined;
    message.serial = object.serial ?? "";
    return message;
  },
};

function createBaseLaserLifeTime(): LaserLifeTime {
  return { id: undefined, lifetimeSec: 0 };
}

export const LaserLifeTime: MessageFns<LaserLifeTime> = {
  encode(message: LaserLifeTime, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      LaserIdentifier.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.lifetimeSec !== 0) {
      writer.uint32(16).uint64(message.lifetimeSec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserLifeTime {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserLifeTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = LaserIdentifier.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lifetimeSec = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserLifeTime {
    return {
      id: isSet(object.id) ? LaserIdentifier.fromJSON(object.id) : undefined,
      lifetimeSec: isSet(object.lifetimeSec) ? globalThis.Number(object.lifetimeSec) : 0,
    };
  },

  toJSON(message: LaserLifeTime): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = LaserIdentifier.toJSON(message.id);
    }
    if (message.lifetimeSec !== 0) {
      obj.lifetimeSec = Math.round(message.lifetimeSec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserLifeTime>, I>>(base?: I): LaserLifeTime {
    return LaserLifeTime.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserLifeTime>, I>>(object: I): LaserLifeTime {
    const message = createBaseLaserLifeTime();
    message.id = (object.id !== undefined && object.id !== null) ? LaserIdentifier.fromPartial(object.id) : undefined;
    message.lifetimeSec = object.lifetimeSec ?? 0;
    return message;
  },
};

function createBaseLaserEventTime(): LaserEventTime {
  return { id: undefined, timestampSec: 0 };
}

export const LaserEventTime: MessageFns<LaserEventTime> = {
  encode(message: LaserEventTime, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      LaserIdentifier.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.timestampSec !== 0) {
      writer.uint32(16).int64(message.timestampSec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserEventTime {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserEventTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = LaserIdentifier.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampSec = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserEventTime {
    return {
      id: isSet(object.id) ? LaserIdentifier.fromJSON(object.id) : undefined,
      timestampSec: isSet(object.timestampSec) ? globalThis.Number(object.timestampSec) : 0,
    };
  },

  toJSON(message: LaserEventTime): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = LaserIdentifier.toJSON(message.id);
    }
    if (message.timestampSec !== 0) {
      obj.timestampSec = Math.round(message.timestampSec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserEventTime>, I>>(base?: I): LaserEventTime {
    return LaserEventTime.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserEventTime>, I>>(object: I): LaserEventTime {
    const message = createBaseLaserEventTime();
    message.id = (object.id !== undefined && object.id !== null) ? LaserIdentifier.fromPartial(object.id) : undefined;
    message.timestampSec = object.timestampSec ?? 0;
    return message;
  },
};

function createBaseLaserLifeTimes(): LaserLifeTimes {
  return { lifetimes: [] };
}

export const LaserLifeTimes: MessageFns<LaserLifeTimes> = {
  encode(message: LaserLifeTimes, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.lifetimes) {
      LaserLifeTime.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserLifeTimes {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserLifeTimes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lifetimes.push(LaserLifeTime.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserLifeTimes {
    return {
      lifetimes: globalThis.Array.isArray(object?.lifetimes)
        ? object.lifetimes.map((e: any) => LaserLifeTime.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LaserLifeTimes): unknown {
    const obj: any = {};
    if (message.lifetimes?.length) {
      obj.lifetimes = message.lifetimes.map((e) => LaserLifeTime.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserLifeTimes>, I>>(base?: I): LaserLifeTimes {
    return LaserLifeTimes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserLifeTimes>, I>>(object: I): LaserLifeTimes {
    const message = createBaseLaserLifeTimes();
    message.lifetimes = object.lifetimes?.map((e) => LaserLifeTime.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLaserChangeTimes(): LaserChangeTimes {
  return { installs: [], removals: [] };
}

export const LaserChangeTimes: MessageFns<LaserChangeTimes> = {
  encode(message: LaserChangeTimes, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.installs) {
      LaserEventTime.encode(v!, writer.uint32(10).fork()).join();
    }
    for (const v of message.removals) {
      LaserEventTime.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LaserChangeTimes {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaserChangeTimes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.installs.push(LaserEventTime.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.removals.push(LaserEventTime.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaserChangeTimes {
    return {
      installs: globalThis.Array.isArray(object?.installs)
        ? object.installs.map((e: any) => LaserEventTime.fromJSON(e))
        : [],
      removals: globalThis.Array.isArray(object?.removals)
        ? object.removals.map((e: any) => LaserEventTime.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LaserChangeTimes): unknown {
    const obj: any = {};
    if (message.installs?.length) {
      obj.installs = message.installs.map((e) => LaserEventTime.toJSON(e));
    }
    if (message.removals?.length) {
      obj.removals = message.removals.map((e) => LaserEventTime.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaserChangeTimes>, I>>(base?: I): LaserChangeTimes {
    return LaserChangeTimes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaserChangeTimes>, I>>(object: I): LaserChangeTimes {
    const message = createBaseLaserChangeTimes();
    message.installs = object.installs?.map((e) => LaserEventTime.fromPartial(e)) || [];
    message.removals = object.removals?.map((e) => LaserEventTime.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCountsByConclusionType(): CountsByConclusionType {
  return { disarmedWeed: [], armedWeed: [], disarmedCrop: [], armedCrop: [] };
}

export const CountsByConclusionType: MessageFns<CountsByConclusionType> = {
  encode(message: CountsByConclusionType, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.disarmedWeed) {
      writer.uint32(v);
    }
    writer.join();
    writer.uint32(18).fork();
    for (const v of message.armedWeed) {
      writer.uint32(v);
    }
    writer.join();
    writer.uint32(26).fork();
    for (const v of message.disarmedCrop) {
      writer.uint32(v);
    }
    writer.join();
    writer.uint32(34).fork();
    for (const v of message.armedCrop) {
      writer.uint32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CountsByConclusionType {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountsByConclusionType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.disarmedWeed.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.disarmedWeed.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.armedWeed.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.armedWeed.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.disarmedCrop.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.disarmedCrop.push(reader.uint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.armedCrop.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.armedCrop.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountsByConclusionType {
    return {
      disarmedWeed: globalThis.Array.isArray(object?.disarmedWeed)
        ? object.disarmedWeed.map((e: any) => globalThis.Number(e))
        : [],
      armedWeed: globalThis.Array.isArray(object?.armedWeed)
        ? object.armedWeed.map((e: any) => globalThis.Number(e))
        : [],
      disarmedCrop: globalThis.Array.isArray(object?.disarmedCrop)
        ? object.disarmedCrop.map((e: any) => globalThis.Number(e))
        : [],
      armedCrop: globalThis.Array.isArray(object?.armedCrop)
        ? object.armedCrop.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: CountsByConclusionType): unknown {
    const obj: any = {};
    if (message.disarmedWeed?.length) {
      obj.disarmedWeed = message.disarmedWeed.map((e) => Math.round(e));
    }
    if (message.armedWeed?.length) {
      obj.armedWeed = message.armedWeed.map((e) => Math.round(e));
    }
    if (message.disarmedCrop?.length) {
      obj.disarmedCrop = message.disarmedCrop.map((e) => Math.round(e));
    }
    if (message.armedCrop?.length) {
      obj.armedCrop = message.armedCrop.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CountsByConclusionType>, I>>(base?: I): CountsByConclusionType {
    return CountsByConclusionType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountsByConclusionType>, I>>(object: I): CountsByConclusionType {
    const message = createBaseCountsByConclusionType();
    message.disarmedWeed = object.disarmedWeed?.map((e) => e) || [];
    message.armedWeed = object.armedWeed?.map((e) => e) || [];
    message.disarmedCrop = object.disarmedCrop?.map((e) => e) || [];
    message.armedCrop = object.armedCrop?.map((e) => e) || [];
    return message;
  },
};

function createBaseTargetSizeData(): TargetSizeData {
  return { cumulativeSize: 0, count: 0 };
}

export const TargetSizeData: MessageFns<TargetSizeData> = {
  encode(message: TargetSizeData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.cumulativeSize !== 0) {
      writer.uint32(9).double(message.cumulativeSize);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint64(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TargetSizeData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetSizeData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.cumulativeSize = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetSizeData {
    return {
      cumulativeSize: isSet(object.cumulativeSize) ? globalThis.Number(object.cumulativeSize) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: TargetSizeData): unknown {
    const obj: any = {};
    if (message.cumulativeSize !== 0) {
      obj.cumulativeSize = message.cumulativeSize;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetSizeData>, I>>(base?: I): TargetSizeData {
    return TargetSizeData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetSizeData>, I>>(object: I): TargetSizeData {
    const message = createBaseTargetSizeData();
    message.cumulativeSize = object.cumulativeSize ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseRequiredLaserTimeData(): RequiredLaserTimeData {
  return { cumulativeTime: 0, count: 0 };
}

export const RequiredLaserTimeData: MessageFns<RequiredLaserTimeData> = {
  encode(message: RequiredLaserTimeData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.cumulativeTime !== 0) {
      writer.uint32(8).uint64(message.cumulativeTime);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint64(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RequiredLaserTimeData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequiredLaserTimeData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cumulativeTime = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequiredLaserTimeData {
    return {
      cumulativeTime: isSet(object.cumulativeTime) ? globalThis.Number(object.cumulativeTime) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: RequiredLaserTimeData): unknown {
    const obj: any = {};
    if (message.cumulativeTime !== 0) {
      obj.cumulativeTime = Math.round(message.cumulativeTime);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RequiredLaserTimeData>, I>>(base?: I): RequiredLaserTimeData {
    return RequiredLaserTimeData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RequiredLaserTimeData>, I>>(object: I): RequiredLaserTimeData {
    const message = createBaseRequiredLaserTimeData();
    message.cumulativeTime = object.cumulativeTime ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseSpatialPosition(): SpatialPosition {
  return { latitude: 0, longitude: 0, heightMm: 0, timestampMs: 0, ecefX: 0, ecefY: 0, ecefZ: 0 };
}

export const SpatialPosition: MessageFns<SpatialPosition> = {
  encode(message: SpatialPosition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude);
    }
    if (message.heightMm !== 0) {
      writer.uint32(25).double(message.heightMm);
    }
    if (message.timestampMs !== 0) {
      writer.uint32(32).uint64(message.timestampMs);
    }
    if (message.ecefX !== 0) {
      writer.uint32(41).double(message.ecefX);
    }
    if (message.ecefY !== 0) {
      writer.uint32(49).double(message.ecefY);
    }
    if (message.ecefZ !== 0) {
      writer.uint32(57).double(message.ecefZ);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SpatialPosition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpatialPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.heightMm = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampMs = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.ecefX = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.ecefY = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.ecefZ = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpatialPosition {
    return {
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      heightMm: isSet(object.heightMm) ? globalThis.Number(object.heightMm) : 0,
      timestampMs: isSet(object.timestampMs) ? globalThis.Number(object.timestampMs) : 0,
      ecefX: isSet(object.ecefX) ? globalThis.Number(object.ecefX) : 0,
      ecefY: isSet(object.ecefY) ? globalThis.Number(object.ecefY) : 0,
      ecefZ: isSet(object.ecefZ) ? globalThis.Number(object.ecefZ) : 0,
    };
  },

  toJSON(message: SpatialPosition): unknown {
    const obj: any = {};
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.heightMm !== 0) {
      obj.heightMm = message.heightMm;
    }
    if (message.timestampMs !== 0) {
      obj.timestampMs = Math.round(message.timestampMs);
    }
    if (message.ecefX !== 0) {
      obj.ecefX = message.ecefX;
    }
    if (message.ecefY !== 0) {
      obj.ecefY = message.ecefY;
    }
    if (message.ecefZ !== 0) {
      obj.ecefZ = message.ecefZ;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpatialPosition>, I>>(base?: I): SpatialPosition {
    return SpatialPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpatialPosition>, I>>(object: I): SpatialPosition {
    const message = createBaseSpatialPosition();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.heightMm = object.heightMm ?? 0;
    message.timestampMs = object.timestampMs ?? 0;
    message.ecefX = object.ecefX ?? 0;
    message.ecefY = object.ecefY ?? 0;
    message.ecefZ = object.ecefZ ?? 0;
    return message;
  },
};

function createBaseWeedCounterChunk(): WeedCounterChunk {
  return {
    conclusionCounts: undefined,
    weedSizeData: undefined,
    cropSizeData: undefined,
    countsByCategory: {},
    targetedLaserTimeData: undefined,
    untargetedLaserTimeData: undefined,
    validCropCount: 0,
  };
}

export const WeedCounterChunk: MessageFns<WeedCounterChunk> = {
  encode(message: WeedCounterChunk, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.conclusionCounts !== undefined) {
      CountsByConclusionType.encode(message.conclusionCounts, writer.uint32(10).fork()).join();
    }
    if (message.weedSizeData !== undefined) {
      TargetSizeData.encode(message.weedSizeData, writer.uint32(18).fork()).join();
    }
    if (message.cropSizeData !== undefined) {
      TargetSizeData.encode(message.cropSizeData, writer.uint32(26).fork()).join();
    }
    Object.entries(message.countsByCategory).forEach(([key, value]) => {
      WeedCounterChunk_CountsByCategoryEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    if (message.targetedLaserTimeData !== undefined) {
      RequiredLaserTimeData.encode(message.targetedLaserTimeData, writer.uint32(42).fork()).join();
    }
    if (message.untargetedLaserTimeData !== undefined) {
      RequiredLaserTimeData.encode(message.untargetedLaserTimeData, writer.uint32(50).fork()).join();
    }
    if (message.validCropCount !== 0) {
      writer.uint32(56).uint64(message.validCropCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WeedCounterChunk {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWeedCounterChunk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.conclusionCounts = CountsByConclusionType.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.weedSizeData = TargetSizeData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cropSizeData = TargetSizeData.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = WeedCounterChunk_CountsByCategoryEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.countsByCategory[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.targetedLaserTimeData = RequiredLaserTimeData.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.untargetedLaserTimeData = RequiredLaserTimeData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.validCropCount = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WeedCounterChunk {
    return {
      conclusionCounts: isSet(object.conclusionCounts)
        ? CountsByConclusionType.fromJSON(object.conclusionCounts)
        : undefined,
      weedSizeData: isSet(object.weedSizeData) ? TargetSizeData.fromJSON(object.weedSizeData) : undefined,
      cropSizeData: isSet(object.cropSizeData) ? TargetSizeData.fromJSON(object.cropSizeData) : undefined,
      countsByCategory: isObject(object.countsByCategory)
        ? Object.entries(object.countsByCategory).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      targetedLaserTimeData: isSet(object.targetedLaserTimeData)
        ? RequiredLaserTimeData.fromJSON(object.targetedLaserTimeData)
        : undefined,
      untargetedLaserTimeData: isSet(object.untargetedLaserTimeData)
        ? RequiredLaserTimeData.fromJSON(object.untargetedLaserTimeData)
        : undefined,
      validCropCount: isSet(object.validCropCount) ? globalThis.Number(object.validCropCount) : 0,
    };
  },

  toJSON(message: WeedCounterChunk): unknown {
    const obj: any = {};
    if (message.conclusionCounts !== undefined) {
      obj.conclusionCounts = CountsByConclusionType.toJSON(message.conclusionCounts);
    }
    if (message.weedSizeData !== undefined) {
      obj.weedSizeData = TargetSizeData.toJSON(message.weedSizeData);
    }
    if (message.cropSizeData !== undefined) {
      obj.cropSizeData = TargetSizeData.toJSON(message.cropSizeData);
    }
    if (message.countsByCategory) {
      const entries = Object.entries(message.countsByCategory);
      if (entries.length > 0) {
        obj.countsByCategory = {};
        entries.forEach(([k, v]) => {
          obj.countsByCategory[k] = Math.round(v);
        });
      }
    }
    if (message.targetedLaserTimeData !== undefined) {
      obj.targetedLaserTimeData = RequiredLaserTimeData.toJSON(message.targetedLaserTimeData);
    }
    if (message.untargetedLaserTimeData !== undefined) {
      obj.untargetedLaserTimeData = RequiredLaserTimeData.toJSON(message.untargetedLaserTimeData);
    }
    if (message.validCropCount !== 0) {
      obj.validCropCount = Math.round(message.validCropCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WeedCounterChunk>, I>>(base?: I): WeedCounterChunk {
    return WeedCounterChunk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WeedCounterChunk>, I>>(object: I): WeedCounterChunk {
    const message = createBaseWeedCounterChunk();
    message.conclusionCounts = (object.conclusionCounts !== undefined && object.conclusionCounts !== null)
      ? CountsByConclusionType.fromPartial(object.conclusionCounts)
      : undefined;
    message.weedSizeData = (object.weedSizeData !== undefined && object.weedSizeData !== null)
      ? TargetSizeData.fromPartial(object.weedSizeData)
      : undefined;
    message.cropSizeData = (object.cropSizeData !== undefined && object.cropSizeData !== null)
      ? TargetSizeData.fromPartial(object.cropSizeData)
      : undefined;
    message.countsByCategory = Object.entries(object.countsByCategory ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.targetedLaserTimeData =
      (object.targetedLaserTimeData !== undefined && object.targetedLaserTimeData !== null)
        ? RequiredLaserTimeData.fromPartial(object.targetedLaserTimeData)
        : undefined;
    message.untargetedLaserTimeData =
      (object.untargetedLaserTimeData !== undefined && object.untargetedLaserTimeData !== null)
        ? RequiredLaserTimeData.fromPartial(object.untargetedLaserTimeData)
        : undefined;
    message.validCropCount = object.validCropCount ?? 0;
    return message;
  },
};

function createBaseWeedCounterChunk_CountsByCategoryEntry(): WeedCounterChunk_CountsByCategoryEntry {
  return { key: "", value: 0 };
}

export const WeedCounterChunk_CountsByCategoryEntry: MessageFns<WeedCounterChunk_CountsByCategoryEntry> = {
  encode(message: WeedCounterChunk_CountsByCategoryEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint32(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WeedCounterChunk_CountsByCategoryEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWeedCounterChunk_CountsByCategoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WeedCounterChunk_CountsByCategoryEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: WeedCounterChunk_CountsByCategoryEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WeedCounterChunk_CountsByCategoryEntry>, I>>(
    base?: I,
  ): WeedCounterChunk_CountsByCategoryEntry {
    return WeedCounterChunk_CountsByCategoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WeedCounterChunk_CountsByCategoryEntry>, I>>(
    object: I,
  ): WeedCounterChunk_CountsByCategoryEntry {
    const message = createBaseWeedCounterChunk_CountsByCategoryEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseWheelEncoderSpatialData(): WheelEncoderSpatialData {
  return { startPosM: 0, endPosM: 0 };
}

export const WheelEncoderSpatialData: MessageFns<WheelEncoderSpatialData> = {
  encode(message: WheelEncoderSpatialData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startPosM !== 0) {
      writer.uint32(13).float(message.startPosM);
    }
    if (message.endPosM !== 0) {
      writer.uint32(21).float(message.endPosM);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WheelEncoderSpatialData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWheelEncoderSpatialData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.startPosM = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.endPosM = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WheelEncoderSpatialData {
    return {
      startPosM: isSet(object.startPosM) ? globalThis.Number(object.startPosM) : 0,
      endPosM: isSet(object.endPosM) ? globalThis.Number(object.endPosM) : 0,
    };
  },

  toJSON(message: WheelEncoderSpatialData): unknown {
    const obj: any = {};
    if (message.startPosM !== 0) {
      obj.startPosM = message.startPosM;
    }
    if (message.endPosM !== 0) {
      obj.endPosM = message.endPosM;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WheelEncoderSpatialData>, I>>(base?: I): WheelEncoderSpatialData {
    return WheelEncoderSpatialData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WheelEncoderSpatialData>, I>>(object: I): WheelEncoderSpatialData {
    const message = createBaseWheelEncoderSpatialData();
    message.startPosM = object.startPosM ?? 0;
    message.endPosM = object.endPosM ?? 0;
    return message;
  },
};

function createBaseBandingSpatialData(): BandingSpatialData {
  return { percentBanded: 0 };
}

export const BandingSpatialData: MessageFns<BandingSpatialData> = {
  encode(message: BandingSpatialData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.percentBanded !== 0) {
      writer.uint32(13).float(message.percentBanded);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BandingSpatialData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBandingSpatialData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.percentBanded = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BandingSpatialData {
    return { percentBanded: isSet(object.percentBanded) ? globalThis.Number(object.percentBanded) : 0 };
  },

  toJSON(message: BandingSpatialData): unknown {
    const obj: any = {};
    if (message.percentBanded !== 0) {
      obj.percentBanded = message.percentBanded;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BandingSpatialData>, I>>(base?: I): BandingSpatialData {
    return BandingSpatialData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BandingSpatialData>, I>>(object: I): BandingSpatialData {
    const message = createBaseBandingSpatialData();
    message.percentBanded = object.percentBanded ?? 0;
    return message;
  },
};

function createBaseImplementWidthSpatialData(): ImplementWidthSpatialData {
  return { widthMm: 0 };
}

export const ImplementWidthSpatialData: MessageFns<ImplementWidthSpatialData> = {
  encode(message: ImplementWidthSpatialData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.widthMm !== 0) {
      writer.uint32(13).float(message.widthMm);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImplementWidthSpatialData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImplementWidthSpatialData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.widthMm = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImplementWidthSpatialData {
    return { widthMm: isSet(object.widthMm) ? globalThis.Number(object.widthMm) : 0 };
  },

  toJSON(message: ImplementWidthSpatialData): unknown {
    const obj: any = {};
    if (message.widthMm !== 0) {
      obj.widthMm = message.widthMm;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImplementWidthSpatialData>, I>>(base?: I): ImplementWidthSpatialData {
    return ImplementWidthSpatialData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImplementWidthSpatialData>, I>>(object: I): ImplementWidthSpatialData {
    const message = createBaseImplementWidthSpatialData();
    message.widthMm = object.widthMm ?? 0;
    return message;
  },
};

function createBaseVelocitySpatialMetric(): VelocitySpatialMetric {
  return { avgTargetVel: {} };
}

export const VelocitySpatialMetric: MessageFns<VelocitySpatialMetric> = {
  encode(message: VelocitySpatialMetric, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.avgTargetVel).forEach(([key, value]) => {
      VelocitySpatialMetric_AvgTargetVelEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VelocitySpatialMetric {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVelocitySpatialMetric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = VelocitySpatialMetric_AvgTargetVelEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.avgTargetVel[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VelocitySpatialMetric {
    return {
      avgTargetVel: isObject(object.avgTargetVel)
        ? Object.entries(object.avgTargetVel).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: VelocitySpatialMetric): unknown {
    const obj: any = {};
    if (message.avgTargetVel) {
      const entries = Object.entries(message.avgTargetVel);
      if (entries.length > 0) {
        obj.avgTargetVel = {};
        entries.forEach(([k, v]) => {
          obj.avgTargetVel[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VelocitySpatialMetric>, I>>(base?: I): VelocitySpatialMetric {
    return VelocitySpatialMetric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VelocitySpatialMetric>, I>>(object: I): VelocitySpatialMetric {
    const message = createBaseVelocitySpatialMetric();
    message.avgTargetVel = Object.entries(object.avgTargetVel ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseVelocitySpatialMetric_AvgTargetVelEntry(): VelocitySpatialMetric_AvgTargetVelEntry {
  return { key: "", value: 0 };
}

export const VelocitySpatialMetric_AvgTargetVelEntry: MessageFns<VelocitySpatialMetric_AvgTargetVelEntry> = {
  encode(message: VelocitySpatialMetric_AvgTargetVelEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VelocitySpatialMetric_AvgTargetVelEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVelocitySpatialMetric_AvgTargetVelEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VelocitySpatialMetric_AvgTargetVelEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: VelocitySpatialMetric_AvgTargetVelEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VelocitySpatialMetric_AvgTargetVelEntry>, I>>(
    base?: I,
  ): VelocitySpatialMetric_AvgTargetVelEntry {
    return VelocitySpatialMetric_AvgTargetVelEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VelocitySpatialMetric_AvgTargetVelEntry>, I>>(
    object: I,
  ): VelocitySpatialMetric_AvgTargetVelEntry {
    const message = createBaseVelocitySpatialMetric_AvgTargetVelEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseJobMetric(): JobMetric {
  return { jobId: "" };
}

export const JobMetric: MessageFns<JobMetric> = {
  encode(message: JobMetric, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobMetric {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobMetric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobMetric {
    return { jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "" };
  },

  toJSON(message: JobMetric): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobMetric>, I>>(base?: I): JobMetric {
    return JobMetric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobMetric>, I>>(object: I): JobMetric {
    const message = createBaseJobMetric();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseHWMetric(): HWMetric {
  return { lifted: false, estopped: false, laserKey: false, interlock: false, waterProtect: false };
}

export const HWMetric: MessageFns<HWMetric> = {
  encode(message: HWMetric, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lifted !== false) {
      writer.uint32(8).bool(message.lifted);
    }
    if (message.estopped !== false) {
      writer.uint32(16).bool(message.estopped);
    }
    if (message.laserKey !== false) {
      writer.uint32(24).bool(message.laserKey);
    }
    if (message.interlock !== false) {
      writer.uint32(32).bool(message.interlock);
    }
    if (message.waterProtect !== false) {
      writer.uint32(40).bool(message.waterProtect);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HWMetric {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHWMetric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lifted = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.estopped = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.laserKey = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.interlock = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.waterProtect = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HWMetric {
    return {
      lifted: isSet(object.lifted) ? globalThis.Boolean(object.lifted) : false,
      estopped: isSet(object.estopped) ? globalThis.Boolean(object.estopped) : false,
      laserKey: isSet(object.laserKey) ? globalThis.Boolean(object.laserKey) : false,
      interlock: isSet(object.interlock) ? globalThis.Boolean(object.interlock) : false,
      waterProtect: isSet(object.waterProtect) ? globalThis.Boolean(object.waterProtect) : false,
    };
  },

  toJSON(message: HWMetric): unknown {
    const obj: any = {};
    if (message.lifted !== false) {
      obj.lifted = message.lifted;
    }
    if (message.estopped !== false) {
      obj.estopped = message.estopped;
    }
    if (message.laserKey !== false) {
      obj.laserKey = message.laserKey;
    }
    if (message.interlock !== false) {
      obj.interlock = message.interlock;
    }
    if (message.waterProtect !== false) {
      obj.waterProtect = message.waterProtect;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HWMetric>, I>>(base?: I): HWMetric {
    return HWMetric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HWMetric>, I>>(object: I): HWMetric {
    const message = createBaseHWMetric();
    message.lifted = object.lifted ?? false;
    message.estopped = object.estopped ?? false;
    message.laserKey = object.laserKey ?? false;
    message.interlock = object.interlock ?? false;
    message.waterProtect = object.waterProtect ?? false;
    return message;
  },
};

function createBaseSpatialMetricBlock(): SpatialMetricBlock {
  return {
    start: undefined,
    end: undefined,
    weedCount: undefined,
    weData: undefined,
    bandingData: undefined,
    implementWidthData: undefined,
    velData: undefined,
    startLeft: undefined,
    startRight: undefined,
    endLeft: undefined,
    endRight: undefined,
    jobMetric: undefined,
    suspicious: false,
    hwMetric: undefined,
  };
}

export const SpatialMetricBlock: MessageFns<SpatialMetricBlock> = {
  encode(message: SpatialMetricBlock, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.start !== undefined) {
      SpatialPosition.encode(message.start, writer.uint32(10).fork()).join();
    }
    if (message.end !== undefined) {
      SpatialPosition.encode(message.end, writer.uint32(18).fork()).join();
    }
    if (message.weedCount !== undefined) {
      WeedCounterChunk.encode(message.weedCount, writer.uint32(26).fork()).join();
    }
    if (message.weData !== undefined) {
      WheelEncoderSpatialData.encode(message.weData, writer.uint32(34).fork()).join();
    }
    if (message.bandingData !== undefined) {
      BandingSpatialData.encode(message.bandingData, writer.uint32(42).fork()).join();
    }
    if (message.implementWidthData !== undefined) {
      ImplementWidthSpatialData.encode(message.implementWidthData, writer.uint32(50).fork()).join();
    }
    if (message.velData !== undefined) {
      VelocitySpatialMetric.encode(message.velData, writer.uint32(58).fork()).join();
    }
    if (message.startLeft !== undefined) {
      SpatialPosition.encode(message.startLeft, writer.uint32(66).fork()).join();
    }
    if (message.startRight !== undefined) {
      SpatialPosition.encode(message.startRight, writer.uint32(74).fork()).join();
    }
    if (message.endLeft !== undefined) {
      SpatialPosition.encode(message.endLeft, writer.uint32(82).fork()).join();
    }
    if (message.endRight !== undefined) {
      SpatialPosition.encode(message.endRight, writer.uint32(90).fork()).join();
    }
    if (message.jobMetric !== undefined) {
      JobMetric.encode(message.jobMetric, writer.uint32(98).fork()).join();
    }
    if (message.suspicious !== false) {
      writer.uint32(104).bool(message.suspicious);
    }
    if (message.hwMetric !== undefined) {
      HWMetric.encode(message.hwMetric, writer.uint32(114).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SpatialMetricBlock {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpatialMetricBlock();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.start = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.end = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.weedCount = WeedCounterChunk.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.weData = WheelEncoderSpatialData.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bandingData = BandingSpatialData.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.implementWidthData = ImplementWidthSpatialData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.velData = VelocitySpatialMetric.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startLeft = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.startRight = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.endLeft = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.endRight = SpatialPosition.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.jobMetric = JobMetric.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.suspicious = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.hwMetric = HWMetric.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpatialMetricBlock {
    return {
      start: isSet(object.start) ? SpatialPosition.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? SpatialPosition.fromJSON(object.end) : undefined,
      weedCount: isSet(object.weedCount) ? WeedCounterChunk.fromJSON(object.weedCount) : undefined,
      weData: isSet(object.weData) ? WheelEncoderSpatialData.fromJSON(object.weData) : undefined,
      bandingData: isSet(object.bandingData) ? BandingSpatialData.fromJSON(object.bandingData) : undefined,
      implementWidthData: isSet(object.implementWidthData)
        ? ImplementWidthSpatialData.fromJSON(object.implementWidthData)
        : undefined,
      velData: isSet(object.velData) ? VelocitySpatialMetric.fromJSON(object.velData) : undefined,
      startLeft: isSet(object.startLeft) ? SpatialPosition.fromJSON(object.startLeft) : undefined,
      startRight: isSet(object.startRight) ? SpatialPosition.fromJSON(object.startRight) : undefined,
      endLeft: isSet(object.endLeft) ? SpatialPosition.fromJSON(object.endLeft) : undefined,
      endRight: isSet(object.endRight) ? SpatialPosition.fromJSON(object.endRight) : undefined,
      jobMetric: isSet(object.jobMetric) ? JobMetric.fromJSON(object.jobMetric) : undefined,
      suspicious: isSet(object.suspicious) ? globalThis.Boolean(object.suspicious) : false,
      hwMetric: isSet(object.hwMetric) ? HWMetric.fromJSON(object.hwMetric) : undefined,
    };
  },

  toJSON(message: SpatialMetricBlock): unknown {
    const obj: any = {};
    if (message.start !== undefined) {
      obj.start = SpatialPosition.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = SpatialPosition.toJSON(message.end);
    }
    if (message.weedCount !== undefined) {
      obj.weedCount = WeedCounterChunk.toJSON(message.weedCount);
    }
    if (message.weData !== undefined) {
      obj.weData = WheelEncoderSpatialData.toJSON(message.weData);
    }
    if (message.bandingData !== undefined) {
      obj.bandingData = BandingSpatialData.toJSON(message.bandingData);
    }
    if (message.implementWidthData !== undefined) {
      obj.implementWidthData = ImplementWidthSpatialData.toJSON(message.implementWidthData);
    }
    if (message.velData !== undefined) {
      obj.velData = VelocitySpatialMetric.toJSON(message.velData);
    }
    if (message.startLeft !== undefined) {
      obj.startLeft = SpatialPosition.toJSON(message.startLeft);
    }
    if (message.startRight !== undefined) {
      obj.startRight = SpatialPosition.toJSON(message.startRight);
    }
    if (message.endLeft !== undefined) {
      obj.endLeft = SpatialPosition.toJSON(message.endLeft);
    }
    if (message.endRight !== undefined) {
      obj.endRight = SpatialPosition.toJSON(message.endRight);
    }
    if (message.jobMetric !== undefined) {
      obj.jobMetric = JobMetric.toJSON(message.jobMetric);
    }
    if (message.suspicious !== false) {
      obj.suspicious = message.suspicious;
    }
    if (message.hwMetric !== undefined) {
      obj.hwMetric = HWMetric.toJSON(message.hwMetric);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpatialMetricBlock>, I>>(base?: I): SpatialMetricBlock {
    return SpatialMetricBlock.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpatialMetricBlock>, I>>(object: I): SpatialMetricBlock {
    const message = createBaseSpatialMetricBlock();
    message.start = (object.start !== undefined && object.start !== null)
      ? SpatialPosition.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null)
      ? SpatialPosition.fromPartial(object.end)
      : undefined;
    message.weedCount = (object.weedCount !== undefined && object.weedCount !== null)
      ? WeedCounterChunk.fromPartial(object.weedCount)
      : undefined;
    message.weData = (object.weData !== undefined && object.weData !== null)
      ? WheelEncoderSpatialData.fromPartial(object.weData)
      : undefined;
    message.bandingData = (object.bandingData !== undefined && object.bandingData !== null)
      ? BandingSpatialData.fromPartial(object.bandingData)
      : undefined;
    message.implementWidthData = (object.implementWidthData !== undefined && object.implementWidthData !== null)
      ? ImplementWidthSpatialData.fromPartial(object.implementWidthData)
      : undefined;
    message.velData = (object.velData !== undefined && object.velData !== null)
      ? VelocitySpatialMetric.fromPartial(object.velData)
      : undefined;
    message.startLeft = (object.startLeft !== undefined && object.startLeft !== null)
      ? SpatialPosition.fromPartial(object.startLeft)
      : undefined;
    message.startRight = (object.startRight !== undefined && object.startRight !== null)
      ? SpatialPosition.fromPartial(object.startRight)
      : undefined;
    message.endLeft = (object.endLeft !== undefined && object.endLeft !== null)
      ? SpatialPosition.fromPartial(object.endLeft)
      : undefined;
    message.endRight = (object.endRight !== undefined && object.endRight !== null)
      ? SpatialPosition.fromPartial(object.endRight)
      : undefined;
    message.jobMetric = (object.jobMetric !== undefined && object.jobMetric !== null)
      ? JobMetric.fromPartial(object.jobMetric)
      : undefined;
    message.suspicious = object.suspicious ?? false;
    message.hwMetric = (object.hwMetric !== undefined && object.hwMetric !== null)
      ? HWMetric.fromPartial(object.hwMetric)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
