import { AnyLayer, CustomLayerInterface } from "mapbox-gl";
import { Bounds } from "portal/utils/geo";
import { capitalize } from "portal/utils/strings";
import { classes, theme } from "portal/utils/theme";
import { Farm as FarmProto } from "protos/portal/farm";
import { Feature, FeatureCollection, Geometry } from "geojson";
import { FixType, Point } from "protos/geo/geo";
import { Header } from "portal/components/header/Header";
import { Layer, Marker, Source } from "react-map-gl";
import { Navigate, useParams } from "react-router-dom";
import { Page } from "portal/components/Page";
import { Path } from "portal/utils/routing";
import { Map as PortalMap } from "portal/components/map/Map";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useGetFarmQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { WithSkeleton } from "portal/components/WithSkeleton";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useMemo,
  useState,
} from "react";

enum TabId {
  POINTS = "points",
  ZONES = "zones",
}

export const Farm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { farmId } = useParams();
  const { data: farm, isSuccess } = useQueryPopups(
    useGetFarmQuery(farmId ?? skipToken)
  );

  if (!farmId) {
    return <Navigate to={`/${Path.FARMS}`} />;
  }

  return (
    <>
      <Header
        title={capitalize(t("models.farms.farm_one"))}
        pageTitle={
          farm && `${farm.name} - ${capitalize(t("models.farms.farm_one"))}`
        }
        parentLink={Path.FARMS}
      />
      <Page>
        {/* like `<NoScroll>` but only for `md:` and above */}
        <div className="flex flex-col absolute inset-10 md:overflow-hidden">
          <WithSkeleton variant="rectangular" success={isSuccess}>
            {farm && <FarmView farm={farm} />}
          </WithSkeleton>
        </div>
      </Page>
    </>
  );
};

interface FarmViewProps {
  farm: FarmProto;
}
const FarmView: FunctionComponent<FarmViewProps> = ({ farm }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabId>(TabId.POINTS);
  const handleTabChange = (event: React.SyntheticEvent, tab: TabId): void =>
    setActiveTab(tab);

  const [focusedPointId, setFocusedPointId] = useState<string | undefined>();
  const [focusedZoneId, setFocusedZoneId] = useState<string | undefined>();

  return (
    <div className="flex flex-col gap-3 h-full">
      <Typography variant="h1" className="text-4xl">
        {farm.name}
      </Typography>
      <div className="flex flex-col md:flex-row gap-3 md:gap-5 basis-0 grow">
        {/* extra wrapper to prevent map from having a weird effective min-height */}
        <div className="farm-map-container relative min-h-64 md:min-h-0 md:flex-1 md:h-full">
          <FarmMap
            farm={farm}
            className="absolute inset-0"
            focusedPointId={focusedPointId}
            focusedZoneId={focusedZoneId}
          />
        </div>
        <div className="basis-0 grow flex flex-col min-h-64 md:min-h-0">
          <Tabs color="primary" value={activeTab} onChange={handleTabChange}>
            <Tab label={t("models.farms.point_other")} value={TabId.POINTS} />
            <Tab label={t("models.farms.zone_other")} value={TabId.ZONES}></Tab>
          </Tabs>
          {activeTab === TabId.POINTS && (
            <Points farm={farm} setFocusedPointId={setFocusedPointId} />
          )}
          {activeTab === TabId.ZONES && (
            <Zones farm={farm} setFocusedZoneId={setFocusedZoneId} />
          )}
        </div>
      </div>
    </div>
  );
};

// align Mapbox's generic layer type to `<Marker />`'s more restrictive props
type AnyStandardLayer = Exclude<AnyLayer, CustomLayerInterface>;

const polygonFillLayerStyle: AnyStandardLayer = {
  id: "farm-area-polygons-fill",
  type: "fill",
  filter: ["==", ["geometry-type"], "Polygon"],
  paint: {
    "fill-color": ["get", "zoneColor"],
    "fill-opacity": 0.6,
  },
};

const polygonOutlineLayerStyle: AnyStandardLayer = {
  id: "farm-area-polygons-outline",
  filter: ["==", ["geometry-type"], "Polygon"],
  type: "line",
  paint: {
    "line-color": "black",
    "line-width": 2,
  },
};

const pointLayerStyle: AnyStandardLayer = {
  id: "farm-area-points",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": ["get", "zoneColor"],
    "circle-stroke-color": "black",
    "circle-stroke-width": 2,
  },
  filter: ["==", ["geometry-type"], "Point"],
};

const makeFocusedZoneLayerStyles = (zoneId: string): AnyStandardLayer[] => {
  return [
    {
      id: "farm-area-focused-polygon",
      type: "fill",
      paint: {
        "fill-color": theme.colors.carbon.map.farms.focused,
        "fill-opacity": 0.75,
      },
      filter: [
        "==",
        ["==", ["geometry-type"], "Polygon"],
        ["==", ["get", "zoneId"], zoneId],
      ],
    },
    {
      id: "farm-area-focused-point",
      type: "circle",
      paint: {
        "circle-radius": 16,
        "circle-color": theme.colors.carbon.map.farms.focused,
        "circle-opacity": 0.75,
      },
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "zoneId"], zoneId],
      ],
    },
  ];
};

const makeFocusedPointLayerStyle = (pointId: string): AnyStandardLayer => ({
  id: "farm-focused-point",
  type: "circle",
  paint: {
    "circle-radius": 16,
    "circle-color": theme.colors.carbon.map.farms.focused,
    "circle-opacity": 0.75,
  },
  filter: ["==", ["get", "pointId"], pointId],
});

interface FarmMapProps {
  farm: FarmProto;
  focusedPointId: string | undefined;
  focusedZoneId: string | undefined;
  className?: string;
}
const FarmMap: FunctionComponent<FarmMapProps> = ({
  farm,
  focusedPointId,
  focusedZoneId,
  className,
}) => {
  const { pointsById, areasGeojson, pointsGeojson, bounds } = useMemo(() => {
    const pointsById = new Map<string, Point>();
    for (const { point } of farm.pointDefs) {
      if (!point || !point.id) {
        continue;
      }
      pointsById.set(point.id.id, point);
    }

    // simplify control flow with exceptions, for want of an option monad
    class NoSuchPoint {
      constructor(public point: Point) {}
    }

    type LngLatAlt = [lng: number, lat: number, alt: number];
    const derefCoordinates = (reference: Point): LngLatAlt => {
      const id = reference.id?.id;
      if (!id) {
        throw new NoSuchPoint(reference);
      }
      const referent = pointsById.get(id);
      if (!referent) {
        throw new NoSuchPoint(reference);
      }
      return [referent.lng, referent.lat, referent.alt];
    };

    const areasFeatures: Feature[] = farm.zones.flatMap((zone) => {
      const { contents, areas } = zone;
      const id = zone.id?.id;
      let color = theme.colors.carbon.map.farms.default;
      if (contents) {
        if (contents.field) {
          color = theme.colors.carbon.map.farms.field;
        } else if (contents.obstacle) {
          color = theme.colors.carbon.map.farms.obstacle;
        } else if (contents.headland) {
          color = theme.colors.carbon.map.farms.headland;
        }
      }

      const geometries: Geometry[] = areas.flatMap(
        (area, index): Geometry[] => {
          const { point, lineString, polygon } = area;
          try {
            if (point) {
              const coordinates = derefCoordinates(point);
              return [{ type: "Point", coordinates }];
            } else if (lineString) {
              const coordinates = lineString.points.map((p) =>
                derefCoordinates(p)
              );
              return [{ type: "LineString", coordinates }];
            } else if (polygon) {
              const { boundary, holes } = polygon;
              if (!boundary) {
                return [];
              }
              const coordinates = [];
              coordinates.push(boundary.points.map((p) => derefCoordinates(p)));
              for (const hole of holes) {
                coordinates.push(hole.points.map((p) => derefCoordinates(p)));
              }
              return [{ type: "Polygon", coordinates }];
            } else {
              return [];
            }
            // not called "error" because it's not an `Error`; it is used only
            // for control flow
            // eslint-disable-next-line unicorn/catch-error-name, unicorn/prevent-abbreviations
          } catch (e) {
            if (!(e instanceof NoSuchPoint)) {
              throw e;
            }
            console.warn(
              `Invalid point reference in zone ${zone.id?.id} version ${zone.version?.ordinal}, areas[${index}]:`,
              e.point
            );
            return [];
          }
        }
      );
      const feature: Feature = {
        type: "Feature",
        id,
        geometry: { type: "GeometryCollection", geometries },
        properties: { zoneId: id, zoneColor: color },
      };
      return [feature];
    });
    const areasGeojson: FeatureCollection = {
      type: "FeatureCollection",
      features: areasFeatures,
    };

    const pointsGeojson: FeatureCollection = {
      type: "FeatureCollection",
      features: Array.from(pointsById.entries(), ([id, point]) => ({
        type: "Feature",
        id,
        geometry: {
          type: "Point",
          coordinates: [point.lng, point.lat, point.alt],
        },
        properties: { pointId: id },
      })),
    };

    let bounds: Bounds | undefined;
    if (pointsById.size > 0) {
      bounds = {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      };
      for (const point of pointsById.values()) {
        // XXX: These are intentionally extracted backward to match
        // corresponding errors in the Map component, which are hard to fix
        // because they pervade the stack all the way down to robots sending
        // incorrect health logs.
        const { lng: y, lat: x } = point;

        bounds.minX = Math.min(bounds.minX, x);
        bounds.minY = Math.min(bounds.minY, y);
        bounds.maxX = Math.max(bounds.maxX, x);
        bounds.maxY = Math.max(bounds.maxY, y);
      }
    }

    return { pointsById, areasGeojson, pointsGeojson, bounds };
  }, [farm]);

  const focusedPointLayerStyle = useMemo<AnyStandardLayer | undefined>(() => {
    if (!focusedPointId) {
      return;
    }
    return makeFocusedPointLayerStyle(focusedPointId);
  }, [focusedPointId]);

  const focusedZoneLayerStyles = useMemo<AnyStandardLayer[] | undefined>(() => {
    if (!focusedZoneId) {
      return;
    }
    return makeFocusedZoneLayerStyles(focusedZoneId);
  }, [focusedZoneId]);

  return (
    <PortalMap
      className={className}
      hideRobots
      hideMeasure
      allowEmpty
      extraControls={
        <>
          {Array.from(pointsById.entries(), ([id, point]) => (
            <Marker
              key={id}
              longitude={point.lng}
              latitude={point.lat}
              style={{ cursor: "pointer" }}
            >
              <Tooltip
                arrow
                title={
                  point.name ? (
                    <span>{point.name}</span>
                  ) : (
                    <span className="font-mono text-xs">{point.id?.id}</span>
                  )
                }
              >
                {/* this wrapper prevents react from trying to ref an SVG, which breaks */}
                <div>
                  <PointMarkerIcon point={point} className="block" />
                </div>
              </Tooltip>
            </Marker>
          ))}
          <Source
            key="farm-areas"
            id="farm-areas"
            type="geojson"
            data={areasGeojson}
          >
            <Layer {...polygonFillLayerStyle} />
            <Layer {...pointLayerStyle} />
            {focusedZoneLayerStyles?.map((style: AnyStandardLayer) => (
              <Layer
                key={style.id}
                {...style}
                beforeId={polygonOutlineLayerStyle.id}
              />
            ))}
            <Layer {...polygonOutlineLayerStyle} />
          </Source>
          <Source
            key="farm-points"
            id="farm-points"
            type="geojson"
            data={pointsGeojson}
          >
            {focusedPointLayerStyle && <Layer {...focusedPointLayerStyle} />}
          </Source>
        </>
      }
      extraBounds={bounds}
    />
  );
};

interface PointsProps {
  farm: FarmProto;
  setFocusedPointId: Dispatch<SetStateAction<string | undefined>>;
}
const Points: FunctionComponent<PointsProps> = ({
  farm,
  setFocusedPointId,
}) => {
  const { t } = useTranslation();

  interface FixInfo {
    color: string;
    text: string;
  }
  const getFixInfo = (point: Point): FixInfo => {
    switch (point.captureInfo?.fixType) {
      case FixType.RTK_FIXED: {
        return {
          color: "bg-green-500",
          text: t("views.farms.fixTypes.rtkFixed"),
        };
      }
      case FixType.RTK_FLOAT: {
        return {
          color: "bg-tailwind-yellow-300 text-black",
          text: t("views.farms.fixTypes.rtkFloat"),
        };
      }
      case FixType.GNSS:
      case FixType.DIFFERENTIAL_GNSS: {
        return {
          color: "bg-tailwind-orange-400 text-black",
          text: t("views.farms.fixTypes.gps"),
        };
      }
      case undefined:
      case FixType.NO_FIX: {
        return {
          color: "bg-red-700",
          text: t("views.farms.fixTypes.none"),
        };
      }
      default: {
        return {
          color: "bg-gray-600",
          text: t("views.farms.fixTypes.unknown"),
        };
      }
    }
  };

  return (
    <ul className="p-0 flex flex-col gap-2 basis-0 grow overflow-y-auto">
      {farm.pointDefs.map(({ point }) => {
        if (!point) {
          return;
        }
        const pointId = point.id?.id;
        const fixInfo = getFixInfo(point);
        const onFocus = (): void => setFocusedPointId(pointId);
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const onBlur = (): void => setFocusedPointId(undefined);
        return (
          <li
            className="flex flex-wrap gap-x-2 items-baseline"
            key={pointId}
            // don't know a more appropriate ARIA role here
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onFocus}
            onMouseLeave={onBlur}
          >
            {point.name ? (
              <span>{point.name}</span>
            ) : (
              <Trans
                t={t}
                i18nKey="views.farms.unnamedPoint"
                values={{ pointId }}
                components={[
                  <span key="pointId" className="font-mono text-xs" />,
                ]}
              />
            )}
            <span className="font-mono text-xs">
              {point.lng.toFixed(6)}, {point.lat.toFixed(6)}
            </span>
            <span
              className={classes(
                "inline-block py-1 px-2 rounded-sm uppercase font-bold text-xs",
                fixInfo.color
              )}
            >
              {fixInfo.text}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

interface ZonesProps {
  farm: FarmProto;
  setFocusedZoneId: Dispatch<SetStateAction<string | undefined>>;
}
const Zones: FunctionComponent<ZonesProps> = ({ farm, setFocusedZoneId }) => {
  const { t } = useTranslation();

  return (
    <ul className="p-0 flex flex-col gap-2 basis-0 grow overflow-y-auto">
      {farm.zones.map((zone) => {
        const zoneId = zone.id?.id;
        const onFocus = (): void => setFocusedZoneId(zoneId);
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const onBlur = (): void => setFocusedZoneId(undefined);
        return (
          <li
            key={zone.id?.id}
            className="flex gap-2"
            // don't know a more appropriate ARIA role here
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onFocus}
            onMouseLeave={onBlur}
          >
            <span>{zone.name || "Unnamed zone"}</span>
            <span className="inline-block py-1 px-2 bg-blue-700 rounded-sm uppercase font-bold text-xs">
              {(() => {
                const contents = zone.contents ?? {};
                if (contents.field) {
                  return t("views.farms.zoneTypes.field");
                }
                if (contents.obstacle) {
                  return t("views.farms.zoneTypes.obstacle");
                }
                if (contents.headland) {
                  return t("views.farms.zoneTypes.headland");
                }
                if (contents.privateRoad) {
                  return t("views.farms.zoneTypes.privateRoad");
                }
                return t("views.farms.zoneTypes.unknown");
              })()}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

interface PointMarkerIconProps {
  point: Point;
  className?: string;
}
const PointMarkerIcon: FunctionComponent<PointMarkerIconProps> = ({
  point,
  className,
}) => {
  const innerColor = "white";
  const outerColor = "black";

  let contents;
  switch (point.captureInfo?.fixType) {
    case FixType.RTK_FIXED: {
      // X marks the spot
      const PATH = "M2,2L10,10M2,10L10,2";
      contents = (
        <g fill="none" strokeLinecap="round">
          <path d={PATH} stroke={outerColor} strokeWidth="4" />
          <path d={PATH} stroke={innerColor} strokeWidth="2" />
        </g>
      );
      break;
    }
    case FixType.RTK_FLOAT: {
      // diamond... encircles the spot. between an X and an O.
      const PATH = "M2,6L6,2L10,6L6,10z";
      contents = (
        <g fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d={PATH} stroke={outerColor} strokeWidth="4" />
          <path d={PATH} stroke={innerColor} strokeWidth="2" />
        </g>
      );
      break;
    }
    default: {
      // circle indicates "somewhere around here"
      contents = (
        <g fill="none">
          <circle cx={6} cy={6} r={4} stroke={outerColor} strokeWidth="4" />
          <circle cx={6} cy={6} r={4} stroke={innerColor} strokeWidth="2" />
        </g>
      );
      break;
    }
  }

  return (
    <svg viewBox="0 0 12 12" width={14} height={14} className={className}>
      {contents}
    </svg>
  );
};
