import { buildPermission } from "portal/utils/auth";
import { Header } from "portal/components/header/Header";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { useTranslation } from "react-i18next";
import { withAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import React, { FunctionComponent } from "react";

const _Knowledge: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t("views.knowledge.title")} />
      <Page>
        <iframe
          className="border-0 w-full min-h-48 h-full"
          src="https://docs.google.com/document/d/e/2PACX-1vTaQ9KiyAOh3uL29-REVOnYx2Y3M3vA6bQvPbXA5uHMCNUktqCyRiA_8_9O8eK43AyyRv5dQ2VlNZsI/pub?embedded=true"
          title={`Carbon Robotics $vie(ws.knowledge.title"}`}
        />
      </Page>
    </>
  );
};

export const Knowledge = withAuthorizationRequired(
  [
    buildPermission(
      PermissionAction.read,
      PermissionResource.guides,
      PermissionDomain.all
    ),
  ],
  _Knowledge
);
