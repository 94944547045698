import { AlmanacList } from "portal/components/almanac/AlmanacList";
import { buildPermission } from "portal/utils/auth";
import { Config } from "portal/components/config/Config";
import { convert } from "portal/utils/units/units";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { getActiveAlarms, getCustomerSerial } from "portal/utils/robots";
import { getRobotPath, Path, RobotSubpath, Slug } from "portal/utils/routing";
import { GlobalHotKeys, KeyMap } from "react-hotkeys";
import { GROUP_ROBOT } from "portal/utils/hotkeys";
import { Header } from "portal/components/header/Header";
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { NewRobotField } from "portal/views/robots/NewRobotField";
import { Page } from "portal/components/Page";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { range } from "portal/utils/arrays";
import { ResponsiveSubnav } from "portal/components/ResponsiveSubnav";
import { RobotAdmin } from "./RobotAdmin";
import { RobotAlarmList } from "portal/views/robots/RobotAlarmList";
import { RobotAutotractor } from "./RobotAutotractor";
import { RobotCropList } from "portal/views/robots/RobotCropList";
import { RobotDiscriminatorList } from "./RobotDiscriminatorList";
import { RobotFieldList } from "./RobotFieldList";
import { RobotHardware } from "./RobotHardware";
import { RobotHistory } from "portal/views/robots/RobotHistory";
import { RobotModelinator } from "./RobotModelinator";
import { RobotSelector } from "portal/components/robots/RobotSelector";
import { RobotSummary } from "portal/views/robots/RobotSummary";
import { RobotSupport } from "portal/views/robots/RobotSupport";
import { RobotUploads } from "portal/views/robots/RobotUploads";
import { Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { titleCase } from "portal/utils/strings";
import {
  useAuthorizationRequired,
  withAuthorizationRequired,
} from "portal/components/auth/WithAuthorizationRequired";
import {
  useDeleteAlmanacMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetAlmanacQuery,
  useGetRobotQuery,
  useGetTargetVelocityEstimatorQuery,
  useListAlmanacsForRobotQuery,
  useListTargetVelocityEstimatorsQuery,
  useSetAlmanacMutation,
  useSetTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { VelocityEstimatorList } from "portal/components/velocityEstimator/VelocityEstimatorList";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useState } from "react";

const _Robot: FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // ### USER
  const { isInternal } = useSelf();
  const { isEnabled: hasAlmanac } = useFeatureFlag(FeatureFlag.ALMANAC);
  const { isEnabled: hasTargetVelocityEstimator } = useFeatureFlag(
    FeatureFlag.TARGET_VELOCITY_ESTIMATOR
  );

  // ### ROBOT
  const { serial } = useParams();
  const { data: summary, isSuccess } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken, {
      pollingInterval: convert(1).from("min").to("ms"),
    })
  );

  // ### ROUTING
  const keyMap: KeyMap = {
    GO_SUMMARY: {
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.robots.summary.navTitle"),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r s",
    },
  };
  const routes: RouteObject[] = [
    { path: Path.BASE, element: <RobotSummary /> },
  ];

  // jobs
  const isJobsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.jobs,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.jobs,
      PermissionDomain.all
    ),
  ]);
  if (isJobsAuthorized) {
    routes.push(
      {
        path: `${RobotSubpath.HISTORY}/:startDate/:endDate`,
        element: <RobotHistory />,
      },
      {
        path: `${RobotSubpath.HISTORY}/*`,
        element: (() => {
          const today = DateTime.local();
          const lastWeek = today.minus({ days: 7 });
          return (
            <Navigate
              to={`${getRobotPath(
                serial,
                RobotSubpath.HISTORY
              )}/${lastWeek.toFormat(DATE_PATH_FORMAT)}/${today.toFormat(
                DATE_PATH_FORMAT
              )}`}
            />
          );
        })(),
      }
    );
    keyMap.GO_HISTORY = {
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.robots.history.navTitle"),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r h",
    };
  }

  // autotractor
  const isAutotractorJobsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.autotractor_jobs,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.autotractor_jobs,
      PermissionDomain.all
    ),
  ]);
  if (isAutotractorJobsAuthorized && serial) {
    routes.push({
      path: `${RobotSubpath.AUTOTRACTOR}/*`,
      element: <RobotAutotractor serial={serial} />,
    });
  }

  // crops
  const isCropsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_basic,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.crops_basic,
      PermissionDomain.all
    ),
  ]);
  if (isCropsAuthorized) {
    routes.push(
      {
        path: `${RobotSubpath.CROPS}/:cropId?`,
        element: <RobotCropList />,
      },
      {
        path: `${RobotSubpath.CROPS}/:cropId/:modelId`,
        element: <RobotModelinator />,
      }
    );
    keyMap.GO_CROPS = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.crops.crop_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r o",
    };
  }

  // alarms
  const isAlarmsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.alarms_customer,
      PermissionDomain.all
    ),
  ]);
  if (isAlarmsAuthorized) {
    routes.push({ path: RobotSubpath.ALARMS, element: <RobotAlarmList /> });
    keyMap.GO_ALARMS = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.alarms.alarm_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r a",
    };
  }

  // chat
  const isChatAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.chat,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.chat,
      PermissionDomain.all
    ),
  ]);
  if (isChatAuthorized) {
    routes.push({ path: RobotSubpath.SUPPORT, element: <RobotSupport /> });
    keyMap.GO_SUPPORT = {
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.robots.support.navTitle"),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r t",
    };
  }

  // almanac
  const isAlmanacAuthorized =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.read,
        PermissionResource.almanacs,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.almanacs,
        PermissionDomain.all
      ),
    ]) && hasAlmanac;
  if (isAlmanacAuthorized) {
    routes.push({
      path: `${RobotSubpath.ALMANAC}/*`,
      element: serial ? (
        <AlmanacList
          serial={serial}
          basePath={getRobotPath(serial, RobotSubpath.ALMANAC)}
          useListQuery={useListAlmanacsForRobotQuery}
          useGetQuery={useGetAlmanacQuery}
          useDeleteMutation={useDeleteAlmanacMutation}
          useSetMutation={useSetAlmanacMutation}
        />
      ) : (
        <></>
      ),
    });
    keyMap.GO_ALMANAC = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.almanacs.almanac_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r l",
    };
  }

  // discriminator
  const isDiscriminatorAuthorized =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.read,
        PermissionResource.discriminators,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.discriminators,
        PermissionDomain.all
      ),
    ]) && hasAlmanac;
  if (isDiscriminatorAuthorized) {
    routes.push({
      path: `${RobotSubpath.DISCRIMINATOR}/*`,
      element: <RobotDiscriminatorList />,
    });
    keyMap.GO_DISCRIMINATOR = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.discriminators.discriminator_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r d",
    };
  }

  // velocity estimator
  const isVelocityEstimatorAuthorized =
    useAuthorizationRequired([
      buildPermission(
        PermissionAction.read,
        PermissionResource.velocity_estimators,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.velocity_estimators,
        PermissionDomain.all
      ),
    ]) && hasTargetVelocityEstimator;
  if (isVelocityEstimatorAuthorized) {
    routes.push({
      path: `${RobotSubpath.VELOCITY_ESTIMATOR}/*`,
      element: serial ? (
        <VelocityEstimatorList
          serial={serial}
          basePath={getRobotPath(serial, RobotSubpath.VELOCITY_ESTIMATOR)}
          useListQuery={useListTargetVelocityEstimatorsQuery}
          useGetQuery={useGetTargetVelocityEstimatorQuery}
          useSetMutation={useSetTargetVelocityEstimatorMutation}
          useDeleteMutation={useDeleteTargetVelocityEstimatorMutation}
        />
      ) : (
        <></>
      ),
    });
    keyMap.GO_VELOCITYESTIMATOR = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(
          t("models.velocityEstimators.velocityEstimator_other")
        ),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r v",
    };
  }

  // config
  const isConfigAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.configs,
      PermissionDomain.all
    ),
  ]);
  if (isConfigAuthorized) {
    routes.push({
      path: `${RobotSubpath.CONFIG}/*`,
      element: (
        <Config
          serial={serial}
          basePath={getRobotPath(serial, RobotSubpath.CONFIG)}
        />
      ),
    });
    keyMap.GO_CONFIG = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.configs.config_one")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r c",
    };
  }

  // hardware
  const isHardwareAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.hardware,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.hardware,
      PermissionDomain.all
    ),
  ]);
  if (isHardwareAuthorized) {
    routes.push({
      path: `${RobotSubpath.HARDWARE}/*`,
      element: <RobotHardware />,
    });
    keyMap.GO_HARDWARE = {
      name: t("utils.actions.goToLong", {
        subject: t("views.fleet.robots.hardware.title"),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r r",
    };
  }

  // images
  const isImagesAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.images,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.images,
      PermissionDomain.all
    ),
  ]);
  if (isImagesAuthorized) {
    routes.push({ path: RobotSubpath.UPLOADS, element: <RobotUploads /> });
    keyMap.GO_UPLOADS = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.images.image_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r u",
    };
  }

  // admin
  const isAdminAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.admin_cloud,
      PermissionDomain.all
    ),
  ]);
  if (isAdminAuthorized) {
    routes.push({ path: RobotSubpath.ADMIN, element: <RobotAdmin /> });
    keyMap.GO_ADMIN = {
      name: t("utils.actions.goToLong", {
        subject: t("views.admin.title"),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r a",
    };
  }

  // farms
  const isFarmsAuthorized = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.farms,
      PermissionDomain.customer
    ),
    buildPermission(
      PermissionAction.read,
      PermissionResource.farms,
      PermissionDomain.all
    ),
  ]);
  if (isFarmsAuthorized) {
    routes.push(
      {
        path: `${RobotSubpath.FIELDS}`,
        element: <RobotFieldList />,
      },
      {
        path: `${RobotSubpath.FIELDS}/${Slug.NEW}`,
        element: <NewRobotField serial={serial} />,
      }
    );
    keyMap.GO_FIELDS = {
      name: t("utils.actions.goToLong", {
        subject: titleCase(t("models.fieldDefinitions.fieldDefinition_other")),
      }),
      group: GROUP_ROBOT,
      action: "keyup",
      sequence: "r f",
    };
  }

  const element = useRoutes(routes);
  const [subtitle, setSubtitle] = useState<string>("");

  let name: string = "";
  if (isSuccess) {
    name = isInternal
      ? summary.robot?.serial ?? t("models.robots.unknown")
      : getCustomerSerial(t, summary.robot?.serial);
  }

  return (
    <>
      <GlobalHotKeys
        allowChanges
        keyMap={keyMap}
        handlers={{
          GO_SUMMARY: () => navigate(getRobotPath(serial)),
          GO_HISTORY: () =>
            navigate(getRobotPath(serial, RobotSubpath.HISTORY)),
          GO_CONFIG: () => navigate(getRobotPath(serial, RobotSubpath.CONFIG)),
          GO_CROPS: () => navigate(getRobotPath(serial, RobotSubpath.CROPS)),
          GO_ALARMS: () => navigate(getRobotPath(serial, RobotSubpath.ALARMS)),
          GO_SUPPORT: () =>
            navigate(getRobotPath(serial, RobotSubpath.SUPPORT)),
          GO_HARDWARE: () =>
            navigate(getRobotPath(serial, RobotSubpath.HARDWARE)),
          GO_ALMANAC: () =>
            navigate(getRobotPath(serial, RobotSubpath.ALMANAC)),
          GO_DISCRIMINATOR: () =>
            navigate(getRobotPath(serial, RobotSubpath.DISCRIMINATOR)),
          GO_VELOCITYESTIMATOR: () =>
            navigate(getRobotPath(serial, RobotSubpath.VELOCITY_ESTIMATOR)),
          GO_UPLOADS: () =>
            navigate(getRobotPath(serial, RobotSubpath.UPLOADS)),
          GO_ADMIN: () => navigate(getRobotPath(serial, RobotSubpath.ADMIN)),
          GO_FIELDS: () => navigate(getRobotPath(serial, RobotSubpath.FIELDS)),
        }}
      />
      <Header
        hideTitle={true}
        title={name}
        subtitle={subtitle}
        parentLink={Path.FLEET}
      >
        <RobotSelector
          serial={serial}
          onChange={({ robot }) => {
            if (!serial || !robot) {
              return;
            }
            navigate(pathname.replace(serial, robot.serial));
          }}
          typography="h1"
          typographyClassName="text-lg"
        />
        {summary ? (
          <ResponsiveSubnav
            onChange={(index, name) => setSubtitle(name)}
            pages={[
              {
                label: t("views.fleet.robots.summary.navTitle"),
                to: getRobotPath(serial),
              },
              {
                label: t("views.fleet.robots.history.navTitle"),
                to: `${getRobotPath(serial, RobotSubpath.HISTORY)}/*`,
                hidden: !isJobsAuthorized,
              },
              {
                label: titleCase(t("models.configs.config_one")),
                to: `${getRobotPath(serial, RobotSubpath.CONFIG)}/*`,
                hidden: !isConfigAuthorized,
              },
              {
                count: getActiveAlarms(summary).length,
                label: titleCase(t("models.alarms.alarm_other")),
                to: getRobotPath(serial, RobotSubpath.ALARMS),
                hidden: !isAlarmsAuthorized,
              },
              {
                label: t("views.fleet.robots.support.navTitle"),
                to: getRobotPath(serial, RobotSubpath.SUPPORT),
                hidden: !isChatAuthorized,
              },
              {
                label: titleCase(t("models.crops.crop_other")),
                to: getRobotPath(serial, RobotSubpath.CROPS),
                hidden: !isCropsAuthorized,
              },
              {
                label: titleCase(t("models.almanacs.almanac_other")),
                to: `${getRobotPath(serial, RobotSubpath.ALMANAC)}/*`,
                hidden: !isAlmanacAuthorized,
              },
              {
                label: titleCase(
                  t("models.discriminators.discriminator_other")
                ),
                to: `${getRobotPath(serial, RobotSubpath.DISCRIMINATOR)}/*`,
                hidden: !isDiscriminatorAuthorized,
              },
              {
                label: titleCase(
                  t("models.velocityEstimators.velocityEstimator_other")
                ),
                to: `${getRobotPath(
                  serial,
                  RobotSubpath.VELOCITY_ESTIMATOR
                )}/*`,
                hidden: !isVelocityEstimatorAuthorized,
              },
              {
                label: titleCase(t("views.fleet.robots.hardware.title")),
                to: getRobotPath(serial, RobotSubpath.HARDWARE),
                hidden: !isHardwareAuthorized,
              },
              {
                label: titleCase(t("models.images.image_other")),
                to: getRobotPath(serial, RobotSubpath.UPLOADS),
                hidden: !isImagesAuthorized,
              },
              {
                label: titleCase(
                  t("models.fieldDefinitions.fieldDefinition_other")
                ),
                to: getRobotPath(serial, RobotSubpath.FIELDS),
                hidden: !isFarmsAuthorized,
              },
              {
                label: t("views.admin.title"),
                to: getRobotPath(serial, RobotSubpath.ADMIN),
                hidden: !isAdminAuthorized,
              },
            ]}
          />
        ) : (
          <>
            {range(3).map((index) => (
              <Skeleton
                variant="rectangular"
                className="w-16 h-full"
                key={index}
              />
            ))}
          </>
        )}
      </Header>
      <Page>{element}</Page>
    </>
  );
};

export const Robot = withAuthenticationRequired(
  withAuthorizationRequired(
    [
      buildPermission(
        PermissionAction.read,
        PermissionResource.robots,
        PermissionDomain.customer
      ),
      buildPermission(
        PermissionAction.read,
        PermissionResource.robots,
        PermissionDomain.all
      ),
    ],
    _Robot
  )
);
