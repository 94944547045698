// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: target_velocity_estimator/target_velocity_estimator.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.aimbot.target_velocity_estimator";

export interface TVERowProfile {
  primaryKillRate: number;
  secondaryKillRate: number;
}

export interface TVEProfile {
  id: string;
  name: string;
  updateTs: number;
  protected: boolean;
  cruiseOffsetPercent: number;
  primaryRange: number;
  secondaryRange: number;
  increaseSmoothing: number;
  decreaseSmoothing: number;
  /**
   * use rows map instead
   *
   * @deprecated
   */
  row1:
    | TVERowProfile
    | undefined;
  /**
   * use rows map instead
   *
   * @deprecated
   */
  row2:
    | TVERowProfile
    | undefined;
  /**
   * use rows map instead
   *
   * @deprecated
   */
  row3: TVERowProfile | undefined;
  rows: { [key: number]: TVERowProfile };
  minVelMph: number;
  maxVelMph: number;
}

export interface TVEProfile_RowsEntry {
  key: number;
  value: TVERowProfile | undefined;
}

export interface ProfileDetails {
  id: string;
  name: string;
}

function createBaseTVERowProfile(): TVERowProfile {
  return { primaryKillRate: 0, secondaryKillRate: 0 };
}

export const TVERowProfile: MessageFns<TVERowProfile> = {
  encode(message: TVERowProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.primaryKillRate !== 0) {
      writer.uint32(13).float(message.primaryKillRate);
    }
    if (message.secondaryKillRate !== 0) {
      writer.uint32(21).float(message.secondaryKillRate);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TVERowProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTVERowProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.primaryKillRate = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.secondaryKillRate = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TVERowProfile {
    return {
      primaryKillRate: isSet(object.primaryKillRate) ? globalThis.Number(object.primaryKillRate) : 0,
      secondaryKillRate: isSet(object.secondaryKillRate) ? globalThis.Number(object.secondaryKillRate) : 0,
    };
  },

  toJSON(message: TVERowProfile): unknown {
    const obj: any = {};
    if (message.primaryKillRate !== 0) {
      obj.primaryKillRate = message.primaryKillRate;
    }
    if (message.secondaryKillRate !== 0) {
      obj.secondaryKillRate = message.secondaryKillRate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TVERowProfile>, I>>(base?: I): TVERowProfile {
    return TVERowProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TVERowProfile>, I>>(object: I): TVERowProfile {
    const message = createBaseTVERowProfile();
    message.primaryKillRate = object.primaryKillRate ?? 0;
    message.secondaryKillRate = object.secondaryKillRate ?? 0;
    return message;
  },
};

function createBaseTVEProfile(): TVEProfile {
  return {
    id: "",
    name: "",
    updateTs: 0,
    protected: false,
    cruiseOffsetPercent: 0,
    primaryRange: 0,
    secondaryRange: 0,
    increaseSmoothing: 0,
    decreaseSmoothing: 0,
    row1: undefined,
    row2: undefined,
    row3: undefined,
    rows: {},
    minVelMph: 0,
    maxVelMph: 0,
  };
}

export const TVEProfile: MessageFns<TVEProfile> = {
  encode(message: TVEProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.updateTs !== 0) {
      writer.uint32(24).int64(message.updateTs);
    }
    if (message.protected !== false) {
      writer.uint32(32).bool(message.protected);
    }
    if (message.cruiseOffsetPercent !== 0) {
      writer.uint32(45).float(message.cruiseOffsetPercent);
    }
    if (message.primaryRange !== 0) {
      writer.uint32(53).float(message.primaryRange);
    }
    if (message.secondaryRange !== 0) {
      writer.uint32(61).float(message.secondaryRange);
    }
    if (message.increaseSmoothing !== 0) {
      writer.uint32(69).float(message.increaseSmoothing);
    }
    if (message.decreaseSmoothing !== 0) {
      writer.uint32(77).float(message.decreaseSmoothing);
    }
    if (message.row1 !== undefined) {
      TVERowProfile.encode(message.row1, writer.uint32(82).fork()).join();
    }
    if (message.row2 !== undefined) {
      TVERowProfile.encode(message.row2, writer.uint32(90).fork()).join();
    }
    if (message.row3 !== undefined) {
      TVERowProfile.encode(message.row3, writer.uint32(98).fork()).join();
    }
    Object.entries(message.rows).forEach(([key, value]) => {
      TVEProfile_RowsEntry.encode({ key: key as any, value }, writer.uint32(106).fork()).join();
    });
    if (message.minVelMph !== 0) {
      writer.uint32(117).float(message.minVelMph);
    }
    if (message.maxVelMph !== 0) {
      writer.uint32(125).float(message.maxVelMph);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TVEProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTVEProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.updateTs = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.protected = reader.bool();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.cruiseOffsetPercent = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.primaryRange = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.secondaryRange = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.increaseSmoothing = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.decreaseSmoothing = reader.float();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.row1 = TVERowProfile.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.row2 = TVERowProfile.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.row3 = TVERowProfile.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          const entry13 = TVEProfile_RowsEntry.decode(reader, reader.uint32());
          if (entry13.value !== undefined) {
            message.rows[entry13.key] = entry13.value;
          }
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.minVelMph = reader.float();
          continue;
        case 15:
          if (tag !== 125) {
            break;
          }

          message.maxVelMph = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TVEProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      updateTs: isSet(object.updateTs) ? globalThis.Number(object.updateTs) : 0,
      protected: isSet(object.protected) ? globalThis.Boolean(object.protected) : false,
      cruiseOffsetPercent: isSet(object.cruiseOffsetPercent) ? globalThis.Number(object.cruiseOffsetPercent) : 0,
      primaryRange: isSet(object.primaryRange) ? globalThis.Number(object.primaryRange) : 0,
      secondaryRange: isSet(object.secondaryRange) ? globalThis.Number(object.secondaryRange) : 0,
      increaseSmoothing: isSet(object.increaseSmoothing) ? globalThis.Number(object.increaseSmoothing) : 0,
      decreaseSmoothing: isSet(object.decreaseSmoothing) ? globalThis.Number(object.decreaseSmoothing) : 0,
      row1: isSet(object.row1) ? TVERowProfile.fromJSON(object.row1) : undefined,
      row2: isSet(object.row2) ? TVERowProfile.fromJSON(object.row2) : undefined,
      row3: isSet(object.row3) ? TVERowProfile.fromJSON(object.row3) : undefined,
      rows: isObject(object.rows)
        ? Object.entries(object.rows).reduce<{ [key: number]: TVERowProfile }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = TVERowProfile.fromJSON(value);
          return acc;
        }, {})
        : {},
      minVelMph: isSet(object.minVelMph) ? globalThis.Number(object.minVelMph) : 0,
      maxVelMph: isSet(object.maxVelMph) ? globalThis.Number(object.maxVelMph) : 0,
    };
  },

  toJSON(message: TVEProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.updateTs !== 0) {
      obj.updateTs = Math.round(message.updateTs);
    }
    if (message.protected !== false) {
      obj.protected = message.protected;
    }
    if (message.cruiseOffsetPercent !== 0) {
      obj.cruiseOffsetPercent = message.cruiseOffsetPercent;
    }
    if (message.primaryRange !== 0) {
      obj.primaryRange = message.primaryRange;
    }
    if (message.secondaryRange !== 0) {
      obj.secondaryRange = message.secondaryRange;
    }
    if (message.increaseSmoothing !== 0) {
      obj.increaseSmoothing = message.increaseSmoothing;
    }
    if (message.decreaseSmoothing !== 0) {
      obj.decreaseSmoothing = message.decreaseSmoothing;
    }
    if (message.row1 !== undefined) {
      obj.row1 = TVERowProfile.toJSON(message.row1);
    }
    if (message.row2 !== undefined) {
      obj.row2 = TVERowProfile.toJSON(message.row2);
    }
    if (message.row3 !== undefined) {
      obj.row3 = TVERowProfile.toJSON(message.row3);
    }
    if (message.rows) {
      const entries = Object.entries(message.rows);
      if (entries.length > 0) {
        obj.rows = {};
        entries.forEach(([k, v]) => {
          obj.rows[k] = TVERowProfile.toJSON(v);
        });
      }
    }
    if (message.minVelMph !== 0) {
      obj.minVelMph = message.minVelMph;
    }
    if (message.maxVelMph !== 0) {
      obj.maxVelMph = message.maxVelMph;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TVEProfile>, I>>(base?: I): TVEProfile {
    return TVEProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TVEProfile>, I>>(object: I): TVEProfile {
    const message = createBaseTVEProfile();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.updateTs = object.updateTs ?? 0;
    message.protected = object.protected ?? false;
    message.cruiseOffsetPercent = object.cruiseOffsetPercent ?? 0;
    message.primaryRange = object.primaryRange ?? 0;
    message.secondaryRange = object.secondaryRange ?? 0;
    message.increaseSmoothing = object.increaseSmoothing ?? 0;
    message.decreaseSmoothing = object.decreaseSmoothing ?? 0;
    message.row1 = (object.row1 !== undefined && object.row1 !== null)
      ? TVERowProfile.fromPartial(object.row1)
      : undefined;
    message.row2 = (object.row2 !== undefined && object.row2 !== null)
      ? TVERowProfile.fromPartial(object.row2)
      : undefined;
    message.row3 = (object.row3 !== undefined && object.row3 !== null)
      ? TVERowProfile.fromPartial(object.row3)
      : undefined;
    message.rows = Object.entries(object.rows ?? {}).reduce<{ [key: number]: TVERowProfile }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = TVERowProfile.fromPartial(value);
      }
      return acc;
    }, {});
    message.minVelMph = object.minVelMph ?? 0;
    message.maxVelMph = object.maxVelMph ?? 0;
    return message;
  },
};

function createBaseTVEProfile_RowsEntry(): TVEProfile_RowsEntry {
  return { key: 0, value: undefined };
}

export const TVEProfile_RowsEntry: MessageFns<TVEProfile_RowsEntry> = {
  encode(message: TVEProfile_RowsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      TVERowProfile.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TVEProfile_RowsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTVEProfile_RowsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = TVERowProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TVEProfile_RowsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? TVERowProfile.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: TVEProfile_RowsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = TVERowProfile.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TVEProfile_RowsEntry>, I>>(base?: I): TVEProfile_RowsEntry {
    return TVEProfile_RowsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TVEProfile_RowsEntry>, I>>(object: I): TVEProfile_RowsEntry {
    const message = createBaseTVEProfile_RowsEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? TVERowProfile.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseProfileDetails(): ProfileDetails {
  return { id: "", name: "" };
}

export const ProfileDetails: MessageFns<ProfileDetails> = {
  encode(message: ProfileDetails, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileDetails {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileDetails {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: ProfileDetails): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileDetails>, I>>(base?: I): ProfileDetails {
    return ProfileDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileDetails>, I>>(object: I): ProfileDetails {
    const message = createBaseProfileDetails();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
