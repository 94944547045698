import {
  Alert,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import { array, boolean, number, object, string } from "yup";
import {
  BLUE_LOADING_BUTTON,
  BUTTON,
  classes,
  OUTLINED_BUTTON,
  RED_BUTTON,
  SMALL_INPUT_DARK,
  SMALL_LABEL,
  SMALL_SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { buildPermission } from "portal/utils/auth";
import { capitalize } from "portal/utils/strings";
import { CheckboxWithLabel, TextField } from "formik-mui";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ConfirmationDialog } from "portal/components/ConfirmationDialog";
import { CustomerSelector } from "portal/components/customers/CustomerSelector";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { Field, Form, Formik } from "formik";
import { findWhere, moveItem, sortBy } from "portal/utils/arrays";
import { FleetView, ViewMode, viewModeFromJSON } from "protos/portal/users";
import {
  FleetViewColumn,
  getColumns,
  InfoColumnId,
  isFleetColumnId,
} from "portal/utils/fleetViews";
import { FormikEffect } from "portal/components/FormikEffect";
import {
  ImplementationStatus,
  toImplementationStatus,
} from "portal/utils/robots";
import { isEqual, values } from "portal/utils/objects";
import { isUndefined } from "portal/utils/identity";
import { LoadingButton } from "@mui/lab";
import { Path } from "portal/utils/routing";
import {
  PermissionAction,
  PermissionDomain,
  PermissionResource,
} from "protos/portal/auth";
import { RobotMultiSelector } from "portal/components/robots/RobotMultiSelector";
import { setFleetView, unsetFleetView } from "portal/state/self";
import { SortableContainer } from "portal/components/Sortable";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { StatusSelector } from "portal/components/robots/StatusSelector";
import { TFunction } from "i18next";
import { useAuthorizationRequired } from "portal/components/auth/WithAuthorizationRequired";
import {
  useDeleteFleetViewMutation,
  useUpdateFleetViewMutation,
} from "portal/state/portalApi";
import { useDispatch } from "react-redux";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import CardsIcon from "@mui/icons-material/ViewModuleOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import React, { FunctionComponent, useMemo, useState } from "react";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import TableIcon from "@mui/icons-material/TableChartOutlined";

interface Props {
  view: FleetView;
  open?: boolean;
  onChange: (editingView: FleetView) => void;
  onSave: (editingView: FleetView) => void;
  onCancel: () => void;
}

export const FleetViewEditor: FunctionComponent<Props> = ({
  view,
  open = false,
  onChange,
  onSave,
  onCancel,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInternal, user, customer, measurementSystem } = useSelf();
  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );
  const { i18n, t } = useTranslation();

  const canReadCustomers = useAuthorizationRequired([
    buildPermission(
      PermissionAction.read,
      PermissionResource.customers,
      PermissionDomain.all
    ),
  ]);

  const [deleteFleetView] = useMutationPopups(useDeleteFleetViewMutation(), {
    success: t("utils.actions.deletedLong", {
      subject: capitalize(t("views.fleet.views.fleetView_one")),
    }),
  });

  const [updateFleetView] = useMutationPopups(useUpdateFleetViewMutation(), {
    success: capitalize(
      t("utils.actions.savedLong", {
        subject: capitalize(t("views.fleet.views.fleetView_one")),
      })
    ),
  });
  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const columns = useMemo(
    () => (user ? getColumns(i18n, t, user, measurementSystem) : []),
    [i18n, measurementSystem, t, user]
  );

  // columns
  const visibleColumns = useMemo<FleetViewColumn[]>(
    () =>
      view.columns
        .map((id) => findWhere(columns, { id }))
        .filter((column) => !isUndefined(column)),
    [columns, view.columns]
  );
  const hiddenColumns = useMemo<FleetViewColumn[]>(
    () => columns.filter((column) => !visibleColumns.includes(column)),
    [columns, visibleColumns]
  );

  const [draggingId, setDraggingId] = useState<string | undefined>(undefined);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const setList = (list: FleetViewColumn[]): void => {
    onChange({ ...view, columns: list.map((column) => column.id) });
  };

  const handleDragStart = ({ active }: DragStartEvent): void => {
    if (!isFleetColumnId(active.id)) {
      return;
    }
    setDraggingId(active.id);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    setList(
      // offset indexes because of group column
      moveItem(
        visibleColumns,
        active.data.current?.sortable.index + 1,
        over?.data.current?.sortable.index + 1
      )
    );

    setDraggingId(undefined);
  };

  const getRenderColumn =
    (
      t: TFunction,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => Promise<any>,
      viewMode: ViewMode,
      isHidden: boolean = false
    ) =>
    (column: FleetViewColumn): JSX.Element => {
      if (isHidden && column.isPending && !hasUnvalidatedMetrics) {
        return <></>;
      }
      if (isHidden && column.isInternal && !isInternal) {
        return <></>;
      }
      return (
        <li
          key={column.id}
          className={classes(
            "group whitespace-nowrap flex items-center rounded-sm",
            {
              hidden: isHidden && column.isInternal && !isInternal,
              "opacity-20":
                viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS) &&
                !column.renderForCard,
              "bg-slate-600": column.id === draggingId,
              "italic text-blue-600": column.isPending,
              "italic text-orange-200": column.isInternal,
            }
          )}
        >
          <button
            type="button"
            className="flex items-center whitespace-nowrap border-0 bg-transparent text-inherit p-0"
            onClick={() => {
              if (
                viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS) &&
                !column.renderForCard
              ) {
                return;
              }
              const newColumns = isHidden
                ? [...visibleColumns, column]
                : visibleColumns.filter(({ id }) => id !== column.id);
              setFieldValue(
                "columns",
                newColumns.map(({ id }) => id)
              );
            }}
          >
            {(() => {
              if (
                viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS) &&
                !column.renderForCard
              ) {
                return <InfoIcon className="cursor-not-allowed" />;
              }
              return isHidden ? (
                <AddIcon className="cursor-pointer hover:text-green-700" />
              ) : (
                <RemoveIcon className="cursor-pointer hover:text-red-700" />
              );
            })()}
            <strong
              className={classes("text-ellipsis ml-1", {
                "cursor-row-resize": !isHidden,
                "cursor-pointer": isHidden,
              })}
            >
              {column.headerName}
            </strong>
          </button>
        </li>
      );
    };

  if (!open) {
    return;
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{
        paper: classes("h-auto p-4 bottom-0", "top-16", "w-screen md:w-80"),
      }}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: view.name,
          columns: view.columns,
          search: view.search,
          showInternal: view.showInternal,
          showMap: view.showMap,
          showOffline: view.showOffline,
          statuses: view.statuses,
          pinnedRobotIds: view.pinnedRobotIds,
          customerIds: (() => {
            if (canReadCustomers) {
              return view.customerIds;
            } else {
              return customer?.db?.id ? [customer.db.id] : [];
            }
          })(),
          viewMode: view.viewMode,
        }}
        validationSchema={object({
          name: string().required(t("utils.form.required")),
          columns: array(string()).required(t("utils.form.required")),
          search: string(),
          showInternal: boolean(),
          showMap: boolean(),
          showOffline: boolean(),
          statuses: array(
            string()
              .oneOf<ImplementationStatus>(values(ImplementationStatus))
              .required()
          )
            .ensure()
            .required(t("utils.form.required")),
          pinnedRobotIds: array()
            .of(number())
            .required(t("utils.form.required")),
          customerIds: array().of(number()),
          viewMode: number().oneOf(values(ViewMode)),
        })}
        onSubmit={async (values) => {
          if (!user?.userId) {
            return;
          }
          const result = { ...view, ...values };
          await updateFleetView({ userId: user.userId, fleetView: result });
          dispatch(setFleetView(result));
          onSave(result);
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form className={classes("flex flex-col gap-4")}>
            <FormikEffect
              onChange={(previous, next) => {
                if (
                  !previous?.values ||
                  !next.values ||
                  isEqual(previous.values, next.values)
                ) {
                  return;
                }
                onChange({
                  ...view,
                  ...next.values,
                });
              }}
            />
            <div className="flex justify-end gap-2">
              <Button
                {...RED_BUTTON}
                onClick={() => setConfirmDelete(true)}
                startIcon={<DeleteIcon />}
              >
                {t("utils.actions.delete")}
              </Button>
              {confirmDelete && (
                <ConfirmationDialog
                  title={t("utils.actions.deleteLong", {
                    subject: t("views.fleet.views.fleetView_one"),
                  })}
                  description={t(
                    "components.ConfirmationDialog.delete.description",
                    {
                      subject: view.name,
                    }
                  )}
                  destructive
                  yesText={t("utils.actions.delete", {
                    subject: view.name,
                  })}
                  onClose={() => setConfirmDelete(false)}
                  yesDisabled={!user?.userId || !view.db?.id}
                  onYes={async () => {
                    if (!user?.userId || !view.db?.id) {
                      return;
                    }
                    await deleteFleetView({
                      userId: user.userId,
                      fleetViewId: view.db.id,
                    });
                    dispatch(unsetFleetView(view.db.id));
                    navigate(Path.FLEET);
                  }}
                />
              )}
              <Button
                variant="text"
                className="text-white"
                onClick={() => onCancel()}
              >
                {t("utils.actions.cancel")}
              </Button>
              <LoadingButton
                {...BLUE_LOADING_BUTTON}
                disabled={!view}
                loading={isSubmitting}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                {t("utils.actions.save")}
              </LoadingButton>
            </div>
            <Field
              {...TEXT_FIELD_DARK}
              component={TextField}
              label={t("views.fleet.views.fields.name")}
              name="name"
              className="text-lg"
            />
            {/* Hide this until we can figure out how to do it in a not confusing way... */}
            {/* <Field
              {...SMALL_TEXT_FIELD_DARK}
              component={TextField}
              label={t("utils.actions.search")}
              name="search"
              className="text-lg"
            /> */}
            {canReadCustomers && (
              <CustomerSelector
                value={values.customerIds}
                multiple
                onChange={(customerIds) => {
                  setFieldValue("customerIds", customerIds);
                }}
                small
              />
            )}
            <RobotMultiSelector
              selectedRobots={values.pinnedRobotIds}
              label={t("views.fleet.views.fields.pinnedRobotIds")}
              customerIds={values.customerIds}
              themeProps={{
                input: SMALL_INPUT_DARK,
                select: SMALL_SELECT_DARK,
                label: SMALL_LABEL,
              }}
              onChange={(pinnedRobotIds) => {
                setFieldValue("pinnedRobotIds", pinnedRobotIds);
              }}
            />
            <StatusSelector
              value={values.statuses.map((status) =>
                toImplementationStatus(status)
              )}
              onChange={(statuses) => {
                setFieldValue("statuses", statuses);
              }}
            />
            <Divider />
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              className="text-white"
              name="showMap"
              Label={{
                label: t("utils.actions.showLong", {
                  subject: t("components.map.map"),
                }),
              }}
            />
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              className="text-white"
              name="showOffline"
              Label={{
                label: t("utils.actions.showLong", {
                  subject: t("utils.descriptors.offline"),
                }),
              }}
            />
            {isInternal && (
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                className="text-white"
                name="showInternal"
                Label={{
                  label: t("utils.actions.showLong", {
                    subject: t("views.fleet.robots.toggleable.internal"),
                  }),
                }}
              />
            )}
            <Divider />
            <ButtonGroup variant="contained" className="w-full flex">
              <Button
                {...(values.viewMode ===
                viewModeFromJSON(ViewMode.VIEW_MODE_CARDS)
                  ? BUTTON
                  : OUTLINED_BUTTON)}
                classes={{ root: "w-1/2" }}
                onClick={() => {
                  setFieldValue(
                    "viewMode",
                    viewModeFromJSON(ViewMode.VIEW_MODE_CARDS)
                  );
                }}
                startIcon={<CardsIcon />}
              >
                {t("views.fleet.views.fields.viewMode.values.cards")}
              </Button>
              <Button
                {...(values.viewMode ===
                viewModeFromJSON(ViewMode.VIEW_MODE_TABLE)
                  ? BUTTON
                  : OUTLINED_BUTTON)}
                classes={{ root: "w-1/2" }}
                onClick={() => {
                  setFieldValue(
                    "viewMode",
                    viewModeFromJSON(ViewMode.VIEW_MODE_TABLE)
                  );
                }}
                startIcon={<TableIcon />}
              >
                {t("views.fleet.views.fields.viewMode.values.table")}
              </Button>
            </ButtonGroup>
            <Divider />
            {values.viewMode === ViewMode.VIEW_MODE_CARDS && (
              <Alert
                severity="info"
                variant="outlined"
                className="text-blue-300"
              >
                {t("views.fleet.views.tableOnly")}
              </Alert>
            )}
            <Typography variant="subtitle2">
              {t("views.reports.scheduled.editor.columnsVisible")}
            </Typography>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContainer
                id="visible"
                items={visibleColumns.filter(
                  (column) =>
                    column.id !== InfoColumnId.GROUP &&
                    (isInternal || !column.isInternal)
                )}
                render={getRenderColumn(
                  t,
                  setFieldValue,
                  values.viewMode,
                  false
                )}
              />
            </DndContext>
            <Typography variant="subtitle2">
              {t("views.reports.scheduled.editor.columnsHidden")}
            </Typography>
            <ul className="p-0 m-0">
              {sortBy(hiddenColumns, "headerName").map(
                getRenderColumn(t, setFieldValue, values.viewMode, true)
              )}
            </ul>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
